import * as React from 'react';
import { Stack } from 'office-ui-fabric-react';
import HelpMenu from './HelpMenu';
import './Help.css'
import InvoiceViewDoc from './InvoiceViewDoc';
import BillableUnitsDoc from './BillableUnitsViewDoc';
import PricingDoc from './PricingDoc';
import PurchaseOrderDoc from './PurchaseOrderDoc';
import RollOverDoc from './RollOverDoc';
import EngagementDoc from './EngagementDoc';
import InvoiceManagementDoc from './InvoiceManagementDoc';
import SupplierContractsDoc from './SupplierContractsDoc';
import SupplierDoc from './SupplierDoc';
import HomePageDoc from './HomePageDoc';
import HelmetTab from '../../shared/Helmet/Helmet'

export interface IHelp {
    showDiv: boolean[]
}

class Help extends React.Component<{}, IHelp> {
    constructor(props) {
        super(props);
        this.state = {
            showDiv: [false, false, false,false, false, false, false, false, false, false, false]
        }
        this.onChangeHelpComponent();
    }
    toggleDiv(index) {
        var show = { ...this.state };
        show.showDiv.forEach((x, i, arr) => {
            if (i !== index) {
                arr[i] = false;
            }
            else {
                arr[i] = !arr[i];
            }
        })
        this.setState({
            showDiv: show.showDiv
        })
    }
    onChangeHelpComponent = () => {
        let currentUrl = window.location.pathname;
        switch (currentUrl) {
            case "/help/engagement":
                return this.toggleDiv(0);
            case "/help/rollover":
                return this.toggleDiv(1);
            case "/help/invoiceManagement":
                return this.toggleDiv(2);
            case "/help/purchaseOrders":
                return this.toggleDiv(3);
            case "/help/billableunits":
                return this.toggleDiv(4);
            case "/help/invoiceview":
                return this.toggleDiv(5);
            case "/help/pricing":
                return this.toggleDiv(6);
            case "/help/contract":
                return this.toggleDiv(7);
            case "/help/supplier":
                return this.toggleDiv(8);
            case "/help/home":
                return this.toggleDiv(9);
            default:
                return null
        }
    }

    render() {
        return (
            <div className = "helpmain">
                <Stack horizontalAlign="space-between" horizontal={true} >
               {window.location.pathname === '/help' && <HelmetTab title={'Help'} /> }
               {window.location.pathname === '/help/engagement' && <HelmetTab title = 'Help - Engagement' /> }
               {window.location.pathname === '/help/rollover' && <HelmetTab title = 'Help - Rollover' /> }
               {window.location.pathname === '/help/pricing' && <HelmetTab title = 'Help - Pricing' /> }
               {window.location.pathname === '/help/contract' && <HelmetTab title = 'Help - Supplier Contracts' /> }
               {window.location.pathname === '/help/invoiceManagement' && <HelmetTab title = 'Help - Invoice Management' /> }
               {window.location.pathname === '/help/invoiceview' && <HelmetTab title = 'Help - Invoice' /> }
               {window.location.pathname === '/help/purchaseOrders' && <HelmetTab title = 'Help - Purchase Order Management' /> }
               {window.location.pathname === '/help/billableunits' && <HelmetTab title = 'Help - Billable Units' /> }
               {window.location.pathname === '/help/supplier' && <HelmetTab title = 'Help - Supplier' /> }
            <div className="mainView">
                        <HelpMenu />
                    </div>
                    <div className="content">
                        {
                            this.state.showDiv[0] ?
                                <EngagementDoc />
                                : null
                        }
                        {
                            this.state.showDiv[1] ?
                                <RollOverDoc />
                                : null
                        }
                        {
                            this.state.showDiv[2] ?
                                <InvoiceManagementDoc />
                                : null
                        }
                        {
                            this.state.showDiv[3] ?
                                <PurchaseOrderDoc />
                                : null
                        }
                        {
                            this.state.showDiv[4] ?
                                <BillableUnitsDoc />
                                : null
                        }
                        {
                            this.state.showDiv[5] ?
                                <InvoiceViewDoc />
                                : null
                        }
                        {
                            this.state.showDiv[6] ?
                                <PricingDoc />
                                : null
                        }
                        {
                            this.state.showDiv[7] ?
                                <SupplierContractsDoc />
                                : null
                        }
                        {
                            this.state.showDiv[8] ?
                                <SupplierDoc />
                                : null
                        }
                        {
                            this.state.showDiv[9] ?
                                <HomePageDoc />
                                : null
                        }
                    </div>
                </Stack>
            </div>

        );
    }
}
export default Help;
