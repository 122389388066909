import { Icon, IRenderFunction, ITextFieldProps, mergeStyleSets, Stack, StackItem, TooltipHost } from "@fluentui/react";
import React from "react";

/**
 * For textbox rendering the lable with tooltip.
 * @guidanceText {string}
 */
export const CustomTextboxLabelRender = (guidanceText?: string) => (
  props: ITextFieldProps,
  defaultRender: IRenderFunction<ITextFieldProps>): JSX.Element => {
  const iconBlueColor = {
    color: 'darkblue',
    cursor: "pointer"
  };
  const calloutProps = { gapSpace: 0 };
  const classNames = mergeStyleSets({
    deepSkyBlue: [iconBlueColor],
  });
  return (
    <>
      <Stack horizontal verticalAlign="center" >
        <StackItem style={props.required === true ? {} : { paddingRight: "10px" }}>
          <span>{defaultRender(props)}</span>
        </StackItem>
        <StackItem>
          <TooltipHost
            content={guidanceText}
            // Give the user more time to interact with the tooltip before it closes
            closeDelay={200}
            id={'TooltipHost' + props.id}
            calloutProps={calloutProps}>
            <Icon tabIndex={0} iconName="Info" ariaLabel={guidanceText} className={classNames.deepSkyBlue} />
          </TooltipHost>
        </StackItem>
      </Stack>
    </>
  );
};