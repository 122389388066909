import * as actionTypes from './actionTypes';
import appConfig from '../../assets/configuration/config';
import { v4 as uuidv4 } from 'uuid';
import { delay } from 'q';
import {
    Routes,
    TelemetryEvent,
    TelemetryException,
  } from '../../shared/models';
  import telemetryContext from '../../shared/services/TelemetryServices';
  import gtoApiNewInstance, {
    gtoMsalApiFetch,
  } from '../../shared/http/GtoApiNew.axios';
  import { toast } from 'react-toastify';
  import { css } from 'glamor';
  import {
    NRCCategoryMockData,
  } from '../../shared/mock/NRCMockData';

export const setNrcLineItemCategories = (nrcLineCategories) => {
    return {
      type: actionTypes.GET_NRC_LINE_CATEGORIES,
      nrcLineCategories,
    };
  };

  /* action to fetch the NRC Category details from API */
export const getNRCLineItemCategories = () => {
    return (dispatch) => {
      // Checking if app is using mock data.
      if (appConfig.UseMockData === 'false') {
        // Creating headers for telemetry
        const headers: object = {
          'X-CorrelationId': uuidv4(),
          SubCorrelationKey: uuidv4(),
        };
  
        // Defining the url
        let url: string = Routes.GetNRCLineItemCategories;
  
        // Logging telemetry for NRC Categories api call.
        telemetryContext.logEvent(TelemetryEvent.FetchNRCLINEiTEMcategoriesBegin, {
          url,
          headers,
        });
  
        // Making the get call.
        return gtoMsalApiFetch(gtoApiNewInstance.get, url, {
          headers,
        })
          .then((response: any) => {
            // Mapping the response data.
            let nrcLineCategories = response.data.map((x) => ({
              CategoryID: x.categoryId,
              Type: x.type,
              TypeId: x.typeId,
              Category: x.category,
              IsActive: x.isActive,
              IsDiscounted: x.isDiscounted,
              IsScheduleRestricted:x.onlyIMViewable
            }));
  
            dispatch(setNrcLineItemCategories(nrcLineCategories));
          })
          .catch((error) => {
            // Log Exception
            telemetryContext.logException(
              error.toString(),
              TelemetryException.FetchNRCLINEiTEMcategoriesFailure,
              undefined,
              {
                url: url,
                headers,
              }
            );
            // Error handling.
            if (error.response && error.response.status === 404) {
              toast.error('Unable to fetch NRC Line Item Categories.', {
                className: css({
                  background: '#a80000 !important',
                }),
              });
            }
  
            throw error;
          });
      } else {
        //Setting mock data.
        return delay(2000).then(() => {
          dispatch(setNrcLineItemCategories(NRCCategoryMockData));
        });
      }
    };
  };
