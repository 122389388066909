import * as actionTypes from './actionTypes';
import { IInvoiceBillableUnitDetails } from '../../shared/models/InvoiceBillableUnitDetails';
import { v4 as uuidv4 } from 'uuid';
import {
  Routes,
  TelemetryEvent,
  TelemetryException,
  IHeadCountMonthList,
} from '../../shared/models';
import telemetryContext from '../../shared/services/TelemetryServices';
import gtoApiNewInstance, {
  gtoMsalApiFetch,
} from '../../shared/http/GtoApiNew.axios';
import { toast } from 'react-toastify';
import { delay } from 'q';
import { IInvoiceHeadCountDetails } from '../../shared/models/InvoiceHeadCountDetails';
import {
  IAmountCalculation,
  ICurrencyTotalAmount,
} from '../../components/headcount/volume-nrc/detail-list/VolumeDetailList';
import { IPriorPeriodAdjustment } from '../../shared/models/PriorPeriodAdjustment';
import { IAcceptModification } from '../../shared/models/AcceptModification';
import { IPriorPeriodDetails } from '../../shared/models/PriorPeriodDetails';
import { setIsLoadingGlobal } from './user.action';
import { css } from 'glamor';
import { MicrosoftSubmittedBillableUnits } from '../../shared/models/MicrosoftSubmittedBillableUnits';
import { IDataCount } from '../../shared/models/DataCount';
import {
  INrcType,
  INrcCategory,
  INrcDescription,
  ISubmitNRCDetails,
} from '../../shared/models/NRC';
import {
  ISDUser,
  ISDForSEID,
} from '../../shared/models/SDProxy';
import { InvoiceBillableUnitDetailsMockData } from '../../shared/mock/InvoiceBillableUnitDetailsMockData';
import { MonthListMockData } from '../../shared/mock/MonthListMockData';
import { FiscalPeriodsMockData } from '../../shared/mock/FiscalPeriodsMockData';
import { MicrosoftSubmittedBillableUnitsMockData } from '../../shared/mock/MicrosoftSubmittedBillableUnitsMockData';
import {
  NRCTypesMockData,
  NRCCategoryMockData,
  NRCDescriptionMockData,
} from '../../shared/mock/NRCMockData';
import {
  SDUsersMockData,
} from '../../shared/mock/SDProxyMockData';
import appConfig from '../../assets/configuration/config';
import { calculateItemCount } from './invoiceView.action';

/* This function sets the invoice billable units array details */
export const setInvoiceBillableUnits = (
  units: IInvoiceBillableUnitDetails[]
) => {
  return {
    type: actionTypes.SET_BILLABLE_UNITS,
    units,
  };
};

/* This function sets the month list array details */
export const setMonthListBegin = (months: IHeadCountMonthList[]) => {
  return {
    type: actionTypes.SET_MONTH_LIST,
    months,
  };
};

/* This function sets the prior period array details */
export const setPriorPeriod = (priorPeriod: IPriorPeriodAdjustment[]) => {
  return {
    type: actionTypes.SET_PERIOD_DETAIL_PRIOR_ADJUSTMENT,
    priorPeriod,
  };
};

//This function sets the Microsoft submitted billable units
export const getMicrosoftSubmittedBillableUnits = (
  microsoftSubmittedBillableUnits: MicrosoftSubmittedBillableUnits[]
) => {
  return {
    type: actionTypes.GET_MICROSOFT_SUBMITTED_BILLABLE_UNITS,
    microsoftSubmittedBillableUnits,
  };
};

/* This function sets the amount calculation details array*/
export const totalAmountCalculation = (amountTotal: IAmountCalculation[]) => {
  return {
    type: actionTypes.TOTAL_AMOUNT,
    amountTotal,
  };
};

/* This function sets the total amount calculation details */
export const totalAmountCalculated = (totalAmount: ICurrencyTotalAmount[]) => {
  return {
    type: actionTypes.TOTAL_AMOUNT_CALCULATED,
    totalAmount,
  };
};

/* This function is for requesting Invoice details. */
export const requestInvoiceDetails = () => {
  return { type: actionTypes.REQUEST_BILLABLEUNITS_DETAILS };
};

/* This function is to request the fetch completion for Invoice details */
export const requestInvoiceDetailsComplete = () => {
  return { type: actionTypes.REQUEST_BILLABLEUNITS_DETAILS_COMPLETE };
};

/* This function is for requesting month details. */
export const requestMonthDetails = () => {
  return { type: actionTypes.REQUEST_MONTHLIST_DETAILS };
};

/* This function is to request the fetch completion for Month details */
export const requestMonthDetailsComplete = () => {
  return { type: actionTypes.REQUEST_MONTHLIST_DETAILS_COMPLETE };
};

/* This function is for error message */
export const showErrorMessage = () => {
  return { type: actionTypes.SHOW_ERROR_MESSAGE };
};

/* This function is for error message completion */
export const showErrorMessageComplete = () => {
  return { type: actionTypes.SHOW_ERROR_MESSAGE_COMPLETE };
};

/* This function is for error message for month details */
export const showErrorMessageMonth = () => {
  return { type: actionTypes.SHOW_ERROR_MESSAGE_MONTH };
};

/* This function is for error message completion for month details */
export const showErrorMessageMonthComplete = () => {
  return { type: actionTypes.SHOW_ERROR_MESSAGE_MONTH_COMPLETE };
};

/* This function sets to true if there is change in the billable units */
export const setBillableUnitsChange = (isBillableUnitChange: boolean) => {
  return { type: actionTypes.SET_BILLABLEUNITS_CHANGE, isBillableUnitChange };
};

export const isColumnSortedDetails = (isColumnSorted: boolean) => {
  return {
    type: actionTypes.IS_COLUMN_SORTED,
    isColumnSorted,
  };
};

export const dataCountBillableUnits = (dataCount: IDataCount) => {
  return {
    type: actionTypes.SET_BILLABLEUNITS_COUNT,
    dataCount,
  };
};

export const setNrcTypes = (nrcTypes: INrcType[]) => {
  return {
    type: actionTypes.GET_NRC_TYPES,
    nrcTypes,
  };
};

export const setNrcCategories = (nrcCategories: INrcCategory[]) => {
  return {
    type: actionTypes.GET_NRC_CATEGORIES,
    nrcCategories,
  };
};

export const setNrcDescription = (nrcDescription: INrcDescription[]) => {
  return {
    type: actionTypes.GET_NRC_DESCRIPTION,
    nrcDescription,
  };
};

export const isNRCDescriptionFetched = (isDescriptionFetched: boolean) => {
  return {
    type: actionTypes.IS_NRC_DESCRIPTION_FETCHED,
    isDescriptionFetched,
  };
};

export const setSDUsers = (sdUsers: ISDUser[]) => {
  return {
    type: actionTypes.GET_SD_Users,
    sdUsers,
  };
};

/*-----------------------------THUNK ACTION CREATORS-------------------------------*/

/* SUMMARY:
Function calls api for getting the billable units based on fiscal year and cum month.
Its possible for only fiscalYearNumber to be given.
If cumMonth is provided, fiscalYearNumber must also be provided.
*/
export const getBillableUnits = (CumMonth: number) => {
  return (dispatch) => {
    dispatch(requestInvoiceDetails());
    dispatch(showErrorMessageComplete());
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };

      // Defining the url
      let url: string = Routes.GetBillableUnits;
      if (CumMonth !== 0) {
        url = `${url}?cumMonth=${CumMonth}`;
      }
      // Logging telemetry for billable units api call.
      telemetryContext.logEvent(TelemetryEvent.FetchBillableUnitsBegin, {
        url,
        headers,
      });

      // Making the get call.
      return gtoMsalApiFetch(gtoApiNewInstance.get, url, {
        headers,
      })
        .then((response: any) => {
          // Mapping the response data.
          let billableUnits: IInvoiceBillableUnitDetails[] = response.data.map(
            (x) => ({
              EngagementId: x.engagementId,
              FiscalYearNumber: x.fiscalYearNumber,
              SupplierEngagementId: x.supplierEngagementId,
              FunctionDetail: x.functionDetail,
              FunctionSummary: x.functionSummary,
              SupplierName: x.supplierName,
              SupplierCity: x.supplierCity,
              SupplierCountry: x.supplierCountry,
              BusinessSegment: x.businessSegment,
              ExecPL: x.execPL,
              Product: x.product,
              ProductGroup: x.productGroup,
              ProductSubType: x.productSubType,
              Offering: x.offering,
              LOB: x.lob,
              MediaType: x.mediaType,
              MediaSubType: x.mediaSubtype,
              SupportTier: x.supportTier,
              Language: x.language,
              TaxPercent: x.taxPercent,
              BillableUnits: x.billableUnits,
              LastAction: x.lastAction,
              State: x.state,
              StateId: x.stateId,
              PostCumMonth: x.postCumMonth,
              PostInvoicePeriod: x.postInvoicePeriod,
              CurrencyCode: x.currencyCode,
              DiscountPercent: x.discountPercent,
              TotalAmount: x.totalAmount,
              GrossAmount: x.grossAmount
                .toFixed(2)
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
              DiscountAmount: x.discountAmount
                .toFixed(2)
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
              TaxAmount: x.taxAmount
                .toFixed(2)
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
              NetAmount: x.netAmount
                .toFixed(2)
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),

              PriceLine:
                x.priceLine === null
                  ? null
                  : x.priceLine.map((y, index) => ({
                      PricingModel: y.pricingModel,
                      PricingType: y.pricingType,
                      PricingLevel: y.pricingLevel,
                      Price: y.price,
                      VolumeMin: y.volumeMin,
                      VolumeMax: y.volumeMax,
                      Index: index,
                    })),
              NRCId: x.nrcId,
              NRCType: x.nrcType,
              NRCCategory: x.nrcCategory,
              NRCDescription: x.nrcDescription,
              IsEditable: x.isEditable,
              NeedsMicrosoftAcceptance: x.needsMicrosoftAcceptance,
              IsNRC: x.isNRC,
              IsPriorPeriodAdjustment: x.isPriorPeriodAdjustment,
              CumMonth: x.cumMonth,
              IsVolume: x.isVolume,
              Comment: x.comment,
              SD1: x.serviceDelivery,
              SD2: x.serviceDeliveryManager,
              PricingModel: x.pricingModel,
              SupplierNumber:x.supplierNumber,
              PurchaseOrder: x.purchaseOrder,
              CompanyCode:x.companyCode,
              CostCenter: x.costCenter,
              InternalOrder: x.internalOrder,
              AccountCode:x.accountCode,
              TotalAmountCD: x.totalAmountCD
                .toFixed(2)
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
              GrossAmountCD: x.grossAmountCD
                .toFixed(2)
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
              DiscountAmountCD: x.discountAmountCD
                .toFixed(2)
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
              TaxAmountCD: x.taxAmountCD
                .toFixed(2)
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
              NetAmountCD: x.netAmountCD
                .toFixed(2)
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
              SalesRegion: x.geoRegion,
              PCY: x.pcy,
              SubPCY: x.subPCY,
              IsCommerce: x.isCommerce,
              BusinessGroup: x.businessGroup,
              IsAutomationSupplierEngagement: x.isAutomationSupplierEngagement
            })
          );
          dispatch(calculateItemCount(billableUnits));
          dispatch(amountCalculationHeadCount(billableUnits, false));
          dispatch(requestInvoiceDetailsComplete());
        })
        .catch((error) => {
          // Log exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchBillableUnitsFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );

          // Error handling.
          if (error.response.status === 404) {
            toast.error('No Supplier Engagement assigned to you.', {
              className: css({
                background: '#a80000 !important',
              }),
            });
            dispatch(calculateItemCount([]));
            dispatch(showErrorMessage());
          }
          dispatch(requestInvoiceDetailsComplete());
          throw error;
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {
        //dispatch(dataCountBillableUnits(dataCount));
        dispatch(
          amountCalculationHeadCount(InvoiceBillableUnitDetailsMockData, false)
        );
        dispatch(requestInvoiceDetailsComplete());
      });
    }
  };
};

/* This action posts billable units to the api. */
export const postInvoiceBillableUnits = (
  billableUnits: IInvoiceHeadCountDetails[],
  numberOfUnitsSubmitted
) => {
  return (dispatch) => {
    let cumMonth = billableUnits.map((x) => {
      return x.cumMonth;
    });
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers
      const headers: any = {
        SubCorrelationKey: uuidv4(),
        'X-CorrelationId': uuidv4(),
      };
      let url: string = `${Routes.PostBillableUnits}`;

      // Logging telemetry
      telemetryContext.logEvent(TelemetryEvent.PostBillableUnitsBegin, {
        body: billableUnits,
        headers,
        url,
      });
      return gtoMsalApiFetch(gtoApiNewInstance, url, {
        data: billableUnits,
        headers,
        method: 'post',
      })
        .then((response: any) => {
          dispatch(setIsLoadingGlobal(false));
          // Logging success
          telemetryContext.logEvent(TelemetryEvent.PostBillableUnitsSuccess, {
            headers,
            url,
          });
          toast.success(
            `Billable Units for ${numberOfUnitsSubmitted} supplier engagements submitted successfully.`,
            {
              className: css({
                background: '#107c10 !important',
              }),
            }
          );
          dispatch(
            getBillableUnits(cumMonth[0]) // TODO: Pass the current cumulative month/year from the props.
          );
        })
        .catch((error) => {
          dispatch(setIsLoadingGlobal(false));
          //Logging Exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.PostBillableUnitsFailure,
            undefined,
            {
              headers,
              url,
            }
          );

          if (
            error.response &&
            (error.response.status === 404 || error.response.status === 400)
          ) {
            toast.error('Unable to post the Billable unit' + error.message, {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }
        });
    } else {
      return delay(2000).then(() => {
        dispatch(
          getBillableUnits(cumMonth[0]) // TODO: Pass the current cumulative month/year from the props.
        );
        toast.success('Billable unit was successfully submitted.', {
          className: css({
            background: '#107c10 !important',
          }),
        });
      });
    }
  };
};

/* action to fetch the monthList data from API */
export const getMonthListData = (fiscalYear: number) => {
  return (dispatch) => {
    dispatch(requestMonthDetails());
    dispatch(showErrorMessageMonthComplete());
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };
      // Defining the url
      let url: string = Routes.GetMonthList;
      if (fiscalYear !== 0) {
        url = `${url}?fiscalYearNumber=${fiscalYear}`;
      }
      // Logging telemetry for Month List api call.
      telemetryContext.logEvent(TelemetryEvent.FetchMonthListBegin, {
        url,
        headers,
      });

      // Making the get call.
      return gtoMsalApiFetch(gtoApiNewInstance.get, url, {
        headers,
      })
        .then((response: any) => {
          // Mapping the response data.

          let monthList: IHeadCountMonthList[] = response.data.map((x,i) => ({
            CalendarYear: x.calendarYear,
            CumMonth: x.cumMonth,
            Month: x.month,
            Adjustments: x.adjustments,
            IsCurrentInvoiceMonth: x.isCurrentInvoiceMonth,
            PeriodDetail: x.periodDetail,
            index:i
          }));

          dispatch(setMonthListBegin(monthList));
          dispatch(requestMonthDetailsComplete());
        })
        .catch((error) => {
          // Log exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchMonthListFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );

          // Error handling.
          if (error.response && error.response.status === 404) {
            toast.error('MonthList not available', {
              className: css({
                background: '#a80000 !important',
              }),
            });
            dispatch(showErrorMessageMonth());
          }
          dispatch(requestMonthDetailsComplete());
          throw error;
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {
        //dispatch(dataCountBillableUnits(dataCount));
        dispatch(setMonthListBegin(MonthListMockData));
        dispatch(requestMonthDetailsComplete());
      });
    }
  };
};

/* action to calculate the amount for all suppliers */
export const amountCalculationHeadCount = (
  billableUnits: IInvoiceBillableUnitDetails[],
  isFilter: boolean
) => {
  return (dispatch) => {
    let amountCalc: IAmountCalculation[] = [];
    for (let i = 0; i < billableUnits.length; i++) {
      const pricingType: any = billableUnits[i].PriceLine.map((x) => {
        return x.PricingType;
      });
      if (pricingType[0] === undefined) {
        const amount = 0;
        amountCalc.push({
          amount: amount,
          currencyCode: billableUnits[i].CurrencyCode,
          key: i,
        });
      }
      if (pricingType[0] === 'Simple') {
        const price: any = billableUnits[i].PriceLine.map((x) => {
          return x.Price;
        });
        let units: any;
        units = billableUnits[i].BillableUnits;
        const amount = price * units;
        amountCalc.push({
          amount: amount,
          currencyCode: billableUnits[i].CurrencyCode,
          key: i,
        });
      }
      if (pricingType[0] === 'Tiered') {
        let heads = billableUnits[i].BillableUnits;
        var amount = 0;
        for (let j = 0; j < billableUnits[i].PriceLine.length; j++) {
          if (billableUnits[i].PriceLine[j].VolumeMax - heads > 0) {
            amount = amount + heads * billableUnits[i].PriceLine[j].Price;
            break;
          } else {
            if (billableUnits[i].PriceLine[j].VolumeMax !== 0) {
              amount =
                amount +
                billableUnits[i].PriceLine[j].VolumeMax *
                  billableUnits[i].PriceLine[j].Price;
              heads = heads - billableUnits[i].PriceLine[j].VolumeMax;
            } else {
              amount = amount + heads * billableUnits[i].PriceLine[j].Price;
            }
          }
        }
        amountCalc.push({
          amount: amount,
          currencyCode: billableUnits[i].CurrencyCode,
          key: i,
        });
      }
      if (pricingType[0] === 'Banded') {
        let heads = billableUnits[i].BillableUnits;
        let price;
        for (
          let j = 0;
          j < billableUnits[i].PriceLine.length &&
          heads >= billableUnits[i].PriceLine[j].VolumeMin;
          j++
        ) {
          price = billableUnits[i].PriceLine[j].Price;
        }
        let amount = price * heads;
        amountCalc.push({
          amount: amount,
          currencyCode: billableUnits[i].CurrencyCode,
          key: i,
        });
      }
    }

    dispatch(totalAmountCalculation(amountCalc));
    for (let i = 0; i < billableUnits.length; i++) {
      billableUnits[i].TotalAmount = amountCalc[i].amount;
    }
    if (!isFilter) dispatch(setInvoiceBillableUnits(billableUnits));

    let currencyAmount: ICurrencyTotalAmount[] = [];
    let uniqueCurrencies: string[] = [];
    uniqueCurrencies = amountCalc
      .map((x) => {
        return x.currencyCode;
      })
      .filter((x, i, a) => a.indexOf(x) === i);
    let calculatedAmount: number;
    for (let j = 0; j < uniqueCurrencies.length; j++) {
      calculatedAmount = 0;
      for (let k = 0; k < amountCalc.length; k++) {
        if (amountCalc[k].currencyCode === uniqueCurrencies[j]) {
          calculatedAmount = calculatedAmount + amountCalc[k].amount;
        }
      }
      currencyAmount.push({
        currencyCode: uniqueCurrencies[j],
        totalAmount: calculatedAmount,
        key: j,
      });
    }
    dispatch(totalAmountCalculated(currencyAmount));
  };
};

/* action to fetch the prior period details from API */
export const setPeriodDetailPriorAdjustment = () => {
  return (dispatch) => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };

      // Defining the url
      let url: string = Routes.GetPriorPeriodAdjustmentDetails;

      //Logging telemetry for Prior Period Adjustment details API call
      telemetryContext.logEvent(
        TelemetryEvent.FetchPriorPeriodAdjustmentDetailsBegin,
        {
          url,
          headers,
        }
      );

      // Making the get call.
      return gtoMsalApiFetch(gtoApiNewInstance.get, url, {
        headers,
      })
        .then((response: any) => {
          // Mapping the response data.
          let priorPeriodDetails: IPriorPeriodAdjustment[] = response.data.map(
            (x) => ({
              CumMonth: x.cumMonth,
              FiscalYear: x.fiscalYear,
              Period: x.period,
              PeriodDetail: x.periodDetail,
              StartDate: x.startDate,
              EndDate: x.endDate,
              CalendarYear: x.calendarYear,
              DaysInPeriod: x.daysInPeriod,
              Month: x.month,
              FiscalYearNumber: x.fiscalYearNumber,
            })
          );

          dispatch(setPriorPeriod(priorPeriodDetails));
        })
        .catch((error) => {
          // Log Exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchPriorPeriodAdjustmentDetailsFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );
          // Error handling.
          if (error.response && error.response.status === 404) {
            toast.error('Unable to fetch prior period information.', {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }

          throw error;
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {
        dispatch(setPriorPeriod(FiscalPeriodsMockData));
      });
    }
  };
};

/* This action is for accept modification */
export const acceptModification = (
  acceptModification: IAcceptModification,
  cumMonth: number,
  fiscalYearNumber: number
) => {
  return (dispatch) => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers
      const headers: any = {
        SubCorrelationKey: uuidv4(),
        'X-CorrelationId': uuidv4(),
      };
      let url: string = `${Routes.AcceptModification}`;

      // Logging telemetry
      telemetryContext.logEvent(TelemetryEvent.AcceptModificationBegin, {
        body: acceptModification,
        headers,
        url,
      });
      return gtoMsalApiFetch(gtoApiNewInstance, url, {
        data: acceptModification,
        headers,
        method: 'post',
      })
        .then((response: any) => {
          dispatch(setIsLoadingGlobal(false));

          // Logging success
          telemetryContext.logEvent(TelemetryEvent.AcceptModificationSuccess, {
            headers,
            url,
          });
          toast.success('Modification Accepted', {
            className: css({
              background: '#107c10 !important',
            }),
          });
          dispatch(getBillableUnits(cumMonth));
          dispatch(getMonthListData(fiscalYearNumber));
        })
        .catch((error) => {
          dispatch(setIsLoadingGlobal(false));

          //Loggin Exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.AcceptModificationFailure,
            undefined,
            {
              headers,
              url,
            }
          );

          if (error.response && error.response.status === 404) {
            toast.error('Unable to accept the modification' + error.message, {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }

          if (error.response.status === 400) {
            toast.error(error.response.data[0].errorMessage, {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }
        });
    } else {
      return delay(2000).then(() => {
        toast.success('Modification Accepted', {
          className: css({
            background: '#107c10 !important',
          }),
        });
      });
    }
  };
};

/* Action to post the prior period adjustments */
export const postPriorPeriodAdjustmentDetails = (
  priorPeriodDetails: IPriorPeriodDetails,
  cumMonth: number,
  fiscalYearNumber: number
) => {
  return (dispatch) => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers
      const headers: any = {
        SubCorrelationKey: uuidv4(),
        'X-CorrelationId': uuidv4(),
      };
      let url: string = `${Routes.PostPriorPeriodAdjustment}`;

      // Logging telemetry
      telemetryContext.logEvent(
        TelemetryEvent.PostPriorPeriodAdjustmentDetailsBegin,
        {
          body: priorPeriodDetails,
          headers,
          url,
        }
      );
      return gtoMsalApiFetch(gtoApiNewInstance, url, {
        data: priorPeriodDetails,
        headers,
        method: 'post',
      })
        .then((response: any) => {
          // Logging success
          telemetryContext.logEvent(
            TelemetryEvent.PostPriorPeriodAdjustmentDetailsSuccess,
            {
              headers,
              url,
            }
          );
          dispatch(setIsLoadingGlobal(false));
          toast.success('Prior Period Adjustment posted successfully', {
            className: css({
              background: '#107c10 !important',
            }),
          });
          dispatch(getBillableUnits(cumMonth));
          dispatch(getMonthListData(fiscalYearNumber));
        })
        .catch((error) => {
          dispatch(setIsLoadingGlobal(false));

          //Logging Exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.PostPriorPeriodAdjustmentDetailsFailure,
            undefined,
            {
              headers,
              url,
            }
          );

          if (error.response && error.response.status === 404) {
            toast.error(
              'Unable to post Prior Period Adjustment ' + error.message,
              {
                className: css({
                  background: '#a80000 !important',
                }),
              }
            );
          }

          if (error.response.status === 400) {
            toast.error(error.response.data[0].errorMessage, {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }
        });
    } else {
      return delay(2000).then(() => {
        toast.success('Prior Period Adjustment posted successfully', {
          className: css({
            background: '#107c10 !important',
          }),
        });
      });
    }
  };
};

//Action to get the Microsoft submitted billable units
export const microsoftSubmittedBillableUnits = (cumMonth: number) => {
  return (dispatch) => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };

      // Defining the url
      let url: string = Routes.MicrosoftSubmittedBillableUnits;
      if (cumMonth !== 0) {
        url = `${url}?cumMonth=${cumMonth}`;
      }

      //Logging telemetry for Microsoft submitted billable units API call
      telemetryContext.logEvent(
        TelemetryEvent.FetchMicrosoftSubmittedBillableUnitsBegin,
        {
          url,
          headers,
        }
      );

      // Making the get call.
      return gtoMsalApiFetch(gtoApiNewInstance.get, url, {
        headers,
      })
        .then((response: any) => {
          // Mapping the response data.
          let microsoftSubmittedBillableUnits: MicrosoftSubmittedBillableUnits[] = response.data.map(
            (x) => ({
              SupplierEngagementId: x.supplierEngagementId,
              CumMonth: x.cumMonth,
              PostCumMonth: x.postCumMonth,
              IsNrc: x.isNRC,
              MicrosoftUnits: x.billableUnits,
              NRCId: x.nrcId,
            })
          );

          dispatch(
            getMicrosoftSubmittedBillableUnits(microsoftSubmittedBillableUnits)
          );
        })
        .catch((error) => {
          // Log Exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchMicrosoftSubmittedBillableUnitsFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );
          // Error handling.
          if (error.response && error.response.status === 404) {
            toast.error('Unable to fetch Microsoft submitted billable units', {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }

          throw error;
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {
        dispatch(
          getMicrosoftSubmittedBillableUnits(
            MicrosoftSubmittedBillableUnitsMockData
          )
        );
      });
    }
  };
};

/* action to fetch the NRC Type details from API */
export const getNRCTypes = () => {
  return (dispatch) => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };

      // Defining the url
      let url: string = Routes.GetNRCTypes;

      // Logging telemetry for NRC types api call.
      telemetryContext.logEvent(TelemetryEvent.FetchNRCTypesBegin, {
        url,
        headers,
      });

      // Making the get call.
      return gtoMsalApiFetch(gtoApiNewInstance.get, url, {
        headers,
      })
        .then((response: any) => {
          // Mapping the response data.
          let nrcTypes: INrcType[] = response.data.map((x) => ({
            TypeId: x.typeId,
            Type: x.type,
            IsActive: x.isActive,
          }));

          dispatch(setNrcTypes(nrcTypes));
        })
        .catch((error) => {
          // Log Exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchNRCTypesFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );
          // Error handling.
          if (error.response && error.response.status === 404) {
            toast.error('Unable to fetch NRC Types.', {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }

          throw error;
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {
        dispatch(setNrcTypes(NRCTypesMockData));
      });
    }
  };
};

/* action to fetch the NRC Category details from API */
export const getNRCCategories = () => {
  return (dispatch) => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };

      // Defining the url
      let url: string = Routes.GetNRCCategories;

      // Logging telemetry for NRC Categories api call.
      telemetryContext.logEvent(TelemetryEvent.FetchNRCcategoriesBegin, {
        url,
        headers,
      });

      // Making the get call.
      return gtoMsalApiFetch(gtoApiNewInstance.get, url, {
        headers,
      })
        .then((response: any) => {
          // Mapping the response data.
          let nrcCategories: INrcCategory[] = response.data.map((x) => ({
            CategoryID: x.categoryId,
            Type: x.type,
            TypeId: x.typeId,
            Category: x.category,
            IsActive: x.isActive,
          }));

          dispatch(setNrcCategories(nrcCategories));
        })
        .catch((error) => {
          // Log Exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchNRCCategoriesFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );
          // Error handling.
          if (error.response && error.response.status === 404) {
            toast.error('Unable to fetch NRC Categories.', {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }

          throw error;
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {
        dispatch(setNrcCategories(NRCCategoryMockData));
      });
    }
  };
};

/* action to fetch the NRC Description details from API */
export const getNRCDescription = (supplierEngagementId: number) => {
  return (dispatch) => {
    // Checking if app is using mock data.
    dispatch(isNRCDescriptionFetched(false));
    if (appConfig.UseMockData === 'false') {
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };
      // Defining the url
      let url: string = Routes.GetNRCDescription;
      if (supplierEngagementId !== 0) {
        url = `${url}?supplierEngagementId=${supplierEngagementId}`;
      }

      // Logging telemetry for NRC Description api call.
      telemetryContext.logEvent(TelemetryEvent.FetchNRCDescriptionBegin, {
        url,
        headers,
      });

      // Making the get call.
      return gtoMsalApiFetch(gtoApiNewInstance.get, url, {
        headers,
      })
        .then((response: any) => {
          // Mapping the response data.

          let nrcDescription: INrcDescription[] = response.data.map((x) => ({
            NRCId: x.nrcId,
            SupplierEngagementId: x.supplierEngagementId,
            NRCTypeId: x.nrcTypeId,
            NRCCategoryId: x.nrcCategoryId,
            Description: x.description,
            AnaplanNRCId: x.anaplanNRCId,
            IsActive: x.isActive,
          }));

          dispatch(setNrcDescription(nrcDescription));
          dispatch(isNRCDescriptionFetched(true));
        })
        .catch((error) => {
          // Log exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchNRCDescriptionFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );

          // Error handling.
          if (error.response && error.response.status === 404) {
            dispatch(isNRCDescriptionFetched(true));
            toast.error('Unable to fetch NRC Description.', {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }
          throw error;
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {
        dispatch(setNrcDescription(NRCDescriptionMockData));
      });
    }
  };
};

/* Action to save new or editted NRC Description */
export const saveNewOrEdittedNRCDescription = (
  fiscalYearNumber: number,
  nrcDescriptionDetails: INrcDescription
) => {
  return (dispatch) => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers
      const headers: any = {
        SubCorrelationKey: uuidv4(),
        'X-CorrelationId': uuidv4(),
      };
      let url: string = `${Routes.SaveNRCDescription}`;
      if (fiscalYearNumber !== 0) {
        url = `${url}?fiscalyear=${fiscalYearNumber}`;
      }
      // Logging telemetry
      telemetryContext.logEvent(TelemetryEvent.SaveNRCDescriptionBegin, {
        body: nrcDescriptionDetails,
        headers,
        url,
      });
      return gtoMsalApiFetch(gtoApiNewInstance, url, {
        data: nrcDescriptionDetails,
        headers,
        method: 'post',
      })
        .then((response: any) => {
          // Logging success
          telemetryContext.logEvent(TelemetryEvent.SaveNRCDescriptionSuccess, {
            headers,
            url,
          });

          toast.success('NRC Description saved successfully', {
            className: css({
              background: '#107c10 !important',
            }),
          });
          dispatch(
            getNRCDescription(nrcDescriptionDetails.SupplierEngagementId)
          );
        })
        .catch((error) => {
          //Logging Exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.SaveNRCDescriptionFailure,
            undefined,
            {
              headers,
              url,
            }
          );

          if (error.response && error.response.status === 404) {
            toast.error('Unable to save NRC Description ' + error.message, {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }

          if (error.response.status === 400) {
            toast.error(error.response.data[0].errorMessage, {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }
        });
    } else {
      return delay(2000).then(() => {
        toast.success('NRC Description saved successfully', {
          className: css({
            background: '#107c10 !important',
          }),
        });
      });
    }
  };
};

/* Action to submit NRC Details */
export const submitNRCDetails = (nrcDetails: ISubmitNRCDetails) => {
  return (dispatch) => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers
      const headers: any = {
        SubCorrelationKey: uuidv4(),
        'X-CorrelationId': uuidv4(),
      };
      let url: string = `${Routes.SubmitNRCDetails}`;
      // Logging telemetry
      telemetryContext.logEvent(TelemetryEvent.SubmitNRCDetailsBegin, {
        body: nrcDetails,
        headers,
        url,
      });
      return gtoMsalApiFetch(gtoApiNewInstance, url, {
        data: nrcDetails,
        headers,
        method: 'post',
      })
        .then((response: any) => {
          // Logging success
          telemetryContext.logEvent(TelemetryEvent.SubmitNRCDetailsSuccess, {
            headers,
            url,
          });

          toast.success('NRC submitted successfully', {
            className: css({
              background: '#107c10 !important',
            }),
          });

          dispatch(getBillableUnits(nrcDetails.CumMonth));
        })
        .catch((error) => {
          //Logging Exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.SubmitNRCDetailsFailure,
            undefined,
            {
              headers,
              url,
            }
          );

          if (error.response && error.response.status === 404) {
            toast.error('Unable to submit NRC ' + error.message, {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }

          if (error.response.status === 400) {
            toast.error(error.response.data[0].errorMessage, {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }
        });
    } else {
      return delay(2000).then(() => {
        toast.success('NRC submitted successfully', {
          className: css({
            background: '#107c10 !important',
          }),
        });
      });
    }
  };
};

/* action to fetch the SD Users List from API */
export const getSDUsers = () => {
  return (dispatch) => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };

      // Defining the url
      let url: string = Routes.GetSDUsers;

      // Logging telemetry for SD Users List api call.
      telemetryContext.logEvent(TelemetryEvent.FetchSDUsersBegin, {
        url,
        headers,
      });

      // Making the get call.
      return gtoMsalApiFetch(gtoApiNewInstance.get, url, {
        headers,
      })
        .then((response: any) => {
          // Mapping the response data.
          let sdUsers: ISDUser[] = response.data.map((x) => ({
            UserId: x.userId,
            UserAlias: x.userAlias,
            UserEmail: x.userEmail,
            UserRoleID: x.userRoleID,
            RoleName: x.roleName, 
          }));

          dispatch(setSDUsers(sdUsers));
        })
        .catch((error) => {
          // Log Exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchSDUsersFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );
          // Error handling.
          if (error.response && error.response.status === 404) {
            toast.error('Unable to fetch SD Users.', {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }

          throw error;
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {
        dispatch(setSDUsers(SDUsersMockData));
      });
    }
  };
};

/* action to fetch the SD Users List from API */

/* Action to submit SD Details */
export const submitSDDetails = (sdProxyDetials: ISDForSEID) => {
  return (dispatch) => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers
      const headers: any = {
        SubCorrelationKey: uuidv4(),
        'X-CorrelationId': uuidv4(),
      };
      let url: string = `${Routes.SubmitSDDetails}`;
      // Logging telemetry
      telemetryContext.logEvent(TelemetryEvent.SubmitSDDetailsBegin, {
        body: sdProxyDetials,
        headers,
        url,
      });
      return gtoMsalApiFetch(gtoApiNewInstance, url, {
        data: sdProxyDetials,
        headers,
        method: 'post',
      })
        .then((response: any) => {
          // Logging success
          telemetryContext.logEvent(TelemetryEvent.SubmitSDDetailsSuccess, {
            headers,
            url,
          });
          let resMessage : string = response.data
          if(resMessage.startsWith('SD'))
          {
            toast.success(resMessage, {
              className: css({
                background: '#107c10 !important',
              }),
            });          
          }
          else
          {
            toast.error(resMessage, {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }
          dispatch(getBillableUnits(sdProxyDetials.CurrentInvoiceMonth));
        })
        .catch((error) => {
          //Logging Exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.SubmitSDDetailsFailure,
            undefined,
            {
              headers,
              url,
            }
          );

          if (error.response && error.response.status === 404) {
            toast.error('Unable to submit SD ' + error.message, {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }

          if (error.response.status === 400) {
            toast.error(error.response.data[0].errorMessage, {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }
        });
    } else {
      return delay(2000).then(() => {
        toast.success('SD submitted successfully', {
          className: css({
            background: '#107c10 !important',
          }),
        });
      });
    }
  };
};

/* Action to submit SD Details */