import React from 'react';
import { Navigation } from '../navigation/Navigation';
import Footer from '../Footer/Footer';

function Layout(props: any) {
  return (
    <React.Fragment>
      <Navigation
        isSynthetic={props.isSynthetic}
        isAdmin={props.isAdmin}
        toggleIsSynthetic={props.toggleIsSynthetic}
        currentUserName={props.userName}
        logoutHandler={props.logoutHandler}
        handleFeedback={props.handleFeedback}
      />
      <main>{props.children}</main>
      <Footer></Footer>
    </React.Fragment>
  );
}

export default Layout;
