import * as React from 'react';
import { Image } from 'office-ui-fabric-react';
class HomePageDoc extends React.Component<{}> {
    render() {
        return (
            <div>
                <h4>HOME PAGE</h4>                
                <p>
                    The main menu consists of several tiles. These tiles will be presented to you based on your role in the system.
                    The tiles below are all of the options that can be seen.
                </p>
                <Image src={require('../../assets/images/HomePage1.png')} style={{ height: 500, width: 800 }} />
                <ol>
                    <li ><b>Engagements &ndash;</b> This tile will display the main Engagement and Vendor Engagements interface. You can save your most common searches and a brief mouse&ndash;over on this tile displays your saved searches. </li>
                    <li ><b>NRC Review &ndash;</b> The NRC tile is the location you go to schedule NRC&rsquo;s for invoicing.</li>
                    <li ><b>Pricing &ndash;</b> The contracts and Pricing groups are all managed through this tile&sbquo; click this tile to add a contract&sbquo; add a pricing group&sbquo; or update pricing by adding a pricing line.  </li>
                    <li ><b>Invoicing &ndash;</b> The Invoicing tile has a many links in it&sbquo; allowing you to go to the invoicing tool or to the Invoicing Approval dashboard.  </li>
                    <li ><b>Admin &ndash;</b> the Admin tile has sub menu items for managing the user roles&sbquo; checking the system log&sbquo; adding notifications&sbquo; editing the attribute values&sbquo; managing the custom grid columns and managing users. However&sbquo; you will only see links to the areas to which you have permissions.</li>
                    <li ><b>Notifications &ndash;</b> The Notifications tile is where you will find messages about the system&sbquo; such as scheduled down time&sbquo; upgrades or notifications of a new feature.</li>
                    <li ><b>Help Documents &ndash;</b>This tile will take you to a list of help files for step by step instructions.</li>
                    <li ><b>Welcome &ndash;</b>This tile will display a brief description about GTO Tool.</li>
                    <li ><b>My Completed requests &ndash;</b>This tile will list all your previous request groups that are completed.</li>
                    <li ><b>My Pending requests &ndash;</b>This tile will list all of the pending Engagement and Supplier Engagement Requests that are pending <b>for</b> you. Click on the tile to see the list, then click on a link to view the requests.</li>
                    <li ><b>Feedback & Support &ndash;</b>This is a link to send an email to the support team.</li>
                    <li ><b>GTO SharePoint &ndash;</b>This is a link to SharePoint site of GTO Application.</li>
                    <li ><b>Invoice Approval Status &ndash;</b> This tile will display the current status of the approval process. It will display a message when the approvals have started and it will display a message when the approvals are closing.</li>
                </ol><br /><br />
            </div>
        )
    }
}
export default HomePageDoc;