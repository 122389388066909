import { TagPicker } from '@fluentui/react';
import {
  BaseComponent,
  IBaseProps,
} from 'office-ui-fabric-react/lib/Utilities';
import * as React from 'react';
import { ITag } from '../../../../shared/models';
import './SupplierPicker.css';
import { connect } from 'react-redux';
import { setIsSupplierFiltered } from '../../../../store/actions/selectedSupplier.action';

// interface for props.
export interface ISupplierPickerProps extends IBaseProps {
  tagsChanged: any;
  tagsList: ITag[];
  setIsSupplierFiltered: any;
}

class SupplierPicker extends BaseComponent<ISupplierPickerProps, {}> {
  public render() {
    return (
      <div>
        <TagPicker
          onResolveSuggestions={this._onFilterChanged}
          getTextFromItem={this._getTextFromItem}
          pickerSuggestionsProps={{
            noResultsFoundText: 'No Company Code or Supplier Tags Found',
            suggestionsHeaderText: 'Suggested Tags',
          }}
          itemLimit={2}
          disabled={false}
          inputProps={{
            'aria-label': 'Supplier Company Code Picker',
            onBlur: (ev: React.FocusEvent<HTMLInputElement>) =>
              console.log('onBlur called'),
            onFocus: (ev: React.FocusEvent<HTMLInputElement>) =>
              console.log('onFocus called'),
            placeholder: 'Filter by Supplier/CC',
          }}
          onChange={this.selectedTagsChanged}
        />
      </div>
    );
  }

  // passes the selected tags to parent component.
  private selectedTagsChanged = (currentTags: ITag[]) => {
    this.props.tagsChanged({ selectedTags: currentTags });
    if (currentTags.length > 0) {
      this.props.setIsSupplierFiltered(true);
    } else {
      this.props.setIsSupplierFiltered(false);
    }
  };

  // Gets the text from the given item.
  private _getTextFromItem(item: ITag): string {
    return item.name;
  }

  // Filters out tags that are already selected. User can select only one tag of each type.
  private _onFilterChanged = (filter: string, tagList: ITag[]): ITag[] => {
    return filter
      ? this.props.tagsList
          .filter(
            (tag) => tag.name.toLowerCase().indexOf(filter.toLowerCase()) === 0
          )
          .filter((tag) => !this._listContainsTags(tag, tagList))
      : [];
  };

  private _listContainsTags(tag: ITag, tagList?: ITag[]) {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    const keyMatch =
      tagList.filter((compareTag) => compareTag.key === tag.key).length > 0;
    const typeMatch =
      tagList.filter((compareTag) => compareTag.type === tag.type).length > 0;
    return keyMatch || typeMatch;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setIsSupplierFiltered: (isSupplierFiltered: boolean) => {
      dispatch(setIsSupplierFiltered(isSupplierFiltered));
    },
  };
};

export default connect(null, mapDispatchToProps)(SupplierPicker);
