import { IExcelHeaders} from '../../shared/common/common-util';
import {IColumn} from '@fluentui/react/lib/DetailsList';

export const internalOrderTemplate : IExcelHeaders[] =[
    {header: 'EngagementId',key: 'EngagementId'},
    {header: 'SupplierEngagementId',key: 'SupplierEngagementId'},
    {header: 'InternalOrder',key: 'InternalOrder'},
    {header: 'NewInternalOrder',key: 'NewInternalOrder'},
    {header: 'CostCenter',key: 'CostCenter'},
    {header: 'NewCostCenter',key: 'NewCostCenter'},
    {header: 'keyValue',key: 'keyValue'},
    {header: 'FiscalYearNumber',key: 'FiscalYearNumber'}]

export const internalGridExportHeaders : IExcelHeaders[] =[
    {header: 'EngagementId',key: 'EngagementId'},
    {header: 'SupplierEngagementId',key: 'SupplierEngagementId'},
    {header: 'InternalOrder',key: 'InternalOrder'},
    {header: 'CostCenter',key: 'CostCenter'},
    {header: 'IsInvoiceActiveString',key: 'IsInvoiceActiveString'},
    {header: 'IsBudgetActiveString',key: 'IsBudgetActiveString'},
    {header: 'ExecPl',key: 'ExecPl'},
    {header: 'Product',key: 'Product'},
    {header: 'LOB',key: 'LOB'}]
       
export const InternalOrderColumns: IColumn[] = [
    { key: 'EngagementId',  name: 'Engagement Id', fieldName: 'EngagementId',  minWidth: 100, maxWidth: 150, isResizable: false},
    { key: 'SupplierEngagementId',  name: 'Supplier Engagement Id', fieldName: 'SupplierEngagementId',  minWidth: 170, maxWidth: 170,  isResizable: false},
    { key: 'InternalOrder', name: 'Internal Order', fieldName: 'InternalOrder', minWidth: 100, maxWidth: 150, isResizable: false },
    { key: 'CostCenter',name: 'Cost Center', fieldName: 'CostCenter',minWidth: 90, maxWidth: 150,isResizable: false},
    { key: 'IsInvoiceActive', name: 'Invoice', fieldName: 'IsInvoiceActive', minWidth: 105, maxWidth: 150,isResizable: false},
    { key: 'IsBudgetActive',name: 'Budget', fieldName: 'IsBudgetActive', minWidth: 105, maxWidth: 150, isResizable: false},
    { key: 'ExecPl',name: 'ExecPl', fieldName: 'ExecPl', minWidth: 100, maxWidth: 150, isResizable: false},
    { key: 'Product', name: 'Product', fieldName: 'Product', minWidth: 150, maxWidth: 150, isResizable: false},
    { key: 'LOB',name: 'LOB', fieldName: 'LOB', minWidth: 200, maxWidth: 200,  isResizable: false},
    { key: 'KeyValue', name: 'Key Value', fieldName: 'KeyValue', minWidth: 110, maxWidth: 150, isResizable: false},
];

export const InternalOrderImportColumns: IColumnConfig[] = [
    { key: 'ImportStatus',  text: 'Import Status', name: 'Import Status', fieldName: 'ImportStatus', dataType: 'string', minWidth: 100, maxWidth: 100, applyColumnFilter: true, isResizable: false, disableSort: true },
    { key: 'EngagementId',  text: 'Engagement Id', name: 'Engagement Id', fieldName: 'EngagementId', dataType: 'number', minWidth: 100, maxWidth: 100, applyColumnFilter: true, isResizable: false, disableSort: true },
    { key: 'SupplierEngagementId', text: 'Supplier Engagement Id', name: 'Supplier Engagement Id', fieldName: 'SupplierEngagementId', dataType: 'number', minWidth: 170, maxWidth: 170, applyColumnFilter: true, isResizable: false, disableSort: true },
    { key: 'OldInternalOrder', text: 'Old Internal Order', name: 'Old Internal Order', fieldName: 'OldInternalOrder', dataType: 'number', minWidth: 140, maxWidth: 140, applyColumnFilter: true, isResizable: false, disableSort: true },
    { key: 'NewInternalOrder', text: 'New Internal Order', name: 'New Internal Order', fieldName: 'NewInternalOrder', dataType: 'number', minWidth: 140, maxWidth: 140, applyColumnFilter: true, isResizable: false, disableSort: true },
    { key: 'OldCostCenter', text: 'Old Cost Center', name: 'Old Cost Center', fieldName: 'OldCostCenter', dataType: 'number', minWidth: 120, maxWidth: 120, applyColumnFilter: true, isResizable: false, disableSort: true },
    { key: 'NewCostCenter', text: ' New Cost Center', name: 'New Cost Center', fieldName: 'NewCostCenter', dataType: 'number', minWidth: 120, maxWidth: 120, applyColumnFilter: true, isResizable: false, disableSort: true },
    { key: 'LOB', text: 'LOB', name: 'LOB', fieldName: 'LOB', dataType: 'string', minWidth: 120, maxWidth: 120, applyColumnFilter: true, isResizable: false, disableSort: true},
];

export const InternalOrderApproveColumns: IColumn[] = [
    { key: 'Status',  name: 'Status', fieldName: 'Status',minWidth: 70, maxWidth: 120, isResizable: false },
    { key: 'EngagementId', name: 'Engagement Id', fieldName: 'EngagementId', minWidth: 100, maxWidth: 150,isResizable: false},
    { key: 'SupplierEngagementId', name: 'Supplier Engagement Id', fieldName: 'SupplierEngagementId', minWidth: 170, maxWidth: 170, isResizable: false},
    { key: 'InternalOrder',name: 'Old Internal Order', fieldName: 'InternalOrder', minWidth: 120, maxWidth: 150,isResizable: false,},
    { key: 'NewInternalOrder', name: 'New Internal Order', fieldName: 'NewInternalOrder',minWidth: 125, maxWidth: 150,isResizable: false,},
    { key: 'CostCenter',name: 'Cost Center', fieldName: 'CostCenter',minWidth: 100, maxWidth: 150,isResizable: false},
    { key: 'NewCostCenter',name: 'New Cost Center', fieldName: 'NewCostCenter', minWidth: 110, maxWidth: 150, isResizable: false},
    { key: 'KeyValue',name: 'Key Value', fieldName: 'KeyValue', minWidth: 110, maxWidth: 150,isResizable: false},
    { key: 'FiscalYearNumber', name: 'Fiscal Year', fieldName: 'FiscalYearNumber',minWidth: 100, maxWidth: 150,isResizable: false},
]