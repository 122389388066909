export const bobReportFiltersMockData: any = {
      "suppliers": [
        {
          "supplierMasterId": 1,
          "supplierName": "Convergys"
        }
      ],
      "quarters": [
        "Q1"
      ],
      "budgetTypes": [
        {
          "budgetTypeId": 1,
          "budgetTypeName": "FY21 Budget"
        }
      ],
      "invoiceSources": [
        {
          "invoiceSourceId": 1,
          "invoiceSourceName": "Actual"
        }
      ],
      "fiscalYearNumbers": [
        2019
      ]
}

export const bobReportInvoiceSummaryDataMock: any = {
    "supplierInvoiceDetails": [
      {
        "supplierMasterId": 4,
        "supplierName": "Convergys",
        "currencyCode": "CNY",
        "totalSpendInLC": 9962878.86,
        "passThrough": 29203.77,
        "grossAmount": 9933675.09,
        "exchangeRate": 0.1466,
        "exchangeRateMonth": "Sep",
        "spendInDollar": 1456166.27,
        "boBDiscountPercent": 1.5,
        "discountAmountLC": 149005.13,
        "discountAmountInUSD": 21842.49,
        "quarter": "Q1",
        "isAnnualDiscount": false,
        "invoiceSourceName": "Actual",
        "invoiceSourceId": 4,
        "fiscalYearNumber": 2019
      },
      {
        "supplierMasterId": 4,
        "supplierName": "Convergys",
        "currencyCode": "EUR",
        "totalSpendInLC": 5562974.74,
        "passThrough": 1003.88,
        "grossAmount": 5561970.86,
        "exchangeRate": 1.1693,
        "exchangeRateMonth": "Sep",
        "spendInDollar": 6503612.53,
        "boBDiscountPercent": 1.5,
        "discountAmountLC": 83429.56,
        "discountAmountInUSD": 97554.19,
        "quarter": "Q1",
        "isAnnualDiscount": false,
        "invoiceSourceName": "Actual",
        "invoiceSourceId": 4,
        "fiscalYearNumber": 2019
      }
    ],
    "totalSpendInDollar": 42266613.23,
    "totalDiscountAmountInUSD": 633999.2    
}

export const bobInvoiceReportSelectedFiltersMock: any = {
  "supplierIds": [
    4
  ],
  "quarters": [
    "Q1"
  ],
  "sourceIds": [
    4
  ],
  "fiscalYearNumbers": [
    2019
  ]
}

export const bobReportInvoiceDetailsDataMock: any[] = [
  {
    "supplierEngagementId": 288,
    "profitCenter": "P10124892",
    "functionDetail": "OneDrive",
    "supplierName": "Convergys",
    "source": "Actual",
    "currencyCode": "USD",
    "isAnnualDiscount": false,
    "quarter": "Q1",
    "discountAmountCD": 212.513
  },
  {
    "supplierEngagementId": 290,
    "profitCenter": "P10124892",
    "functionDetail": "OneDrive",
    "supplierName": "Convergys",
    "source": "Actual",
    "currencyCode": "USD",
    "isAnnualDiscount": false,
    "quarter": "Q1",
    "discountAmountCD": 637.54
  }
]


export const bobReportBudgetSummaryDataMock: any = {
  "supplierBudgetSpendDetails": [
    {
      "supplierMasterId": 4,
      "supplierName": "Convergys",
      "currencyCode": "CNY",
      "grossAmount": 8274653.17,
      "exchangeRate": 0.1447,
      "exchangeRateMonth": "Bud",
      "spendInDollar": 1197506.93,
      "boBDiscountPercent": 4.5,
      "discountAmountLC": 372359.39,
      "discountAmountInUSD": 53887.81,
      "budgetTypeName": "FY20 Budget",
      "budgetTypeId": 30,
      "quarter": "Q1",
      "isAnnualDiscount": false
    },
    {
      "supplierMasterId": 4,
      "supplierName": "Convergys",
      "currencyCode": "EUR",
      "grossAmount": 5633686.47,
      "exchangeRate": 1.1142,
      "exchangeRateMonth": "Bud",
      "spendInDollar": 6277335.15,
      "boBDiscountPercent": 4.5,
      "discountAmountLC": 253515.89,
      "discountAmountInUSD": 282480.08,
      "budgetTypeName": "FY20 Budget",
      "budgetTypeId": 30,
      "quarter": "Q1",
      "isAnnualDiscount": false
    }
  ],
  "totalGrossAmount": 51338321.32,
  "totalSpendInDollar": 36122557.23,
  "totalDiscountAmountInUSD": 1625515.08  
}

export const bobBudgetReportSelectedFiltersMock: any ={
  "supplierIds": [
    4
  ],
  "quarters": [
    "Q1"
  ],
  "budgetTypeIds": [
    30
  ]
}

export const bobReportBudgetDetailsDataMock: any[] = [
{
  "supplierEngagementId": 288,
  "profitCenter": "P10124892",
  "functionDetail": "OneDrive",
  "supplierName": "Convergys",
  "source": "Actual",
  "currencyCode": "USD",
  "isAnnualDiscount": false,
  "quarter": "Q1",
  "discountAmountCD": 212.513
},
{
  "supplierEngagementId": 290,
  "profitCenter": "P10124892",
  "functionDetail": "OneDrive",
  "supplierName": "Convergys",
  "source": "Actual",
  "currencyCode": "USD",
  "isAnnualDiscount": false,
  "quarter": "Q1",
  "discountAmountCD": 637.54
}
]