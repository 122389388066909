import { IPurchaseOrder } from '../models/index';

export const purchaseOrderMockData: IPurchaseOrder[] = [];

// For Submitted PO Data
for (let i = 0; i < 5; i++) {
  purchaseOrderMockData.push({
    poNumber: `009851422${i}`,
    poTitle: 'Accenture Project Aurora HR Change Manag',

    poCreatedDate: '02-Mar-2019',
    poAmount: 38038,
    // ContractNumber: 'ysc124537',
    contracts: [],
    poState: 'Approved',
    parentPurchaseOrder: null,
    poType: 'Master',
    aravoEngagementId: '12345689',
    fiscalYearNumber: i % 2 === 0 ? 2019 : 2020,
    currencyCode: 'USD',
    invoices: [
      {
        currencyCode: 'USD',
        invoiceAmount: '1234.9 USD',
        invoiceCreatedDate: '02-Mar-2019',
        invoiceNumber: '0098514222',
        invoiceStatus: 'Open',
      },
      {
        currencyCode: 'USD',
        invoiceAmount: '22233.9 USD',
        invoiceCreatedDate: '02-Mar-2019',
        invoiceNumber: '009851422222',
        invoiceStatus: 'Open',
      },
      {
        currencyCode: 'USD',
        invoiceAmount: '22233.9 USD',
        invoiceCreatedDate: '02-Mar-2019',
        invoiceNumber: '009851422222',
        invoiceStatus: 'Open',
      },
      {
        currencyCode: 'USD',
        invoiceAmount: '22233.9 USD',
        invoiceCreatedDate: '02-Mar-2019',
        invoiceNumber: '009851422222',
        invoiceStatus: 'Open',
      },
      {
        currencyCode: 'USD',
        invoiceAmount: '22233.9 USD',
        invoiceCreatedDate: '02-Mar-2019',
        invoiceNumber: '009851422222',
        invoiceStatus: 'Open',
      },
      {
        currencyCode: 'USD',
        invoiceAmount: '22233.9 USD',
        invoiceCreatedDate: '02-Mar-2019',
        invoiceNumber: '009851422222',
        invoiceStatus: 'Open',
      },
      {
        currencyCode: 'USD',
        invoiceAmount: '22233.9 USD',
        invoiceCreatedDate: '02-Mar-2019',
        invoiceNumber: '009851422222',
        invoiceStatus: 'Open',
      },
    ],
  });
}

// For Draft PO Data
for (let i = 0; i < 5; i++) {
  purchaseOrderMockData.push({
    poNumber: `009851522${i}`,
    poTitle: 'Accenture Project Aurora HR Change Manag',
    // FiscalYearNumber: '2019',
    poCreatedDate: '02-Mar-2019',
    poAmount: 3803,
    // contractNumber: 'ysc124537',
    contracts: [],
    poState: 'Draft',
    poType: 'Master',
    aravoEngagementId: '12345689',
    fiscalYearNumber: i % 2 === 0 ? 2019 : 2020,
    currencyCode: 'USD',
    invoices: [],
    parentPurchaseOrder: null,
  });
}
