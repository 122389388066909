import * as React from 'react';
import { Image } from 'office-ui-fabric-react';
import PO_1 from '../../assets/images/PO_1.jpg';
import PO_1_1 from '../../assets/images/PO_1.1.jpg';
import PO_Search1 from '../../assets/images/PO_Search1.jpg';
import PO_Search1_1 from '../../assets/images/PO_Search1.1.jpg';
import PO_AddClone1 from '../../assets/images/PO_AddClone1.jpg';
import PO_AddClone3 from '../../assets/images/PO_AddClone3.jpg';
import PO_AddClone2_1 from '../../assets/images/PO_AddClone2.1.jpg';
import PO_BulkAdds from '../../assets/images/PO_BulkAdds.jpg';
import PO_BulkAdds1 from '../../assets/images/PO_BulkAdds1.jpg';
import PO_BulkAdds2 from '../../assets/images/PO_BulkAdds2.jpg';
import PO_BulkAdds3 from '../../assets/images/PO_BulkAdds3.jpg';
import PO_BulkAdds4 from '../../assets/images/PO_BulkAdds4.jpg';
import PO_BulkAdds5 from '../../assets/images/PO_BulkAdds5.jpg';
import PO_BulkAdds6 from '../../assets/images/PO_BulkAdds6.jpg';
import PO_BulkAdds6_1 from '../../assets/images/PO_BulkAdds6.1.jpg';
import PO_Edit2 from '../../assets/images/PO_Edit2.jpg';
import PO_ViewAssoSE1 from '../../assets/images/PO_ViewAssoSE1.jpg';
import PO_ViewAssoSE1_1 from '../../assets/images/PO_ViewAssoSE1.1.jpg';
import PO_ViewAssoSE1_2 from '../../assets/images/PO_ViewAssoSE1.2.jpg';




class PurchaseOrderDoc extends React.Component<{}> {
    render() {
        return (
            <div>
                <h2 style={{display: 'block', fontSize: '1.3em', margin: '0.83em 0 0.83em 0', fontWeight: 'bold'}}>Purchase Order Management</h2>
                <h3>Table of Contents</h3>
                <a href="#PO1"><b>Purchase Order</b></a><br />
                <a href="#PO2"><b>Search</b></a><br />
                <a href="#PO3"><b>Add/Clone</b></a><br />
                <a href="#PO4"><b>Bulk Adds</b></a><br />
                <a href="#PO5"><b>Edit</b></a><br />
                <a href="#PO6"><b>View Associated Supplier Engagements</b></a><br />
                <h3 id="PO1" tabIndex={0}>Purchase Order</h3>
                <p>
                    The Purchase Order component of the GTO Invoicing Tool is used by the Finance Operations GTO Invoicing Manager to add or edit purchase order information that will be used by the tool for the monthly invoicing process.
               </p>
                <p>
                    Users assigned to the Invoicing Manager role access the ‘Purchase Order’ component from the Invoicing tile on the tool Home page.
                        </p>
                <Image src={PO_1} style={{ height: 450, width: 850 }} alt="PO_1 Image" />

                <Image src={PO_1_1} style={{ height: 450, width: 850 }} alt="PO_1.1 Image" />
                <h3 id="PO2" tabIndex={0}>Search</h3>
                <p>The user can search for an existing Purchase Order entry based on various criteria.
                          Type in a PO Number, Supplier Name or any other field value and records will be filtered.</p>
                <Image src={PO_Search1} style={{ height: 450, width: 850 }} alt="PO_Search1 Image" />

                <Image src={PO_Search1_1} style={{ height: 450, width: 850 }} alt="PO_Search1.1 Image" />

                <h3 id="PO3" tabIndex={0}>Add/Clone</h3>

                <p>To add a new Purchase Order entry:</p>
                <ol>
                    <li>Select the appropriate Fiscal Year from the drop-down. Typically, the user will be creating a PO record for the current fiscal year, except at the time of fiscal year roll-over when they will be creating entries for the next year.

                    <Image src={PO_AddClone1} style={{ height: 450, width: 850 }} alt="PO_AddClone1 Image" />
                    </li>
                    <li>Click on ‘Add’ button</li>
                    <li>Enter the new Purchase Order number, select from the Supplier drop-down the appropriate Supplier including Supplier Number and Company Code.  The appropriate Currency will be populated based on that selection.
                                 Enter a comment if desired and check the ‘Active for assignment in Supplier Engagement’ box if the value should be immediately available for use. Click ‘Save’.</li>
                </ol>
                <Image src={PO_AddClone3} style={{ height: 450, width: 850 }} alt="PO_AddClone3 Image" />

                <p>To create a new entry by cloning an existing entry:</p>
                <ol>
                    <li>Select the existing entry and click on the ‘Clone’ option.</li>
                    <li>For this example, a new PO number is entered for the same Supplier Number and Company Code.  Click ‘Save’ and the new value will be displayed in the grid.</li>
                </ol>
                <Image src={PO_AddClone2_1} style={{ height: 450, width: 850 }} alt="PO_AddClone2.1 Image" />

                <h3 id="PO4" tabIndex={0}>Bulk Adds</h3>
                <p>If you have multiple Purchase Orders that need to be added, you have the option to add them in bulk.</p>

                <Image src={PO_BulkAdds} style={{ height: 450, width: 850 }} alt="PO_BulkAdds Image" />

                <ol>
                    <li>Click on the ‘Bulk Import’ button and you will receive the following instructions.

                    <Image src={PO_BulkAdds1} style={{ height: 450, width: 850 }} alt="PO_BulkAdds1 Image" />
                    </li>
                    <li>Click ‘Next’ and select the appropriate Fiscal Year from the drop-down.

                    <Image src={PO_BulkAdds2} style={{ height: 450, width: 850 }} alt="PO_BulkAdds2 Image" />
                    </li>

                    <li>
                        Per the instructions, you can copy and paste multiple entries from an Excel spreadsheet using the following format for the data:
                                 Supplier Name, MS Company Code, Currency Code, Supplier Number, PO Number.<br />
                        The field headers must be included but the Supplier Name is optional since the system can use the Supplier Number to look that up in the Supplier table.
                        

                    <Image src={PO_BulkAdds3} style={{ height: 200, width: 850 }} alt="PO_BulkAdds3 Image" />
                    </li>

                    <li>
                        Click ‘Next’. The system will validate the data that you provided and indicate if there are any issues such as a duplicate.
                        If there are duplicates, use the ‘Remove Duplicates’ button to eliminate the record.
                    
                    <Image src={PO_BulkAdds4} style={{ height: 450, width: 850 }} alt="PO_BulkAdds4 Image" />

                    </li>

                    <li>During this step if you determine there are records included that you’re not ready to add, you can use the ‘Remove’ option which will remove the record from your current selection and then ‘Re-verify Import’ to check that all records are valid.

                    <Image src={PO_BulkAdds5} style={{ height: 450, width: 850 }} alt="PO_BulkAdds5 Image" />
                    </li>

                    <li>Click ‘Next’. You have one last opportunity to verify the data as well as add a comment for the batch of entries.  If the data is ready to import click ‘Finish’.
                                 Your new entries will be displayed in the grid.</li>
                </ol>
                <Image src={PO_BulkAdds6} style={{ height: 450, width: 850 }} alt="PO_BulkAdds6 Image" />
                <Image src={PO_BulkAdds6_1} style={{ height: 450, width: 850 }} alt="PO_BulkAdds6.1 Image" />
                <h3 id="PO5" tabIndex={0}>Edit</h3>
                <p>Existing Purchase Order entries typically only need to be edited if 1) The original entry had an error or 2) the user wants to deactivate it so that it can no longer be assigned to Supplier Engagements.</p>
                <ol>
                    <li>On an existing record click the ‘Edit’ option.</li>
                    <li>Make the required changes and click ‘OK’ to save.</li>
                </ol>
                <Image src={PO_Edit2} style={{ height: 450, width: 850 }} alt="PO_Edit2 Image" />

                <h3 id="PO6" tabIndex={0}>View Associated Supplier Engagements</h3>
                <p>
                    The Supplier Engagements column on the Purchase Order grid indicates that a Purchase Order is currently assigned to one or more Supplier Engagements.
                            Click on the ‘Supplier Engagements’ option and you will be taken to the Supplier Engagement grid where the impacted Supplier Engagements are displayed.<br />
                    <b>NOTE:</b>This grid uses the same logic as the SE search function so will pull all Supplier Engagements associated to the Engagements as defined by the Supplier Engagements selected.
                              </p>
                <Image src={PO_ViewAssoSE1} style={{ height: 450, width: 850 }} alt="PO_ViewAssoSE1 Image" />
                <Image src={PO_ViewAssoSE1_1} style={{ height: 450, width: 850 }} alt="PO_ViewAssoSE1.1 Image" />
                <Image src={PO_ViewAssoSE1_2} style={{ height: 450, width: 850 }} alt="PO_ViewAssoSE1.2 Image" />

            </div>
        );
    }
}
export default PurchaseOrderDoc;