import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IDetailsFooterProps,
  DetailsRow,
  SelectionMode,
  IDetailsRowBaseProps,
  IDetailsListProps,
  IDetailsRowStyles,
  ConstrainMode,
  IDetailsHeaderProps,
  IDetailsHeaderStyles,
} from '@fluentui/react/lib/DetailsList';
import * as React from 'react';
import { Sticky } from 'office-ui-fabric-react/lib/components/Sticky/Sticky';
import { IRenderFunction, StickyPositionType, ScrollablePane } from 'office-ui-fabric-react';

// Interface for BOBReportTable props
interface IBOBReportTableProps {
  columns: IColumn[];
  items: any;
  spendInDollar: string;
  discountAmountInUSD: string;
  grossAmount: string;
  lastRefreshedAt: string;
  showFooter: boolean;
}

// Interface for BOBReportTable State
interface IBOBReportTableState {
  sortedItems: any[];
  sortedColumns: IColumn[];
  lastRefreshedAt: string;
}


const customHeaderStyles: Partial<IDetailsHeaderStyles> = {
  root: {
    backgroundColor: 'transparent',
    borderBottom: ' 2px solid lightblue;',
    height: '32px',
    paddingBottom: '5px',
    selectors: {      
      '.ms-DetailsHeader-cell': {
        height: '100%',
        cursor: 'pointer'
      },
      '.ms-DetailsHeader-cellTitle': {
        height: '100%',
        paddingLeft: '3px'
      },
      '.ms-DetailsHeader-cellTitle i': {
        margin: 'auto 0 0 0',
      },
      '.ms-DetailsHeader-cellName': {
        color: '#333333',
        fontSize: '10px',
        lineHeight: 'normal',
        margin: 'auto 0 0 0',
        whiteSpace: 'pre-line',
      },     
    },
  },
};

export class BOBReportTable extends React.Component<
  IBOBReportTableProps, IBOBReportTableState
> {

  constructor(props: IBOBReportTableProps) {
    super(props);

    // State Initialization
    this.state = {
      sortedItems: props.items,
      sortedColumns: props.columns,
      lastRefreshedAt: props.lastRefreshedAt     
    };
  }

  static getDerivedStateFromProps(nextProps, state) {          
      if(state.lastRefreshedAt !== nextProps.lastRefreshedAt){
       return {sortedItems: nextProps.items, sortedColumns: nextProps.columns, lastRefreshedAt: nextProps.lastRefreshedAt };
      }
      else{
        return state;
      }
  }

  public render() {
    const { sortedItems, sortedColumns } = this.state;     
    return (      
        <ScrollablePane>
        <DetailsList 
          className = "bobReportDetailList"
          items={this.addKeyToItems(sortedItems)}
          columns={sortedColumns}
          isHeaderVisible={true}
          enterModalSelectionOnTouch={true}
          selectionMode={SelectionMode.none}
          layoutMode={DetailsListLayoutMode.justified}
          setKey="set"         
          checkButtonAriaLabel="Row checkbox"   
          onColumnHeaderClick={this._onColumnClick}
          constrainMode={ConstrainMode.unconstrained}
          onRenderRow={this._onRenderRow}
          onRenderDetailsHeader = {this._onRenderDetailsHeader}
          onRenderDetailsFooter = {this._onRenderDetailsFooter.bind(this)}
          ariaLabelForGrid = "BOB Discount Details List"
        />
        </ScrollablePane>
    );
  }

  private addKeyToItems(items: any): any[] {
    let i = 0;
    items.forEach(element => {
      element['key'] = i;
      i++;
    });

    return items;
  }

  private _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      if (props.itemIndex % 2 === 0) {
        // Every other row renders with a different background color
        customStyles.root = { backgroundColor: '#EFEFEF', minHeight: '20px'};
      }
      else{
        customStyles.root = { minHeight: '20px'};
      }

      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  };

  // Function for sticky header
  private _onRenderDetailsHeader(
    props: IDetailsHeaderProps,
    defaultRender?: IRenderFunction<IDetailsHeaderProps>
  ): JSX.Element {    
    return (
      <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
        {defaultRender!({
          ...props,
          styles: customHeaderStyles,
        })}
      </Sticky>
    );
  };

  private _onRenderDetailsFooter(detailsFooterProps: IDetailsFooterProps): JSX.Element {
    const customStyles: Partial<IDetailsRowStyles> = {};
    customStyles.root = { borderTop: ' 2px solid lightblue;', borderBottom: 'none' }
    if(this.state.sortedItems.length > 0 && this.props.showFooter){
    return (
      <Sticky stickyClassName= "stickyFooter" stickyPosition={StickyPositionType.Footer} isScrollSynced={true}>
        <DetailsRow
          styles = { customStyles }
          {...detailsFooterProps}
          columns={detailsFooterProps.columns}
          item={-1}
          itemIndex={-1}      
          
          selectionMode={SelectionMode.none}
          onRenderItemColumn={this._renderDetailsFooterItemColumn}
        />
      </Sticky>      
      );   
    }
    else{
      return undefined
    }
  }

  private _onColumnClick = (event: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { sortedColumns } = this.state;
    let { sortedItems } = this.state;
    let isSortedDescending = column.isSortedDescending;


    // If we've sorted this column, flip it.
    if (column.isSorted) {
      isSortedDescending = !isSortedDescending;
    }

    // Sort the items.
    sortedItems = _copyAndSort(sortedItems, column.fieldName!, isSortedDescending);

    // Reset the items and columns to match the state.
    this.setState({
      sortedItems: sortedItems,
      sortedColumns: sortedColumns.map(col => {
        col.isSorted = col.key === column.key;

        if (col.isSorted) {
          col.isSortedDescending = isSortedDescending;
        }

        return col;
      }),
    });
  };

  private _renderDetailsFooterItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] = (item, index, column) => {
      if(column.name === 'SupplierName'){
        return (
            <b> Total </b>
        );
      }
      else if(column.name === 'SpendInDollar'){
        return (
            <b>{ this.props.spendInDollar }</b>
        );
      }
      else if(column.name === 'DiscountAmountInUSD'){
        return (
            <b>{ this.props.discountAmountInUSD }</b>
        );
      }
      else if(column.name === 'GrossAmount'){
        return (
            <b>{ this.props.grossAmount }</b>
        );
      }
      else{
        return undefined;
      }   
  };
}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T;
  return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}
