import { ISDUser } from '../models/SDProxy';

export const SDUsersMockData: ISDUser[] = [
    {
        UserId: 123,
        UserAlias: 'ABC',
        UserEmail: 'ABC@microsoft.com',
        UserRoleID: 6,
        RoleName: 'Service Delivery'
    },
    {
        UserId: 124,
        UserAlias: 'BCD',
        UserEmail: 'BCD@microsoft.com',
        UserRoleID: 6,
        RoleName: 'Service Delivery'
    },
    {
        UserId: 125,
        UserAlias: 'CDE',
        UserEmail: 'CDE@microsoft.com',
        UserRoleID: 6,
        RoleName: 'Service Delivery'
    },
    {
        UserId: 126,
        UserAlias: 'DEF',
        UserEmail: 'DEF@microsoft.com',
        UserRoleID: 6,
        RoleName: 'Service Delivery'
    },
    {
        UserId: 127,
        UserAlias: 'EFG',
        UserEmail: 'EFG@microsoft.com',
        UserRoleID: 6,
        RoleName: 'Service Delivery'
    },
    {
        UserId: 128,
        UserAlias: 'FGH',
        UserEmail: 'FGH@microsoft.com',
        UserRoleID: 6,
        RoleName: 'Service Delivery'
    },
    {
        UserId: 129,
        UserAlias: 'GHI',
        UserEmail: 'GHI@microsoft.com',
        UserRoleID: 6,
        RoleName: 'Service Delivery'
    },
    {
        UserId: 130,
        UserAlias: 'HIJ',
        UserEmail: 'HIJ@microsoft.com',
        UserRoleID: 6,
        RoleName: 'Service Delivery'
    },
]