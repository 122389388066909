import React from 'react';
import { Bar, BarChart, Legend, Tooltip, XAxis, YAxis,CartesianGrid,LabelList } from 'recharts';
import { ILegend } from '../../../shared/models';
import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
} from '@fluentui/react/lib/DetailsList';
import {DefaultTooltipContent} from 'recharts/lib/component/DefaultTooltipContent';
import { CSVLink } from 'react-csv';
import { IconButton, Spinner, SpinnerSize } from 'office-ui-fabric-react';




// Interface for budget chart props.
interface IBudgetChartProps {
  legendTypes: ILegend[];
  data: any[];
  suppliername:string
}
interface IBudgetChartState {
  legendTypes: ILegend[];
  data: any[];
}

const renderCustomizedLabel = (props) => {
  const {x, y, fill, value} = props;
  return (
    <g fill='windowText'>
    <text
      x={x}
      y={y}
      dy={-2}
      fontSize="10"
      fontFamily="sans-serif"
      // dominantBaseline="middle"
      // textAnchor="middle"
      color={fill}    
      >
      {value.toLocaleString('en')}{' '}
      </text>
    </g>
  );
};
const renderCustomizedLabelBudget = (props) => {
  const {x, y, fill, value} = props;
  return (
    <g fill='windowText'>
    <text
      x={x}
      y={y}
      dy={-10}
      fontSize="10"
      fontFamily="sans-serif"
      // dominantBaseline="middle"
      // textAnchor="middle"
      color={fill}    
      >
      {value.toLocaleString('en')}{' '}
      </text>
    </g>
  );
  }
const BudgetChartTooltip = props => {

  if (!props.active) {
    return null;
  }
  // creating the new payload.
  if (props.payload[0].payload.currencyCode !== 'USD') {
    let newPayload: any;
    if (props.payload.length > 1) {
      newPayload = [
        {
          name: `${props.payload[0].name} (USD)`,
          value: (props.payload[0].payload.constantDollarValue).toLocaleString('en'),
          color: props.payload[0].color,
        },

        {
          name: `${props.payload[1].name} (USD)`,
          value: (props.payload[1].payload.budgetConstantDollarValue).toLocaleString('en'),
          color: props.payload[1].color,
        },

        ...props.payload,
      ];
    } else {
      newPayload = [
        {
          name: `${props.payload[0].name} (USD)`,
          value: (props.payload[0].payload.constantDollarValue).toLocaleString('en'),
          color: props.payload[0].color,
        },

        ...props.payload,
      ];
    }
    return <DefaultTooltipContent {...props} payload={newPayload} />;
  } else {
    return <DefaultTooltipContent {...props} />;
  }
};

export default class BudgetChart extends React.Component<
  IBudgetChartProps,
  IBudgetChartState
> {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      legendTypes: this.props.legendTypes

    };
  }


  private headers = [
    { label: "Month", key: "month" },
    { label: "Actual", key: "Actual" },
    { label: "Budget", key: "Budget" },
    { label: "FY Forecast", key: "BudgetType" },
  ];
  // List of Colors for bar chart.
  private colorList = [
    { key: 'Accrual', colorValue: '#4299fF' },
    { key: 'Actual', colorValue: '#5F73B4' },
    { key: 'Forecast', colorValue: '#0278D1' },
    { key: 'Budget', colorValue: '#378452' },
  ];
  private _onRenderItemMonth = (item: any, index: number, column: IColumn): JSX.Element => {
      return (
        <span role="cell" aria-roledescription={item.month}>{item.month} {item.Year}</span>
    );
};
  private _onRenderItemColumnvalues = (item: any, index: number, column: IColumn): JSX.Element => {
    if (item.Actual !== undefined) {
        return (
            <span role="cell" aria-roledescription={item.Actual}>{item.Actual}</span>
        );
    }else{
      return (
        <span role="cell" aria-roledescription={'No Actual Value'}>No Actual Value</span>
    );
    }
};
private _onRenderItemFY21Q4Forecast = (item: any, index: number, column: IColumn): JSX.Element => {
  if (item.FY21Q4Forecast !== undefined) {
    return (
        <span role="cell" aria-roledescription={item.FY21Q4Forecast}>{item.FY21Q4Forecast}</span>
    );
  }else{
  return (
    <span role="cell" aria-roledescription={'No Forecast Value'}>No Forecast Value</span>
  );
  }
};

  private invoiceTableColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Month',
      fieldName: 'month',
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      onRender:this._onRenderItemMonth,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Actual',
      fieldName: 'Actual',
      minWidth: 70,
      maxWidth: 120,
      isResizable: true,
      onRender: this._onRenderItemColumnvalues,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Budget',
      fieldName: 'Budget',
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'FY Forecast',
      fieldName: 'FY Forecast',
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      data: 'string',
     onRender: this._onRenderItemFY21Q4Forecast,
      isPadded: true,
    },
  ];

  

  public render() {

    let exportData = [];
    for (let i = 0; i < this.props.data.length; i++) {
      exportData.push({
        month:this.props.data[i].month +" "+ this.props.data[i].Year,
        Actual:this.props.data[i].Actual !== undefined ? this.props.data[i].Actual : 'No Actual Value',
        Budget:this.props.data[i].Budget,
        BudgetType:this.props.data[i].BudgetType !== null ? this.props.data[i][this.props.data[i].BudgetType] : 'No Forecast Value'
      })
    }
    return (
         <div className="budgetchart-container_1">
      <BarChart
        width={1450}
        height={200}
        data={this.state.data}
        tabIndex={0}
        aria-label={'Budget Chart'}
        barGap= {40}
        barSize={25}
        aria-hidden="true"
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis padding={{ top: 20 }} />
        <Tooltip
          content={<BudgetChartTooltip data={this.state.data} tabIndex={0} />}
          tabIndex='0'
        />
        <Legend layout="horizontal" horizontalAlign="middle" tabIndex={0} />
        {this.props.legendTypes.map((x) => (
          <Bar
            key={x.displayName}
            name={x.displayName}
            stackId="a"
            dataKey={x.key}
            fill={this.getColor(x.key)}
            
          >
            <LabelList datakey={x.key} content={renderCustomizedLabel} position="left"></LabelList>
            </Bar>
        ))}
        <Bar
          key="Budget"
          name="Budget"
          dataKey="Budget"
          fill={this.getColor('Budget')}
          >
            <LabelList datakey="Budget" content={renderCustomizedLabelBudget} position='insideTopRight'></LabelList>
          </Bar>
      </BarChart>
        <CSVLink
        data={exportData}
        filename={`${this.props.suppliername}-GRAPH.csv`}
        headers={this.headers}
        >
        <IconButton
        iconProps={{ iconName: 'ExcelDocument' }}
        title="Export Graph Data to Excel"
        ariaLabel="Export Graph data to Excel"
        />
        </CSVLink>
      {/* <div className="sr-only">
        <DetailsList
          items={this.props.data}
          compact={false}
          columns={this.invoiceTableColumns}
          selectionMode={SelectionMode.none}
          setKey="set"
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          ariaLabelForGrid="Budget graph data resprented in table"
        />
        </div> */}
      </div>
    );
  }

  // Returns bar chart color based on key.
  private getColor = (trendKey: string) => {
    return this.colorList.find((x) => trendKey.includes(x.key)).colorValue;
  };
}
