import React from 'react';
import { CSVLink } from 'react-csv';
import { IInvoiceBillableUnitDetails } from '../../shared/models/InvoiceBillableUnitDetails';
import { IconButton, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import '../headcount/HeadCount.css';
import { IInvoiceBillableUnitsExport } from '../../shared/models/InvoiceBillableExport';
import { connect } from 'react-redux';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { isNullOrUndefined } from '../../shared/common/common-util';

export interface IExportCSVProps {
  billableUnitsData: IInvoiceBillableUnitDetails[];
  fileName: string;
  isEnable: number;
  showInvoiceViewLoader: boolean;
  showAccrualInitialLoader: boolean;
  isBillableUnits: boolean;
  invoicePeriod: string;
  isDisplayButtonClicked: boolean;
  isAllInvoiceLoader: boolean;
}

export interface IExportCSVState {
  headers: IDataHeaders[];
}

export interface IDataHeaders {
  label: string;
  key: string;
  IsHeadCount: boolean;
}

class ExportToExcel extends React.Component<IExportCSVProps, IExportCSVState> {
  constructor(props) {
    super(props);
    this.state = {
      headers: [
        {
          label: 'Fiscal Year Number',
          key: 'FiscalYearNumber',
          IsHeadCount: true,
        },
        { label: 'Engagement Id', key: 'EngagementId', IsHeadCount: true },
        {
          label: 'Supplier Engagement Id',
          key: 'SupplierEngagementId',
          IsHeadCount: true,
        },
        {
          label: 'Invoice Prefix',
          key: 'InvoicePrefix',
          IsHeadCount: false,
        },
        { label: 'Supplier Name', key: 'SupplierName', IsHeadCount: true },
        {
          label: 'Supplier Number',
          key: 'SupplierNumber',
          IsHeadCount: false,
        },
        { label: 'Supplier City', key: 'SupplierCity', IsHeadCount: true },
        {
          label: 'Supplier Country',
          key: 'SupplierCountry',
          IsHeadCount: true,
        },
        {
          label: 'Purchase Order',
          key: 'PurchaseOrder',
          IsHeadCount: false,
        },
        {
          label: 'Company Code',
          key: 'CompanyCode',
          IsHeadCount: false,
        },
        {
          label: 'Cost Center',
          key: 'CostCenter',
          IsHeadCount: false,
        },
        {
          label: 'Internal Order',
          key: 'InternalOrder',
          IsHeadCount: false,
        },
        {
          label: 'Account Code',
          key: 'AccountCode',
          IsHeadCount: false,
        },
        {
          label: 'Function Summary',
          key: 'FunctionSummary',
          IsHeadCount: true,
        },
        { label: 'Function Detail', key: 'FunctionDetail', IsHeadCount: true },
        {
          label: 'Business Segment',
          key: 'BusinessSegment',
          IsHeadCount: true,
        },
        { label: 'Exec PL', key: 'ExecPL', IsHeadCount: true },
        { label: 'Product', key: 'Product', IsHeadCount: true },
        { label: 'Product Group', key: 'ProductGroup', IsHeadCount: true },
        { label: 'Product SubType', key: 'ProductSubType', IsHeadCount: true },
        { label: 'Offering', key: 'Offering', IsHeadCount: true },
        { label: 'LOB', key: 'LOB', IsHeadCount: true },
        { label: 'Media Type', key: 'MediaType', IsHeadCount: true },
        { label: 'Media SubType', key: 'MediaSubType', IsHeadCount: true },
        { label: 'Support Tier', key: 'SupportTier', IsHeadCount: true },
        { label: 'Language', key: 'Language', IsHeadCount: true },
        { label: 'Invoice Period', key: 'InvoicePeriod', IsHeadCount: true },
        {
          label: 'Post Invoice Period',
          key: 'PostInvoicePeriod',
          IsHeadCount: true,
        },
        { label: 'Billable Units', key: 'BillableUnits', IsHeadCount: true },
        { label: 'Pricing Model', key: 'PricingModel', IsHeadCount: true },
        { label: 'Price', key: 'Price', IsHeadCount: true },
        { label: 'Total Amount', key: 'TotalAmount', IsHeadCount: true },
        { label: 'Local Currency', key: 'CurrencyCode', IsHeadCount: true },
        {
          label: 'Discount Percent',
          key: 'DiscountPercent',
          IsHeadCount: true,
        },
        { label: 'Discount Amount', key: 'DiscountAmount', IsHeadCount: true },
        { label: 'Gross Amount', key: 'GrossAmount', IsHeadCount: true },
        { label: 'Tax Percent', key: 'TaxPercent', IsHeadCount: true },
        { label: 'Tax Amount', key: 'TaxAmount', IsHeadCount: true },
        { label: 'Net Amount', key: 'NetAmount', IsHeadCount: true },
        { label: 'Total Amount ($)', key: 'TotalAmountCD', IsHeadCount: true },
        {
          label: 'Discount Amount ($)',
          key: 'DiscountAmountCD',
          IsHeadCount: true,
        },
        { label: 'Gross Amount ($)', key: 'GrossAmountCD', IsHeadCount: true },
        { label: 'Tax Amount ($)', key: 'TaxAmountCD', IsHeadCount: true },
        { label: 'Net Amount ($)', key: 'NetAmountCD', IsHeadCount: true },
        { label: 'Pricing Type', key: 'PricingType', IsHeadCount: true },
        { label: 'Pricing Level', key: 'PricingLevel', IsHeadCount: true },
        { label: 'Volume Min', key: 'VolumeMin', IsHeadCount: true },
        { label: 'Volume Max', key: 'VolumeMax', IsHeadCount: true },
        { label: 'NRC Type', key: 'NRCType', IsHeadCount: true },
        { label: 'NRC Category', key: 'NRCCategory', IsHeadCount: true },
        { label: 'NRC Description', key: 'NRCDescription', IsHeadCount: true },
        {
          label: 'IsPriorPeriodAdjustment',
          key: 'IsPriorPeriodAdjustment',
          IsHeadCount: true,
        },
        { label: 'IsNRC', key: 'IsNRC', IsHeadCount: true },
        { label: 'IsVolume', key: 'IsVolume', IsHeadCount: true },
        { label: 'SD1', key: 'SD1', IsHeadCount: true },
        { label: 'SD2', key: 'SD2', IsHeadCount: true },
        { label: 'Sales Region', key: 'SalesRegion', IsHeadCount: true },
        { label: 'PCY', key: 'PCY', IsHeadCount: true },
        { label: 'Sub-PCY', key: 'SubPCY', IsHeadCount: true },
        { label: 'IsCommerce', key: 'IsCommerce', IsHeadCount: true },
        { label: 'BusinessGroup', key: 'BusinessGroup', IsHeadCount: true }
      ],
    };
  }

  //Function to headers for Excel file for Billable units and Invoice view (all invoice data and supplier details)
  private getHeaders = () => {
    if (this.props.isBillableUnits)
      this.state.headers.filter((x) => x.IsHeadCount);
    else {
      if (this.props.isDisplayButtonClicked) return this.state.headers;
      else {
        let headers = [...this.state.headers];
        return headers;
      }
    }
  };

  public render() {
    const { billableUnitsData } = this.props;
    let exportData: IInvoiceBillableUnitsExport[] = [];
    for (let i = 0; i < billableUnitsData.length; i++) {
      exportData.push({
        EngagementId: billableUnitsData[i].EngagementId,
        FiscalYearNumber: billableUnitsData[i].FiscalYearNumber,
        SupplierEngagementId: billableUnitsData[i].SupplierEngagementId,
        FunctionSummary: billableUnitsData[i].FunctionSummary,
        FunctionDetail: billableUnitsData[i].FunctionDetail,
        SupplierName: billableUnitsData[i].SupplierName,
        SupplierCity: billableUnitsData[i].SupplierCity,
        SupplierCountry: billableUnitsData[i].SupplierCountry,
        BusinessSegment: billableUnitsData[i].BusinessSegment,
        ExecPL: billableUnitsData[i].ExecPL,
        Product: billableUnitsData[i].Product,
        ProductGroup: billableUnitsData[i].ProductGroup,
        ProductSubType: billableUnitsData[i].ProductSubType,
        Offering: billableUnitsData[i].Offering,
        LOB: billableUnitsData[i].LOB,
        MediaType: billableUnitsData[i].MediaType,
        MediaSubType: billableUnitsData[i].MediaSubType,
        SupportTier: billableUnitsData[i].SupportTier,
        Language: billableUnitsData[i].Language,
        TaxPercent: billableUnitsData[i].TaxPercent,
        BillableUnits: billableUnitsData[i].BillableUnits,
        PostInvoicePeriod: billableUnitsData[i].PostInvoicePeriod,
        InvoicePeriod: this.props.invoicePeriod,
        CurrencyCode: billableUnitsData[i].CurrencyCode,
        DiscountPercent: billableUnitsData[i].DiscountPercent,
        TotalAmount: billableUnitsData[i].TotalAmount,
        GrossAmount: billableUnitsData[i].GrossAmount,
        DiscountAmount: billableUnitsData[i].DiscountAmount,
        TaxAmount: billableUnitsData[i].TaxAmount,
        NetAmount: billableUnitsData[i].NetAmount,
        PricingType:
        isNullOrUndefined(billableUnitsData[i].PriceLine) || billableUnitsData[i].PriceLine.length === 0
            ? null
            : billableUnitsData[i].PriceLine[
                billableUnitsData[i].PriceLine.length - 1
              ].PricingType,
        PricingModel:
        isNullOrUndefined(billableUnitsData[i].PriceLine) || billableUnitsData[i].PriceLine.length === 0
            ? null
            : billableUnitsData[i].PriceLine[
                billableUnitsData[i].PriceLine.length - 1
              ].PricingModel,
        PricingLevel:
        isNullOrUndefined(billableUnitsData[i].PriceLine) || billableUnitsData[i].PriceLine.length === 0
            ? null
            : billableUnitsData[i].PriceLine[
                billableUnitsData[i].PriceLine.length - 1
              ].PricingLevel,
        Price:
        isNullOrUndefined(billableUnitsData[i].PriceLine) || billableUnitsData[i].PriceLine.length === 0
            ? null
            : billableUnitsData[i].PriceLine[
                billableUnitsData[i].PriceLine.length - 1
              ].Price,
        VolumeMin:
        isNullOrUndefined(billableUnitsData[i].PriceLine) || billableUnitsData[i].PriceLine.length === 0
            ? null
            : billableUnitsData[i].PriceLine[
                billableUnitsData[i].PriceLine.length - 1
              ].VolumeMin,
        VolumeMax:
        isNullOrUndefined(billableUnitsData[i].PriceLine) || billableUnitsData[i].PriceLine.length === 0
            ? null
            : billableUnitsData[i].PriceLine[
                billableUnitsData[i].PriceLine.length - 1
              ].VolumeMax,
        PricingModelId:
        isNullOrUndefined(billableUnitsData[i].PriceLine) || billableUnitsData[i].PriceLine.length === 0
            ? null
            : billableUnitsData[i].PriceLine[
                billableUnitsData[i].PriceLine.length - 1
              ].PricingModelId,
        PriceLineBillableUnits:
        isNullOrUndefined(billableUnitsData[i].PriceLine) || billableUnitsData[i].PriceLine.length === 0
            ? null
            : billableUnitsData[i].PriceLine[
                billableUnitsData[i].PriceLine.length - 1
              ].PriceLineBillableUnits,
        PriceLineAmount:
        isNullOrUndefined(billableUnitsData[i].PriceLine) || billableUnitsData[i].PriceLine.length === 0
            ? null
            : billableUnitsData[i].PriceLine[
                billableUnitsData[i].PriceLine.length - 1
              ].PriceLineAmount,
        NRCType: billableUnitsData[i].NRCType,
        NRCCategory: billableUnitsData[i].NRCCategory,
        NRCDescription: billableUnitsData[i].NRCDescription,
        IsPriorPeriodAdjustment: billableUnitsData[i].IsPriorPeriodAdjustment
          ? 'true'
          : 'false',
        IsNRC: billableUnitsData[i].IsNRC ? 'true' : 'false',
        IsVolume: billableUnitsData[i].IsVolume ? 'true' : 'false',
        SupplierNumber: billableUnitsData[i].SupplierNumber,
        CompanyCode: billableUnitsData[i].CompanyCode,
        TotalAmountCD: billableUnitsData[i].TotalAmountCD,
        GrossAmountCD: billableUnitsData[i].GrossAmountCD,
        DiscountAmountCD: billableUnitsData[i].DiscountAmountCD,
        TaxAmountCD: billableUnitsData[i].TaxAmountCD,
        NetAmountCD: billableUnitsData[i].NetAmountCD,
        InternalOrder: billableUnitsData[i].InternalOrder,
        PurchaseOrder: billableUnitsData[i].PurchaseOrder,
        CostCenter: billableUnitsData[i].CostCenter,
        AccountCode: billableUnitsData[i].AccountCode,
        InvoicePrefix: billableUnitsData[i].InvoicePrefix,
        SD1: billableUnitsData[i].SD1,
        SD2: billableUnitsData[i].SD2,
        NRCId: billableUnitsData[i].NRCId,
        SalesRegion: billableUnitsData[i].SalesRegion,
        PCY: billableUnitsData[i].PCY,
        SubPCY: billableUnitsData[i].SubPCY,
        IsCommerce: billableUnitsData[i].IsCommerce,
        BusinessGroup: billableUnitsData[i].BusinessGroup
      });
    }
      if (this.props.isEnable === 0) {
          return (
              this.props.showAccrualInitialLoader &&
              this.props.showInvoiceViewLoader) ||
              this.props.isAllInvoiceLoader ? (
                  <Spinner className="excelIcon" size={SpinnerSize.large} />
              ) : (
                    <IconButton
                        className="excelIcon"
                        disabled={this.props.isEnable === 0}
                        iconProps={{ iconName: 'ExcelDocument' }}
                        title="Export to Excel"
                        ariaLabel="Export to Excel"
                    />
              );
      }
      else {
          return (
              this.props.showAccrualInitialLoader &&
              this.props.showInvoiceViewLoader) ||
              this.props.isAllInvoiceLoader ? (
                  <Spinner className="excelIcon" size={SpinnerSize.large} />
              ) : (
                  <CSVLink
                      data={exportData}
                      filename={this.props.fileName}
                      headers={this.getHeaders()}
                  >
                  <FontIcon
                  aria-label="Export to Excel"
                  iconName="ExcelDocument"
                  className='fontIconExcel'
                  />
                    </CSVLink>
                );
      }
    }
}

const mapStateToProps = (state) => {
  return {
    showInvoiceViewLoader: state.invoiceView.isFetchingAccrualOrActual,
    showAccrualInitialLoader: state.invoiceView.accrualInitialLoader,
    isDisplayButtonClicked: state.invoiceView.isDisplayButtonClicked,
    isAllInvoiceLoader: state.invoiceView.isAllInvoiceLoader,
  };
};

export default connect(mapStateToProps, null)(ExportToExcel);
