import * as actionTypes from '../actions/actionTypes';

const initialState = {
    nrcLineCategories: [],
}

const reducer = (state = initialState, action) => {

        return Object.assign({}, state, {
            nrcLineCategories: action.nrcLineCategories,
        });
}


export default reducer;
