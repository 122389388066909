import * as React from 'react';
import { Image } from 'office-ui-fabric-react';
class EngagementDoc extends React.Component<{}> {
    render() {
        return (
            <div>
                <div className="Engagement">
                    <h2>Engagement and Supplier Engagement Management</h2>
                    <h3 >Table of Contents</h3>

                    <a href="#Eng1"><b>Engagement and Supplier Engagement Management and Workflow</b></a><br />
                    <a href="#Eng2"><b>Button enablement based on Roles</b></a><br />
                    <a href="#Eng3"><b>Create and Clone a New Engagement</b></a><br />
                    <a href="#Eng4"><b>Create and Clone New Supplier Engagement</b></a><br />
                    <a href="#Eng5"><b>Edit Engagement/Supplier Engagement Attributes</b></a><br />
                    <a href="#Eng6"><b>Searching for E/SE</b></a><br />
                    <a href="#Eng7"><b>History</b></a><br />
                    <a href="#Eng8"><b>Columns</b></a><br />
                    <a href="#Eng9"><b>Export</b></a><br />
                    <a href="#Eng10"><b>FAQ</b></a><br />

                    <h3 id="Eng1" tabIndex={0}>Engagement and Supplier Engagement Management and Workflow</h3>
                    <p>The Engagement module is the central management tool for creating&sbquo; editing and archiving Engagements and Supplier Engagements throughout their life cycle.
                            An <i>Engagement</i> record represents a line of support business defined by a number of unique attributes. This business is supported by one or more Suppliers&sbquo; represented by unique <i>Supplier Engagements</i>&sbquo; which are associated with the &ldquo;parent&rdquo; Engagement.
                            Another way to think of this relationship is the Engagement defines the body of work and the Supplier Engagement defines how and by whom the work is supported.
                        </p>
                    <p>Various actions with any given record are subject to controls enforced by a <i>workflow process</i>.
                     This process helps to ensure data integrity by requiring &ldquo;gatekeeper&rdquo; approvals for specific actions.
                     When a record is awaiting a pending approval it is locked in workflow; edits or changes are not possible until the pending actions are complete.
                         This workflow is a straight forward process passing along through three user roles &ndash; Supplier Support Manager <b>(SSM);</b> Business Analyst <b>(BA);</b> and Invoice Analyst <b>(IA)</b> as shown below.
                        </p>

                    <Image src={require('../../assets/images/EM_Interface.png')} style={{ height: 175, width: 700 }} alt="EM_Interface Image" />
                    <figcaption><b>Engagement Management Interface</b></figcaption><br />

                    <Image src={require('../../assets/images/ESM_Workflow.png')} style={{ height: 350, width: 700 }} alt="ESM_Workflow Image" />
                    <figcaption><b>Fig.1 Engagements and Supplier Engagements in Workflow</b></figcaption><br />

                    <Image src={require('../../assets/images/ESM_IdentificationGrid.png')} style={{ height: 350, width: 700 }} alt="ESM_IdentificationGrid Image" />
                    <figcaption><b>Fig.2</b></figcaption>

                    <p>
                        Salmon background color used for Identification of records in the grid.
                        Holding the cursor over a given record will display a pop up box indicating the details&sbquo; such as Pending Request Group&sbquo; E/SE Request ID&sbquo; and pending changes to the record&sbquo; Fig. 2.
                         </p>

                    <Image src={require('../../assets/images/ESM_PendingReq.png')} alt="ESM_PendingReq Image" />
                    <Image src={require('../../assets/images/ESM_CompletedReq.png')} alt="ESM_CompletedReq Image" />
                    <figcaption><b>Fig.3</b></figcaption><br />

                    <Image src={require('../../assets/images/ESM_StatusRB.png')} alt="ESM_StatusRB Icon" />
                    <figcaption><b>Fig.4</b></figcaption><br />

                    <p>Pending Requests are accessible from the Invoicing Tool Home page by clicking on the Pending Request tile&sbquo; Fig. 3.  Additionally&sbquo; searches are conducted or filtered based on record status.</p>

                    <p>There are 4 radio buttons to the right of the Additional Attributes Filter panel. These four preset searches allow accessing the following records:</p><br />
                    <p><b>All Approved -</b>Selected by default&sbquo; it will show only approved Engagements/Supplier Engagements.</p>
                    <p><b>All Pending - </b>Will show only new Engagements/Supplier Engagements and change requests.</p>
                    <p><b>Pending For Me -</b>Will show only new and change requests assigned to me.</p>
                    <p><b>Pending I Submitted -</b>Will show only new and change requests that I submitted.</p>
                    <p><b>Archived –</b> Will show archived records</p><br />

                    <h3 id="Eng2" tabIndex={0}>Button enablement based on Roles</h3>

                    <p><b>Create Engagement Button</b></p>
                    <ol>
                        <li>SSM, BA, SD can Create Engagements</li>
                    </ol>
                    <p><b>Clone Engagement Button</b></p>
                    <ol>
                        <li>SSM, BA, SD can Clone Engagements</li>
                    </ol>
                    <p><b>Edit Engagement Button</b></p>
                    <ol>
                        <li>SSM, BA, SD can Edit Engagements</li>
                    </ol>
                    <p><b>Save Engagement Button</b></p>
                    <ol>
                        <li>SSM, BA, SD can Save Engagements to next level</li>
                    </ol>
                    <p><b> Submit Engagement Button</b></p>
                    <ol>
                        <li>SSM,SD can Submit Engagements to next level</li>
                    </ol>
                    <p><b> Approve Engagement Button</b></p>
                    <ol>
                        <li>BA can Approve Engagements</li>
                    </ol>
                    <p><b>Reject Engagement Button</b></p>
                    <ol>
                        <li>BA can Reject Engagements</li>
                    </ol>
                    <p><b>Return Engagement Button</b></p>
                    <ol>
                        <li>BA can Return Engagements</li>
                    </ol>
                    <p><b>Create Supplier Engagement Button</b></p>
                    <ol>
                        <li>SSM can Create Supplier Engagements</li>
                    </ol>
                    <p><b>Clone Supplier Engagement Button</b></p>
                    <ol>
                        <li>SSM can Clone Supplier Engagements</li>
                    </ol>
                    <p><b>Edit Supplier Engagement Button</b></p>
                    <ol>
                        <li>SSM, BA, IA can Edit Supplier Engagements</li>
                    </ol>
                    <p><b>Save Supplier Engagement Button</b></p>
                    <ol>
                        <li>SSM, BA, IA can Save Supplier Engagements to next level</li>
                    </ol>
                    <p><b>Submit Supplier Engagement Button</b></p>
                    <ol>
                        <li>SSM, IA can Submit Supplier Engagements</li>
                    </ol>
                    <p><b>Approve Supplier Engagement Button</b></p>
                    <ol>
                        <li>BA, IM can Approve Supplier Engagements</li>
                    </ol>
                    <p><b>Reject Supplier Engagement Button</b></p>
                    <ol>
                        <li>SSM, BA, IA, IM can Reject Supplier Engagements</li>
                    </ol>
                    <p><b>Return Supplier Engagement Button</b></p>
                    <ol>
                        <li>BA, IM can Return Supplier Engagements</li>
                    </ol>

                    <h3 id="Eng3" tabIndex={0}>Create and Clone a New Engagement</h3>
                    <h4>Option 1 (Create) – Start with blank template</h4>
                    <ol>
                        <li>GTO Tool user navigates to the Engagements screen through the Home screen or by clicking the Engagement Menu.</li>
                        <li>Click the Create Engagements button.</li>
                        <li>Enter the number of Engagements to Create and Fiscal Year as current FY by default and click OK.</li>
                        <li>Within the Engagement grid&sbquo; scroll to the right and enter Attribute values for fields appropriate to a SSM.

                        <p>
                            These fields have headers in Purple or Green. At minimum&sbquo; fields with an asterisk (*)&sbquo; need to be populated before the record can be Saved.
                             Refer to the tab <i>Key symbol(Color and Symbol Legend)</i>.<br />
                            <b><i>Note</i> :</b>&ndash; Change the sales location of an Engagement then the Sales Region is updated automatically.Sales region field will be non-editable.
                      </p>    
                      </li>
                        <li>Click the Save button. </li>
                        <li>In the dialog box presented&sbquo; enter a Description that will help you and downstream reviewers identify your request. This field is required.</li>
                        <li>Enter a Comment if desired. This is an optional field.

                        <p>
                            <b>Note the Request Group number assigned.</b>
                            This number and Description will show up on your Home Page tile under My Pending Requests&sbquo; as well as the Home Page of downstream approvers as this group is submitted through the workflow.
                        </p>
                        </li>

                        <li>
                            Click the &lsquo;Submit&rsquo; button to send the request to your BA for approval.
                            Once approved&sbquo; your new Engagement is created and available for use.
                        </li>
                    </ol>
                    <h4>Option 2 (Clone) – Use an existing record and clone</h4>
                    <ol>
                        <li>Select the Engagement(s) you want to Clone.</li>
                        <li>Click the <i>Clone button</i>. In the dialog box, select the number of copies to make of the source Engagement. </li>
                        <li>New Engagement(s) are created with the same data as the original Engagement(s). </li>
                        <li>Click on the newly created records one at a time and make the desired edits to relevant columns.</li>
                        <li>Click the <i>Save</i> button to save to a Request Group and then <i>Submit</i> to your BA. </li>
                    </ol>

                    <h3 id="Eng4" tabIndex={0}>Create and Clone a New Supplier Engagement</h3>
                    <h4>Option 1 (Create) – Start with blank template</h4>
                    <ol>
                        <li>Select the Engagement you just created by clicking the row or checking the box.</li>
                        <li>Click the <i>Add button</i> to create new Supplier Engagement.</li>
                        <li>Enter the number of SEs you want to create for the selected Engagement and click OK.</li>
                        <li>Scroll to the right and enter Attribute values for fields for fields appropriate to a SSM.

                        <p>
                            These fields have headers in Purple or Green.  At minimum&sbquo; fields with an asterisk (*)&sbquo; need to be populated before the record can be Saved.
                          Refer to the tab <i>Key symbol (Color and Symbol Legend)</i>.
                        </p>
                        
                        <p>In exceptional scenario when user associates a Supplier&ndash;Engagement to a Future Pricing Group&sbquo; a confirmation box will pop-up prompting the user to reaffirm the update</p>
                        <p><b>Note &ndash;</b>Creation of SE can be done only by SSM&rsquo;s.</p>
                        <p>The Invoice Active field can be set to a future month&sbquo; Active or Inactive. </p>
                        <p>Engagement can be archived if all the SE associated to it are not in pending state. SE can be archived only after an Engagement is in approved state.</p>
                        
                        </li>
                        <li>Click the Save button.</li>
                        <li>Enter a description and system will create a new Pending Request Group.  Record this number.</li>
                        <li>Click the &lsquo;Submit&rsquo; button.</li>
                        <li>In the dialog box&sbquo; select your BA from the Approver dropdown to send the request for approval. </li>
                        <li>Once approved by BA&sbquo; a new SE is created and available for use. </li>
                    </ol>

                    <p><i>Note: For the BA&sbquo; a dialog will display a pull down to select the IA who can enter/verify the financial attributes (IO&sbquo; CC&sbquo; AC&sbquo; PO)</i>.</p>

                    <h4>Option 2 (Clone) – Use an existing record and clone</h4>
                    <ol>
                        <li>Select the Supplier Engagement(s) you want to Clone</li>
                        <li>Click the <i>Clone</i> button. In the pop-up dialog box&sbquo; select the number of copies to make of the source Supplier engagement. 

                        <p><i><b>Tip: When cloning a SE&sbquo; you can also clone the related Engagement to save a step.</b></i></p>
                        </li>
                        <li>New SE(s) are created with the same data as the original SE(s).</li>
                        <li>You can now edit the newly created records in the grid or using the Edit (bulk) button to apply changes to the selected vendor engagements.</li>
                        <li><i>Save</i> to a Request Group and then <i>Submit</i> to your BA for approval. </li>
                    </ol>
                    <p><b>Note –</b>Clone of SE can be done only by SSM&rsquo;s.</p>
                    <p>the Invoice Active field can be set to a future month&sbquo; Active or Inactive.</p>

                    <h3 id="Eng5" tabIndex={0}>Edit Engagement/Supplier Engagement Attributes</h3>
                    <h4>Option 1 (Single record edit) – Edit within the grid</h4>
                    <ol>
                        <li>Select the record you want to update by clicking on it. Go to the field you want to change and select from the dropdown list.</li>
                        <li>The changed fields will be highlighted in yellow.</li>
                        <li>Click the Save button and save to a New or Existing Request Group</li>
                        <li>If CCO1/CCO2/SD1 /SD2 of a SE  are the only fields updated&sbquo; then the request will be submitted to IA for approval.</li>
                        <li>If Pricing Group/Contract Description are updated then the request shall be auto-approved and notified to IA.</li>
                        <li>If Budget Active / Invoice Active are updated then the request shall be submitted to BA for approval and post BA approval it shall be auto-approved and notified to IA.</li>
                    </ol>
                    <p>
                        <b>Note:</b><br />
                        In exceptional scenario when user associates a Supplier &ndash;Engagement to a Future Pricing Group&sbquo; a confirmation box will pop-up prompting the user to reaffirm the update.
                        Change the sales location of an Engagement then the Sales Region will be automatically updated. Sales region field will be non-editable.
                        </p>
                    <h4>Option 2 (Bulk record edit) – Edit using the Edit button and pop-up dialog window</h4>
                    <ol>
                        <li> Select the records you want to update by clicking on them&sbquo; or the select all box in the upper left corner of the grid.  </li>
                        <li>
                            Click the Edit button.  Note &ndash; with multiple records selected&sbquo; the edit dialog will display all fields as empty.
                            You only need to provide input for those fields you wish to change&sbquo; all other fields will be unchanged in the grid.
                           </li>
                        <li>In the field(s) you want to change&sbquo; click on the field once&sbquo; then make your selection from the dropdown list.</li>
                        <li>Click Ok and you&rsquo;ll return to the grid view and the changed fields will be highlighted in yellow.</li>
                        <li>Click the Save button and save to a Request Group.</li>
                        <li>If CCO1/ CCO2/SD1/SD2 of a SE  are the only fields updated&sbquo; then the request will be submitted to IA for approval.</li>
                        <li>If Pricing Group/Contract Description are updated then the request shall be auto-approved and notified to IA.</li>
                        <li>If Budget Active/ Invoice Active are updated then the request shall be submitted to BA for approval and post BA approval it shall be auto-approved and notified to IA.</li>
                    </ol>
                    <p>
                        <b>Note :</b><br />
                        In exceptional scenario when user associates a Vendor&ndash;Engagement to a Future Pricing Group&sbquo; a confirmation box will pop&ndash;up prompting the user to reaffirm the update.
                            Change the sales location of an Engagement then the Sales Region will be automatically updated. Sales region field will be non&ndash;editable.<br />
                        <h3 id="Eng6" tabIndex={0}>Searching for E/SE</h3><br />
                        <h4>Option 1 – Quick Search</h4><br />
                    </p>

                    <Image src={require('../../assets/images/ESE_QuickSearch.png')} style={{ height: 100, width: 750 }} alt="ESE_QuickSearch Image" />
                    <figcaption>Fig.5</figcaption>

                    <ol>
                        <li>Navigate to the Engagement menu&sbquo; from the Quick Search dropdown select Engagement (or Supplier Engagement). </li>
                        <li>Enter the Engagement/Supplier Engagement ID(s) in the Quick Search window. </li>
                        <li>Click the Quick Search Button&sbquo; the Engagement/Vendor Engagement is found and displayed in the grid below.</li>
                    </ol>

                    <h4>Option 2 – Attribute Filters</h4>
                    <ol>
                        <li> Navigate to the Engagement menu&sbquo; select Fiscal Year (defaults to current FY)&sbquo; and Business Org.</li>
                        <li>Under <i>Additional Filter Attributes > Engagement Attributes</i>&sbquo; select the attribute to filter by and choose the desired values in the Attribute Values list, Fig. 5.</li>
                    </ol>
                    <p>The <i>Filter Definition</i> window to the right will display the selected attributes. </p>
                    <p>At the bottom of this box&sbquo; the total number of both Engagements and Vendor Engagements matching the selected filters are displayed.The grid will return a <b>maximum of 200 viewable records;</b> a record count above this value will be displayed in red.</p>

                    <Image src={require('../../assets/images/ESE_AttriFilter.png')} style={{ height: 200, width: 750 }} alt="ESE_AttriFilter Image" />
                    <figcaption>Fig.6</figcaption>

                    <p>
                        There are 4 radio buttons to the right of the Additional Attributes Filter panel.
                        These four preset searches allow you to readily access the following records:
                            </p>

                    <p><b>All Approved &ndash;</b>Selected by default&sbquo;it will show only approved Engagements/Supplier Engagements.</p>
                    <p><b>All Pending&ndash;</b>Will show only new Engagements/Supplier Engagements and change requests.</p>
                    <p><b>Pending For Me &ndash;</b>Will show only new and change requests assigned to me.</p>
                    <p><b>Pending I Submitted &ndash;</b>Will show only new and change requests that I submitted.</p>
                    <p>Select a preset and click the <i>Retrieve</i> button to display the desired records.<br />
                        <p><i>Note: More than one attribute can be used for filtering by selecting additional attributes and values.</i></p>

                        <h4>Option 3 – Saved Searches</h4>
                        <p>If you frequently look up Engagements/Supplier Engagements by filtering on certain attributes&sbquo; you can save the filters as a <i>Saved Filters</i>. </p>
                        <p>To save a filter&sbquo; once you have made all your filter selections:</p>
                        <ol>
                            <li> Click on the <i>Saved Filters</i> button. </li>
                            <li>In the pop-up dialog&sbquo; click on <i>Save As New Filter</i>. </li>
                            <li>Give it a name in the dialog box&sbquo; Fig. 6.</li>
                        </ol>
                        <p>The filter is now saved for quick access in the future. It will be available on your Home Page > Engagement tile&sbquo; Fig. 7.</p>
                        <table aria-label="Saved Search Fig Table">
                            {/* cellspacing="40"> */}
                            <tr>
                                <td><Image src={require('../../assets/images/ESE_SavedS1.png')} style={{ height: 250 }} alt="ESE_SavedS1 Image" /></td>

                                <td> <Image src={require('../../assets/images/ESE_SavedS2.png')} style={{ height: 225, width: 300 }} alt="ESE_SavedS2 Image" /></td>
                            </tr>
                            <tr>
                                <td align="center">Fig.7</td>
                                <td align="center">Fig.8</td>
                            </tr>
                        </table>

                        <p><b>Engagement </b></p>

                        <Image src={require('../../assets/images/ESE_Engagement.png')} style={{ height: 100, width: 1000 }} alt="ESE_Engagement Image" />
                        <figcaption>Fig.9</figcaption>

                        <p><b>Supplier Engagement</b></p>

                        <Image src={require('../../assets/images/ESE_SuppEngagement.png')} style={{ height: 100, width: 1000 }} alt="ESE_SuppEngagement Image" />
                        <figcaption>Fig.10</figcaption>

                        <p><b>Key tab</b></p>

                        <Image src={require('../../assets/images/ESE_KeyTab.png')} style={{ height: 350, width: 600 }} alt="ESE_KeyTab Image" />
                        <figcaption>Fig.11</figcaption>


                        <p><b>Button Controls</b>&ndash;(left to right, Figures 8 and 9) </p>
                        <p>
                            <b>Create Engagements</b>&ndash;opens a dialog to specify number of engagements to create in a specified Fiscal Year.
                            A blank template is presented in the grid allowing the user to input from pull downs into each cell.(SSM&sbquo;BA&sbquo;SD)
                               </p>
                        <p>
                            <b>Create Supplier Engagements</b>&ndash;opens a dialog to specify number of Supplier engagements to create in a specified Fiscal Year.
                            A blank template is presented in the grid allowing the user to input from pull downs into each cell.(SSM)
                                </p>
                        <p><b>Clone</b>&ndash; creates one or more copies of an Engagement/Supplier Engagement.  (SSM, BA, SD – E and SSM - SE))</p>
                        <p><b>Save</b>&ndash; the first step to commit any new record or record changes to work flow by saving to a Pending Request Group.  This command is used for successive changes throughout work flow.  (SSM, BA, IA).</p>
                        <p><b>Submit</b>&ndash;used to send new record or record changes further along the progressive workflow. (SSM, SD – Engagement and SSM, IA - SE)..</p>
                        <p><b>Approve</b>&ndash;used to approve a new record or record changes.  (BA,IM).</p>
                        <p><b>Reject</b>&ndash;used to reject (delete) a request.  (SSM, BA, SD – Engagement and SSM, BA, IA, IM - SE). </p>
                        <p><b>Return</b>&ndash;used to return a requested change to the requesting user with recommended or required changes.  (BA) </p>
                        <p><b>Edit</b>  &lsquo;the bulk edit&rsaquo; tool; allows editing all selected records at one time.(SSM, BA, IA).</p>
                        <p><b>Pricing</b>&ndash;SE only &ndash; opens Pricing module for the selected VE.  (SSM, BA; IA Read-Only). </p>
                        <p><b>History</b> view all committed or approved changes post workflow&sbquo;(SSM, BA, IA).</p>
                        <p><b>Changes&ndash;</b>while in workflow only&sbquo; view pending changes and original values.(SSM, BA, IA).</p>
                        <p><b>Forward</b>&ndash;(SE only) used to forward a request to another IA user in workflow. (IA).</p>
                        <p><b>Columns</b>&ndash;used to choose the attribute columns visible in the grid and arrange their order.(SSM, BA, SD – Engagement and SSM, BA, IA for SE).</p>
                        <p><b>Export</b>&ndash;used to export the selected records to an Excel file. Max. 200 records.  (SSM, BA).</p>
                        <p><b>Clear</b>&ndash; used to clear all grid filters. (SSM, BA, IA).</p>
                        <p><b>Active flags</b>&ndash;for Invoicing, Budget, Archive, and Synch to Year, Fig. 11</p>

                        <Image src={require('../../assets/images/ESE_ActFlag.png')} alt="ESE_ActFlag Image" />
                        <figcaption><b>Fig.12</b></figcaption>

                        <p><b>Invoice Active</b>&ndash;indicates the record is available for invoicing.</p>
                        <p><b>Budget Active</b>&ndash;indicates the record is available for budget forecasting.</p>
                        <p><b>Archive</b>&ndash;renders the record inactive and it will have no further visibility in the UI or Reporting.  </p>
                        <p>
                            <b>Synch to Year</b>&ndash;Indicates records where updates to attributes will flow into the listed fiscal year.<br />
                            Note – direct updates to a higher fiscal year will break the synch with earlier fiscal years.
                                </p>

                        <h3 id="Eng7" tabIndex={0}>History</h3>

                        <p><b>Steps to view Engagement History</b></p>
                        <ol>
                            <li>Select an engagement</li>
                            <li>Click on Actions drop-down</li>
                            <li>Click on History button</li>
                        </ol>

                        <Image src={require('../../assets/images/ESE_History.jpg')} alt="ESE_History Image" />

                        <h3> Enhanced View :</h3>
                        <Image src={require('../../assets/images/ESE_EnhancedView.jpg')} style={{ height: 350, width: 820 }} alt="ESE_EnhancedView Image" />

                        <h3>Grid View :</h3>
                        <Image src={require('../../assets/images/ESE_GridView.jpg')} style={{ height: 350, width: 820 }} alt="ESE_GridView Image" />

                        <p><b>Steps to view Supplier Engagement History</b></p>
                        <ol>
                            <li>Search for a Supplier Engagement</li>
                            <li>Select the Supplier Engagement</li>
                            <li>Click on Actions drop-down</li>
                            <li>Click on History button</li>
                        </ol>

                        <h3>Enhanced View :</h3>
                        <Image src={require('../../assets/images/ESE_EnhancedViewSE.jpg')} style={{ height: 350, width: 820 }} alt="ESE_EnhancedViewSE Image" />

                        <h3>Grid View :</h3>
                        <Image src={require('../../assets/images/ESE_GridViewSE.jpg')} style={{ height: 350, width: 820 }} alt="ESE_GridViewSE Image" />
                        <h3 id="Eng8" tabIndex={0}>Columns</h3>
                        <p>Engagement / Supplier Engagement</p>
                        <p><b>Steps to modify columns in the grid</b></p>
                        <ol>
                            <li>Select the Engagement/Supplier Engagement</li>
                            <li>Click on Actions drop-down</li>
                            <li>Click on Columns button
                            <Image src={require('../../assets/images/ESE_ColumnsESE3.jpg')} style={{ height: 350, width: 820 }} alt="ESE_ColumnsESE3 Image" />
                            </li>
                            <li>Select or un-select the columns that you do not wish to view in the grid.
                            <Image src={require('../../assets/images/ESE_ColumnsESE4.jpg')} style={{ height: 450, width: 700 }} alt="ESE_ColumnsESE4 Image" />
                            </li>
                            <li>Click on Ok</li>
                        </ol>
                        <h3 id="Eng9" tabIndex={0}>Export</h3>
                        <p>Export feature helps you to export the data in excel sheet.</p>
                        <p><b>Steps to export Engagement data</b></p>
                        <ol>
                            <li>Select an Engagement</li>
                            <li>Click on Actions drop-down</li>
                            <li>Click on Export button
                            <Image src={require('../../assets/images/ESE_Export3.jpg')} style={{ height: 350, width: 820 }} alt="ESE_Export3 Image" />
                            </li>
                            <li>Open the downloaded excel sheet</li>
                        </ol>
                        <Image src={require('../../assets/images/ESE_Export4.jpg')} style={{ height: 100, width: 820 }} alt="ESE_Export4 Image" />

                        <p><b> Steps to export Supplier Engagement data</b></p>
                        <ol>
                            <li>Select a Supplier Engagement</li>
                            <li>Click on Actions drop-down</li>
                            <li>Click on Export button
                            <Image src={require('../../assets/images/ESE_ExportSE3.jpg')} style={{ height: 350, width: 820 }} alt="ESE_ExportSE3 Image" />
                            </li>
                            <li>Open the downloaded excel sheet.</li>
                        </ol>

                        <Image src={require('../../assets/images/ESE_ExportSE4.jpg')} style={{ height: 100, width: 820 }} alt="ESE_ExportSE4 Image" />

                        <h3 id="Eng10" tabIndex={0}>FAQ</h3>
                        <h4>What Business Org do I select?</h4>
                        <p>The Invoicing Tool is configured to support the taxonomy requirements of multiple business drivers.</p>
                        <p>Records are organized based on two general categories; Consumer&sbquo; CSS (Customer Service and Support)&sbquo;</p>

                        <h4>Why are some of the values in the attribute drop-downs gold?</h4>
                        <p>Values in the attribute drop&ndash;downs that have a green font are not currently active in the system and cannot be used on new Engagements.</p>

                        <h4>When I&rsquo;m creating a new Supplier Engagement I don&rsquo;t see any Contract Description available in the ContractDescription.What should I do?</h4>
                        <p>If the correct Supplier and Supplier Site are accessible in the SE creation process and the appropriate Contract Description is not available in the pull&ndash;down you must add the contract description in the Pricing Module</p>
                        <ol>
                            <li>Click on the Pricing tile.</li>
                            <li>Click on Add or Modify Supplier Contracts.</li>
                            <li>Click on Add a New Supplier Contract.</li>
                            <li>Select the appropriate Supplier Name/Number</li>
                            <li>Type in the Contract Description in the field provided.</li>
                            <li>Select a Pricing Owner and click OK. </li>
                        </ol>
                        <p>The Supplier and Contract Description have now been associated in the system and Contract Description will now be available when creating new Supplier Engagements. </p>

                        <h4>How can I look up Pricing assigned to a SE?</h4>
                        <ol>
                            <li>In the Engagement screen, pull up the desired SEID(s)</li>
                            <li>Expand the Engagements and Supplier Engagements.</li>
                            <li>On expansion exact Contract Description and Pricing Group will be shown in the grid.</li>
                        </ol>

                        <h4>How do I look up previous changes made to the Engagement or Supplier Engagement?</h4>
                        <ol>
                            <li>In the Engagement screen, pull up the desired EID(s) or SEID(s)</li>
                            <li>Select a single EID or SEID. </li>
                            <li>Click the History button under Actions drop-down.This will display a grid with details on the current and previous values. </li>
                        </ol>

                        <h4>How can I look up a Pending record for details like who it is pending with and what changes are being made?</h4>
                        <ol>
                            <li>If you have the Pending Request Group ID&sbquo; Engagement Request ID or the Supplier Engagement Request ID&sbquo; then you can look up by these Request IDs in Quick Search.</li>
                            <li>If you don&rsquo;t have any of the Request IDs indicated above&sbquo; then you have to first find that and then look up the pending records. You can do this by –</li>
                            <li>Hovering over the record and noting the Engagement Request ID (if it is an Engagement) or the Supplier Engagement Request ID (if it is a Supplier Engagement).</li>
                            <li>Then going to Quick Search&sbquo; selecting the appropriate filter from the dropdown (Engagement Request ID or the Vendor Engagement Request ID) and typing that ID.</li>
                            <li>Now the grid will display the pending record and who it is pending with&sbquo; along with the pending changes. There is also the Changes button that will show the change details in a grid&ndash;like format.</li>
                        </ol>

                        <h4>There are too many E/SE Attribute columns. How can I keep only the fields I want in the grid?</h4>
                        <p>While in the Engagement and Supplier Engagement tab&sbquo; click on the Columns button to de&ndash;select or select the desired fields.</p>

                        <h4>How many records are displayed per page in the grid and are there limitations to bulk edits or exports?</h4>
                        <ol>
                            <li>The search results grid displays 200 records per page.</li>
                            <li>Additional records will be displayed on successive pages, 200 records maximum per page.  </li>
                            <li>Note &ndash;the Export command is also limited to 200 records per page.Exporting more than 200 records is accomplished by advancing to the next page and initiating another export.  </li>
                        </ol>

                        <h4>As a BA/IA when I access a pending request&sbquo; the Submit/Approve/Return/Reject buttons are grayed out. Why is that?</h4>
                        <ol>
                            <li>This is a result of selecting all the records in the Pending Request Group but where each record is in a different pending state e.g. some may be pending with the BA&sbquo; some with the IA&sbquo; and some still with the VAM.</li>
                            <li>To work around this problem, look up the Pending With field and filter on only those records that are pending with you. Then the relevant action buttons will get enabled and you can proceed. Alternately&sbquo; you can select one record at a time and action them as allowed and needed.</li>
                        </ol>

                        <h4>What is the criteria to archive an Engagement?</h4>
                        <ol>
                            <li>Archival of an engagement is direct process where in the user need not go through the cycle of submission and approval. User can only archive an engagement, which is in approved state and not having any active supplier engagements associated to it.</li>
                        </ol>

                        <h4>What is the criteria to Archive a Supplier Engagement?</h4>
                        <ol>
                            <li>
                                Archival of a supplier engagement is direct process where in the user need not go through the cycle of submission and approval.
                                User can only archive a supplier engagement which is in approved state and whose &ldquo;Invoice Active&rdquo; and &ldquo;Budget Active&rdquo; flag is set to &lsquo;false&rsquo; or &lsquo;Not Active&rsquo;.
                                    </li>
                        </ol>

                        <h4>What is mean by Lockdown period? How it works?</h4>
                        <ol>
                            <li>
                                Lockdown is period is specific time window of a month where in the engagement and supplier engagements are not allowed to be changed.
                                Therefore&sbquo; as compared to normal un-locked state the users are not allowed to submit or approve any new records or make any changes to the previous records. Only an IA and IM have permissions to un-lock the approval and allow submission during the lockdown period.
                                     </li>
                        </ol>

                        <h4>Where can I see full view of Engagement&sbquo; supplier Engagement & Pricing?</h4>
                        <ol>
                            <li>
                                Full view of Engagement&sbquo; Supplier Engagement and Pricing is viewable for a particular Engagement by expanding the Engagement and Supplier Engagement associated to it.
                                    </li>
                        </ol>
                    </p>
                </div >
            </div>

        );
    }
}
export default EngagementDoc;