import gtoApiInstance, {
  gtoMsalApiFetch,
} from '../../shared/http/GtoApiNew.axios';
import { Routes } from '../../shared/models';
import { v4 as uuidv4 } from 'uuid';
import * as actionTypes from './actionTypes';

export const setMyOrderFeature = (enabled: boolean) => {
  return {
    type: actionTypes.SET_MYORDER_FEATURE,
    enabled,
  };
};

export const setMyOrder2Feature = (enabled: boolean) => {
  return {
    type: actionTypes.SET_MYORDER2_FEATURE,
    enabled,
  };
};

/*--------------------------------------THUNK ACTION CREATORS-----------------------------------*/

export const getFeatureStatus = (feature: string) => {
  return dispatch => {
    const headers: object = {
      'X-CorrelationId': uuidv4(),
      SubCorrelationKey: uuidv4(),
    };
    gtoMsalApiFetch(
      gtoApiInstance.get,
      `${Routes.FetchFeatureStatus}?featureName=${feature}`,
      { headers }
    )
      .then((response: any) => {
        if (feature === 'myorder') {
          dispatch(setMyOrderFeature(response.data));
        } else if (feature === 'myorder2') {
          dispatch(setMyOrder2Feature(response.data));
        }
      })

      .catch(() => {
        alert(`Unable to fetch Feature status for feature ${feature}.`);
      });
  };
};
