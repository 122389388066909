import * as actionTypes from '../actions/actionTypes';

// Initialiing the exisitng Contracts state to an empty array.
const initialState = {
  exsistingContracts: [],
  
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_EXISTING_CONTRACTS:
      return Object.assign({}, state, {
        exsistingContracts: action.existingContracts,
      });

    default:
      return state;
  }
};

export default reducer;