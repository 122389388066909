import { ISupplierInvoice } from '../models/SupplierAndInvoicePrefix.model';

export const SupplierAndInvociePrefixMockData: ISupplierInvoice[] = [
  {
    SupplierId: 60,
    SupplierName: 'Alorica',
    CompanyCode: 1010,
    SupplierNumber: 2261626,
    InvoicePrefix: 'PRC01',
  },
  {
    SupplierId: 17,
    SupplierName: 'Arvato',
    CompanyCode: 1010,
    SupplierNumber: 2078620,
    InvoicePrefix: 'ARV01',
  },
  {
    SupplierId: 17,
    SupplierName: 'Arvato',
    CompanyCode: 1010,
    SupplierNumber: 2078620,
    InvoicePrefix: 'ARV02',
  },
  {
    SupplierId: 194,
    SupplierName: 'Arvato',
    CompanyCode: 1062,
    SupplierNumber: 2284256,
    InvoicePrefix: 'ARV05',
  },
  {
    SupplierId: 69,
    SupplierName: 'Arvato',
    CompanyCode: 1062,
    SupplierNumber: 2037878,
    InvoicePrefix: 'ARV06',
  },
];
