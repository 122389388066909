import * as actionTypes from '../actions/actionTypes';

// Initialiing the suppliers state to an empty array.
const initialState = {
  suppliersList: [],
  supplierTags: [],
  suppliersForCurrentUserList: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SUPPLIERS:
      return Object.assign({}, state, {
        suppliersList: action.suppliers,
      });
    case actionTypes.SET_SUPPLIER_TAG_LIST:
      return Object.assign({}, state, {
        supplierTags: action.tags,
      });

    case actionTypes.SET_SUPPLIERS_FOR_USER:
      return Object.assign({}, state, {
        suppliersForCurrentUserList: action.suppliers,
      });
  }
  return state;
};

export default reducer;
