import * as actionTypes from '../actions/actionTypes';

const initialState = {
  supplierInvoiceList: [],
  invoiceAccrualActualDetails: [],
  isErrorMessageInvoice: false,
  isFetchingAccrualOrActual: true,
  isFetchingInvoiceVariance: true,
  accrualInitialLoader: false,
  showInvoiceInfo: false,
  emptyBillableUnits: [],
  invoiceState: '',
  sdmLockedOn: '',
  modificationStartOn: '',
  actualsPostOn: '',
  accrualsPostOn: '',
  isFetchingTotalDetails: false,
  billableUnitsAllSuppliers: [],
  amountDetails: {
    totalAmount: 0,
    grossAmount: 0,
    discountAmount: 0,
    taxAmount: 0,
    netAmount: 0,
  },
  dataForActualAccrualCall: {
    CumMonth: 0,
    SupplierId: 0,
    InvoicePrefix: '',
    CompanyCode: '',
    SupplierNumber: 0,
  },
  supplierVariance: [],
  isDisplayButtonClicked: false,
  allInvoiceAccrualsData: [],
  allInvoiceActualsData: [],
  isAllInvoiceLoader: false,
  isActionChanged: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SUPPLIER_INVOICE:
      return Object.assign({}, state, {
        supplierInvoiceList: action.supplierInvoice,
      });
    case actionTypes.SET_INVOICE_ACCRUAL_ACTUAL_DETAILS:
      return Object.assign({}, state, {
        invoiceAccrualActualDetails: action.invoiceAccrualActualDetails,
      });
    case actionTypes.SHOW_ERROR_MESSAGE_INVOICE:
      return Object.assign({}, state, { isErrorMessageInvoice: true });
    case actionTypes.SHOW_ERROR_MESSAGE_INVOICE_COMPLETE:
      return Object.assign({}, state, { isErrorMessageInvoice: false });
    case actionTypes.REQUEST_INVOICE_DETAILS:
      return Object.assign({}, state, { isFetchingAccrualOrActual: true });
    case actionTypes.REQUEST_INVOICE_DETAILS_COMPLETE:
      return Object.assign({}, state, { isFetchingAccrualOrActual: false });
    case actionTypes.REQUEST_INVOICE_VARIANCE_DETAILS:
      return Object.assign({}, state, { isFetchingInvoiceVariance: true });
    case actionTypes.REQUEST_INVOICE_VARIANCE_DETAILS_COMPLETE:
      return Object.assign({}, state, { isFetchingInvoiceVariance: false });
    case actionTypes.ACCRUALS_INITIAL_LOADER:
      return Object.assign({}, state, { accrualInitialLoader: false });
    case actionTypes.ACCRUALS_INITIAL_LOADER_COMPLETE:
      return Object.assign({}, state, { accrualInitialLoader: true });
    case actionTypes.SHOW_INVOICE_INFO:
      return Object.assign({}, state, { showInvoiceInfo: true });
    case actionTypes.TOTAL_CALCULATION_DETAILS:
      return Object.assign({}, state, { amountDetails: action.amountDetails });
    case actionTypes.SET_EMPTY_BILLABLE_UNITS:
      return Object.assign({}, state, {
        emptyBillableUnits: action.emptyBillableUnits,
      });
    case actionTypes.GET_SUPPLIER_INVOICE_STATE:
      return Object.assign({}, state, { invoiceState: action.invoiceState });
    case actionTypes.GET_SDM_Locked_DateTime:
      return Object.assign({}, state, { sdmLockedOn: action.sdmLockedOn });
    case actionTypes.GET_Month_Start_DateTime:
      return Object.assign({}, state, { modificationStartOn: action.modificationStartOn });
    case actionTypes.GET_Actuals_DateTime:
      return Object.assign({}, state, { actualsPostOn: action.actualsPostOn });
    case actionTypes.GET_Accruals_DateTime:
      return Object.assign({}, state, { accrualsPostOn: action.accrualsPostOn });
    case actionTypes.INITAL_CALCULATION_VALUES:
      return Object.assign({}, state, { amountDetails: action.initialAmounts });
    case actionTypes.GET_BILLABLEUNITS_FOR_ALL_SUPPLIERS:
      return Object.assign({}, state, {
        billableUnitsAllSuppliers: action.billableUnitsAllSuppliers,
      });
    case actionTypes.GET_SUPPLIER_VARIANCE:
      return Object.assign({}, state, {
        supplierVariance: action.supplierVariance,
      });
    case actionTypes.SET_IS_DISPLAY_BUTTON_CLICKED:
      return Object.assign({}, state, {
        isDisplayButtonClicked: action.isDisplayButtonClicked,
      });
    case actionTypes.GET_DATA_FOR_ACTUAL_ACCRUAL_CALL:
      return Object.assign({}, state, {
        dataForActualAccrualCall: action.dataForActualAccrualCall,
      });
    case actionTypes.GET_ALL_INVOICE_ACCRUALS:
      return Object.assign({}, state, {
        allInvoiceAccrualsData: action.allInvoiceAccrualsData,
      });
    case actionTypes.GET_ALL_INVOICE_ACTUALS:
      return Object.assign({}, state, {
        allInvoiceActualsData: action.allInvoiceActualsData,
      });
    case actionTypes.IS_ALL_INVOICE_LOADER:
      return Object.assign({}, state, {
        isAllInvoiceLoader: action.isAllInvoiceLoader,
      });
    case actionTypes.SET_IS_ACTION_CHANGED:
      return Object.assign({}, state, {
        isActionChanged: action.isActionChanged,
      });
    default:
      return state;
  }
};

export default reducer;
