import React from 'react';
import './Footer.css'
import logo from '../../assets/images/MicrosoftLogo_new.png';
class Footer extends React.Component<
  {
  },
  {}
  >{
  public render(): JSX.Element {
    return (
      <footer>
        <a style = {{padding: 8, fontSize: 12}} href="https://go.microsoft.com/fwlink/?LinkId=518021" target="_blank" rel="noopener noreferrer"> Microsoft Data Privacy Notice </a>
        <img className = "img1" src={logo} alt="Microsoft_Logo"></img>
      </footer>
    );
  }
}
export default Footer
