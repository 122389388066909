import * as React from 'react';
class SupplierContractsDoc extends React.Component {
    render() {
        return (
            <div>
                <h2 style={{display: 'block', fontSize: '1.3em', margin: '0.83em 0 0.83em 0', fontWeight: 'bold'}}>Supplier Contracts</h2>
            </div>
        );
    }
}
export default SupplierContractsDoc;