import { IColumn  } from '@fluentui/react/lib/DetailsList';
import { IExcelHeaders} from '../../shared/common/common-util';


export const automatedSeidExportheaders : IExcelHeaders[] =[
    {
    header: 'Supplier Engagement ID',
    key: 'supplierEngagementId',
  },
  {
    header: 'Supplier Name',
    key: 'supplierName',
  },
  {
    header: 'Fiscal Year Number',
    key: 'fiscalYearNumber',
  },
  {
    header: 'Engagement ID',
    key: 'engagementId',
  },
  {
    header: 'Business Group',
    key: 'businessGroup',
  },
  {
    header: 'CCOOrg',
    key: 'cooOrg',
  },
  {
    header: 'PCY',
    key: 'pcy',
  },
  {
    header: 'PCY IsGroup',
    key: 'pcyIsGroup',
  },
  {
    header: 'PCY Id',
    key: 'pcyId',
  },
  {
    header: 'SubPCY',
    key: 'subPcy',
  },
  {
    header: 'SubPCY IsGroup',
    key: 'subPcyIsGroup',   
  },
  {
    header: 'SubPCY Id',
    key: 'subPcyId',
  },
  {
    header: 'Language',
    key: 'language',
  },
  {
    header: 'IsCommerce',
    key: 'isCommerce',
  },
  {
    header: 'Offering',
    key: 'offering',
  },
  {
    header: 'Report Category',
    key: 'reportGrouping',
  },
  {
    header: 'Supplier Id',
    key: 'supplierId',
  },
  {
    header: 'Supplier Number',
    key: 'supplierNumber',
  },
  {
    header: 'Company Code',
    key: 'companyCode',
  },
  {
    header: 'Supplier Site',
    key: 'supplierSite',
  },
  {
    header: 'Supplier City',
    key: 'supplierCity',
  },
  {
    header: 'Supplier Country/Region',
    key: 'supplierCountry',
  },
  {
    header: 'Currency Code',
    key: 'currencyCode',
  },
  {
    header: 'Internal Order',
    key: 'internalOrder',
  },
  {
    header: 'Cost Center',
    key: 'costCenter',
  },
  {
    header: 'Profit Center',
    key: 'profitCenter',
  },
  {
    header: 'Account Code',
    key: 'accountCode',
  },
  {
    header: 'Pricing Group Id',
    key: 'pricingGroupId',
  },
  {
    header: 'Purchase Order',
    key: 'purchaseOrder',
  },
  {
    header: 'SD1',
    key: 'sD1',
  },
  {
    header: 'SD2',
    key: 'sD2',
  },
  {
    header: 'CCO1',
    key: 'ccO1',
  },
  {
    header: 'CCO2',
    key: 'ccO2',
  },
  {
    header: 'SEID IsInvoice Active',
    key: 'isInvoiceActive',
  },
  {
    header: 'Invoice Prefix',
    key: 'invoicePrefix',
  },
  {
    header: 'Business Segment',
    key: 'BusinessSegment',
  },
  {
    header: 'ExcePI',
    key: 'ExcePI',
  },
  {
    header: 'PL Function',
    key: 'function',
  },
  {
    header: 'PL FunctionDetail',
    key: 'functionDetail',
  },
  {
    header: 'PL FunctionSummary',
    key: 'functionSummary',
  },
  {
    header: 'PL Org',
    key: 'org',
  },
  {
    header: 'PL OrgDetail',
    key: 'orgDetail',
  },
  {
    header: 'Product',
    key: 'Product',
  },
  {
    header: 'Product Group',
    key: 'ProductGroup',
  },
  {
    header: 'Product SubType',
    key: 'ProductSubType',
  },
];

export const newAutomatedSupplierengagementsHeaders : IExcelHeaders[] =[
  {header: 'EngagementId',key: 'EngagementId'},
  {header: 'SupplierEngagementId',key: 'SupplierEngagementId'},
  {header: 'SupplierName',key: 'SupplierName'},
  {header: 'SupplierNumber',key: 'supplierNumber'},
  {header: 'CompanyCode',key: 'CompanyCode'},
  {header: 'InternalOrder',key: 'InternalOrder'},
  {header: 'InvoicePrefix',key: 'InvoicePrefix'},
  {header: 'PurchaseOrder',key: 'PurchaseOrder'},
  {header: 'SupplierCountry',key: 'SupplierCountry'},
  {header: 'SupplierSite',key: 'SupplierSite'},
]

export const automatedSeidTemplateheaders : IExcelHeaders[] =[
  {header: 'SupplierEngagementId',key: 'SupplierEngagementId'},
  {header: 'FiscalYearNumber',key: 'FiscalYearNumber'},
  {header: 'EngagementId',key: 'EngagementId'},
  {header: 'BusinessGroup',key: 'BusinessGroup'},
  {header: 'PCY',key: 'PCY'},
  {header: 'SubPCY',key: 'SubPSubPCYCY'},
  {header: 'Offering',key: 'Offering'},
  {header: 'ReportCategory',key: 'ReportCategory'},
  {header: 'Language',key: 'Language'},
  {header: 'SalesRegion',key: 'SalesRegion'},
  {header: 'MediaType',key: 'MediaType'},
  {header: 'IsCommerce',key: 'IsCommerce'},
  {header: 'CloudType',key: 'CloudType'},
  {header: 'BusinessSegment',key: 'BusinessSegment'},
  {header: 'ExecPL',key: 'ExecPL'},
  {header: 'ProductGroup',key: 'ProductGroup'},
  {header: 'Product',key: 'Product'},
  {header: 'ProductSubType',key: 'ProductSubType'},
  {header: 'SupportTier',key: 'SupportTier'},
  {header: 'LOB',key: 'LOB'},
  {header: 'MediaSubType',key: 'MediaSubType'},
  {header: 'SalesLocation',key: 'SalesLocation'},
  {header: 'CCOOrg',key: 'CCOOrg'},
  {header: 'SupplierName',key: 'SupplierName'},
  {header: 'SupplierNumber',key: 'SupplierNumber'},
  {header: 'CompanyCode',key: 'CompanyCode'},
  {header: 'SupplierSite',key: 'SupplierSite'},
  {header: 'SupplierCity',key: 'SupplierCity'},
  {header: 'SupplierCountry',key: 'SupplierCountry'},
  {header: 'PricingGroupDescription',key: 'PricingGroupDescription'},
  {header: 'FunctionSummary',key: 'FunctionSummary'},
  {header: 'InternalOrder',key: 'InternalOrder'},
  {header: 'CostCenter',key: 'CostCenter'},
  {header: 'ProfitCenter',key: 'ProfitCenter'},
  {header: 'AccountCode',key: 'AccountCode'},
  {header: 'PurchaseOrder',key: 'PurchaseOrder'},
  {header: 'SD1',key: 'SD1'},
  {header: 'SD2',key: 'SD2'},
  {header: 'CCO1',key: 'CCO1'},
  {header: 'CCO2',key: 'CCO2'},
  {header: 'IsInvoiceActive',key: 'IsInvoiceActive'},
  {header: 'IsBudgetActive',key: 'IsBudgetActive'},
  {header: 'State',key: 'State'},
  {header: 'IsActive',key: 'IsActive'},
  {header: 'InvoicePrefix',key: 'InvoicePrefix'}
];

export const _automatedseidGridUploadColumns: IColumn[] =
[
    {
    key: 'column1',
    name: 'Supplier Engagement ID',
    fieldName: 'SupplierEngagementId',
    minWidth: 180,
    maxWidth: 250,
    isResizable: true,
  },
  {
    key: 'column2',
    name: 'Fiscal Year Number',
    fieldName: 'FiscalYearNumber',
    minWidth: 150,
    maxWidth: 250,
    isResizable: true,
  },
  {
    key: 'column3',
    name: 'Engagement ID',
    fieldName: 'EngagementId',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column4',
    name: 'Business Group',
    fieldName: 'BusinessGroup',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column5',
    name: 'CCOOrg',
    fieldName: 'CCOOrg',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column6',
    name: 'PCY',
    fieldName: 'PCY',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column7',
    name: 'PCY IsGroup',
    fieldName: 'PCYIsGroup',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column8',
    name: 'PCY Id',
    fieldName: 'PCYId',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column9',
    name: 'SubPCY',
    fieldName: 'SubPCY',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column10',
    name: 'SubPCY IsGroup',
    fieldName: 'SubPCYIsGroup',
    minWidth: 150,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column11',
    name: 'SubPCY Id',
    fieldName: 'SubPCYId',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column12',
    name: 'Supplier Name',
    fieldName: 'SupplierName',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column13',
    name: 'Language',
    fieldName: 'Language',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column14',
    name: 'LanguageIsGroup',
    fieldName: 'LanguageIsGroup',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  
  {
    key: 'column15',
    name: 'IsCommerce',
    fieldName: 'IsCommerce',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column16',
    name: 'IsCommerceIsGroup',
    fieldName: 'IsCommerceIsGroup',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column17',
    name: 'Offering',
    fieldName: 'Offering',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column18',
    name: 'OfferingIsGroup',
    fieldName: 'OfferingIsGroup',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column19',
    name: 'Report Category',
    fieldName: 'ReportCategory',
    minWidth: 150,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column20',
    name: 'Report Category IsGroup',
    fieldName: 'ReportCategoryIsGroup',
    minWidth: 150,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column21',
    name: 'Sales Region',
    fieldName: 'SalesRegion',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column22',
    name: 'SalesLocation',
    fieldName: 'SalesLocation',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  
  {
    key: 'column23',
    name: 'CloudType',
    fieldName: 'CloudType',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column24',
    name: 'BusinessSegment',
    fieldName: 'BusinessSegment',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column25',
    name: 'ExecPL',
    fieldName: 'ExecPL',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column26',
    name: 'SupportTier',
    fieldName: 'SupportTier',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  
  {
    key: 'column27',
    name: 'LOB',
    fieldName: 'LOB',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column28',
    name: 'MediaType',
    fieldName: 'MediaType',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column29',
    name: 'MediaSubType',
    fieldName: 'MediaSubType',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  
  
  {
    key: 'column30',
    name: 'Supplier Id',
    fieldName: 'SupplierId',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  
  {
    key: 'column31',
    name: 'Supplier Number',
    fieldName: 'SupplierNumber',
    minWidth: 130,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column32',
    name: 'Company Code',
    fieldName: 'CompanyCode',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column33',
    name: 'Supplier Site',
    fieldName: 'SupplierSite',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column34',
    name: 'Supplier City',
    fieldName: 'SupplierCity',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column35',
    name: 'Supplier Country',
    fieldName: 'SupplierCountry',
    minWidth: 150,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column36',
    name: 'Currency Code',
    fieldName: 'currencyCode',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column37',
    name: 'Internal Order',
    fieldName: 'InternalOrder',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column38',
    name: 'Cost Center',
    fieldName: 'CostCenter',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column39',
    name: 'Profit Center',
    fieldName: 'ProfitCenter',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column40',
    name: 'Account Code',
    fieldName: 'AccountCode',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column41',
    name: 'Pricing Group Id',
    fieldName: 'PricingGroupId',
    minWidth: 150,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column42',
    name: 'Pricing Group Description',
    fieldName: 'PricingGroupDescription',
    minWidth: 150,
    maxWidth: 200,
    isResizable: true,
  },
  
  {
    key: 'column43',
    name: 'Purchase Order',
    fieldName: 'PurchaseOrder',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column44',
    name: 'PurchaseorderId',
    fieldName: 'PurchaseorderId',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  
  {
    key: 'column45',
    name: 'SD1',
    fieldName: 'SD1',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column46',
    name: 'SD2',
    fieldName: 'SD2',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column47',
    name: 'CCO1',
    fieldName: 'CCO1',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column48',
    name: 'CCO2',
    fieldName: 'CCO2',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column49',
    name: 'SEID IsInvoice Active',
    fieldName: 'IsInvoiceActive',
    minWidth: 150,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column50',
    name: 'IsBudgetActive',
    fieldName: 'IsBudgetActive',
    minWidth: 150,
    maxWidth: 200,
    isResizable: true,
  },
  
  {
    key: 'column51',
    name: 'IsActive',
    fieldName: 'IsActive',
    minWidth: 150,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column52',
    name: 'State',
    fieldName: 'State',
    minWidth: 150,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column53',
    name: 'InvoicePrefix',
    fieldName: 'InvoicePrefix',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column54',
    name: 'PL FunctionSummary',
    fieldName: 'FunctionSummary',
    minWidth: 150,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column55',
    name: 'Product',
    fieldName: 'Product',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column56',
    name: 'Product Group',
    fieldName: 'ProductGroup',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'column57',
    name: 'Product SubType',
    fieldName: 'ProductSubType',
    minWidth: 150,
    maxWidth: 200,
    isResizable: true,
  },
];
export const invalidAutomatedSeidExportheaders : IExcelHeaders[] =[
  { header: "IsValid", key: "IsValid" },
  { header: "ErrorMessage", key: "ErrorMessage" },
  { header: "FunctionHierarchyCode", key: "FunctionHierarchyCode" },
  { header: "SupplierEngagementId", key: "SupplierEngagementId" },
  { header: "FiscalYearNumber", key: "FiscalYearNumber" },
  { header: "EngagementId", key: "EngagementId" },
  { header: "BusinessGroup", key: "BusinessGroup" },
  { header: "PCY", key: "PCY" },
  { header: "SubPCY", key: "SubPCY" },
  { header: "Offering", key: "Offering" },
  { header: "ReportCategory", key: "ReportCategory" },
  { header: "Language", key: "Language" },
  { header: "SalesRegion", key: "SalesRegion" },
  { header: "MediaType", key: "MediaType" },
  { header: "IsCommerce", key: "IsCommerce" },
  { header: "CloudType", key: "CloudType" },
  { header: "BusinessSegment", key: "BusinessSegment" },
  { header: "ExecPL", key: "ExecPL" },
  { header: "ProductGroup", key: "ProductGroup" },
  { header: "Product", key: "Product" },
  { header: "ProductSubType", key: "ProductSubType" },
  { header: "SupportTier", key: "SupportTier" },
  { header: "LOB", key: "LOB" },
  { header: "MediaSubType", key: "MediaSubType" },
  { header: "SalesLocation", key: "SalesLocation" },
  { header: "CCOOrg", key: "CCOOrg" },
  { header: "SupplierName", key: "SupplierName" },
  { header: "SupplierNumber", key: "SupplierNumber" },
  { header: "CompanyCode", key: "CompanyCode" },
  { header: "SupplierSite", key: "SupplierSite" },
  { header: "SupplierCity", key: "SupplierCity" },
  { header: "SupplierCountry", key: "SupplierCountry" },
  { header: "PricingGroupDescription", key: "PricingGroupDescription" },
  { header: "FunctionSummary", key: "FunctionSummary" },
  { header: "InternalOrder", key: "InternalOrder" },
  { header: "CostCenter", key: "CostCenter" },
  { header: "ProfitCenter", key: "ProfitCenter" },
  { header: "AccountCode", key: "AccountCode" },
  { header: "PurchaseOrder", key: "PurchaseOrder" },
  { header: "SD1", key: "SD1" },
  { header: "SD2", key: "SD2" },
  { header: "CCO1", key: "CCO1" },
  { header: "CCO2", key: "CCO2" },
  { header: "IsInvoiceActive", key: "IsInvoiceActive" },
  { header: "IsBudgetActive", key: "IsBudgetActive" },
  { header: "State", key: "State" },
  { header: "IsActive", key: "IsActive" },
  { header: "InvoicePrefix", key: "InvoicePrefix" }
]


