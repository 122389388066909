import { Stack } from 'office-ui-fabric-react';
import {
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import { Dropdown, IDropdown,IDropdownOption } from '@fluentui/react';
import { Modal} from '@fluentui/react';
import {
  ITextFieldProps,
  TextField,
} from 'office-ui-fabric-react/lib/TextField';
import { getId } from 'office-ui-fabric-react/lib/Utilities';
import * as React from 'react';
import { toast } from 'react-toastify';
import gtoApiInstance, {
  gtoMsalApiFetch,
} from '../../../../shared/http/GtoApiNew.axios';
import {
  ISelectedContract,
  ISupplier,
  Routes,
  TelemetryEvent,
  TelemetryException,
  TypeOfSelectedContract,
} from '../../../../shared/models';
import telemetryContext from '../../../../shared/services/TelemetryServices';
import './CreateContractShellModal.css';
import { css } from 'glamor';
import { v4 as uuidv4 } from 'uuid';

export interface ICreateShellContractModalProps {
  showModal: boolean;
  closeModal: any;
  updateExistingContracts: any;
  supplierList: ISupplier[];
}

export interface ICreateShellContractModalState {
  description: string;
  supplierName: string;
  supplierNumber: number;
  companyCode: number;
  invalid: boolean;
  submitBtnEnabled: boolean;
  supplierNameDropdownEnabled: boolean;
  supplierNameOptions: IDropdownOption[];
  companyCodeOptions: IDropdownOption[];
}

const dropdownRef = React.createRef<IDropdown>();

export class CreateShellContractModal extends React.Component<
  ICreateShellContractModalProps,
  ICreateShellContractModalState
> {
  // Use getId() to ensure that the IDs are unique on the page.
  // (It's also okay to use plain strings without getId() and manually ensure uniqueness.)
  private titleId: string = getId('title');
  private subtitleId: string = getId('subText');

  constructor(props) {
    super(props);

    this.state = {
      description: '',
      supplierName: '',
      supplierNumber: -1,
      companyCode: -1,
      invalid: false,
      submitBtnEnabled: false,
      supplierNameDropdownEnabled: false,
      supplierNameOptions: [],
      companyCodeOptions: [],
    };

    this.setCompanyCodes();
  }

  // ------------ LIFECYCLE METHODS --------------------- //
  public render(): JSX.Element {
    if (!this.props.showModal) {
      return null;
    } else {
      return (
        <div>
          <Modal
                  titleAriaId={this.titleId}
            subtitleAriaId={this.subtitleId}
            isOpen={this.props.showModal}
            onDismiss={this.closeModal}
            isBlocking={false}            
          >

            <div className="contractShellModalContainer">
              <h2 style={{ width: '500px' }} id={this.titleId}>Create Contract Shell</h2>
              <div>
                <div className="companyCode">
                  <Dropdown
                    componentRef={dropdownRef}
                    placeholder="Select an option"
                    label="Company Code"
                    id="coCode"
                    onChange={this.handleCompanyCodeChange}
                    options={this.state.companyCodeOptions}
                    styles={{
                      label: { fontWeight: 'bold' },
                      callout: {
                        maxHeight: '160px',
                        height: 'auto',
                        overflowY: 'auto',
                      },
                    }}
                    required={true}
                  />
                </div>

                <div className="supplierName">
                  <Dropdown
                    componentRef={dropdownRef}
                    placeholder="Select an option"
                    label="Supplier Name"
                    id="supName"
                    onChange={this.handleSupplierNameChange}
                    options={this.state.supplierNameOptions}
                    styles={{
                      label: { fontWeight: 'bold' },
                      callout: {
                        maxHeight: '200px',
                        height: 'auto',
                        overflowY: 'auto',
                        selectors: {
                          '.ms-Callout-main': {
                            height:
                              this.state.supplierNameOptions.length <= 6
                                ? 'auto'
                                : '198px',
                          },
                        },
                      },
                    }}
                    required={true}
                    disabled={!this.state.supplierNameDropdownEnabled}
                  />
                </div>

                <TextField
                  className="description"
                  label="Description"
                  id="descr"
                  name="description"
                  placeholder="Enter description"
                  autoComplete="on"
                  value={this.state.description}
                  onChange={this.handleDescrChange}
                  onGetErrorMessage={this.getDescriptionErrorMessage}
                  required={true}
                  //onRenderLabel={this._onRenderTextFieldLabel}
                  styles={{ fieldGroup: { margin: '5px 0px 0px 0px' } }}
                  aria-label="Description"
                />

                <div className="mapButtonGroup">
                  <DefaultButton
                    data-automation-id="cancelBtn"
                    text="Cancel"
                    onClick={this.closeModal}
                    style={{ margin: '10px' }}
                  />
                  <PrimaryButton
                    data-automation-id="submitBtn"
                    text="Submit"
                    role="button"
                    onClick={this.handleSubmit}
                    style={{ float: 'right', margin: '10px 0px' }}
                    disabled={!this.state.submitBtnEnabled}
                  />
                </div>
              </div>
            </div>
          </Modal>
        </div>
      );
    }
  }
  // ------------ END OF LIFECYCLE METHODS -------------- //

  private initStates = () => {
    this.setState({
      description: '',
      supplierName: '',
      supplierNumber: -1,
      companyCode: -1,
      invalid: false,
      submitBtnEnabled: false,
      supplierNameDropdownEnabled: false,
      supplierNameOptions: [],
    });
  };

  // set the list of company codes in the constructor
  // call from  Supplier API
  private setCompanyCodes = async () => {
    //  private setCompanyCodes =  () => {
    let companyCodes: IDropdownOption[] = [];
    let temp: number[] = [];
    //   let temp: string[] = [];
    //  for (let i =0; i< this.props.supplierList.length; i++){
    //    if(temp.indexOf(this.props.supplierList[i].companyCode) === -1){
    //      temp.push(this.props.supplierList[i].companyCode);

    //    }
    //  }

    const url: string = Routes.FetchSupplierList;

    try {
      // Logging telemetry
      this.logFetchSupplierDataBegin(url);
      const headers: Object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };

      let response = await gtoMsalApiFetch(gtoApiInstance.get, url, {
        headers,
      });
      // let response = this.props.supplierList;

      // Mapping only the company codes
      for (let i = 0; i < response.data.length; i++) {
        // Adding the Company code if it is not present in temp array
        if (temp.indexOf(response.data[i].msCompanyCode) === -1) {
          temp.push(response.data[i].msCompanyCode);
        }
      }
      // Logging success
      this.logFetchSupplierDataSuccess(url);
    } catch (ex) {
      this.logFetchSupplierDataFailure(url, ex);
      if (ex.response && ex.response.status === 404) {
        toast.error(
          'Company code information is not available at this moment.',
          {
            className: css({
              background: '#a80000 !important',
            }),
          }
        );
      }
      // Setting the contract data as empty array as response had an error.
      companyCodes = [];
    }

    // sort the array in ascending order
    temp.sort((a, b) => (a > b ? 1 : -1));

    // map the current array to one with dropdown options
    companyCodes = temp.map(x => ({
      key: x.toString(),
      text: x.toString(),
    }));

    // set the current state with the unique company code options
    this.setState({ companyCodeOptions: companyCodes });
  };

  // set the list of supplier names based on the selected company code
  // call from API
  private setSupplierNames = async () => {
    let supplierNames: IDropdownOption[] = [];
    let temp: string[] = [];

    const url: string = Routes.FetchSupplierList;

    try {
      // Logging telemetry
      this.logFetchSupplierDataBegin(url);
      const headers: Object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };

      let response = await gtoMsalApiFetch(gtoApiInstance.get, url, {
        headers,
      });

      // Mapping only the suppliers that match the company code selected
      for (let i = 0; i < response.data.length; i++) {
        // Based on the company code selection diplay the suppliers and add them to dropDown
        if (
          this.state.companyCode === response.data[i].msCompanyCode &&
          temp.indexOf(response.data[i].supplierName) === -1
        ) {
          temp.push(
            response.data[i].supplierName +
              ' (' +
              response.data[i].supplierNumber +
              ')'
          );
        }
      }
      // Logging success
      this.logFetchSupplierDataSuccess(url);
    } catch (ex) {
      this.logFetchSupplierDataFailure(url, ex);
      if (ex.response && ex.response.status === 404) {
        toast.error(
          'Supplier name information is not available at this moment.',
          {
            className: css({
              background: '#a80000 !important',
            }),
          }
        );
      }
      // Setting the contract data as empty array as response had an error.
      supplierNames = [];
    }

    // sort the array in ascending order
    temp.sort((a, b) => (a > b ? 1 : -1));

    // map the current array to one with dropdown options
    supplierNames = temp.map(x => ({
      key: x,
      text: x,
    }));

    // set the current state with the unique supplier name options
    this.setState({ supplierNameOptions: supplierNames });
  };

  // -------------------- Handle change methods ------------------------- //

  // changes the state of the description to what the user entered
  private handleDescrChange = (event, x) => {
    this.setState({ description: x }, this.checkSubmitBtnEnabled);
  };

  // changes the state of the company code to what the user selected
  private handleCompanyCodeChange = (event, selected) => {
    this.setState({ companyCode: +selected.text }, this.checkAreasEnabled);
  };

  // changes the state of the supplier name to what the user selected
  private handleSupplierNameChange = (event, selected) => {
    let supplierName = selected.text.substring(
      0,
      selected.text.indexOf('(') - 1
    );
    let supplierNumber = +selected.text.substring(
      selected.text.indexOf('(') + 1,
      selected.text.length - 1
    );

    this.setState(
      { supplierName: supplierName, supplierNumber: supplierNumber },
      this.checkSubmitBtnEnabled
    );
  };

  // when the user presses the "submit" button, the current description is shown (temporary behavior)
  private handleSubmit = event => {
    // Logging the click of the submit button
    this.logSubmitContractShellButtonClick();

    // Defining the url
    const url: string = Routes.InsertContract;
    let _this = this;
    // try to map the contract - api call

    // Logging telemetry
    _this.logInsertContractBegin(url);

    _this.callInsertContractAPI(url).then(function(result) {
      // if there is a message, then there was an error
      if (result.Message !== undefined) {
        // log the exception
        _this.logInsertContractFailure(result.Message, url);

        toast.error(result.Message, {
          className: css({
            background: '#a80000 !important',
          }),
        });
      } else {
        // Logging success
        _this.logInsertContractSucess(url);
        //let response = _this.props.updateExistingContracts();
        _this.props.updateExistingContracts();
        toast.info('Contract shell has been added', {
          className: css({
            background: '#0275d8 !important',
          }),
        });
      }

      _this.closeModal();
    });
  };

  // -------------------- End of handle change methods ----------------- //

  // makes the "description" label bold
  private _onRenderTextFieldLabel = (props: ITextFieldProps): JSX.Element => {
    return (
      <>
        <Stack horizontal verticalAlign="center">
          <span style={{ fontWeight: 'bold' }}>{props.label}</span>
        </Stack>
      </>
    );
  };

  // check to see if certain parts of modal should be enabled or not
  private checkAreasEnabled = () => {
    this.checkSubmitBtnEnabled();
    this.checkSupplierNameDropdownEnabled();
  };

  // check to see if supplier name dropdown should be enabled
  private checkSupplierNameDropdownEnabled = () => {
    if (this.state.companyCode < 0) {
      this.setState({ supplierNameDropdownEnabled: false });
    } else {
      this.setSupplierNames();
      this.setState({ supplierNameDropdownEnabled: true });
    }
  };

  // check to see if the submit button should be enabled or not
  private checkSubmitBtnEnabled = () => {
    if (
      this.state.invalid ||
      this.state.description.length === 0 ||
      this.state.companyCode < 0 ||
      !this.state.supplierName
    ) {
      this.setState({ submitBtnEnabled: false });
    } else {
      this.setState({ submitBtnEnabled: true });
    }
  };

  // returns an error message for the description text field if there are more than 50 characters
  private getDescriptionErrorMessage = (value: string): string => {
    if (value.length <= 50) {
      this.setState({ invalid: false }, () => {
        this.checkSubmitBtnEnabled();
      });
      return '';
    } else {
      this.setState({ invalid: true }, () => {
        this.checkSubmitBtnEnabled();
      });
      return 'Max 50 characters';
    }
  };

  private closeModal = (): void => {
    this.props.closeModal();
    this.initStates();
  };

  // ----------- LOGGING METHODS ------------------ //
  private logFetchSupplierDataBegin(url) {
    telemetryContext.logEvent(TelemetryEvent.FetchSupplierDataBegin, {
      url: url,
    });
  }

  private logFetchSupplierDataSuccess(url) {
    telemetryContext.logEvent(TelemetryEvent.FetchSupplierDataSuccess, {
      url: url,
    });
  }

  private logFetchSupplierDataFailure(url, ex) {
    telemetryContext.logException(
      ex.toString(),
      TelemetryException.FetchSupplierDataFailure,
      undefined,
      {
        url: url,
      }
    );
  }

  private logSubmitContractShellButtonClick() {
    telemetryContext.logEvent(TelemetryEvent.SubmitContractShellButtonClick, {
      description: this.state.description,
      supplierName: this.state.supplierName,
      supplierNumber: this.state.supplierNumber,
      companyCode: this.state.companyCode,
    });
  }

  private logInsertContractBegin(url) {
    telemetryContext.logEvent(TelemetryEvent.InsertContractBegin, {
      url: url,
      description: this.state.description,
      supplierName: this.state.supplierName,
      supplierNumber: this.state.supplierNumber,
      companyCode: this.state.companyCode,
    });
  }

  private logInsertContractSucess(url) {
    telemetryContext.logEvent(TelemetryEvent.InsertContractSuccess, {
      url: url,
      description: this.state.description,
      supplierName: this.state.supplierName,
      supplierNumber: this.state.supplierNumber,
      companyCode: this.state.companyCode,
    });
  }

  private logInsertContractFailure(resultMessage, url) {
    telemetryContext.logException(
      resultMessage,
      TelemetryException.InsertNewContractFailure,
      undefined,
      {
        url: url,
        description: this.state.description,
        supplierName: this.state.supplierName,
        supplierNumber: this.state.supplierNumber,
        companyCode: this.state.companyCode,
      }
    );
  }
  // ----------- END OF LOGGING METHODS ----------- //

  private async callInsertContractAPI(url) {
    try {
      const contract: ISelectedContract = {
        contractNumber: null,
        // aravoEngagementId: '',
        contractDescription: this.state.description,
        supplierName: this.state.supplierName,
        supplierNumber: this.state.supplierNumber.toString(),
        companyCode: this.state.companyCode.toString(),
        expirationDate: null,
        contractID: null,
        expDateValue: null,
        typeOfContract: TypeOfSelectedContract.shell,
      };
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };
      let temp = await gtoMsalApiFetch(gtoApiInstance, url, {
        method: 'post',
        headers,
        data: contract,
      });
      return temp;
    } catch (ex) {
      return ex.response.data;
    }
  }
}
