export enum InvoicingEvent{
    UnitsInitial = 'UnitsInitial', 
    UnitsFirstEsc = 'UnitsFirstEsc',
    UnitsSecondEsc = 'UnitsSecondEsc',
    InvoiceAccrual = 'InvoiceAccrual',
    LockedForMicrosoft = 'LockedForMicrosoft',
    LockedForSupplier = 'LockedForSupplier',
    InvoiceActual = 'InvoiceActual',
    InvoiceAuditRetry = 'InvoiceAuditRetry',
    OpenForMicrosoft = 'OpenForMicrosoft',
    OpenForSupplier = 'OpenForSupplier',
    InvoiceMonthStart = 'InvoiceMonthStart',
}