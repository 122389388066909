import { IUser } from '../../shared/models';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  Modules: [],
  Alias: null,
  FriendlyName: null,
  IsActive: false,
  IsAdmin: false,
  IsBusinessAnalyst: true,
  IsSupplierSupportManager: true,
  IsInvoiceAnalyst: true,
  IsInvoicingManager: true,
  IsFinanceOwner: true,
  IsFetchingUser: true,
  IsNotification: null,
  IsOnlyReportUser: null,
  IsServiceDelivery: false,
  IsSupplierAccountManager: null,
  UserId: null,
  isSynthetic: false,
  isLoadingGlobal: false, // This flag is used for any submit button that is clicked.
  preferences: {}
};

const reducer = (state: IUser = initialState, action) => {
  let myStorage = window.localStorage;
  // console.log('In Reducer' + state);
  switch (action.type) {
    case actionTypes.SET_USER_ROLES:
      return {
        ...state,
        Alias: action.userDetails.alias,
        IsActive: action.userDetails.isActive,
        IsAdmin: action.userDetails.isAdmin,
        IsBusinessAnalyst: action.userDetails.isBusinessAnalyst,
        IsSupplierSupportManager: action.userDetails.isSupplierSupportManager,
        IsInvoiceAnalyst: action.userDetails.isInvoiceAnalyst,
        IsInvoicingManager: action.userDetails.isInvoicingManager,
        IsFinanceOwner: action.userDetails.isFinanceOwner,
        IsServiceDelivery: action.userDetails.isServiceDelivery,
      } as IUser;
    case actionTypes.REQUEST_USER_ROLES:
      return Object.assign({}, state, { IsFetchingUser: true });

    case actionTypes.SET_USER_MODULES:
      return Object.assign({}, state, {
        Modules: action.modules,
        IsAdmin: action.IsAdmin,
        IsFetchingUser: false,
      });

    case actionTypes.SET_SYNTHETIC_FLAG:
      // this is used to store isSynthetic in the local storage.
      myStorage.setItem('synthetic', String(!state.isSynthetic));
      return Object.assign({}, state, {
        isSynthetic: !state.isSynthetic,
      });
    // Setting the Is Loading global flag
    case actionTypes.SET_IS_LOADING_GLOBAL_FLAG:
      return Object.assign({}, state, {
        isLoadingGlobal: action.isLoadingGlobal,
      });

    case actionTypes.GET_USER_PREFERENCES:
      return Object.assign({}, state, {
        preferences: action.userPreferences
      });
    
    default:
      return state;
  }
};

export default reducer;
