import gtoApiInstance, {
    gtoMsalApiFetch,
  } from '../../shared/http/GtoApiNew.axios';
import { Routes, TelemetryEvent, TelemetryException } from '../../shared/models';
import * as actionTypes from './actionTypes';
import { v4 as uuidv4 } from 'uuid';
import { IDropdownOption } from 'office-ui-fabric-react';
import { delay } from 'q';
import telemetryContext from '../../shared/services/TelemetryServices';
import { toast } from 'react-toastify';
import { css } from 'glamor';
import { bobReportInvoiceSummaryDataMock,
         bobInvoiceReportSelectedFiltersMock,
         bobReportInvoiceDetailsDataMock,
         bobReportBudgetSummaryDataMock, 
         bobBudgetReportSelectedFiltersMock, 
         bobReportBudgetDetailsDataMock, 
         bobReportFiltersMockData} from '../../shared/mock/BOBReportMockData';
import appConfig from '../../assets/configuration/config';
  
  export const setFilterOptions = (data: any) => {
    return {
      type: actionTypes.SET_BOBREPORT_FILTEROPTIONS,
      masterData: data,
    };
  };

  export const updateInvoiceReportSelectedSuppliers = (option: IDropdownOption) => {
    return {
      type: actionTypes.SET_INVOICEREPORT_SELECTEDSUPPLIERS,
      data: option,
    };
  };

  export const updateInvoiceReportSelectedQuarters = (option: IDropdownOption) => {    
    return {
      type: actionTypes.SET_INVOICEREPORT_SELECTEDQUARTERS,
      data: option,
    };
  };

  export const updateInvoiceReportSelectedSources = (option: IDropdownOption) => {
    return {
      type: actionTypes.SET_INVOICEREPORT_SELECTEDSOURCES,
      data: option,
    };
  };

  export const updateInvoiceReportSelectedFiscalYears = (option: IDropdownOption) => {
    return {
      type: actionTypes.SET_INVOICEREPORT_SELECTEDFISCALYEARS,
      data: option,
    };
  };
  
  export const updateInvoiceReportSummary = (data: any) => {
    return {
      type: actionTypes.SET_INVOICEREPORT_SUMMARYDATA,
      data: data,
    };
  };

  export const updateInvoiceReportDetails = (data: any) => {
    return {
      type: actionTypes.SET_INVOICEREPORT_DETAILS,
      data: data,
    };
  };

  export const requestInvoiceReportSummary = () => {
    return { type: actionTypes.REQUEST_INVOICEREPORT_SUMMARY };
  };

  export const requestInvoiceReportSummaryComplete = () => {
    return { type: actionTypes.REQUEST_INVOICEREPORT_SUMMARYCOMPLTE };
  };

  export const requestInvoiceReportDetails = () => {
    return { type: actionTypes.REQUEST_INVOICEREPORT_DETAILS };
  };

  export const requestInvoiceReportDetailsComplete = () => {
    return { type: actionTypes.REQUEST_INVOICEREPORT_DETAILSCOMPLTE };
  };  

  export const updateInvoiceReportSelectedFilters = (data:any) =>{
    return {
      type: actionTypes.SET_INVOICEREPORT_SELCTEDFILTERS,
      selectedFilters: data
    };
  }

  export const updateBudgetReportSelectedSuppliers = (option: IDropdownOption) => {
    return {
      type: actionTypes.SET_BUDGETREPORT_SELECTEDSUPPLIERS,
      data: option,
    };
  };

  export const updateBudgetReportSelectedQuarters = (option: IDropdownOption) => {
    return {
      type: actionTypes.SET_BUDGETREPORT_SELECTEDQUARTERS,
      data: option,
    };
  };
  
  export const updateBudgetReportSelectedBudgetTypes = (option: IDropdownOption) => {
    return {
      type: actionTypes.SET_BUDGETEREPORT_SELECTEDBUDGETTYPES,
      data: option,
    };
  };

  export const updateBudgetReportSummary = (data: any) => {
    return {
      type: actionTypes.SET_BudgetREPORT_SUMMARYDATA,
      data: data,
    };
  };

  export const updateBudgetReportDetails = (data: any) => {
    return {
      type: actionTypes.SET_BUDGETREPORT_DETAILS,
      data: data,
    };
  };

  export const requestBudgetReportSummary = () => {
    return { type: actionTypes.REQUEST_BUDGETREPORT_SUMMARY };
  };

  export const requestBudgetReportSummaryComplete = () => {
    return { type: actionTypes.REQUEST_BUDGETREPORT_SUMMARYCOMPLTE };
  };

  export const requestBudgetReportDetails = () => {
    return { type: actionTypes.REQUEST_BUDGETREPORT_DETAILS };
  };

  export const requestBudgetReportDetailsComplete = () => {
    return { type: actionTypes.REQUEST_BUDGETREPORT_DETAILSCOMPLTE };
  };


  export const updateBudgetReportSelectedFilters = (data:any) =>{
    return {
      type: actionTypes.SET_BUDGETREPORT_SELCTEDFILTERS,
      selectedFilters: data
    };
  }
  

  /*--------------------------------------THUNK ACTION CREATORS-----------------------------------*/
  
  export const getBOBReportMasterData = () => {
    return dispatch => {    
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {  
        // Creating headers for telemetry
        const headers: object = {
          'X-CorrelationId': uuidv4(),
          SubCorrelationKey: uuidv4(),
        };

        // Defining the url
        const url: string = Routes.GetBOBReportMasterData;

         // Logging telemetry
        telemetryContext.logEvent(TelemetryEvent.FetchBOBReportMasterDataBegin, {
          url: url,
          headers,
        });

        // Calling the api to get the master data
        gtoMsalApiFetch(gtoApiInstance.get, url, { headers })
          .then((response: any) => {    
            // Logging success
            telemetryContext.logEvent(
              TelemetryEvent.FetchBOBReportMasterDataSuccess,
              {
                headers,
                url,
              }
            );
            // Mapping to Supplier type
            let supplierFilterOptions: IDropdownOption[] = response.data.suppliers
            .map(x => ({     
              key: x.supplierMasterId,
              text: x.supplierName             
            }));

            //Mapping to FiscalYears
            let fiscalYearFilterOptions: IDropdownOption[] = response.data.fiscalYearNumbers
            .map(item => ({ key: item, text: item}));

            //Mapping to Quarters
            let quarterFilterOptions: IDropdownOption[] = response.data.quarters
            .map(item => ({  key: item, text: item}));

            //Mapping to Invoice Sources
            let invoiceSourceFilterOptions: IDropdownOption[] = response.data.invoiceSources
            .map(x => ({
              key: x.invoiceSourceId,
              text: x.invoiceSourceName
            }));

            //Mapping to Budget Types
            let budgetTypeFilterOptions: IDropdownOption[] = response.data.budgetTypes
            .map(x => ({
              key: x.budgetTypeId,
              text: x.budgetTypeName
            }));

            const data = {
              SupplierFilterOptions: supplierFilterOptions,
              FiscalYearFilterOptions: fiscalYearFilterOptions,
              QuarterFilterOptions: quarterFilterOptions,
              InvoiceSourceFilterOptions: invoiceSourceFilterOptions,
              BudgetTypeFilterOptions: budgetTypeFilterOptions
            }

            dispatch(setFilterOptions(data));
          })        
          .catch(error => {
            // Log exception
            telemetryContext.logException(
              error.toString(),
              TelemetryException.FetchBOBReportMasterDataFailure,
              undefined,
              {
                url: url,
                headers,
              }
            );
             // Error handling.
            if (error.response && error.response.status === 404) {
              toast.error('Unable to fetch Report filters data.', {
                className: css({
                  background: '#a80000 !important',
                }),
              });
            }

            throw error;  
          });
      }
      else{
        // Setting mock data.
        return delay(2000).then(() => {
          dispatch(setFilterOptions(bobReportFiltersMockData));          
        });
      }
    };
  };

  export const getInvoiceReportSummary = () => {
    return (dispatch, getState) => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {  
      dispatch(requestInvoiceReportSummary())

      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
        
      };

      const bobReportState = getState().bobReport;     
      //Filter selected Suppliers
      const supplierIdsList: [] = bobReportState.invoiceSupplierFilterOptions
      .filter(x => x.selected === true)
      .map(option => {
        return option.key;
      });
      //Filter selected Quarters
      const quartersList: [] = bobReportState.invoiceQuarterFilterOptions
      .filter(x => x.selected === true)
      .map(option => {
        return option.key;
      });
      //Filter selected Sources
      const sourceIdsList: [] = bobReportState.invoiceSourceFilterOptions
      .filter(x => x.selected === true)
      .map(option => {
        return option.key;
      });
      //Filter selected Fiscal Years
      const fiscalYearsList: [] = bobReportState.invoiceFiscalYearFilterOptions
      .filter(x => x.selected === true)
      .map(option => {
        return option.key;
      });

      // Defining the url
      let url: string = `${Routes.GetBOBInvoiceReportSummaryData}`;

      var filterData = {
        supplierIds: supplierIdsList,
        quarters: quartersList,
        sourceIds: sourceIdsList,
        fiscalYearNumbers: fiscalYearsList
      }
     
      // Logging telemetry
      telemetryContext.logEvent(
        TelemetryEvent.FetchBOBReportInvoiceSummaryBegin,
        {
          body: filterData,
          headers,
          url,
        }
      );

      // Making the get call.
      gtoMsalApiFetch(gtoApiInstance, url, {
        data: filterData,
        headers,
        method: 'post',
      })
        .then((response: any) => {
          if (response.status === 200) {       
            // Logging telemetry
            telemetryContext.logEvent(
              TelemetryEvent.FetchBOBReportInvoiceSummarySuccess,
              {
                url: url,
                headers,
              }
            );
            //Mapping to Invoice Report Summary
            let invoiceReport: [] = response.data.supplierInvoiceDetails
            .map(x => ({
              SupplierName: x.supplierName,
              CurrencyCode: x.currencyCode,
              TOTALSpendinLC: x.totalSpendInLC.toLocaleString(),
              PassThrough: x.passThrough.toLocaleString(),
              GrossAmount: x.grossAmount.toLocaleString(),
              ExchangeRate: x.exchangeRate.toLocaleString(),
              ExchangeRateMonth: x.exchangeRateMonth,
              SpendInDollar : x.spendInDollar.toLocaleString(),
              BoBDiscountPercent : x.boBDiscountPercent,
              DiscountAmountLC : x.discountAmountLC.toLocaleString(),
              DiscountAmountInUSD : x.discountAmountInUSD.toLocaleString(),
              IsAnualDiscount : x.isAnnualDiscount,              
              Quarter : x.quarter,
              SOURCE : x.invoiceSourceName,
              LastBobProcessedTime : x.lastBobProcessedTime
            }));

            

            let data = {
              invoiceReportSummary : invoiceReport,
              invoiceTotalSpendInDollar: response.data.totalSpendInDollar.toLocaleString(),
              invoiceTotalDiscountAmountInUSD: response.data.totalDiscountAmountInUSD.toLocaleString(),
            }
            dispatch(updateInvoiceReportSummary(data));
            dispatch(requestInvoiceReportSummaryComplete());
            dispatch(updateInvoiceReportSelectedFilters(filterData));
          }         
        })
        .catch(error => {
           // Log exception
           telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchBOBReportInvoiceSummaryFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );
          dispatch(requestInvoiceReportSummaryComplete());
          if (error.response && error.response.status === 404) {
            let data = {
              invoiceReportSummary : [],
              invoiceTotalSpendInDollar: "0",
              invoiceTotalDiscountAmountInUSD: "0"
            }
            dispatch(updateInvoiceReportSummary(data));
            dispatch(updateInvoiceReportSelectedFilters(filterData));
          }
          else{            
            throw error;  
          }
        });
      }
      else{
        // Setting mock data.
        return delay(2000).then(() => {
          dispatch(updateInvoiceReportSummary(bobReportInvoiceSummaryDataMock));  
          dispatch(updateInvoiceReportSelectedFilters(bobInvoiceReportSelectedFiltersMock));        
        });
      }
    };
  }

  export const getInvoiceReportDetails = () => {
    return async (dispatch, getState) => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') { 
      dispatch(requestInvoiceReportDetails());
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
        
      };

      const bobReportState = getState().bobReport;      

      // Defining the url
      let url: string = `${Routes.GetBOBInvoiceReportDetails}`;
      var filterData = bobReportState.invoiceReportSelectedFilters;

      // Logging telemetry
      telemetryContext.logEvent(
        TelemetryEvent.FetchBOBReportInvoiceDetailsBegin,
        {
          body: filterData,
          headers,
          url,
        }
      );

      // Making the get call.
      await gtoMsalApiFetch(gtoApiInstance, url, {
        data: filterData,
        headers,
        method: 'post',
      })
        .then((response: any) => {
          if (response.status === 200) {     
            // Logging telemetry
            telemetryContext.logEvent(
              TelemetryEvent.FetchBOBReportInvoiceDetailsSuccess,
              {
                headers,
                url,
              }
            );
            //Mapping to Invoice Report Summary
            let invoiceReportDetails: [] = response.data
            .map(x => ({
              SupplierEngagementId: x.supplierEngagementId,
              ProfitCenter: x.profitCenter,
              PL_FunctionDetail: x.functionDetail,
              SupplierName: x.supplierName,
              Source: x.source,
              CurrencyCode: x.currencyCode,
              IsAnnualDiscount: x.isAnnualDiscount.toString(),
              Quarter: x.quarter,
              DiscountAmountCD: x.discountAmountCD          
            }));

            let data = {
              invoiceReportDetails : invoiceReportDetails,            
            }

            dispatch(updateInvoiceReportDetails(data));
            dispatch(requestInvoiceReportDetailsComplete());
          }         
        })
        .catch(error => {
          // Log exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchBOBReportInvoiceDetailsFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );

          if (error.response && error.response.status === 404) {
            let data = {
              invoiceReportDetails : [],            
            }

            dispatch(updateInvoiceReportDetails(data));            
          }
          else{
            throw error;
          }
          dispatch(requestInvoiceReportDetailsComplete());
        });
      }
      else{
        // Setting mock data.
        return delay(2000).then(() => {
          dispatch(updateInvoiceReportDetails(bobReportInvoiceDetailsDataMock));       
        });
      }
    };
  }

  export const getBudgetReportSummary = () => {
    return (dispatch, getState) => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') { 
      dispatch(requestBudgetReportSummary())
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };

      const reportFilters = getState().bobReport;   
      // Filter Selected Suppliers
      const supplierIdsList: [] = reportFilters.budgetSupplierFilterOptions
      .filter(x => x.selected === true)
      .map(option => {
        return option.key;
      });
      // Filter Selected Quarters
      const quartersList: [] = reportFilters.budgetQuarterFilterOptions
      .filter(x => x.selected === true)
      .map(option => {
        return option.key;
      });     
      // Filter Selected Budget Types
      const budgetTypesList: [] = reportFilters.budgetTypeFilterOptions
      .filter(x => x.selected === true)
      .map(option => {
        return option.key;
      });

      // Defining the url
      let url: string = `${Routes.GetBOBBudgetReportSummaryData}`;
      var filterData = {
        supplierIds: supplierIdsList,
        quarters: quartersList,
        budgetTypeIds: budgetTypesList
      }

       // Logging telemetry
       telemetryContext.logEvent(
        TelemetryEvent.FetchBOBReportBudgetSummaryBegin,
        {
          body: filterData,
          headers,
          url,
        }
      );
    
      return gtoMsalApiFetch(gtoApiInstance, url, {
        data: filterData,
        headers,
        method: 'post',
      })
        .then((response: any) => {
          if (response.status === 200) {         
            // Logging telemetry
            telemetryContext.logEvent(
              TelemetryEvent.FetchBOBReportBudgetSummarySuccess,
              {
                headers,
                url,
              }
            );
            //Mapping to Invoice Report Summary
            let budgetReport: [] = response.data.supplierBudgetSpendDetails
            .map(x => ({
              SupplierName: x.supplierName,
              CurrencyCode: x.currencyCode,    
              GrossAmount: x.grossAmount.toLocaleString(),
              ExchangeRate: x.exchangeRate,
              ExchangeRateMonth: x.exchangeRateMonth,
              SpendInDollar: x.spendInDollar.toLocaleString(),
              BoBDiscountPercent: x.boBDiscountPercent,
              DiscountAmountLC: x.discountAmountLC.toLocaleString(),
              DiscountAmountInUSD: x.discountAmountInUSD.toLocaleString(),
              BudgetType: x.budgetTypeName,
              Quarter: x.quarter,
              IsAnnualDiscount: x.isAnnualDiscount
            }));

            let data = {
              budgetReportSummary : budgetReport,
              budgetTotalSpendInDollar: response.data.totalSpendInDollar.toLocaleString(),
              budgetTotalDiscountAmountInUSD: response.data.totalDiscountAmountInUSD.toLocaleString(),
              budgetGrossAmount: response.data.totalGrossAmount.toLocaleString()
            }
            dispatch(updateBudgetReportSummary(data));
            dispatch(requestBudgetReportSummaryComplete());
            dispatch(updateBudgetReportSelectedFilters(filterData));
          }
        })
        .catch(error => {
           // Log exception
           telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchBOBReportBudgetSummaryFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );
          dispatch(requestBudgetReportSummaryComplete());
          if (error.response && error.response.status === 404) {
            let data = {
              budgetReportSummary : [],
              budgetTotalSpendInDollar: 0,
              budgetTotalDiscountAmountInUSD: 0,
              budgetGrossAmount: 0
            }
            dispatch(updateBudgetReportSummary(data));            
            dispatch(updateBudgetReportSelectedFilters(filterData));
          }
          else{
            throw error;
          }
        });
      }
      else
      {
        // Setting mock data.
        return delay(2000).then(() => {
          dispatch(updateBudgetReportSummary(bobReportBudgetSummaryDataMock));   
          dispatch(updateBudgetReportSelectedFilters(bobBudgetReportSelectedFiltersMock));    
        });
      }
    };
  }

  export const getBudgetReportDetails = () => {
    return async (dispatch, getState) => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') { 
      dispatch(requestBudgetReportDetails())
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
        
      };

      const bobReportState = getState().bobReport;      

      // Defining the url
      let url: string = `${Routes.GetBOBBudgetReportDetails}`;
      var filterData = bobReportState.budgetReportSelectedFilters;

      // Logging telemetry
      telemetryContext.logEvent(
        TelemetryEvent.FetchBOBReportBudgetDetailsBegin,
        {
          body: filterData,
          headers,
          url,
        }
      );

      // Making the get call.
      await gtoMsalApiFetch(gtoApiInstance, url, {
        data: filterData,
        headers,
        method: 'post',
      })
        .then((response: any) => {
          if (response.status === 200) {                     
            // Logging telemetry
            telemetryContext.logEvent(
              TelemetryEvent.FetchBOBReportBudgetDetailsSuccess,
              {
                body: filterData,
                headers,
                url,
              }
            );
            //Mapping to Invoice Report Summary
            let budgeteReportDetails: [] = response.data
            .map(x => ({
              SupplierEngagementId: x.supplierEngagementId,
              ProfitCenter: x.profitCenter,
              PL_FunctionDetail: x.functionDetail,
              SupplierName: x.supplierName,
              Source: x.source,
              CurrencyCode: x.currencyCode,
              IsAnnualDiscount: x.isAnnualDiscount.toString(),
              Quarter: x.quarter,
              DiscountAmountCD: x.discountAmountCD          
            }));

            let data = {
              BudgetReportDetails : budgeteReportDetails,            
            }


            dispatch(updateBudgetReportDetails(data));
            dispatch(requestBudgetReportDetailsComplete())
          }         
        })
        .catch(error => {
            // Log exception
            telemetryContext.logException(
              error.toString(),
              TelemetryException.FetchBOBReportBudgetDetailsFailure,
              undefined,
              {
                url: url,
                headers,
              }
            );

          if (error.response && error.response.status === 404) {
            let data = {
              BudgetReportDetails : [],            
            }
            dispatch(updateBudgetReportDetails(data));
          }
          else{          
          throw error;
          }
          dispatch(requestBudgetReportDetailsComplete());
        });
      }
      else{
         // Setting mock data.
         return delay(2000).then(() => {
          dispatch(updateBudgetReportDetails(bobReportBudgetDetailsDataMock));    
        });
      }
    };
  }
  