import { ISupplier } from '../models/index';

export const supplierMockData: ISupplier[] = [
  {
    supplierId: 1,
    companyCode: '1010',
    masterSupplierName: 'Alorica',
    supplierLegalName: 'Alorica',
    supplierNumber: '2247242',
    contactName: '',
    contactEmail: '',
    paymentTerms: '',
    currencyCode: 'USD',
  },
  {
    supplierId: 2,
    companyCode: '1062',
    masterSupplierName: 'Arvato',
    supplierLegalName: 'Arvato',
    supplierNumber: '2037878',
    contactName: '',
    contactEmail: '',
    paymentTerms: '',
    currencyCode: 'USD',
  },
  {
    supplierId: 3,
    companyCode: '1107',
    masterSupplierName: 'Tek Experts',
    supplierLegalName: 'Tek Experts Global Ltd',
    supplierNumber: '2049059',
    contactName: '',
    contactEmail: '',
    paymentTerms: '',
    currencyCode: 'USD',
  },
  {
    supplierId: 4,
    companyCode: '1107',
    masterSupplierName: 'Tek Experts',
    supplierLegalName: 'TEK Experts Technologies (Dalian)',
    supplierNumber: '3016202',
    contactName: '',
    contactEmail: '',
    paymentTerms: '',
    currencyCode: 'USD',
  },
];

// Creating Mock Data
for (let i = 0; i < 30; i++) {
  supplierMockData.push(supplierMockData[0]);
}
