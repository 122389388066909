import * as React from 'react';
import HelmetTab from "../../shared/Helmet/Helmet";
import { IStackStyles, Stack } from '@fluentui/react/lib/Stack';
import { TextField } from '@fluentui/react/lib/TextField';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { useEffect, useRef, useState } from 'react';
import { gtoService } from '../../shared/services/GtoService';
import { IRolloverResponse, IUser } from '../../shared/models';
import { ChoiceGroup, DetailsList, DetailsListLayoutMode, IChoiceGroupOption, IColumn, Link, ScrollablePane, SelectionMode, Spinner, SpinnerSize ,Selection} from '@fluentui/react';
import { IExcelHeaders, exportToXlsx, isValidInteger } from '../../shared/common/common-util';
import { ErrorMessages } from '../../shared/common/error-messages';
import { UIMessages } from '../../shared/common/ui-messages';
import { RollOverPageStyles } from './rollover.style';
import '../../shared/common/filedragdrop.css'
import readXlsxFile from 'read-excel-file'
import { CustomTextboxLabelRender } from '../../shared/common/custom-textbox-label-render';
import { toast } from 'react-toastify';
import { css } from 'glamor';
const Rollover: React.FunctionComponent = () => {
  const [userData, setUserData] = useState<IUser>();
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const [rollOverYear, setRollOverYear] = useState<number>(2023);
  const [fileName, setFileName] = useState<string>('or drag and drop files here');
  const searchByValues = [{ key: 'E', text: 'Engagement ID', generalText: 'Engagements', styles: { field: { marginLeft: "10px" } } }, { key: "SE", text: 'Supplier Engagement ID', generalText: 'Supplier Engagements', styles: { field: { marginLeft: "10px" } } },
    { key: 'AE', text: 'Automated Engagement ID', generalText: 'Automated Engagements', styles: { field: { marginLeft: "10px" } } },
    { key: 'ASE', text: 'Automated Supplier Engagement ID', generalText: 'Automated Supplier Engagements', styles: { field: { marginLeft: "10px" } } }
  ];
  const engagementheaders : IExcelHeaders[] =      [{header: 'Engagement Id',key: 'Engagement Id'}];
  const supplierengagementheaders : IExcelHeaders[] =      [{header: 'Supplier Engagement Id',key: 'Supplier Engagement Id'}];
  const automatedengagementheaders : IExcelHeaders[] =      [{header: 'Automated  Engagement Id',key: 'Automated  Engagement Id'}];
  const automatedsupplierengagementheaders : IExcelHeaders[] =      [{header: 'Automated  Supplier Engagement Id',key: 'Automated  Supplier Engagement Id'}];
  const [selectedSearchByKey, setSelectedSearchByKey] = useState<string>('E');
  const [showGrids, setShowGrids] = useState<boolean>(false);
  const getSearchByText = () => { return searchByValues.find(t => t.key === selectedSearchByKey); }
  const [selectedSearchBy, setSelectedSearchBy] = useState<any>(getSearchByText());
  const [enableAddRecordsButton, setEnableAddRecordsButton] = useState<boolean>(false);
  const [enableCheckRecordsButton, setEnableCheckRecordsButton] = useState<boolean>(false);
  const [enableRemoveRecordsButton, setEnableRemoveRecordsButton] = useState<boolean>(false);
  const [rolloverData, setRolloverData] = useState<IRolloverResponse[]>();
  const [searchByTextboxIds, setSearchByTextboxIds] = useState<string>('');
  const [searchByExcelIds, setSearchByExcelIds] = useState<string>('');
  const [selectedNotRolloverGridIds, setSelectedNotRolloverGridIds] = useState<string>('');
  const [selectedRolloverGridIds, setSelectedRolloverGridIds] = useState<string>('');
  const _columns: IColumn[] = [{ key: 'Id', name: 'Id', fieldName: 'Id', minWidth: 162, maxWidth: 162, isResizable: false }];
  const fileRef = useRef<HTMLInputElement>();
  const jsonDataEngagements = [{ "Engagement Id": "" }]
  const jsonDataSupplierEngagements = [{ "Supplier Engagement Id": "" }]
  const jsonDataAutoEngagements = [{ "Automated  Engagement Id": "" }]
  const jsonDataAutoSupplierEngagements = [{ "Automated  Supplier Engagement Id": "" }]


  const onSearchByOptionSetChange = (ev: React.SyntheticEvent<HTMLElement>, option: IChoiceGroupOption) => {
    setSelectedSearchByKey(option.key.toString());
    var searchData = searchByValues.find(t => t.key === option.key.toString())
    setSelectedSearchBy(searchData);
    setShowGrids(false);
  }
  const _selection = new Selection({
    onSelectionChanged: () => {
      _getSelectionDetails();
    },
  });

function _getSelectionDetails() {
    setSelectedRolloverGridIds(_selection.getSelection().map((t) => t['Id']).toString());
    setEnableRemoveRecordsButton(_selection.getSelection().length > 0);
}

  useEffect(() => {
    setSelectedSearchByKey('E');
    setRolloverData([]);
    const fetchData = async () => {
      await gtoService.getUserRoles().then((response) => {
        setUserData(response);
      });

      await gtoService.getFiscalYearToRollover().then((response) => {
        if (response !== null) {
          setRollOverYear(response);
        }
      });

    };
    fetchData();

  }, []);

  let elements = Array.from(document.getElementsByClassName('editableGrid') as HTMLCollectionOf<HTMLElement>);
  elements.forEach((parentElement) => {
    let nestedCommandBar = Array.from(parentElement.getElementsByClassName('ms-CommandBar') as HTMLCollectionOf<HTMLElement>);
    nestedCommandBar.forEach((nestedElement) => {
      nestedElement.remove();
    })
  });

  const getColumns = (headerName: string) => {
    return _columns.map((column) => {
      return {
        ...column,
        text: headerName
      };
    });
  }

  const validateInteger = (value: string): string => {
    if (isValidInteger(value)) {
      setEnableCheckRecordsButton(true);
      return "";
    }
    else {
      setEnableCheckRecordsButton(false);
      return ErrorMessages.ValidIntegerError;
    }
  };

  async function exportRolloverData() {
    var searchByIds = searchByTextboxIds.length > 0 ? searchByTextboxIds : searchByExcelIds;
    await gtoService.checkRolloverStatus(rollOverYear - 1, selectedSearchByKey, searchByIds).then((response) => {
      var data: any;
      var headers: IExcelHeaders[] = [];
      if (selectedSearchBy.key === "E") {
        var engagements: any = [];
        response.forEach(t => {
          engagements.push({ "EngagementId": t.Id, "RolloverStatus": (t.EngagementId === null ? "Invalid" : (t.EngagementSyncToYr ? "Rollover" : "Not Rollover")) });
        })
        headers.push({ header: 'Engagement Id', key: 'EngagementId' });
        headers.push({ header: 'Rollover Status', key: 'RolloverStatus' });
        headers.push({ header: 'Rollover', key: 'Rollover' });
        data = engagements;
      }
      else if (selectedSearchBy.key === "SE") {
        var supplierEngagements: any = [];
        response.forEach(t => {
          supplierEngagements.push({ "SupplierEngagementId": t.Id, "RolloverStatus": (t.EngagementId === null ? "Invalid" : (t.EngagementSyncToYr ? "Rollover" : "Not Rollover")) });
        })
        headers.push({header: 'Supplier Engagement Id',key: 'SupplierEngagementId'});
        headers.push({header: 'Rollover Status',key: 'RolloverStatus'});
        headers.push({header: 'Rollover',key: 'Rollover'});
        data = supplierEngagements;
      }else if (selectedSearchBy.key === "AE") {
        var automatedEngagements: any = [];
        response.forEach(t => {
          automatedEngagements.push({ "AutomatedEngagementId": t.Id, "Rollover Status": (t.EngagementId === null ? "Invalid" : (t.EngagementSyncToYr ? "Rollover" : "Not Rollover")) });
        })
        headers.push({header: 'Automated Engagement Id',key: 'AutomatedEngagementId'});
        headers.push({header: 'Rollover Status',key: 'RolloverStatus'});
        headers.push({header: 'Rollover',key: 'Rollover'});
        data = automatedEngagements;
      }else if (selectedSearchBy.key === "ASE") {
        var automatedSupplierEngagements: any = [];
        response.forEach(t => {
          automatedSupplierEngagements.push({ "AutomatedSupplierEngagementId": t.Id, "Rollover Status": (t.EngagementId === null ? "Invalid" : (t.EngagementSyncToYr ? "Rollover" : "Not Rollover")) });
        })
        data = automatedSupplierEngagements;
        headers.push({header: 'Automated Supplier Engagement Id',key: 'AutomatedSupplierEngagementId'});
        headers.push({header: 'Rollover Status',key: 'RolloverStatus'});
        headers.push({header: 'Rollover',key: 'Rollover'});
      }
      exportToXlsx(data, headers, selectedSearchBy.generalText);
    });
  }

  async function checkRecordStatus() {
    setIsFormLoading(true);
    setShowGrids(true);
    var searchByIds = searchByTextboxIds.length > 0 ? searchByTextboxIds : searchByExcelIds;
    await gtoService.checkRolloverStatus(rollOverYear - 1, selectedSearchByKey, searchByIds).then((response) => {
      setRolloverData(response);
      setIsFormLoading(false);
      setShowGrids(true);
    });
  }

  const onAddRecordsRollover = async () => {
    setIsFormLoading(true);
    await gtoService.updateBulkRolloverData(rollOverYear, selectedSearchByKey, selectedNotRolloverGridIds, false).then((response) => {
      checkRecordStatus();
    });
  }

  const onRemoveRecordsRollover = async () => {
    setIsFormLoading(true);
    await gtoService.updateBulkRolloverData(rollOverYear, selectedSearchByKey, selectedRolloverGridIds, true).then((response) => {
      checkRecordStatus();
    });
  }

  const fileOnChange = (event) => {
    setShowGrids(false);
    setSearchByTextboxIds('');
    let fileName = event.target.files[0].name;
    var ext: string = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
    let isValid: boolean = true;
    if (ext.toLowerCase() === "xlsx") {
      try {
        readXlsxFile(event.target.files[0], { sheet: 'data' })
          .then((rows: any) => {
            if (rows && rows.length > 1 &&
              ((rows[0][0].toString() === Object.keys(jsonDataEngagements[0])[0].toString())
                || (rows[0][0].toString() === Object.keys(jsonDataSupplierEngagements[0])[0].toString())
                || (rows[0][0].toString() === Object.keys(jsonDataAutoEngagements[0])[0].toString())
                || (rows[0][0].toString() === Object.keys(jsonDataAutoSupplierEngagements[0])[0].toString()))
            ) {
              let arrayExcelData: any = [];
              rows.slice(1).forEach((element: any) => {
                if (isValidInteger(element[0])) {
                  arrayExcelData.push(element[0]);
                }
                else {
                  isValid = false;
                }
              });
              if (isValid) {
                setFileName(event.target.files[0].name);
                setSearchByExcelIds(arrayExcelData.toString());
                setEnableCheckRecordsButton(true);
              }
              else {
                toast.error(
                  "The uploaded file contains invalid integers.",
                  {
                    className: css({
                      background: '#a80000 !important',
                    }),
                  }
                );
              }
              event.target.value = null;
            }
            else if (rows.length === 1) {
              event.target.value = null;
              toast.error(
                "The uploaded file is blank.",
                {
                  className: css({
                    background: '#a80000 !important',
                  }),
                }
              );
            }
            else {
              event.target.value = null;
              toast.error(
                "The uploaded file is invalid, please download the template and re-upload.",
                {
                  className: css({
                    background: '#a80000 !important',
                  }),
                }
              );
            }
          })
          .catch(() => {
            event.target.value = null;
            toast.error(
              "The uploaded file is invalid, please download the template and re-upload.",
              {
                className: css({
                  background: '#a80000 !important',
                }),
              }
            );
          });
      }
      catch (ex) {
        event.target.value = null;
        toast.error(
          "The uploaded file is invalid, please download the template and re-upload.",
          {
            className: css({
              background: '#a80000 !important',
            }),
          }
        );
      }
    }
    else {
      event.target.value = null;
      toast.error(
        "only xlsx file allowed.",
        {
          className: css({
            background: '#a80000 !important',
          }),
        }
      );
    }

  };

  const onGridSelectionChange = (items: any, isAlreadyRollOver: boolean = false) => {
    if (isAlreadyRollOver) {
      setSelectedRolloverGridIds(items.map(t => t.Id));
      setEnableRemoveRecordsButton(items.length > 0);
    }
    else {
      setSelectedNotRolloverGridIds(items.map(t => t.Id));
      setEnableAddRecordsButton(items.length > 0);
    }
  }

  return (
    <React.Fragment>

      {isFormLoading && <Spinner size={SpinnerSize.small} styles={RollOverPageStyles.spinnerLoadingIcon} >
        Loading...
      </Spinner>
      }
      <HelmetTab title={'Rollover'} />
      <h1 style={RollOverPageStyles.h1Style} aria-label={'Rollover'} >  Rollover
      </h1>
      <div style={RollOverPageStyles.mainHeight}>
        <Stack>
          <div style={RollOverPageStyles.divHeightScrollPanel}>
            <ScrollablePane>
              <div className="ms-Grid" dir="ltr" style={RollOverPageStyles.alignCentre}>
                <div className="ms-Grid-row" style={RollOverPageStyles.marginLeft10}>
                  <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1" >
                    &nbsp;
                  </div>
                  <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg7" >
                    <div style={RollOverPageStyles.clearBoth}>
                      <div style={RollOverPageStyles.floatLeftMarginTop10}>Search By:</div> <ChoiceGroup selectedKey={selectedSearchByKey}
                        options={searchByValues}
                        ariaLabelledBy="Search By"
                        styles={RollOverPageStyles.choiceGroupStyle}
                        onChange={onSearchByOptionSetChange}
                      />
                    </div>
                  </div>
                  <div className="ms-Grid-col ms-sm5 ms-md5 ms-lg5" >
                    &nbsp;
                  </div>
                </div>
              </div>
              <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row" style={RollOverPageStyles.marginTopLeft10}>
                  <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1" >
                    &nbsp;
                  </div>
                  <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                    <TextField label={getSearchByText().text + "(s)"} multiline rows={3}
                      onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => { setSearchByTextboxIds(newValue); fileRef.current.value = ""; setFileName("or drag and drop files here"); setShowGrids(false); }}
                      ariaLabel={selectedSearchBy.text + "(s)"}
                      onGetErrorMessage={validateInteger}
                      value={searchByTextboxIds}
                      resizable={false}
                      onRenderLabel={CustomTextboxLabelRender(selectedSearchByKey === "E" ? UIMessages.RollOverEngagementNote : (selectedSearchByKey === "SE" ? UIMessages.RollOverSupplierEngagementNote : UIMessages.RollOverAutomatedSupplierEngagementNote))}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1" style={RollOverPageStyles.paddingTopLeft46}>
                    <label aria-label='or'>(or)</label>
                  </div>
                  <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4" style={RollOverPageStyles.marginTop25Left10}>
                    <div className="file-drop-area">
                      <span className="fake-btn">Choose files</span>
                      <span className="file-msg" >{fileName}</span>
                      <input aria-label='upload a excel file' className="file-input"
                        type="file" onChange={(event) => fileOnChange(event)}
                        ref={fileRef}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                    </div>
                  </div>

                  <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1" style={RollOverPageStyles.paddingTop51} >
                    <Link onClick={() => exportToXlsx(selectedSearchBy.key === "E" ? jsonDataEngagements : (selectedSearchBy.key === "SE" ? jsonDataSupplierEngagements : (selectedSearchBy.key === "AE" ? jsonDataAutoEngagements :jsonDataAutoSupplierEngagements)),selectedSearchBy.key === "E" ? engagementheaders : (selectedSearchBy.key === "SE" ? supplierengagementheaders : (selectedSearchBy.key === "AE" ? automatedengagementheaders :automatedsupplierengagementheaders)), selectedSearchBy.generalText)}>Download Template</Link>
                  </div>
                </div>
              </div>
              <div className="ms-Grid" dir="ltr" style={RollOverPageStyles.paddingBottom10}>
                <div className="ms-Grid-row" style={RollOverPageStyles.marginTopLeft10}>
                  <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4" >
                    &nbsp;
                  </div>
                  <div className="ms-Grid-col ms-sm8 ms-md8 ms-lg8" style={RollOverPageStyles.paddingLeft54} >
                    <PrimaryButton
                      id="checkRolloverYearRecordStatusId"
                      text={"Check " + rollOverYear + " Record Status"}
                      style={RollOverPageStyles.marginLeft10}
                      onClick={checkRecordStatus}
                      data-custom-parentid="Check rollover year record status"
                      disabled={userData && (!userData.IsAdmin || !userData.IsBusinessAnalyst) && (searchByTextboxIds.trim() !== "" || searchByExcelIds.trim() !== "") && enableCheckRecordsButton ? false : true}
                    />
                  </div>
                </div>
              </div>
              {showGrids &&
                <div>
                  <div className="ms-Grid" dir="ltr" style={RollOverPageStyles.grayBorder}>
                    <div className="ms-Grid-row" style={RollOverPageStyles.marginLeft10}>
                      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 editableGrid">
                        {userData && userData.IsAdmin &&
                          <PrimaryButton
                            id="removeRolloverYear"
                            text={"Remove Records from " + rollOverYear + " (--Admin Only--)"}
                            style={RollOverPageStyles.marginLeft10}
                            onClick={async () => await onRemoveRecordsRollover()}
                            disabled={enableRemoveRecordsButton}
                            data-custom-parentid="Remove records from rollover year"
                          />
                        }
                        <PrimaryButton
                          id="addRolloverYear"
                          text={"Add Records to " + rollOverYear + " rollover"}
                          style={RollOverPageStyles.marginLeft20}
                          onClick={async () => { await onAddRecordsRollover() }}
                          disabled={(userData && (!userData.IsAdmin || !userData.IsBusinessAnalyst) && enableAddRecordsButton) ? false : true}
                          data-custom-parentid="Add records to rollover year"
                        />
                        <PrimaryButton
                          id="exportRolloverYear"
                          text={"Export " + searchByValues.find(t => t.key === selectedSearchByKey).generalText}
                          style={RollOverPageStyles.marginLeft20}
                          onClick={async () => await exportRolloverData()}
                          disabled={rolloverData && rolloverData.length === 0}
                          data-custom-parentid="Export records to rollover year"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row" style={RollOverPageStyles.marginTopLeft10}>
                      <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg4 editableGrid">
                        <DetailsList
                          items={rolloverData && rolloverData.filter(t => (selectedSearchByKey === "E" && t.EngagementId !== null && t.EngagementSyncToYr === true) || (selectedSearchByKey === "SE" && t.SupplierEngagementId !== null && t.SupplierEngagementSyncToYr === true)||
                          (selectedSearchByKey === "AE" && t.EngagementId !== null && t.EngagementSyncToYr === true) || (selectedSearchByKey === "ASE" && t.SupplierEngagementId !== null && t.SupplierEngagementSyncToYr === true))}
                          columns={getColumns("Rolled over")}
                          setKey="set"
                          layoutMode={DetailsListLayoutMode.justified}
                          selectionPreservedOnEmptyClick={true}
                          ariaLabelForSelectionColumn="Toggle selection"
                          ariaLabelForSelectAllCheckbox="Toggle selection for Rolled over items"
                          checkButtonAriaLabel="select Rolled over row"
                          selectionMode={SelectionMode.none}
                          styles={{
                            root: {
                              height: '32vh',
                            },
                          }}
                        />
                      </div>
                      <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg4 editableGrid">
                        <DetailsList
                          items={rolloverData && rolloverData.filter(t => (selectedSearchByKey === "E" && t.EngagementId !== null && t.EngagementSyncToYr === false) || (selectedSearchByKey === "SE" && t.SupplierEngagementId !== null && t.SupplierEngagementSyncToYr === false)
                          ||(selectedSearchByKey === "AE" && t.EngagementId !== null && t.EngagementSyncToYr === false) || (selectedSearchByKey === "ASE" && t.SupplierEngagementId !== null && t.SupplierEngagementSyncToYr === false))}
                          columns={getColumns("Not Yet Rollover")}
                          setKey="set"
                          layoutMode={DetailsListLayoutMode.justified}
                          selectionPreservedOnEmptyClick={true}
                          ariaLabelForSelectionColumn="Toggle selection"
                          ariaLabelForSelectAllCheckbox="Toggle selection for Not Yet Rollover items"
                          checkButtonAriaLabel="select Not Yet Rollover row"
                          selection={_selection}
                          // onGridSelectionChange={(items) => onGridSelectionChange(items)}
                          styles={{
                            root: {
                              height: '32vh',
                            },
                          }}
                        />
                      </div>
                      <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg4 editableGrid">
                        <DetailsList
                          items={rolloverData && rolloverData.filter(t => (selectedSearchByKey === "E" && t.EngagementId === null) || (selectedSearchByKey === "SE" && t.SupplierEngagementId === null)
                          || (selectedSearchByKey === "AE" && t.EngagementId === null)
                          || (selectedSearchByKey === "ASE" && t.SupplierEngagementId === null))}
                          columns={getColumns("Invalid")}
                          setKey="set"
                          layoutMode={DetailsListLayoutMode.justified}
                          selectionPreservedOnEmptyClick={true}
                          ariaLabelForSelectionColumn="Toggle selection"
                          ariaLabelForSelectAllCheckbox="Toggle selection for Invalid items"
                          checkButtonAriaLabel="select Invalid row"
                          styles={{
                            root: {
                              height: '32vh',
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              }
            </ScrollablePane>
          </div>
        </Stack>
      </div>
    </React.Fragment>
  )
}

export default Rollover;