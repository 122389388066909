import * as React from 'react';
import './Reports.css';
import { Stack } from '@fluentui/react/lib/Stack';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import reportimg from '../../assets/images/Reportimg.png';
import { Link } from 'office-ui-fabric-react/lib/Link';
import {
  TelemetryEvent,
  Routes,
  TelemetryException,
} from '../../shared/models';
import telemetryContext from '../../shared/services/TelemetryServices';
import gtoNewApiInstance, {
  gtoMsalApiFetch,
} from '../../shared/http/GtoApiNew.axios';
import appConfig from '../../assets/configuration/config';
import { toast } from 'react-toastify';
import { css} from 'glamor';
import { delay } from 'q';
import { v4 as uuidv4 } from 'uuid';
import HelmetTab from "../../shared/Helmet/Helmet";


export interface reportstate {
  items: any;
  filterRootNode: any;
}


class Reports extends React.Component<{}, reportstate> {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      filterRootNode: [],
    };
  }
  public componentDidMount() {
   this.getAllReport();
  }

  getAllReport = async () => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };

      // Defining the url
      let url: string = Routes.getAllReport;

      // Logging telemetry for NRC Categories api call.
       telemetryContext.logEvent(TelemetryEvent.FetchAllReportBegin, {
          url,
          headers,
        });

      // Making the get call.
      return gtoMsalApiFetch(gtoNewApiInstance.get, url, {
        headers,
      })
        .then((response: any) => {
          this.setState({
            items: response.data,
            filterRootNode: response.data.find((x) => x.ReportParent_ID === 0),
          });

        })
        .catch((error) => {
         // Log Exception
            telemetryContext.logException(
                error.toString(),
                TelemetryException.FetchAllReportFailure,
                undefined,
                {
                    url: url,
                    headers,
                }
            );
          //Error handling.
          if (error.responce && error.responce.status === 404) {
            toast.error('Unable to fetch All reports.', {
              className: css({
                background: '#a80000 !importanat',
              }),
            });
          }
          throw error;
        });
    } else {
      //setting mock data.
      return delay(2000).then(() => {

      });
    }
  };
  onrenderreports = () => {
     return this.state.items.map((attr, key) => {
      return (
        <div key={key}>
          <ul>
            <li>
            {this.state.filterRootNode.Report_ID === attr.ReportParent_ID && (
              <div>
              <div
                  className="ulinnerclass"
                >
                  <IconButton aria-label={attr.ReportName}
                    style={{
                      height: '20px',
                      textAlign: 'center',
                      color: '#ffd283',
                    }}
                    iconProps={{ iconName: 'FabricFolderFill' }}
                  />
                  {attr.ReportName}{' '}

                </div>
                <div className="list1">{this.renderChild(attr.Report_ID,key)}</div>
                </div>
            )}
          </li>
          </ul>
        </div>
      );
    });
  };
  renderChild = (value,parentKey) => {
    return this.state.items.map((attr, key) => {
      return (
        <div key={key}>
          <ul>
            <li>
            {value === attr.ReportParent_ID && attr.ReportPath === '' ? (
              <div>
               <div
                  className="ulinnerclass"
                >
                  {' '}
                  <IconButton aria-label={attr.ReportName}
                    style={{
                      height: '20px',
                      textAlign: 'center',
                      color: '#ffd283',
                    }}
                    data-automation-id="test"
                    iconProps={{ iconName: 'FabricFolderFill'}}
                  />
                  {attr.ReportName}
                </div>

              <div className="liinnerclass">
                  {this.renderSubChild(attr.Report_ID)}
                </div>
              </div>
            ) : (
              value === attr.ReportParent_ID &&
              attr.ReportPath !== '' && (
                <div className="liinnerclass">
                  {' '}
                  <IconButton aria-label={attr.ReportName}
                    style={{
                      height: '20px',
                      textAlign: 'center',
                    }}
                    iconProps={{ iconName: 'OpenFile' }}
                    />
                  <Link target="_blank" href={attr.ReportPath} aria-label={attr.ReportName}>
                    {attr.ReportName}
                  </Link>
                </div>
              )
            )}
            </li>
          </ul>
        </div>
      );
    });
  };

  renderSubChild = (value) => {
    return this.state.items.map((attr, key) => {
      return (
        <div key={key}>
          <ul>
            <li>
            {value === attr.ReportParent_ID && (
              <div>
                <div className="liinnerclass">
                  {' '}
                  <IconButton aria-label={attr.ReportName}
                    style={{ height: '20px', textAlign: 'center'}}
                    iconProps={{ iconName: 'OpenFile' }}
                  />
                  <Link target="_blank" href={attr.ReportPath}>
                    {attr.ReportName}
                  </Link>
                </div>
              </div>
            )}
            </li>
          </ul>
        </div>
      );
    });
  };

  render() {
    return (
      <Stack>
      <div style={{
        border:"1px solid black",
      }}>
        <HelmetTab title={'Reports'} />


        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div
              className="ms-Grid-col ms-sm6 ms-md4 ms-lg4"
              style={{ width: '30%', height: '100%',
            }}
            >
               <h1 style={{
              fontSize: '16px',
              display : 'inline',
              padding:'20px',
              paddingTop:'20px',
            }}>GTO Invoicing - Reports</h1>

              <ul>
                <li>
               <div
                  className="ulinnerclass"
                >
                  <IconButton aria-label={this.state.filterRootNode.ReportName} className="font"
                    style={{
                      height: '20px',
                      textAlign: 'center',
                      color: '#ffd283',
                    }}
                    iconProps={{ iconName: 'FabricFolderFill' }}
                  />{' '}
                  {this.state.filterRootNode.ReportName}
                </div>
                <div>{this.onrenderreports()}</div>
                </li>
              </ul>

            </div>
            <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg8" style={{marginLeft:'-10px',width:'68%'}}>
              <div
                style={{
                  marginLeft: '0px',
                  textAlign: 'justify',
                  height: '100%',
                  borderLeft: "1px ",
                  borderLeftStyle : "groove",
                }}
              >
                <div style={{ marginLeft: '100px', marginRight: '10px' ,marginTop:'-20px'}}>
                  <div style={{ fontSize: 'x-large', textAlign: 'center' }}>
                    <p> CE&S Outsourcing Delivery Partners</p>
                    <p>
                      {' '}
                      Report Repository from Global Tools & Operations (GTO)
                      Invoicing Tool{' '}
                    </p>
                  </div>
                  <div>
                    <br />
                    <br />
                    <p>
                      <b>
                        {' '}
                        Welcome to the GTO Reporting Repository which will be
                        our new Launch Portal for all GTO Actuals vs Plan
                        reporting !{' '}
                      </b>{' '}
                    </p>
                    <br />
                    <p>
                      {' '}
                      The left panel of this site lists the standard PowerBI
                      reports for GTO invoicing. Following is the definition of
                      the folders:{' '}
                    </p>
                    <br />
                    <br />
                    <b> Financial Reporting: </b>
                    <ul>
                      <li>
                        <b>Supplier Spend Reports:</b> Monthly, quarterly and
                        yearly spend data for CSS Delivery Partners.
                        <br />
                      </li>
                      <li>
                        <b>Audit Reports:</b> List all the EID/SEIDs for the
                        current Fiscal Year
                        <br />
                      </li>
                      <li>
                        <b>Headcount Reports:</b> Shows the HC information
                        submitted by our DPs every month
                        <br />
                      </li>
                    </ul>
                  </div>
                  <br />
                  <br />
                  <div>
                    <b>Report Security </b>
                    <p>
                      {' '}
                      Because of the sensitive data presented in the reports
                      available on this site, certain reports may not be
                      available based on permissions for a specific audience. If
                      you do not have access to a specific report or for any
                      other feedback please reach out to GTOBA{' '}
                      <a
                        style={{ color: 'blue' }}
                        href="mailto:GTOBA@microsoft.com?subject=Feedback&body=Message"
                      >
                        GTOBA@microsoft.com
                      </a>
                    </p>
                  </div>
                  <div>
                    <p>
                      For any support issues or technical questions related to
                      the reports found on this site or about the site itself,
                      please email the
                      <a
                        style={{ color: 'blue' }}
                        href="mailto:mgobtsup?subject=MGO Reports Web"
                      >
                        {' '}
                        Global Tools & Reporting (GTO) Support Alias
                      </a>
                    </p>
                  </div>
                  <br />
                  <br />
                  <div>
                    <b>Quick Help</b>
                    <p>Navigating in the Reporting Tree </p>
                    <p>
                      {' '}
                      Expand the tree and click on a report to open it. The tree
                      will remember the state of each node between visits.
                    </p>
                    <div style={{ marginLeft: '5px' }}>
                      <img
                        height="300"
                        width="300"
                        title="Report Image"
                        src={reportimg}
                        alt="Report Help Image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </Stack>
    );
  }
}
export default Reports;
