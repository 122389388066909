import { IInvoiceBillableUnitDetails } from './../models/InvoiceBillableUnitDetails';

export const InvoiceBillableUnitDetailsMockData: IInvoiceBillableUnitDetails[] = [
  {
    EngagementId: 2546,
    FiscalYearNumber: 2020,
    SupplierEngagementId: 1262,
    FunctionSummary: 'MS Store',
    FunctionDetail: 'Online',
    SupplierName: 'Tek Experts',
    BusinessSegment: 'CAPS Consumer',
    ExecPL: 'CAPS',
    Product: 'Consumer All',
    ProductGroup: 'MSStore Online',
    SupplierCity: 'Shaw',
    SupplierCountry: 'Non-Specific APGC',
    ProductSubType: 'Non-Specific',
    Offering: 'Consumer',
    LOB: 'Sales',
    MediaType: 'Chat',
    MediaSubType: 'Projects',
    SupportTier: 'Tier 1',
    Language: 'English',
    TaxPercent: 0,
    BillableUnits: 0,
    LastAction: null,
    StateId: 1,
    State: null,
    CumMonth: 545,
    PostCumMonth: 545,
    PostInvoicePeriod: 'FY20 Nov [P5]',
    CurrencyCode: 'USD',
    DiscountPercent: 2,
    TotalAmount: 0,
    GrossAmount: 0,
    DiscountAmount: 0,
    TaxAmount: 0,
    NetAmount: 0,
    PriceLine: [
      {
        PricingModel: 'PHd',
        PricingType: 'Simple',
        PricingLevel: 0,
        Price: 2795,
        VolumeMin: 0,
        VolumeMax: 0,
        PricingModelId: 3,
        PriceLineBillableUnits: 5808,
        PriceLineAmount: 353707.2,
        Index: 1,
      },
    ],
    NRCId: 0,
    NRCType: null,
    NRCCategory: null,
    NRCDescription: null,
    IsEditable: false,
    NeedsMicrosoftAcceptance: false,
    IsPriorPeriodAdjustment: false,
    IsNRC: false,
    IsVolume: true,
    SD1: 'ericsi',
    SD2: 'apearce',
    PricingModel: 'PHd',
    SupplierNumber: 245,
    CompanyCode: 1010,
    TotalAmountCD: 279500,
    GrossAmountCD: 273910,
    DiscountAmountCD: 5590,
    TaxAmountCD: 0,
    NetAmountCD: 273910,
    InvoicePrefix: 'TEX01',
    InternalOrder: '1706440',
    AccountCode: '724045',
    CostCenter: '0',
    PurchaseOrder: '98902139',
    Org: null,
    OrgDetails: null,
    Function: null,
    SalesDistrict: null,
    SalesSubLocation: null,
    ManagementEntity: null,
    SupplierId: 0,
    SupplierSite: null,
    LastActionId: null,
    Comment: null,
    ExchangeRate: null,
    ProfitCenter: null,
    InvoiceAdjRequestId: 0,
    SalesRegion: 'testregion',
    PCY: 'testPCY',
    SubPCY: 'testSubPCY',
    IsCommerce: 'testIsCommerce',
    BusinessGroup: 'testBusinessGroup',
    IsAutomationSupplierEngagement: false
  },
];
