import * as React from 'react';
import {Helmet} from "react-helmet";

 const HelmetTab = (props) =>{
    return(
        <Helmet>
        <title>{props.title !== '' ? `GTO Portal - ${props.title}` : 'GTO Portal' }</title>
    </Helmet>
    )
};

export default HelmetTab;