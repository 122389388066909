import * as React from 'react';
import { TextField, MaskedTextField } from '@fluentui/react/lib/TextField';
import { Stack, IStackProps, IStackStyles } from '@fluentui/react/lib/Stack';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Toggle } from '@fluentui/react/lib/Toggle';
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  IColumn,
  SelectionMode,
} from '@fluentui/react/lib/DetailsList';
import { MarqueeSelection } from '@fluentui/react/lib/MarqueeSelection';
import { Panel, PanelType } from 'office-ui-fabric-react';
import { Dropdown, IDropdownOption, IDropdown } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import {
  IconButton,
} from 'office-ui-fabric-react/lib/Button';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';


export interface IPanelLargeExampleState {
  showPendingPanel: boolean;
  showaddpanel:boolean;
  showeditpanel:boolean;
  showfilterpanel:boolean;
  selectionDetails: any;
  items: [];
  panelflag: any;
  showEditPanel: boolean;
  filterOptions: IDropdownOption[];
  showsuppliercontacts:boolean;
}
const dropDownRef = React.createRef<IDropdown>();

class Engagementidattribute extends React.Component<{}, IPanelLargeExampleState> {
  private _selection: Selection;
  private _selectionadd: Selection;
  private _selectionpending: Selection;
  private _allItems: any;
  private _columns: IColumn[];
  private _filterItems:any;
  private _filtercolumns:IColumn[];
  private _pendingallItems: any;
  private _addnewuserItems: any;

  constructor(props: {}) {
    super(props);

    this._selection = new Selection({
      onSelectionChanged: () =>
        this.setState({ selectionDetails: this._getSelectionDetails() }),
    });
    this._selectionadd = new Selection({
      onSelectionChanged: () =>
        this.setState({ selectionDetails: this._getSelectionDetails() }),
    });
    this._selectionpending = new Selection({
      onSelectionChanged: () =>
        this.setState({ selectionDetails: this._getSelectionDetails() }),
    });

    this._allItems = [];
    this._filterItems = [];
    this._filterItems =[{

        columnname: 'Exec Pl',
        filteroperator: 'Contains',
        columnvalue: 'asd',
    }]

    for (let i = 0; i < 20; i++) {
      if (i % 2 === 0) {
        this._allItems.push({
          key: i,
          execpl: 'ASG',
          fyconsumer: false,
          fycommercial: true,
          nextfyconsumer: false,
          nextfycommercial: true,
        });
      } else {
        this._allItems.push({
          key: i,
          execpl: 'BOB',
          fyconsumer: true,
          fycommercial: false,
          nextfyconsumer: true,
          nextfycommercial: false,
        });
      }
    }


    this._columns = [
      {
        key: 'column1',
        name: 'ExecPl ',
        fieldName: 'execpl',
        minWidth: 200,
        maxWidth: 300,
        isResizable: true,
      },
      {
        key: 'column2',
        name: 'FY Consumer	',
        fieldName: 'fyconsumer',
        minWidth: 200,
        maxWidth: 300,
        isResizable: true,
      },
      {
        key: 'column3',
        name: 'FY Commercial	',
        fieldName: 'fycommercial',
        minWidth: 200,
        maxWidth: 300,
        isResizable: true,
      },
      {
        key: 'column4',
        name: 'Next FY Consumer	',
        fieldName: 'nextfyconsumer',
        minWidth: 200,
        maxWidth: 300,
        isResizable: true,
      },
      {
        key: 'column5',
        name: 'Next FY Commercial',
        fieldName: 'nextfycommercial',
        minWidth: 200,
        maxWidth: 300,
        isResizable: true,
      }
     
    ];
    this._filtercolumns = [
        {
          key: 'column1',
          name: 'Column Name ',
          fieldName: 'columnname',
          minWidth: 100,
          maxWidth: 200,
          isResizable: true,
        },
        {
          key: 'column2',
          name: 'Filter Operator',
          fieldName: 'filteroperator',
          minWidth: 100,
          maxWidth: 200,
          isResizable: true,
        },
        {
          key: 'column3',
          name: 'Column Value ',
          fieldName: 'columnvalue',
          minWidth: 100,
          maxWidth: 200,
          isResizable: true,
        },
        {
          key: 'column4',
          name: 'Delete',
          fieldName: 'delete',
          minWidth: 100,
          maxWidth: 200,
          isResizable: true,
          onRender:this.renderDeleteIcon
        },
       
      ];

    this.state = {
      items: this._allItems,
      selectionDetails: this._getSelectionDetails(),
      showPendingPanel: false,
      panelflag: '',
      showEditPanel: false,
      filterOptions: [],
      showsuppliercontacts:false,
      showaddpanel:false,
      showeditpanel:false,
      showfilterpanel:false
    };
  }

  private _getSelectionDetails(): string {
    const selectionCount = this._selection.getSelectedCount();
    return this._allItems[0];
    switch (selectionCount) {
      case 0:
        return 'No items selected';
      default:
        return `${selectionCount} items selected`;
    }
  }


  onclickaddbutton = () =>{
    this.setState({
      showaddpanel:true
    })
  }
  ondismissadd = () =>{
    this.setState({
        showaddpanel:false
    })
  }
  onclickeditbutton = () => {
    this.setState({
      showeditpanel: true,
    });
  };
  ondismissEdit = () => {
    this.setState({
        showeditpanel: false,
    });
  };
  onclickfilterbutton = () =>{
    this.setState({
      showfilterpanel:true
    })
  }
  ondismissfilter = () =>{
    this.setState({
        showfilterpanel:false
    })
  }
  private renderDeleteIcon = (item: any): JSX.Element => {
    return (
      <TooltipHost
        content={'Delete'}
        id={'delete'}
        calloutProps={{ gapSpace: 0 }}
      >
        <IconButton
          style={{ height: '10px', textAlign: 'center' }}
          data-automation-id="test"
          iconProps={{ iconName: 'delete' }}
          
        />
      </TooltipHost>
    );
  };
 
 

  render() {
    return (
      <div>
        <h1
          style={{ padding: '10px', fontSize: '18.72px' }}
          aria-label={'Suppliers'}
        >
          EngagementID Attributes
        </h1>
        <Stack>
          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row" style={{ marginLeft: '10px' }}>
              <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg6">
              <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg6">
              <Dropdown
                    placeholder="Attribute"
                    ariaLabel="attribute"
                    options={[
                      { key: 'A', text: 'Option a', title: 'I am option a.' },
                      { key: 'B', text: 'Option b' },
                      { key: 'C', text: 'Option c', disabled: true },
                      { key: 'D', text: 'Option d' },
                      { key: 'E', text: 'Option e' },
                    ]}
                    required={false}
                  />                </div>
               
                {/* <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2">
                <Dropdown
                    placeholder="Fiscal Year"
                    ariaLabel="Fiscal Year"
                    options={[
                      { key: 'A', text: '2019', title: 'I am option a.' },
                      { key: 'B', text: '2020' },
                      { key: 'C', text: '2021', },
                      { key: 'D', text: '2022' },
                    ]}
                    required={false}
                  />              
                </div> */}
                <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2">
                <Toggle label="Only Active"  inlineLabel />
               
                </div>

              </div>
              
            </div>
          </div>

          <div className="ms-Grid" dir="ltr" style={{marginTop:'10px'}}>
            <div
              className="ms-Grid-row "
              style={{ marginLeft: '10px', margin: '10px' }}
            >
              <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg4">
                <PrimaryButton
                  text="Add"
                  iconProps={{ iconName: 'Add' }}
                  style={{ marginRight: '2px' }}
                  onClick={() => this.onclickaddbutton()}
                />

                <PrimaryButton
                  text="Edit"
                  iconProps={{ iconName: 'EditNote' }}
                  style={{ marginRight: '2px' }}
                  onClick={() => this.onclickeditbutton()}
                />
                <PrimaryButton
                  text="Remove"
                  iconProps={{ iconName: 'delete' }}
                  style={{ marginRight: '2px' }}
                />
                <PrimaryButton
                  text="Filter"
                  iconProps={{ iconName: 'Filter' }}
                  style={{ marginRight: '2px' }}
                  onClick={() => this.onclickfilterbutton()}
                />
                <PrimaryButton
                  text="Clear Filters"
                  iconProps={{ iconName: 'ClearFilter' }}
                />
              </div>
             

                {/* <div style={{float:'right'}}>

              <DefaultButton
                      text="Action"
                      primary
                      split
                      splitButtonAriaLabel="See 2 options"
                      aria-roledescription="split button"
                      menuProps={{
                          items: [
                              {
                                  key: 'delete',
                                  text: 'Delete',
                                  iconProps: { iconName: 'Mail' },
                                },
                            {
                              key: 'archive',
                              text: 'Archive',
                              iconProps: { iconName: 'Mail' },
                            },
                            ],
                        }}
                        disabled={false}

                  /> 
                  </div> */}
            </div>
          </div>

          <Panel
          isOpen={this.state.showaddpanel}
          onDismiss={this.ondismissadd}
          type={PanelType.smallFixedFar}
          closeButtonAriaLabel="Close"
          headerText={'ExecPl'}>

            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                <TextField placeholder="Attribute" required />
              </div>
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                <Toggle label="Consumer"  inlineLabel />
              </div>
              <div className="ms-Grid-row" style={{ margin: '10px' }}>
                <Toggle label="Commercial"  inlineLabel />
              </div>
                <div className=" ms-Grid-row addNrcButtonGroup" style={{ margin: '10px' }}>
                <DefaultButton
                  data-automation-id="cancelBtn"
                  text="Cancel"
                  onClick={() => {
                    this.ondismissadd()
                  }}
                  style={{ margin: '10px' }}
                />
                <PrimaryButton
                  data-automation-id="submitBtn"
                  role="button"
                  text="Ok"
                  // onClick={() => this.onClickSubmitProxy()}
                  style={{ float: 'right', margin: '10px 0px' }}
                />
              </div>
            </div>
            </Panel>
            <Panel
          isOpen={this.state.showeditpanel}
          onDismiss={this.ondismissEdit}
          type={PanelType.medium}
          closeButtonAriaLabel="Close"
          headerText={'Supplier Sites'}>

            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                <Dropdown
                    label="Supplier"
                    placeholder="Supplier"
                    ariaLabel="Supplier"
                    options={[
                      { key: 'A', text: 'Option a', title: 'I am option a.' },
                      { key: 'B', text: 'Option b' },
                      { key: 'C', text: 'Option c', disabled: true },
                      { key: 'D', text: 'Option d' },
                      { key: 'E', text: 'Option e' },
                    ]}
                    required={true}
                  />                </div>
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                  <Dropdown
                    label="Supplier City"
                    placeholder="Supplier City"
                    ariaLabel="Supplier City"
                    options={[
                      { key: 'A', text: 'Option a', title: 'I am option a.' },
                      { key: 'B', text: 'Option b' },
                      { key: 'C', text: 'Option c', disabled: true },
                      { key: 'D', text: 'Option d' },
                      { key: 'E', text: 'Option e' },
                    ]}
                    required={true}
                  />
                </div>
                <div className=" ms-Grid-row addNrcButtonGroup" style={{ margin: '10px' }}>
                <DefaultButton
                  data-automation-id="cancelBtn"
                  text="Cancel"
                  onClick={() => {
                    this.ondismissEdit()
                  }}
                  style={{ margin: '10px' }}
                />
                <PrimaryButton
                  data-automation-id="submitBtn"
                  role="button"
                  text="Save"
                  // onClick={() => this.onClickSubmitProxy()}
                  style={{ float: 'right', margin: '10px 0px' }}
                />
              </div>
            </div>
            </Panel>

          <Panel
            isOpen={this.state.showfilterpanel}
            onDismiss={this.ondismissfilter}
            type={PanelType.medium}
            closeButtonAriaLabel="Close"
            headerText={'Suppliers Sites Filters'}
          >
               <div className="ms-Grid" dir="ltr" style={{marginTop:'15px'}}>
            <div className="ms-Grid-row" style={{ marginLeft: '10px' }}>
              <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg3">
              <Dropdown
                    placeholder="Column Name"
                    ariaLabel="Column Name"
                    options={[
                      { key: 'A', text: 'Exec Pl', title: 'I am option a.' },
                      { key: 'B', text: 'FY Consumer' },
                      { key: 'C', text: 'FY Commercial',},
                      { key: 'D', text: 'Next FY Consumer' },
                      { key: 'E', text: 'Next FY Commercial' },
                    ]}
                    required={false}
                    />
                    </div>
               
                <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg3">
                <Dropdown
                    placeholder="Filter Operators"
                    ariaLabel="Filter Operators"
                    options={[
                      { key: 'A', text: 'contains', title: 'I am option a.' },
                      { key: 'B', text: 'is empty' },
                      { key: 'C', text: 'starts with', },
                      { key: 'D', text: 'ends with' },
                    ]}
                    required={false}
                  />              
                </div>
                <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg3">
                <TextField placeholder="Filter Value"  />             
                </div>

                <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg3">
                <PrimaryButton
                  text="Add"
                  iconProps={{ iconName: 'Add' }}
                />                
                </div>
              
              
            </div>
          </div>
          <div style={{marginTop:'10px',marginLeft:'10px'}}>
            <MarqueeSelection selection={this._selection}>
              <DetailsList
                compact={true}
                items={this._filterItems}
                columns={this._filtercolumns}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                selection={this._selection}
                selectionPreservedOnEmptyClick={true}
                selectionMode={SelectionMode.none}
                // onItemInvoked={this._onItemInvoked}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
              />
            </MarqueeSelection>
          </div>
              <div className="ms-Grid" dir="ltr">
            
              <div className=" ms-Grid-row addNrcButtonGroup" style={{ margin: '10px' }}>
              <PrimaryButton
                  data-automation-id="submitBtn"
                  role="button"
                  text="Apply"
                  // onClick={() => this.onClickSubmitProxy()}
                  style={{ float: 'right', margin: '10px 0px' }}
                />
                <DefaultButton
                  data-automation-id="cancelBtn"
                  text="Close"
                  onClick={() => {
                    this.ondismissfilter();
                  }}
                  style={{ margin: '10px' }}
                />
                
              </div>
            </div>
          </Panel>

          <div>
            <MarqueeSelection selection={this._selection}>
              <DetailsList
                compact={true}
                items={this._allItems}
                columns={this._columns}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                selection={this._selection}
                selectionPreservedOnEmptyClick={true}
                // onItemInvoked={this._onItemInvoked}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
              />
            </MarqueeSelection>
          </div>
        </Stack>
      </div>
    );
  }
}

export default Engagementidattribute;
