export interface IContract {
  contractID: number;
  supplierNumber: number;
  supplierName: string;
  expirationDate: string;
  expDateValue: number;
  contractDescription: string;
  companyCode: number;
  // type: TypeOfContract;
  typeOfContract: TypeOfContract;
  aravoEngagementId: string;
  contractNumber: number;
  IsSharedContract: boolean;
}

// TODO : change the type of Contract based on the API changes
// enum for the different types of contracts that exist
export enum TypeOfContract {
  none,
  master,
  addendumOrAmendment,
  sow,
  standalone,
  shell,
}
