import { DetailsListLayoutMode, IPivotStyles, Link, Pivot, PivotItem, PrimaryButton, ScrollablePane, SelectionMode, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import React, { useEffect, useRef, useState } from "react";
import { FunctionComponent } from "react";
import { exportToXlsx } from "../../shared/common/common-util";
import HelmetTab from "../../shared/Helmet/Helmet";
import { IInternalOrders } from "../../shared/models/IInternalOrders";
import { gtoService } from "../../shared/services/GtoService";
import { InternalOrderColumns, InternalOrderApproveColumns ,internalOrderTemplate,internalGridExportHeaders} from "./Internal-order-columns";
import { InternalOrderPageStyles } from "./internal-order-style";
import readXlsxFile from 'read-excel-file';
import { toast } from 'react-toastify';
import { css } from 'glamor';
import {DetailsList,Selection} from '@fluentui/react/lib/DetailsList';

const InternalOrder: FunctionComponent = () => {
    const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
    const [isShowImportGrid, setIsShowImportGrid] = useState<boolean>(false);
    const [duplicateRecords, setduplicateRecords] = useState<boolean>(false);
    const [currentFiscalYear, setCurrentFiscalYear] = useState<number>();
    const [internalOrders, setInternalOrders] = useState<IInternalOrders[]>([]);
    const [bulkimportdata, setbulkimportdata] = useState<any>([]);
    const [internalOrdersToApprove, setInternalOrdersToApprove] = useState<any>([]);
    const [fileName, setFileName] = useState<string>('or drag and drop file here');
    const [selectedApproveGridIds, setSelectedApproveGridIds] = useState<any>([]);
    const textFieldRef = useRef<HTMLInputElement>();

    const pivotStyles: IPivotStyles = {
        root: [{ backgroundColor: '#f3f2f1', width: "400px", borderRadius: "7px" }],
        link: [{ color: '#6d6c6b' }],
        linkIsSelected: [{
            color: 'black', backgroundColor: 'rgb(243, 242, 241)',
            //fontWeight:"bold",
            borderRadius: "7px",
            selectors: {
                ':before': {
                    borderBottom: 'none',
                }
            }
        },
        ],
        linkContent: "",
        text: "",
        count: "",
        icon: "",
        linkInMenu: "",
        overflowMenuButton: ""
    };
   const _selection = new Selection({
        onSelectionChanged: () => {
          _getSelectionDetails();
        },
      });

    function _getSelectionDetails() {
      setSelectedApproveGridIds(
        _selection.getSelection().map((t) => t['SupplierEngagementId'])
      );
    }
    useEffect(() => {
        const fetchData = async () => {
            await gtoService.getFiscalYears().then((response) => {
                setCurrentFiscalYear(response.currentFiscalYearNumber);
                setIsFormLoading(true);
                gtoService.getInternalOrders(response.currentFiscalYearNumber).then((response) => {
                    setInternalOrders(response);
                    setIsFormLoading(false);

                });
            },);

        };
        fetchData();
    }, []);

    async function bulkImportClick() {
        setIsFormLoading(true);
        await gtoService.validateInternalOrders(bulkimportdata).then((response) => {
            setIsFormLoading(false);
            if (response) {
                getInternalOrdersForApprovals();
                setIsShowImportGrid(true);
                setFileName('or drag and drop file here');
            }
        });
    }
    const readUploadFile= (e) => {
        setFileName(e.target.files[0].name);
        var fileref=e.target.files[0].name;
        var ext: string = fileref.substring(fileref.lastIndexOf('.') + 1).toLowerCase();
        if (ext.toLowerCase() === "xlsx") {
        readXlsxFile(textFieldRef.current.files[0])
          .then((rows: any) => {
            let finalArr=[];
            rows.filter(function(v,index){
                let _obj = {};
                if(rows[index+1] !== undefined){
                    rows[0].filter(function(key,i){
                        _obj[key] = rows[index+1][i];
                    })
                    finalArr.push(_obj);
                }
            })
           if(finalArr.length < 1){
                  toast.error(
                    `${fileref} file is blank.Please check`,
                    {
                      className: css({
                        background: '#a80000 !important',
                      }),
                    }
                  );
                     }
              else if(finalArr.length > 1){
                  let arrayVal = [];
                  let duplicatedrows =[]
                  finalArr.filter(function(i,v){
                      if(JSON.stringify(arrayVal).indexOf(JSON.stringify(i)) === -1){
                        arrayVal.push(i);  
                      }else{
                        duplicatedrows.push(v+2);
                        setduplicateRecords(true)
                        toast.error(
                          `row number ${v+2} in ${fileref} is duplicate. please remove duplicates and upload`,
                          {
                            className: css({
                              background: '#a80000 !important',
                            }),
                          }
                        );
                      }
                  })
          }
          setbulkimportdata(finalArr);
        })
      }else{
        toast.error('only xlsx file allowed.', {
          className: css({
            background: '#a80000 !important',
          }),
        });
      }
      }

    const onPivotTabChange = async (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
        setIsFormLoading(true);
        await gtoService.getInternalOrders(currentFiscalYear).then((response) => {
            setInternalOrders(response);
            setIsFormLoading(false);
        });
        await gtoService.getInternalOrdersForApproval().then((response) => {
            setInternalOrdersToApprove(response);
            setIsFormLoading(false);
            if (response.length > 0) {
                setIsShowImportGrid(true)
            }
        });
    }

    async function getInternalOrdersForApprovals() {
        await gtoService.getInternalOrdersForApproval().then((response) => {
            setInternalOrdersToApprove(response);
            if (response.length > 0) {
                setIsShowImportGrid(true)
            }
        });
    }
    async function ApproveInternalorders() {
        await gtoService.approveInternalOrders(selectedApproveGridIds).then((response) => {
        });
    }

    return (
      <React.Fragment>
        {isFormLoading && (
          <Spinner
            size={SpinnerSize.small}
            styles={InternalOrderPageStyles.spinnerLoadingIcon}
          >
            Loading...
          </Spinner>
        )}
        <div style={{ height: '100%' }}>
          <HelmetTab title={'Internal Order'} />
          <h1
            style={InternalOrderPageStyles.h1Style}
            aria-label={'Internal Order'}
          >
            {' '}
            Internal Order
          </h1>
          <div style={InternalOrderPageStyles.mainHeight}>
            <Stack>
              <div
                className="ms-Grid"
                dir="ltr"
                style={{ paddingLeft: '20px' }}
              >
                <div className="ms-Grid-row" dir="ltr">
                  <Pivot onLinkClick={onPivotTabChange} styles={pivotStyles}>
                    <PivotItem
                      headerText="Supplier Engagements"
                      aria-label="Supplier Engagements tab"
                    >
                      <div style={{ width: '100%' }}>
                        <div className="ms-Grid " dir="ltr">
                          <div
                            className="ms-Grid-row"
                            style={{
                              paddingTop: '20px',
                              paddingBottom: '20px',
                              textAlign: 'center',
                              borderBottom: '1px solid rgb(237, 235, 233)',
                            }}
                          >
                            <div>
                              Fiscal year : <b>{currentFiscalYear}</b>
                            </div>
                          </div>
                          <div
                            className="ms-Grid-row"
                            style={{ paddingTop: '23px' }}
                          >
                            <div>
                              <PrimaryButton
                                text={'Export'}
                                style={InternalOrderPageStyles.marginLeft20}
                                onClick={() =>
                                  exportToXlsx(
                                    internalOrders.map(
                                      (x: IInternalOrders) => ({
                                        EngagementId: x.EngagementId,
                                        SupplierEngagementId:
                                          x.SupplierEngagementId,
                                        InternalOrder: x.InternalOrder,
                                        CostCenter: x.CostCenter,
                                        IsInvoiceActive: x.IsInvoiceActive,
                                        IsBudgetActive: x.IsBudgetActive,
                                        ExecPl: x.ExecPl,
                                        Product: x.Product,
                                        LOB: x.LOB,
                                      })
                                    ),
                                    internalGridExportHeaders,
                                    'BulkImportInternalOrder'
                                  )
                                }
                                disabled={
                                  internalOrders && internalOrders.length === 0
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <div
                            style={{
                              height: '60vh',
                              position: 'relative',
                              marginLeft: '1vw',
                            }}
                          >
                            <ScrollablePane>
                              <DetailsList
                                items={internalOrders}
                                columns={InternalOrderColumns}
                                setKey="set"
                                layoutMode={DetailsListLayoutMode.justified}
                                selectionPreservedOnEmptyClick={true}
                                selectionMode={SelectionMode.none}
                              />
                            </ScrollablePane>
                          </div>
                        </div>
                      </div>
                    </PivotItem>
                    <PivotItem
                      headerText="Upload/Approve Internal Orders"
                      aria-label="Upload/Approve Internal Orders tab"
                    >
                      <div className="ms-Grid" dir="ltr">
                        <div
                          className="ms-Grid-row"
                          style={
                            InternalOrderPageStyles.marginTopLeft20TextAlign
                          }
                        >
                          <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                            &nbsp;
                          </div>
                          <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                            <div className="file-drop-area">
                              <span className="fake-btn">Choose file</span>
                              <span className="file-msg">{fileName}</span>
                              <input
                                aria-label="upload a excel file"
                                className="file-input"
                                type="file"
                                onChange={(event) => readUploadFile(event)}
                                ref={textFieldRef}
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              />
                            </div>
                          </div>

                          <div
                            className="ms-Grid-col ms-sm1 ms-md1 ms-lg1"
                            style={InternalOrderPageStyles.paddingTop12}
                          >
                            <Link
                              onClick={() =>
                                exportToXlsx(
                                  [],
                                  internalOrderTemplate,
                                  'Internal Order'
                                )
                              }
                            >
                              Download Template
                            </Link>
                          </div>
                          <div
                            className="ms-Grid-col ms-sm2 ms-md2 ms-lg2"
                            style={InternalOrderPageStyles.paddingTop17}
                          >
                            <PrimaryButton
                              text={'Bulk Import'}
                              style={InternalOrderPageStyles.marginLeft10}
                              onClick={bulkImportClick}
                              disabled={
                                fileName === 'or drag and drop file here' ||
                                bulkimportdata.length === 0 ||
                                duplicateRecords === true
                                  ? true
                                  : false
                              }
                            />
                          </div>
                          <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                            &nbsp;
                          </div>
                        </div>
                        {isShowImportGrid && (
                          <div
                            className="ms-Grid-row"
                            style={
                              InternalOrderPageStyles.marginTopLeft10BorderBottom
                            }
                          >
                            <div className="ms-Grid-col ms-sm5 ms-md5 ms-lg5">
                              <PrimaryButton
                                text={'Approve'}
                                style={InternalOrderPageStyles.marginLeft10}
                                onClick={ApproveInternalorders}
                                disabled={
                                  selectedApproveGridIds.length > 0
                                    ? false
                                    : true
                                }
                              />
                            </div>
                          </div>
                        )}

                        {isShowImportGrid && (
                          <div
                            className="ms-Grid-row"
                            style={InternalOrderPageStyles.marginTopLeft10}
                          >
                            <div>
                              <div
                                style={{
                                  height: '50vh',
                                  position: 'relative',
                                  marginLeft: '1vw',
                                }}
                              >
                                <ScrollablePane>
                                  <DetailsList
                                    items={internalOrdersToApprove}
                                    setKey="set"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    columns={InternalOrderApproveColumns}
                                    selectionPreservedOnEmptyClick={true}
                                    selectionMode={
                                      internalOrdersToApprove.length > 0 &&
                                      SelectionMode.multiple
                                    }
                                    selection={_selection}
                                  />
                                </ScrollablePane>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </PivotItem>
                  </Pivot>
                </div>
              </div>
            </Stack>
          </div>
        </div>
      </React.Fragment>
    );
}

export default InternalOrder;