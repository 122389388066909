import * as React from 'react';
import {
  PrimaryButton,
  DefaultButton,
} from 'office-ui-fabric-react';
import { Dropdown, IDropdownOption } from '@fluentui/react';
import { connect } from 'react-redux';
import { Modal} from '@fluentui/react';
import { IInvoiceBillableUnitDetails } from '../../shared/models/InvoiceBillableUnitDetails';
import { IHeadCountMonthList } from '../../shared/models';
import{
  ISDUser,
  ISDForSEID,
} from '../../shared/models/SDProxy';

import{
  getSDUsers,
  submitSDDetails,
} from '../../store/actions/headCount.action';
import { getId } from 'office-ui-fabric-react/lib/Utilities';

export interface IAddProxyProps {
  showProxy: boolean;
  closeProxy: any;
  fiscalYear: number;
  getSDUsers: any;
  sdUsersList: ISDUser[];
  billableUnits: IInvoiceBillableUnitDetails[];
  submitSDDetails:any;
  monthList: IHeadCountMonthList[];
  loggedinUserAlias: string;
}

export interface IAddProxyState {
  isEdit: boolean;
  selectedSD: string; 
  SEIDInitialList: IDropdownOption[];
}

class AddProxy extends React.Component<IAddProxyProps, IAddProxyState> {
  private cumMonth: number;
  private titleId: string = getId('title');
  private subtitleId: string = getId('subText');

  constructor(props) {
    super(props);
    this.state = {
      isEdit: true, 
      selectedSD: '',    
      SEIDInitialList: [],      
    };
  }
  public componentDidMount() {
    this.props.getSDUsers()
    this.SEIDList()
  }

  public render() {
    return (
      <Modal
      titleAriaId={this.titleId}
      subtitleAriaId={this.subtitleId}
        isOpen={this.props.showProxy}
        onDismiss={() => {
          this.props.closeProxy(false);
        }}
        isBlocking={false}
        containerClassName="addProxyModal"
      >
        <div className="nrcModalProxy">
          <h2 id={this.titleId}>Update SD Proxy</h2>
          <Dropdown
            placeholder = "All"
            label = "Supplier Engagement Id (Current SD Proxy)"
            options= {this.state.SEIDInitialList}
            className="nrcProxyDropdowns"
            multiSelect
            style={{ marginRight: '1vw', marginBottom: '2vh' }}
            onChange = {(e, o) => this.onSEIDDropdowChange(o)}
            required={true}
          />
          <Dropdown
            ariaLabel="SD"
            placeholder="Select SD"
            id="nrcType"
            className="nrcDropdowns"
            label="SD"
            onChange={(e, o) => this.onSDDropdownChange(o)}
            options={this.SDUserList()}
            style={{ marginRight: '1vw', marginBottom: '2vh' }}
            required={true}
          />
          <div className="addNrcButtonGroup">
            <DefaultButton
              data-automation-id="cancelBtn"
              text="Cancel"
              onClick={() => {
                this.props.closeProxy(false);
              }}
              style={{ margin: '10px' }}
              id="BillableunitsSetupSDProxiesCancelId"
              data-custom-parentid="Billable units Setup SD Proxies Cancel"
            />
            <PrimaryButton
              data-automation-id="submitBtn"
              role="button"
              text="Submit"
              disabled={this.state.isEdit}
              onClick={() => this.onClickSubmitProxy()}
              style={{ float: 'right', margin: '10px 0px' }}
              id="BillableunitsSetupSDProxiesSubmitId"
              data-custom-parentid="Billable units Setup SD Proxies Submit"
            />
          </div>
          <div>
            <label>*Users can update the SD proxy only for supplier engagements to which they are primary SD (SD1)</label>
          </div>
        </div>
      </Modal>
    );
  }
  private SEIDList () {
    let options: IDropdownOption[] = [];
    const { billableUnits } = this.props;
    const {loggedinUserAlias} = this.props;   
    billableUnits.forEach((x) => {
      if (!options.some(o => o.key === x.SupplierEngagementId)) {
        if(x.SD1.toLowerCase() === loggedinUserAlias.toLowerCase()){
          options.push({
            key: x.SupplierEngagementId,
            text: x.SupplierEngagementId.toString() + ' (' + x.SD2 + ')',
          });
       }
      }
    }); 
    this.setState({SEIDInitialList: options});   

    const { monthList } = this.props;
    for (let i = 0; i < monthList.length; i++) {
      if (monthList[i].IsCurrentInvoiceMonth === true) {
        this.cumMonth = Number(monthList[i].CumMonth);
      }
    }
  };

  private SDUserList = (): IDropdownOption[] => {
    let options: IDropdownOption[] = [];
    const { sdUsersList } = this.props;
    const {loggedinUserAlias} = this.props; 
    sdUsersList.forEach((x) => {
      if (!options.some(o => o.key === x.UserId)) {
        if(x.UserAlias.toLowerCase() !== loggedinUserAlias.toLowerCase()){
          options.push({
            key: x.UserId,
            text: x.UserAlias,
          });
        }
      }
    });
    return options;
  };
  private onSEIDDropdowChange(currentOption : IDropdownOption){  
    var options= this.state.SEIDInitialList.map(option => {
      if (option.key === currentOption.key) {
        return {
          ...option,
          selected: currentOption.selected,
        };
      } else return option;
    });
     this.setState({SEIDInitialList: options}); 
  };

  //Function to submit the SD details
  private onClickSubmitProxy = () => {
    let SDDetails: ISDForSEID = {
      AssignedUser: '',
      SupplierEngagementIds: [],
      CurrentInvoiceMonth: 0,
    };
    //Filter selected SupplierEngagements
    SDDetails.AssignedUser = this.state.selectedSD;
    SDDetails.SupplierEngagementIds = this.state.SEIDInitialList
    .filter(x => x.selected === true)
    .map(option => {
      return option.key;
    });

    if(SDDetails.SupplierEngagementIds.length === 0)
    {
      SDDetails.SupplierEngagementIds = this.state.SEIDInitialList    
      .map(option => {
        return option.key;
      });
    }
    SDDetails.CurrentInvoiceMonth = this.cumMonth;

     this.props.submitSDDetails(SDDetails);
     this.props.closeProxy(false);
  };
  private onSDDropdownChange = (o: IDropdownOption) => {
    this.setState({ selectedSD: o.text });
    this.setState({isEdit: false});
  };

}

const mapDispatchToProps = (dispatch) => {
  return {   
    getSDUsers: () => {
      dispatch(getSDUsers());
    },
    submitSDDetails: (sdProxyDetials: ISDForSEID) => {
      dispatch(submitSDDetails(sdProxyDetials));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    sdUsersList: state.headCount.sdUsers,
    billableUnits: state.headCount.billableUnits,
    monthList: state.headCount.monthList,
    loggedinUserAlias: state.user.Alias
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProxy);
