import * as React from 'react';
import { Image } from 'office-ui-fabric-react';
class InvoiceManagementDoc extends React.Component<{}> {
    render() {
        return (
            <div>
                <style>{
                    `td{
                        border: 1px solid black;
                        border-collapse:collapse; 
                                      
                     `}
                </style>
                <h4>Invoice Management</h4>
                <h3>Table of Contents</h3>
                <a href="#IM1"><b>Invoice Prefix management</b></a><br />
                <a href="#IM2"><b>Overview  </b></a><br />
                <a href="#IM3"><b>INVOICING PRECONDITIONS</b></a><br />
                <a href="#IM4"><b> Create an Invoice Prefix (IA)</b></a><br />
                <a href="#IM5"><b>Edit an Invoice Prefix (IA)</b></a><br />
                <a href="#IM6"><b>Assign a Supplier Engagement to an Invoice Prefix (IA, VAM)</b></a><br />
                <a href="#IM7"><b>Option 1</b></a><br />
                <a href="#IM8"><b>Option 2</b></a><br />
                <a href="#IM9"><b>Assign an invoice prefix from the edit dialog  </b></a><br />
                <a href="#IM10"><b>Assign an invoice prefix from within supplier engagement grid.</b></a><br />
                <a href="#IM11"><b>Un-Assign a Supplier Engagement from an Invoice Prefix (IA)</b></a><br />
                <h3 id="IM1" tabIndex={0}>Invoice Prefix management</h3>
                <p>
                    The goal of the GTO Invoicing Tool is to facilitate accuracy and speed in invoicing.
                    It does this by enforcing business rules during the creation of engagements and supplier engagements which contain the justification for the invoice.  The roles primarily responsible for proper development of engagements and supplier engagements are SD, VAM, and BA.
                        </p>
                <p>
                    Once engagements and supplier engagements are approved, invoicing can begin.
                    Invoice management is the responsibility of the IA and involves collecting invoice estimates from suppliers, reconciling the supplier’s entered volumes with contractual agreements, sending a second invoice template for the collection of the actual invoices, and finally the posting of the actual invoice based on actual volumes.
                        </p>
                <h3 id="IM2" tabIndex={0}>Overview</h3>
                <p>The following steps provide an overview of all the steps that must be taken before an invoice is complete.</p>
                <ol>
                    <li>Generate Accrual Invoices</li>
                    <li>IA Review</li>
                    <li>Export Accrual Invoices

                    <p>(Supplier Adds Volume)</p>
                    <table style={{float:'right'}}>                        
                        <tr>
                            <td>First half of the invoicing workflow used for<br /> creating invoice estimates.</td>
                        </tr>
                    </table>
                    </li>
                    <li>Import Accrual Invoices</li>
                    <li>Audit Accrual Invoices</li>
                    <li>Post Accrual</li>
                    <li>Generate Actual Invoices</li>
                    <li>IA Review</li>
                    <li>Export Actual Invoices
                    <p>(Supplier Adds Volume)</p>
                    <table style={{float:'right'}}>                        
                        <tr>
                            <td>Second half of workflow is identical to the first,<br />but this time used for producing actual invoices.</td>
                        </tr>
                    </table>
                    </li>
                    <li>Import Actual Invoices</li>
                    <li>Audit Actual Invoices</li>
                    <li>Post Actual</li>
                </ol>
                <p>
                    The above workflow steps are reflected in the Invoice Generation screen.
                    As each step is compled the approprate box is colored green. Some of the boxes can be clicked on once they turn green, allowing the user to return to that step.
                        </p>
                <p>At this moment an accruals invoice is generated the following things occur simultaneously.</p>
                <ol>
                    <li>All Supplier Engagements assigned to the Invoice Prefix will generate one invoice line</li>
                    <li>All previously scheduled adjustments for the supplier engagements under this Invoice Prefix will generate two invoice lines.</li>
                    <li>All scheduled NRC’s for this Invoice Period and Invoice Prefix will generate one invoice line.</li>
                    <li>All Volume Line Descriptions for the supplier engagements under this Invoice Prefix will generate invoice lines.</li>
                    <li>All CAP Mailbox names for the supplier engagements under this Invoice Prefix will generate invoice lines.</li>
                </ol>
                <p>
                    Any invoice that is available to be added to the selected invoice period will be listed in this grid.
                    There will be an action status assigned to each invoice reflecting where it is in the Invoice Generation workflow.
                    This status is found in the column “Last Action”.
                    If this column is blank it means that invoice has not yet keyed moving through the Invoice Generation workflow.
                        </p>
                <h3 id="IM3" tabIndex={0}>INVOICING PRECONDITIONS</h3>
                <p>
                    Engagements and supplier engagements are the basis upon which invoices are created.
                    Each represents a different aspect of the Microsoft/supplier outsourcing agreement.
                    The engagement represents and describes the characteristics of the Microsoft product or service that needs to be outsourced for support.
                    The supplier engagement outlines the pricing, incentives, and the characteristics of the supplier that will do the outsourced work.
                        </p>
                <p>
                    The invoice prefix is important because it links the supplier engagement with the invoice owner responsible for managing its’ invoices, and also because it ensures that all components of the invoice are accounted for at the time of invoice generation.
                    These components are Non-Recurring Charges, Volume Line Descriptions, and CAP Mailboxes, however, since NRCs, Volume Lines Descriptions, and CAP Mailboxes are optional they are sometimes added after an invoice has been generated.
                    Refreshes to the invoice is needed to ensure their inclusion on the invoice detail.
                        </p>
                <h3 id="IM4" tabIndex={0}>Create an Invoice Prefix (IA)</h3>
                <p>
                    An invoice prefix is created for the purpose of having a key by which to associate components of an invoice.  The moment the invoice prefix is created it is available in the invoice management system’s prefix list and supplier engagements can be assigned to it.
                        </p>
                <p>
                    The Invoice Prefix Management screen will show all Invoice Prefixes, Active or In-Active. Each invoice prefix must have a unique name and is customarily made up of Invoice owner (3 letter abbreviation), supplier name (3 letter abbreviation).
                        </p>
                <ol>
                    <li>From Invoicing Menu dropdown, click Invoice Management Screen.</li>
                    <li>Select an Invoice Owner from the Invoice Owner Dropdown</li>
                    <li>Click on Add button
                    <Image src={require('../../assets/images/IM_CreatePrefix3.jpg')} style={{ height: 350, width: 750 }} alt="IM_CreatePrefix3 Image" />
                    </li>
                    <li>
                        Enter values for Invoice Prefix, using the naming convention 3-3-1.
                        Supplier is for supplier name, Headcount Template is the name of an excel spreadsheet which contains supplier headcount numbers, Headcount Template Active/Inactive fields are controlled with checkmark.
                        Supplier Contact name , Supplier Contact Name – Alternate 1,  Supplier Contact Name – Alternate 2, Supplier Contact Email, Supplier Contact Email – Alternate 1, Supplier Contact Email – Alternate 2, SharePoint site- Blank template, SharePoint site- Completed template.

                    <Image src={require('../../assets/images/IM_CreatePrefix4.jpg')} style={{ height: 350, width: 750 }} alt="IM_CreatePrefix4 Image" />  
                    </li>
             <li>Click ‘OK’. You will get an Invoice Id assignment.

                    <Image src={require('../../assets/images/IM_CreatePrefix5.jpg')} alt="IM_CreatePrefix5 Image" />
                    </li>
                    <li>The newly added prefix will be found in the Invoice Management Screen menu.</li>
                </ol>

                <h3 id="IM5" tabIndex={0}>Edit an Invoice Prefix (IA)</h3>

                <p>
                    The edit box for Invoice Prefix details is used correct any fields other than Invoice Owner and Invoice Prefix.
                    Once created an invoice prefix cannot be renamed or reassigned to another Invoice Owner.
                 </p>
                <ol>
                    <li>From navigation bar select Invoicing Menu -> Invoice Management Screen.</li>
                    <li>Select an Invoice Owner from the Invoice Owner Dropdown.</li>
                    <li>Click on the checkbox to select the Invoice Prefix and click on edit button. This dialog box contains the attributes needed to create an Invoice Prefix and associate it to both the supplier and Invoice Owner.

                    <Image src={require('../../assets/images/IM_EditPrefix3.jpg')} style={{ height: 350, width: 750 }} alt="IM_EditPrefix3 Image" />
                    </li>
                    <li>Make modifications.</li>
                    <li>Click ‘Save Changes’ button.</li>
                </ol>

                <h3 id="IM6" tabIndex={0}>Assign a Supplier Engagement to an Invoice Prefix (IA, VAM)</h3>
                <p>
                    The supplier engagement assignment to an invoice prefix is mandatory.
                    Without it, no invoice lines will be generated for that supplier. You can assign a supplier engagement to an invoice prefix either in the invoicing menu or in the supplier engagements tab.
                        </p>
                {/* <center> */}
                <p><b><i>NOTE: You may find that even after you assign a supplier engagement to an invoice that it does not generate invoice lines. Solution: check the Is Active column in the supplier engagement tab.
                                The Is Active column within the supplier engagement must be checked.</i></b></p>
                {/* </center><hr /> */}
                <hr />
                <h3 id="IM7" tabIndex={0}>Option 1:</h3>
                <p>Adding a Supplier Engagement to the Invoice Prefix from the Invoice Management menu.</p>
                <ol>
                    <li>From navigation menu select <b>Invoicing -> Invoice Management -> Invoice Prefix Assignment.</b> </li>
                    <li>Select an Invoice Owner from the Invoice Owner Dropdown.

                    <Image src={require('../../assets/images/IM_Option1.2.jpg')} style={{ height: 250, width: 800 }} alt="IM_Option1.2 Image" /> </li>

                    <li>Select the Assign invoices Radio button(if Supplier Engagements are assigned, they will appear under the “Current Invoice Assignments” tab).</li>
                    <li>Select the row with Invoice Prefix you wish to assign supplier engagements by checking the check-box.</li>
                    <li>Click on Actions drop-down and click on “Add Selected Rows to Invoice”.

                    <Image src={require('../../assets/images/IM_Option1.5.jpg')} style={{ height: 250, width: 800 }} alt="IM_Option1.5 Image" />
                    </li>
                    <li>Click tab “Filter and Assign Invoices”
                    <Image src={require('../../assets/images/IM_Option1.6.jpg')} alt="IM_Option1.6 Image" />
                    </li>
                    <li>Click OK.</li>
                    <li>
                        Optionally, Regenerate Accruals Invoice.See note below.<br />
                        <b><i> NOTE: If you wish for a new supplier assignment to be effective on the current invoice you must regenerate the invoice.
                                      Since you lose edits to original invoice lines when you regenerate, plan to make supplier assignments (and regenerating) before making other edits.</i></b>
                    </li>
                </ol><hr />
                <h3 id="IM8" tabIndex={0}>Option 2:</h3>
                <p>Adding a Supplier Engagement to the Invoice Prefix from within the Supplier Engagement tab.</p>
                <p>You can assign an invoice prefix within the supplier engagement tab of the engagement menu. The invoice prefix assignment is important because it links the supplier engagement to the invoice owner and allows for invoicing.</p>
                <h3 id="IM9" tabIndex={0}>Assign an invoice prefix from the edit dialog</h3>
                <ol>
                    <li>Select the ‘Engagement’ menu item</li>
                    <li>Retrieve your supplier engagement using a Supplier Engagement ID.</li>
                    <li>Select the supplier engagement you want to edit.</li>
                    <li>Click ‘Edit’.  This will retrieve the edit box.
                    <Image src={require('../../assets/images/IM_Option2.4.jpg')} alt="IM_Option2.4 Image" />
                    </li>
                    <li>Select an Invoice Prefix from the Dropdown.</li>
                    <li>Click ‘OK’.</li>
                    <li>Click ‘Save’.</li>
                </ol>

                <h3 id="IM10" tabIndex={0}>Assign an invoice prefix from within supplier engagement grid.</h3>
                <p>
                    In the Supplier Engagement Grid, each Supplier Engagement can be edited by changing the value in the row containing the value you require changed.
                    Find the Invoice Prefix column in the grid, select the Supplier engagement to change, and then click the dropdown and the correct Invoice prefix.
                        </p>
                <Image src={require('../../assets/images/IM_AssignPrefix.jpg')} style={{ width: 750 }} alt="IM_AssignPrefix Image" />
                <ol>
                    <li>
                        Click the save button to complete the change request.<br />
                        When making a change to an Engagement or a Supplier Engagement, the change is added to a Change Request Group.
                        You can enter a description for this change, or add it to a previous change group.
                            </li>
                    <li>
                        Click ‘OK’<br />
                        Note the Supplier Engagement Request Id and click ‘OK’.
                        It will also be found on your Home page under “My Pending Requests”
                    <Image src={require('../../assets/images/IM_AssignPrefix2.jpg')} alt="IM_AssignPrefix2 Image" />
                    </li>

                    <li>Click ‘OK’.<br />
                        You have created a Change Request. Search with the Pending Request Group/Supplier Engagement request ID.</li>
                    <li>Select the supplier engagement.</li>
                    <li>Click ‘Submit’ to make the change go from a request to production.</li>
                </ol>
                <Image src={require('../../assets/images/IM_AssignPrefix5.jpg')} alt="IM_AssignPrefix5 Image" />
                <ol>
                    <li>Click ‘OK’.</li>
                </ol>
                <Image src={require('../../assets/images/IM_AssignPrefix6.jpg')} alt="IM_AssignPrefix6 Image" />
                <h3 id="IM11" tabIndex={0}>Un-Assign a Supplier Engagement from an Invoice Prefix (IA)</h3>
                <p>Un-assigning a Supplier Engagement from an Invoice Prefix prevents the Supplier Engagement from generating an invoice line.
                 No invoice lines associated with the supplier engagement will appear within the invoice prefix if this action is taken.
                </p>
                      <p style={{ textAlign: 'center' }}><b><i>NOTE: If you wish for a new supplier assignment to be effective on the current invoice you must regenerate the invoice.
                        Since you lose edits to original invoice lines when you regenerate, plan to make supplier assignments (and regenerating) before making other edits.
                         </i></b></p><hr />

                    <ol>
                        <li>From navigation menu select Invoicing -> Invoice Management -> Invoice Prefix Assignment.</li>
                        <li>Select an Invoice Owner from the Invoice Owner Dropdown.</li>
                        <li>Select your invoice prefix from Invoice Management grid.</li>
                        <li>Click Current Invoices Radio button.</li>
                        <li>Check the box for the records which you want removed from the invoice.</li>
                        <li>Click on Actions drop-down.
                        <Image src={require('../../assets/images/IM_Un-AssignPrefix6.jpg')} style={{ height: 250, width: 800 }} alt="IM_Un-AssignPrefix6 Image" />
                        </li>
                        <li>Click Remove Selected Rows from Invoice.
                        <Image src={require('../../assets/images/IM_Un-AssignPrefix7.jpg')} style={{ height: 250, width: 800 }} alt="IM_Un-AssignPrefix7 Image" />
                        </li>
                        <li>You will get confirmation of removal.
                        <Image src={require('../../assets/images/IM_Un-AssignPrefix8.jpg')} alt="IM_Un-AssignPrefix8 Image" />
                        </li>
                        <li>
                            Click OK<br /><br />
                            <b>
                                <i>
                                    NOTE: If any adjustments or NRCs were scheduled for the current invoice against the supplier engagement that you removed, they will still be invoiced, and will appear on the current invoice.
                                    However, the underlying invoice line for the pricing and incentive model will be removed.
                                    </i>
                            </b>
                        </li>
                    </ol><hr /><br /><br /><br />

                </div>
                );
            }
        }
export default InvoiceManagementDoc;