import {
  SelectionMode,
  Stack,
  Spinner,
  SpinnerSize,
} from 'office-ui-fabric-react';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  IAravoToContractMap,
  IContract,
  ISupplier,
  ISupplierEngagement,
  IPoLineItemNew,
  IPurchaseOrderNew,
  IPurchaseOrderAmoutAndLineItems,
} from '../../shared/models/index';
import telemetryContext from '../../shared/services/TelemetryServices';
import {
  getAravoToContractMappingSetDefaultAravoAndSetLineItemsForDefault,
  setSelectedAravoId,
} from '../../store/actions';
import ContractDetails from './contract-details/ContractDetails';
import './PurchaseOrderReview.css';
import SupplierDetails from './supplier-details/SupplierDetails';
import PoLineItemDetails from './supplier-engagement-details/PoLineItemDetails';
import {
  postPurchaseOrderForSupplier,
  setSelectedLineItemKey,
  setMonthlyLineItems,
  setSingleLineItems,
  setPONotCreated,
} from './../../store/actions/selectedSupplier.action';
import { RouteProps } from 'react-router';
import appConfig from '../../assets/configuration/config';
import HelmetTab from "../../shared/Helmet/Helmet";

// Interface for purchase order review state.
export interface IPurchaseOrderReviewState {
  /// contracts: IContract[]; // Stores contract list loaded from api.
  poTitle: string;
  poDescription: string;
  choice: string;
  isCreateClicked: boolean;
  // poMetadata: IPoMetadata; // This will be passed to the supplier component for use. TODO: Define a contract for this.
  // lineItems: IPoLineItem[]; // Line Items array.
}

export interface IPurchaseOrderReviewProps {
  supplierInfo: ISupplier;
  aravoToContractMappings: IAravoToContractMap[];
  supplierEngagements: ISupplierEngagement[];
  currentFiscalYear: string;
  poAmount: number;
  contractsForSupplier: IContract[];
  singleLineItems: IPoLineItemNew[];
  monthlyLineItems: IPoLineItemNew[];
  selectedKey: string;
  selectedAravoId: string;
  createdPO: number;
  lineItemMetadata: IPurchaseOrderAmoutAndLineItems;
  setSingleLineItems(lineItems: IPoLineItemNew[]): any;
  setMonthlyLineItems(lineItems: IPoLineItemNew[]): any;
  setSelectedKey(selectedKey: string): any;
  // setLineItems(lineItems: IPoLineItemNew[]): any;
  loadPageDefaultValues(): any;
  setSelectedAravoId(aravoId: string): any;
  createPurchaseOrders(purchaseOrders: IPurchaseOrderNew): any;
  // purchaseOrder: IPurchaseOrder[];
  isPOCreated: boolean;
  poNotCreated: boolean;
  setPONotCreated: any;
}
// Purchase order review component.
// This component acts as a parent for the Creat PO Page.
export class PurchaseOrderReview extends React.Component<
  IPurchaseOrderReviewProps & RouteProps,
  IPurchaseOrderReviewState
> {
  private poNumber: string;
  // Stores supplier engagement master list returned from api.
  // private supplierEngagements: ISupplierEngagement[] = [];

  public constructor(props) {
    super(props);
    if (this.props.match.params['purchaseordernumber'] !== undefined) {
      this.poNumber = this.props.match.params['purchaseordernumber'];
    } else {
      this.poNumber = '';
    }

    // this.currentSelectedKey = 'none';
    // State Initialization
    // TODO : Purchase Order modal is giving null PO title and description is not available in the API
    this.state = {
      poTitle: `CSS Contact Center Outsourcing Managed Support for ${this.props.supplierInfo.supplierLegalName} ${this.props.currentFiscalYear} `, // TODO: Create the titles here and initialize them here, Use these when calling the post api.
      poDescription: `This is a BLANKET PURCHASE ORDER for ${this.props.currentFiscalYear} supporting the contracts indicated in the QUESTIONS section of the purchase order.`, // Create the titles here and initialize them here. Use these when calling the post api.
      choice: 'none',
      isCreateClicked: false,
    };
    // this.props.loadPageDefaultValues();
  }

  // Component did mount life cycle hook
  public componentDidMount() {
    // Logging the page view.
    telemetryContext.logPageView();

    this.props.loadPageDefaultValues();
  }

  public componentDidUpdate() {
    if (this.props.isPOCreated) {
      window.open(`${appConfig.CreatePOUrl}${this.props.createdPO}`, '_blank');
    }
  }

  public render() {
    // To redirecting to new My order Page
    // if (this.props.createdPO !== 0) {
    //   // Calculating path
    //   const path: string = 'complete';

    //   // Return redirect.
    //   return <Redirect push={true} to={path} />;
    //   // return `${window.location.replace('www.google.com')}`
    // }

    return (
      <React.Fragment>
        <div>
          <h1
            style={{
              marginBottom: '0px',
              marginTop: '0px',
              paddingLeft: '15px',
              paddingBottom: '3px',
              paddingTop: '3px',
              backgroundColor: 'rgb(232, 237, 241, 0.8)',
              fontSize: '18.72px'
            }}
            aria-label={'Review Purchase Order'}
          >
            <HelmetTab title={'Purchase Order'} />
            {this.poNumber !== ''
              ? `Create Draft PO Extention for Original PO ${this.poNumber}`
              : 'Create Draft PO'}
          </h1>
        </div>
        <div className="po-review-container">
          <Stack style={{ width: '98vw' }}>
            <Stack horizontalAlign="space-between" horizontal={true}>
              <SupplierDetails
                poTitle={this.state.poTitle}
                poDescription={this.state.poDescription}
                onAravoIdSelection={this.handleAravoIdSelection}
                currentSupplier={this.props.supplierInfo}
                currentFiscalYear={this.props.currentFiscalYear}
                aravoIds={this.props.aravoToContractMappings.map(
                  (x) => x.AravoEngagementId
                )}
                poAmount={
                  this.props.singleLineItems.length === 0
                    ? 0
                    : this.props.singleLineItems[0].amount
                }
                lineItemMetadata={this.props.lineItemMetadata}
                onPOTitleChange={this.handlePOTitleChange}
                onPODescriptionChange={this.handlePODescriptionChange}
              />
              <ContractDetails
                showCard={true}
                listHeight="25vh"
                listWidth="42vw"
                contractItems={
                  this.getContractItemsBasedOnAravoSelection()

                  // this.props.contractsForSupplier.filter(
                  // x => x.contractNumber === this.props.aravoToContractMappings.filter())
                } // Directly filtering out contract items based on aravo id selection.
                onContractSelection={() => {}}
                selectionMode={SelectionMode.none}
                aria-label={'Contract Details'}
              />
            </Stack>

            <PoLineItemDetails
              onPoLinePrePaidCheckbox={this.handlePoLineItemCheckbox}
              onItemModeChange={this.handleChoiceGroupChange}
              poLineItems={
                this.state.choice === 'month'
                  ? this.props.monthlyLineItems
                  : this.props.singleLineItems
              }
            />
            <div className="submitbutton">
              {this.state.isCreateClicked &&
              !this.props.isPOCreated &&
              this.props.poNotCreated ? (
                <Spinner style={{ width: '3%' }} size={SpinnerSize.medium} />
              ) : (
                <PrimaryButton
                  data-automation-id="test"
                  disabled={
                    this.props.aravoToContractMappings.length === 0 ||
                    this.props.isPOCreated
                  }
                  onClick={this.submitButtonHandler}
                  text="Create"
                  role="button"
                  ariaLabel="Submit Purchase Order to the My Order"
                  data-custom-parentid = "Submit Purchase Order to the My Order"
                  id="submitPurchaseOrderToTheMyOrder"
                />
              )}
            </div>
          </Stack>
        </div>
      </React.Fragment>
    );
  }

  // This function gets the contract items based on aravo id selection.
  private getContractItemsBasedOnAravoSelection(): IContract[] {
    // Contracts value to return.
    let contracts: IContract[];

    // Checking if any aravo's were selected by the user or by default.
    if (this.props.selectedAravoId === '') {
      contracts = this.props.contractsForSupplier;
    } else {
      let filteredAravoToContractMappings = this.props.aravoToContractMappings.filter(
        (x) =>
          x.AravoEngagementId === this.props.selectedAravoId.toString() &&
          x.ContractIds !== null
      );

      let contractNumberFilter = this.props.contractsForSupplier.filter(
        (x) => x.contractNumber !== null
      );
      contracts = contractNumberFilter.filter((x) =>
        filteredAravoToContractMappings[0].ContractIds.includes(
          x.contractNumber.toString()
        )
      );

      //     .ContractIds.Includes(x.contractNumber)
      // contracts = this.props.contractsForSupplier.filter(
      // );
    }
    return contracts;
  }

  // Submit Button Handler.
  private submitButtonHandler = () => {
    this.setState({ isCreateClicked: true });
    this.props.setPONotCreated(true);
    const purchaseOrder: IPurchaseOrderNew = {
      PurchaseOrderId: 0,
      PurchaseOrderNumber: 0,
      FiscalYearNumber: parseInt(this.props.currentFiscalYear),
      AravoId: '',
      AravoEngagementId: this.props.selectedAravoId,
      Title: this.state.poTitle,
      Description: this.props.lineItemMetadata.purchaseOrderDescription,
      SupplierId: this.props.supplierInfo.supplierId,
      ContactName: this.props.supplierInfo.contactName,
      ContactEmail: this.props.supplierInfo.contactEmail,
      MSCompanyCode: this.props.supplierInfo.companyCode,
      SupplierNumber: parseInt(this.props.supplierInfo.supplierNumber),
      CurrencyCode: this.props.supplierInfo.currencyCode,
      Amount: this.props.lineItemMetadata.purchaseOrderAmount,
      Status: '',
      ParentPurchaseOrder:
        this.poNumber === '' ? 0 : parseInt(this.poNumber, 10),
      LineItems:
        this.state.choice === 'month'
          ? this.props.monthlyLineItems.map((x) => ({
              LineItemNumber: 1,
              Description: x.description,
              Amount: x.amount,
              InternalOrder: x.internalOrder,
              CostCenter: x.costCenter,
              AccountCode: x.accountCode,
              IsPrepaid: false, // TODO: See how to check and fix this.
              SupplierEngagements: x.supplierEngagements,
            }))
          : this.props.singleLineItems.map((x) => ({
              LineItemNumber: 1,
              Description: x.description,
              Amount: x.amount,
              InternalOrder: x.internalOrder,
              CostCenter: x.costCenter,
              AccountCode: x.accountCode,
              IsPrepaid: false, // TODO: See how to check and fix this.
              SupplierEngagements: x.supplierEngagements,
            })),
    };

    this.props.createPurchaseOrders(purchaseOrder);
  };

  // This function handles po title changes.
  private handlePOTitleChange = (newTitle: string) => {
    // Setting the new state
    this.setState({ poTitle: newTitle });
  };

  // This function handles po title changes.
  private handleAravoIdSelection = (selectedAravo: string) => {
    //  this.selectedAravo = selectedAravo;
    this.props.setSelectedAravoId(selectedAravo);

    // const selectedContracts: number[] = this.props.contractsForSupplier
    //   .filter(x => x.aravoEngagementId === selectedAravo)
    //   .map(x => x.contractNumber);
  };

  // This function handles po title changes.
  private handlePODescriptionChange = (newDesc: string) => {
    // Setting the new state
    this.setState({ poDescription: newDesc });
  };

  private handlePoLineItemCheckbox = (
    lineItemNumber: number,
    isChecked: boolean
  ) => {
    const lineItems =
      this.state.choice === 'month'
        ? [...this.props.monthlyLineItems]
        : [...this.props.singleLineItems];

    const currentLineItem: IPoLineItemNew = lineItems.find(
      (x) => x.lineItemNumber === lineItemNumber
    );
    currentLineItem.isPrePaid = isChecked; // Check this logic.

    this.state.choice === 'month'
      ? this.props.setMonthlyLineItems(lineItems)
      : this.props.setSingleLineItems(lineItems);
  };

  private handleChoiceGroupChange = (option) => {
    this.props.setSelectedKey(option.key);

    // const selectedContracts: number[] = this.props.contractsForSupplier
    //   .filter(x => x.aravoEngagementId === this.props.selectedAravoId)
    //   .map(x => x.contractNumber);

    this.setState({ choice: option.key });
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadPageDefaultValues: () => {
      dispatch(
        getAravoToContractMappingSetDefaultAravoAndSetLineItemsForDefault()
      );
    },
    setSingleLineItems: (lineItems: IPoLineItemNew[]) => {
      dispatch(setSingleLineItems(lineItems));
    },
    setMonthlyLineItems: (lineItems: IPoLineItemNew[]) => {
      dispatch(setMonthlyLineItems(lineItems));
    },
    setSelectedAravoId: (aravoId: string) => {
      dispatch(setSelectedAravoId(aravoId));
    },
    setSelectedKey: (selectedKey: string) => {
      dispatch(setSelectedLineItemKey(selectedKey));
    },
    createPurchaseOrders: (purchaseOrders: IPurchaseOrderNew) => {
      dispatch(postPurchaseOrderForSupplier(purchaseOrders));
    },
    setPONotCreated: (poNotCreated: boolean) => {
      dispatch(setPONotCreated(poNotCreated));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    contractsForSupplier: state.selectedSupplier.contracts,
    supplierInfo: state.selectedSupplier.basicInfo,
    currentFiscalYear: state.selectedSupplier.selectedFiscalYear,
    aravoToContractMappings: state.selectedSupplier.aravoToContractMapping,
    isFetchingAravoToContractMap:
      state.selectedSupplier.isFetchingAravoEngagementToContractMapping,
    supplierEngagements: state.selectedSupplier.supplierEngagements, // TODO: This needs to be implemented.
    poAmount: state.selectedSupplier.poAmount, // TODO This needs to be read from budget information
    selectedKey: state.selectedSupplier.selectedLineItemKey,
    selectedAravoId: state.selectedSupplier.selectedAravoId,
    singleLineItems: state.selectedSupplier.singleLineItems,
    monthlyLineItems: state.selectedSupplier.monthlyLineItems,
    lineItemMetadata: state.selectedSupplier.lineItemMetadata,
    createdPO: state.selectedSupplier.createdPO,
    purchaseOrder: state.selectedSupplier.purchaseOrders,
    isPOCreated: state.selectedSupplier.isPOCreated,
    poNotCreated: state.selectedSupplier.poNotCreated,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseOrderReview);
