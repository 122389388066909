import {
  TextField,
  TooltipHost,
  Stack,
  Spinner,
  SpinnerSize,
} from 'office-ui-fabric-react';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import * as React from 'react';
import {
  ISupplier,
  getDecimalAdjustedStringAmount,
  IPurchaseOrderAmoutAndLineItems,
} from '../../../shared/models/index';
import './SupplierDetails.css';

// Interface for supplier detail props.
export interface SupplierDetailsProps {
  currentSupplier: ISupplier;
  currentFiscalYear: string;
  onPOTitleChange: any;
  onPODescriptionChange: any;
  onAravoIdSelection: any;
  poAmount: number;
  aravoIds: string[];
  poTitle: string;
  poDescription: string;
  lineItemMetadata: IPurchaseOrderAmoutAndLineItems;
}

// Interface for supplier detail states.
export interface SupplierDetailsState {}

class SupplierDetails extends React.Component<
  SupplierDetailsProps,
  SupplierDetailsState
> {
  // Stores selected aravo dropdown key.
  private selectedKey: React.ReactText = '';

  public render() {
    const { currentSupplier } = this.props;

    const aravoIdDropdown = this.buildAravoIdDropdown();
    if (aravoIdDropdown.length !== 0 && this.selectedKey === '') {
      this.selectedKey = aravoIdDropdown[0].key;
    }

    return (
      <div className="supplier-container" style={{ width: '50%' }}>
        <Stack>
          <div>
            <h2 className="supplier-details-1">{this.getTitle()}</h2>
          </div>
          {this.props.lineItemMetadata !== null ? (
            <>
              <Stack
                horizontalAlign="space-between"
                horizontal={true}
                className="supplier-details-3"
              >
                <TextField
                  value={this.props.poTitle}
                  onChange={(e, newString) =>
                    this.props.onPOTitleChange(newString)
                  }
                  styles={{ root: { width: 280 } }}
                  autoComplete="on"
                  label="PO Title"
                  ariaLabel={'Enter Purchase Order'}
                  multiline
                  rows={3}
                  className="textFieldStyle"
                />
                <TextField // TODO: Handle update of text field and passing this information back to parent.
                  value={
                    this.props.lineItemMetadata !== null
                      ? this.props.lineItemMetadata.purchaseOrderDescription
                      : ''
                  }
                  onChange={(e, newString) =>
                    this.props.onPODescriptionChange(newString)
                  }
                  styles={{ root: { width: 280 } }}
                  label="PO Description"
                  autoComplete="on"
                  ariaLabel={'Enter Purchase Order Description'}
                  multiline
                  rows={3}
                  className="textFieldStyle"
                />
              </Stack>
              <Stack
                horizontal={true}
                horizontalAlign="space-between"
                style={{ width: '100%' }}
              >
                <Stack
                  className="supplier-details-6"
                  style={{ width: '30%', textAlign: 'center' }}
                >
                  <h3 aria-label={'Contact Name'} className="wordwraptitle" style={{ fontSize: '13.28px' }}>Contact Name</h3>
                  <span>{currentSupplier.contactName}</span>
                </Stack>
                <Stack
                  className="supplier-details-6"
                  style={{ width: '30%', textAlign: 'center' }}
                >
                  <h3 aria-label={'Contact Email'} className="wordwraptitle" style={{ fontSize: '13.28px' }}>Contact Email</h3>
                  <span  className="wordwraptitle">{currentSupplier.contactEmail}</span>
                </Stack>
                <Stack
                  className="supplier-details-6"
                  style={{ width: '30%', textAlign: 'center' }}
                >
                  <h3 aria-label={'Payment Terms'} className="wordwraptitle" style={{ fontSize: '13.28px' }}>Payment Terms</h3>
                  <span className="wordwraptitle">{currentSupplier.paymentTerms}</span>
                </Stack>
              </Stack>
              <Stack
                horizontal={true}
                horizontalAlign="space-between"
                style={{ width: '100%' }}
              >
                <Stack
                  className="supplier-details-6"
                  style={{ width: '30%', textAlign: 'center' }}
                >
                  <h3 aria-label={'Aravo Id'} style={{ fontSize: '13.28px' }}>Aravo Id</h3>
                  <Dropdown
                    defaultSelectedKey={this.selectedKey}
                    styles={{
                      root: { width: '13vw' },
                    }}
                    placeholder='Aravo Id'
                    options={aravoIdDropdown}
                    ariaLabel="Aravo Id"
                    onChange={(e, o) => this.onDropdownChange(o)}
                  />
                </Stack>
                <Stack
                  className="supplier-details-6"
                  style={{ width: '30%', textAlign: 'center' }}
                >
                  <h3 aria-label={'Fiscal Year Number'} style={{ fontSize: '13.28px' }}>FY number</h3>
                  <span>{this.props.currentFiscalYear}</span>
                </Stack>
                <Stack
                  className="supplier-details-6"
                  style={{ width: '30%', textAlign: 'center' }}
                >
                  <TooltipHost
                    content={
                      this.props.lineItemMetadata !== null
                        ? this.props.lineItemMetadata.purchaseOrderAmountFormula
                        : ''
                    }
                    id={'submitHover'}
                    calloutProps={{ gapSpace: 0 }}
                  >
                    <h3
                      aria-label={'Purchase Order Amount'}
                      style={{ margin: '0px', fontSize: '13.28px' }}
                      className="wordwraptitle"
                    >
                      PO Amount
                    </h3>
                  </TooltipHost>
                  <TooltipHost
                    content={
                      this.props.lineItemMetadata !== null
                        ? this.props.lineItemMetadata
                            .purchaseOrderAmountBreakdown
                        : ''
                    }
                    id={'submitHover'}
                    calloutProps={{ gapSpace: 0 }}
                    className="wordwraptitle"
                      >
                    <span className="wordwraptitle">{getDecimalAdjustedStringAmount(
                      this.props.lineItemMetadata !== null
                        ? this.props.lineItemMetadata.purchaseOrderAmount
                        : 0
                    )}
                    </span>
                  </TooltipHost>
                </Stack>
              </Stack>{' '}
            </>
          ) : (
            <Spinner style={{ marginTop: '10%' }} size={SpinnerSize.large} />
          )}
        </Stack>
      </div>
    );
  }

  // On Dropdow change
  private onDropdownChange(o) {
    this.selectedKey = o.key;
    this.props.onAravoIdSelection(o.key);
  }

  // This function returns the title by concatenating information related to supplier
  private getTitle = (): string => {
    const { currentSupplier } = this.props;
    return `${currentSupplier.supplierLegalName} (SN:${currentSupplier.supplierNumber} CC:${currentSupplier.companyCode} ${currentSupplier.currencyCode})`;
  };

  // Fiscalyear dropdown creation method
  private buildAravoIdDropdown(): IDropdownOption[] {
    const options: IDropdownOption[] = [];

    // Building key and text list for each aravo id for this supplier.
    this.props.aravoIds.forEach((x) => {
      options.push({
        key: x,
        text: `${x}`,
      });
    });

    return options;
  }
}

export default SupplierDetails;
