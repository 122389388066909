export const AutomatedSEIDStyles = {
    spinnerLoadingIcon: {
        root: {
            position: "fixed" as "fixed",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            background: "rgba(0, 0, 0, 0.1)",
            zIndex: 99999999,
        }
    },
    paddingTop51: {
        paddingTop: '51px'
    },
    marginTop25Left10: {
        marginTop: '26px',
        marginLeft: '10px'
    },

}
