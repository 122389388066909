import React, { Component } from 'react';
import {PrimaryButton,
         Label, 
         Spinner,
         SpinnerSize } from "office-ui-fabric-react";
import {IColumn} from '@fluentui/react/lib/DetailsList';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { BOBReportTable } from "./BOBReportTable";
import { connect } from 'react-redux';
import { updateBudgetReportSelectedSuppliers,
         updateBudgetReportSelectedQuarters,
         updateBudgetReportSelectedBudgetTypes, 
         getBudgetReportSummary,
         getBudgetReportDetails} from '../../store/actions/bobReport.action';
import { CSVLink } from 'react-csv';
import { toast } from 'react-toastify';
import { css } from 'glamor';

//Interface for BOB Budget Report Props
export interface IInvoiceReportProps {
  IsFetchingBudgetReport: boolean;
  IsFetchingBudgetReportDetails: boolean;
  BudgetReportInitialLoading: boolean;
  BudgetSupplierFilterOptions: IDropdownOption[],
  BudgetQuarterFilterOptions: IDropdownOption[],
  BudgetTypeFilterOptions: IDropdownOption[],
  BudgetReportSummary: [],
  BudgetReportDetails: [],
  BudgetTotalSpendInDollar: string,
  BudgetTotalDiscountAmountInUSD: string,
  BudgetGrossAmount: string,
  onSupplierFilterChange(option : IDropdownOption): any;
  onQuarterFilterChange(option : IDropdownOption): any;
  onBudgetTypeFilterChange(option : IDropdownOption): any;
  getBudgetReportSummary(): any;
  getBudgetReportDetails(): any;
}

const csvLink = React.createRef<CSVLink>();
  
class BOBReportBudget extends Component<IInvoiceReportProps, {}> {

  private columns: IColumn[];

  public constructor(props) {
    super(props);

    // Columns for the Budget Report    
    this.columns =  [
      {
        key: 'column1',
        name: 'SupplierName',
        fieldName: 'SupplierName',
        minWidth: 20,
        maxWidth: 50,
        isResizable: true,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column2',
        name: 'CurrencyCode',
        fieldName: 'CurrencyCode',
        minWidth: 20,
        maxWidth: 35,
        isResizable: true,
        data: 'string',
        isPadded: true,
      },
      {
      key: 'column3',
      name: 'GrossAmount',
      fieldName: 'GrossAmount',
      minWidth: 50,
      maxWidth: 80,
      isResizable: true,
      data: 'string',
      isPadded: true,
      isMultiline: true
      },
      {
      key: 'column4',
      name: 'ExchangeRate',
      fieldName: 'ExchangeRate',
      minWidth: 20,
      maxWidth: 35,
      isResizable: true,
      data: 'string',
      isPadded: true,
      },      
      {
      key: 'column5',
      name: 'ExchangeRateMonth',
      fieldName: 'ExchangeRateMonth',
      minWidth: 40,
      maxWidth: 65,
      isResizable: true,
      data: 'string',
      isPadded: true,
      },      
      {
      key: 'column6',
      name: 'SpendInDollar',
      fieldName: 'SpendInDollar',
      minWidth: 50,
      maxWidth: 80,
      isResizable: true,
      data: 'string',
      isPadded: true,
      isMultiline: true
      },
      {
      key: 'column7',
      name: 'BoBDiscountPercent',
      fieldName: 'BoBDiscountPercent',
      minWidth: 30,
      maxWidth: 60,
      isResizable: true,
      data: 'string',
      isPadded: true,
      },
      {
      key: 'column8',
      name: 'DiscountAmountLC',
      fieldName: 'DiscountAmountLC',
      minWidth: 30,
      maxWidth: 65,
      isResizable: true,
      data: 'string',
      isPadded: true,
      },
      {
      key: 'column9',
      name: 'DiscountAmountInUSD',
      fieldName: 'DiscountAmountInUSD',
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      data: 'string',
      isPadded: true,
      isMultiline: true
      },
      {
      key: 'column10',
      name: 'BudgetType',
      fieldName: 'BudgetType',
      minWidth: 40,
      maxWidth: 70,
      isResizable: true,
      data: 'string',
      isPadded: true,
      },
      {
      key: 'column11',
      name: 'Quarter',
      fieldName: 'Quarter',
      minWidth: 20,
      maxWidth: 30,
      isResizable: true,
      data: 'string',
      isPadded: true,
      },
      {
      key: 'column12',
      name: 'IsAnnualDiscount',
      fieldName: 'IsAnnualDiscount',
      minWidth: 15,
      maxWidth: 100,
      isResizable: true,
      data: 'string',
      isPadded: true,
      },
    ];      
  }
    
  public render() {
      return(
        <React.Fragment>
          <div className="report-filters">
              <div className="ms-Grid" dir="ltr" style={{marginLeft: '1vw'}}>
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg10"> 
                      <div className="ms-Grid-row">     
                        <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg4 reportFilter">
                          <Dropdown
                          multiSelect
                          placeholder="All"
                          label = "SupplierName"
                          options={this.props.BudgetSupplierFilterOptions}
                          onChange = {(e, o) => this.props.onSupplierFilterChange(o)}
                          />
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg4 reportFilter">
                          <Dropdown
                          multiSelect
                          placeholder="All"
                          label = "Quarter"
                          options={this.props.BudgetQuarterFilterOptions}
                          onChange = {(e, o) => this.props.onQuarterFilterChange(o)}
                          />
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg4 reportFilter">
                          <Dropdown
                          multiSelect
                          placeholder="All"
                          label = "BudgetType"
                          options={this.props.BudgetTypeFilterOptions}
                          onChange = {(e, o) => this.props.onBudgetTypeFilterChange(o)}
                          />
                        </div>                       
                      </div>
                    </div>
                    <div className ="ms-Grid-col ms-sm6 ms-md4 ms-lg2 reportFilter">
                      <PrimaryButton
                      className="ms-Button ms-Button--primary"
                      text="Search"    
                      ariaLabel = "Search"
                      iconProps={{ iconName: 'search' }}       
                      style = {{marginTop: '25px'}}       
                      onClick={() => this.props.getBudgetReportSummary()}
                    ></PrimaryButton>
                    </div>
                  </div>
              </div>
          </div>
          <div className="report-content1">
              {this.columns
              ?(
              <div style={{
              height: '40vh',
              position: 'relative',
              marginLeft: '1vw',}}
              className="budgettable"
              >
                {this.renderReportSummary()}
              </div>
              )
              : ""}                
          </div>
          <div className="report-content3">
            {this.renderReportDetails()}
          </div>
          </React.Fragment>
      )
  }

  private renderReportSummary(){   
    if(this.props.IsFetchingBudgetReport){
      return(
        <div aria-label={'Loading'} style={{paddingTop: '10vh'}}>
          <Spinner
            style={{ height: '100%' }}
            label="Loading..."
            size={SpinnerSize.large}
          />
        </div>
      )
    }
    else{
      if(this.props.BudgetReportInitialLoading){
        return(
          <Label style={{paddingLeft : '8px'}} className="invoicelabel">
                Please select the filter conditions and click the search button to see results
            </Label>
        )
      }
      else{
        if(this.props.BudgetReportSummary.length <= 0){
          return (
            <Label style={{paddingLeft : '8px'}}>
                No Records found for the selected filter conditions
            </Label>
          )
        }
        else{
          return (
            <BOBReportTable items={this.props.BudgetReportSummary}
            columns={this.columns}
            spendInDollar= {this.props.BudgetTotalSpendInDollar}
            discountAmountInUSD= {this.props.BudgetTotalDiscountAmountInUSD}
            grossAmount= {this.props.BudgetGrossAmount}
            lastRefreshedAt = {new Date().toString()}
            showFooter = {true}
            />
          )  
        }    
      }
    }      
  }

  private renderReportDetails(){
    if(this.props.BudgetReportSummary.length > 0){
      return (
          <div style={{
           height: '5vh',
           position: 'relative',
           marginRight: '1vw',
           marginTop: '7vh',
           textAlign: 'right'}}
          >
            <PrimaryButton 
             className="ms-Button ms-Button--primary exportDetailsBtn"
             text="Export Supplier Engagement Level Discount Details to Excel"    
             ariaLabel = "Export Supplier Engagement Level Discount Details to Excel"
             onClick={ async () =>
              {
                await this.props.getBudgetReportDetails();
                if(this.props.BudgetReportDetails.length > 0){
                csvLink.current.link.click()
                }
                else{
                  toast.info('Supplier Engagement Level Discount details not found for the selected filter conditions', {
                    className: css({
                      background: '#0275d8 !important',
                    }),
                  });
                }
              }
             }
            >
              {this.props.IsFetchingBudgetReportDetails ?
              <Spinner
                style={{ height: '100%', marginLeft: '5px' }}
                size={SpinnerSize.large}
              /> : ""}  
            </PrimaryButton>
            <CSVLink
              data={this.props.BudgetReportDetails}
              filename={'SupplierDiscountDetails_Budget.csv'}
              className="hidden"
              ref={csvLink}
              target="_blank" 
              aria-hidden="true"
              tabindex="-1"
            />    
          </div>                          
      )
    }
    else{
      return "";
    }
  }
}

const mapDispatchToProps = (dispatch, props) => { 
  return {
    onSupplierFilterChange: (option: IDropdownOption) => {
      return dispatch(updateBudgetReportSelectedSuppliers(option));
    },
    onQuarterFilterChange: (option: IDropdownOption) => {
      return dispatch(updateBudgetReportSelectedQuarters(option));
    },
    onBudgetTypeFilterChange: (option: IDropdownOption) => {
      return dispatch(updateBudgetReportSelectedBudgetTypes(option));
    },
    getBudgetReportDetails: () => {
      return dispatch(getBudgetReportDetails());
    },
    getBudgetReportSummary: () => {
      return dispatch(getBudgetReportSummary());
    }
  }
};

const mapStateToProps = state => {
  return {
    IsFetchingBudgetReport: state.bobReport.isFetchingBudgetReport,
    IsFetchingBudgetReportDetails: state.bobReport.isFetchingBudgetReportDetails,
    BudgetReportInitialLoading: state.bobReport.budgetReportInitialLoading,
    BudgetSupplierFilterOptions: state.bobReport.budgetSupplierFilterOptions,
    BudgetQuarterFilterOptions: state.bobReport.budgetQuarterFilterOptions,
    BudgetTypeFilterOptions: state.bobReport.budgetTypeFilterOptions,
    BudgetReportSummary: state.bobReport.budgetReportSummary,
    BudgetReportDetails: state.bobReport.budgetReportDetails,
    BudgetTotalSpendInDollar: state.bobReport.budgetTotalSpendInDollar,
    BudgetTotalDiscountAmountInUSD: state.bobReport.budgetTotalDiscountAmountInUSD,
    BudgetGrossAmount: state.bobReport.budgetGrossAmount
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BOBReportBudget);