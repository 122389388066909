/* Global Actions */
export const SET_IS_LOADING_GLOBAL_FLAG = 'SET_IS_LOADING_GLOBAL_FLAG';
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE';
export const REQUEST_BILLABLEUNITS_DETAILS = 'REQUEST_BILLABLEUNITS_DETAILS';
export const TOTAL_AMOUNT_CALCULATED = 'TOTAL_AMOUNT_CALCULATED';
export const TOTAL_AMOUNT = 'TOTAL_AMOUNT';
export const SET_BILLABLE_UNITS = 'SET_BILLABLE_UNITS';

/* User role actions */
export const REQUEST_USER_ROLES = 'REQUEST_USER_ROLES';
export const SET_USER_ROLES = 'SET_USER_ROLES';
export const FETCH_USER_FAILED = 'FETCH_USER_FAILED';
export const SET_USER_MODULES = 'SET_USER_MODULES';

/* Selected supplier actions */
export const SET_RETURN_TO_SELECTED_SUPPLIER =
  'SET_RETURN_TO_SELECTED_SUPPLIER';

/* Existing Contracts action */
export const SET_EXISTING_CONTRACTS = 'SET_EXISTING_CONTRACTS';
export const SET_SELECTED_SUPPLIER = 'SET_SELECTED_SUPPLIER';
export const REQUEST_SUPPLIER_BUDGET = 'REQUEST_SUPPLIER_BUDGET';
export const REQUEST_SUPPLIER_BUDGET_COMPLETE =
  'REQUEST_SUPPLIER_BUDGET_COMPLETE';
export const SET_SUPPLIER_BUDGET = 'SET_SUPPLIER_BUDGET';
export const SET_PURCHASE_ORDER_CREATED = 'SET_PURCHASE_ORDER_CREATED';
export const REQUEST_PURCHASE_ORDER_CREATED_COMPLETE =
  'REQUEST_PURCHASE_ORDER_CREATED_COMPLETE';
export const SET_NETPOAMOUNT_FOR_SUPPLIER = 'SET_NETPOAMOUNT_FOR_SUPPLIER ';
export const SET_SELECTED_LINE_ITEM_KEY = 'SET_SELECTED_LINE_ITEM_KEY';
export const SET_MONTHLY_LINE_ITEMS = 'SET_MONTHLY_LINE_ITEMS';
export const SET_SINGlE_LINE_ITEM = 'SET_SINGlE_LINE_ITEM';
export const SET_SELECTED_ARAVO_ID = 'SET_SELECTED_ARAVO_ID';
export const SET_PO_LINE_METADATA = 'SET_PO_LINE_METADATA';
export const REQUEST_SUPPLIER_PURCHASE_ORDERS =
  'REQUEST_SUPPLIER_PURCHASE_ORDERS';
export const REQUEST_SUPPLIER_PURCHASE_ORDERS_COMPLETE =
  'REQUEST_SUPPLIER_PURCHASE_ORDERS_COMPLETE';
export const SET_SUPPLIER_PURCHASE_ORDERS = 'SET_SUPPLIER_PURCHASE_ORDERS';
export const SET_SUPPLIER_ENGAGEMENTS = 'SET_SUPPLIER_ENGAGEMENTS';
export const SET_AVAILABLE_FISCAL_YEARS = 'SET_AVAILABLE_FISCAL_YEARS';
export const SET_SELECTED_FISCAL_YEAR = 'SET_SELECTED_FISCAL_YEAR';
export const SET_SYNTHETIC_FLAG = 'SET_SYNTHETIC_FLAG';
export const SET_IS_PO_CREATED = 'SET_IS_PO_CREATED';
export const SET_IS_PO_NOT_CREATED = 'SET_IS_PO_NOT_CREATED';
export const SET_IS_SUPPLIER_FILTERED = 'SET_IS_SUPPLIER_FILTERED';

/* Selected Supplier contracts action */
export const REQUEST_SUPPLIER_CONTRACTS = 'REQUEST_SUPPLIER_CONTRACTS';
export const REQUEST_SUPPLIER_CONTRACTS_COMPLETE =
  'REQUEST_SUPPLIER_CONTRACTS_COMPLETE';
export const SET_SUPPLIER_CONTRACTS = 'SET_SUPPLIER_CONTRACTS';

/* Selected Supplier judgements Actions */
export const REQUEST_SUPPLIER_JUDGEMENTS = 'REQUEST_SUPPLIER_JUDGEMENTS';
export const REQUEST_SUPPLIER_JUDGEMENTS_COMPLETE =
  'REQUEST_SUPPLIER_JUDGEMENTS_COMPLETE';
export const SET_SUPPLIER_JUDGEMENTS = 'SET_SUPPLIER_JUDGEMENTS';

/* Aravo To Contract Mapping Actions */
export const REQUEST_SUPPLIER_ARAVOTOCONTRACT_MAPPING =
  'REQUEST_SUPPLIER_ARAVOTOCONTRACT_MAPPING';
export const REQUEST_SUPPLIER_ARAVOTOCONTRACT_MAPPING_COMPLETE =
  'REQUEST_SUPPLIER_ARAVOTOCONTRACT_MAPPING_COMPLETE';
export const SET_SUPPLIER_ARAVOTOCONTRACT_MAPPING =
  'SET_SUPPLIER_ARAVOTOCONTRACT_MAPPING';

/* Supplier Actions */
export const SET_SUPPLIERS_FOR_USER = 'SET_SUPPLIERS_FOR_USER';
export const SET_SUPPLIERS = 'SET_SUPPLIERS';
export const SET_SUPPLIER_TAG_LIST = 'SET_SUPPLIER_TAG_LIST';

/* Features Action */
export const SET_MYORDER_FEATURE = 'SET_MYORDER_FEATURE';
export const SET_MYORDER2_FEATURE = 'SET_MYORDER2_FEATURE';

/* headCount Actions*/
export const SET_BILLABLEUNITS_CHANGE = 'SET_BILLABLEUNITS_CHANGE';
export const SHOW_ERROR_MESSAGE_COMPLETE = 'SHOW_ERROR_MESSAGE_COMPLETE';
export const REQUEST_BILLABLEUNITS_DETAILS_COMPLETE =
  'REQUEST_BILLABLEUNITS_DETAILS_COMPLETE';
export const REQUEST_MONTHLIST_DETAILS = 'REQUEST_MONTHLIST_DETAILS';
export const REQUEST_MONTHLIST_DETAILS_COMPLETE =
  'REQUEST_MONTHLIST_DETAILS_COMPLETE';
export const SET_PERIOD_DETAIL_PRIOR_ADJUSTMENT =
  'SET_PERIOD_DETAIL_PRIOR_ADJUSTMENT';
export const SET_MONTH_LIST = 'SET_MONTH_LIST';
export const IS_NRC_DESCRIPTION_FETCHED = 'IS_NRC_DESCRIPTION_FETCHED';
export const SHOW_ERROR_MESSAGE_MONTH = 'SHOW_ERROR_MESSAGE_MONTH';
export const SHOW_ERROR_MESSAGE_MONTH_COMPLETE =
  'SHOW_ERROR_MESSAGE_MONTH_COMPLETE';
export const GET_SD_Users = 'GET_SD_Users'

/* invoiceView Actions */
export const GET_SUPPLIER_INVOICE_STATE = 'GET_SUPPLIER_INVOICE_STATE';
export const GET_SDM_Locked_DateTime = 'GET_SDM_Locked_DateTime'; 
export const GET_Month_Start_DateTime = 'GET_Month_Start_DateTime';
export const GET_Actuals_DateTime = 'GET_Actuals_DateTime';
export const GET_Accruals_DateTime = 'GET_Accruals_DateTime';
export const SET_EMPTY_BILLABLE_UNITS = 'SET_EMPTY_BILLABLE_UNITS';
export const SHOW_INVOICE_INFO = 'SHOW_INVOICE_INFO';
export const ACCRUALS_INITIAL_LOADER_COMPLETE =
  'ACCRUALS_INITIAL_LOADER_COMPLETE';
export const ACCRUALS_INITIAL_LOADER = 'ACCRUALS_INITIAL_LOADER';
export const REQUEST_INVOICE_DETAILS = 'REQUEST_INVOICE_DETAILS';
export const REQUEST_INVOICE_DETAILS_COMPLETE =
  'REQUEST_INVOICE_DETAILS_COMPLETE';
export const REQUEST_INVOICE_VARIANCE_DETAILS =
  'REQUEST_INVOICE_VARIANCE_DETAILS';
export const REQUEST_INVOICE_VARIANCE_DETAILS_COMPLETE =
  'REQUEST_INVOICE_VARIANCE_DETAILS_COMPLETE';
export const SHOW_ERROR_MESSAGE_INVOICE = 'SHOW_ERROR_MESSAGE_INVOICE';
export const SHOW_ERROR_MESSAGE_INVOICE_COMPLETE =
  'SHOW_ERROR_MESSAGE_INVOICE_COMPLETE';
export const SET_INVOICE_ACCRUAL_ACTUAL_DETAILS =
  'SET_INVOICE_ACCRUAL_ACTUAL_DETAILS';
export const SET_SUPPLIER_INVOICE = 'SET_SUPPLIER_INVOICE';
export const TOTAL_CALCULATION_DETAILS = 'TOTAL_CALCULATION_DETAILS';
export const INITAL_CALCULATION_VALUES = 'INITAL_CALCULATION_VALUES';
export const IS_COLUMN_SORTED = 'IS_COLUMN_SORTED';
export const GET_MICROSOFT_SUBMITTED_BILLABLE_UNITS =
  'GET_MICROSOFT_SUBMITTED_BILLABLE_UNITS';
export const SET_BILLABLEUNITS_COUNT = 'SET_BILLABLEUNITS_COUNT';
export const GET_BILLABLEUNITS_FOR_ALL_SUPPLIERS =
  'GET_BILLABLEUNITS_FOR_ALL_SUPPLIERS';
export const GET_NRC_TYPES = 'GET_NRC_TYPES';
export const GET_NRC_CATEGORIES = 'GET_NRC_CATEGORIES';
export const GET_NRC_DESCRIPTION = 'GET_NRC_DESCRIPTION';
export const GET_SUPPLIER_VARIANCE = 'GET SUPPLIER VARIANCE';
export const SET_IS_DISPLAY_BUTTON_CLICKED = 'SET_IS_DISPLAY_BUTTON_CLICKED';
export const GET_DATA_FOR_ACTUAL_ACCRUAL_CALL =
  'GET_DATA_FOR_ACTUAL_ACCRUAL_CALL';
export const GET_ALL_INVOICE_ACCRUALS = 'GET_ALL_INVOICE_ACCRUALS';
export const GET_ALL_INVOICE_ACTUALS = 'GET_ALL_INVOICE_ACTUALS';
export const IS_ALL_INVOICE_LOADER = 'IS_ALL_INVOICE_LOADER';
export const SET_IS_ACTION_CHANGED = 'SET_IS_ACTION_CHANGED';

/* Un Auth User Actions */
export const SET_ACCESS_REQUEST = 'SET_ACCESS_REQUEST';
export const SET_AVAILABLE_ROLES = 'SET_AVAILABLE_ROLES';
export const SET_SELECTED_ROLES = 'SET_SELECTED_ROLES';
export const SET_BUSINESSJUSTIFICATION = 'SET_BUSINESSJUSTIFICATION';
export const SET_SUBMITREQUEST_STATUS = 'SET_SUBMITREQUEST_STATUS';
export const SET_MAILREMINDER_ELIGIBILITY = 'SET_MAILREMINDER_ELIGIBILITY';
export const SET_MAILREMINDER_DIALOG_VISIBILITY =
  'SET_MAILREMINDER_DIALOG_VISIBILITY';

/* BOB Report Actions */
export const SET_BOBREPORT_FILTEROPTIONS = 'SET_BOBREPORT_FILTEROPTIONS';
export const SET_INVOICEREPORT_SELECTEDSUPPLIERS =
  'SET_INVOICEREPORT_SELECTEDSUPPLIERS';
export const SET_INVOICEREPORT_SELECTEDQUARTERS =
  'SET_INVOICEREPORT_SELECTEDQUARTERS';
export const SET_INVOICEREPORT_SELECTEDSOURCES =
  'SET_INVOICEREPORT_SELECTEDSOURCES';
export const SET_INVOICEREPORT_SELECTEDFISCALYEARS =
  'SET_INVOICEREPORT_SELECTEDFISCALYEARS';
export const SET_INVOICEREPORT_SUMMARYDATA = 'SET_INVOICEREPORT_SUMMARYDATA';
export const SET_INVOICEREPORT_DETAILS = 'SET_INVOICEREPORT_DETAILS';
export const REQUEST_INVOICEREPORT_SUMMARY = 'REQUEST_INVOICEREPORT_SUMMARY';
export const REQUEST_INVOICEREPORT_SUMMARYCOMPLTE =
  'REQUEST_INVOICEREPORT_SUMMARYCOMPLTE';
export const REQUEST_INVOICEREPORT_DETAILS = 'REQUEST_INVOICEREPORT_DETAILS';
export const REQUEST_INVOICEREPORT_DETAILSCOMPLTE =
  'REQUEST_INVOICEREPORT_DETAILSCOMPLTE';
export const SET_INVOICEREPORT_SELCTEDFILTERS =
  'SET_INVOICEREPORT_SELCTEDFILTERS';
export const SET_BUDGETREPORT_SELECTEDSUPPLIERS =
  'SET_BUDGETREPORT_SELECTEDSUPPLIERS';
export const SET_BUDGETREPORT_SELECTEDQUARTERS =
  'SET_BUDGETREPORT_SELECTEDQUARTERS';
export const SET_BUDGETEREPORT_SELECTEDBUDGETTYPES =
  'SET_BUDGETEREPORT_SELECTEDBUDGETTYPES';
export const SET_BudgetREPORT_SUMMARYDATA = 'SET_BudgetREPORT_SUMMARYDATA';
export const REQUEST_BUDGETREPORT_SUMMARY = 'REQUEST_BUDGETREPORT_SUMMARY';
export const REQUEST_BUDGETREPORT_SUMMARYCOMPLTE =
  'REQUEST_BUDGETREPORT_SUMMARYCOMPLTE';
export const SET_BUDGETREPORT_SELCTEDFILTERS =
  'SET_BUDGETREPORT_SELCTEDFILTERS';
export const SET_BUDGETREPORT_DETAILS = 'SET_BUDGETREPORT_DETAILS';
export const REQUEST_BUDGETREPORT_DETAILS = 'REQUEST_BUDGETREPORT_DETAILS';
export const REQUEST_BUDGETREPORT_DETAILSCOMPLTE =
  'REQUEST_BUDGETREPORT_DETAILSCOMPLTE';

export const GET_USER_PREFERENCES = 'GET_USER_PREFERENCES';
export const SAVE_USER_PREFERENCES = 'SAVE_USER_PREFERENCES';

/*Nrc Line Item */
export const GET_NRC_LINE_CATEGORIES = 'GET_NRC_LINE_CATEGORIES';