import {
  DetailsList,
  IColumn,
  IDetailsHeaderProps,
  IDetailsList,
  IRenderFunction,
} from 'office-ui-fabric-react';
import { Sticky, StickyPositionType } from 'office-ui-fabric-react/lib/Sticky';
import * as React from 'react';
import { toast } from 'react-toastify';
import gtoApiInstance, {
  gtoMsalApiFetch,
} from '../../../../shared/http/GtoApiNew.axios';
import {
  Routes,
  TelemetryEvent,
  TelemetryException,
} from '../../../../shared/models';
import { IContract } from '../../../../shared/models/Contract.model';
import telemetryContext from '../../../../shared/services/TelemetryServices';
import { contractColumns } from '../../../../shared/styles/ContractListColumns';
import { customDetailsListHeaderStyles } from '../../../../shared/styles/DetailsListHeaderStyles';
import { css } from 'glamor';
import { v4 as uuidv4 } from 'uuid';
import appConfig from '../../../../assets/configuration/config';

export interface ISearchResultsProps {
  showResults: boolean;
  masterContractID: number;
}

export interface ISearchResultsState {
  contractItems: IContract[];
  showSearchResults: boolean;
}

export class SearchResults extends React.Component<
  ISearchResultsProps,
  ISearchResultsState
> {
  private _root = React.createRef<IDetailsList>();
  private _columns: IColumn[];

  constructor(props: ISearchResultsProps) {
    super(props);

    // initialize necessary stuff
    this.state = {
      // can't have contractItems be an empty array,
      // so just initialize it with the prop that was passed in
      contractItems: [],
      showSearchResults: false,
    };

    // Columns for the Map a New Contract
    this._columns = contractColumns;
  }

  // ---------------------- LIFECYCLE METHODS ------------------------ //

  public static getDerivedStateFromProps(props) {
    return { showSearchResults: props.showResults };
  }

  componentDidUpdate(prevProps) {
    // console.log('Prev Props', prevProps);
    // console.log('current props', this.props);

    if (prevProps.showResults !== this.props.showResults) {
      if (this.state.showSearchResults) {
        if (appConfig.UseMockData === 'false') {
          // fetch all contract data from api based on master contract's
          // id that was passed in through props
          // Defining the url
          const url: string = `${Routes.FetchContractDetails}?contractID=${this.props.masterContractID}`;

          let _this = this;
          // try to fetch all contract details - api call

          // Logging telemetry
          _this.logFetchContractDetailsBegin(url);
          // console.log('masterContractId',_this.props);

          _this
            .callGetRelatedContractsAPI(url, _this.props.masterContractID)
            .then(function (result) {
              // console.log('result'+result.data);
              // if there is a message, then there was an error
              if (result.Message !== undefined) {
                // log the exception
                _this.logFetchContractDetailsFailure(url, result.Message);

                toast.error('Could not fetch contract details', {
                  className: css({
                    background: '#a80000 !important',
                  }),
                });
              } else {
                _this.parseResult(result);
                // Logging success
                _this.logFetchContractDetailsSuccess(url);
              }
            });
        } else {
          // if want to use mock data
          // console.log('This will generate Mock Dummy Data')
          this.setState({ contractItems: this.generateDummyData() });
        }
      } else {
        // console.log('Calling init State');
        this.initState();
      }
    }
  }

  public render() {
    const { contractItems } = this.state;

    return (
      <div>
        <DetailsList
          className="searchResultsList"
          checkboxVisibility={2} // to hide the checkboxes
          componentRef={this._root}
          items={contractItems}
          ariaLabelForGrid="Map a New Contract List"
          columns={this._columns}
          onRenderDetailsHeader={(
            detailsHeaderProps: IDetailsHeaderProps,
            defaultRender: IRenderFunction<IDetailsHeaderProps>
          ) => {
            // custom header styles
            return (
              <Sticky stickyPosition={StickyPositionType.Header}>
                {defaultRender({
                  ...detailsHeaderProps,
                  styles: customDetailsListHeaderStyles,
                })}
              </Sticky>
            );
          }}
          groupProps={{
            showEmptyGroups: true,
          }}
          onRenderItemColumn={this._onRenderColumn}
        />
      </div>
    );
  }

  // -------------------- END OF LIFECYCLE METHODS --------------------- //

  private async callGetRelatedContractsAPI(url, contractID) {
    try {
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };
      let temp = await gtoMsalApiFetch(gtoApiInstance, url, {
        contractID,
        headers,
      });

      return temp;
    } catch (ex) {
      return ex.response.data;
    }
  }

  // After GetRelatedContracts API this will parse the result
  private parseResult(result) {
    let contractsList: IContract[] = [];
    //let numAmendments = 0;
    let numAddendums = 0;
    // map to "this.filteredContracts", making each returned object an
    // IContract object
    for (let i = 0; i < result.data.length; i++) {
      // initialize the contract object
      let contract: IContract = {
        contractNumber: 0,
        aravoEngagementId: '',
        contractID: 0,
        supplierName: '',
        supplierNumber: 0,
        contractDescription: '',
        companyCode: 0,
        typeOfContract: 0,
        expirationDate: '',
        expDateValue: 0,
        IsSharedContract: false,
      };

      // actually set the values
      contract.contractID = result.data[i].contractId;
      // console.log('API Returned ContractID',result.data[i].ContractID);
      contract.supplierName = result.data[i].supplierName;
      contract.contractDescription = result.data[i].contractDescription;
      contract.companyCode = result.data[i].companyCode;
      contract.typeOfContract = result.data[i].contractType;
      contract.supplierNumber = result.data[i].supplierNumber;

      // TODO : Contract Type data should return type of contract and accordingly change
      // if (contract.type === TypeOfContract.amendment) {
      //   numAmendments++;
      // } else if (contract.type === TypeOfContract.addendum) {
      //   numAddendums++;
      // }

      // Rendering based on contract description which will change
      // if (contract.contractDescription === 'amendment description') {
      //   numAmendments++;
      // } else if (contract.contractDescription === 'addendum description') {
      //   numAddendums++;
      // }
      // setting the expiration date
      let expDate = result.data[i].expirationDate;

      // '0001-01-01T00:00:00' is the min value returned by API
      // - meaning that the value was null in the DB
      if (expDate !== '0001-01-01T00:00:00') {
        let dateObj = new Date(result.data[i].expirationDate);

        contract.expirationDate = dateObj.toLocaleDateString();
        contract.expDateValue = dateObj.valueOf();
      }

      contractsList.push(contract);
    }
    // this.mapListToDetailsList(contractsList, numAddendums, numAmendments);
    this.mapListToDetailsList(contractsList, numAddendums);
  }

  // Map List to the DetailList component
  // private mapListToDetailsList(contractsList, numAddendums, numAmendments)
  private mapListToDetailsList(contractsList, numAddendums) {
    // first sort the array by the type of contract
    contractsList.sort((a, b) => (a.type > b.type ? 1 : -1));

    // console.log('contract List', contractsList.count());
    this.setState({
      contractItems: contractsList,
    });
  }

  private _onRenderColumn(item: IContract, index: number, column: IColumn) {
    const value =
      item && column && column.fieldName
        ? item[column.fieldName as keyof IContract] || ''
        : '';

    return <div data-is-focusable={true}>{value}</div>;
  }

  private randomDate(
    start: Date,
    end: Date
  ): { value: number; dateFormatted: string } {
    const date: Date = new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    );
    return {
      value: date.valueOf(),
      dateFormatted: date.toLocaleDateString(),
    };
  }

  private initState() {
    this.setState({
      contractItems: [
        {
          contractNumber: 0,
          aravoEngagementId: '',
          contractID: 123,
          supplierName: 'Alorica',
          supplierNumber: 0,
          expirationDate: this.randomDate(new Date(2012, 0, 1), new Date())
            .dateFormatted,
          expDateValue: this.randomDate(new Date(2012, 0, 1), new Date()).value,
          contractDescription: 'this is an example description',
          companyCode: 1010,
          typeOfContract: 0,
          IsSharedContract: false,
        },
      ],
    });
  }

  private generateDummyData() {
    const items: IContract[] = [];
    for (let i = 0; i < 20; i++) {
      const randomContractID = 1234567 + i;
      // const randomSupplierName = i;
      // const randomCompanyCode = 123 + i;
      const randomDate = this.randomDate(new Date(2012, 0, 1), new Date());
      items.push({
        contractNumber: 0,
        aravoEngagementId: '',
        contractID: randomContractID,
        supplierName: 'Alorica',
        supplierNumber: 0,
        expirationDate: randomDate.dateFormatted,
        expDateValue: randomDate.value,
        contractDescription: 'this is an example description',
        companyCode: 1010,
        typeOfContract: 0,
        IsSharedContract: false,
      });
    }
    return items;
  }

  // ----------- LOGGING METHODS ------------------ //
  private logFetchContractDetailsBegin(url) {
    telemetryContext.logEvent(TelemetryEvent.FetchContractDetailsBegin, {
      url: url,
      masterContractID: this.props.masterContractID,
    });
  }

  private logFetchContractDetailsSuccess(url) {
    telemetryContext.logEvent(TelemetryEvent.FetchContractDetailsSuccess, {
      url: url,
      masterContractID: this.props.masterContractID,
    });
  }

  private logFetchContractDetailsFailure(url, resultMessage) {
    telemetryContext.logException(
      resultMessage,
      TelemetryException.FetchContractDetailsFailure,
      undefined,
      {
        url: url,
        masterContractID: this.props.masterContractID,
      }
    );
  }
  // ----------- END OF LOGGING METHODS ----------- //
}
