import { IRenderFunction } from '@uifabric/utilities';
import { IDetailsHeaderStyles } from '@fluentui/react';
import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IDetailsHeaderProps,
  Selection,
  SelectionMode,
} from '@fluentui/react/lib/DetailsList';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { Sticky, StickyPositionType } from 'office-ui-fabric-react/lib/Sticky';
import * as React from 'react';
import { toast } from 'react-toastify';
import { css } from 'glamor';

// Interface for PurchaseOrderTable states
interface IPurchaseOrderTableState {
  // TODO: Clean these up because we can make this component stateless.
  selectionDetails: string;
  isModalSelection: boolean;
  isCompactMode: boolean;
}

// Interface for PurchaseOrderTable props
interface IPurchaseOrderTableProps {
  columns: IColumn[];
  items: any;
}

// Custom Header Styles for the PO Table
export const customHeaderStyles: Partial<IDetailsHeaderStyles> = {
  root: {
    backgroundColor: 'transparent',
    borderBottom: ' 1px solid lightgray;',
    height: '32px',
    paddingBottom: '10px',
    selectors: {
      '.ms-DetailsHeader-cell': {
        height: '100%',
      },
      '.ms-DetailsHeader-cellTitle': {
        height: '100%',
      },
      '.ms-DetailsHeader-cellTitle i': {
        margin: 'auto 0 0 0',
      },
      '.ms-DetailsHeader-cellName': {
        fontFamily: 'Segoe UI',
        color: '#333333',
        fontSize: '12px',
        fontWeight: 'bold',
        lineHeight: 'normal',
        margin: 'auto 0 0 0',
        whiteSpace: 'pre-line',
      },
    },
  },
};

export class PurchaseOrderTable extends React.Component<
  IPurchaseOrderTableProps,
  IPurchaseOrderTableState
> {
  private selection: Selection;

  constructor(props: IPurchaseOrderTableProps) {
    super(props);

    // TODO: Investigate where this is being used.
    this.selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.state = {
      selectionDetails: this._getSelectionDetails(),
      isModalSelection: true,
      isCompactMode: false,
    };
  }

  public render() {
    return (
      <MarqueeSelection selection={this.selection}>
        <DetailsList
          items={this.addKeyToItems(this.props.items)}
          compact={false}
          columns={this.props.columns}
          selectionMode={SelectionMode.none}
          setKey="set"
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          selection={this.selection}
          selectionPreservedOnEmptyClick={true}
          onItemInvoked={this._onItemInvoked}
          enterModalSelectionOnTouch={true}
          ariaLabelForGrid="Purchase Order Details Table"
          ariaLabelForSelectionColumn="Toggle selection"
          ariaLabelForSelectAllCheckbox="Toggle selection for all items"
          // tslint:disable-next-line: jsx-no-lambda
          onRenderDetailsHeader={(
            detailsHeaderProps: IDetailsHeaderProps,
            defaultRender: IRenderFunction<IDetailsHeaderProps>
          ) => {
            return (
              <Sticky stickyPosition={StickyPositionType.Header}>
                {defaultRender({
                  ...detailsHeaderProps,
                  styles: customHeaderStyles,
                })}
              </Sticky>
            );
          }}
        />
      </MarqueeSelection>
    );
  }

  // TODO: See if this can be removed or implemented another way.
  private addKeyToItems(items: any): any[] {
    let i = 0;
    items.forEach(element => {
      element['key'] = i;
      i++;
    });

    return items;
  }

  private _onItemInvoked(item: any): void {
    toast.info(`Item invoked: ${item.PONumber}`, {
      className: css({
        background: '#0275d8 !important',
      }),
    });
  }

  private _getSelectionDetails(): string {
    const selectionCount = this.selection.getSelectedCount();

    switch (selectionCount) {
      case 0:
        return 'No items selected';

      default:
        return `${selectionCount} items selected`;
    }
  }
}
