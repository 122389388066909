import { delay } from 'q';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import gtoApiInstance, {
  gtoMsalApiFetch,
} from '../../shared/http/GtoApiNew.axios';
import {
  budgetMockData,
  existingContractsMockData,
  purchaseOrderMockData,
} from '../../shared/mock';
import { aravoToContractMappingMockData } from '../../shared/mock/AravoToContractsMappingMockData';
import {
  // IAravoToContractMap,
  IBudget,
  IContract,
  // IJudgement,
  IPurchaseOrder,
  ISupplier,
  Routes,
  TelemetryEvent,
  TelemetryException,
  TypeOfContract,
  IAravoToContractMap,
  IJudgement,
  ISupplierEngagement,
  IPoLineItemNew,
  IPurchaseOrderNew,
  IPurchaseOrderAmoutAndLineItems,
} from '../../shared/models';
import telemetryContext from '../../shared/services/TelemetryServices';
import * as actionTypes from './actionTypes';
import { getSuppliers } from './suppliers.action';
import { css } from 'glamor';
import appConfig from '../../assets/configuration/config';
import { isNullOrUndefined } from '../../shared/common/common-util';

// This action can be used to set the selected supplier.
export const setSelectedSupplier = (supplier: ISupplier) => {
  return { type: actionTypes.SET_SELECTED_SUPPLIER, supplier };
};

// This action can be used for requesting supplier budget.
export const requestBudgetForSupplier = () => {
  return { type: actionTypes.REQUEST_SUPPLIER_BUDGET };
};
// This action can be used for requesting supplier contracts.
export const setReturnToSelectedSupplier = (value: boolean) => {
  return { type: actionTypes.SET_RETURN_TO_SELECTED_SUPPLIER, value };
};

// This action can be used for requesting supplier budget.
export const setSelectedLineItemKey = (selectedLineItemKey: string) => {
  return { type: actionTypes.SET_SELECTED_LINE_ITEM_KEY, selectedLineItemKey };
};

// This action can be used for requesting supplier purchase orders.
export const requestPurchaseOrdersForSupplier = () => {
  return { type: actionTypes.REQUEST_SUPPLIER_PURCHASE_ORDERS };
};

// This action can be used for requesting supplier contracts.
export const requestContractsForSupplier = () => {
  return { type: actionTypes.REQUEST_SUPPLIER_CONTRACTS };
};
// This action can be used for requesting supplier judgements.
export const requestJudgementsForSupplier = () => {
  return { type: actionTypes.REQUEST_SUPPLIER_JUDGEMENTS };
};
// This action can be used for requesting supplier contracts.
export const requestAravoToContractMappingForSupplier = () => {
  return { type: actionTypes.REQUEST_SUPPLIER_ARAVOTOCONTRACT_MAPPING };
};

// This action can be used to indicate that supplier budget fetch is complete.
export const requestBudgetForSupplierComplete = () => {
  return { type: actionTypes.REQUEST_SUPPLIER_BUDGET_COMPLETE };
};

// This action can be used to indicate that supplier budget fetch is complete.
export const requestAravoToContractMappingForSupplierComplete = () => {
  return {
    type: actionTypes.REQUEST_SUPPLIER_ARAVOTOCONTRACT_MAPPING_COMPLETE,
  };
};

// This action can be used for requesting supplier judgements fetch is complete.
export const requestJudgementsForSupplierComplete = () => {
  return { type: actionTypes.REQUEST_SUPPLIER_JUDGEMENTS_COMPLETE };
};
// This action can be used for indicating that supplier purchase order fetch is complete.
export const requestPurchaseOrdersForSupplierComplete = () => {
  return { type: actionTypes.REQUEST_SUPPLIER_PURCHASE_ORDERS_COMPLETE };
};

// This action can be used for indicating that supplier contracts fetch is complete.
export const requestContractsForSupplierComplete = () => {
  return { type: actionTypes.REQUEST_SUPPLIER_CONTRACTS_COMPLETE };
};

// This action can be used for indicating that supplier contracts fetch is complete.
export const setSelectedAravoId = (selectedAravoId: string) => {
  return {
    selectedAravoId,
    type: actionTypes.SET_SELECTED_ARAVO_ID,
  };
};

// This action can be used for indicating that supplier contracts fetch is complete.
export const setNetPOAmountForSupplier = (netPoAmount: number) => {
  return { type: actionTypes.SET_NETPOAMOUNT_FOR_SUPPLIER, netPoAmount };
};

// This action can be used for indicating that supplier contracts fetch is complete.
export const setSupplierEngagements = (
  supplierEngagements: ISupplierEngagement[]
) => {
  return { type: actionTypes.SET_SUPPLIER_ENGAGEMENTS, supplierEngagements };
};

// This action can be used to set the supplier budget.
export const setBudgetForSupplier = (supplierBudget: IBudget) => {
  return { type: actionTypes.SET_SUPPLIER_BUDGET, budget: supplierBudget };
};

// This action can be used to set the supplier budget.
export const setPurchaseOrderCreated = (poNumber: string) => {
  return { type: actionTypes.SET_PURCHASE_ORDER_CREATED, poNumber };
};

export const setIsPOCreated = (isPOCreated: boolean) => {
  return { type: actionTypes.SET_IS_PO_CREATED, isPOCreated };
};

export const setPONotCreated = (poNotCreated: boolean) => {
  return { type: actionTypes.SET_IS_PO_NOT_CREATED, poNotCreated };
};

// This action can be used to set the supplier Judgement.
export const setJudgementsForSupplier = (supplierJudgement: IJudgement[]) => {
  let totalOtherJudgements: number = 0;
  let totalBudgetJudgement: number = 0;

  supplierJudgement.forEach((x) => {
    if (!x.IsBudgetJudgement) {
      totalOtherJudgements += x.JudgementAmountLC;
    } else {
      totalBudgetJudgement = x.JudgementAmountLC;
    }
  });

  return {
    judgements: supplierJudgement,
    totalBudgetJudgement: totalBudgetJudgement,
    totalOtherJudgements: totalOtherJudgements,
    type: actionTypes.SET_SUPPLIER_JUDGEMENTS,
  };
};

// This action can be used to set the supplier budget.
export const setAravoToContractMappingForSupplier = (
  aravoToContractMapping: IAravoToContractMap[]
) => {
  return {
    type: actionTypes.SET_SUPPLIER_ARAVOTOCONTRACT_MAPPING,
    aravoToContractMapping,
  };
};

// This action can be used to set the supplier purchase order number.
export const setPurchaseOrdersForSupplier = (
  purchaseOrders: IPurchaseOrder[]
) => {
  return {
    purchaseOrders,
    type: actionTypes.SET_SUPPLIER_PURCHASE_ORDERS,
  };
};

// This action can be used to set the supplier contracts.
export const setContractsForSupplier = (contracts: IContract[]) => {
  return {
    contracts,
    type: actionTypes.SET_SUPPLIER_CONTRACTS,
  };
};

// This action can be used to set available fiscal years for the supplier.
export const setAvailableFiscalYearsForSupplier = (fiscalYears: number[]) => {
  return {
    availableFiscalYears: fiscalYears,
    type: actionTypes.SET_AVAILABLE_FISCAL_YEARS,
  };
};

// This action can be used to set the line items.
export const setMonthlyLineItems = (lineItems: IPoLineItemNew[]) => {
  return {
    lineItems,
    type: actionTypes.SET_MONTHLY_LINE_ITEMS,
  };
};

// This action can be used to set the line items.
export const setPoLineMetadata = (
  lineItemData: IPurchaseOrderAmoutAndLineItems
) => {
  return {
    lineItemData,
    type: actionTypes.SET_PO_LINE_METADATA,
  };
};

export const setSingleLineItems = (lineItems: any[]) => {
  return {
    lineItems,
    type: actionTypes.SET_SINGlE_LINE_ITEM,
  };
};

// This action can be used to set available fiscal years for the supplier.
export const setSelectedFiscalYearForSupplier = (fiscalYear: number) => {
  return {
    type: actionTypes.SET_SELECTED_FISCAL_YEAR,
    year: fiscalYear,
  };
};

export const setIsSupplierFiltered = (isSupplierFiltered: boolean) => {
  return {
    type: actionTypes.SET_IS_SUPPLIER_FILTERED,
    isSupplierFiltered,
  };
};

/*--------------------------------------THUNK ACTION CREATORS-----------------------------------*/

// This action creator loads the budget data for the given supplier.
export const getPoLineItemsForSupplier = (
  supplier: ISupplier,
  fiscalYearNumber: string
) => {
  return (dispatch, getState) => {
    // dispatch(requestBudgetForSupplier()); TODO: See if this is needed.
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers
      const headers: any = {
        SubCorrelationKey: uuidv4(),
        'X-CorrelationId': uuidv4(),
      };

      let url: string;
      // Defining the url
      url = `${Routes.FetchLineItemsForSupplier}?SupplierId=${supplier.supplierId}&FiscalYear=${fiscalYearNumber}`;

      // Logging telemetry
      telemetryContext.logEvent(TelemetryEvent.FetchLineItemDataBegin, {
        headers,
        url,
      });

      // console.log('Calling Po Line item data start');

      // console.log('calling api');
      return gtoMsalApiFetch(gtoApiInstance.get, url, { headers })
        .then((response: any) => {
          // Logging success
          telemetryContext.logEvent(TelemetryEvent.FetchLineItemDataSuccess, {
            headers,
            url,
          });
          // console.log('Calling PO Line item data success');
          // console.log(response.data);

          const { data } = response;
          const purchaseOrderAmoutAndLineItems: IPurchaseOrderAmoutAndLineItems = {
            purchaseOrderAmount: data.amount,
            purchaseOrderAmountFormula: data.purchaseOrderAmountFormula,
            purchaseOrderAmountBreakdown: data.purchaseOrderAmountBreakdown,
            purchaseOrderDescription: data.description,
            lineItemByMonths: data.lineItems.map((x) => ({
              amount: x.amount,
              month: x.month,
              internalOrder: x.internalOrder === '0' ? '' : x.internalOrder,
              costCenter: x.costCenter === '0' ? '' : x.costCenter,
              accountCode: x.accountCode,
              supplierEngagements: x.supplierEngagements,
              description: x.description,
            })),
          };

          // console.log(
          //   'purchaseOrderAmoutAndLineItems',
          //   purchaseOrderAmoutAndLineItems
          // );

          if (
            (getState().selectedSupplier.basicInfo !== null &&
              getState().selectedSupplier.basicInfo.supplierNumber ===
                supplier.supplierNumber) ||
            getState().selectedSupplier.basicInfo === null
          ) {
            let item: IPoLineItemNew[] = purchaseOrderAmoutAndLineItems.lineItemByMonths.map(
              (x, index) => ({
                amount: x.amount,
                month: x.month,
                internalOrder: x.internalOrder,
                costCenter: x.costCenter,
                accountCode: x.accountCode,
                supplierEngagements: x.supplierEngagements,
                description: x.description,
                isPrePaid: x.isPrePaid,
                lineItemNumber: index + 1,
              })
            );

            let counter = 1;
            let monthlyItems = purchaseOrderAmoutAndLineItems.lineItemByMonths.map(
              (item) => {
                let newItem: IPoLineItemNew = { ...item };
                newItem.lineItemNumber = counter++;
                newItem.supplierEngagements = item.supplierEngagements;
                newItem.isPrePaid = false;
                newItem.description = item.month.toString();
                return newItem;
              }
            );

            // console.log('monthly', monthlyItems);
            // console.log('single', item);
            dispatch(setSingleLineItems(item));
            dispatch(setMonthlyLineItems(monthlyItems));
            dispatch(setPoLineMetadata(purchaseOrderAmoutAndLineItems));
          }
        })
        .catch((error) => {
          // console.log('Calling Line Item data failed', error);

          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchLineItemDataFailure,
            undefined,
            {
              headers,
              url,
            }
          );

          if (error.response && error.response.status === 404) {
            // TODO: See if you want to do any alerting here.
          }
          // dispatch(requestBudgetForSupplierComplete()); TODO See if this is required.
        });
    } else {
      return delay(2000).then(() => {
        // dispatch(setBudgetForSupplier(budgetMockData)); TODO: Implement this.
        // dispatch(requestBudgetForSupplierComplete()); TODO: see if this is required.
      });
    }
  };
};

// This action creator loads the budget data for the given supplier.
export const getBudgetForSupplier = (
  supplier: ISupplier,
  fiscalYearNumber: string = ''
) => {
  return (dispatch, getState) => {
    dispatch(requestBudgetForSupplier());
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers
      const headers: any = {
        SubCorrelationKey: uuidv4(),
        'X-CorrelationId': uuidv4(),
      };

      let url: string;
      // Defining the url
      if (fiscalYearNumber === '') {
        url = `${Routes.FetchBudgetData}?supplierId=${supplier.supplierId}`;
      } else {
        url = `${Routes.FetchBudgetData}?supplierId=${supplier.supplierId}&FiscalYear=${fiscalYearNumber}`;
      }

      // Logging telemetry
      telemetryContext.logEvent(
        TelemetryEvent.FetchBudgetDataForSupplierBegin,
        {
          headers,
          url,
        }
      );

      // console.log('Calling budget data start');

      return gtoMsalApiFetch(gtoApiInstance.get, url, { headers })
        .then((response: any) => {
          // Logging success
          telemetryContext.logEvent(
            TelemetryEvent.FetchBudgetDataForSupplierSuccess,
            {
              headers,
              url,
            }
          );
          // console.log('Calling budget data success');
          // console.log(response.data);

          const { data } = response;

          // Map budget data from API
          const budgetData: IBudget = {
            OriginalBudgetLC: data.originalBudgetLC,
            OriginalBudgetCD: data.originalBudgetCD,
            YTDActualsLC: data.ytdActualsLC,
            YTDActualsCD: data.ytdActualsCD,
            BudgetAfterJudgementLC: data.budgetAfterJudgementLC,
            BudgetAfterJudgementCD: data.budgetAfterJudgementCD,
            AdjustmentsLC: data.adjustmentsLC,
            AdjustmentsCD: data.adjustmentsCD,
            ProjectedSpendLC: data.projectedSpendLC,
            ProjectedSpendCD: data.projectedSpendCD,
            OriginalPOAmountLC: data.originalPOAmountLC,
            OriginalPOAmountCD: data.originalPOAmountCD,
            EstimatedAmountLC: 0,
            EstimatedAmountCD: 0,
            BudgetAndActualDetails: data.budgetAndActualDetails.map((y) => ({
              CalenderMonth: y.calenderMonth,
              CalendarYear: y.calendarYear,
              CumulativeMonth: y.cumulativeMonth,
              TrendType: y.trendType,
              ExchangeRateAmt: y.exchangeRateAmt,
              AmountLC: y.amountLC,
              AmountCD: y.amountCD,
              BudgetLC: y.budgetLC,
              BudgetCD: y.budgetCD,
            })),
          };

          // Calculating a summation of the monthly amounts for the supplier.
          budgetData.BudgetAndActualDetails.forEach((element) => {
            budgetData.EstimatedAmountLC += element.AmountLC;
            budgetData.EstimatedAmountCD += element.AmountCD;
          });

          if (
            (getState().selectedSupplier.basicInfo !== null &&
              getState().selectedSupplier.basicInfo.supplierNumber ===
                supplier.supplierNumber) ||
            getState().selectedSupplier.basicInfo === null
          ) {
            dispatch(setBudgetForSupplier(budgetData));
          }

          dispatch(requestBudgetForSupplierComplete());
        })
        .catch((error) => {
          // console.log('Calling budget data failed', error);

          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchBudgetDataForSupplierFailure,
            undefined,
            {
              headers,
              url,
            }
          );

          if (error.response && error.response.status === 404) {
            // TODO: See if you want to do any alerting here.
          }
          dispatch(requestBudgetForSupplierComplete());
        });
    } else {
      return delay(2000).then(() => {
        dispatch(setBudgetForSupplier(budgetMockData));
        dispatch(requestBudgetForSupplierComplete());
      });
    }
  };
};

export const getFiscalYearsForSupplier = () => {
  return (dispatch) => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers
      const headers: any = {
        SubCorrelationKey: uuidv4(),
        'X-CorrelationId': uuidv4(),
      };

      // Defining the url
      const url: string = `${Routes.GetAvailableFiscalYears}
      `;

      // Logging telemetry
      telemetryContext.logEvent(
        TelemetryEvent.FetchAvailableFiscalYearsForSupplierBegin,
        {
          headers,
          url,
        }
      );

      // console.log('Calling available fiscal years api start');

      return gtoMsalApiFetch(gtoApiInstance.get, url, { headers })
        .then((response: any) => {
          // Logging success
          telemetryContext.logEvent(
            TelemetryEvent.FetchAvailableFiscalYearsForSupplierSuccess,
            {
              headers,
              url,
            }
          );
          // console.log('Calling available fiscal years success');
          // console.log('fiscal year data', response.data);

          let fiscalYearNumbers: number[] = response.data.fiscalYearNumbers.reverse();
          // hardcoded removal of fiscal year numbers before 2019
          fiscalYearNumbers = fiscalYearNumbers.filter((x) => x >= 2019);
          // Map budget data from API

          // console.log('fiscalYearNumbers', fiscalYearNumbers);
          dispatch(setAvailableFiscalYearsForSupplier(fiscalYearNumbers));
          dispatch(setSelectedFiscalYearForSupplier(response.data.currentFiscalYearNumber));

        })
        .catch((error) => {
          // console.log('Calling avaialable fiscal years failed');

          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchAvailableFiscalYearsForSupplierFailure,
            undefined,
            {
              headers,
              url,
            }
          );

          if (error.response && error.response.status === 404) {
            toast.error(
              'Fiscal Year information is not available for this supplier.',
              {
                className: css({
                  background: '#a80000 !important',
                }),
              }
            );
          }
        });
    } else {
      return delay(2000).then(() => {
        dispatch(setAvailableFiscalYearsForSupplier([]));
        dispatch(setSelectedFiscalYearForSupplier(null));
      });
    }
  };
};

// This action gets the purchase orders for the given supplier.
export const getPurchaseOrdersForSupplier = (
  supplier: ISupplier,
  fiscalYearNumber: string = ''
) => {
  return (dispatch, getState) => {
    dispatch(requestPurchaseOrdersForSupplier());
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers
      const headers: any = {
        SubCorrelationKey: uuidv4(),
        'X-CorrelationId': uuidv4(),
      };

      let url: string;

      // Defining the url. TODO: See if this works properly and if the api accepts calls without a fiscal year number.
      if (fiscalYearNumber === '') {
        url = `${Routes.FetchPOData}?supplierId=${supplier.supplierId}`;
      } else {
        url = `${Routes.FetchPOData}?supplierId=${supplier.supplierId}&FiscalYear=${fiscalYearNumber}`;
      }

      // Logging telemetry
      telemetryContext.logEvent(
        TelemetryEvent.FetchPurhaseOrderDataForSupplierBegin,
        {
          headers,
          url,
        }
      );

      // console.log('Calling PO data start');

      return gtoMsalApiFetch(gtoApiInstance.get, url, { headers })
        .then((response: any) => {
          // Logging success
          telemetryContext.logEvent(
            TelemetryEvent.FetchPurhaseOrderDataForSupplierSuccess,
            {
              headers,
              url,
            }
          );
          // console.log('Calling purchase order data success');

          const { data } = response;

          let netPoAmount: number = 0;
          // TODO: Replace with the new mode when ready.
          const purcahseOrderData = data.map((x) => {
            netPoAmount += x.amount;
            const poData: IPurchaseOrder = {
              poNumber: x.purchaseOrderNumber,
              poTitle: x.title,
              poCreatedDate: x.createdDate,
              fiscalYearNumber: x.fiscalYearNumber,
              currencyCode: x.currencyCode,
              contracts:
                x.contracts === null || x.contracts === undefined
                  ? []
                  : x.contracts.map((z) => ({
                      contractNumber: z.contractID, // TODO: See if there will be an issue here as only contract Id is mapped.
                    })),
              poAmount: !isNullOrUndefined(x.amount) ? x.amount.toFixed(2).replace(
                /(\d)(?=(\d\d\d)+(?!\d))/g,
                '$1,'
              ) : x.amount,
              invoices: [],
              // TODO map invoices to data from api once the data is ready.
              // invoices:  x.Invoices.map(y => ({
              //   invoiceNumber: y.InvoiceNumber,
              //   invoiceCreatedDate: y.InvoiceCreatedDate,
              //   invoiceAmount: y.InvoiceAmount,
              //   invoiceStatus: y.InvoiceStatus,
              //   currencyCode: y.CurrencyCode,
              // })),
              // poState:x.POState,
              poState: x.status,
              poType: x.associatedRequisitionId == null ? 'Master' : 'Extended', // TODO: See if this logic works.
              aravoEngagementId: x.aravoEngagementId,
              parentPurchaseOrder: x.parentPurchaseOrder,
            };

            return poData;
          });

          // console.log('PO Data Populated', purcahseOrderData);
          // TODO: calculated the net PO amount/check if this is sent by api.
          if (
            (getState().selectedSupplier.basicInfo !== null &&
              getState().selectedSupplier.basicInfo.supplierNumber ===
                supplier.supplierNumber) ||
            getState().selectedSupplier.basicInfo === null
          ) {
            dispatch(setNetPOAmountForSupplier(netPoAmount));
            dispatch(setPurchaseOrdersForSupplier(purcahseOrderData));
          }

          dispatch(requestPurchaseOrdersForSupplierComplete());
        })
        .catch((error) => {
          // console.log('Calling PO data failed', error);

          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchPurhaseOrderDataForSupplierFailure,
            undefined,
            {
              headers,
              url,
            }
          );

          if (error.response && error.response.status === 404) {
            // TODO: See if you want to do any alerting here.
          }
          dispatch(requestPurchaseOrdersForSupplierComplete());
        });
    } else {
      return delay(2000).then(() => {
        dispatch(setNetPOAmountForSupplier(12020));
        dispatch(setPurchaseOrdersForSupplier(purchaseOrderMockData));
        dispatch(requestPurchaseOrdersForSupplierComplete());
      });
    }
  };
};

// This action gets the contracts for the given supplier.
export const getAravoToContractMapForSupplier = (
  supplier: ISupplier,
  fiscalYearNumber: string = ''
) => {
  return (dispatch, getState) => {
    dispatch(requestAravoToContractMappingForSupplier());
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers
      const headers: any = {
        SubCorrelationKey: uuidv4(),
        'X-CorrelationId': uuidv4(),
      };

      let url: string;

      // Defining the url.
      if (fiscalYearNumber === '') {
        url = `${Routes.FetchAravoToContractMapForSupplier}?supplierId=${supplier.supplierId}`;
      } else {
        url = `${Routes.FetchAravoToContractMapForSupplier}?fiscalYearNumber=${fiscalYearNumber}&supplierId=${supplier.supplierId}`;
      }

      // Logging telemetry
      telemetryContext.logEvent(
        TelemetryEvent.FetchAravoToContractMapForSupplierBegin,
        {
          headers,
          url,
        }
      );

      // console.log('Calling Aravo data start');

      return gtoMsalApiFetch(gtoApiInstance.get, url, { headers })
        .then((response: any) => {
          // Logging success
          telemetryContext.logEvent(
            TelemetryEvent.FetchAravoToContractMapForSupplierSuccess,
            {
              headers,
              url,
            }
          );
          // console.log('Calling aravo data success');

          let aravoToContractMap: IAravoToContractMap[];
          const { data } = response;
          if (data === null) {
            aravoToContractMap = [];
          } else {
            aravoToContractMap = data.map((x) => {
              const map: IAravoToContractMap = {
                SupplierId: x.supplierId,
                FiscalYearNumber: x.fiscalYearNumber,
                ContractIds: x.contractIds,
                AravoEngagementId: x.aravoEngagementId,
                SharedContractIds: null,
              };

              return map;
            });
          }

          // console.log('Aravo Populated', aravoToContractMap);

          if (
            (getState().selectedSupplier.basicInfo !== null &&
              getState().selectedSupplier.basicInfo.supplierNumber ===
                supplier.supplierNumber) ||
            getState().selectedSupplier.basicInfo === null
          ) {
            dispatch(setAravoToContractMappingForSupplier(aravoToContractMap));
          }

          dispatch(requestAravoToContractMappingForSupplierComplete());
        })
        .catch((error) => {
          // console.log('Calling Aravo data failed', error);

          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchAravoToContractMapForSupplierFailure,
            undefined,
            {
              headers,
              url,
            }
          );

          if (error.response && error.response.status === 404) {
            // TODO: See if you want to do any alerting here.
          }
          dispatch(requestAravoToContractMappingForSupplierComplete());
        });
    } else {
      return delay(2000).then(() => {
        dispatch(
          setAravoToContractMappingForSupplier(aravoToContractMappingMockData)
        );
        dispatch(requestAravoToContractMappingForSupplierComplete());
      });
    }
  };
};

// This action gets the judgements for the given supplier.
export const getJudgementsForSupplier = (
  supplier: ISupplier,
  fiscalYearNumber: string = ''
) => {
  return (dispatch, getState) => {
    dispatch(requestJudgementsForSupplier());
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers
      const headers: any = {
        SubCorrelationKey: uuidv4(),
        'X-CorrelationId': uuidv4(),
      };

      let url: string;

      // Defining the url. TODO: See if this works properly and if the api accepts calls without a fiscal year number.
      if (fiscalYearNumber === '') {
        url = `${Routes.FetchJudgementsForSupplier}?SupplierId=${supplier.supplierId}`;
      } else {
        url = `${Routes.FetchJudgementsForSupplier}?SupplierId=${supplier.supplierId}&FiscalYear=${fiscalYearNumber}`;
      }

      // Logging telemetry
      telemetryContext.logEvent(
        TelemetryEvent.FetchJudgementsForSupplierBegin,
        {
          headers,
          url,
        }
      );

      // console.log('Calling Judgements data start');

      return gtoMsalApiFetch(gtoApiInstance.get, url, { headers })
        .then((response: any) => {
          // Logging success
          telemetryContext.logEvent(
            TelemetryEvent.FetchJudgementsForSupplierSuccess,
            {
              headers,
              url,
            }
          );
          // console.log('Calling Judgements success');

          const { data } = response;
          // console.log('Judgement', data);

          const judgements: IJudgement[] = data.map((x) => {
            const map: IJudgement = {
              JudgementAmountLC: x.judgementAmountLC,
              IsBudgetJudgement: x.isBudgetJudgement,
              Comments: x.comments,
              CreatedDate: x.createdDate.toString(), // TODO : Chaneg once API give the Date
            };

            return map;
          });
          // console.log('Judgement2', judgements);

          // TODO: calculated the net PO amount/check if this is sent by api.
          if (
            (getState().selectedSupplier.basicInfo !== null &&
              getState().selectedSupplier.basicInfo.supplierNumber ===
                supplier.supplierNumber) ||
            getState().selectedSupplier.basicInfo === null
          ) {
            dispatch(setJudgementsForSupplier(judgements));
          }

          dispatch(requestJudgementsForSupplierComplete());
        })
        .catch((error) => {
          // console.log('Calling Judgements data failed', error);

          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchJudgementsForSupplierFailure,
            undefined,
            {
              headers,
              url,
            }
          );

          if (error.response && error.response.status === 404) {
            // TODO: See if you want to do any alerting here.
          }
          dispatch(requestJudgementsForSupplierComplete());
        });
    } else {
      return delay(2000).then(() => {
        dispatch(
          setJudgementsForSupplier([]) // TODO : Define mock data for Judgements
        );
        dispatch(requestJudgementsForSupplierComplete());
      });
    }
  };
};

// This action creator loads the contracts data for the given supplier.
export const getContractsForSupplier = (supplier: ISupplier) => {
  return (dispatch, getState) => {
    dispatch(requestContractsForSupplier());
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      const headers: any = {
        SubCorrelationKey: uuidv4(),
        'X-CorrelationId': uuidv4(),
      };
      // Defining the url
      const url: string = `${Routes.FetchActiveContractsForSupplier}?supplierNumber=${supplier.supplierNumber}&companyCode=${supplier.companyCode}`;

      // Logging telemetry
      telemetryContext.logEvent(
        TelemetryEvent.FetchActiveContractsForSupplierBegin,
        {
          url: url,
        }
      );

      // fetch existing contract data from api for the Existing Contract Part on Contract page
      return gtoMsalApiFetch(gtoApiInstance.get, url, { headers })
        .then((response: any) => {
          // Logging success
          telemetryContext.logEvent(
            TelemetryEvent.FetchActiveContractsForSupplierSuccess,
            {
              url: url,
            }
          );

          let contracts: IContract[] = [];
          // TODO: See if this mapping is working correctly.
          for (let i = 0; i < response.data.length; i++) {
            // initialize the contract object
            let contract: IContract = {
              contractNumber: 0,
              aravoEngagementId: '',
              contractID: 0,
              supplierName: '',
              supplierNumber: 0,
              contractDescription: '',
              companyCode: 0,
              typeOfContract: 0,
              expirationDate: '',
              expDateValue: 0,
              IsSharedContract: false,
            };

            // actually set the values
            contract.contractID = response.data[i].supplierContractId;
            contract.contractNumber = response.data[i].contractId;
            contract.supplierName = response.data[i].supplierName;
            contract.contractDescription = response.data[i].contractDescription;
            contract.companyCode = response.data[i].companyCode;
            contract.IsSharedContract = response.data[i].isSharedContract;
            contract.typeOfContract =
              response.data[i].contractID === null ||
              response.data[i].expirationDate === '0001-01-01T00:00:00'
                ? TypeOfContract.shell
                : TypeOfContract.master;

            // setting the expiration date
            let expDate = response.data[i].expirationDate;

            // '0001-01-01T00:00:00' is the min value returned by API
            // - meaning that the value was null in the DB
            if (expDate !== '0001-01-01T00:00:00') {
              let dateObj = new Date(response.data[i].expirationDate);

              contract.expirationDate = dateObj.toLocaleDateString();
              contract.expDateValue = dateObj.valueOf();
            }

            contracts.push(contract);
          }

          // console.log('Contracts for Supplier', contracts);
          if (
            (getState().selectedSupplier.basicInfo !== null &&
              getState().selectedSupplier.basicInfo.supplierNumber ===
                supplier.supplierNumber) ||
            getState().selectedSupplier.basicInfo === null
          ) {
            dispatch(setContractsForSupplier(contracts));
          }

          dispatch(requestContractsForSupplierComplete());
        })
        .catch((error) => {
          // console.log('Calling Contracts data failed', error);

          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchActiveContractsForSupplierFailure,
            undefined,
            {
              headers,
              url,
            }
          );

          if (error.response && error.response.status === 404) {
            // TODO: See if you want to do any alerting here.
          }
          dispatch(requestContractsForSupplierComplete());
        });
    } else {
      return delay(2000).then(() => {
        dispatch(setContractsForSupplier(existingContractsMockData));
        dispatch(requestContractsForSupplierComplete());
      });
    }
  };
};

// This action posts aravo id mappings to the api.
export const postAravoToContractMapForSupplier = (
  supplier: ISupplier,
  aravoToContractObj: IAravoToContractMap
) => {
  return (dispatch) => {
    // dispatch(postAravoForSupplier()); //TODO: See if loading for posting an aravo id is required.
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers
      const headers: any = {
        SubCorrelationKey: uuidv4(),
        'X-CorrelationId': uuidv4(),
      };

      let url: string = `${Routes.PostAravoEngagementId}`;

      // Logging telemetry
      telemetryContext.logEvent(TelemetryEvent.PostAravoEngagementIdBegin, {
        body: aravoToContractObj,
        headers,
        url,
      });

      return gtoMsalApiFetch(gtoApiInstance, url, {
        data: aravoToContractObj,
        headers,
        method: 'post',
      })
        .then((response: any) => {
          // Logging success
          telemetryContext.logEvent(
            TelemetryEvent.PostAravoEngagementIdSuccess,
            {
              headers,
              url,
            }
          );

          // Alert that the Aravo id has been successfully submitted.
          toast.success(
            'Aravo Id and Contract Mapping was successfully submitted.',
            {
              className: css({
                background: '#107c10 !important',
              }),
            }
          );

          // console.log('AravoId Response', response);

          dispatch(
            getAravoToContractMapForSupplier(
              supplier,
              aravoToContractObj.FiscalYearNumber.toString()
            )
          );
          // dispatch(postAravoForSupplierComplete());
        })
        .catch((error) => {
          // console.log('Posting Aravo data failed', error);

          telemetryContext.logException(
            error.toString(),
            TelemetryException.PostAravoEngagementIdFailure,
            undefined,
            {
              headers,
              url,
            }
          );

          if (
            error.response &&
            (error.response.status === 404 || error.response.status === 400)
          ) {
            // TODO: See if you want to do any alerting here.
            toast.error('Unable to post this Aravo Id:' + error.message, {
              className: css({
                background: '#a80000 !important',
              }),
            });
            dispatch(requestAravoToContractMappingForSupplierComplete());
          }
          // dispatch(postAravoForSupplierComplete());
        });
    } else {
      return delay(2000).then(() => {
        // console.log('supplier', supplier);
        // console.log('aravoToContractObj', aravoToContractObj);
        dispatch(
          getAravoToContractMapForSupplier(
            supplier,
            aravoToContractObj.FiscalYearNumber.toString()
          )
        );
        toast.success(
          'Aravo Id and Contract Mapping was successfully submitted.',
          {
            className: css({
              background: '#107c10 !important',
            }),
          }
        );
        // dispatch(postAravoForSupplierComplete());
      });
    }
  };
};

// This action posts judgements  to the api.
export const postJudgementsForSupplier = (
  supplier: ISupplier,
  fiscalYear: number,
  judgementObj: IJudgement
) => {
  return (dispatch) => {
    // dispatch(postAravoForSupplier()); //TODO: See if loading for posting an aravo id is required.
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers
      const headers: any = {
        SubCorrelationKey: uuidv4(),
        'X-CorrelationId': uuidv4(),
      };

      let url: string = `${Routes.PostJudgementsForSupplier}?supplierId=${
        supplier.supplierId
      }&fiscalYear=${fiscalYear.toString()}&amountLC=${
        judgementObj.JudgementAmountLC
      }&isBudgetJudgement=${judgementObj.IsBudgetJudgement}&comments=${
        judgementObj.Comments
      }`;

      // Logging telemetry
      telemetryContext.logEvent(TelemetryEvent.PostJudgementsBegin, {
        headers,
        url,
      });

      return gtoMsalApiFetch(gtoApiInstance, url, {
        headers,
        method: 'post',
      })
        .then((response: any) => {
          // Logging success
          telemetryContext.logEvent(TelemetryEvent.PostJudgementsSuccess, {
            headers,
            url,
          });

          // Alert that the judgement has been successfully submitted.
          toast.success('Judgement was successfully submitted.', {
            className: css({
              background: '#107c10 !important',
            }),
          });

          if (fiscalYear === null) {
            dispatch(getJudgementsForSupplier(supplier));
          } else {
            dispatch(getJudgementsForSupplier(supplier, fiscalYear.toString()));
          }

          dispatch(getJudgementsForSupplier(supplier, fiscalYear.toString()));
          // dispatch(postAravoForSupplierComplete());
        })
        .catch((error) => {
          telemetryContext.logException(
            error.toString(),
            TelemetryException.PostJudgementsFailure,
            undefined,
            {
              headers,
              url,
            }
          );

          if (error.response && error.response.status === 404) {
            // TODO: See if you want to do any alerting here.
            toast.error('Unable to post judgement:' + error.message, {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }
          // dispatch(postAravoForSupplierComplete());
        });
    } else {
      return delay(2000).then(() => {
        dispatch(getJudgementsForSupplier(supplier, fiscalYear.toString()));
        toast.success('Judgement was successfully submitted.', {
          className: css({
            background: '#107c10 !important',
          }),
        });
        // dispatch(postAravoForSupplierComplete());
      });
    }
  };
};

// This action posts purchase orders for supplier
export const postPurchaseOrderForSupplier = (
  purchaseOrder: IPurchaseOrderNew
) => {
  return (dispatch) => {
    // dispatch(postAravoForSupplier()); //TODO: See if loading for posting an aravo id is required.
    if (appConfig.UseMockData === 'false') {
      dispatch(setIsPOCreated(false));
      // Creating headers
      const headers: any = {
        SubCorrelationKey: uuidv4(),
        'X-CorrelationId': uuidv4(),
      };

      let url: string = `${Routes.CreatePurchaseOrder}`;

      // Logging telemetry
      telemetryContext.logEvent(TelemetryEvent.PostPurchaseOrderBegin, {
        headers,
        url,
      });

      // console.log('PoText');
      return gtoMsalApiFetch(gtoApiInstance, url, {
        headers,
        method: 'post',
        data: purchaseOrder,
      })
        .then((response: any) => {
          // Logging success
          telemetryContext.logEvent(TelemetryEvent.PostPurchaseOrderSuccess, {
            headers,
            url,
          });

          // If the status code is negative show the error and in the else condition redirect to the myorder Page with appropriate PO number
          if (response.data['StatusCode'] < 0) {
            dispatch(setPONotCreated(false));
            toast.error(
              `Purchase Order could not be created. ${response.data['StatusMessage']}`,
              {
                className: css({
                  background: '#a80000 !important',
                }),
              }
            );
          } else {
            dispatch(setPurchaseOrderCreated(response.data['StatusCode']));
            dispatch(setIsPOCreated(true));
          }
          // console.log('PO DATA', purchaseOrder);
          // let json = JSON.stringify(purchaseOrder);
          // console.log('JSON',json);
        })
        .catch((error) => {
          telemetryContext.logException(
            error.toString(),
            TelemetryException.PostJudgementsFailure,
            undefined,
            {
              headers,
              url,
            }
          );

          if (error.response && error.response.status === 404) {
            // TODO: See if you want to do any alerting here.
            toast.error('Unable to post judgement:' + error.message, {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }
          // dispatch(postAravoForSupplierComplete());
        });
    } else {
      return delay(2000).then(() => {
        // dispatch(getJudgementsForSupplier(supplier, fiscalYear.toString()));
        //  toast.success('Judgement was successfully submitted.', {
        //   className: css({
        //     background: '#107c10 !important',
        //   }),
        // });
        // dispatch(postAravoForSupplierComplete());
      });
    }
  };
};

// This action delete Aravo Id for the Supplier
export const deleteAravoIdForSupplier = (
  supplier: ISupplier,
  aravoEngagementId: string
) => {
  return (dispatch, getState) => {
    if (appConfig.UseMockData === 'false') {
      // Creating headers
      const headers: any = {
        SubCorrelationKey: uuidv4(),
        'X-CorrelationId': uuidv4(),
      };

      let url: string = `${Routes.DeleteAravoIdForSupplier}?aravoEngagementId=${aravoEngagementId}&supplierId=${supplier.supplierId}`;

      // Logging telemetry
      telemetryContext.logEvent(TelemetryEvent.DeleteAravoIdForSupplierBegin, {
        aravoEngagementId,
        supplierId: supplier.supplierId,
        headers,
        url,
      });

      return gtoMsalApiFetch(gtoApiInstance, url, {
        headers,
        method: 'delete',
      })
        .then((response: any) => {
          // Logging success
          telemetryContext.logEvent(
            TelemetryEvent.DeleteAravoIdForSupplierSuccess,
            {
              aravoEngagementId,
              supplierId: supplier.supplierId,
              headers,
              url,
            }
          );

          // Alert that the Aravo id has been successfully submitted.
          toast.success('Aravo Id has been successfully deleted.', {
            className: css({
              background: '#107c10 !important',
            }),
          });

          // console.log('AravoId Response', response);

          dispatch(
            getAravoToContractMapForSupplier(
              supplier,
              getState().selectedSupplier.selectedFiscalYear.toString()
            )
          );
          // dispatch(postAravoForSupplierComplete());
        })
        .catch((error) => {
          // console.log('Deleting Aravo data failed', error);

          telemetryContext.logException(
            error.toString(),
            TelemetryException.DeleteAravoIdForSupplierFailure,
            undefined,
            {
              aravoEngagementId,
              supplierId: supplier.supplierId,
              headers,
              url,
            }
          );

          if (error.response && error.response.status === 404) {
            // TODO: See if you want to do any alerting here.
            toast.error('Unable to delete this Aravo Id:' + error.message, {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }
          // dispatch(postAravoForSupplierComplete());
        });
    } else {
      // console.log('deleting');
      return delay(2000).then(() => {
        toast.success(
          'Aravo Id and Contract Mapping was successfully deleted.',
          {
            className: css({
              background: '#107c10 !important',
            }),
          }
        );
        dispatch(
          getAravoToContractMapForSupplier(
            supplier,
            getState().selectedSupplier.selectedFiscalYear.toString()
          )
        );

        // dispatch(postAravoForSupplierComplete());
      });
    }
  };
};

// This action creator loads the budget data for the given supplier.
export const getSuppliersSetDefaultAndGetBudgetAndPODataForDefault = () => {
  return (dispatch, getState) => {
    return dispatch(getSuppliers()).then(() => {
      const defaultSupplier: ISupplier = getState().selectedSupplier.basicInfo;

      // TODO Add PO Data here. This may need refactoring.
      dispatch(getFiscalYearsForSupplier());
      dispatch(getBudgetForSupplier(defaultSupplier)); // TODO: Not returning a promise in this function as I am dispatching multiple parallel actions.
      dispatch(getPurchaseOrdersForSupplier(defaultSupplier));
      dispatch(getContractsForSupplier(defaultSupplier));
      // dispatch(getAravoToContractMapForSupplier(defaultSupplier));
    });
  };
};

// This action creator gets the aravo to contract mappings and sets the default. Based on the default, it filters out the supplier engagement list and generates the line items to display.
export const getAravoToContractMappingSetDefaultAravoAndSetLineItemsForDefault = () => {
  return (dispatch, getState) => {
    dispatch(setSelectedAravoId(''));
    // console.log('test If call is happening.');
    return dispatch(
      getAravoToContractMapForSupplier(
        getState().selectedSupplier.basicInfo,
        getState().selectedSupplier.selectedFiscalYear
      )
    ).then(() => {
      // const selectedAravoId:string  = getState().selectedSupplier.aravoToContractMapping;

      // console.log('trying to get line items');
      dispatch(
        getPoLineItemsForSupplier(
          getState().selectedSupplier.basicInfo,
          getState().selectedSupplier.selectedFiscalYear
        )
      );

      const { aravoToContractMapping } = getState().selectedSupplier;
      if (aravoToContractMapping.length > 0) {
        // Setting the selected aravo id to the first one in the list.

        const selectedAravo: IAravoToContractMap = aravoToContractMapping[0];
        dispatch(setSelectedAravoId(selectedAravo.AravoEngagementId));

        // TODO: Filter the line items here.
        // const filteredSupplierEngagements = getState().selectedSupplier.supplierEngagements.filter(
        //   x => selectedAravo.ContractIds.includes(x.contractID)
        // );

        // setting the selected line item key.
        dispatch(setSelectedLineItemKey('none'));
        // Setting the line items for default method none. TODO: See if you need to set the default mode to none as well and read it from redux.
        // dispatch(
        //   setLineItems(
        //     createLineItemsHelper(filteredSupplierEngagements, 'none')
        //   )
        // );
      } else {
        dispatch(setSelectedAravoId(''));
      }
    });
  };
};
