import * as actionTypes from '../actions/actionTypes';
import { IDropdownOption } from 'office-ui-fabric-react';
import { any } from 'glamor';

// Initialiing the suppliers state to an empty array.
const initialState = {
  invoiceReportIntialLoading: true,
  isFetchingInvoiceReport: false,
  isFetchingInvoiceReportDetails: false,
  invoiceFiscalYearFilterOptions: [], 
  invoiceSupplierFilterOptions: [],
  invoiceQuarterFilterOptions: [],  
  invoiceSourceFilterOptions: [],  
  invoiceReportSummary: [],
  invoiceReportImportDetails: [],
  invoiceReportDetails: [],
  invoiceReportSelectedFilters: String,
  invoiceTotalSpendInDollar: any,
  invoiceTotalDiscountAmountInUSD: any,
  budgetReportInitialLoading: true,  
  isFetchingBudgetReport: false,
  isFetchingBudgetReportDetails: false,
  budgetSupplierFilterOptions: [],
  budgetQuarterFilterOptions: [],  
  budgetTypeFilterOptions: [],    
  budgetReportSummary: [],
  budgetReportDetails: [],  
  budgetReportSelectedFilters: String,
  budgetTotalSpendInDollar: any,
  budgetTotalDiscountAmountInUSD: any,
  budgetGrossAmount: any  
};

function CheckAndUpdateReportFilterOptions(filterOptions: any[], currentOption : IDropdownOption){  
  var options= filterOptions.map(option => {
    if (option.key === currentOption.key) {
      return {
        ...option,
        selected: currentOption.selected,
      };
    } else return option;
  });
  return options;
}

const reducer = (state = initialState, action) => {  
  switch (action.type) {    
    case actionTypes.SET_BOBREPORT_FILTEROPTIONS:
        return Object.assign({}, state, {   
          invoiceSupplierFilterOptions: action.masterData.SupplierFilterOptions,
          invoiceFiscalYearFilterOptions: action.masterData.FiscalYearFilterOptions,
          invoiceQuarterFilterOptions: action.masterData.QuarterFilterOptions,          
          invoiceSourceFilterOptions: action.masterData.InvoiceSourceFilterOptions,
          budgetTypeFilterOptions: action.masterData.BudgetTypeFilterOptions,
          budgetSupplierFilterOptions: action.masterData.SupplierFilterOptions,
          budgetQuarterFilterOptions: action.masterData.QuarterFilterOptions          
        });
    case actionTypes.SET_INVOICEREPORT_SELECTEDSUPPLIERS:    
        let suppliersFilterOptions = CheckAndUpdateReportFilterOptions(state.invoiceSupplierFilterOptions, action.data);    
        return Object.assign({}, state, {
          invoiceSupplierFilterOptions: suppliersFilterOptions
        });
    case actionTypes.SET_INVOICEREPORT_SELECTEDQUARTERS:
        let quartersFilterOptions = CheckAndUpdateReportFilterOptions(state.invoiceQuarterFilterOptions, action.data);
        return Object.assign({}, state, {
          invoiceQuarterFilterOptions: quartersFilterOptions            
        });
    case actionTypes.SET_INVOICEREPORT_SELECTEDSOURCES:
        let sourcesFilterOptions = CheckAndUpdateReportFilterOptions(state.invoiceSourceFilterOptions, action.data);
        return Object.assign({}, state, {
          invoiceSourceFilterOptions: sourcesFilterOptions            
        });
    case actionTypes.SET_INVOICEREPORT_SELECTEDFISCALYEARS:
        let fiscalYearFilterOptions = CheckAndUpdateReportFilterOptions(state.invoiceFiscalYearFilterOptions, action.data);
        return Object.assign({}, state, {
          invoiceFiscalYearFilterOptions: fiscalYearFilterOptions            
        });
    case actionTypes.SET_INVOICEREPORT_SUMMARYDATA:
        return Object.assign({}, state, {
          invoiceReportSummary: action.data.invoiceReportSummary,
          invoiceTotalSpendInDollar: action.data.invoiceTotalSpendInDollar,
          invoiceTotalDiscountAmountInUSD: action.data.invoiceTotalDiscountAmountInUSD,
          invoiceReportImportDetails: action.data.invoiceReportImportDetails,
          invoiceReportIntialLoading: false
        });
    case actionTypes.REQUEST_INVOICEREPORT_SUMMARY:
      return Object.assign({}, state, { isFetchingInvoiceReport: true });
    case actionTypes.REQUEST_INVOICEREPORT_SUMMARYCOMPLTE:
      return Object.assign({}, state, { isFetchingInvoiceReport: false });
    case actionTypes.SET_INVOICEREPORT_SELCTEDFILTERS:
      return Object.assign({}, state, { invoiceReportSelectedFilters: action.selectedFilters  });
    case actionTypes.SET_INVOICEREPORT_DETAILS:
        return Object.assign({}, state, { 
          invoiceReportDetails: action.data.invoiceReportDetails
        });
    case actionTypes.REQUEST_INVOICEREPORT_DETAILS:
      return Object.assign({}, state, { isFetchingInvoiceReportDetails: true });
    case actionTypes.REQUEST_INVOICEREPORT_DETAILSCOMPLTE:
      return Object.assign({}, state, { isFetchingInvoiceReportDetails: false });
    case actionTypes.SET_BUDGETREPORT_SELECTEDSUPPLIERS:
        let budgetSupplierFilterOptions = CheckAndUpdateReportFilterOptions(state.budgetSupplierFilterOptions, action.data);
        return Object.assign({}, state, {
          budgetSupplierFilterOptions: budgetSupplierFilterOptions
        });
    case actionTypes.SET_BUDGETREPORT_SELECTEDQUARTERS:
        let budgetQuartersFilterOptions = CheckAndUpdateReportFilterOptions(state.budgetQuarterFilterOptions, action.data);
        return Object.assign({}, state, {
          budgetQuarterFilterOptions: budgetQuartersFilterOptions
        });
    case actionTypes.SET_BUDGETEREPORT_SELECTEDBUDGETTYPES:
        let budgetTypesFilterOptions = CheckAndUpdateReportFilterOptions(state.budgetTypeFilterOptions, action.data);
        return Object.assign({}, state, {
          budgetTypeFilterOptions: budgetTypesFilterOptions
        });
    case actionTypes.SET_BudgetREPORT_SUMMARYDATA:      
        return Object.assign({}, state, {
          budgetReportSummary: action.data.budgetReportSummary,
          budgetTotalSpendInDollar: action.data.budgetTotalSpendInDollar,
          budgetTotalDiscountAmountInUSD: action.data.budgetTotalDiscountAmountInUSD,
          budgetGrossAmount: action.data.budgetGrossAmount,
          budgetReportInitialLoading: false
        });
    case actionTypes.REQUEST_BUDGETREPORT_SUMMARY:
      return Object.assign({}, state, { isFetchingBudgetReport: true });
    case actionTypes.REQUEST_BUDGETREPORT_SUMMARYCOMPLTE:
      return Object.assign({}, state, { isFetchingBudgetReport: false });
    case actionTypes.SET_BUDGETREPORT_SELCTEDFILTERS:
      return Object.assign({}, state, { budgetReportSelectedFilters: action.selectedFilters });
    case actionTypes.SET_BUDGETREPORT_DETAILS:
        return Object.assign({}, state, { 
          budgetReportDetails: action.data.BudgetReportDetails
        });
    case actionTypes.REQUEST_BUDGETREPORT_DETAILS:
      return Object.assign({}, state, { isFetchingBudgetReportDetails: true });
    case actionTypes.REQUEST_BUDGETREPORT_DETAILSCOMPLTE:
      return Object.assign({}, state, { isFetchingBudgetReportDetails: false });
    default:
        return state;
  }
};

export default reducer;
