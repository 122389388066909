import * as React from 'react';
import { Image } from 'office-ui-fabric-react';
import ROM_BAonly from "../../assets/images/ROM_BAonly.jpg";
import ROM_AdminView from "../../assets/images/ROM_AdminView.jpg";
import ROM_NonAdmin1 from "../../assets/images/ROM_NonAdmin1.jpg";
import ROM_NonAdmin1_1 from "../../assets/images/ROM_NonAdmin1.1.jpg";
import ROM_NonAdmin1_2 from "../../assets/images/ROM_NonAdmin1.2.jpg";



class RollOverDoc extends React.Component<{}> {

    
    render() {
        return (
            <div>
                <h2 style={{display: 'block', fontSize: '1.3em', margin: '0.83em 0 0.83em 0', fontWeight: 'bold'}}>Roll Over Management</h2>
                <h3>Table of Contents</h3>
                <a href="#ROM1"><b>Roll Over Management - BAs Only</b></a><br />
                <a href="#ROM2"><b>Admin View</b></a><br />
                <a href="#ROM3"><b>Non Admin/BA users view</b></a><br />
                <h3 id="ROM1" tabIndex={0}>Roll Over Management - BAs Only</h3>
                <p>Managing current engagements and supplier engagements for the succeeding fiscal year is accomplished using the Roll over menu item from within the Engagement Management tile.</p>
                <Image src={ROM_BAonly} style={{ height: 200, width: 300 }} alt="ROM_BAonly Image" />
                <p>Using the Roll over interface, a user can paste (Ctrl-V) a list of Engagements or Supplier Engagement IDs into the upper left pane.
                                             The IDs are validated and indications are given for invalid IDs or those already present in the next Fiscal Year. </p>
                <h3 id="ROM2" tabIndex={0}>Admin View :</h3>
                <Image src={ROM_AdminView} style={{ height: 350, width: 850 }} alt="ROM_AdminView Image" />
                <h3 id="ROM3" tabIndex={0}>Non Admin/BA users view :</h3>
                <Image src={ROM_NonAdmin1} style={{ height: 350, width: 850 }} alt="ROM_NonAdmin1 Image" />
                <Image src={ROM_NonAdmin1_1} style={{ height: 350, width: 850 }} alt="ROM_NonAdmin1.1 Image" />
                <p>The interface defaults to the Supplier Engagement setting; when this is used the parent Engagement is also rolled over.
                        Conversely, if rolling over only Engagement IDs the child Supplier Engagements are NOT rolled over.</p>
                <Image src={ROM_NonAdmin1_2} style={{ height: 350, width: 850 }} alt="ROM_NonAdmin1.2 Image" />
                <p>Any records erroneously rolled over can be rolled back by an admin using the admin function.</p>
                <p>Note – Records rolled over to the succeeding Fiscal Year will have the “Sync to Year” field populated for the current year, reflecting they have been rolled over.
                            To maintain synchronization, any updates to attribute data should be made in the earliest Fiscal Year in order to flow through to the new Fiscal Year.</p>
                <p>Updates to attributes directly to the succeeding Fiscal Year will break the synchronization to the previous year.
                           This may or may not be desired based on business need; please use caution concerning updates.</p> <br /><br />
                <br />
            </div>
        );
    }
}
export default RollOverDoc;