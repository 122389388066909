import * as React from 'react';
import HelmetTab from "../../shared/Helmet/Helmet";



class Pricing extends React.Component {

    render(){
        return(<div>
                  <HelmetTab title={'Pricing'} />

            <p>pricing</p>
            </div>
        )
    }
}

export default Pricing
