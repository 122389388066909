import { toast } from "react-toastify";
import gtoApiNewInstance from "../http/GtoApiNew.axios";
import { IUser, Routes, TelemetryException, IRolloverResponse } from "../models";
import telemetryContext from "./TelemetryServices";
import { css } from 'glamor';
import appConfig from '../../assets/configuration/config';
import { delay } from 'q';
import { v4 as uuidv4 } from 'uuid';
import { userMockData } from "../mock/UserMockData";
import { removeDuplicates } from "../common/common-util";
import { IFiscalYears } from "../models/IFiscalYears.model";
import { IInternalOrderExport, IInternalOrders } from "../models/IInternalOrders";
import { IInternalOrderApproveMockData, IInternalOrderMockData } from "../mock/InternalOrderMockData";
import gtoNewApiInstance, {
    gtoMsalApiFetch,
  } from '../../shared/http/GtoApiNew.axios';
class GtoService {
    /**
    * Method to Get validateAutomatedSupplierEngagementRollover using API call.
    * @method
    * @returns {IRolloverResponse[]} The result of the call.
    */
    public async checkRolloverStatus(fiscalYearNumber: number, searchByType: string, searchByIds: string): Promise<IRolloverResponse[]> {
        if (appConfig.UseMockData === 'false') {
            var headers = this.getHeaderData();
            var params = new URLSearchParams();
            var url = "";
            var payload: any;
            if (searchByType === "E" || searchByType === "SE") {
                url = Routes.checkEngagementAndSupplierEngagementRollover;
                payload = removeDuplicates(searchByIds.split(/[\n,]/)).toString();
                params.append("fiscalYearNumber", fiscalYearNumber.toString());
            }
            else {
                url = Routes.checkAutomatedEngagementAndSupplierEngagementRollover;
                params.append("fiscalYearNumber", fiscalYearNumber.toString());
                params.append("checkMode", searchByType);
                payload = removeDuplicates(searchByIds.split(/[\n,]/)).toString();
            }
            return await gtoApiNewInstance.post<IRolloverResponse[]>(
                `${url}`,
                (searchByType === "E" || searchByType === "SE" ||searchByType === "AE" || searchByType === "ASE")  ? "\"" + payload + "\"" : payload,
                { params, headers },
            ).then((response: any) => {
                return response.data;
            }).catch((error) => {
                this.handleErrorResponse(error, TelemetryException.ValidateAutomatedSeidRolloverFailure, headers, url)
                return null;
            });;
        }
        else {
            //Setting mock data.
            return delay(2000).then(() => {
                return null;
            });
        }
    }

    public async updateBulkRolloverData(fiscalYearNumber: number, searchByType: string, selectedIds: string, isRemoveRecords: boolean = false): Promise<IRolloverResponse[]> {
        if (appConfig.UseMockData === 'false') {
            var headers = this.getHeaderData();
            var params = new URLSearchParams();
            var url = "";
            var payload: any;
            if (searchByType === "E" || searchByType === "SE") {
                url = Routes.updateBulkRolloverEngagementAndSupplierEngagement;
                payload = selectedIds.toString();
                params.append("fiscalYearNumber", (fiscalYearNumber - 1).toString());
                params.append("setSyncToYr", isRemoveRecords ? "0" : fiscalYearNumber.toString());
                params.append("setMode", searchByType);
            }
            else {
                url = Routes.checkAutomatedSeidRollover;
                payload = {
                    fiscalYearNumber,
                    supplierEngagementId: selectedIds
                };
            }
            return await gtoApiNewInstance.post<IRolloverResponse[]>(
                `${url}`,
                (searchByType === "E" || searchByType === "SE") ? "\"" + payload + "\"" : payload,
                { params, headers },
            ).then((response: any) => {
                toast.success('Data Rolled-over successfully', {
                    className: css({
                        background: '#107c10 !important',
                    })
                });
                return response.data;
            }).catch((error) => {
                this.handleErrorResponse(error, TelemetryException.ValidateAutomatedSeidRolloverFailure, headers, url)
                return null;
            });;
        }
        else {
            //Setting mock data.
            return delay(2000).then(() => {
                return null;
            });
        }
    }

    /**
    * Method to Get user roles using API call.
    * @method
    * @returns {IUser} The result of the call.
    */
    public async getUserRoles(): Promise<IUser> {
        if (appConfig.UseMockData === 'false') {
            var headers = this.getHeaderData();
            var url = Routes.FetchUserAuth;
            return await gtoApiNewInstance.get<IUser>(
                `${url}`, { headers })
                .then((response: any) => {
                    return response.data
                })
                .catch((error) => {
                    this.handleErrorResponse(error, TelemetryException.UnAuthorizedError, headers, url);
                    return null;
                });;
        }
        else {
            return userMockData;
        }
    }

    /**
    * Method to get Fiscal Year To Rollover using API call.
    * @method
    * @returns {number} The result of the call.
    */
    public async getFiscalYearToRollover(): Promise<number> {
        if (appConfig.UseMockData === 'false') {
            var headers = this.getHeaderData();
            var url = Routes.getFiscalYeartoRollover;
            return await gtoApiNewInstance.get<number>(
                `${url}`, { headers })
                .then((response: any) => {
                    return response.data;
                })
                .catch((error) => {
                    this.handleErrorResponse(error, TelemetryException.GetFiscalYearToRolloverFailure, headers, url)
                    return null;
                });;
        }
        else {
            return new Date().getFullYear();
        }
    }

    /**
    * Method to Get FiscalYears using API call.
    * @method
    * @returns {IFiscalYears} The result of the call.
    */
    public async getFiscalYears(): Promise<IFiscalYears> {
        if (appConfig.UseMockData === 'false') {
            var headers = this.getHeaderData();
            var url = Routes.GetAvailableFiscalYears;
            return await gtoApiNewInstance.get<IFiscalYears>(
                `${url}`, { headers })
                .then((response: any) => {
                    return response.data
                })
                .catch((error) => {
                    this.handleErrorResponse(error, TelemetryException.UnAuthorizedError, headers, url);
                    return null;
                });;
        }
        else {
            return null;//FiscalYearsMockData;
        }
    }

    /**
    * Method to Get InternalOrders using API call.
    * @method
    * @returns {IFiscalYears} The result of the call.
    */
    public async getInternalOrders(currentFiscalYear:number): Promise<IInternalOrders[]> {
        if (appConfig.UseMockData === 'false') {
            var headers = this.getHeaderData();
            var url = Routes.getInternalOrders;
            return await gtoApiNewInstance.get<IInternalOrders[]>(
                `${url}?fiscalYear=${currentFiscalYear}`, { headers })
                .then((response: any) => {
                    var formatData = response.data.map((x) => ({
                        CostCenter:x.CostCenter,
                        EngagementId:x.EngagementId,
                        ExecPl:x.ExecPl,
                        FiscalYearNumber:x.FiscalYearNumber,
                        HasErrors:x.HasErrors,
                        InternalOrder:x.InternalOrder,
                        IsBudgetActive:x.IsBudgetActive.toString(),
                        IsInvoiceActive:x.IsInvoiceActive.toString(),
                        KeyValue:x.KeyValue,
                        LOB:x.LOB,
                        NewCostCenter:x.NewCostCenter,
                        NewInternalOrder:x.NewInternalOrder,
                        Product:x.Product,
                        Status:x.Status,
                        SupplierEngagementId:x.SupplierEngagementId
                     
                      }));
                    return formatData
                })
                .catch((error) => {
                    this.handleErrorResponse(error, TelemetryException.UnAuthorizedError, headers, url);
                    return null;
                });;
        }
        else {
            return IInternalOrderMockData;//FiscalYearsMockData;
        }
    }
    /**
    * Method to Get InternalOrders using API call.
    * @method
    * @returns {IFiscalYears} The result of the call.
    */
    public async getInternalOrdersForApproval(): Promise<IInternalOrders[]> {
        if (appConfig.UseMockData === 'false') {
            var headers = this.getHeaderData();
            var url = Routes.getInternalOrdersForApproval;
            return await gtoApiNewInstance.get<IInternalOrders[]>(
                `${url}`, { headers })
                .then((response: any) => {
                    return response.data
                })
                .catch((error) => {
                    this.handleErrorResponse(error, TelemetryException.UnAuthorizedError, headers, url);
                    return null;
                });
        }
        else {
            return IInternalOrderMockData;//FiscalYearsMockData;
        }
    }

    /**
     * Method to Get InternalOrders using API call.
     * @method
     * @returns {IFiscalYears} The result of the call.
     */
    public async validateInternalOrders(body): Promise<IInternalOrderExport[]> {
        if (appConfig.UseMockData === 'false') {
            var headers = this.getHeaderData();
            var url = Routes.updateInternalOrders;
           
            return await gtoMsalApiFetch(gtoNewApiInstance, url, {
                data: body,
                headers,
                method: 'post',
              })
                .then((response: any) => {
                    return response.data
                })
                .catch((error) => {
                    if(error.response.data &&  error.response.data.length >0 ){
                        error.response.data.map(option => {
                            this.handleErrorResponse(error,option.ErrorMessage, headers, url);    });

                    }else{

                        this.handleErrorResponse(error, TelemetryException.UnAuthorizedError, headers, url);
                    
                    }
                    return null;
                });
        }
        else {
            return IInternalOrderApproveMockData;//FiscalYearsMockData;
        }
    }
    public async approveInternalOrders(body): Promise<IInternalOrderExport[]> {
        if (appConfig.UseMockData === 'false') {
            var headers = this.getHeaderData();
            var url = Routes.approveInternalOrders;
           
            return await gtoMsalApiFetch(gtoNewApiInstance, url, {
                data: body,
                headers,
                method: 'post',
              })
            // return await gtoApiNewInstance.post<IInternalOrderExport[]>(
            //     `${url}` ,{headers })
                .then((response: any) => {
                    this.getInternalOrdersForApproval();
                    return response.data
                })
                .catch((error) => {
                    if(error.response.data &&  error.response.data.length >0 ){
                        error.response.data.map(option => {
                            this.handleErrorResponse(error,option.ErrorMessage, headers, url);    });

                    }else{

                        this.handleErrorResponse(error, TelemetryException.UnAuthorizedError, headers, url);
                    
                    }
                    return null;
                });
        }
        else {
            return IInternalOrderApproveMockData;//FiscalYearsMockData;
        }
    }

    private getHeaderData(): any {
        return {
            'X-CorrelationId': uuidv4(),
            SubCorrelationKey: uuidv4(),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
    }

    private handleErrorResponse(error: any, errorMessage: string, headers: any, url: string) {
        // Log Exception
        telemetryContext.logException(
            error.toString(),
            errorMessage,
            undefined,
            {
                url: url,
                headers,
            }
        );
        // Error handling.
        if (error.response) {
            toast.error(error.response.data &&  error.response.data[0].ErrorMessage, {
                className: css({
                    background: '#a80000 !important',
                }),
            });
        }
    }
}

export const gtoService = new GtoService();