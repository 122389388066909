import { initializeIcons } from '@uifabric/icons';
import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { v4 as uuidv4 } from 'uuid';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { Routes, TelemetryEvent } from './shared/models';
import telemetryContext from './shared/services/TelemetryServices';
import featuresReducer from './store/reducers/features.reducer';
import selectedSupplierReducer from './store/reducers/selectedSupplier.reducer';
import suppliersReducer from './store/reducers/suppliers.reducer'; // TODO: Move all these into a common index file so that we can import everything in one import statement.
import userReducer from './store/reducers/user.reducer';
import existingContractsReducer from './store/reducers/existingContracts.reducer';
import HeadCount from './store/reducers/headCount.reducer';
import InvoiceView from './store/reducers/invoiceView.reducer';
import unAuthUser from './store/reducers/unAuthUser.reducer';
import BOBReport from './store/reducers/bobReport.reducer';
import Unauthorized from './components/unauthorized/Unauthorized';
 import { getAuthProvider } from '../src/shared/auth/msalConfig';
 import { AzureAD, MsalAuthProvider, IAzureADFunctionProps, AuthenticationState } from 'react-aad-msal';
 import { Spinner, SpinnerSize } from 'office-ui-fabric-react';
 import Nrclineitem from './store/reducers/nrclineitem.reducer';




// Setting up all reducers.
const rootReducer = combineReducers({
  suppliers: suppliersReducer,
  user: userReducer,
  selectedSupplier: selectedSupplierReducer,
  features: featuresReducer,
  contracts: existingContractsReducer,
  headCount: HeadCount,
  invoiceView: InvoiceView,
  unAuthUser: unAuthUser,
  bobReport : BOBReport,
  nrclineitem:Nrclineitem
});

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__COMPOSE__: any;
  }
}

window.localStorage.setItem('synthetic', 'false');

const composeEnhancers =
  window.__REDUX_DEVTOOLS_EXTENSION__COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  {},
  composeEnhancers(applyMiddleware(thunk))
);

// Initialize icons of Office-fabric for the UI
initializeIcons();


axios.interceptors.request.use(
  request => {
    // console.log(request);
    return request;
  },
  error => {
    // console.log(error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    // console.log(response);
    return response;
  },
  error => {
    // console.log(error);
    return Promise.reject(error);
  }
);

    const authProvider: MsalAuthProvider = getAuthProvider();
    const logOutHandler = () => {
      if (authProvider) {
        authProvider.logout();
    }
    }
    ReactDOM.render(
        // Disabling strict mode check. The Fluent UI controls in @m365-admin have tons of deprecated things
        // like componentWillReceiveProps lifecycle method. Strict mode check will flag all these things and
        // make the console log noisy. Enable only when we want to find issues in our own components.
        // This React.StrictMode could be used individually in our own component code on a case by case basis.
        // See: https://reactjs.org/docs/strict-mode.html
        // <React.StrictMode>
        <Provider store={store}>

                    <AzureAD provider={authProvider} forceLogin={true} reduxStore={store}>
                        {({
                            accountInfo,
                            authenticationState,
                            error
                        }: IAzureADFunctionProps) => {
                              // Creating headers
                                const headers: Object = {
                                  'X-CorrelationId': uuidv4(),
                                  SubCorrelationKey: uuidv4(),
                                };
                                 // route
                              const url: string = Routes.FetchUserAuth;

                              // Logging telemetry
                              telemetryContext.logEvent(TelemetryEvent.FetchUserAuthBegin, {
                                headers,
                                url,
                              });
                            switch (authenticationState) {
                                case AuthenticationState.Authenticated: {
                                    const aadOid: string = accountInfo?accountInfo.account.accountIdentifier : '';
                                    return (

                                      <BrowserRouter>
                                      <App
                                        userName={accountInfo.account.name}
                                        logoutHandler={logOutHandler}
                                      />
                                    </BrowserRouter>
                                    );
                                }
                                default:
                                case AuthenticationState.InProgress: {
                                  return (
                                    <div style={{ marginTop: '45vh' }}>
                                      <Spinner label="Loading..." size={SpinnerSize.large} />
                                    </div>
                                  );
                                }
                            }
                        }
                        }
                    </AzureAD>

        </Provider>,
        document.getElementById('root')
    );

// ReactDOM.render(<div className={'absolute-center'}>Loading...</div>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
