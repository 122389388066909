import { IRenderFunction } from '@uifabric/utilities';
import {
  Checkbox,
  ChoiceGroup,
  SpinnerSize,
  Spinner,
} from 'office-ui-fabric-react';
import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IDetailsHeaderProps,
  Selection,
  SelectionMode,
} from 'office-ui-fabric-react/lib/DetailsList';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { Sticky, StickyPositionType } from 'office-ui-fabric-react/lib/Sticky';
import * as React from 'react';
import { customHeaderStyles } from '../../supplier-summary/purchase-orders/purchase-order-table/PurchaseOrderTable';
import { IPoLineItemNew } from './../../../shared/models/LineItem.model';
import './PoLineItemDetails.css';

// Interface for supplier engagement details props.
export interface PoLineItemDetailsProps {
  poLineItems: IPoLineItemNew[];
  onItemModeChange: any;
  onPoLinePrePaidCheckbox: any;
}

// interface for supplier engagement details state.
export interface PoLineItemDetailsState {}

// This class renders the POLineItem details on the PO review page.
export default class PoLineItemDetails extends React.Component<
  PoLineItemDetailsProps,
  PoLineItemDetailsState
> {
  // private helper objects.
  private selection: Selection;

  // columns to render
  private columns: IColumn[];

  constructor(props: PoLineItemDetailsProps) {
    super(props);

    this.columns = [
      {
        key: 'column1',
        name: 'Line Item',
        fieldName: 'lineItemNumber',
        minWidth: 50,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column2',
        name: 'SEIDs',
        fieldName: 'supplierEngagements',
        minWidth: 100,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onRender: this.renderSupplierEngagements,
      },
      {
        key: 'column3',
        name: 'Description',
        fieldName: 'description',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        data: 'string',
      },
      {
        key: 'column4',
        name: 'Amount',
        fieldName: 'amount',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        data: 'string',
        onRender: (item: IPoLineItemNew) => {
          return (
            <span>
              {item.amount
                .toFixed(2)
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}
            </span>
          );
        },
      },
      {
        key: 'column5',
        name: 'Internal Order',
        fieldName: 'internalOrder',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        data: 'string',
      },
      {
        key: 'column6',
        name: 'Cost Center',
        fieldName: 'costCenter',
        minWidth: 50,
        maxWidth: 100,
        isResizable: true,
        data: 'string',
      },
      {
        key: 'column7',
        name: 'Account Code',
        fieldName: 'accountCode',
        minWidth: 50,
        maxWidth: 100,
        isResizable: true,
        data: 'string',
      },
      {
        key: 'column0',
        name: 'isPrePaid',
        minWidth: 50,
        maxWidth: 100,
        isResizable: false,
        data: 'string',
        onRender: this.renderPrePaid,
      },
    ];
  }

  public render() {
    return (
      <div className="supplier-engagement-container">
        <h2 className="engagement-details-header">Purchase Order Line Items</h2>

        <Fabric
          style={{
            width: '30vw',
            //height: '15.3vh',
            textAlign: 'left',
            overflow: 'visible',
            margin: '1vh 0px 0vh 3vw',
          }}
        >
          <div id="choice_group">
            <ChoiceGroup
              defaultSelectedKey="none"
              styles={{ flexContainer: { display: 'flex' } }}
              options={[
                {
                  key: 'none',
                  // iconProps: { iconName: 'Remove' },
                  text: 'None\u00A0\u00A0',
                  disabled: this.props.poLineItems.length === 0,
                },
                {
                  key: 'month',
                  // iconProps: { iconName: 'Calendar' },
                  text: 'Month',
                  disabled: true,
                },
              ]}
              onChange={(_, option) => this.props.onItemModeChange(option)}
              ariaLabelledBy="choice_group"
              aria-label="Use arrow keys to navigate choice group"
            />
          </div>
        </Fabric>

        <Fabric
          style={{
            width: '90vw',
            height: '30vh',
            overflow: 'auto',
            display: 'block',
            margin: '0 auto',
            marginBottom: '23px',
          }}
        >
          {this.props.poLineItems.length > 0 ? (
            <DetailsList
              items={this.props.poLineItems}
              compact={false}
              columns={this.columns}
              selectionMode={SelectionMode.none}
              setKey="set"
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              selection={this.selection}
              selectionPreservedOnEmptyClick={true}
              enterModalSelectionOnTouch={true}
              ariaLabelForGrid="Purchase Order Line Items List"
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              // tslint:disable-next-line: jsx-no-lambda
              onRenderDetailsHeader={(
                detailsHeaderProps: IDetailsHeaderProps,
                defaultRender: IRenderFunction<IDetailsHeaderProps>
              ) => {
                return (
                  <Sticky stickyPosition={StickyPositionType.Header}>
                    {defaultRender({
                      ...detailsHeaderProps,
                      styles: customHeaderStyles,
                    })}
                  </Sticky>
                );
              }}
            />
          ) : (
            <Spinner style={{ marginTop: '5%' }} size={SpinnerSize.large} />
          )}
        </Fabric>
      </div>
    );
  }

  // This function renders the prepaid checkbox.
  private renderPrePaid = (item?: IPoLineItemNew): JSX.Element => {
    return (
      <Checkbox
        checked={item.isPrePaid}
        onChange={(e, v) => this.onCheckboxChange(e, v, item.lineItemNumber)}
        ariaLabel="is prepaid Checkbox"
      />
    );
  };

  // This function renders the prepaid checkbox.
  private renderSupplierEngagements = (item?: IPoLineItemNew): JSX.Element => {
    return (
      <span>
        {item.supplierEngagements !== undefined
          ? item.supplierEngagements.join(',')
          : ''}
      </span>
    );
  };

  // This function is called when a checkbox is clicked.
  private onCheckboxChange = (
    ev: React.FormEvent<HTMLElement>,
    isChecked: boolean,
    lineItem: number
  ): void => {
    this.props.onPoLinePrePaidCheckbox(lineItem, isChecked);
  };

  // private getMappedItems = () => {
  //   let counter = 1;
  //   return this.props.poLineItems.map(item => {
  //     let newItem: any = { ...item };
  //     newItem.lineItemNumber = counter++;
  //     newItem.supplierEngagements = item.supplierEngagements.join(',');

  //     return newItem;
  //   });
  // };

  private _getSelectionDetails(): string {
    const selectionCount = this.selection.getSelectedCount();

    switch (selectionCount) {
      case 0:
        return 'No items selected';
      case 1:
        return '1 item selected: ' + this.selection.getSelection()[0];
      default:
        return `${selectionCount} items selected`;
    }
  }
}
