import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Stack, Pivot, PivotItem } from 'office-ui-fabric-react';
import './BOBReport.css';
import BOBReportInvoice from './BOBReportInvoice';
import BOBReportBudget from './BOBReportBudget';
import { getBOBReportMasterData } from '../../store/actions/bobReport.action';
import { getUserRoles } from '../../store/actions/user.action';
import Unauthorized from '../../components/unauthorized/Unauthorized';
import HelmetTab from "../../shared/Helmet/Helmet"

//Interface for BOB Report Props
export interface IBOBReportProps {
  loadReportMasterData: any;
  getUserRoles: any;
  IsBusinessAnalyst: boolean;
  IsSupplierSupportManager: boolean;
  IsInvoiceAnalyst: boolean;
  IsInvoicingManager: boolean;
  IsFinanceOwner: boolean;
}

// Interface for BOBReport state.
interface IBOBReportState {
  currentReportType: string;
}

class BOBReport extends Component<IBOBReportProps, IBOBReportState> {
  public constructor(props) {
    super(props);
    this.props.getUserRoles();
    // State Initialization
    this.state = {
      currentReportType: 'Invoice',
    };
  }

  // --------------------- LIFECYCLE METHODS ---------------------------- //
  componentDidMount() {
    this.props.loadReportMasterData();
  }

  public render() {
    return this.props.IsBusinessAnalyst ||
      this.props.IsSupplierSupportManager ||
      this.props.IsInvoiceAnalyst ||
      this.props.IsInvoicingManager ||
      this.props.IsFinanceOwner ? (
      <div
        style={{
          background: 'rgb(232, 237, 241, 0.8)',
          padding: '10px',
        }}
      >
      <HelmetTab title={'Book Of Business Discount'} />
        <div className="report-container">
          <div className="report-header">
            <Stack horizontal={true}>
              <h1
                className="header-text"
                style={{ marginLeft: '1vw', width: '97%', textAlign: 'left', display: 'block', fontSize: '1.17em', margin: '1em 0 1em 0', fontWeight: 'bold', }}
              >
                Book Of Business Discount
              </h1>
            </Stack>
          </div>
          <div className="report-tabs">
            <Stack
              horizontal={true}
              horizontalAlign="space-between"
              style={{ width: '100%'}}
            >
              <Pivot
                style={{ textAlign: 'left', marginLeft: '1vw' }}
                onLinkClick={this.handleReportTypeToggle}
              >
                <PivotItem headerText="Invoice" />
                <PivotItem headerText="Budget" />
              </Pivot>
            </Stack>
          </div>
          {this.state.currentReportType === 'Invoice' ? (
            <BOBReportInvoice />
          ) : (
            <BOBReportBudget />
          )}
        </div>
      </div>
    ) : (
      <Unauthorized />
    );
  }

  private handleReportTypeToggle = (item: PivotItem) => {
    this.setState({ currentReportType: item.props.headerText });
  };
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    loadReportMasterData: () => {
      return dispatch(getBOBReportMasterData());
    },
    getUserRoles: () => {
      dispatch(getUserRoles());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    IsBusinessAnalyst: state.user.IsBusinessAnalyst,
    IsSupplierSupportManager: state.user.IsSupplierSupportManager,
    IsInvoiceAnalyst: state.user.IsInvoiceAnalyst,
    IsInvoicingManager: state.user.IsInvoicingManager,
    IsFinanceOwner: state.user.IsFinanceOwner,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BOBReport);
