import * as actionTypes from './actionTypes';
import { ISupplierInvoice } from '../../shared/models/SupplierAndInvoicePrefix.model';
import { v4 as uuidv4 } from 'uuid';
import {
  Routes,
  TelemetryEvent,
  TelemetryException,
} from '../../shared/models';
import telemetryContext from '../../shared/services/TelemetryServices';
import gtoNewApiInstance, {
  gtoMsalApiFetch,
} from '../../shared/http/GtoApiNew.axios';
import { toast } from 'react-toastify';
import { IInvoiceBillableUnitDetails } from '../../shared/models/InvoiceBillableUnitDetails';
import { ITotalCalculation } from '../../components/invoice-view/InvoiceView';
import { css } from 'glamor';
import { IDataCount } from '../../shared/models/DataCount';
import { dataCountBillableUnits } from './headCount.action';
import { InvoiceBillableUnitDetailsMockData } from '../../shared/mock/InvoiceBillableUnitDetailsMockData';
import { delay } from 'q';
import { SupplierAndInvociePrefixMockData } from '../../shared/mock/SupplierAndInvoicePrefixMockData';
import appConfig from '../../assets/configuration/config';
import { ISupplierInvoieVariance } from '../../shared/models/SupplierInvoiceVariance';
import { SupplierInvoiceVariance } from '../../shared/mock/SupplierInvoiceVariance';
import { InvoicingEvent } from '../../shared/models/InvoicingEvent.enum';

/* This function sets the amount calculation details array*/
export const totalCalculationDetails = (amountDetails: ITotalCalculation) => {
  return {
    type: actionTypes.TOTAL_CALCULATION_DETAILS,
    amountDetails,
  };
};

/* This function sets the supplier invoice prefix */
export const setSupplierInvoiceState = (invoiceState: string) => {
  return {
    type: actionTypes.GET_SUPPLIER_INVOICE_STATE,
    invoiceState,
  };
};

export const setSDMLockedDateTime = (sdmLockedOn: string) => {
  return {
    type: actionTypes.GET_SDM_Locked_DateTime,
    sdmLockedOn,
  };
};

export const setMonthStartDateTime = (modificationStartOn: string) => {
  return {
    type: actionTypes.GET_Month_Start_DateTime,
    modificationStartOn,
  };
};

export const setActualsDateTime = (actualsPostOn: string) => {
  return {
    type: actionTypes.GET_Actuals_DateTime,
    actualsPostOn,
  };
};

export const setAccrualsDateTime = (accrualsPostOn: string) => {
  return {
    type: actionTypes.GET_Accruals_DateTime,
    accrualsPostOn,
  };
};

/* This function sets the supplier invoice list */
export const setSupplierInvoiceList = (supplierInvoice: ISupplierInvoice[]) => {
  return {
    type: actionTypes.SET_SUPPLIER_INVOICE,
    supplierInvoice,
  };
};

/* This function sets the Billable Units for Actual */
export const setBillableUnitsForInvoice = (
  invoiceAccrualActualDetails: IInvoiceBillableUnitDetails[]
) => {
  return {
    type: actionTypes.SET_INVOICE_ACCRUAL_ACTUAL_DETAILS,
    invoiceAccrualActualDetails,
  };
};

/* This function sets the supplier invoice variance */
export const setSupplierInvoiceVariance = (
  supplierVariance: ISupplierInvoieVariance[]
) => {
  return {
    type: actionTypes.GET_SUPPLIER_VARIANCE,
    supplierVariance,
  };
};

/* This function sets the status for display button clicked in supplier variance grid */
export const setIsDisplayButtonClicked = (isDisplayButtonClicked: boolean) => {
  return {
    type: actionTypes.SET_IS_DISPLAY_BUTTON_CLICKED,
    isDisplayButtonClicked,
  };
};

/* This function stores the data which are paramaters for making Actual/Accrual data call. */
export const getDataForActualAccrualCall = (
  dataForActualAccrualCall: ISupplierInvoieVariance
) => {
  return {
    type: actionTypes.GET_DATA_FOR_ACTUAL_ACCRUAL_CALL,
    dataForActualAccrualCall,
  };
};

/* This function sets the data for all invoice actuals. */
export const setAllInvoiceAccrualsData = (
  allInvoiceAccrualsData: IInvoiceBillableUnitDetails[]
) => {
  return {
    type: actionTypes.GET_ALL_INVOICE_ACCRUALS,
    allInvoiceAccrualsData,
  };
};

/* This function sets the data for all invoice accruals. */
export const setAllInvoiceActualsData = (
  allInvoiceActualsData: IInvoiceBillableUnitDetails[]
) => {
  return {
    type: actionTypes.GET_ALL_INVOICE_ACTUALS,
    allInvoiceActualsData,
  };
};

/* Loader actions */
export const requestInvoiceDetails = () => {
  return { type: actionTypes.REQUEST_INVOICE_DETAILS };
};

export const requestInvoiceDetailsComplete = () => {
  return { type: actionTypes.REQUEST_INVOICE_DETAILS_COMPLETE };
};

export const requestInvoiceVarianceDetails = () => {
  return { type: actionTypes.REQUEST_INVOICE_VARIANCE_DETAILS };
};

export const requestInvoiceVarianceDetailsComplete = () => {
  return { type: actionTypes.REQUEST_INVOICE_VARIANCE_DETAILS_COMPLETE };
};

export const showErrorMessage = () => {
  return { type: actionTypes.SHOW_ERROR_MESSAGE_INVOICE };
};

export const showErrorMessageComplete = () => {
  return { type: actionTypes.SHOW_ERROR_MESSAGE_INVOICE_COMPLETE };
};

export const accrualInitialLoader = () => {
  return { type: actionTypes.ACCRUALS_INITIAL_LOADER };
};

export const accrualInitialLoaderComplete = () => {
  return { type: actionTypes.ACCRUALS_INITIAL_LOADER_COMPLETE };
};

export const showInvoiceInfo = () => {
  return { type: actionTypes.SHOW_INVOICE_INFO };
};

export const initialAmountCalculation = (initialAmounts: ITotalCalculation) => {
  return { type: actionTypes.INITAL_CALCULATION_VALUES, initialAmounts };
};

export const allInvoiceDataLoader = (isAllInvoiceLoader: boolean) => {
  return { type: actionTypes.IS_ALL_INVOICE_LOADER, isAllInvoiceLoader };
};

export const setIsActionChanged = (isActionChanged: boolean) => {
  return { type: actionTypes.SET_IS_ACTION_CHANGED, isActionChanged };
};

/* ------ */

/* This function sets the empty billable units */
export const setEmptyBillableUnits = (
  emptyBillableUnits: IInvoiceBillableUnitDetails[]
) => {
  return {
    type: actionTypes.SET_EMPTY_BILLABLE_UNITS,
    emptyBillableUnits,
  };
};

/* ------- Thunk Action Creators ------- */

/* This action fetches the Supplier and Invoice prefix from API */
export const getSupplierandInvoicePrefix = () => {
  return (dispatch) => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };
      // Defining the url
      let url: string = Routes.GetSupplierandInvoicePrefix;

      // Logging telemetry for billable units api call.
      telemetryContext.logEvent(
        TelemetryEvent.FetSupplierAndInvoicePrefixBegin,
        {
          url,
          headers,
        }
      );

      // Making the get call.
      return gtoMsalApiFetch(gtoNewApiInstance.get, url, {
        headers,
      })
        .then((response: any) => {
          // Mapping the response data.

          let supplierInvoiceList: ISupplierInvoice[] = response.data.map(
            (x) => ({
              SupplierName: x.supplierName,
              CompanyCode: x.companyCode,
              SupplierNumber: x.supplierNumber,
              SupplierId: x.supplierId,
              InvoicePrefix: x.invoicePrefix,
            })
          );
          dispatch(setSupplierInvoiceList(supplierInvoiceList));
        })
        .catch((error) => {
          throw error;
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {
        dispatch(setSupplierInvoiceList(SupplierAndInvociePrefixMockData));
      });
    }
  };
};

/* This action fetches the Billable Units for Actual from API */
export const getBillableUnitsForInvoice = (
  CumMonth: number,
  SupplierId: number,
  invoicePrefix: string,
  CompanyCode: string,
  SupplierNumber: number,
  isAccrualOrActual: boolean
) => {
  return (dispatch) => {
    if (SupplierId !== -1) {
      dispatch(requestInvoiceDetails());
      dispatch(showErrorMessageComplete());
      dispatch(accrualInitialLoaderComplete());
      // Checking if app is using mock data.
      if (appConfig.UseMockData === 'false') {
        // Creating headers for telemetry
        const headers: object = {
          'X-CorrelationId': uuidv4(),
          SubCorrelationKey: uuidv4(),
        };

        // Defining the url
        let url: string = isAccrualOrActual
          ? Routes.GetActualsForInvoicePrefix
          : Routes.GetAccrualsForInvoicePrefix;
        if (SupplierId !== 0) {
          url = `${url}?supplierId=${SupplierId}`;
        }
        if (invoicePrefix !== '') {
          url = `${url}&invoicePrefix=${invoicePrefix}`;
        }
        if (CumMonth !== 0) {
          url = `${url}&cumulativeMonth=${CumMonth}`;
        }

        // Logging telemetry for billable units api call.
        telemetryContext.logEvent(TelemetryEvent.FetchInvoiceDetailsBegin, {
          url,
          headers,
        });

        // Making the get call.
        return gtoMsalApiFetch(gtoNewApiInstance.get, url, {
          headers,
        })
          .then((response: any) => {
            // Mapping the response data.

            let invoiceBillableUnits: IInvoiceBillableUnitDetails[] = response.data.map(
              (x) => ({
                EngagementId: x.engagementId,
                FiscalYearNumber: x.fiscalYearNumber,
                SupplierEngagementId: x.supplierEngagementId,
                FunctionSummary: x.functionSummary,
                FunctionDetail: x.functionDetail,
                SupplierName: x.supplierName,
                SupplierCity: x.supplierCity,
                SupplierCountry: x.supplierCountry,
                BusinessSegment: x.businessSegment,
                ExecPL: x.execPL,
                Product: x.product,
                ProductGroup: x.productGroup,
                ProductSubType: x.productSubType,
                Offering: x.offering,
                LOB: x.lob,
                MediaType: x.mediaType,
                MediaSubType: x.mediaSubtype,
                SupportTier: x.supportTier,
                Language: x.language,
                TaxPercent: x.taxPercent,
                BillableUnits: x.billableUnits,
                LastAction: x.lastAction,
                State: x.state,
                PostCumMonth: x.postCumMonth,
                PostInvoicePeriod: x.postInvoicePeriod,
                CurrencyCode: x.currencyCode,
                DiscountPercent: x.discountPercent,
                TotalAmount: x.totalAmount,
                GrossAmount: x.grossAmount,
                DiscountAmount: x.discountAmount,
                TaxAmount: x.taxAmount,
                NetAmount: x.netAmount,
                PriceLine:
                  x.priceLine === null
                    ? null
                    : x.priceLine.map((y, index) => ({
                        PricingModel: y.pricingModel,
                        PricingType: y.pricingType,
                        PricingLevel: y.pricingLevel,
                        Price: y.price,
                        VolumeMin: y.volumeMin,
                        VolumeMax: y.volumeMax,
                        Index: index,
                      })),
                NRCType: x.nrcType,
                NRCCategory: x.nrcCategory,
                NRCDescription: x.nrcDescription,
                IsEditable: x.isEditable,
                NeedsMicrosoftAcceptance: x.needsMicrosoftAcceptance,
                IsNRC: x.isNRC,
                IsVolume: x.isVolume,
                IsPriorPeriodAdjustment: x.isPriorPeriodAdjustment,
                CumMonth: CumMonth,
                SD1: x.serviceDelivery,
                SD2: x.serviceDeliveryManager,
                PricingModel: x.pricingModel,
                SupplierNumber: SupplierNumber,
                CompanyCode: CompanyCode,
                TotalAmountCD: x.totalAmountCD
                  .toFixed(2)
                  .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
                GrossAmountCD: x.grossAmountCD
                  .toFixed(2)
                  .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
                DiscountAmountCD: x.discountAmountCD
                  .toFixed(2)
                  .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
                TaxAmountCD: x.taxAmountCD
                  .toFixed(2)
                  .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
                NetAmountCD: x.netAmountCD
                  .toFixed(2)
                  .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
                InvoicePrefix: x.invoicePrefix,
                InternalOrder: x.internalOrder,
                AccountCode: x.accountCode,
                CostCenter: x.costCenter,
                PurchaseOrder: x.purchaseOrder,
                SalesRegion: x.geoRegion,
                PCY: x.pcy,
                SubPCY: x.subPCY,
                IsCommerce: x.isCommerce,
                BusinessGroup: x.businessGroup
              })
            );

            // Logging telemetry for billable units api call.
            telemetryContext.logEvent(
              TelemetryEvent.FetchInvoiceDetailsSuccess,
              {
                url,
                headers,
              }
            );
            dispatch(calculateItemCount(invoiceBillableUnits));
            dispatch(setBillableUnitsForInvoice(invoiceBillableUnits));
            dispatch(amountCalculationInvoice(invoiceBillableUnits));
            dispatch(requestInvoiceDetailsComplete());
          })
          .catch((error) => {
            // Log exception
            telemetryContext.logException(
              error.toString(),
              TelemetryException.FetchInvoiceDetailsFailure,
              undefined,
              {
                url: url,
                headers,
              }
            );

            // Error handling.
            if (error.response && error.response.status === 404) {
              toast.error(
                `Unable to fetch Invoice ${
                  isAccrualOrActual ? 'Actual' : 'Accrual'
                } information. ${error.message}`,
                {
                  className: css({
                    background: '#a80000 !important',
                  }),
                }
              );
              dispatch(showErrorMessage());
              dispatch(setBillableUnitsForInvoice([]));
              dispatch(requestInvoiceDetailsComplete());
              dispatch(setSupplierInvoiceState(''));
              dispatch(calculateItemCount([]));
            }

            throw error;
          });
      } else {
        // Setting mock data.
        return delay(2000).then(() => {
          //dispatch(dataCountBillableUnits(dataCount));
          dispatch(
            setBillableUnitsForInvoice(InvoiceBillableUnitDetailsMockData)
          );
          dispatch(
            amountCalculationInvoice(InvoiceBillableUnitDetailsMockData)
          );
          dispatch(requestInvoiceDetailsComplete());
        });
      }
    }
  };
};

/* action to calculate the count of All, NRC, PPA and Volume */
export const calculateItemCount = (
  billableUnits: IInvoiceBillableUnitDetails[]
) => {
  return (dispatch) => {
    let dataCount: IDataCount = {
      All: 0,
      Volume: 0,
      NRC: 0,
      PPA: 0,
    };
    dataCount.All = billableUnits.length;
    dataCount.NRC = billableUnits.filter(
      (x) => x.IsNRC === true && !x.IsPriorPeriodAdjustment
    ).length;
    dataCount.Volume = billableUnits.filter((x) => x.IsNRC === false).length;
    dataCount.PPA = billableUnits.filter(
      (x) => x.IsPriorPeriodAdjustment === true
    ).length;
    dispatch(dataCountBillableUnits(dataCount));
  };
};

/* action to calculate the amount for all suppliers */
export const amountCalculationInvoice = (
  billableUnits: IInvoiceBillableUnitDetails[]
) => {
  return (dispatch) => {
    let amountDetails: ITotalCalculation = {
      totalAmount: 0,
      grossAmount: 0,
      discountAmount: 0,
      taxAmount: 0,
      netAmount: 0,
    };
    let totalCalculatedAmount: number = 0;
    let totalGrossAmount: number = 0;
    let totalDiscountAmount: number = 0;
    let totalTaxAmount: number = 0;
    let totalNetAmount: number = 0;

    for (let i = 0; i < billableUnits.length; i++) {
      totalCalculatedAmount =
        totalCalculatedAmount + billableUnits[i].TotalAmount;
      totalGrossAmount = totalGrossAmount + billableUnits[i].GrossAmount;
      totalDiscountAmount =
        totalDiscountAmount + billableUnits[i].DiscountAmount;
      totalTaxAmount = totalTaxAmount + billableUnits[i].TaxAmount;
      totalNetAmount = totalNetAmount + billableUnits[i].NetAmount;
    }

    amountDetails.discountAmount = totalDiscountAmount;
    amountDetails.grossAmount = totalGrossAmount;
    amountDetails.netAmount = totalNetAmount;
    amountDetails.taxAmount = totalTaxAmount;
    amountDetails.totalAmount = totalCalculatedAmount;
    dispatch(totalCalculationDetails(amountDetails));
  };
};

/* This action sets the Invoice Info message */
export const getInvoiceInfoMessage = () => {
  return (dispatch) => {
    toast.warn('Data will not Available for future months', {
      className: css({
        background: '#d83b01 !important',
      }),
    });
    dispatch(showInvoiceInfo());
  };
};

/* This action fetches the supplier invoice state from API */
export const getSupplierInvoiceState = (
  SupplierId: number,
  CumMonth: number,
  invoicePrefix: string
) => {
  return (dispatch) => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };
      // Defining the url
      let url: string = Routes.GetSupplierInvoiceState;
      if (SupplierId !== 0) {
        url = `${url}?supplierId=${SupplierId}`;
      }
      if (CumMonth !== 0) {
        url = `${url}&cumulativeMonth=${CumMonth}`;
      }
      if (invoicePrefix !== '') {
        url = `${url}&invoicePrefix=${invoicePrefix}`;
      }
      // Logging telemetry for billable units api call.
      telemetryContext.logEvent(
        TelemetryEvent.FetSupplierAndInvoicePrefixBegin,
        {
          url,
          headers,
        }
      );

      // Making the get call.
      return gtoMsalApiFetch(gtoNewApiInstance.get, url, {
        headers,
      })
        .then((response: any) => {
          // Mapping the response data.
          let invoiceState: string = response.data;          
          dispatch(setSupplierInvoiceState(invoiceState));
        })
        .catch((error) => {
          dispatch(setSupplierInvoiceState(''));
          throw error;
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {
        dispatch(setSupplierInvoiceState('Invoice Generated'));
      });
    }
  };
};

/* This action fetches supplier variance from API */
export const getSupplierVariance = (cumulativeMonth: number) => {
  return (dispatch) => {
    dispatch(requestInvoiceVarianceDetails());
    //dispatch(showErrorMessageComplete());
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };
      // Defining the url
      let url: string = Routes.GetSupplierInvoice;
      if (cumulativeMonth !== 0)
        url = `${url}?cumulativeMonth=${cumulativeMonth}`;

      // Logging telemetry for billable units api call.
      telemetryContext.logEvent(TelemetryEvent.FetchSupplierVarianceBegin, {
        url,
        headers,
      });

      // Making the get call.
      return gtoMsalApiFetch(gtoNewApiInstance.get, url, {
        headers,
      })
        .then((response: any) => {
          // Mapping the response data.

          let supplierInvoiceVarianceList: ISupplierInvoieVariance[] = response.data.map(
            (x) => ({
              SupplierId: x.supplierId,
              SupplierName: x.supplierName,
              SupplierNumber: Number(x.supplierNumber),
              CompanyCode: x.companyCode,
              InvoicePrefix: x.invoicePrefix,
              CumulativeMonth: x.cumMonth,
              Currency: x.currency,
              MonthName: x.monthName,
              AccrualTotalAmount: x.accrualTotalAmount,
              AccrualGrossAmount: x.accrualGrossAmount,
              AccrualNetAmount: x.accrualNetAmount,
              AccrualDiscount: x.accrualDiscount,
              AccrualTax: x.accrualTax,
              ActualTotalAmount: x.actualTotalAmount,
              ActualGrossAmount: x.actualGrossAmount,
              ActualNetAmount: x.actualNetAmount,
              ActualDiscount: x.actualDiscount,
              ActualTax: x.actualTax,
              Grossvariance: x.grossVariance,
              GrossVariancePercent: x.grossVariancePercent,
              ExchangeRate: x.exchangeRate,
              AccrualTotalAmountCD: x.accrualTotalAmountCD,
              AccrualGrossAmountCD: x.accrualGrossAmountCD,
              AccrualNetAmountCD: x.accrualNetAmountCD,
              AccrualDiscountCD: x.accrualDiscountCD,
              AccrualTaxCD: x.accrualTaxCD,
              ActualTotalAmountCD: x.actualTotalAmountCD,
              ActualGrossAmountCD: x.actualGrossAmountCD,
              ActualNetAmountCD: x.actualNetAmountCD,
              ActualDiscountCD: x.actualDiscountCD,
              ActualTaxCD: x.actualTaxCD,
              GrossvarianceCD: x.grossVarianceCD,
              GrossVariancePercentCD: x.grossVariancePercentCD,
            })
          );
          telemetryContext.logEvent(
            TelemetryEvent.FetchSuppliervarianceSuccess,
            {
              url,
              headers,
            }
          );
          dispatch(requestInvoiceVarianceDetailsComplete());
          //dispatch(showErrorMessage());
          dispatch(setSupplierInvoiceVariance(supplierInvoiceVarianceList));
        })
        .catch((error) => {
          // Log exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchSupplierVarianceFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );
          // Error handling.
          if (error.response && error.response.status === 404) {
            toast.error(
              `Unable to fetch Supplier Variance information. ${error.message} `,
              {
                className: css({
                  background: '#a80000 !important',
                }),
              }
            );
            //dispatch(showErrorMessage());
          }
          dispatch(requestInvoiceVarianceDetailsComplete());
          dispatch(setSupplierInvoiceVariance([]));
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {
        dispatch(setSupplierInvoiceVariance(SupplierInvoiceVariance));
      });
    }
  };
};

export const getAllInvoice = (
  cumulativeMonth: number,
  isActualAccrual: boolean
) => {
  return (dispatch) => {
    dispatch(allInvoiceDataLoader(true));
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };
      // Defining the url
      let url: string = isActualAccrual
        ? Routes.GetAllInvoiceActuals
        : Routes.GetAllInvoiceAccruals;
      if (cumulativeMonth !== 0)
        url = `${url}?cumulativeMonth=${cumulativeMonth}`;
      // Logging telemetry for billable units api call.
      telemetryContext.logEvent(
        isActualAccrual
          ? TelemetryEvent.FetchAllInvoiceActualsBegin
          : TelemetryEvent.FetchAllInvoiceAccrualsBegin,
        {
          url,
          headers,
        }
      );

      // Making the get call.
      return gtoMsalApiFetch(gtoNewApiInstance.get, url, {
        headers,
      })
        .then((response: any) => {
          // Mapping the response data.
          let allInvoiceData: IInvoiceBillableUnitDetails[] = response.data.map(
            (x) => ({
              EngagementId: x.engagementId,
              FiscalYearNumber: x.fiscalYearNumber,
              SupplierEngagementId: x.supplierEngagementId,
              FunctionSummary: x.functionSummary,
              FunctionDetail: x.functionDetail,
              SupplierName: x.supplierName,
              SupplierCity: x.supplierCity,
              SupplierCountry: x.supplierCountry,
              BusinessSegment: x.businessSegment,
              ExecPL: x.execPL,
              Product: x.product,
              ProductGroup: x.productGroup,
              ProductSubType: x.productSubType,
              Offering: x.offering,
              LOB: x.lob,
              MediaType: x.mediaType,
              MediaSubType: x.mediaSubtype,
              SupportTier: x.supportTier,
              Language: x.language,
              TaxPercent: x.taxPercent,
              BillableUnits: x.billableUnits,
              LastAction: x.lastAction,
              State: x.state,
              PostCumMonth: x.postCumMonth,
              PostInvoicePeriod: x.postInvoicePeriod,
              CurrencyCode: x.currencyCode,
              DiscountPercent: x.discountPercent,
              TotalAmount: x.totalAmount,
              GrossAmount: x.grossAmount,
              DiscountAmount: x.discountAmount,
              TaxAmount: x.taxAmount,
              NetAmount: x.netAmount,
              PriceLine:
                x.priceLine === null
                  ? null
                  : x.priceLine.map((y, index) => ({
                      PricingModel: y.pricingModel,
                      PricingType: y.pricingType,
                      PricingLevel: y.pricingLevel,
                      Price: y.price,
                      VolumeMin: y.volumeMin,
                      VolumeMax: y.volumeMax,
                      PricingModelId: y.pricingModelId,
                      PriceLineBillableUnits: y.priceLineBillableUnits,
                      PriceLineAmount: y.priceLineAmount,
                      Index: index,
                    })),
              NRCType: x.nrcType,
              NRCCategory: x.nrcCategory,
              NRCDescription: x.nrcDescription,
              IsEditable: x.isEditable,
              NeedsMicrosoftAcceptance: x.needsMicrosoftAcceptance,
              IsNRC: x.isNRC,
              IsVolume: x.isVolume,
              IsPriorPeriodAdjustment: x.isPriorPeriodAdjustment,
              CumMonth: x.cumMonth,
              SD1: x.serviceDelivery,
              SD2: x.serviceDeliveryManager,
              PricingModel: x.pricingModel,
              TotalAmountCD: x.totalAmountCD
                .toFixed(2)
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
              GrossAmountCD: x.grossAmountCD
                .toFixed(2)
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
              DiscountAmountCD: x.discountAmountCD
                .toFixed(2)
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
              TaxAmountCD: x.taxAmountCD
                .toFixed(2)
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
              NetAmountCD: x.netAmountCD
                .toFixed(2)
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
              InvoicePrefix: x.invoicePrefix,
              InternalOrder: x.internalOrder,
              AccountCode: x.accountCode,
              CostCenter: x.costCenter,
              PurchaseOrder: x.purchaseOrder,
              Org: x.org,
              OrgDetails: x.orgDetail,
              Function: x.function,
              SalesDistrict: x.salesDistrict,
              SalesSubLocation: x.salesSubLocation,
              ManagementEntity: x.managementEntity,
              SupplierSite: x.supplierSite,
              SupplierId: x.supplierId,
              LastActionId: x.lastActionId,
              InvoiceAdjRequestId: x.invoiceAdjRequestId,
              Comment: x.comment,
              ExchangeRate: x.exchangeRate,
              ProfitCenter: x.profitCenter,
              SupplierNumber:x.supplierNumber,
              CompanyCode:x.companyCode,
              SalesRegion: x.geoRegion,
              PCY: x.pcy,
              SubPCY: x.subPCY,
              IsCommerce: x.isCommerce,
              BusinessGroup: x.businessGroup
            })
          );

          telemetryContext.logEvent(
            isActualAccrual
              ? TelemetryEvent.FetchAllInvoiceActualsSuccess
              : TelemetryEvent.FetchAllInvoiceAccrualsSuccess,
            {
              url,
              headers,
            }
          );

          isActualAccrual
            ? dispatch(setAllInvoiceActualsData(allInvoiceData))
            : dispatch(setAllInvoiceAccrualsData(allInvoiceData));
          dispatch(allInvoiceDataLoader(false));
        })
        .catch((error) => {
          // Log exception
          telemetryContext.logException(
            error.toString(),
            isActualAccrual
              ? TelemetryException.FetchAllInvoiceActualsFailure
              : TelemetryException.FetchAllInvoiceAccrualsFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );
          dispatch(allInvoiceDataLoader(false));
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {
        dispatch(setAllInvoiceAccrualsData(InvoiceBillableUnitDetailsMockData));
      });
    }
  };
};

/* This action fetches the event timelines from API */
export const getEventDateTime = (
  eventName: string,
  CumMonth: number,
  SupplierId: number
) => {
  return (dispatch) => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };
      // Defining the url
      let url: string = Routes.getEventDateTime;

      if (eventName !== null){
        url = `${url}?eventName=${eventName}`;
      }      
      if (CumMonth !== 0) {
        url = `${url}&cumulativeMonth=${CumMonth}`;
      }  
      if (SupplierId !== 0) {
        url = `${url}&supplierId=${SupplierId}`;
      }
      // Logging telemetry for event datatime api call.
      telemetryContext.logEvent(
        TelemetryEvent.FetchEventDateTimeBegin,
        {
          url,
          headers,
        }
      );

      // Making the get call.
      return gtoMsalApiFetch(gtoNewApiInstance.get, url, {
        headers,
      })
        .then((response: any) => {
          // Mapping the response data.
          
          const options = {
            year: 'numeric', 
            month: 'numeric', 
            day: 'numeric',
            hour: 'numeric', 
            minute: 'numeric',
            hour12: false,
          };

          const eventday: Date = new Date(response.data);          
          const eventDateTime: string = new Intl.DateTimeFormat('en-US',options).format(eventday).toString()
         
          if(eventName === InvoicingEvent.LockedForMicrosoft){
            dispatch(setSDMLockedDateTime(eventDateTime));
          }
          else if (eventName === InvoicingEvent.InvoiceMonthStart){
            dispatch(setMonthStartDateTime(eventDateTime));
          }
          else if(eventName === InvoicingEvent.InvoiceActual){
            dispatch(setActualsDateTime(eventDateTime));
          }
          else if(eventName === InvoicingEvent.InvoiceAccrual){
            dispatch(setAccrualsDateTime(eventDateTime));
          }          
        })
        .catch(() => {
          if(eventName === InvoicingEvent.LockedForMicrosoft){
            dispatch(setSDMLockedDateTime(''));
          }
          else if (eventName === InvoicingEvent.InvoiceMonthStart){
            dispatch(setMonthStartDateTime(''));
          }
          else if(eventName === InvoicingEvent.InvoiceActual){
            dispatch(setActualsDateTime(''));
          }
          else if(eventName === InvoicingEvent.InvoiceAccrual){
            dispatch(setAccrualsDateTime(''));
          }          
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {
        if(eventName === InvoicingEvent.LockedForMicrosoft){
          dispatch(setSDMLockedDateTime('2020-09-09T12:00:00'));
        }
        else if (eventName === InvoicingEvent.InvoiceMonthStart){
          dispatch(setMonthStartDateTime('2020-09-09T12:00:00'));
        }
        else if(eventName === InvoicingEvent.InvoiceActual){
          dispatch(setActualsDateTime('2020-09-09T12:00:00'));
        }
        else if(eventName === InvoicingEvent.InvoiceAccrual){
          dispatch(setAccrualsDateTime('2020-09-09T12:00:00'));
        }
      });
    }
  };
};
