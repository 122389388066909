import { IAdditionalAttribute } from '../headcount/HeadCount';

const AdditionalAttributes: IAdditionalAttribute[] = [
  {
    name: 'BusinessSegment',
    displayName: 'Business Segment',
    selected: false,
    key: 0
  },
  {
    name: 'DiscountAmount',
    displayName: 'Discount Amount',
    selected: false,
    key: 1
  },
  {
    name: 'DiscountPercent',
    displayName: 'Discount Percent',
    selected: false,
    key: 2
  },
  {
    name: 'EngagementId',
    displayName: 'Engagement Id',
    selected: false,
    key: 3
  },
  {
    name: 'ExecPL',
    displayName: 'ExecPL',
    selected: false,
    key: 4
  },
  {
    name: 'FunctionDetail',
    displayName: 'Function Detail',
    selected: false,
    key: 5
  },
  {
    name: 'SupplierCity',
    displayName: 'Supplier City',
    selected: false,
    key: 6
  },
  {
    name: 'SupplierCountry',
    displayName: 'Supplier Country/Region',
    selected: false,
    key: 7
  },
  {
    name: 'GrossAmount',
    displayName: 'Gross Amount',
    selected: false,
    key: 8
  },
  {
    name: 'LOB',
    displayName: 'Line of Business',
    selected: false,
    key: 9
  },
  {
    name: 'MediaType',
    displayName: 'Media Type',
    selected: false,
    key: 10
  },
  {
    name: 'NetAmount',
    displayName: 'Net Amount',
    selected: false,
    key: 11
  },
  {
    name: 'NRCCategory',
    displayName: 'NRC Category',
    selected: false,
    key: 12
  },
  {
    name: 'NRCDescription',
    displayName: 'NRC Description',
    selected: false,
    key: 13
  },
  {
    name: 'NRCType',
    displayName: 'NRC Type',
    selected: false,
    key: 14
  },
  {
    name: 'Offering',
    displayName: 'Offering',
    selected: false,
    key: 15
  },
  {
    name: 'ProductSubType',
    displayName: 'Product Sub Type',
    selected: false,
    key: 16
  },
  {
    name: 'SupplierName',
    displayName: 'Supplier Name',
    selected: false,
    key: 17
  },
  {
    name: 'TaxAmount',
    displayName: 'Tax Amount',
    selected: false,
    key: 18
  },
  {
    name: 'TaxPercent',
    displayName: 'Tax Percent',
    selected: false,
    key: 19
  },
  {
    name: 'SD1',
    displayName: 'SD1',
    selected: false,
    key: 20
  },
  {
    name: 'SD2',
    displayName: 'SD2',
    selected: false,
    key: 21
  },
  {
    name: 'TotalAmountCD',
    displayName: 'Total Amount ($)',
    selected: false,
    key: 22
  },
  {
    name: 'GrossAmountCD',
    displayName: 'Gross Amount ($)',
    selected: false,
    key: 23
  },
  {
    name: 'DiscountAmountCD',
    displayName: 'Discount Amount ($)',
    selected: false,
    key: 24
  },
  {
    name: 'TaxAmountCD',
    displayName: 'Tax Amount ($)',
    selected: false,
    key: 25
  },
  {
    name: 'NetAmountCD',
    displayName: 'Net Amount ($)',
    selected: false,
    key: 26
  },
  {
    name: 'InvoicePrefix',
    displayName: 'Invoice Prefix',
    selected: false,
    key: 27
  },
  {
    name: 'InternalOrder',
    displayName: 'Internal Order',
    selected: false,
    key: 28
  },
  {
    name: 'CostCenter',
    displayName: 'Cost Center',
    selected: false,
    key: 29
  },
  {
    name: 'AccountCode',
    displayName: 'Account Code',
    selected: false,
    key: 30
  },
  {
    name: 'PurchaseOrder',
    displayName: 'Purchase Order',
    selected: false,
    key: 31
  },
  {
    name: 'SalesRegion',
    displayName: 'Sales Region',
    selected: false,
    key: 32
  },
  {
    name: 'PCY',
    displayName: 'PCY',
    selected: false,
    key: 33
  },
  {
    name: 'SubPCY',
    displayName: 'Sub-PCY',
    selected: false,
    key: 34
  },
  {
    name: 'Language',
    displayName: 'Language',
    selected: false,
    key: 35
  },
  {
    name: 'IsCommerce',
    displayName: 'Is Commerce',
    selected: false,
    key: 36
  },
  {
    name: 'BusinessGroup',
    displayName: 'Business Group',
    selected: false,
    key: 37
  },
  {
    name: 'PricingModel',
    displayName: 'PM',
    selected: false,
    key: 38
  },
  {
    name: 'PricingType',
    displayName: 'Pricing Type',
    selected: false,
    key: 39
  },
  {
    name: 'Level',
    displayName: 'Level',
    selected: false,
    key: 40
  },
  {
    name: 'CurrencyCode',
    displayName: 'Currency',
    selected: false,
    key: 41
  },
  {
    name: 'Price',
    displayName: 'Price',
    selected: false,
    key: 42
  },
  {
    name: 'TotalAmount',
    displayName: 'Amount',
    selected: false,
    key: 43
  },
  {
    name: 'FunctionSummary',
    displayName: 'Function Summary',
    selected: false,
    key: 44
  },
  {
    name: 'ProductGroup',
    displayName: 'Product Group',
    selected: false,
    key: 45
  },
  {
    name: 'Product',
    displayName: 'Product',
    selected: false,
    key: 46
  },
  {
    name: 'SupportTier',
    displayName: 'Support Tier',
    selected: false,
    key: 47
  },
  {
    name: 'MediaSubType',
    displayName: 'Media Subtype',
    selected: false,
    key: 48
  }
];

export default AdditionalAttributes;
