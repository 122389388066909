import React from 'react';
import { IconButton } from 'office-ui-fabric-react';
import { IFilterData } from '../FilterComponents/FilterDropdown';

export interface Props {
  displayData: IFilterData;
  onDelete: any;
}

export const DisplayFilter: React.FC<Props> = (props) => {
  const removeFilter = () => {
    props.onDelete(props.displayData);
  };

  return (
    <div style={{ float: 'right', marginRight: '1%', marginBottom: '1%' }}>
      <div
        style={{ display: 'flex', flexDirection: 'row' }}
        className="filter-display"
      >
        <span
          style={{
            border: '1px solid #0078d4',
            color: '#0078d4',
            borderRadius: '95px 95px 95px 95px',
            fontSize: '14px',
            paddingLeft: '5px',
            backgroundColor: 'white',
          }}
        >
          {props.displayData.columnValue} == {props.displayData.filter}
          <IconButton
            iconProps={{ iconName: 'Cancel' }}
            onClick={removeFilter}
            ariaLabel="Cancel Filter"
            tabIndex={0}
            autoFocus={true}
          />
        </span>
      </div>
    </div>
  );
};

export default DisplayFilter;
