import * as React from 'react';
import {
  Stack,
  Fabric,
  PrimaryButton,
  SpinnerSize,
  Spinner,
  Icon,
  TooltipHost,
  ScrollablePane,
  ScrollbarVisibility,
  StickyPositionType,
  Sticky,
  IRenderFunction,
  ConstrainMode,
  IconButton,
  Label,
  DefaultButton,
  DirectionalHint,
  Panel,
  PanelType,
  ActionButton,
  IColumnReorderOptions,
} from 'office-ui-fabric-react';
import { TextField } from '@fluentui/react/lib/TextField';
import { SpinButton, ISpinButtonStyles } from '@fluentui/react/lib/SpinButton';
import { Announced } from '@fluentui/react/lib/Announced';

import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IDetailsHeaderProps,
  SelectionMode,
  IDetailsRowProps,
  DetailsRow,
} from '@fluentui/react';
import { IInvoiceBillableUnitDetails } from '../../../../shared/models/InvoiceBillableUnitDetails';
import { CardStyle } from '../../../../shared/styles/Card';
import {
  totalAmountCalculation,
  amountCalculationHeadCount,
  acceptModification,
  setBillableUnitsChange,
  getBillableUnits,
  isColumnSortedDetails,
  postPriorPeriodAdjustmentDetails,
} from '../../../../store/actions/headCount.action';
import {
  accrualInitialLoader,
  getBillableUnitsForInvoice,
  setIsDisplayButtonClicked,
  getSupplierInvoiceState,
  getDataForActualAccrualCall
} from '../../../../store/actions/invoiceView.action';
import { connect } from 'react-redux';
import { IAcceptModification } from '../../../../shared/models/AcceptModification';
import { setIsLoadingGlobal } from '../../../../store/actions/user.action';
import { IInvoiceHeadCountFilterDetails } from '../../../../shared/models/HeadcountFilterDetails.model';
import { IAdditionalAttribute } from '../../HeadCount';
import '../../HeadCount.css';
import { MicrosoftSubmittedBillableUnits } from '../../../../shared/models/MicrosoftSubmittedBillableUnits';
import { IHeadCountMonthList } from '../../../../shared/models';
import { IPriorPeriodAdjustment } from '../../../../shared/models/PriorPeriodAdjustment';
import { IPriorPeriodDetails } from '../../../../shared/models/PriorPeriodDetails';
import { ISupplierInvoieVariance } from '../../../../shared/models/SupplierInvoiceVariance';
import { UserPreferences } from '../../../../shared/models/UserPreferences';
import { isNullOrUndefined } from '../../../../shared/common/common-util';

export interface IVolumeListProps {
  isLoadingGlobal: boolean;
  setGlobalLoadingFlag: any;
  additionalAttributesToDisplay: IAdditionalAttribute[];
  billableUnit: any[];
  updatedItems: any;
  stateSelected: string;
  totalAmountCalculation: any;
  amountTotal: IAmountCalculation[];
  amountCalculationHeadCount: any;
  isEditable: boolean;
  showInvoiceLoader: boolean;
  showErrorMessage: boolean;
  showInvoiceViewLoader: boolean;
  showInvoiceErrorMessage: boolean;
  acceptModification: any;
  accrualInitialLoader: any;
  showAccrualInitialLoader: boolean;
  getBillableUnits: any;
  selectedMonth: any;
  setBillableUnitsChange: any;
  isColumnSortedDetails: any;
  isColumnSorted: boolean;
  headCountBillableUnits: IInvoiceBillableUnitDetails[];
  previousMonthMicrosoftSubmittedData: MicrosoftSubmittedBillableUnits[];
  monthList: IHeadCountMonthList[];
  priorPeriod: IPriorPeriodAdjustment[];
  postPriorPeriodAdjustmentDetails: any;
  isFilter: boolean;
  getData: any;
  isFetchingInvoiceVariance: boolean;
  getBillableUnitsForInvoice: any;
  isActualOrAccrual: boolean;
  setIsDisplayButtonClicked: any;
  isDisplayButtonClicked: boolean;
  getSupplierInvoiceState: any;
  getDataForActualAccrualCall: any;
  loggedinUserAlias: string;
  onButtonClick: any;
  columns: IColumn[];
  userPreferences: UserPreferences;
  onColumnReorder?: (frozenColumns: number, draggedIndex: number, targetIndex: number) => void;
  onShowDetailsColumns?: () => void;
  isAutomatedSEIDsFiltered: boolean;
}

export interface IVolumeListState {
  updatesSupplier: IInvoiceBillableUnitDetails[];
  amountCalc: IAmountCalculation[];
  items: IInvoiceBillableUnitDetails[];
  showPriorPeriodModal: boolean;
  adjustShow: boolean;
  approveDisable: boolean;
  announcedMessage:string;
}

export interface IAmountCalculation {
  amount: number;
  currencyCode: string;
  key: number;
}

export interface ICurrencyTotalAmount {
  currencyCode: string;
  totalAmount: number;
  key: number;
}

class VolumeList extends React.Component<IVolumeListProps, IVolumeListState> {
  private changedItems: IInvoiceBillableUnitDetails[] = [];
  private columnList: IColumn[] = [];
  private isSortedDescending: boolean = false;
  private additionalAttributesAdded: boolean = false;
  private changedRow: IAcceptModification = {
    supplierEngagementId: 0,
    fiscalYearNumber: 0,
    cumMonth: 0,
    postCumMonth: 0,
    billableUnits: 0,
    comment: '',
    nrcId: 0,
  };
  private priorPeriodSEID: number;
  private priorPeriodBillableUnits: number = 0;
  private priorPeriodPostPeriod: string = '';
  private priorPeriodPostCumMonth: number = 0;
  private selectedFiscalYear: number = 2021;
  private allData: IInvoiceBillableUnitDetails[] = [];
  private volumeData: IInvoiceBillableUnitDetails[] = [];
  private nrcData: IInvoiceBillableUnitDetails[] = [];
  private isNrc: boolean;
  private nrcId: number;
  private microsoftSubmittedUnits: number = 0;
  private supplierSubmittedUnits: number = 0;
  private supplierComments: string = '';
  private selectedItem: IInvoiceBillableUnitDetails;
  private microsoftSubmittedAmount: string = '';
  private supplierSubmittedAmount: string = '';  
  private submittedCurrencyCode: string = '';
  private automatedSEIDDisable: boolean = false

  constructor(props) {
    super(props);
    this.state = {
      updatesSupplier: [],
      amountCalc: [],
      items: [],
      showPriorPeriodModal: false,
      adjustShow: false,
      approveDisable: this.props.isLoadingGlobal,
      announcedMessage:undefined
    };
  }

  public render() {
    const {adjustShow} = this.state;
    const {approveDisable,announcedMessage} = this.state;
    return (
      <div style={{ position: 'relative'}}>
        <Panel
          isOpen={this.state.showPriorPeriodModal}
          type={PanelType.smallFixedFar}
          onDismiss={this.closePriorPeriodModal}
          headerText="Review Billable Units"
          closeButtonAriaLabel="Close"
          onRenderFooterContent={this.cancelPriorPeriodShow}
        >
          <div>
            <table>
              <tr>
                <td style = {{width:'30%'}}>
                  <Label className="invoicePeriodLabel">Supplier Engagement ID</Label>
                </td>
                <td>
                  <Label className="invoiceDisplayPeriodLabel">
                    {this.priorPeriodSEID}
                  </Label>
                </td>
              </tr>
              <tr>
                <td style = {{width:'30%'}}>
                  <Label className="invoicePeriodLabel">Microsoft Submitted Units</Label>
                </td>
                <td>
                  <Label className="invoiceDisplayPeriodLabel">
                    {this.microsoftSubmittedUnits}
                  </Label>
                </td>
              </tr>
              <tr>
                <td style = {{width:'30%'}}>
                  <Label className="invoicePeriodLabel">Supplier Submitted Units</Label>
                </td>
                <td>
                  <Label className="invoiceDisplayPeriodLabel">
                    {this.supplierSubmittedUnits}
                  </Label>
                </td>
              </tr>
              <tr>
                <td style = {{width:'30%'}}>
                  <Label className="invoicePeriodLabel">Supplier Comments</Label>
                </td>
                <td>
                  <Label className="invoiceDisplayPeriodLabel">
                    {this.supplierComments}
                  </Label>
                </td>
              </tr>
              <tr>
                <td style = {{width:'30%'}}>
                  <Label className="invoicePeriodLabel">Microsoft Submitted Amount</Label>
                </td>
                <td>
                  <Label className="invoiceDisplayPeriodLabel">
                    {this.microsoftSubmittedAmount + ' ' + this.submittedCurrencyCode}
                  </Label>
                </td>
              </tr>
              <tr>
                <td style = {{width:'30%'}}>
                  <Label className="invoicePeriodLabel">Supplier Submitted Amount</Label>
                </td>
                <td>
                  <Label className="invoiceDisplayPeriodLabel">
                    {this.supplierSubmittedAmount + ' ' + this.submittedCurrencyCode}
                  </Label>
                </td>
              </tr>
            </table>
              <PrimaryButton
                data-automation-id="acceptBtn"
                role="button"
                text="Accept"
                disabled= {approveDisable}
                onClick={() => this.onClickAccept(this.selectedItem)}
                style={{ margin: '10px 10px 10px 0px' }}
              />
            <ActionButton
                  onClick={this.adjustPriorPeriodShow}
                  iconProps={adjustShow? { iconName: 'DoubleChevronUp12' }: { iconName: 'DoubleChevronDown12' }}
                  role="button"
                  disabled={this.props.isLoadingGlobal}
                >
                 Adjust
                </ActionButton>
            { adjustShow
              ?<div style={{width:'80%'}}>
                <h4 style={{ width: '300px', margin: '10px 0px', fontSize: '17px' }}>Prior Period Adjustment</h4>
                <Label className="invoicePeriodLabel">Post Period</Label>
                <Label className="invoiceDisplayPeriodLabel">
                  {this.priorPeriodPostPeriod}
                </Label>
                <Label className="invoicePeriodLabel">Invoice Period</Label>
                <Label className="invoiceDisplayPeriodLabel">
                  {this.showCurrentInvoicePeriod()}
                </Label>
                <TextField
                  className="textField"
                  label="Billable Units"
                  id="billableUnits"
                  type="number"
                  autoComplete="off"
                  name="billableUnits"
                  onChange={this.onBillableUnitsChange}
                  placeholder={this.priorPeriodBillableUnits.toString()}
                  styles={{ fieldGroup: { margin: '5px 0px 0px 0px' } }}
                />
                  <PrimaryButton
                            data-automation-id="submitBtn"
                            role="button"
                            text="Adjust"
                            disabled={this.props.isLoadingGlobal}
                            onClick={() => this.onClickSubmitPriorPeriodAdjustment()}
                            style={{ margin: '10px 0px' }}
                  />
              </div>
              :null
            }
          </div>
        </Panel>
        {this.props.isEditable
          ? this.showBillableUnitsDetails()
          : this.showInvoiceDetails()}
                {announcedMessage ? <Announced message={announcedMessage} />: undefined}
      </div>
    );
  }

  private adjustPriorPeriodShow = () => {
    if(this.state.adjustShow === true){
      this.setState({ adjustShow: false });
      this.setState({ approveDisable: false });
    }
    else{
      this.setState({ adjustShow: true });
      this.setState({ approveDisable: true });
    }
  };

  private cancelPriorPeriodShow = () => {
    return(
      // <div style ={{position: 'fixed',bottom: '10px',right: '10px'}}>
      //<div style={{float: 'right'}}>
      <div className = "priorPeriodButtonGroup">
        <DefaultButton
          data-automation-id="cancelBtn"
          text="Cancel"
          onClick={this.closePriorPeriodModal}
          // style={{ margin: '14px' }}
        />
      </div>
    )
  };

  // Function to render the Billable Units detailslist
  private showBillableUnitsDetails = () => {
    if (this.props.showInvoiceLoader) {
      return (
        <div
          style={{
            ...CardStyle,
            overflow: 'scroll',
            width: '100%',
            height: '70vh',
          }}
        >
          <Fabric
            style={{
              width: '100%',
              height: '70vh',
              display: 'block',
              margin: '1vh 2vw 0vh 3vw',
            }}
          >
            <div style={{ paddingTop: '25vh' }}>
              <Spinner label="Loading..." size={SpinnerSize.large} />
            </div>
          </Fabric>
        </div>
      );
    }
    if (this.props.billableUnit.length > 0) {
      if (this.props.showErrorMessage) {
        return (
          <div style={{ paddingTop: '25vh', marginLeft: '18vw' }}>
            <h1>Supplier Details not available for this month.</h1>
          </div>
        );
      } else {
        return (
          <ScrollablePane
            scrollbarVisibility={ScrollbarVisibility.auto}
            style={{
              backgroundColor: 'white',
              width: '100%',
              height: '70vh',
              ...CardStyle,
              overflowX: 'hidden',
              marginBottom: '2vh',
            }}
          >
            <DetailsList
              items={this.createStateItemsList()}
              columns={this.getColumns()}
              groupProps={{
                showEmptyGroups: true,
              }}
              className="detailsListStyle"
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.fixedColumns}
              enterModalSelectionOnTouch={true}
              onColumnHeaderClick={this._onColumnClick}
              ariaLabelForSelectionColumn="Toggle selection of Suppliers"
              onRenderDetailsHeader={this._onRenderDetailsHeader}
              constrainMode={ConstrainMode.unconstrained}
              onRenderRow={this.renderRow}
              onShouldVirtualize={() => false}
              ariaLabel="DetailsList"
              ariaLabelForGrid="Billable Units List"
              styles={{
                root: {
                  backgroundColor: 'white',
                  width: '0vw',
                  height: '70vh',
                  ...CardStyle,
                },
              }}
              columnReorderOptions={this.getColumnReorderOptions()}
            />
          </ScrollablePane>
        );
      }
    }
  };

  // Gets column reorder options, and handles the column reordering.
  private getColumnReorderOptions(): IColumnReorderOptions {
    const frozenColumns: number = 4;
    return {
      frozenColumnCountFromStart: frozenColumns,
      frozenColumnCountFromEnd: 0,
      handleColumnReorder: (draggedIndex: number, targetIndex: number) => {
        this.props.onColumnReorder(frozenColumns, draggedIndex, targetIndex);
      }
    };
  }

  // Function for sticky header
  private _onRenderDetailsHeader(
    props: IDetailsHeaderProps,
    defaultRender?: IRenderFunction<IDetailsHeaderProps>
  ): JSX.Element {
    return (
      <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
        {defaultRender!({
          ...props,
        })}
      </Sticky>
    );
  }

  // Function to render row for the DetailsList
  private renderRow = (props: IDetailsRowProps): JSX.Element => {
      return <DetailsRow {...props} />;    
  };

  // Function for sorting on Column header click
  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    this.props.isColumnSortedDetails(true);
    const newColumns: IColumn[] = this.columnList.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        if (this.props.isDisplayButtonClicked) {
          if (this.additionalAttributesAdded === true) {
            if (currColumn.isSortedDescending === undefined) {
              currColumn.isSortedDescending = this.isSortedDescending;
              this.isSortedDescending = !this.isSortedDescending;
            }
          }
        } else {
          currColumn.isSortedDescending = this.isSortedDescending;
          this.isSortedDescending = !this.isSortedDescending;
        }
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
      this.setState({
        announcedMessage: `${currColumn.name} is sorted ${
          currColumn.isSortedDescending ? 'descending' : 'ascending'
        }`
      })
    });
    const newItems = this._copyAndSort(
      this.changedItems,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    this.setState({
      items: newItems,
      announcedMessage: `${currColumn.name} is sorted ${
        currColumn.isSortedDescending ? 'descending' : 'ascending'
      }`,
    });
  };

  // Function for sorting the data
  private _copyAndSort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const key = columnKey as keyof T;
    return items
      .slice(0)
      .sort((a: T, b: T) => {
        return (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1;
      });
  }

  private showInvoiceDetails = () => {
    if (
      (this.props.showInvoiceViewLoader &&
        this.props.showAccrualInitialLoader) ||
      this.props.isFetchingInvoiceVariance
    ) {
      return (
        <div
          style={{
            ...CardStyle,
            overflow: 'scroll',
            width: '100%',
            height: '57vh',
          }}
        >
          <Fabric
            style={{
              width: '100%',
              height: '57vh',
              display: 'block',
              margin: '1vh 2vw 0vh 3vw',
            }}
          >
            <div style={{ paddingTop: '25vh' }}>
              <Spinner label="Loading..." size={SpinnerSize.large} />
            </div>
          </Fabric>
        </div>
      );
    }
    if (this.props.showInvoiceErrorMessage) {
      return (
        <div style={{ paddingTop: '25vh', marginLeft: '18vw' }}>
          <h1>Invoice Details not available for this month.</h1>
        </div>
      );
    } else {
      return (
        <ScrollablePane
          scrollbarVisibility={ScrollbarVisibility.auto}
          style={{
            backgroundColor: 'white',
            width: '100%',
            height: '57vh',
            ...CardStyle,
            overflowX: 'hidden',
            marginBottom: '2vh',
          }}
        >
          <DetailsList
            items={this.createStateItemsList()}
            columns={this.getColumns()}
            groupProps={{
              showEmptyGroups: true,
            }}
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.fixedColumns}
            enterModalSelectionOnTouch={true}
            ariaLabelForSelectionColumn="Toggle selection of Suppliers"
            ariaLabelForGrid="Supplier Invoice Details List"
            onColumnHeaderClick={this._onColumnClick}
            // onRenderDetailsHeader={this._onRenderDetailsHeader}
            constrainMode={ConstrainMode.unconstrained}
            onShouldVirtualize={() => false}
            styles={{
              root: {
                backgroundColor: 'white',
                width: '0vw',
                height: '57vh',
                ...CardStyle,
              },
            }}
          />
        </ScrollablePane>
      );
    }
  };

  // Function to populate the Billable Units Detailslist
  private createStateItemsList(): IInvoiceBillableUnitDetails[] {
    let volumeList = [];
    let nrcList = [];
    let ppaList = [];
    var filteredData: IInvoiceBillableUnitDetails[];
    filteredData = this.props.billableUnit;
    if (!this.props.isDisplayButtonClicked && !this.props.isEditable)
      this.changedItems = this.props.isColumnSorted
        ? this.state.items
        : filteredData;
    else {
      if (this.props.stateSelected === 'Volume') {
        volumeList = filteredData.filter((x) => {
          if (x.IsNRC === false) {
            return x;
          } else {
            return null;
          }
        });
        this.volumeData = volumeList.filter(
          (x) => x.IsPriorPeriodAdjustment === false
        );
        if (this.props.isEditable) this.props.getData(this.volumeData);
        this.changedItems = this.props.isColumnSorted
          ? this.state.items
          : volumeList;
      }
      if (this.props.stateSelected === 'NRC') {
        nrcList = filteredData.filter((x) => {
            if (x.IsNRC && !x.IsPriorPeriodAdjustment) {
              return x;
            } else {
              return null;
            }
        });
        this.nrcData = nrcList;
        if (this.props.isEditable) this.props.getData(this.nrcData);
        this.changedItems = this.props.isColumnSorted
          ? this.state.items
          : nrcList;
      }
      if (this.props.stateSelected === 'PPA') {
        ppaList = filteredData.filter((x) => {
          if (x.IsPriorPeriodAdjustment === true) {
            return x;
          } else {
            return null;
          }
        });
        if (this.props.isEditable) this.props.getData(ppaList);
        this.changedItems = this.props.isColumnSorted
          ? this.state.items
          : ppaList;
      }
      if (this.props.stateSelected === 'All') {
        this.allData = filteredData.filter((x) => x.IsEditable === true);
        if (this.props.isEditable) {
          this.props.getData(this.allData);
        }
        this.changedItems = this.props.isColumnSorted
          ? this.state.items
          : this.props.billableUnit;
      }
    }
    return this.changedItems;
  }

  // Function to set the columns for Billable units, Invoice view - Supplier variance and Actuals / Accruals
  private getColumns = () => {
    let columns: IColumn[] = [];
    let isAcceptRecords: boolean = false;

    if (!this.props.isDisplayButtonClicked && !this.props.isEditable) {
      columns = [...this.props.columns];
      columns.map((x) => {
        if (x.key === 'Details') {
          x.onRender = this.renderDetails;
        }
        if (x.key === 'SupplierName') {
          x.onRender = this.renderSupplierName;
        }
        if (x.key === 'ActualGrossAmount') {
          x.onRender = this.renderActualGrossAmount;
        }
        if (x.key === 'AccrualGrossAmount') {
          x.onRender = this.renderAccrualGrossAmount;
        }
        if (x.key === 'Grossvariance') {
          x.onRender = this.renderGrossVariance;
        }
        if (x.key === 'ActualNetAmount') {
          x.onRender = this.renderActualNetAmount;
        }
        if (x.key === 'AccrualNetAmount') {
          x.onRender = this.renderAccrualNetAmount;
        }
        if (x.key === 'ActualGrossAmountCD') {
          x.onRender = this.renderActualGrossAmountCD;
        }
        if (x.key === 'AccrualGrossAmountCD') {
          x.onRender = this.renderAccrualGrossAmountCD;
        }
        if (x.key === 'GrossvarianceCD') {
          x.onRender = this.renderGrossVarianceCD;
        }
        if (x.key === 'GrossVariancePercentCD') {
          x.onRender = this.renderGrossVariancePercentCD;
        }
        if (x.key === 'GrossVariancePercent') {
          x.onRender = this.renderGrossVariancePercent;
        }
        return null;
      });
    } else {
      // Adding to the private columns based on any additional attributes selected by the user.
      this.props.additionalAttributesToDisplay.forEach((attr) => {
        columns.push({
          key: attr.name,
          name: attr.displayName,
          fieldName: attr.name,
          minWidth: 50,
          maxWidth: 100,
          isResizable: true,
          isPadded: true,
          isSorted: true,
          isSortedDescending: false,
          isRowHeader: true,
          sortAscendingAriaLabel: 'Ascending and select to sort',
          sortDescendingAriaLabel: 'Descending and select to sort',
        });
      });
      this.additionalAttributesAdded = true;

      // Combining with the fixed column list.
      columns = this.props.columns.concat(columns);

      if (this.props.isEditable) {
        if (this.props.billableUnit !== undefined) {
          for (let i = 0; i < this.props.billableUnit.length; i++) {
            if (this.props.billableUnit[i].NeedsMicrosoftAcceptance) {
              isAcceptRecords = true;
              break;
            }
          }
          if (isAcceptRecords === false) {
            columns.splice(1, 1);
          }
        }
      } else {
        for (let i = 0; i < columns.length; i++) {
          if (columns[i].key === 'State') {
            columns.splice(i, 1);
          }
        }
        columns.splice(0, 1);
      }

      // Columns onRender for Billable units
      columns.map((x) => {
        if (x.key === 'State') {
          x.onRender = this.renderState;
        }
        if (x.key === 'Accept') {
          x.onRender = this.renderAcceptButton;
        }
        if (x.key === 'SupplierEngagementId') {
          x.onRender = this.renderSupplierEngagement;
        }
        if (x.key === 'BillableUnits') {
          x.onRender = this.renderTextFieldUnitEntry;
        }
        if (x.key === 'PricingModel') {
          x.onRender = this.pricingModel;
        }
        if (x.key === 'PricingType') {
          x.onRender = this.pricingType;
        }
        if (x.key === 'Level') {
          x.onRender = this.renderPricingLevels;
        }
        if (x.key === 'Price') {
          x.onRender = this.renderLevelPrices;
        }
        if (x.key === 'TotalAmount') {
          x.onRender = this.calculateAmount;
        }

        return null;
      });
    }

    this.columnList = columns;

    return columns;
  };

  // Function that renders unit ranges for each price level.
  private renderPricingLevels = (
    item: IInvoiceBillableUnitDetails
  ): JSX.Element => {
    if (item.IsNRC === false && !isNullOrUndefined(item.PriceLine)) {
      const pricingType = item.PriceLine.map((x) => {
        return x.PricingType;
      });
      if (pricingType[0] !== 'Simple') {
        return (
          <Stack>
            {item.PriceLine.map((lvl) => {
              return lvl.VolumeMax === 0 ? (
                <span key={lvl.Index}>{` > ${lvl.VolumeMin}`}</span>
              ) : (
                <span
                  key={lvl.Index}
                >{`${lvl.VolumeMin} - ${lvl.VolumeMax}`}</span>
              );
            })}
          </Stack>
        );
      }
    }
  };

  private renderSupplierEngagement = (
    item: IInvoiceBillableUnitDetails
  ): JSX.Element => {
    const {loggedinUserAlias} = this.props;      
    return (
      <Stack>
        <span>
          {item.SupplierEngagementId}
          {item.IsPriorPeriodAdjustment === true ? (
            <sup>
              <Icon
                style={{
                  fontSize: '5px',
                  color: 'black',
                  marginLeft: '1px',
                }}
                iconName="Asterisk"
              />
            </sup>
          ) : null}
          {item.IsNRC === true ? (
            <sup>
              <Icon
                style={{
                  fontSize: '8px',
                  color: 'black',
                  marginLeft: '1px',
                }}
                iconName="Add"
              />
            </sup>
          ) : null}
          { item.SD1 && loggedinUserAlias ? item.SD1.toLowerCase() === loggedinUserAlias.toLowerCase() ?        
            <Icon
              style={{
                fontSize: '12px',
                color:  'green',
                marginLeft: '1px',
                paddingLeft: '2px',
              }}
              iconName="SquareShapeSolid"
            />
          : null : null}
          { item.SD2 && loggedinUserAlias ? item.SD2.toLowerCase() === loggedinUserAlias.toLowerCase() ?          
              <Icon
                style={{
                  fontSize: '12px',
                  color:  'purple',
                  marginLeft: '1px',
                  paddingLeft: '2px',
                }}
                iconName="SquareShapeSolid"
              />
            : null : null}
          { item.IsAutomationSupplierEngagement === true ? (
            <Icon
              style={{
                fontSize: '12px',
                color:  '#c17e06',
                marginLeft: '1px',
                paddingLeft: '2px',
              }}
              iconName="SquareShapeSolid"
            />
          ) : null}
        </span>
      </Stack>
    );
  };

  // Function to calculate the amount for different pricing types
  private calculateAmount = (
    item: IInvoiceBillableUnitDetails,
    index: number
  ): JSX.Element => {
    if (this.props.isEditable) {
      if (!item.IsNRC) {
        return (
          <Stack>
            {this.props.isColumnSorted
              ? this.state.items[index].TotalAmount.toFixed(2).replace(
                  /(\d)(?=(\d\d\d)+(?!\d))/g,
                  '$1,'
                )
              : item.TotalAmount.toFixed(2).replace(
                  /(\d)(?=(\d\d\d)+(?!\d))/g,
                  '$1,'
                )}
          </Stack>
        );
      } else {
        return (
          <span>
            {item.TotalAmount.toFixed(2).replace(
              /(\d)(?=(\d\d\d)+(?!\d))/g,
              '$1,'
            )}
          </span>
        );
      }
    } else {
      return (
        <span>
          {item && !isNullOrUndefined(item.TotalAmount) && item.TotalAmount.toFixed(2).replace(
            /(\d)(?=(\d\d\d)+(?!\d))/g,
            '$1,'
          )}
        </span>
      );
    }
  };

  // Function to render the pricing model
  private pricingModel = (item: IInvoiceBillableUnitDetails): JSX.Element => {
    return <Stack>{item.PricingModel}</Stack>;
  };

  // Function to render the pricing type
  private pricingType = (item: IInvoiceBillableUnitDetails): JSX.Element => {
    if (item.IsNRC === false && !isNullOrUndefined(item.PriceLine)) {
      return (
        <Stack>
          {item.PriceLine.map((lvl) => {
            return <span key={lvl.Index}>{`${lvl.PricingType}`}</span>;
          })}
        </Stack>
      );
    } else {
      return <span />;
    }
  };

  // Function that renders the prices for each level.
  private renderLevelPrices = (
    item: IInvoiceBillableUnitDetails
  ): JSX.Element => {
    if (!isNullOrUndefined(item.PriceLine)) {
      return (
        <Stack>
          {item.PriceLine.map((lvl) => {
            return (
              <span key={lvl.Index}>{`${lvl.Price.toFixed(2).replace(
                /(\d)(?=(\d\d\d)+(?!\d))/g,
                '$1,'
              )}`}</span>
            );
          })}
        </Stack>
      );
    }
  };

  // Function to render Accept button column
  private renderAcceptButton = (
    item: IInvoiceBillableUnitDetails
  ): JSX.Element => {
    if (item.NeedsMicrosoftAcceptance) {
      return (
        <Stack>
          <TooltipHost
            content={'Review'}
            calloutProps={{ gapSpace: 0 }}
            styles={{ root: { display: 'inline-block' } }}
            directionalHint={DirectionalHint.bottomCenter}
          >
            <IconButton
              className="acceptRejectIcon"
              onClick={() => this.showPriorPeriodModal(item)}
              disabled={this.props.isLoadingGlobal}
              iconProps={{ iconName: 'Edit' }}
              ariaLabel="Review"
            />
          </TooltipHost>
        </Stack>
      );
    }
  };

  // Function to set the state to show the prior period modal
  private showPriorPeriodModal = (item: IInvoiceBillableUnitDetails) => {
    this.setState({ showPriorPeriodModal: true });
    this.priorPeriodSEID = item.SupplierEngagementId;
    this.priorPeriodPostPeriod = item.PostInvoicePeriod;
    this.priorPeriodPostCumMonth = item.PostCumMonth;
    this.isNrc = item.IsNRC;
    this.nrcId = item.NRCId;
    const { previousMonthMicrosoftSubmittedData } = this.props;
    for (let i = 0; i < previousMonthMicrosoftSubmittedData.length; i++) {
      if (
        item.SupplierEngagementId === previousMonthMicrosoftSubmittedData[i].SupplierEngagementId &&
        item.CumMonth === previousMonthMicrosoftSubmittedData[i].CumMonth &&
        item.PostCumMonth === previousMonthMicrosoftSubmittedData[i].PostCumMonth &&
        item.IsNRC === false &&
        item.IsNRC === previousMonthMicrosoftSubmittedData[i].IsNrc
      ) {
        this.priorPeriodBillableUnits = previousMonthMicrosoftSubmittedData[i].MicrosoftUnits;
        this.microsoftSubmittedUnits = previousMonthMicrosoftSubmittedData[i].MicrosoftUnits;
        this.supplierSubmittedUnits = item.BillableUnits;
        this.supplierComments = item.Comment;
        this.selectedItem = item;
        this.supplierSubmittedAmount = item.TotalAmount.toFixed(2);
        this.submittedCurrencyCode = item.CurrencyCode;
        this.microsoftSubmittedAmount = this.calculateMicrosoftSubmittedAmount(item,previousMonthMicrosoftSubmittedData[i].MicrosoftUnits)
      }
      else if (
        item.SupplierEngagementId === previousMonthMicrosoftSubmittedData[i].SupplierEngagementId &&
        item.CumMonth === previousMonthMicrosoftSubmittedData[i].CumMonth &&
        item.PostCumMonth === previousMonthMicrosoftSubmittedData[i].PostCumMonth &&
        item.IsNRC === true &&
        item.NRCId === previousMonthMicrosoftSubmittedData[i].NRCId
      ) {
        this.priorPeriodBillableUnits = previousMonthMicrosoftSubmittedData[i].MicrosoftUnits;
        this.microsoftSubmittedUnits = previousMonthMicrosoftSubmittedData[i].MicrosoftUnits;
        this.supplierSubmittedUnits = item.BillableUnits;
        this.supplierComments = item.Comment;
        this.selectedItem = item;
        this.supplierSubmittedAmount = item.TotalAmount.toFixed(2);
        this.submittedCurrencyCode = item.CurrencyCode;        
        this.microsoftSubmittedAmount = this.calculateMicrosoftSubmittedAmount(item,previousMonthMicrosoftSubmittedData[i].MicrosoftUnits)
        }
    }
  };

  // This method will calcute the totalamount based on billable units passed
  private calculateMicrosoftSubmittedAmount = (item: IInvoiceBillableUnitDetails, microsoftUnits: number) => {
    let totalAmount = 0.0;                    
    let unitsDifference = microsoftUnits;
    if (unitsDifference < 0) {
      unitsDifference = -unitsDifference;
    }
    if (!item.IsNRC)
    {
        let billableUnits = microsoftUnits;
        if (billableUnits < 0) {
            billableUnits = -billableUnits;
        }
        item.PriceLine.forEach(x =>
        {
            if (x.PricingType === 'Simple')
            {
                x.PriceLineBillableUnits= billableUnits;
            }
            else if (x.PricingType === 'Banded')
            {                  
                x.PriceLineBillableUnits = this.GetBillableUnitsForPricingTypeBanded(x.VolumeMin, x.VolumeMax, billableUnits);                         
            }
            else if (x.PricingType === 'Tiered')
            {
                x.PriceLineBillableUnits = this.GetBillableUnitsForPricingTypeTiered(x.VolumeMax, x.PricingLevel, unitsDifference);
            }
            x.PriceLineAmount = this.CalculateVolumePaymentLC(x.PriceLineBillableUnits, x.Price);
            totalAmount += x.PriceLineAmount;            
        });
    }
    else
    {
        totalAmount = microsoftUnits;
        if (totalAmount < 0) {
            totalAmount = -totalAmount;
        }
    }
    return totalAmount.toFixed(2);
  }

  private GetBillableUnitsForPricingTypeBanded = (volumeMin, volumeMax, billableUnits) =>
    {
        let billableUnitsPerLevel = 0;
        if (volumeMax !== 0)
        {
            if (billableUnits >= volumeMin && billableUnits <= volumeMax)
            {
                billableUnitsPerLevel = billableUnits;
            }
            else if (billableUnits > volumeMax || billableUnits < volumeMin)
            {
                billableUnitsPerLevel = 0;
            }
        }
        else
        {
            if (billableUnits >= volumeMin)
            {
                billableUnitsPerLevel = billableUnits;
            }
            else if (billableUnits < volumeMin)
            {
                billableUnitsPerLevel = 0;
            }
        }
        return billableUnitsPerLevel;
    }

    private GetBillableUnitsForPricingTypeTiered = (volumeMax, pricingLevel, unitsDifference) =>
    {
      let actualUnits;
      if (pricingLevel === 1)
      {
          if (unitsDifference > 0)
          {
              const newUnitsDifference = unitsDifference - volumeMax;
              if (newUnitsDifference >= 0)
              {
                  actualUnits = volumeMax;
                  unitsDifference = newUnitsDifference;
              }
              else
              {
                  actualUnits = unitsDifference;
                  unitsDifference = 0.0;
              }
          }
          else
          {
              actualUnits = 0.0;
              unitsDifference = 0.0;
          }
      }
      else
      {
          if (volumeMax !== 0)
          {
              const newUnitsDifference = unitsDifference - volumeMax;
              if (newUnitsDifference < 0)
              {
                  actualUnits = unitsDifference;
                  unitsDifference = 0.0;
              }
              else
              {
                  actualUnits = volumeMax;
                  unitsDifference = newUnitsDifference;
              }
          }
          else
          {
              if (unitsDifference > 0)
              {
                  actualUnits = unitsDifference;
              }
              else
              {
                  actualUnits = 0.0;
              }
          }
      }
      return actualUnits;
  }

  private CalculateVolumePaymentLC = (billableUnits, basePrice) =>
  {
      return billableUnits * basePrice;
  }

  // Function to close the prior period modal
  private closePriorPeriodModal = () => {
    this.setState({ showPriorPeriodModal: false });
    this.setState({ adjustShow: false });
    this.setState({ approveDisable: false });
  };

  // Function to display the current invoice period in prior period adjustment
  private showCurrentInvoicePeriod = () => {
    const { monthList } = this.props;
    const currentMonth = monthList.filter(
      (x) => x.IsCurrentInvoiceMonth === true
    );
    const currentCumMonth = currentMonth.map((x) => {
      return x.CumMonth;
    });
    for (let i = 0; i < this.props.priorPeriod.length; i++) {
      if (Number(currentCumMonth[0]) === this.props.priorPeriod[i].CumMonth) {
        return <span>{this.props.priorPeriod[i].PeriodDetail}</span>;
      }
    }
  };

  // Function to store the prior period billable units
  private onBillableUnitsChange = (e, newValue) => {
    this.priorPeriodBillableUnits = Number(newValue);
  };

  // Function to post prior period adjustments
  private onClickSubmitPriorPeriodAdjustment = () => {
    this.props.setGlobalLoadingFlag(true);
    const priorPeriodAdjustmentDetails: IPriorPeriodDetails = {
      supplierEngagementId: 0,
      postCumMonth: 0,
      billableUnits: 0,
      comment: '',
      nrcId: 0,
    };
    priorPeriodAdjustmentDetails.billableUnits = this.priorPeriodBillableUnits;
    priorPeriodAdjustmentDetails.postCumMonth = this.priorPeriodPostCumMonth;
    priorPeriodAdjustmentDetails.supplierEngagementId = this.priorPeriodSEID;
    if (this.isNrc) {
      priorPeriodAdjustmentDetails.nrcId = this.nrcId;
    } else {
      priorPeriodAdjustmentDetails.nrcId = null;
    }
    this.props.postPriorPeriodAdjustmentDetails(
      priorPeriodAdjustmentDetails,
      this.props.selectedMonth.CumMonth,
      this.selectedFiscalYear
    );
    this.setState({ showPriorPeriodModal: false });
    this.setState({ adjustShow: false });
    this.setState({ approveDisable: false });
    this.props.onButtonClick(false);
  };


  private renderState = (item) => {
    if (item.StateId === 1) {
      return (
        <sup>
          <TooltipHost
            content="New"
            calloutProps={{ gapSpace: 0 }}
            styles={{ root: { display: 'inline-block' } }}
            aria-label ="New"
          >
            <Icon
              style={{
                fontSize: '20px',
                color: '#0178D4',
                marginLeft: '1px',
              }}
              tabIndex={0}
              iconName="AlertSolid"
              aria-label='New'
            />
          </TooltipHost>
        </sup>
      );
    } else if (item.StateId === 2) {
      return (
        <sup>
          <TooltipHost
            content="Rejected"
            calloutProps={{ gapSpace: 0 }}
            styles={{ root: { display: 'inline-block' } }}
            aria-label ="Rejected"
          >
            <Icon
              style={{
                fontSize: '20px',
                color: 'red',
                marginLeft: '1px',
              }}
              tabIndex={0}
              iconName="StatusErrorFull"
              aria-label ="Rejected"
            />
          </TooltipHost>
        </sup>
      );
    } else if (item.StateId === 3) {
      return (
        <sup>
          <TooltipHost
            content="Pending Supplier"
            calloutProps={{ gapSpace: 0 }}
            styles={{ root: { display: 'inline-block' } }}
            aria-label ="Pending Supplier"
          >
            <Icon
              style={{
                fontSize: '20px',
                color: 'black',
                marginLeft: '1px',
              }}
              tabIndex={0}
              iconName="People"
              aria-label ="Pending Supplier"
            />
          </TooltipHost>
        </sup>
      );
    } else if (item.StateId === 4) {
      return (
        <sup>
          <TooltipHost
            content="Pending Microsoft"
            calloutProps={{ gapSpace: 0 }}
            styles={{ root: { display: 'inline-block' } }}
            aria-label ="Pending Microsoft"
          >
            <Icon
              style={{
                fontSize: '20px',
                color: '#FFBC02',
                marginLeft: '1px',
              }}
              tabIndex={0}
              iconName="AwayStatus"
              aria-label ="Pending Microsoft"
            />
          </TooltipHost>
        </sup>
      );
    } else if (item.StateId === 5) {
      return (
        <sup>
          <TooltipHost
            content="Accepted"
            calloutProps={{ gapSpace: 0 }}
            styles={{ root: { display: 'inline-block' } }}
            aria-label ="Accepted"
          >
            <Icon
              style={{
                fontSize: '20px',
                color: 'green',
                marginLeft: '1px',
              }}
              tabIndex={0}
              iconName="CompletedSolid"
              aria-label ="Accepted"
            />
          </TooltipHost>
        </sup>
      );
    }
  };

  //Function to post the modifications
  private onClickAccept = (item) => {    
    this.changedRow.billableUnits = item.BillableUnits;
    this.changedRow.cumMonth = item.CumMonth;
    this.changedRow.fiscalYearNumber = item.FiscalYearNumber;
    this.changedRow.postCumMonth = item.PostCumMonth;
    this.changedRow.supplierEngagementId = item.SupplierEngagementId;
    if (item.IsNRC) {
      this.changedRow.nrcId = item.NRCId;
    } else {
      this.changedRow.nrcId = null;
    }

    const fiscalYear = this.changedRow.fiscalYearNumber;
    this.props.setGlobalLoadingFlag(true);
    this.props.acceptModification(
      this.changedRow,
      this.props.selectedMonth.CumMonth,
      fiscalYear
    );
    this.setState({ showPriorPeriodModal: false });
    this.setState({ adjustShow: false });
    this.setState({ approveDisable: false });
    this.props.onButtonClick(false);
  };

  // Function to render the units/heads
  private renderTextFieldUnitEntry = (
    item: IInvoiceBillableUnitDetails
  ): JSX.Element => {    
    if(item.IsAutomationSupplierEngagement && !this.props.isAutomatedSEIDsFiltered && !item.IsNRC)
    {
        this.automatedSEIDDisable = true
    }
    else {
        this.automatedSEIDDisable = false
    }
    if (item.IsEditable) {
      return (
        <div>
          <TextField
            value={item.BillableUnits.toString()}
            type="number"
            min={0}
            ariaLabel={
              'Enter Units for SEID ' + item.SupplierEngagementId.toString()
            }          
            tabIndex={0}
            title='Units for SEID'
            onChange={(e, newValue) =>
              this.handleTextFieldChange(item, newValue)
            }
            role="presentation"
            onBlur={(e) => this.fixDecimalPlace(item, e)}
            disabled = {this.automatedSEIDDisable}
          />
        </div>
      );
    } else {
      if (isNullOrUndefined(item.BillableUnits)) {
        return <span />;
      } else {
        return <span>{item.BillableUnits.toString()}</span>;
      }
    }
  };

  //changing decimal places to 2 
  private fixDecimalPlace (item, e){
    let newUnitWith2Decimal = parseFloat(e.target.value).toFixed(2)
    this.handleTextFieldChange(item,newUnitWith2Decimal)
  }
  
  // Handles text field changes.
  private handleTextFieldChange = (
    item: IInvoiceBillableUnitDetails,
    newHead
  ) => {
    let billableUnitData: IInvoiceBillableUnitDetails[] = [];
    if (this.props.stateSelected === 'All') {
      billableUnitData = this.allData;
    }
    if (this.props.stateSelected === 'NRC') {
      billableUnitData = this.nrcData;
    }
    if (this.props.stateSelected === 'Volume') {
      billableUnitData = this.volumeData;
    }
    item.BillableUnits = newHead;
    const indexChange = billableUnitData.findIndex(
      (y) =>
        y.SupplierEngagementId === item.SupplierEngagementId &&
        y.NRCId === item.NRCId &&
        y.PostCumMonth === item.PostCumMonth &&
        y.CumMonth === item.CumMonth
    );
    const changedInvoiceDetails: IInvoiceHeadCountFilterDetails[] = [];
    billableUnitData[indexChange].BillableUnits = item.BillableUnits;
    billableUnitData[indexChange].PostCumMonth = item.PostCumMonth;
    for (let i = 0; i < billableUnitData.length; i++) {
      changedInvoiceDetails.push({
        SupplierEngagementId: billableUnitData[i].SupplierEngagementId,
        FiscalYearNumber: billableUnitData[i].FiscalYearNumber,
        CumMonth: billableUnitData[i].CumMonth,
        PostCumMonth: billableUnitData[i].PostCumMonth,
        BillableUnits: Number(billableUnitData[i].BillableUnits),
        IsVolume: billableUnitData[i].IsVolume,
        IsPriorPeriodAdjustment: billableUnitData[i].IsPriorPeriodAdjustment,
        NRCId: billableUnitData[i].NRCId,
      });
    }
    this.setState({ updatesSupplier: billableUnitData });
    this.props.updatedItems(changedInvoiceDetails);
    this.props.amountCalculationHeadCount(
      this.props.billableUnit,
      this.props.isFilter ? true : false
    );
    this.props.setBillableUnitsChange(true);
  };

  //Function to display button (drillThrough) on the Invoice Supplier Variance grid
  private renderDetails = (item: ISupplierInvoieVariance) => {
    return (
      <TooltipHost content="Click to view Supplier details">
        <IconButton
          onClick={() => this.displayActualAccrualData(item)}
          iconProps={{ iconName: 'drillthrough' }}
          aria-label={`View Supplier Details of ${item.SupplierName} ( ${item.SupplierNumber} ) - ${item.CompanyCode} `}
        />
      </TooltipHost>
    );
  };

  //Function to display the supplier in Invoice Supplier variance grid
  private renderSupplierName = (item: ISupplierInvoieVariance) => {
    return (
      <span>{`${item.SupplierName} ( ${item.SupplierNumber} ) - ${item.CompanyCode}`}</span>
    );
  };

  //Function to handle on details button click in Invoice Suppler variance grid
  private displayActualAccrualData = (item: ISupplierInvoieVariance) => {
    this.props.onShowDetailsColumns();
    this.props.setIsDisplayButtonClicked(true);
    this.props.getSupplierInvoiceState(item.SupplierId, item.CumulativeMonth, item.InvoicePrefix);
    this.props.getBillableUnitsForInvoice(
      item.CumulativeMonth,
      item.SupplierId,
      item.InvoicePrefix,
      item.CompanyCode,
      item.SupplierNumber,
      this.props.isActualOrAccrual
    );
    this.props.getDataForActualAccrualCall(item);
    this.props.isColumnSortedDetails(false);
  };

  //Function to display Actual Gross Amount in Invoice Supplier variance grid
  private renderActualGrossAmount = (item: ISupplierInvoieVariance) => {
    if (item.ActualGrossAmount === null || item.ActualGrossAmount === undefined) {
      return <></>
    }
    return (
      <span>
        {item.ActualGrossAmount.toFixed(2).replace(
          /(\d)(?=(\d\d\d)+(?!\d))/g,
          '$1,'
        )}
      </span>
    );
  };

  //Function to display Accrual Gross Amount in Invoice Supplier variance grid
  private renderAccrualGrossAmount = (item: ISupplierInvoieVariance) => {
    if (item.AccrualGrossAmount === null || item.AccrualGrossAmount === undefined) {
      return <></>
    }
    return (
      <span>
        {item.AccrualGrossAmount.toFixed(2).replace(
          /(\d)(?=(\d\d\d)+(?!\d))/g,
          '$1,'
        )}
      </span>
    );
  };

  //Function to display Gross Variance in Invoice Supplier variance grid
  private renderGrossVariance = (item: ISupplierInvoieVariance) => {
    if (item.Grossvariance === null || item.Grossvariance === undefined) {
      return <></>
    }
    return (
      <span>
        {item.Grossvariance.toFixed(2).replace(
          /(\d)(?=(\d\d\d)+(?!\d))/g,
          '$1,'
        )}
      </span>
    );
  };

  //Function to display Actual Gross Amount CD in Invoice Supplier variance grid
  private renderActualGrossAmountCD = (item: ISupplierInvoieVariance) => {
    if (item.ActualGrossAmountCD === null || item.ActualGrossAmountCD === undefined) {
      return <></>
    }
    return (
      <span>
        {item.ActualGrossAmountCD.toFixed(2).replace(
          /(\d)(?=(\d\d\d)+(?!\d))/g,
          '$1,'
        )}
      </span>
    );
  };

  //Function to display Accrual Gross Amount CD in Invoice Supplier variance grid
  private renderAccrualGrossAmountCD = (item: ISupplierInvoieVariance) => {
    if (item.AccrualGrossAmountCD === null || item.AccrualGrossAmountCD === undefined) {
      return <></>
    }
    return (
      <span>
        {item.AccrualGrossAmountCD.toFixed(2).replace(
          /(\d)(?=(\d\d\d)+(?!\d))/g,
          '$1,'
        )}
      </span>
    );
  };

  //Function to display Gross Variance CD in Invoice Supplier variance grid
  private renderGrossVarianceCD = (item: ISupplierInvoieVariance) => {
    if (item.GrossvarianceCD === null || item.GrossvarianceCD === undefined) {
      return <></>
    }
    return (
      <span>
        {item.GrossvarianceCD.toFixed(2).replace(
          /(\d)(?=(\d\d\d)+(?!\d))/g,
          '$1,'
        )}
      </span>
    );
  };

  //Function to display Actual Net Amount in Invoice Supplier variance grid
  private renderActualNetAmount = (item: ISupplierInvoieVariance) => {
    if (item.ActualNetAmount === null || item.ActualNetAmount === undefined) {
      return <></>
    }
    return (
      <span>
        {item.ActualNetAmount.toFixed(2).replace(
          /(\d)(?=(\d\d\d)+(?!\d))/g,
          '$1,'
        )}
      </span>
    );
  };

  //Function to display Accrual Net Amount in Invoice Supplier variance grid
  private renderAccrualNetAmount = (item: ISupplierInvoieVariance) => {
    if (item.AccrualNetAmount === null || item.AccrualNetAmount === undefined) {
      return <></>
    }
    return (
      <span>
        {item.AccrualNetAmount.toFixed(2).replace(
          /(\d)(?=(\d\d\d)+(?!\d))/g,
          '$1,'
        )}
      </span>
    );
  };

  //Function to display Gross variabce percent CD in Invoice Supplier variance grid
  private renderGrossVariancePercentCD = (item: ISupplierInvoieVariance) => {
    if (item.GrossVariancePercentCD === null || item.GrossVariancePercentCD === undefined) {
      return <></>
    }
    return <span>{`${item.GrossVariancePercentCD} %`}</span>;
  };

  //Function to display Gross variance percent in Invoice Supplier variance grid
  private renderGrossVariancePercent = (item: ISupplierInvoieVariance) => {
    if (item.GrossVariancePercent === null || item.GrossVariancePercent === undefined) {
      return <></>
    }
    return <span>{`${item.GrossVariancePercent} %`}</span>;
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    totalAmountCalculation: (amountTotal: IAmountCalculation[]) => {
      dispatch(totalAmountCalculation(amountTotal));
    },
    setGlobalLoadingFlag: (flag: boolean) => {
      dispatch(setIsLoadingGlobal(flag));
    },
    amountCalculationHeadCount: (
      billableUnits: IInvoiceBillableUnitDetails[],
      isFilter: boolean
    ) => {
      dispatch(amountCalculationHeadCount(billableUnits, isFilter));
    },
    acceptModification: (
      modification: IAcceptModification,
      cumMonth: number,
      fiscalYearNumber: number
    ) => {
      dispatch(acceptModification(modification, cumMonth, fiscalYearNumber));
    },
    accrualInitialLoader: () => {
      dispatch(accrualInitialLoader());
    },
    getBillableUnits: (selectedMonth: any) => {
      dispatch(getBillableUnits(selectedMonth));
    },
    setBillableUnitsChange: (isBillableUnitChange: boolean) => {
      dispatch(setBillableUnitsChange(isBillableUnitChange));
    },
    isColumnSortedDetails: (isColumnSorted: boolean) => {
      dispatch(isColumnSortedDetails(isColumnSorted));
    },
    postPriorPeriodAdjustmentDetails: (
      priorPeriodDetails: IPriorPeriodDetails,
      cumMonth: number,
      fiscalYearNumber: number
    ) => {
      dispatch(
        postPriorPeriodAdjustmentDetails(
          priorPeriodDetails,
          cumMonth,
          fiscalYearNumber
        )
      );
    },
    getBillableUnitsForInvoice: (
      CumMonth: number,
      SupplierId: number,
      invoicePrefix: string,
      CompanyCode: string,
      SupplierNumber: number,
      isAccrualOrActual: boolean
    ) => {
      dispatch(
        getBillableUnitsForInvoice(
          CumMonth,
          SupplierId,
          invoicePrefix,
          CompanyCode,
          SupplierNumber,
          isAccrualOrActual
        )
      );
    },
    setIsDisplayButtonClicked: (isDisplayButtonClicked: boolean) => {
      dispatch(setIsDisplayButtonClicked(isDisplayButtonClicked));
    },
    getSupplierInvoiceState: (SupplierId: number, CumMonth: number, invoicePrefix: string) => {
      dispatch(getSupplierInvoiceState(SupplierId, CumMonth, invoicePrefix));
    },
    getDataForActualAccrualCall: (
      dataForActualAccrualCall: ISupplierInvoieVariance
    ) => {
      dispatch(getDataForActualAccrualCall(dataForActualAccrualCall));
    }
  };
};

const mapStateToProps = (state) => {
  return {
    monthList: state.headCount.monthList,
    priorPeriod: state.headCount.priorPeriod,
    amountTotal: state.headCount.amountTotal,
    showInvoiceLoader: state.headCount.isFetchingInvoice,
    showErrorMessage: state.headCount.IsErrorMessage,
    showInvoiceViewLoader: state.invoiceView.isFetchingAccrualOrActual,
    isFetchingInvoiceVariance: state.invoiceView.isFetchingInvoiceVariance,
    showInvoiceErrorMessage: state.invoiceView.isErrorMessageInvoice,
    showAccrualInitialLoader: state.invoiceView.accrualInitialLoader,
    isLoadingGlobal: state.user.isLoadingGlobal,
    isColumnSorted: state.headCount.isColumnSorted,
    headCountBillableUnits: state.headCount.billableUnits,
    previousMonthMicrosoftSubmittedData: state.headCount.microsoftSubmittedBillableUnits,
    isDisplayButtonClicked: state.invoiceView.isDisplayButtonClicked,
    loggedinUserAlias: state.user.Alias,
    userPreferences: state.user.preferences
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VolumeList);
