import * as React from 'react';
import { TelemetryException } from '../../shared/models';
import telemetryContext from '../../shared/services/TelemetryServices';
import { PrimaryButton } from 'office-ui-fabric-react';
import appConfig from '../../assets/configuration/config';

export interface IErrorBoundaryState {
  hasError: boolean;
  isOkClicked: boolean;
}
// This class is used as a wrapper to catch any errors that may occur on its children.
class AppErrorBoundry extends React.Component<{}, IErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      isOkClicked: false,
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
    };
  }
  // Catch any errors that occured in our application
  public componentDidCatch(error: Error, info: object) {
    // log exceptions for any javascript errors.
    telemetryContext.logException(
      error.toString(),
      TelemetryException.JavascriptError,
      {
        error: error,
        info: info,
      }
    );
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div style={{ textAlign: 'center' }}>
          <p>Sorry, Something went wrong. Please try again later.</p>
          <PrimaryButton text="OK" href={appConfig.GTOvNextUIBaseUrl} />
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

export default AppErrorBoundry;
