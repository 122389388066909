import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import * as React from 'react';
import { Dropdown, IDropdownOption } from '@fluentui/react';
import {
  getDecimalAdjustedStringAmount,
  IBudget,
  ILegend,
  ISupplier,
  TelemetryEvent,
} from '../../../shared/models';
import telemetryContext from '../../../shared/services/TelemetryServices';
import BudgetChart from './BudgetChart';
import './SupplierBudget.css';
import { Redirect } from 'react-router';
import { CommandBarButton } from 'office-ui-fabric-react';
import HelmetTab from "../../../shared/Helmet/Helmet"


// interface for SupplierBudget props
interface ISupplierBudgetProps {
  currentSupplier: ISupplier;
  budgetData: IBudget;
  showLoader: boolean;
  fiscalYearNumbers: number[];
  onFiscalYearChange: any;
  selectedFiscalYear: number;
  netPoAmount: number;
  addAdjustments: boolean;
}

// interface for SupplierBudget states
interface ISupplierBudgetState {
  width: number;
  height: number;
  redirect: IRedirectTypes;
}
// Redirect Types
enum IRedirectTypes {
  None,
  Judgement,
}

export class SupplierBudget extends React.Component<
  ISupplierBudgetProps,
  ISupplierBudgetState
> {
  constructor(props) {
    super(props);

    const currentWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    const currentHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    // Initializing the state.
    this.state = {
      redirect: IRedirectTypes.None,
      height: currentHeight,
      width: currentWidth,
    };
  }
  public componentDidMount() {
    document
      .getElementById('fiscalYearDropdown')
      .removeAttribute('aria-describedby');
    document
      .getElementsByClassName('ms-Dropdown-title')[0]
      .removeAttribute('aria-label');
  }

  public render(): JSX.Element {
    if (this.state.redirect === IRedirectTypes.Judgement) {
      // Calculating path
      const path: string = `/supplier/${this.props.currentSupplier.supplierNumber}/judgements/add`;
      // Return redirect.
      return <Redirect push={true} to={path} />;
    } else {
      // Handling window resize
      window.addEventListener('resize', this.handleWindowResize);
      return <React.Fragment>{this.getBudgetContent()}</React.Fragment>;
    }
  }

  // Returning the supplier budget content
  private getBudgetContent = () => {
    let fiscalDropDownOptions = this.buildFiscalYearDropdown();
    // If showLoader is true then render loading.

    if (this.props.showLoader) {
      return (
        <>
          <div className="flex-container-supplier-budget">
            <div className="flex-container-supplier-budget-1">
            {window.location.pathname ==='/'  && <HelmetTab title={'Supplier'} />}
              <h1
                className="graphHeader"
                style={{
                  paddingTop: '8px',
                  fontWeight: 'bold',
                  fontSize: '1.17em',
                }}
              >
                {`${this.props.currentSupplier.supplierLegalName} ${this.props.currentSupplier.companyCode} (${this.props.currentSupplier.currencyCode}) - ${this.props.currentSupplier.supplierNumber}`}
              </h1>
              <div className="flex-container-supplier-budget-1-item">
                <CommandBarButton
                  data-automation-id="test1"
                  iconProps={{ iconName: 'Add' }}
                  text={
                    this.props.addAdjustments ? 'Adjustments' : 'Judgements'
                  }
                  ariaLabel={this.props.addAdjustments ? 'Add Adjustments' : 'Add Judgements'}
                  role="link"
                  style={{
                    display: 'flex-end',
                    marginRight: '20px',
                    fontSize: '13px',
                    height: '30px',
                  }}
                  onClick={this.handleAddJudgements}
                  // disabled={!this.props.isMyOrderFeatureEnabled}
                />
                <Dropdown
                  ariaLabel="Fiscal Year Dropdown"
                  placeholder="Select an option"
                  id="fiscalYearDropdown"
                  defaultSelectedKey={this.props.selectedFiscalYear}
                  options={fiscalDropDownOptions}
                  onChange={this.handleFiscalYearChange}
                  style={{ marginLeft: '10px' }}
                />
              </div>
            </div>
            <div
              aria-label={'Loading'}
              className="flex-container-supplier-budget-2"
            >
              <Spinner label="Loading..." size={SpinnerSize.large} />
            </div>
          </div>
        </>
      );
    } else if (
      this.props.budgetData != null &&
      this.props.currentSupplier.currencyCode !== 'USD'
    ) {
      // Show tooltip for USD values if currencyCode is non USD.
      return (
        <>
          <div className="flex-container-supplier-budget">
            <div className="flex-container-supplier-budget-1">
              <h1
                className="graphHeader"
                style={{
                  padding: '8px',
                  fontWeight: 'bold',
                  fontSize: '1.17em',
                }}
              >
                {`${this.props.currentSupplier.supplierLegalName} ${this.props.currentSupplier.companyCode} (${this.props.currentSupplier.currencyCode}) - ${this.props.currentSupplier.supplierNumber}`}
              </h1>
              <div className="flex-container-supplier-budget-1-item">
                <CommandBarButton
                  data-automation-id="test1"
                  iconProps={{ iconName: 'Add' }}
                  text=" Judgements"
                  ariaLabel="Add Judgements"
                  role="link"
                  style={{
                    display: 'flex-end',
                    marginRight: '20px',
                    fontSize: '13px',
                    height: '30px',
                  }}
                  onClick={this.handleAddJudgements}
                  // disabled={!this.props.isMyOrderFeatureEnabled}
                />
                <Dropdown
                  ariaLabel="Fiscal Year Dropdown"
                  placeholder="Select an option"
                  id="fiscalYearDropdown"
                  defaultSelectedKey={this.props.selectedFiscalYear}
                  options={fiscalDropDownOptions}
                  onChange={this.handleFiscalYearChange}
                  style={{ marginLeft: '10px' }}
                />
              </div>
            </div>
            <div className="flex-container-supplier-budget-budget-chart">
              <BudgetChart
                aria-label={'Budget graph'}
                legendTypes={this.buildLegend(this.props.budgetData)}
                data={this.buildGraphData(this.props.budgetData)}
                suppliername={this.props.currentSupplier.supplierLegalName}
              />
            </div>
            <div className="flex-container-supplier-budget-3">
              <div>
                <TooltipHost
                  content={`Original Budget (USD):`}
                  id={'Original Budget'}
                  calloutProps={{ gapSpace: 0 }}
                >
                              <label aria-label={'Original Budget'} style={{ margin: '5px', fontSize: '.83em', display: 'block', fontWeight: 'bold', }}>
                    Original Budget{' '}
                  </label>
                </TooltipHost>

                <div>
                  <span style={{ fontWeight: 'normal' }}>
                    {`${getDecimalAdjustedStringAmount(
                      this.props.budgetData.OriginalBudgetLC
                    )}`}
                  </span>
                </div>
                <div>
                  <span style={{ fontWeight: 'normal' }}>
                    {`($${getDecimalAdjustedStringAmount(
                      this.props.budgetData.OriginalBudgetCD
                    )})`}
                  </span>
                </div>
              </div>
              <div>
                <TooltipHost
                  content={`Budget After Judgements  (USD):`}
                  id={'Budget After Judgements '}
                  calloutProps={{ gapSpace: 0 }}
                >
                  <label
                    aria-label={'Budget After Judgement'}
                    style={{ margin: '5px',fontSize: '.83em', display: 'block', fontWeight: 'bold', }}
                  >
                    Budget After Judgement{' '}
                  </label>
                </TooltipHost>

                <div>
                  <span style={{ fontWeight: 'normal' }}>
                    {`${getDecimalAdjustedStringAmount(
                      this.props.budgetData.BudgetAfterJudgementLC
                    )}`}
                  </span>
                </div>
                <div>
                  <span style={{ fontWeight: 'normal' }}>
                    {`($${getDecimalAdjustedStringAmount(
                      this.props.budgetData.BudgetAfterJudgementCD
                    )})`}
                  </span>
                </div>
              </div>
              <div>
                <TooltipHost
                  content={`Original PO Amount (USD):`}
                  id={'Original PO Amount '}
                  calloutProps={{ gapSpace: 0 }}
                >
                  <label
                    aria-label={'Original PO Amount '}
                    style={{ margin: '5px',fontSize: '.83em', display: 'block', fontWeight: 'bold',  }}
                  >
                    Original PO Amount{' '}
                  </label>
                </TooltipHost>

                <div>
                  <span style={{ fontWeight: 'normal' }}>
                    {`${getDecimalAdjustedStringAmount(
                      this.props.budgetData.OriginalPOAmountLC
                    )}`}
                  </span>
                </div>
                <div>
                  <span style={{ fontWeight: 'normal' }}>
                    {`($${getDecimalAdjustedStringAmount(
                      this.props.budgetData.OriginalPOAmountCD
                    )})`}
                  </span>
                </div>
              </div>
              <div>
                <TooltipHost
                  content={`Adjustments (USD):`}
                  id={'Adjustments '}
                  calloutProps={{ gapSpace: 0 }}
                >
                  <label aria-label={'Adjustments'} style={{ margin: '5px', fontSize: '.83em', display: 'block', fontWeight: 'bold',  }}>
                    Adjustments
                  </label>
                </TooltipHost>

                <div>
                  <span style={{ fontWeight: 'normal' }} >
                    {`${getDecimalAdjustedStringAmount(
                      this.props.budgetData.AdjustmentsLC
                    )}`}
                  </span>
                </div>
                <div>
                  <span style={{ fontWeight: 'normal' }} >
                    {`${getDecimalAdjustedStringAmount(
                      this.props.budgetData.AdjustmentsCD
                    )}`}
                  </span>
                </div>
              </div>
              <div>
                <TooltipHost
                  content={`Projected Spend (USD):`}
                  id={'Projected Spend'}
                  calloutProps={{ gapSpace: 0 }}
                >
                  <label aria-label={'Projected Spend'} style={{ margin: '5px', fontSize: '.83em', display: 'block', fontWeight: 'bold',  }}>
                    Projected Spend{' '}
                  </label>
                </TooltipHost>

                <div>
                  <span style={{ fontWeight: 'normal' }}>
                    {`${getDecimalAdjustedStringAmount(
                      this.props.budgetData.ProjectedSpendLC
                    )}`}
                  </span>
                </div>
                <div>
                  <span style={{ fontWeight: 'normal' }}>
                    {`($${getDecimalAdjustedStringAmount(
                      this.props.budgetData.ProjectedSpendCD
                    )})`}
                  </span>
                </div>
              </div>
              <div>
                <TooltipHost content={`YTD Actuals (USD)`} id={'YTD Actuals'}>
                  <label aria-label={'YTD Actuals'} style={{ margin: '5px', fontSize: '.83em', display: 'block', fontWeight: 'bold',  }}>
                    YTD Actuals{' '}
                    {/* <Icon iconName="info" className="ms-IconExample" /> */}
                  </label>
                </TooltipHost>

                <div>
                  <span style={{ fontWeight: 'normal' }}>
                    {`${getDecimalAdjustedStringAmount(
                      this.props.budgetData.YTDActualsLC
                    )}`}
                  </span>
                </div>
                <div>
                  <span style={{ fontWeight: 'normal' }}>
                    {`($${getDecimalAdjustedStringAmount(
                      this.props.budgetData.YTDActualsCD
                    )})`}
                  </span>
                </div>
              </div>
              <div>
                <TooltipHost
                  content={`Net PO Amount (USD)`}
                  id={'Net PO Amount '}
                >
                  <label aria-label={'Net PO Amount'} style={{ margin: '5px', fontSize: '.83em', display: 'block', fontWeight: 'bold',  }}>
                    Net PO Amount
                  </label>
                </TooltipHost>

                <div>
                  <span style={{ fontWeight: 'normal' }}>
                    {' '}
                    {`${getDecimalAdjustedStringAmount(
                      this.props.netPoAmount // TODO: Populate this once we get PO Data.
                    )}`}
                  </span>
                </div>
                <div>
                  <span style={{ fontWeight: 'normal' }}>N/A</span>
                </div>
              </div>
              <div>
                <TooltipHost content={`PO Balance (USD):`} id={'PO Balance'}>
                  <label aria-label={'PO Balance'} style={{ margin: '5px', fontSize: '.83em', display: 'block', fontWeight: 'bold',  }}>
                    PO Balance{' '}
                  </label>
                </TooltipHost>

                <div>
                  <span style={{ fontWeight: 'normal' }}>
                    {`${getDecimalAdjustedStringAmount(
                      this.props.netPoAmount -
                        this.props.budgetData.YTDActualsLC // TODO: See what to do with this PO Amount Balance.
                    )}`}
                  </span>
                </div>
                <div>
                  <span style={{ fontWeight: 'normal' }}>N/A</span>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else if (
      this.props.budgetData != null &&
      this.props.currentSupplier.currencyCode === 'USD'
    ) {
      // Render budget when currency is USD
      return (
        <>
          <div className="flex-container-supplier-budget">
            <div className="flex-container-supplier-budget-1">
              <h1
                className="graphHeader"
                style={{
                  padding: '8px',
                  fontWeight: 'bold',
                  fontSize: '1.17em',
                }}
              >
                {`${this.props.currentSupplier.supplierLegalName} ${this.props.currentSupplier.companyCode} (${this.props.currentSupplier.currencyCode}) - ${this.props.currentSupplier.supplierNumber}`}
              </h1>
              <div className="flex-container-supplier-budget-1-item">
                <CommandBarButton
                  data-automation-id="test1"
                  iconProps={{ iconName: 'Add' }}
                  text={
                    this.props.addAdjustments ? 'Adjustments' : 'Judgements'
                  }
                  ariaLabel={this.props.addAdjustments ? 'Add Adjustments' : 'Add Judgements'}
                  role="link"
                  style={{
                    display: 'flex-end',
                    marginRight: '20px',
                    fontSize: '13px',
                    height: '30px',
                  }}
                  tabIndex={0}
                  onClick={this.handleAddJudgements}
                />
                <Dropdown
                  ariaLabel="Fiscal Year Dropdown"
                  placeholder="Select an option"
                  id="fiscalYearDropdown"
                  defaultSelectedKey={this.props.selectedFiscalYear}
                  options={fiscalDropDownOptions}
                  onChange={this.handleFiscalYearChange}
                  style={{ marginLeft: '10px' }}
                />
              </div>
            </div>
            <div className="flex-container-supplier-budget-budget-chart">
              <BudgetChart
                aria-label={'Budget graph'}
                legendTypes={this.buildLegend(this.props.budgetData)}
                data={this.buildGraphData(this.props.budgetData)}
                suppliername={this.props.currentSupplier.supplierLegalName}
                
              />
            </div>
            <div className="flex-container-supplier-budget-3">
              <div>
                <TooltipHost
                  content={`Original Budget`}
                  id={'Original Budget'}
                  calloutProps={{ gapSpace: 0 }}
                >
                  <label aria-label={'Original Budget'} style={{ margin: '5px', fontSize: '.83em', display: 'block', fontWeight: 'bold',  }}>
                    Original Budget
                  </label>
                </TooltipHost>
                <span>
                  {`${getDecimalAdjustedStringAmount(
                    this.props.budgetData.OriginalBudgetLC
                  )}`}
                </span>
              </div>
              <div>
                <TooltipHost
                  content={`'Budget After Judgement`}
                  id={'Budget After Judgement'}
                  calloutProps={{ gapSpace: 0 }}
                >
                  <label
                    aria-label={'Budget After Judgement'}
                    style={{ margin: '5px', fontSize: '.83em', display: 'block', fontWeight: 'bold',  }}
                  >
                    Budget After Judgement
                  </label>
                </TooltipHost>
                <span>
                  {`${getDecimalAdjustedStringAmount(
                    this.props.budgetData.BudgetAfterJudgementLC
                  )}`}
                </span>
              </div>
              <div>
                <TooltipHost
                  content={`Original PO Amount`}
                  id={'Original PO Amount'}
                  calloutProps={{ gapSpace: 0 }}
                >
                  <label
                    aria-label={'Original PO Amount'}
                    style={{ margin: '5px', fontSize: '.83em', display: 'block', fontWeight: 'bold',  }}
                  >
                    Original PO Amount
                  </label>
                </TooltipHost>
                <span>
                  {`${getDecimalAdjustedStringAmount(
                    this.props.budgetData.OriginalPOAmountLC
                  )}`}
                </span>
              </div>
              <div>
                <TooltipHost
                  content={`Adjustments`}
                  id={'Adjustments'}
                  calloutProps={{ gapSpace: 0 }}
                >
                  <label aria-label={'Adjustments'} style={{ margin: '5px', fontSize: '.83em', display: 'block', fontWeight: 'bold',  }}>
                    Adjustments
                  </label>
                </TooltipHost>
                <span style={{ fontWeight: 'normal' }} >
                  {`${getDecimalAdjustedStringAmount(
                    this.props.budgetData.AdjustmentsLC
                  )}`}
                </span>
              </div>
              <div>
                <TooltipHost
                  content={`Projected Spend:`}
                  id={'Projected Spend'}
                  calloutProps={{ gapSpace: 0 }}
                >
                  <label aria-label={'Projected Spend'} style={{ margin: '5px', fontSize: '.83em', display: 'block', fontWeight: 'bold',  }}>
                    Projected Spend
                  </label>
                </TooltipHost>
                <span>
                  {`${getDecimalAdjustedStringAmount(
                    this.props.budgetData.ProjectedSpendLC
                  )}`}
                </span>
              </div>
              <div>
                <TooltipHost content={`YTD Actuals`} id={'YTD Actuals'}>
                  <label aria-label={'YTD Actuals'} style={{ margin: '5px', fontSize: '.83em', display: 'block', fontWeight: 'bold',  }}>
                    YTD Actuals
                  </label>
                </TooltipHost>
                <span>
                  {`${getDecimalAdjustedStringAmount(
                    this.props.budgetData.YTDActualsLC
                  )}`}
                </span>
              </div>
              <div>
                <TooltipHost content={`Net PO Amount`} id={'Net PO Amount'}>
                  <label aria-label={'Net PO Amount'} style={{ margin: '5px', fontSize: '.83em', display: 'block', fontWeight: 'bold',  }}>
                    Net PO Amount
                  </label>
                </TooltipHost>

                <span>
                  {' '}
                  {`${getDecimalAdjustedStringAmount(
                    this.props.netPoAmount // TODO: figure out what needs to go here.
                  )}`}
                </span>
              </div>
              <div>
                <TooltipHost content={`PO Balance:`} id={'PO Balance'}>
                  <label aria-label={'PO Balance'} style={{ margin: '5px', fontSize: '.83em', display: 'block', fontWeight: 'bold',  }}>
                    PO Balance
                  </label>
                </TooltipHost>
                <span>
                  {`${getDecimalAdjustedStringAmount(
                    this.props.netPoAmount - this.props.budgetData.YTDActualsLC
                  )}`}
                </span>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="flex-container-supplier-budget">
            <div className="flex-container-supplier-budget-1">
              <h1
                className="graphHeader"
                style={{
                  padding: '8px',
                  fontWeight: 'bold',
                  fontSize: '1.17em',
                }}
              >
                {`${this.props.currentSupplier.supplierLegalName} ${this.props.currentSupplier.companyCode} (${this.props.currentSupplier.currencyCode}) - ${this.props.currentSupplier.supplierNumber}`}
              </h1>
              <div className="flex-container-supplier-budget-1-item">
                <CommandBarButton
                  data-automation-id="test1"
                  iconProps={{ iconName: 'Add' }}
                  text=" Judgements"
                  ariaLabel="Add Judgements"
                  role="link"
                  style={{
                    display: 'flex-end',
                    marginRight: '20px',
                    fontSize: '13px',
                    height: '30px',
                  }}
                  onClick={this.handleAddJudgements}
                  // disabled={!this.props.isMyOrderFeatureEnabled}
                />
                <Dropdown
                  ariaLabel="Fiscal Year Dropdown"
                  placeholder="Select an option"
                  id="fiscalYearDropdown"
                  defaultSelectedKey={this.props.selectedFiscalYear}
                  options={fiscalDropDownOptions}
                  onChange={this.handleFiscalYearChange}
                  style={{ marginLeft: '10px', }}
                />
              </div>
            </div>
            <div className="flex-container-supplier-budget-2">
              <label style={{ display: 'block', fontSize: '2em', margin: '0.67em 0 0.67em 0', fontWeight: 'bold',}}>
                {' '}
                {`Budget Information is not avaliable for this supplier for fiscal year ${this.props.selectedFiscalYear}.`}{' '}
              </label>
            </div>
          </div>
        </>
      );
    }
  };

  private handleAddJudgements = () => {
    this.setState({ redirect: IRedirectTypes.Judgement });
  };
  // Building Legend for the graph
  private buildLegend(budgetData: IBudget): ILegend[] {
    let trendSet = new Set<string>();

    budgetData.BudgetAndActualDetails.forEach((x) => {
      if (!x.TrendType.includes('Budget')) {
        trendSet.add(x.TrendType);
      }
    });

    const uniqueTrends = Array.from(trendSet);
    return uniqueTrends.map((x) => ({
      key: x.replace(/\s/g, ''),
      displayName: x,
    }));
  }

  // Building Graph Data
  private buildGraphData = (budgetData: IBudget): any[] => {
    const graphData = budgetData.BudgetAndActualDetails.map((x) => {
      let data = { month: x.CalenderMonth };
      data['Year'] = x.CalendarYear;
      data['BudgetType'] = x.TrendType.replace(/\s/g, '').includes('Forecast') ? x.TrendType.replace(/\s/g, '') : null
      data[`${x.TrendType.replace(/\s/g, '')}`] =
        Math.round(x.AmountLC * 100) / 100;
      data['constantDollarValue'] = Math.round(x.AmountCD * 100) / 100;
      data['currencyCode'] = this.props.currentSupplier.currencyCode;
      data['Budget'] = Math.round(x.BudgetLC * 100) / 100;
      data['budgetConstantDollarValue'] = Math.round(x.BudgetCD * 100) / 100;
      return data;
    });
    return graphData;
  };

  // Fiscal year dropdown creation method
  private buildFiscalYearDropdown = (): IDropdownOption[] => {
    let options: IDropdownOption[] = [];

    const { fiscalYearNumbers } = this.props;
    fiscalYearNumbers.forEach((x) => {
      options.push({
        key: x,
        text: `Fiscal Year ${x.toString()}`,
      });
    });

    return options;
  };

  // Handling Fiscal Year Change
  private handleFiscalYearChange = async (
    event: React.FormEvent<HTMLDivElement>,
    item: IDropdownOption
  ) => {
    // Logging fiscal year change.
    telemetryContext.logEvent(TelemetryEvent.FiscalYearSelection, {
      fiscalYear: item.key,
    });
    await this.props.onFiscalYearChange(item.key);
  };

  // Handling the size when the window size changes
  private handleWindowResize = () => {
    setTimeout(() => {
      const currentWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      const currentHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      this.setState({ width: currentWidth, height: currentHeight });
    }, 1000);
  };
}
