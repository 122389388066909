import * as Excel from "exceljs";
import {saveAs} from "file-saver";
import telemetryContext from "../services/TelemetryServices";
// is Valid Integer
export function isValidInteger(value: string, isCommaAllowed: boolean = true): boolean {
	let pattern = isCommaAllowed ? new RegExp(/^[0-9,\n]*$/) : new RegExp(/^[0-9]*$/)
	return pattern.test(value);
}

export interface IExcelHeaders {
	header: string;
	key: string;
}

export async function exportToXlsx(apiData: any, headers: any, fileName: string, sheetName: string = "data") {
	const wb = new Excel.Workbook();
	const ws = wb.addWorksheet(sheetName);
	ws.columns = headers;
	ws.addRows(apiData);
	const buffer =  await  wb.xlsx.writeBuffer();
	const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
	const fileExtension = '.xlsx';
	const blob = new Blob([buffer], {type: fileType});
	saveAs(blob, fileName + fileExtension);
};

export function removeDuplicates(arr) {
	return arr.filter((item, index) => arr.indexOf(item) === index && item.trim() !== "");
}

export function handleOnblurHeartFramework(event: any) {
    var element = event.target as HTMLElement;
    if (element && element.id && element.getAttribute("data-custom-parentid") && element.getAttribute("data-custom-parentid") !== "") {
        telemetryContext.logEvent(
            element.id,
            { // accepts any type
                parentId: element.getAttribute("data-custom-parentid"),
                pageName: document.title,
                uri: document.URL,
                baseTypeSource: "OnBlur",
                actionType: "CL"
            }
        );
    }
}

export function handleOnChangeHeartFramework(elementId: string,dataCustomParentid: string) {
    if (elementId !== "" && dataCustomParentid !== "") {
        telemetryContext.logEvent(
            elementId,
            { // accepts any type
                parentId: dataCustomParentid,
                pageName: document.title,
                uri: document.URL,
                baseTypeSource: "OnBlur",
                actionType: "CL"
            }
        );
    }
}

//function to validate null or Undefined
export function isNullOrUndefined(value: any): boolean {
	return value === undefined || value === null;
}

//function to validate null or Undefined or empty
export function isNullOrUndefinedOrEmpty(value: any): boolean {
	return value === undefined || value === null || value === "";
}