// Enum for Telemetry events.
export enum TelemetryEvent {
  // UI Specific events: Button clicks, toggles, selections ...
  AppLoad = 'APP LOAD',
  SupplierSelection = 'SUPPLIER SELECTION',
  FiscalYearSelection = 'FISCAL YEAR SELECTION',
  PurchaseOrderViewToggle = 'PURCHASE ORDER VIEW TOGGLE',
  AddJudgementButtonClick = 'ADD JUDGEMENT BUTTON CLICK',
  AddAravoEngagementButtonClick = 'ADD ARAVO ENGAGEMENT BUTTON CLICK',
  AddPurchaseOrderButtonClick = 'ADD PURCHASE ORDER BUTTON CLICK',
  SubmitAravoEngagementIdButtonClick = 'SUBMIT ARAVO ENGAGEMENT ID BUTTON CLICK',
  ViewInvoicesButtonClick = 'VIEW INVOICE BUTTON CLICK',

  ContractSelection = 'CONTRACT SELECTED FROM SEARCH SUGGESTIONS',
  MapContractButtonClick = 'MAP CONTRACT BUTTON CLICK',
  SubmitContractShellButtonClick = 'SUBMIT CONTRACT SHELL BUTTON CLICK',
  SubmitAravoIdButtonClick = 'SUBMIT ARAVO ID BUTTON CLICK',
  DeleteAravoEngagementIdButtonClick = 'DELETE ARAVO ID BUTTON CLICK',
  SubmitJudgementButtonClick = 'SUBMIT JUDGEMENT BUTTON CLICK',
  // TODO: Add events for navigation button clicks.

  // API events
  FetchAllReportBegin = 'FETCH ALL REPORT',
  FetchSupplierDataBegin = 'FETCH SUPPLIER DATA BEGIN',
  FetchSupplierDataSuccess = 'FETCH SUPPLIER DATA SUCCESS',
  DeleteAravoIdForSupplierBegin = 'DELETE ARAVO ID BEGIN',
  DeleteAravoIdForSupplierSuccess = 'DELETE ARAVO ID SUCCESS',
  FetchLineItemDataBegin = 'FETCH LINE ITEM DATA BEGIN',
  FetchLineItemDataSuccess = 'FETCH LINE ITEM DATA SUCCESSS',
  FetchSupplierForUserBegin = 'FETCH SUPPLIER FOR USER BEGIN',
  FetchSupplierForUserSuccess = 'FETCH SUPPLIER FOR USER SUCCESS',
  FetchBillableUnitsBegin = 'FETCH BILLABLE UNITS BEGIN',
  FetchBillableUnitsSuccess = 'FETCH BILLABLE UNITS SUCCESS',
  FetchInvoiceDetailsBegin = 'FETCH INVOICE DETAILS BEGIN',
  FetchInvoiceDetailsSuccess = 'FETCH INVOICE DETAILS SUCCESS',

  FetchBudgetDataForSupplierBegin = 'FETCH BUDGET DATA FOR SUPPLIER BEGIN',
  FetchBudgetDataForSupplierSuccess = 'FETCH BUDGET DATA FOR SUPPLIER SUCCESS',

  FetchAvailableFiscalYearsForSupplierBegin = 'FETCH AVAILABLE FISCAL YEARS FOR SUPPLIER BEGIN',
  FetchAvailableFiscalYearsForSupplierSuccess = 'FETCH AVAILABLE FISCAL YEARS FOR SUPPLIER SUCCESS',

  FetchPurhaseOrderDataForSupplierBegin = 'FETCH PURCHASE ORDER DATA FOR SUPPLIER BEGIN',
  FetchPurhaseOrderDataForSupplierSuccess = 'FETCH PURCHASE ORDER DATA FOR SUPPLIER SUCCESS',

  FetchAravoToContractMapForSupplierBegin = 'FETCH ARAVO TO CONTRACT MAP FOR SUPPLIER BEGIN',
  FetchAravoToContractMapForSupplierSuccess = 'FETCH ARAVO TO CONTRACT MAP FOR SUPPLIER SUCCESS',

  PostAravoEngagementIdBegin = 'POST ARAVO ENGAGEMENT ID BEGIN',
  PostAravoEngagementIdSuccess = 'POST ARAVO ENGAGEMENT ID SUCCESS',

  PostBillableUnitsBegin = 'POST BILLABLE UNITS BEGIN',
  PostBillableUnitsSuccess = 'POST BILLABLE UNITS SUCCESS',

  PostJudgementsBegin = 'POST JUDGEMENT BEGIN',
  PostJudgementsSuccess = 'POST JUDGEMENT SUCCESS',

  PostPurchaseOrderBegin = 'POST PURCHASE ORDER BEGIN',
  PostPurchaseOrderSuccess = 'POST PURCHASE ORDER SUCCESS',

  FetchUserAuthBegin = 'FETCH USER AUTH BEGIN',
  FetchUserAuthSuccess = 'FETCH USER AUTH SUCCESS',

  FetchExistingContractsBegin = 'FETCH EXISTING CONTRACTS BEGIN',
  FetchExistingContractsSuccess = 'FETCH EXISTING CONTRACTS SUCCESS',

  FetchActiveContractsForSupplierBegin = 'FETCH ACTIVE CONTRACTS FOR SUPPLIER BEGIN',
  FetchActiveContractsForSupplierSuccess = 'FETCH ACTIVE CONTRACTS FOR SUPPLIER SUCCESS',

  FetchContractSearchSuggestionsBegin = 'FETCH CONTRACT SEARCH SUGGESTIONS BEGIN',
  FetchContractSearchSuggestionsSuccess = 'FETCH CONTRACT SEARCH SUGGESTIONS SUCCESS',

  FetchJudgementsForSupplierBegin = 'FETCH JUDGEMENTS FOR SUPPLIER BEGIN',
  FetchJudgementsForSupplierSuccess = 'FETCH JUDGEMENTS FOR SUPPLIER SUCCESS',

  InsertContractBegin = 'INSERT CONTRACT BEGIN',
  InsertContractSuccess = 'INSERT CONTRACT SUCCESS',

  FetchContractDetailsBegin = 'FETCH CONTRACT DETAILS BEGIN',
  FetchContractDetailsSuccess = 'FETCH CONTRACT DETAILS SUCCESS',

  FetchMatchingContractShellsBegin = 'FETCH MATCHING CONTRACT SHELLS BEGIN',
  FetchMatchingContractShellsSuccess = 'FETCH MATCHING CONTRACT SHELLS SUCCESS',
  FetchMonthListBegin = 'FETCH MONTH LIST BEGIN',
  FetchMonthListSuccess = 'FETCH MONTH LIST SUCCESS',
  InsertMergeContractWithShellBegin = 'INSERT MERGE CONTRACT WITH SHELL BEGIN',
  InsertMergeContractWithShellSuccess = 'INSERT MERGE CONTRACT WITH SHELL SUCCESS',

  FetSupplierAndInvoicePrefixBegin = 'FETCH SUPPLIER AND INVOICE PREFIX BEGIN',
  FetSupplierAndInvoicePrefixSucess = 'FETCH SUPPLIER AND INVOICE PREFIX SUCCESS',

  AcceptModificationBegin = 'ACCEPT MODIFICATION BEGIN',
  AcceptModificationSuccess = 'ACCEPT MODIFICATION SUCCESS',

  PostPriorPeriodAdjustmentDetailsBegin = 'POST PRIOR PERIOD ADJUSTMENT DETAILS BEGIN',
  PostPriorPeriodAdjustmentDetailsSuccess = 'POST PRIOR PERIOD ADJUSTMENT DETAILS SUCCESS',

  FetchNRCTypesBegin = 'FETCH NRC TYPES BEGIN',
  FetchNRCcategoriesBegin = 'FETCH NRC CATEGORIES BEGIN',
  FetchNRCDescriptionBegin = 'FETCH NRC CATEGORIES BEGIN',
  FetchMicrosoftSubmittedBillableUnitsBegin = 'FETCH MICROSOFT SUBMITTED BILLABLE UNITS BEGIN',
  FetchPriorPeriodAdjustmentDetailsBegin = 'FETCH PRIOR PERIOD ADJUSTMENT DETAILS BEGIN',

  FetchSDUsersBegin = 'FETCH SD USERS BEGIN',

  SaveNRCDescriptionBegin = 'SAVE NRC DESCRIPTION BEGIN',
  SaveNRCDescriptionSuccess = 'SAVE NRC DESCRIPTION SUCCESS',

  SubmitNRCDetailsBegin = 'SUBMIT NRC DETAILS BEGIN',
  SubmitNRCDetailsSuccess = 'SUBMIT NRC DETAILS SUCCESS',

  SubmitSDDetailsBegin = 'SUBMIT SD DETAILS BEGIN',
  SubmitSDDetailsSuccess = 'SUBMIT SD DETAILS SUCCESS',

  FetchBillableUnitsForAllSuppliersBegin = 'FETCH BILLABLE UNITS FOR ALL SUPPLIERS SUCCESS',

  FetchBOBReportMasterDataBegin = 'FETCH BOB REPORT MASTER DATA BEGIN',
  FetchBOBReportMasterDataSuccess = 'FETCH BOB REPORT MASTER DATA SUCCESS',

  FetchBOBReportInvoiceSummaryBegin = 'FETCH BOB REPORT INVOICE SUMMARY BEGIN',
  FetchBOBReportInvoiceSummarySuccess = 'FETCH BOB REPORT INVOICE SUMMARY SUCCESS',

  FetchBOBReportInvoiceDetailsBegin = 'FETCH BOB REPORT INVOICE DETAILS BEGIN',
  FetchBOBReportInvoiceDetailsSuccess = 'FETCH BOB REPORT INVOICE DETAILS SUCCESS',

  FetchBOBReportBudgetSummaryBegin = 'FETCH BOB REPORT BUDGET SUMMARY BEGIN',
  FetchBOBReportBudgetSummarySuccess = 'FETCH BOB REPORT BUDGET SUMMARY SUCCESS',

  FetchBOBReportBudgetDetailsBegin = 'FETCH BOB REPORT BUDGET DETAILS BEGIN',
  FetchBOBReportBudgetDetailsSuccess = 'FETCH BOB REPORT BUDGET DETAILS SUCCESS',

  FetchSupplierVarianceBegin = 'FETCH SUPPLIER VARIANCE BEGIN',
  FetchSuppliervarianceSuccess = 'FETCH SUPPLIER VARIANCE SUCCESS',

  FetchAllInvoiceAccrualsBegin = 'FETCH ALL INVOICE ACCRUALS BEGIN',
  FetchAllInvoiceAccrualsSuccess = 'FETCH ALL INVOICE ACCRUALS SUCCESS',

  FetchAllInvoiceActualsBegin = 'FETCH ALL INVOICE ACTUALS BEGIN',
  FetchAllInvoiceActualsSuccess = 'FETCH ALL INVOICE ACTUALS SUCCESS',

  FetchEventDateTimeBegin = 'FETCH EVENT DATE TIME BEGIN',
  // TODO: Add events for getting user role ...

  GetUserPreferencesBegin = 'GET USER PREFERENCES BEGIN',
  GetUserPreferencesSuccess = 'GET USER PREFERENCES SUCCESS',
  SaveUserPreferencesBegin = 'SAVE USER PREFERENCES BEGIN',
  SaveUserPreferencesSuccess = 'SAVE USER PREFERENCES SUCCESS',

  FetchCurrentFiscalYearForBillableUnitsSuccess = 'FETCH CURRENT FISCAL YEARS FOR BILLABLE UNITS SUCCESS',
  FetchCurrentFiscalYearForInvoiceSuccess = 'FETCH CURRENT FISCAL YEARS FOR INVOICE SUCCESS',
  FetchNRCLINEiTEMcategoriesBegin = 'FETCH NRC LINE ITEM CATEGORIES BEGIN',
  FetchNRCLineItemTypesBegin = "FETCH NRC LINE ITEM TYPES BEGIN",
  DeleteNRCLineItemBegin ="DELETE NRC LINE ITEM BEGIN",
  AddNRCLineItemBegin ="ADD NRC LINE ITEM BEGIN",

  FetchAllUsersList = "FETCH ALL USERS LIST",
  FetchUserRoles ="FETCH USER ROLES",
  FetchNewUsersList="FETCH NEW USERS LIST",
  AddNewUser ="ADD NEW USER",
  FetchUserPeningRequests ="FETCH USER PENDING REQUESTS",
  UserstatusChange ="USER STATUS UPDATE",
  UserAccessRequestAction ="USER ACCESS REQUEST ACTION",

  FetchSupplierList='FETCH SUPPLIERS LIST',
  ArchiveSupplier ='ARCHIVE SUPPLIER',
  FetchSupplierMasterList = 'FETCH SUPPLIER MASTER LIST',
  FetchDiscountTermList ='FETCH DISCOUNT TERM LIST',
  FetchCurrencyList ='FETCH CURRENCY LIST',
  AddSuppliers = 'ADD SUPPLIERS',
  ApproveSuppliers ='APPROVE SUPPLIERS',
  RejectSuppliers = 'REJECT SUPPLIERS',
  DeleteSuppliersInvoiceContact ="DELETE SUPPLIERS INVOICE CONTACT",
  AddSuppliersInvoiceContact ="ADD SUPPLIERS INVOICE CONTACT",
  AddMasterSuppliers = 'ADD MASTER SUPPLIERS',

  FetchInvoicePrefixList='FETCH INVOICE PREFIX LIST',
  ArchiveInvoicePrefix ='ARCHIVE INVOICE PREFIX',
  AddInvoicePrefix ='ADD INVOICE PREFIX',
  
  AddSupplierSite = "ADD SUPPLIER SITE",
  ArchiveSupplierSite = "ARCHIVE SUPPLIER SITE",
  ApproveSupplierSite = "APPROVE SUPPLIER SITE",
  RejectSupplierSite = "REJECT SUPPLIER SITE",
  FetchSupplierSiteList = 'FETCH SUPPLIER SITE LIST',
  FetchAvailableFiscalYears = 'FETCH AVAILABLE FISCAL YEARS',
  FetchCurrentFiscalYearForSupplierSite = 'FETCH CURRENT FISCAL YEAR FOR SUPPLIER SITE',
  FetchSuppliersSiteCities = 'FETCH SUPPLIERS SITE CITIES',
  FetchSupplierSitePendingRecords = 'FETCH SUPPLIERS SITE PENDING RECORDS',

  FetchAutomatedSeidList ='FETCH AUTOMATED SEID LIST',
  FetchAvailableFiscalYearsForAutomationSeidBegin= 'FETCH AVAILABLE FISCAL YEARS FOR AUTOMATION SEID BEGIN',
  FetchAvailableFiscalYearsForAutomationSeidSuccess= 'FETCH AVAILABLE FISCAL YEARS FOR AUTOMATION SEID SUCCESS',
  EditSupplierEngagementsAttributesBegin ="EDIT SUPPLIER ENGAGEMENTS ATTRIBUTES BEGIN",
  EditSupplierEngagementsAttributesSuccess ="EDIT SUPPLIER ENGAGEMENTS ATTRIBUTES SUCCESS",
  FetchPricingGroupListBySupplierBegin = 'FETCH PRICING GROUP LIST BY SUPPLIER BEGIN',
  FetchPricingGroupListBySupplierSuccess = 'FETCH PRICING GROUP LIST BY SUPPLIER SUCCESS',
  AddAutomatedSeid ="ADD AUTOMATED SUPPLIER ENGAGEMEENTS",
  ValidateUploadedAutomatedSeidRecords =' VALIDATE UPLOADED AUTOMATED SUPPLIER ENGAGEMENTS RECORDS'

}

// Enums for Telemetry exceptions.
export enum TelemetryException {
  // TODO: Hook into client side errors and write a service to log.
  FetchBudgetDataForSupplierFailure = 'FETCH BUDGET DATA FOR SUPPLIER FAILURE',
  FetchAvailableFiscalYearsForSupplierFailure = 'FETCH AVAILABLE FISCAL YEARS FOR SUPPLIER FAILURE',
  FetchSupplierDataFailure = 'FETCH SUPPLIER DATA FAILURE',
  FetchPurhaseOrderDataForSupplierFailure = 'FETCH PURCHASE ORDER DATA FOR SUPPLIER FAILURE',
  PostAravoEngagementIdFailure = 'POST ARAVO ENGAGEMENT ID FAILURE',
  PostBillableUnitsFailure = 'POST BILLABLE UNITS FAILURE',
  AcceptModificationFailure = 'ACCEPT MODIFICATION FAILURE',
  PostPriorPeriodAdjustmentDetailsFailure = 'POST PRIOR PERIOD ADJUSTMENT DETAILS FAILURE',
  JavascriptError = 'JAVASCRIPT ERROR',
  FetchUserAuthFailure = 'FETCH USER AUTH FAILURE',
  FetchExistingContractsFailure = 'FETCH EXISTING CONTRACTS FAILURE',
  FetchActiveContractsForSupplierFailure = 'FETCH ACTIVE CONTRACTS FOR SUPPLIER FAILURE',
  FetchContractSearchSuggestionsFailure = 'FETCH CONTRACT SEARCH SUGGESTIONS FAILURE',
  InsertNewContractFailure = 'INSERT CONTRACT FAILURE',
  FetchContractDetailsFailure = 'FETCH CONTRACT DETAILS FAILURE',
  FetchAravoToContractMapForSupplierFailure = 'FETCH ARAVO TO CONTRACT MAP FOR SUPPLIER FAILURE',
  FetchMatchingContractShellsFailure = 'FETCH MATCHING CONTRACT SHELLS FAILURE',
  InsertMergeContractWithShellFailure = 'INSERT MERGE CONTRACT WITH SHELL FAILURE',
  DeleteAravoIdForSupplierFailure = 'DELETE ARAVO ID FAILURE',
  FetchJudgementsForSupplierFailure = 'FETCH JUDGEMENTS FOR SUPPLIER FAILURE',
  PostJudgementsFailure = 'POST JUDGEMENT FAILURE',
  FetchLineItemDataFailure = 'FETCH LINE ITEM DATA FAILURE',
  PostPurchaseOrderFailure = 'POST PURCHASE ORDER FAILURE',
  FetchSupplierForUserFailure = 'FETCH SUPPLIER FOR USER FAILURE',
  FetchBillableUnitsFailure = 'FETCH BILLABLE UNITS FAILURE',
  FetchInvoiceDetailsFailure = 'FETCH INVOICE DETAILS FAILURE',
  FetchMonthListFailure = 'FETCH MONTH LIST FAILURE',
  FetSupplierAndInvoicePrefixFailure = 'FETCH SUPPLIER AND INVOICE PREFIX FAILURE',
  FetchNRCTypesFailure = 'FETCH NRC TYPES FAILURE',
  FetchNRCLINEiTEMcategoriesBeginFetchNRCCategoriesFailure = 'FETCH NRC CATEGORIES FAILURE',
  FetchNRCDescriptionFailure = 'FETCH NRC DESCRIPTION FAILURE',
  FetchMicrosoftSubmittedBillableUnitsFailure = 'FETCH MICROSOFT SUBMITTED BILLABLE UNITS FAILURE',
  FetchPriorPeriodAdjustmentDetailsFailure = 'FETCH PRIOR PERIOD ADJUSTMENT DETAILS FAILURE',
  SaveNRCDescriptionFailure = 'SAVE NRC DESCRIPTION FAILURE',
  SubmitNRCDetailsFailure = 'SUBMIT NRC DETAILS FAILURE',  
  FetchBillableUnitsForAllSuppliersFailure = 'FETCH BILLABLE UNITS FOR ALL SUPPLIERS FAILURE',
  FetchBOBReportMasterDataFailure = 'FETCH BOB REPORT MASTER DATA FAILURE',
  FetchBOBReportInvoiceSummaryFailure = 'FETCH BOB REPORT INVOICE SUMMARY FAILURE',
  FetchBOBReportInvoiceDetailsFailure = 'FETCH BOB REPORT INVOICE DETAILS FAILURE',
  FetchBOBReportBudgetSummaryFailure = 'FETCH BOB REPORT BUDGET SUMMARY FAILURE',
  FetchBOBReportBudgetDetailsFailure = 'FETCH BOB REPORT BUDGET DETAILS FAILURE',
  FetchSupplierVarianceFailure = 'FETCH SUPPLIER VARIANCE FAILURE',
  FetchAllInvoiceAccrualsFailure = 'FETCH ALL INVOICE ACCRUALS FAILURE',
  FetchAllInvoiceActualsFailure = 'FETCH ALL INVOICE ACTUALS FAILURE',
  FetchSDUsersFailure = 'FETCH SD USERS FAILURE',
  SubmitSDDetailsFailure = 'SUBMIT SD DETAILS FAILURE',
  GetUserPreferencesFailure = 'GET USER PREFERENCES FAILURE',
  SaveUserPreferencesFailure = 'SAVE USER PREFERENCES FAILURE',
  FetchCurrentFiscalYearForInvoiceFailure = 'FETCH CURRENT FISCAL YEARS FOR INVOICE FAILURE',
  FetchCurrentFiscalYearForBillableUnitsFailure = 'FETCH CURRENT FISCAL YEARS FOR BILLABLE UNITS FAILURE',
  FetchNRCLINEiTEMcategoriesFailure = 'FETCH NRC LINE ITEM CATEGORIES FAILURE',
  FetchNRCLineItemTypesFailure = "FETCH NRC LINE ITEM TYPES FAILURE",
  DeleteNRCLineItemFailure = "DELETE NRC LINE ITEM FAILURE",
  AddNRCLineItemFailure = "ADD NRC LINE ITEM FAILURE",
  FetchAllUsersListFaiure = "FETCH ALL USERS LIST FAILURE",
  FetchUserRolesFailure = "FETCH USER ROLES FAILURE",
  FetchNewUsersListFailure ="FETCH NEW USERS LIST FAILURE",
  AddNewUserFailure= "FETCH ADD NEW USER FAILURE",
  FetchUserPeningRequestsFailure="FETCH USER PENDING REQUESTS FAILURE",
  UserStatusUpdateFailure ="USEER STATUS UPDATE FAILURE",
  UserAccessRequestActionFailure ="USER ACCESS REQUEST ACTION FAILURE",
  FetchSupplierListFailure='FETCH SUPPLIERS LIST FAILURE',
  ArchiveSupplierFailure ='ARCHIVE SUPPLIER FAILURE',
  FetchSupplierMasterListFailure = 'FETCH SUPPLIER MASTER LIST FAILURE',
  FetchDiscountTermListFailure ='FETCH DISCOUNT TERM LIST FAILURE',
  FetchCurrencyListFailure ='FETCH CURRENCY LIST FAILURE',
  AddSuppliersFailure = 'ADD SUPPLIERS FAILURE',
  FetchAllReportFailure = 'FETCH ALL REPORTS FAILURE',

  FetchSupplierSiteListFailure = 'FETCH SUPPLIER SITE LIST FALIURE',
  ArchiveSupplierSiteFailure = 'ARCHIVE SUPPLIER SITE FAILURE',
  ApproveSupplierSiteFailure = 'APPROVE SUPPLIER SITE FAILURE',
  RejectSupplierSiteFailure = 'REJECT SUPPLIER SITE FAILURE',
  FetchCurrentFiscalYearForSupplierSiteFailure = 'FETCH CURRENT FISCAL YEAR FOR SUPPLIER SITE FAILURE',
  FetchSuppliersSiteCitiesFailure = 'FETCH SUPPLIERS SITE CITIES FAILURE',
  FetchSupplierSitePendingRecordsFailure = 'FETCH SUPPLIERS SITE PENDING RECORDS FAILURE',

  ApproveSuppliersFailure='APPROVE SUPPLIERS FAILURE',
  RejectSuppliersFailure='REJECT SUPPLIERS FAILURE',
  DeleteSuppliersInvoiceContactFailure ="DELETE SUPPLIERS INVOICE CONTACT FAILURE",
  AddSuppliersInvoiceContactFailure ="ADD SUPPLIERS INVOICE CONTACT FAILURE",
  AddMasterSuppliersFailure = 'ADD MASTER SUPPLIERS FAILURE',

  FetchInvoicePrefixListFailure='FETCH INVOICE PREFIX LIST FAILURE',
  ArchiveInvoicePrefixFailure ='ARCHIVE INVOICE PREFIX FAILURE',
  AddInvoicePrefixFailure ='ADD INVOICE PREFIX FAILURE',

  FetchAutomatedSeidListFailure ='FETCH AUTOMATED SEID LIST FAILURE',
  FetchAvailableFiscalYearsForAutomationSeidFailure = 'FETCH AVAILABLE FISCAL YEARS FOR AUTOMATION SEID FAILURE',
  EditSupplierEngagementsAttributesFailure ="EDIT SUPPLIER ENGAGEMENTS ATTRIBUTES FAILURE",
  FetchPricingGroupListBySupplierFailure = 'FETCH PRICING GROUP LIST BY SUPPLIER FAILURE',
  ValidateAutomatedSeidRolloverFailure = "VALIDATE AUTOMATED SEID ROLLOVER FAILURE",
  GetFiscalYearToRolloverFailure = "FETCH FISCAL YEAR TO ROLLOVER FAILURE",
  UnAuthorizedError = "Unable to load user authorization at this moment. Please try again later.",
  AddAutomatedSeidFailure="ADD AUTOMATED SUPPLIER ENGAGEMEENTS FAILURE",
  ValidateUploadedAutomatedSeidRecordsFailure =' VALIDATE UPLOADED AUTOMATED SUPPLIER ENGAGEMENTS RECORDS FAILURE'

}
