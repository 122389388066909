import { ISupplierEngagement } from './SupplierEngagement.model';

// Interface for PO line item
export interface IPoLineItem {
  lineItemNumber: number;
  seid: number[];
  isPrePaid: boolean;
  description: string;
  amount: number;
  internalOrder: string;
  costCenter: string;
  accountCode: string;
}
export interface IPoLineItemNew {
  amount: number;
  month: number; // TODO: Change this to a string where possible.
  internalOrder: string;
  costCenter: string;
  accountCode: string;
  supplierEngagements: number[];
  description: string;
  isPrePaid: boolean;
  lineItemNumber: number;
}

export interface IPurchaseOrderAmoutAndLineItems {
  purchaseOrderAmount: number;
  purchaseOrderAmountFormula: string;
  purchaseOrderAmountBreakdown: string;
  purchaseOrderDescription: string;
  lineItemByMonths: IPoLineItemNew[];
}

// Private months list for quick updating.
const months: string[] = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const createLineItemsHelper = (
  filteredSupplierEngagementList: ISupplierEngagement[],
  lineItemMode: string
): IPoLineItem[] => {
  const lineItems: IPoLineItem[] = [];

  if (filteredSupplierEngagementList.length === 0) {
    return [];
  }
  // Building the line items if none is selected
  if (lineItemMode === 'none') {
    let totalAmount: number = 0;
    let maxSupplierEngagement: ISupplierEngagement = null;

    filteredSupplierEngagementList.forEach((se) => {
      // Summing all total amounts for each se.
      totalAmount += se.totalAmount;
      if (
        maxSupplierEngagement === null ||
        maxSupplierEngagement.totalAmount < se.totalAmount
      ) {
        // setting the supplier engagement with max budget.
        maxSupplierEngagement = se;
      }
    });
    // pushing the single line item to the line item list.
    lineItems.push({
      isPrePaid: false,
      lineItemNumber: 1,
      seid: filteredSupplierEngagementList.map((x) => x.supplierEngagementId),
      description: 'All SEIDs, All Months',
      amount: totalAmount,
      internalOrder:
        maxSupplierEngagement === null
          ? ''
          : maxSupplierEngagement.internalOrder,
      accountCode:
        maxSupplierEngagement === null ? '' : maxSupplierEngagement.accountCode,
      costCenter:
        maxSupplierEngagement === null ? '' : maxSupplierEngagement.constCenter,
    });
  } else if (lineItemMode === 'seid') {
    // The following logic creates line items per seid.
    // Create a line item per supplier engagement.
    let poNumber: number = 1;
    filteredSupplierEngagementList.forEach((se) => {
      lineItems.push({
        isPrePaid: false,
        lineItemNumber: poNumber++,
        seid: [se.supplierEngagementId],
        description: `${se.supplierEngagementId}, All Months`,
        amount: se.totalAmount,
        internalOrder: se.internalOrder,
        accountCode: se.accountCode,
        costCenter: se.constCenter,
      });
    });
  } else if (lineItemMode === 'month') {
    let poNumber = 1;

    // For each month
    months.forEach((month) => {
      let monthTotal = 0;
      const seidList: number[] = [];
      let maxSupplierEngagement: ISupplierEngagement = null;

      // for each seId.
      filteredSupplierEngagementList.forEach((se) => {
        if (
          -1 !==
          se.months.findIndex((item) => {
            return item.month === month;
          })
        ) {
          seidList.push(se.supplierEngagementId);
          monthTotal += se.months.find((x) => x.month === month).budgetAmount;
          if (
            maxSupplierEngagement === null ||
            maxSupplierEngagement.totalAmount < se.totalAmount
          ) {
            maxSupplierEngagement = se;
          }
        }
      });

      lineItems.push({
        isPrePaid: false,
        lineItemNumber: poNumber++,
        seid: seidList,
        description: `All SEIDs,${month}`,
        amount: monthTotal,
        internalOrder:
          maxSupplierEngagement === null
            ? ''
            : maxSupplierEngagement.internalOrder,
        accountCode:
          maxSupplierEngagement === null
            ? ''
            : maxSupplierEngagement.accountCode,
        costCenter:
          maxSupplierEngagement === null
            ? ''
            : maxSupplierEngagement.constCenter,
      });
    });
  }

  return lineItems;
};
