import { IPriorPeriodAdjustment } from '../models/PriorPeriodAdjustment';

export const FiscalPeriodsMockData: IPriorPeriodAdjustment[] = [
  {
    CumMonth: 541,
    FiscalYear: 'FY20',
    Period: 1,
    PeriodDetail: 'FY20 Jul [P1]',
    StartDate: '2019-07-01T00:00:00',
    EndDate: '2019-07-31T23:59:00',
    CalendarYear: 2019,
    DaysInPeriod: 31,
    Month: 'Jul',
    FiscalYearNumber: 2020,
  },
  {
    CumMonth: 542,
    FiscalYear: 'FY20',
    Period: 2,
    PeriodDetail: 'FY20 Aug [P2]',
    StartDate: '2019-08-01T00:00:00',
    EndDate: '2019-08-31T23:59:00',
    CalendarYear: 2019,
    DaysInPeriod: 31,
    Month: 'Aug',
    FiscalYearNumber: 2020,
  },
  {
    CumMonth: 543,
    FiscalYear: 'FY20',
    Period: 3,
    PeriodDetail: 'FY20 Sep [P3]',
    StartDate: '2019-09-01T00:00:00',
    EndDate: '2019-09-30T23:59:00',
    CalendarYear: 2019,
    DaysInPeriod: 30,
    Month: 'Sep',
    FiscalYearNumber: 2020,
  },
  {
    CumMonth: 544,
    FiscalYear: 'FY20',
    Period: 4,
    PeriodDetail: 'FY20 Oct [P4]',
    StartDate: '2019-10-01T00:00:00',
    EndDate: '2019-10-31T23:59:00',
    CalendarYear: 2019,
    DaysInPeriod: 31,
    Month: 'Oct',
    FiscalYearNumber: 2020,
  },
];
