import { IDetailsHeaderStyles } from 'office-ui-fabric-react';

export const customDetailsListHeaderStyles: Partial<IDetailsHeaderStyles> = {
  root: {
    backgroundColor: 'transparent',
    borderBottom: ' 1px solid lightgray;',
    height: '35px',
    paddingBottom: '10px',
    selectors: {
      '.ms-DetailsHeader-cell': {
        height: '100%',
      },
      '.ms-DetailsHeader-cellTitle': {
        height: '100%',
      },
      '.ms-DetailsHeader-cellTitle i': {
        margin: 'auto 0 0 0',
      },
      '.ms-DetailsHeader-cellName': {
        fontFamily: 'Segoe UI',
        color: '#333333',
        fontSize: '14px',
        fontWeight: 'bold',
        lineHeight: 'normal',
        margin: 'auto 0 0 0',
        whiteSpace: 'pre-line',
      },
    },
  },
};
