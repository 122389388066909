import { IInvoiceManagementCategory } from '../models/InvoiceManagement';


export const InvoiceManagementMockData: IInvoiceManagementCategory[] = [
  {
    invoiceId: 23,
    invoicePrefix: 'BIL-LAN-O',
    supplierName: 'Language Line Services',
    isActive: false,
    supplierMasterId: 'Language Line Services',
  },
  {
    invoiceId: 46,
    invoicePrefix: 'CFU-LAN-O',
    supplierName: 'Language Line Services',
    isActive: false,
    supplierMasterId: 'Language Line Services',
  },
  {
    invoiceId: 471,
    invoicePrefix: 'LLS01',
    supplierName: 'Language Line Services',
    isActive: true,
    supplierMasterId: 'Language Line Services',
  },
  {
    invoiceId: 275,
    invoicePrefix: 'PST-LLS-O',
    supplierName: 'Language Line Services',
    isActive: false,
    supplierMasterId: 'Language Line Services',
  },
  {
    invoiceId: 461,
    invoicePrefix: 'CVG01',
    isActive: true,
    supplierName: 'Concentrix',
    supplierMasterId: 'Concentrix',
  },
  {
    invoiceId: 462,
    invoicePrefix: 'CVG02',
    isActive: true,
    supplierName: 'Concentrix',
    supplierMasterId: 'Concentrix',
  },
  {
    invoiceId: 463,
    invoicePrefix: 'CVG03',
    isActive: false,
    supplierName: 'Concentrix',
    supplierMasterId: 'Concentrix',
  },
];
