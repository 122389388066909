import * as actionTypes from '../actions/actionTypes';

// Initialiing the feature state.
const initialState = {
isMyOrderEnabled:false,
isMyOrder2Enabled:false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MYORDER_FEATURE:
      return Object.assign({}, state, {
        isMyOrderEnabled: action.enabled,
      });
      case actionTypes.SET_MYORDER2_FEATURE:
        return Object.assign({}, state, {
          isMyOrder2Enabled: action.enabled,
        });

    default:
      return state;
  }
};

export default reducer;
