import { Stack } from 'office-ui-fabric-react';
import React from 'react';
import { connect } from 'react-redux';
import { ISupplier } from '../../shared/models';
import { IContract } from '../../shared/models/Contract.model';
import telemetryContext from '../../shared/services/TelemetryServices';
import { getSuppliers } from '../../store/actions';
import { getExistingContracts } from '../../store/actions/existingContracts.action';
import { ExistingContractsList } from './existing-contracts/ExistingContractsList';
import { ContractInfo } from './map-contract/MapContract';
import './SupplierContract.css';
import HelmetTab from '../../shared/Helmet/Helmet'

export interface ISupplierContractProps {
  existingContracts: IContract[];
  supplierList: ISupplier[];
  getExistingSuppliers(): any;
  populateExistingContractsList(): any;
}

export interface ISupplierContractState {
  shellInfoShown: boolean;
  contractHeight:string;
  contractmHeight:string;
  existingHeight:string;
  existingmHeight:string
}

export class SupplierContract extends React.Component<
  ISupplierContractProps,
  ISupplierContractState
> {
  constructor(props) {
    super(props);

    // initializing the state
    this.state = {
      shellInfoShown: true,
      contractHeight:'46vh',
      contractmHeight:'42vh',
      existingHeight:'43vh',
      existingmHeight:'40.8vh'
    };
  }

  // --------------------- LIFECYCLE METHODS ---------------------------- //
  public async componentDidMount() {
    // Logging page view.
    telemetryContext.logPageView();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    // this.props.loadExistingContracts()
    this.props.populateExistingContractsList();
    this.props.getExistingSuppliers();
  }

  resize = () =>{
    let widnowSize = window.innerWidth;
     if(widnowSize < 1000){
      this.setState({
      contractHeight:'115vh',
      contractmHeight:'120vh',
      existingHeight:'95vh',
      existingmHeight:'100vh'
      })
     }else{
      this.setState({
        contractHeight:'46vh',
        contractmHeight:'42vh',
        existingHeight:'43vh',
        existingmHeight:'40.8vh'
        })
     }
}

  public render() {
 
    return (
      <Stack className="mainLayoutGrid" verticalFill gap={20}>
      <div className="contractInformation" style={{height:this.state.contractHeight,maxHeight:this.state.contractmHeight}}>
        <HelmetTab title={'Supplier Contracts'} />
          <ContractInfo
            supplierList={this.props.supplierList}
            shellInfoShown={this.state.shellInfoShown}
            updateExistingContracts={() => {
              this.props.populateExistingContractsList();
            }}
          />
        </div>
        <div className="existingContracts" style={{height:this.state.existingHeight,maxHeight:this.state.existingmHeight}}>
          <ExistingContractsList
            listItems={this.props.existingContracts}
            showContractShellInfo={this.state.shellInfoShown}
          />
        </div>
       </Stack>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // For getting list of existing contracts
    populateExistingContractsList: () => {
      dispatch(getExistingContracts());
    },
    // For getting supplier List
    getExistingSuppliers: () => {
      dispatch(getSuppliers());
    },
  };
};

const mapStateToProps = state => {
  return {
    existingContracts: state.contracts.exsistingContracts,
    supplierList: state.suppliers.suppliersList,
    
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupplierContract);
