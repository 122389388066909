import * as React from 'react';
import { Nav } from '@fluentui/react/lib/Nav';


export interface IHelpState {
  isExpandState: boolean[],
  ismenu1:boolean,
  ismenu2:boolean,
  ismenu3:boolean
}
class Help extends React.Component<{}, IHelpState> {

  constructor(props) {
    super(props);
    this.state = {
      isExpandState: [false, false,false],
      ismenu1:false,
      ismenu2:false,
      ismenu3:false
    }
  }

  componentDidMount() {
    let currentPath = window.location.pathname;
    if (currentPath === '/help/engagement' || currentPath === '/help/rollover') {
      this.setState({
        ismenu1:true,
        ismenu2:false,
        ismenu3:false
      })

    }
    else if (currentPath === '/help/pricing' || currentPath === '/help/suppliercontracts') {
      this.setState({
        ismenu1:false,
        ismenu2:true,
        ismenu3:false
      })
    }
    else if (currentPath === '/help/billableunits' || currentPath === '/help/invoiceview' ||
      currentPath === '/help/purchaseOrders' ||
      currentPath === '/help/invoiceManagement' || currentPath === '/help/invoicing') {
      this.setState({
        ismenu1:false,
        ismenu2:false,
        ismenu3:true
      })
    }
  }
 
  render() {
    return (
      <div>
        <h1 style={{ marginLeft: 30, fontSize: 20 }}>Help Documents</h1>
        <Nav
          styles={{ root: { height:700,width: 300, marginLeft: 25, fontSize: 50, fontStyle:'normal' ,fontWeight:'lighter'} }}
          groups={[
            {
              links: [
                {
                  name: 'Engagement',
                  url: '/help/engagement',
                  isExpanded: this.state.ismenu1,
                  expandAriaLabel: 'Expand Engagement section',
                  collapseAriaLabel: 'Collapse Engagement section',
                  links: [
                    {
                      name: 'Engagement',
                      url: '/help/engagement',
                    },
                    {
                      name: 'Roll Over ',
                      url: '/help/rollover',
                    }
                  ]
                },
                {
                  name: 'Pricing',
                  url: '/help/pricing',
                  isExpanded: this.state.ismenu2,
                  links: [
                    {
                      name: 'Pricing',
                      url: '/help/pricing'
                    },
                    {
                      name: 'Supplier Contracts',
                      url: '/help/contract',

                    }
                  ]
                },
                {
                  name: 'Invoicing',
                  isExpanded: this.state.ismenu3,
                  url: '/help/invoiceManagement',
                  links:
                    [
                      {
                        name: 'Invoice Management',
                        url: '/help/invoiceManagement',
                      },
                      {
                        name: 'Purchase Order Management',
                        url: '/help/purchaseOrders',
                      },
                      {
                        name: 'Billableunits',
                        url: '/help/billableunits',
                      },
                      {
                        name: 'Invoice',
                        url: '/help/invoiceview',                      
                      }
                    ],                  
                },
                {
                  name: 'Supplier',
                  url: '/help/supplier',
                }
              ]
            }
          ]
          }
        />        
      </div>
    );
  }
}

export default Help;