import {
  DefaultPalette,
  Dropdown,
  IDropdownOption,
  IDropdownStyles,
  Label,
  mergeStyleSets,
  Stack,
  Checkbox,
} from 'office-ui-fabric-react';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import * as React from 'react';
import { toast } from 'react-toastify';
import gtoApiInstance, {
  gtoMsalApiFetch,
} from '../../../shared/http/GtoApiNew.axios';
import {
  ISupplier,
  Routes,
  TelemetryEvent,
  TelemetryException,
} from '../../../shared/models';
import { IContract } from '../../../shared/models/Contract.model';
import telemetryContext from '../../../shared/services/TelemetryServices';
import { CreateShellContractModal } from './CreateContractShellModal/CreateContractShellModal';
import './MapContract.css';
import { MapContractToShellModal } from './MapContractModal/MapContractModal';
import { SearchContractBox } from './search-contracts/SearchContractBox';
import { SearchResults } from './search-contracts/SearchResults';
import { v4 as uuidv4 } from 'uuid';
import { css } from 'glamor';

interface IContractsProps {
  shellInfoShown: boolean;
  updateExistingContracts: any;
  supplierList: ISupplier[];
}

// State for mapping a new contract
interface IContractsState {
  showCreateShellModal: boolean;
  showMapContractToShellModal: boolean;
  mapBtnEnabled: boolean;
  createContractShellEnabled: boolean;
  showSearchResults: boolean;
  mapTooltipString: string;
  contractSelected: IContract; // Using for Inserting and Mapping Contract
  matchingShellsList: IContract[];
  showDropdownComponent: boolean;
  isSharedContract: boolean;
  mapHeight:string;
  mapmHeight:string;
}

export class ContractInfo extends React.Component<
  IContractsProps,
  IContractsState
> {
  // Stores selected aravo dropdown key.
  private selectedKey: React.ReactText = '';
  // Styles definition for the Stack
  private stackStyles = mergeStyleSets({
    root: {
      selectors: {
        '> *': {
          alignItems: 'flex-start',
          display: 'flex',
          justifyContent: 'flex-start',
          color: DefaultPalette.black,
          fontWeight: '400',
        },
      },
    },
  });
  public constructor(props) {
    super(props);

    this.state = {
      showCreateShellModal: false,
      showMapContractToShellModal: false,
      mapBtnEnabled: false,
      createContractShellEnabled: true,
      showSearchResults: false,
      isSharedContract: false,
      mapTooltipString: 'Search a contract to enable',
      contractSelected: {
        contractNumber: 0,
        aravoEngagementId: '',
        supplierName: '',
        supplierNumber: 0,
        contractID: 0,
        companyCode: 0,
        expirationDate: '',
        expDateValue: 0,
        typeOfContract: 0,
        contractDescription: '',
        IsSharedContract: false,
      },
      matchingShellsList: [],
      showDropdownComponent: false,
      mapHeight:'43vh',
        mapmHeight:'43vh'
    };
  }
  public async componentDidMount() {
    this.resize()
  }


  resize = () =>{
    let widnowSize = window.innerWidth;
     if(widnowSize <1000){
      this.setState({
      mapHeight:'90vh',
      mapmHeight:'115vh'
      })
     }
}

  public render(): JSX.Element {
    const shellModal = this.getShellModal();
    const shellLink = this.getShellLink();

    // To render Dropdown based on the Search Result
    const showDropdown = this.getDropdown();

    return (
      <div className="contractInfo" style={{height: this.state.mapHeight,maxHeight: this.state.mapmHeight}}>
        {shellModal}
        <MapContractToShellModal
          //  showModal ={true}
          showModal={this.state.showMapContractToShellModal}
          closeModal={this.closeMapContractModal}
          listItems={this.state.matchingShellsList}
          mapNewContract={() => {
            this.mapNewContract();
          }}
          currentContract={this.state.contractSelected}
          updateExistingContracts={this.props.updateExistingContracts}
        />
        <h1 style={{ display: 'block', fontSize: '1.5em', margin: '0.83em 0 0.83em 0', fontWeight: 'bold', }}>Map A New Contract</h1>
        {window.innerWidth >1000 ?<div className="contractInfoGrid">
          <div className="searchBox">
            <SearchContractBox
              clearPressed={() => {
                this.clearSearchResults();
              }}
              handleContractChange={(contract) => {
                this.handleContractChange(contract);
              }}
            />
          </div>
        </div>
        :<div className="contractInfoGridAccessible">
          <div className="searchBox">
            <SearchContractBox
              clearPressed={() => {
                this.clearSearchResults();
              }}
              handleContractChange={(contract) => {
                this.handleContractChange(contract);
              }}
            />
          </div>
        </div>}
       
        <div className="searchResults" data-is-scrollable="true">
            {this.getSearchResults()}
          </div>
        
        {showDropdown}
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row" > 
              <div className="ms-Grid-col ms-sm8 ms-md8 ms-lg8">
              <div className="myLink" >
            {shellLink}
          </div>
                </div>
                <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                <TooltipHost
              content={this.state.mapTooltipString}
              id={'mapBtnTooltip'}
              calloutProps={{ gapSpace: 0 }}
            >
              <DefaultButton
                data-automation-id="mapBtn"
                text="Map Contract"
                onClick={() => this.mapBtnClicked()}
                style={{float: 'right' }}
                disabled={!this.state.mapBtnEnabled}
              />
            </TooltipHost>
                </div>
                </div>
                </div>

        {/* <div className="bottomNav">
          <div className="myLink" style={{ padding: '10px 0px' }}>
            {shellLink}
          </div>
          <div className="buttonGroup">
            <TooltipHost
              content={this.state.mapTooltipString}
              id={'mapBtnTooltip'}
              calloutProps={{ gapSpace: 0 }}
            >
              <DefaultButton
                data-automation-id="mapBtn"
                text="Map Contract"
                onClick={() => this.mapBtnClicked()}
                style={{ margin: '10px' }}
                disabled={!this.state.mapBtnEnabled}
              />
            </TooltipHost>
          </div>
        </div> */}
      </div>
    );
  }

  // On Supplier Dropdown change
  private onSupplierDropdownChange(o) {
    this.selectedKey = o.key;
    // Removing space and extra characters from the string
    this.selectedKey = o.key.substr(0, o.key.indexOf(' '));
    // const selectedDropdownKey = this.selectedKey.toString();
    // const array = selectedDropdownKey.split('-',2);
    const stringSN = this.selectedKey.toString();

    //this.state.contractSelected.supplierNumber = parseInt(stringSN);
    let selectedContract = { ...this.state.contractSelected };
    selectedContract.supplierNumber = parseInt(stringSN);

    this.setState({ contractSelected: selectedContract });

    // TODO: setting the selected contract and its company code and supplier number
    // this.props.onAravoIdSelection(o.key);
  }

  // On CompanyCode Dropdown change
  private onCompanyCodeDropdownChange(o) {
    this.selectedKey = o.key;
    // Removing space and extra characters from the string
    this.selectedKey = o.key.substr(0, o.key.indexOf(' '));
    // const selectedDropdownKey = this.selectedKey.toString();
    // const array = selectedDropdownKey.split('-',2);
    const stringCC = this.selectedKey.toString();
    // tslint:disable-next-line: no-unused-expression
    let selectedContract = { ...this.state.contractSelected };
    selectedContract.companyCode = parseInt(stringCC);
    //this.state.contractSelected.companyCode = parseInt(stringCC);
    this.setState({ contractSelected: selectedContract });
    // this.state.contractSelected.companyCode ===
    // TODO: setting the selected contract and its company code and supplier number
    // this.props.onAravoIdSelection(o.key);
  }

  // For showing dropdown based on the condition
  private getDropdown = () => {
    const dropdownStyles: Partial<IDropdownStyles> = {
      dropdown: { width: 350 },
      label: { fontWeight: 'bold', marginRight: '10px' },
      callout: {
        maxHeight: '10vh',
      },
    };

    // Allow user to select correct supplier
    if (this.state.showDropdownComponent) {
      return (
        <div style={{ paddingTop: '5px' }}>
          <Label required={true} style={{ color: 'red' }}>
            Please select a Supplier Number and a Company Code from the
            Dropdown.
          </Label>
          <Stack>
            <Stack
              horizontal={true}
              horizontalAlign="space-between"
              className={this.stackStyles.root}
              padding="s1 2%"
            >
              <Dropdown
                label="Select a Company Code "
                //defaultSelectedKey={this.selectedKey}
                options={this.buildCompanyCodeDropdown()}
                placeholder="Select a Company Code"
                disabled={false}
                styles={dropdownStyles}
                onChange={(e, o) => this.onCompanyCodeDropdownChange(o)}
              />
              <Dropdown
                label="Select a Supplier Number "
                //defaultSelectedKey={this.selectedKey}
                options={this.buildSupplierDropdown()}
                // options={this.setSupplierNames()}
                disabled={false}
                calloutProps={{ calloutMaxHeight: 220 }}
                styles={dropdownStyles}
                placeholder="Select a Supplier Number"
                onChange={(e, o) => this.onSupplierDropdownChange(o)}
              />
              <Checkbox
                checked={this.state.isSharedContract}
                onChange={this.handleCheckboxToggle}
                label="IsSharedContract"
                className="isSharedContractCheckBox"
                ariaLabel="IsSharedContract"
              />
            </Stack>
          </Stack>
        </div>
      );
    }
  };

  // For IsSharedContract check box change event
  private handleCheckboxToggle = () => {
    this.setState(
      (state) => ({
        isSharedContract: !state.isSharedContract,
      }),
      () => {
        let selectedContract = { ...this.state.contractSelected };
        selectedContract.IsSharedContract = this.state.isSharedContract;

        this.setState({ contractSelected: selectedContract });
      }
    );
  };

  // For showing Modal
  private getShellModal = () => {
    if (this.props.shellInfoShown) {
      return (
        <CreateShellContractModal
          showModal={this.state.showCreateShellModal}
          closeModal={this.closeCreateShellModal}
          updateExistingContracts={this.props.updateExistingContracts}
          supplierList={this.props.supplierList}
        />
      );
    }
  };

  // This function builds the CompanyCode dropdown. (Will use if needed)
  private buildCompanyCodeDropdown = (): IDropdownOption[] => {
    let options: IDropdownOption[] = [];
    let i = 0;

    const supplierCompanyCodes: string[] = this.props.supplierList.map(
      (x) => x.companyCode
    );
    const supplierListWithUniqueCC = Array.from(new Set(supplierCompanyCodes));

    // let temp:any = [];

    // temp = this.props.supplierList;
    supplierListWithUniqueCC.forEach((x) => {
      // if(temp.indexOf(x.companyCode) === -1){
      options.push({
        // Adding i to get individual keys for the dropDown
        key: `${x} ${i++}`,
        text: `${x}`,
      });

      // };
    });
    // let unique = new Set(options);
    // options =Array.from(unique);

    // console.log('Options for Set',unique);
    // console.log('Options for Array',options);
    return options;
  };

  // This function builds the supplier dropdown.
  private buildSupplierDropdown = (): IDropdownOption[] => {
    let options: IDropdownOption[] = [];
    let i = 0;

    this.props.supplierList.forEach((x) => {
      options.push({
        // Adding i to get individual keys for the dropDown
        key: `${x.supplierNumber} ${i++}`,
        // key: x.supplierLegalName + x.companyCode,
        text: `${x.supplierLegalName} ${x.supplierNumber} `,
        // text: `${x.supplierLegalName} - (${x.companyCode})`,
      });
    });

    // console.log(options);
    return options;
  };

  // set the list of supplier names based on the selected company code
  // call from API
  private setSupplierNames = (): IDropdownOption[] => {
    let supplierNames: IDropdownOption[] = [];
    let temp: string[] = [];

    // const url: string = Routes.FetchSupplierList;

    // Logging telemetry
    // this.logFetchSupplierDataBegin(url);

    // let response =  gtoMsalApiFetch(gtoApiInstance.get, url, {});

    // Mapping only the suppliers that match the company code selected
    for (let i = 0; i < this.props.supplierList.length; i++) {
      // Based on the company code selection diplay the suppliers and add them to dropDown
      if (
        this.state.contractSelected.companyCode.toString() ===
          this.props.supplierList[i].companyCode &&
        temp.indexOf(this.state.contractSelected.supplierName) === -1
      ) {
        temp.push(
          // this.props.supplierList[i].supplierLegalName +
          //   ' (' +
          this.props.supplierList[i].supplierNumber
          // ')'
        );
      }
    }
    // Logging success
    // this.logFetchSupplierDataSuccess(url);

    // sort the array in ascending order
    temp.sort((a, b) => (a > b ? 1 : -1));

    // map the current array to one with dropdown options
    supplierNames = temp.map((x) => ({
      key: x,
      text: x,
    }));

    return supplierNames;
    // set the current state with the unique supplier name options
    // this.setState({ contractSelected.: supplierNames });
  };

  // returns the link to create a contract shell
  private getShellLink = () => {
    if (this.props.shellInfoShown) {
      return (
        <div>
          <span style={{ fontSize: '15px' }}>
            Don't have a contract in Contract Pro yet?
          </span>
          <Link
            style={{ padding: '0px 10px' }}
            className="createShellLink"
            role="link"
            onClick={() => this.showCreateShellModal()}
            disabled={!this.state.createContractShellEnabled}
          >
            Create a Contract Shell
          </Link>
        </div>
      );
    }
  };

  // handle a change in the selected contract in the search box
  private handleContractChange(contract) {
    this.setState(
      {
        contractSelected: contract,
        showSearchResults: false,
        showDropdownComponent: false,
        isSharedContract: false,
      },
      this.setSearchResults
    );
  }

  // set the search results to be showing
  private setSearchResults = () => {
    this.setState({
      showSearchResults: true,
      mapBtnEnabled: true,
      createContractShellEnabled: false,
      showDropdownComponent: true,
    });
    this.setState({ mapTooltipString: 'Search a contract to enable' });
  };

  // Handler for clearing search results, and disabling map button
  private clearSearchResults = () => {
    this.setState({
      showSearchResults: false,
      mapBtnEnabled: false,
      createContractShellEnabled: true,
      showDropdownComponent: false,
    });
    this.setState({ mapTooltipString: '', isSharedContract: false });
  };

  // return the search results component with appropriate selected contract
  private getSearchResults() {
    return (
      <SearchResults
        masterContractID={this.state.contractSelected.contractID}
        showResults={this.state.showSearchResults}
      />
    );
  }

  // Handler for opening the Modal
  private showCreateShellModal = () => {
    // Setting the Modal state
    this.setState({ showCreateShellModal: true });
  };

  // Close Create Shell Modal
  private closeCreateShellModal = () => {
    this.setState({ showCreateShellModal: false });
  };

  // Show the map contract modal
  private showMapContractModal = () => {
    // Setting the Modal state
    this.setState({ showMapContractToShellModal: true });
  };

  // Close Map Contract against shell Modal
  private closeMapContractModal = () => {
    this.setState({ showMapContractToShellModal: false });
  };

  // handle a click of the "Map contract" button
  private mapBtnClicked = () => {
    // console.log('Map Contract Button Clicked');
    // Logging the click of the map contract button
    this.logMapButtonClicked();

    // Check to see if there are matching shells in the database
    this.checkForMatchingShells();
  };

  // see if there are any matching shells associated with the selected contract in our system
  private checkForMatchingShells = () => {
    let cCode = this.state.contractSelected.companyCode.valueOf();
    // If the API is returning Null value converting Supplier Numbers to empty string
    let sn =
      this.state.contractSelected.supplierNumber == null
        ? ''
        : this.state.contractSelected.supplierNumber;
    let sNumber = sn.valueOf();
    let CC = cCode.toString();
    let SN = sNumber.toString();
    if ((CC === '' && SN === '') || CC === '' || SN === '') {
      //   <div>
      //     <Label>Please select the required values from the dropdown</Label>
      //   </div>
      // );
    } else {
      // Defining url
      const url: string = `${Routes.FetchMatchingContractShells}?supplierNumber=${this.state.contractSelected.supplierNumber}&companyCode=${this.state.contractSelected.companyCode}`;
      // Defining the url
      // const url: string = Routes.FetchMatchingContractShells;
      // try to map the contract - api call

      // Logging beginning of api call
      this.logFetchMatchingContractShellsBegin(url);
      let matchingShellsList: IContract[] = [];

      try {
        // making this variable to use inside of function below
        let _this = this;
        this.callMatchingShellsAPI(url).then(function (response) {
          // Logging success
          _this.logFetchMatchingContractShellsSuccess(url);

          for (let i = 0; i < response.data.length; i++) {
            // initialize the contract object
            let contract: IContract = {
              aravoEngagementId: '',
              contractNumber: 0,
              contractID: 0,
              supplierName: '',
              supplierNumber: 0,
              contractDescription: '',
              companyCode: 0,
              typeOfContract: 0,
              expirationDate: '',
              expDateValue: 0,
              IsSharedContract: false,
            };

            // actually set the values
            contract.contractID = response.data[i].contractId;
            contract.supplierName = response.data[i].supplierName;
            contract.contractDescription = response.data[i].contractDescription;
            contract.companyCode = response.data[i].companyCode;
            contract.typeOfContract = response.data[i].contractType;
            contract.supplierNumber = response.data[i].supplierNumber;

            matchingShellsList.push(contract);
          }

          // if matching shells are found
          // show modal to user telling that matches were found,
          // ask if like to merge or map new
          if (matchingShellsList.length > 0) {
            _this.setState({
              matchingShellsList: matchingShellsList,
            });
            _this.showMapContractModal();
          }
          // if no shells were found, then just map the contract
          else {
            _this.mapNewContract();
          }
        });
      } catch (ex) {
        // log the exception
        this.logFetchMatchingContractShellsFailure(ex, url);

        // if (ex.response && ex.response.status === 404) {
        toast.error(
          'Matching shells information is not available at this moment.',
          {
            className: css({
              background: '#a80000 !important',
            }),
          }
        );
        // Setting the contract data as empty array as response had an error.
        matchingShellsList = [];
      }
    }
  };

  // Map a new contract - call API if no matching shells found in the system
  private mapNewContract = () => {
    // Defining the url
    const url: string = Routes.InsertContract;
    // try to map the contract - api call

    // Logging beginning of api call
    this.logInsertContractBegin(url);

    // making this variable to use inside of function below
    let _this = this;

    _this.callMapContractAPI(url).then(function (result) {
      // if there is a message, then there was an error
      if (result.Message !== undefined) {
        // log the exception
        _this.logInsertContractFailure(result.Message, url);
        // toast the error message

        toast.error(result.Message, {
          className: css({
            background: '#a80000 !important',
          }),
        });
      } else {
        // Logging success
        _this.logInsertContractSucess(url);
        toast.info('Contract has been mapped', {
          className: css({
            background: '#0275d8 !important',
          }),
        });
        _this.props.updateExistingContracts();
      }
    });
  };

  // -------------- ASYNC API CALLS --------------- //
  private async callMapContractAPI(url) {
    try {
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };
      let temp = await gtoMsalApiFetch(gtoApiInstance, url, {
        method: 'post',
        headers,
        data: this.state.contractSelected,
      });
      return temp;
    } catch (ex) {
      return ex.response.data;
    }
  }

  private async callMatchingShellsAPI(url) {
    try {
      // let temp = await gtoMsalApiFetch(gtoApiInstance, url, {
      //   method: 'post',
      //   data: this.state.contractSelected,
      // });
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };
      let temp = await gtoMsalApiFetch(gtoApiInstance, url, { headers });
      return temp;
    } catch (ex) {
      return ex.response.data;
    }
  }

  // -------------- END OF ASYNC API CALLS -------- //

  // ----------- LOGGING METHODS ------------------ //
  private logInsertContractBegin(url) {
    telemetryContext.logEvent(TelemetryEvent.InsertContractBegin, {
      url: url,
      contract: this.state.contractSelected,
    });
  }

  private logInsertContractSucess(url) {
    telemetryContext.logEvent(TelemetryEvent.InsertContractSuccess, {
      url: url,
      contract: this.state.contractSelected,
    });
  }

  private logInsertContractFailure(resultMessage, url) {
    telemetryContext.logException(
      resultMessage,
      TelemetryException.InsertNewContractFailure,
      undefined,
      {
        url: url,
        contract: this.state.contractSelected,
      }
    );
  }

  private logFetchMatchingContractShellsBegin(url) {
    telemetryContext.logEvent(TelemetryEvent.FetchMatchingContractShellsBegin, {
      url: url,
      contract: this.state.contractSelected,
    });
  }

  private logFetchMatchingContractShellsSuccess(url) {
    telemetryContext.logEvent(
      TelemetryEvent.FetchMatchingContractShellsSuccess,
      {
        url: url,
        contract: this.state.contractSelected,
      }
    );
  }

  private logFetchMatchingContractShellsFailure(resultMessage, url) {
    telemetryContext.logException(
      resultMessage,
      TelemetryException.FetchMatchingContractShellsFailure,
      undefined,
      {
        url: url,
        contract: this.state.contractSelected,
      }
    );
  }

  private logMapButtonClicked() {
    telemetryContext.logEvent(TelemetryEvent.MapContractButtonClick, {
      contract: this.state.contractSelected,
    });
  }
  // ----------- END OF LOGGING METHODS ----------- //
}
