import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { ContextualMenu } from 'office-ui-fabric-react/lib/ContextualMenu';
import { IDragOptions, Modal } from 'office-ui-fabric-react/lib/Modal';
import { getId } from 'office-ui-fabric-react/lib/Utilities';
import * as React from 'react';

export interface IPurchaseOrderModalProps {
  showModal: boolean;
  closeModal:any;
}

export class PurchaseOrderModal extends React.Component<
IPurchaseOrderModalProps,
  {}
> {

  // Use getId() to ensure that the IDs are unique on the page.
  // (It's also okay to use plain strings without getId() and manually ensure uniqueness.)
  private titleId: string = getId('title');
  private subtitleId: string = getId('subText');
  private dragOptions: IDragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
  };

  public render(): JSX.Element {
    return (
      <div>
        <Modal
          titleAriaId={this.titleId}
          subtitleAriaId={this.subtitleId}
          isOpen={this.props.showModal}
          onDismiss={this.props.closeModal}
          isBlocking={false}
          dragOptions={this.dragOptions}
        >
          <div>
            <DefaultButton
              aria-label = {'Close Modal'}
              onClick={this.props.closeModal}
              iconProps={{ iconName: 'Cancel' }}
              style={{ float: 'right' }}
            />
          </div>

          {this.props.children}
        </Modal>
      </div>
    );
  }


}
