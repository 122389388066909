export const InternalOrderPageStyles = {
    mainHeight: {
        height: "75.2vh",
        display: "grid" as "grid",
        gridArea: "main" as "main",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        padding: "5px",
        margin: "5px 10px 10px 7px",
        fontSize: "14px",
        background: "#fff",
        borderRadius: "2px",
        boxShadow: "0 3px 6px rgba(0, 0, 0, 0.23), 0 3px 6px rgba(0, 0, 0, 0.23)",
    },

    h1Style: {
        padding: '8px 4px 4px 5px',
        fontSize: '18.72px',
        textAlign: "left" as "left",
        borderTop: "1px solid rgb(237, 235, 233)",
        marginLeft: "5px",
        marginRight: "5px"
    },
    spinnerLoadingIcon: {
        root: {
            position: "fixed" as "fixed",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            background: "rgba(0, 0, 0, 0.1)",
            zIndex: 99999999,
        }
    },
    marginLeft20: {
        marginLeft: '20px'
    },
    marginTop25Left10: {
        marginTop: '26px',
        marginLeft: '10px'
    },
    marginTopLeft10: {
        marginTop: '10px',
        marginLeft: '10px'
    },
    marginTopLeft20TextAlign: {
        marginTop: '20px',
        marginLeft: '20px',
        paddingBottom: "20px"
    },
    marginTopLeft10BorderBottom: {
        paddingTop: '10px',
        paddingRight: '10px',
        paddingLeft: '10px',
        borderTop: "1px solid rgb(237, 235, 233)"
    },
    paddingTop51: {
        paddingTop: '51px'
    },
    paddingTop32: {
        paddingTop: '32px'
    },
    paddingTop12: {
        paddingTop: '12px'
    },
    paddingTop17: {
        paddingTop: '17px'
    },
    paddingBottom10: {
        paddingBottom: '10px'
    },
    paddingLeft54: {
        paddingLeft: "54px"
    },
    marginLeft10: {
        marginLeft: '10px'
    },
}