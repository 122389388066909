import * as actionTypes from '../actions/actionTypes';

const initialState = {
  billableUnits: [],
  monthList: [],
  amountTotal: [],
  totalAmount: [],
  priorPeriod: [],
  isFetchingInvoice: true,
  isFetchingMonth: true,
  IsErrorMessage: false,
  IsErrorMessageMonth: false,
  isBillableUnitChange: false,
  isColumnSorted: false,
  microsoftSubmittedBillableUnits: [],
  dataCount: {
    All: null,
    NRC: null,
    Volume: null,
    PPA: null,
  },
  nrcTypes: [],
  nrcCategories: [],
  nrcDescription: [],
  isDescriptionFetched: false,
  sdUsers: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_BILLABLE_UNITS:
      return Object.assign({}, state, { billableUnits: action.units });
    case actionTypes.SET_MONTH_LIST:
      return Object.assign({}, state, { monthList: action.months });
    case actionTypes.TOTAL_AMOUNT:
      return Object.assign({}, state, { amountTotal: action.amountTotal });
    case actionTypes.TOTAL_AMOUNT_CALCULATED:
      return Object.assign({}, state, { totalAmount: action.totalAmount });
    case actionTypes.SET_PERIOD_DETAIL_PRIOR_ADJUSTMENT:
      return Object.assign({}, state, { priorPeriod: action.priorPeriod });
    case actionTypes.REQUEST_BILLABLEUNITS_DETAILS:
      return Object.assign({}, state, { isFetchingInvoice: true });
    case actionTypes.REQUEST_BILLABLEUNITS_DETAILS_COMPLETE:
      return Object.assign({}, state, { isFetchingInvoice: false });
    case actionTypes.REQUEST_MONTHLIST_DETAILS:
      return Object.assign({}, state, { isFetchingMonth: true });
    case actionTypes.REQUEST_MONTHLIST_DETAILS_COMPLETE:
      return Object.assign({}, state, { isFetchingMonth: false });
    case actionTypes.SHOW_ERROR_MESSAGE:
      return Object.assign({}, state, { IsErrorMessage: true });
    case actionTypes.SHOW_ERROR_MESSAGE_COMPLETE:
      return Object.assign({}, state, { IsErrorMessage: false });
    case actionTypes.SHOW_ERROR_MESSAGE_MONTH:
      return Object.assign({}, state, { IsErrorMessageMonth: true });
    case actionTypes.SHOW_ERROR_MESSAGE_MONTH_COMPLETE:
      return Object.assign({}, state, { IsErrorMessageMonth: false });
    case actionTypes.SET_BILLABLEUNITS_CHANGE:
      return Object.assign({}, state, {
        isBillableUnitChange: action.isBillableUnitChange,
      });
    case actionTypes.IS_COLUMN_SORTED:
      return Object.assign({}, state, {
        isColumnSorted: action.isColumnSorted,
      });
    case actionTypes.GET_MICROSOFT_SUBMITTED_BILLABLE_UNITS:
      return Object.assign({}, state, {
        microsoftSubmittedBillableUnits: action.microsoftSubmittedBillableUnits,
      });
    case actionTypes.SET_BILLABLEUNITS_COUNT:
      return Object.assign({}, state, {
        dataCount: action.dataCount,
      });
    case actionTypes.GET_NRC_TYPES:
      return Object.assign({}, state, {
        nrcTypes: action.nrcTypes,
      });
    case actionTypes.GET_NRC_CATEGORIES:
      return Object.assign({}, state, {
        nrcCategories: action.nrcCategories,
      });
    case actionTypes.GET_NRC_DESCRIPTION:
      return Object.assign({}, state, {
        nrcDescription: action.nrcDescription,
      });
    case actionTypes.IS_NRC_DESCRIPTION_FETCHED:
      return Object.assign({}, state, {
        isDescriptionFetched: action.isDescriptionFetched,
      });
    case actionTypes.GET_SD_Users:
      return Object.assign({}, state, {
        sdUsers: action.sdUsers,
      });
    default:
      return state;
  }
};

export default reducer;
