import * as React from 'react';
import { Image, Icon } from 'office-ui-fabric-react';
import './Help.css';

class BillableUnitsViewDoc extends React.Component<{}> {

    render() {
        return (
            <div tabIndex={0}>
                <h4>Entering Billable Units (SDM View)</h4>
                <p>Go to the GTO Invoicing tool.On the top menu bar, select the Invoicing tab and Billable Units option.</p>
                <p>On the landing page, the Service Delivery Manager (SDM) view will be defaulted to the previous month for which you are invoicing.  For example, if the current month is December, the default view will show the November billable units.</p>
                <p>The billable units will be defaulted based on the last closed budget cycle.  SDMs will be able to see only the billable units for the Supplier Engagement IDs (SEIDs) assigned to them.</p>
                <Image src={require('../../assets/images/SDMView1.png')} alt="SDMView Image" style={{ height: 400, width: 900 }}></Image>
                <p>The SDMs will need to validate the billable units associated with the SEIDs, update the billable units (if needed) and submit.</p>
                <p>Once the billable units are submitted, the status will change from New to Pending Delivery Partner (i.e., S icon).</p>
                <p>SDMs can modify and submit multiple times the billable units even when the status shows as pending action from the Delivery Partner. </p>
                <p>SDMs have from the 13th last business day of the month to Noon (PST) of the 1st business day of the next month to modify the billable units.</p>
                <p>The default view will display a list of attributes at the SEID level to help SDMs identify each SEID.</p>
                <p>To change the default view and add additional attributes, select the ‘Change Table Attributes’ option at the top of the table grid.</p>
                <p>To view Non-Recurring Charges (NRCs), select the NRC tab.  SDMs will be able to view NRCs, only.  To add/schedule new NRCs, please contact Kavita Rawat karawat@microsoft.com.  Once new NRCs are added, they will reflect under the NRC tab.</p>
                <p>Select the ‘All’ tab to view both billable units and NRCs.</p>
                <p>Status Legend:</p>
                <p><Icon style={{ fontSize: '14px', color: 'blue', marginLeft: '1px', }} iconName="AlertSolid" />-	SDM to update billable units </p>
                <p><Icon style={{ fontSize: '14px', color: 'black', marginLeft: '1px', }} iconName="People" />- Pending action from Delivery Partner</p>
                <p>	<Icon style={{ fontSize: '14px', color: '#FFBC02', marginLeft: '1px', }} iconName="AwayStatus" />-Pending review from SDM</p>
                <p><Icon style={{ fontSize: '14px', color: 'red', marginLeft: '1px', }} iconName="StatusErrorFull" />-Rejected by SDM; prior period adjustments are submitted</p>
                <h4>Prior Period Adjustments:</h4>
                <p>After Noon of the 3rd last business day, invoices will be generated based on the billable units submitted by the Deliver Partners (DPs).  Any adjustments made by DPs will route to the SDM for review.  SDMs have 60 days to accept or reject any adjustments made by DPs.  Consequently, only adjustments for the prior two periods will be permitted.</p>
                <p>During the 60 days reconciliation period, a black exclamation point <Icon style={{ fontSize: '14px', color: 'black', marginLeft: '1px', }} iconName="AlertSolid" /> with the number of SEIDs that were adjusted by DPs will appear next to the prior period months.  To review and reconcile the adjustments, select the required prior period month.</p>
                <Image src={require('../../assets/images/SDMView2.png')} alt="SDMView Image" style={{ height: 350, width: 900 }}></Image>
                <p>SEIDs with billable units that were accepted by the DP will show a green status . No further action is required by SDMs on SEIDs that show a green status<Icon style={{ fontSize: '14px', color: 'green', marginLeft: '1px', }} iconName="CompletedSolid" />.</p>
                <p>SEIDs with billable units that were adjusted by the DP will show a yellow status .<Icon style={{ fontSize: '14px', color: '#FFBC02', marginLeft: '1px', }} iconName="AwayStatus" /></p>
                <p>SEIDs with a yellow status <Icon style={{ fontSize: '14px', color: '#FFBC02', marginLeft: '1px', }} iconName="AwayStatus" /> need to be reviewed by the SDMs.  If SDMs agree with the changes submitted by the DP, they will need to select Accept.  When ‘accepting’ the adjustments, you will not need to add a prior period adjustment because the invoice has been generated using the adjusted amount(s) entered by the DP.</p>
                On the other hand, if SDMs do not agree with the adjustments made by the DP, then, a prior period adjustment is required. <p></p>
                <p>To add a prior period adjustment, select the ‘Add Prior Period Adjustment’ option located on the top right of the table grid.</p>
                <p>You will be prompted to enter the SEID, the post period where adjustments are needed, the invoice period, and the new billable units.  Select submit.</p>
                <Image
                    src={require('../../assets/images/SDMView3.png')} alt="SDMView Image"></Image>
                <p>Once the prior period adjustment is submitted, the SEID will be updated with a ‘Rejected’ status</p>
                <p><Icon style={{ fontSize: '14px', color: 'red', marginLeft: '1px', }} iconName="StatusErrorFull" /> The new adjusted billable units will show in the next available invoicing period.</p>
                <p>For example, in a scenario where an SDM submitted a prior period adjustment for the month of October from 25.4 billable units to 5.4 billable units, the adjustments will be reflected in the month of November.</p>
                <p>‘Accruals’ and ‘Actuals’ for October reflect 25.4 billable units</p>
                <Image
                    src={require('../../assets/images/PPA1.png')} alt="SDMView Image" style={{ height: 350, width: 900 }}></Image>
                <Image
                    src={require('../../assets/images/PPA2.png')} alt="SDMView Image" style={{ height: 400, width: 900 }}></Image>
                <p>For November actuals, the invoicing view will reflect a prior period adjustment line.  You will see a negative entry of -25.4 which was already paid in October and a positive entry of 5.24 which shows October’s adjusted and correct invoice amount which will be paid in November.  In summary, invoice for October will not change and adjustments will be reflected in the next invoice.</p>
                <Image
                    src={require('../../assets/images/PPA3.png')} alt="SDMView Image" style={{ height: 380, width: 900 }}></Image>

            </div >
        )

    }
}
export default BillableUnitsViewDoc;