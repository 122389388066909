export const RollOverPageStyles = {
    mainHeight: {
        height: "77.2vh",
        display: "grid" as "grid",
        gridArea: "main" as "main",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        padding: "5px",
        margin: "5px 10px 10px 7px",
        fontSize: "14px",
        background: "#fff",
        borderRadius: "2px",
        boxShadow: "0 3px 6px rgba(0, 0, 0, 0.23), 0 3px 6px rgba(0, 0, 0, 0.23)",
    },

    h1Style: {
        padding: '8px 4px 4px 5px',
        fontSize: '18.72px',
        textAlign: "left" as "left",
        borderTop: "1px solid rgb(237, 235, 233)",
        marginLeft:"5px",
        marginRight:"5px"
    },
    spinnerLoadingIcon: {
        root: {
            position: "fixed" as "fixed",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            background: "rgba(0, 0, 0, 0.1)",
            zIndex: 99999999,
        }
    },
    divHeightScrollPanel: {
        height: '81vh',
        position: 'relative' as 'relative',
        marginLeft: '1vw'
    },
    marginLeft10: {
        marginLeft: '10px'
    },
    marginLeft20: {
        marginLeft: '20px'
    },
    paddingLeft10: {
        paddingLeft: '10px'
    },
    paddingBottom10: {
        paddingBottom: '10px'
    },
    paddingTop51: {
        paddingTop: '51px'
    },
    choiceGroupStyle: {
        flexContainer: { display: "flex" },
        root: { width: 800 }
    },
    paddingLeft54: {
        paddingLeft: "54px"
    },
    marginTopLeft10: {
        marginTop: '10px',
        marginLeft: '10px'
    },
    marginTopLeft30: {
        marginTop: '30px',
        marginLeft: '10px'
    },
    paddingTop40Left10: {
        paddingTop: '40px',
        paddingLeft: '10px'
    },
    paddingTopLeft46: {
        paddingTop: '46px',
        paddingLeft: '46px'
    },
    marginTop25Left10: {
        marginTop: '26px',
        marginLeft: '10px'
    },
    marginRight10: {
        marginRight: '10px'
    },

    grayBorder: {
        paddingTop: "15px",
        borderTop: "1px solid rgb(237, 235, 233)"
    },
    clearBoth: {
        clear: "both" as "both"
    },
    alignCentre: {
        alignItems: "center",
        justifyContent: "center"
    },
    floatLeftMarginTop10: {
        float: "left" as "left",
        marginTop: "10px"
    }

}
