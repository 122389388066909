import {
  IDetailsHeaderProps,
  SelectionMode,
  Sticky,
  StickyPositionType,
} from 'office-ui-fabric-react';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
} from 'office-ui-fabric-react/lib/DetailsList';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { Modal } from 'office-ui-fabric-react/lib/Modal';
import { getId, IRenderFunction } from 'office-ui-fabric-react/lib/Utilities';
import * as React from 'react';
import { toast } from 'react-toastify';
import gtoApiInstance, {
  gtoMsalApiFetch,
} from '../../../../shared/http/GtoApiNew.axios';
import {
  IContract,
  Routes,
  TelemetryEvent,
  TelemetryException,
} from '../../../../shared/models';
import telemetryContext from '../../../../shared/services/TelemetryServices';
import { contractColumns } from '../../../../shared/styles/ContractListColumns';
import { customDetailsListHeaderStyles } from '../../../../shared/styles/DetailsListHeaderStyles';
import './MapContractModal.css';
import { v4 as uuidv4 } from 'uuid';
import { css } from 'glamor';

export interface IMapContractToShellModalProps {
  showModal: boolean;
  closeModal: any;
  listItems: IContract[];
  mapNewContract: any;
  currentContract: IContract;
  updateExistingContracts: any;
}

export interface IMapContractToShellModalState {
  mapNewContract: boolean;
  selectionDetails: IContract;
  shellListItems: IContract[];
  mergeWithShellBtnEnabled: boolean;
}

export class MapContractToShellModal extends React.Component<
  IMapContractToShellModalProps,
  IMapContractToShellModalState
> {
  private _selection: Selection;
  // Use getId() to ensure that the IDs are unique on the page.
  // (It's also okay to use plain strings without getId() and manually ensure uniqueness.)
  private titleId: string = getId('title');
  private subtitleId: string = getId('subText');

  constructor(props) {
    super(props);
    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.state = {
      mapNewContract: false,
      shellListItems: this.props.listItems,
      selectionDetails: null,
      mergeWithShellBtnEnabled: false,
    };
  }

  // ---------------------- LIFECYCLE METHODS ------------------------ //
  static getDerivedStateFromProps(props) {
    return { shellListItems: props.listItems };
  }

  public componentDidUpdate(previousProps: any) {
    // logic to see if the list items have been updated
    if (previousProps.listItems !== this.props.listItems) {
      this.setState({ shellListItems: this.props.listItems });
    }
  }

  public render(): JSX.Element {
    return (
      <div>
        <Modal
          titleAriaId={this.titleId}
          subtitleAriaId={this.subtitleId}
          isOpen={this.props.showModal}
          onDismiss={this.props.closeModal}
          isBlocking={false}
        >
          <div>
            <DefaultButton // close ('x') button
              onClick={this.props.closeModal}
              iconProps={{ iconName: 'Cancel' }}
              style={{ float: 'right' }}
            />
          </div>

          <div className="mapContractContainer">
            <h2 style={{ width: '500px' }}>Matching Contract Shells Found</h2>
            <p>
              Would you like to map a new contract or merge it with an existing
              contract shell?
            </p>
            {/* <MarqueeSelection selection={this._selection}>
              <DetailsList
                items={this.state.shellListItems}
                columns={contractColumns.slice(0, 4)}
                setKey="set"
                layoutMode={DetailsListLayoutMode.fixedColumns}
                selection={this._selection}
                selectionPreservedOnEmptyClick={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              />
            </MarqueeSelection> */}
            <MarqueeSelection
              selection={this._selection}
              className="shellsList"
            >
              <DetailsList
                items={this.state.shellListItems}
                ariaLabelForGrid="Shells List"
                columns={contractColumns.slice(1, 4)}
                selectionMode={SelectionMode.single}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                selection={this._selection}
                selectionPreservedOnEmptyClick={true}
                enterModalSelectionOnTouch={true}
                onRenderDetailsHeader={(
                  detailsHeaderProps: IDetailsHeaderProps,
                  defaultRender: IRenderFunction<IDetailsHeaderProps>
                ) => {
                  return (
                    <Sticky stickyPosition={StickyPositionType.Header}>
                      {defaultRender({
                        ...detailsHeaderProps,
                        styles: customDetailsListHeaderStyles,
                      })}
                    </Sticky>
                  );
                }}
              />
            </MarqueeSelection>
            <div className="mapButtonGroup">
              <DefaultButton
                data-automation-id="mergeShellBtn"
                disabled={!this.state.mergeWithShellBtnEnabled}
                text="Merge with Shell"
                onClick={() => this.mergeShellBtnClicked()}
                style={{ margin: '10px' }}
              />
              <DefaultButton
                data-automation-id="mapNewBtn"
                text="Map New Contract"
                onClick={() => this.mapNewBtnClicked()}
                style={{ margin: '10px' }}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
  // -------------------- END OF LIFECYCLE METHODS --------------------- //

  private _getSelectionDetails(): IContract {
    const selectionCount = this._selection.getSelectedCount();

    switch (selectionCount) {
      case 0:
        this.setMergeWithShellBtnDisabled();
        return null;
      case 1:
        this.setMergeWithShellBtnEnabled();
        return this._selection.getSelection()[0] as IContract;
    }
  }

  private setMergeWithShellBtnEnabled() {
    this.setState({ mergeWithShellBtnEnabled: true });
  }

  private setMergeWithShellBtnDisabled() {
    this.setState({ mergeWithShellBtnEnabled: false });
  }

  private initStates = () => {
    this.setState({
      mapNewContract: false,
      shellListItems: this.props.listItems,
      selectionDetails: null,
      mergeWithShellBtnEnabled: false,
    });
  };

  // will directly map a new contract into the GTO system
  private mapNewBtnClicked = (): void => {
    this.closeModal();
    this.props.mapNewContract();
  };

  // will merge the selected contract with an existing shell in the GTO system
  private mergeShellBtnClicked = (): void => {
    this.closeModal();
    this.mergeContractWithShell();
  };

  private closeModal = (): void => {
    this.initStates();
    this._selection.setAllSelected(false);
    this.props.closeModal();
  };

  private mergeContractWithShell = () => {
    // Defining the url
    const url: string = `${Routes.InsertMergeContractWithShell}?shellContractId=${this.state.selectionDetails.contractID}`;
    let _this = this;
    // try to map the contract - api call

    // Logging beginning of api call
    _this.logInsertMergeContractWithShellBegin(url);

    _this
      .callMergeContractWithShellAPI(
        url,
        this.state.selectionDetails.contractID,
        this.props.currentContract
      )
      .then(function(result) {
        // if there is a message, then there was an error
        if (result.Message !== undefined) {
          // log the exception
          _this.logInsertMergeContractWithShellFailure(result.Message, url);
          // toast the error message
          toast.error(result.Message, {
            className: css({
              background: '#a80000 !important',
            }),
          });
        } else {
          // Logging success
          _this.logInsertMergeContractWithShellSuccess(url);
          toast.info('Contract has been merged with shell', {
            className: css({
              background: '#0275d8 !important',
            }),
          });
        }
      });
  };

  private async callMergeContractWithShellAPI(
    url,
    shellContractId,
    currentContract
  ) {
    try {
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };
      let temp = await gtoMsalApiFetch(gtoApiInstance, url, {
        method: 'post',
        headers,
        data: currentContract,
        shellContractId,
      });
      return temp;
    } catch (ex) {
      return ex.response.data;
    }
  }

  // ----------- LOGGING METHODS ------------------ //
  private logInsertMergeContractWithShellBegin(url) {
    telemetryContext.logEvent(
      TelemetryEvent.InsertMergeContractWithShellBegin,
      {
        url: url,
        contract: this.props.currentContract,
        selection: this.state.selectionDetails,
      }
    );
  }

  private logInsertMergeContractWithShellSuccess(url) {
    telemetryContext.logEvent(
      TelemetryEvent.InsertMergeContractWithShellSuccess,
      {
        url: url,
        contract: this.props.currentContract,
        selection: this.state.selectionDetails,
      }
    );
  }

  private logInsertMergeContractWithShellFailure(resultMessage, url) {
    telemetryContext.logException(
      resultMessage,
      TelemetryException.InsertMergeContractWithShellFailure,
      undefined,
      {
        url: url,
        contract: this.props.currentContract,
        selection: this.state.selectionDetails,
      }
    );
  }
  // ----------- END OF LOGGING METHODS ----------- //
}
