import {
  DefaultPalette,
  DetailsList,
  Fabric,
  IButton,
  IColumn,
  IconButton,
  IDetailsHeaderProps,
  IRenderFunction,
  mergeStyleSets,
  PrimaryButton,
  SelectionMode,
  Stack,
  Sticky,
  StickyPositionType,
  TextField,
  TooltipHost,
} from 'office-ui-fabric-react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  IContract,
  ISupplier,
  TelemetryEvent,
} from '../../shared/models/index';
import { CardStyle } from '../../shared/styles/Card';
import { customDetailsListHeaderStyles } from '../../shared/styles/DetailsListHeaderStyles';
import {
  deleteAravoIdForSupplier,
  getAravoToContractMapForSupplier,
  postAravoToContractMapForSupplier,
  requestAravoToContractMappingForSupplier,
  setReturnToSelectedSupplier,
} from '../../store/actions';
import ContractDetails from '../purchase-order-review/contract-details/ContractDetails';
import { IAravoToContractMap } from './../../shared/models/AravoToContractMap.model';
import telemetryContext from '../../shared/services/TelemetryServices';
import { Redirect } from 'react-router';
import appConfig from '../../assets/configuration/config';
import HelmetTab from '../../shared/Helmet/Helmet'

// Defining State interface for AddAravoEngagement component.
export interface IAddAravoEngagementState {
  aravoErrorMessage: string;
  contractErrorMessage: string;
  contracts: IContract[]; // Stores contract list loaded from api.
  selectedContracts: IContract[]; // Stores selected contract list updated by the handler.
  redirect: boolean; // For redirecting to the Supplier Page
  showresponsivegrid:string

}

// Defining Props interface for AddAravoEngagement component.
export interface IAddAravoEngagementProps {
  currentSupplier: ISupplier;
  // existingAravoMappings: [];
  loadAravoToContractMapping(supplier: ISupplier, fiscalYear?: string): any;
  selectedFiscalYear: number;
  contractsForSupplier: IContract[];
  isFetchingAravoToContractMap: boolean;
  aravoToContractMappings: IAravoToContractMap[];
  // existingAravoMappings: [];
  loadAravoToContractMapping(supplier: ISupplier, fiscalYear: string): any;
  handleAravoIdSubmit(supplier: ISupplier, userInput: IAravoToContractMap): any;
  deleteAravoId(supplier: ISupplier, aravoEngagementId: string): any;
  handleBackButtonClick(): any;
}


const backButtonRef = React.createRef<IButton>();

export class AddAravoEngagement extends React.Component<
  IAddAravoEngagementProps,
  IAddAravoEngagementState
> {
  // engagement input from user.
  private aravoEngagementUserInput: string = '';

  private columns: IColumn[];
  // Style for stack
  private stackStyles = mergeStyleSets({
    root: {
      selectors: {
        '> *': {
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          color: DefaultPalette.black,
          fontWeight: '400',
        },
      },
    },
  });
  // Constructor.
  public constructor(props) {
    super(props);

    this.columns = [
      {
        key: 'column5',
        name: 'Action',
        minWidth: 20,
        maxWidth: 50,
        isResizable: true,
        onRender: this.renderDeleteIcon,
        isPadded: true,
      },
      {
        key: 'aid',
        name: 'Engagement Id',
        fieldName: 'aravoEngagementId',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'contractNumber',
        name: 'Mapped Contracts',
        fieldName: 'contracts',
        minWidth: 200,
        maxWidth: 270,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        onRender:this._onRenderItemColumnvalues
      },
    ];
    // State Initialization
    this.state = {
      contractErrorMessage: '',
      aravoErrorMessage: '',
      contracts: [],
      selectedContracts: [],
      redirect: false,
      showresponsivegrid:'40vh'
    };
  }

  public componentDidMount() {
    // document.title = 'Add Engagements is the title for this page';
    if (this.props.selectedFiscalYear === null) {
      this.props.loadAravoToContractMapping(this.props.currentSupplier);
    } else {
      this.props.loadAravoToContractMapping(
        this.props.currentSupplier,
        this.props.selectedFiscalYear.toString()
      );
    }
    this.resize()
    backButtonRef.current.focus();
    // logging the page view for the engagement page.
    telemetryContext.logPageView();
  }

  resize = () =>{
    let widnowSize = window.innerWidth;
    if(widnowSize <= 854){
this.setState({
showresponsivegrid:'60vh'
})
    }
  }

  public render() {
    if (this.state.redirect === true) {
      // Calculating path
      const path: string = `/`;
      return <Redirect push={true} to={path} />;
    }
    return (
      <div
        style={{
          backgroundColor: 'rgb(232, 237, 241, 0.8)', // This is used for setting the background color.
          height: '100%',
          marginTop: 0,
          width: '100vw',
          overflowX: 'auto',
        }}
      >
        <HelmetTab title={'Engagements'} />
        <div
          className=""
          style={{
            width: '98vw', // 98 vw is the width of the page.
            marginTop: '5vh', // 5 vh Is margin top for this page.
            margin: '0 auto', // This is used to center content
          }}
        >
          <div style={{ display: 'flex', paddingTop: '10px' }}>
            <TooltipHost content="Back to Supplier Page">
              <PrimaryButton
                data-automation-id="test"
                onClick={this.backHandler}
                role="link"
                ariaDescription="Link for going back to supplier home page."
                iconProps={{ iconName: 'ChromeBack' }}
                componentRef={backButtonRef}
                style={{
                  marginTop: 7,
                }}
              />
            </TooltipHost>

            <h1
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingTop: '10px',
                paddingBottom: '10px',
                paddingLeft: '10px',
                fontSize: '19px',
              }} // Using this style to create more distance from top.
              aria-label={'Engagements'}
            >
              Engagements
            </h1>
          </div>

          <div style={{ margin: '1vh', ...CardStyle }}>
            <h2 style={{ padding: '1vh', fontSize: '18.72px' }} aria-label={'Add Engagements'}>
              {' '}
              Add Engagements
            </h2>

            {/* {this.state.showresponsivegrid ?  */}
            <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row parent" > 
              <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2"><Stack>
                  <div
                    style={{
                      textAlign: 'center',
                      height: 'auto',
                      fontWeight: 700,
                      fontSize: '15px',
                    }}
                    id="aravo_engagement_id"
                  >
                    <span aria-label={'Engagement ID '}>Engagement Id: </span>
                  </div>
                  <div
                    style={{
                      textAlign: 'center',
                      height: 'auto',
                      margin: '2vh',
                    }}
                  >
                    <span>
                      <TextField
                        errorMessage={this.state.aravoErrorMessage}
                        onChange={this.handleAravoInputChange}
                        id="aravoid"
                        ariaLabel="Engagement Id"
                        // Aria-label={'Supplier List'}
                        placeholder="Enter Engagement Id "
                        required={true}
                        autoComplete="on"
                        styles={{
                          root: { width: '16vw' },
                        }}
                        // htmlFor="aravo_engagement_id"
                      />
                    </span>
                  </div>
                </Stack>
                </div>
                <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2"><Stack>
                  <div
                    style={{
                      textAlign: 'center',
                      height: 'auto',
                      fontWeight: 700,
                      fontSize: '15px',
                    }}
                    id="supplier_name"
                    >
                    <span aria-label={'Supplier Name'}>Supplier Name</span>
                  </div>

                  <div
                    style={{
                      textAlign: 'center',
                      height: 'auto',
                      margin: '2vh',
                    }}
                  >
                      <span style={{ marginRight: '3vh' }}>
                      {this.props.currentSupplier.masterSupplierName}
                    </span>
                  </div>
                </Stack>
                </div>
                <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2"><Stack>
                  <div
                    style={{
                      textAlign: 'center',
                      height: 'auto',
                      fontWeight: 700,
                      fontSize: '15px',
                    }}
                    id="supplier_number"
                    >
                  <span aria-label={'Supplier Number'}>Supplier Number</span>
                  </div>
                  <div
                    style={{
                      textAlign: 'center',
                      height: 'auto',
                      margin: '2vh',
                    }}
                  >
                   <span style={{ marginRight: '3vh' }}>
        {this.props.currentSupplier.supplierNumber}
      </span>
                  </div>
                </Stack>
                </div>
                <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2"><Stack>
                  <div
                    style={{
                      textAlign: 'center',
                      height: 'auto',
                      fontWeight: 700,
                      fontSize: '15px',
                    }}
                    id="company_code"
    >
      <span aria-label={'Company Code'}>Company Code</span>
                  </div>
                  <div
                    style={{
                      textAlign: 'center',
                      height: 'auto',
                      margin: '2vh',
                    }}
                  >
                    <span style={{ marginRight: '3vh' }}>
        {this.props.currentSupplier.companyCode}
      </span>
                  </div>
                </Stack>
                </div>
                <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg3"><Stack>
                  <div
                    style={{
                      textAlign: 'center',
                      height: 'auto',
                      fontWeight: 700,
                      fontSize: '15px',
                    }}
                    id="fiscal_year"
    >
      <span aria-label={'Fiscal Year'}>Fiscal Year</span>
                  </div>
                  <div
                    style={{
                      textAlign: 'center',
                      height: 'auto',
                      margin: '2vh',
                    }}
                  >
                     <span style={{ marginRight: '3vh' }}>
        {this.props.selectedFiscalYear}
      </span>
                  </div>
                </Stack>
                </div>
              
          </div>
      </div>
            <ContractDetails
              showCard={false}
              listWidth="90vw"
              listHeight="35vh"
              contractItems={this.props.contractsForSupplier}
              selectionMode={SelectionMode.multiple}
              onContractSelection={this.handleContractSelection}
            />
            <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row" > 
              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
              <TooltipHost
                content={this.validateContractSelection()}
                id={'submitAddEngagementContractId'}
                style={{float:'left'}}
              >
                <PrimaryButton
                  data-automation-id="test"
                  id={'submitAddEngagementContractId'}
                  disabled={
                    (this.validateAravoInput() === null &&
                    this.validateContractSelection() === null
                      ? false
                      : true) || this.props.isFetchingAravoToContractMap
                  }
                  text="Submit"
                  onClick={this.handleAravoSubmit}
                  ariaLabel="Submit"
                  data-custom-parentid = "Add Engagement Contract"
                />
              </TooltipHost>
                </div>
                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                <p
                style={{
                  padding: '0px 10px 5px',
                  float: 'right',
                  margin: '10px',
                  fontSize: '18px',
                  fontWeight: 600,
                }}
              >
                {' '}
                Need to create an Engagement ID?
                <a
                  href={appConfig.AravoUrl}
                  style={{
                    padding: '6px 10px 4px',
                    float: 'right',
                    fontSize: '13px',
                    fontWeight: 400,
                  }}
                  className="createShellLink"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="startTheEngagementProcessId"
                  data-custom-parentid = "Start The Engagement Process"
                >
                  Start the Engagement Process
                </a>
              </p>
                </div>
                </div>
                </div>
          </div>
          <div style={{ margin: '1vw', ...CardStyle }}>
            <h2 style={{ padding: '5px', fontSize: '18.72px' }}> View/Delete Engagements</h2>
            {this.getAravoToContractMapContent()}
          </div>
        </div>
      </div>
    );
  }

  private backHandler = () => {
    this.props.handleBackButtonClick();
    this.setState({ redirect: true });
  };

  private getAravoToContractMapContent() {
    if (!this.props.isFetchingAravoToContractMap) {
      return (
        <Fabric
          style={{
            width: '90vw',
            height: this.state.showresponsivegrid,
            overflow: 'auto',
            display: 'block',
            margin: '0 auto',
          }}
        >
          <DetailsList
            className="searchResultsList"
            checkboxVisibility={2} // to hide the checkboxes
            // componentRef={this._root}
            items={this.getItems()} // {contractItems}
            columns={this.columns} // {this._columns}
            ariaLabelForGrid="Engagements List"
            onRenderDetailsHeader={(
              detailsHeaderProps: IDetailsHeaderProps,
              defaultRender: IRenderFunction<IDetailsHeaderProps>
            ) => {
              // custom header styles
              return (
                <Sticky stickyPosition={StickyPositionType.Header}>
                  {defaultRender({
                    ...detailsHeaderProps,
                    styles: customDetailsListHeaderStyles,
                  })}
                </Sticky>
              );
            }}

            // onRenderItemColumn={this._onRenderColumn}
          />
        </Fabric>
      );
    } else {
      return (
        <div aria-label={'Loading'} style={{ paddingTop: '5vh' }}>
          <Spinner
            style={{ height: '100%' }}
            label="Loading..."
            size={SpinnerSize.large}
          />
        </div>
      );
    }
  }
  private getItems() {
    const items: any[] = [];
    this.props.aravoToContractMappings.forEach((x) => {
      items.push({
        key: x.AravoEngagementId,
        aravoEngagementId: x.AravoEngagementId,
        contracts: x.ContractIds.join(', '),
      });
    });
    return items;
  }
  private getGroups() {
    return [];
  }
  // Textfield of Aravo Id change
  private handleAravoInputChange = (e, newValue: string) => {
    // When the input changes update the user input state.
    this.aravoEngagementUserInput = newValue;
    const aravoIdErrorMessage = this.validateAravoInput();

    this.setState({
      aravoErrorMessage:
        aravoIdErrorMessage === null ? '' : aravoIdErrorMessage,
    });
  };

  // AravoId Submit Handler
  private handleAravoSubmit = async () => {
    const userInput: IAravoToContractMap = {
      SupplierId: this.props.currentSupplier.supplierId,
      FiscalYearNumber: this.props.selectedFiscalYear,
      AravoEngagementId: this.aravoEngagementUserInput,
      ContractIds: [],
      SharedContractIds: [],
    };

    this.state.selectedContracts.forEach((x) => {
      if (x.IsSharedContract) {
        userInput.SharedContractIds.push(x.contractID.toString());
      } else {
        userInput.ContractIds.push(x.contractID.toString());
      }
    });

    this.props.handleAravoIdSubmit(this.props.currentSupplier, userInput);

    telemetryContext.logEvent(
      TelemetryEvent.SubmitAravoEngagementIdButtonClick,
      {
        insertedAravoId: this.aravoEngagementUserInput,
        selectedContracts: this.state.selectedContracts.toString(),
      }
    );

    // telemetryContext.logEvent(
    //   TelemetryEvent.SubmitAravoEngagementIdButtonClick,
    //   {
    //     aravoInput: this.aravoEngagementUserInput,
    //     supplierName: 'test', //  TODO: This needs to be read from redux and loaded in component on mount
    //     supplierNumber: this.props.currentSupplier.supplierNumber, // TODO: This needs to be read from redux and loaded in component on mount
    //   }
    // );

    // // Creating headers
    // const headers = {
    //   'X-CorrelationId': uuidv4(),
    //   SubCorrelationKey: uuidv4(),
    // };
    // // Creating the url
    // const url: string = `${Routes.UpdateAravoEngagementId}?aravoEngagementId=${
    //   this.aravoEngagementUserInput
    // }&fiscalYearNumber=2019&supplierId=${
    //   this.props.currentSupplier.supplierId
    // }`;

    // // TODO: The above api needs to be rewritten to accept the selected contract information upon submit.
    // // Log Telemetry event
    // telemetryContext.logEvent(TelemetryEvent.UpdateAravoEngagementIdBegin, {
    //   aravoId: this.aravoEngagementUserInput,
    //   url: url,
    //   headers,
    // });
    // try {
    //   // POST API call for the Aravo engagement id.
    //   const result = await gtoMsalApiFetch(gtoApiInstance, url, {
    //     method: 'post',
    //     headers,
    //   });

    //   // Logging success
    //   telemetryContext.logEvent(TelemetryEvent.UpdateAravoEngagementIdSuccess, {
    //     aravoId: this.aravoEngagementUserInput,
    //     headers,
    //     response: result.data,
    //     url,
    //   });

    //   // TODO: Redirect to the previous page at this point.

    // toast.success(
    //   `Successfully added aravo engagement Id: ${
    //     this.aravoEngagementUserInput
    //   }and will be avaliable during PO creation. `, {
    //     className: css({
    //       background: '#107c10 !important',
    //     }),
    //   }
    // );
    // } catch (ex) {
    //   // log exception
    //   telemetryContext.logException(
    //     ex.toString(),
    //     TelemetryException.UpdateAravoEngagementIdFailure,
    //     undefined,
    //     {
    //       aravoId: this.aravoEngagementUserInput,
    //       headers,
    //       url,
    //     }
    //   );

    //   // TODO we need to change error handling based on validation response from the Api.
    //   if (
    //     ex.response !== undefined &&
    //     (ex.response.status === 404 || ex.response.status === 400)
    //   ) {
    //   toast.error('An error occured during submission. Please try again.', {
    //     className: css({
    //         background: "#a80000 !important"
    //     })
    // });
    //   }
    // }
  };

  // This function validates the contract selection.
  private validateContractSelection = () => {
    let contractErrorMessage = null;
    if (this.state.selectedContracts.length === 0) {
      contractErrorMessage = 'Please select a contract before submitting';
    }

    return contractErrorMessage;
  };

  // This function renders the delete icon.
  private renderDeleteIcon = (item: any): JSX.Element => {
    return (
      <TooltipHost
        content={'Delete Aravo Id'}
        id={'deleteAravo'}
        calloutProps={{ gapSpace: 0 }}
      >
        <IconButton
          style={{ height: '10px', textAlign: 'center' }}
          data-automation-id="test"
          iconProps={{ iconName: 'Delete' }}
          // tslint:disable-next-line: jsx-no-lambda
          onClick={() => this.handleDeleteAravo(item.aravoEngagementId)}
          aria-label={`Delete Engagement id ${item.aravoEngagementId}`}
        />
      </TooltipHost>
    );
  };
  private _onRenderItemColumnvalues = (item: any, index: number, column: IColumn): JSX.Element | React.ReactText => {
    if (item.contracts === null) {
        return (
            <span role="cell" >No Mapped Contracts</span>
        );
    }else{
      return (
        <span role="cell" >{item.contracts}</span>
    );
    }
};
  // Validation for Engagement Id
  private validateAravoInput = () => {
    const alphaNumeric = /^[0-9-]+$/;
    let aravoIdErrorMessage = null;
    if (
      this.aravoEngagementUserInput === null ||
      this.aravoEngagementUserInput === ''
    ) {
      aravoIdErrorMessage = 'Engagement Id cannot be empty';
    } else if (!this.aravoEngagementUserInput.match(alphaNumeric)) {
      aravoIdErrorMessage = 'Engagement Id can only contain numbers and -';
    }

    return aravoIdErrorMessage;
  };

  private handleDeleteAravo(aravoEngagementId: string): void {
    this.props.deleteAravoId(this.props.currentSupplier, aravoEngagementId);

    telemetryContext.logEvent(
      TelemetryEvent.DeleteAravoEngagementIdButtonClick,
      {
        aravoEngagementId,
      }
    );
  }

  // This function handles the supplier contract selection.

  private handleContractSelection = (selectedContracts: IContract[]) => {
    this.setState({
      selectedContracts,
    });
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleAravoIdSubmit: (
      supplier: ISupplier,
      userInput: IAravoToContractMap
    ) => {
      dispatch(requestAravoToContractMappingForSupplier());
      dispatch(postAravoToContractMapForSupplier(supplier, userInput));
    },
    loadAravoToContractMapping: (
      supplier: ISupplier,
      fiscalYearNumber: string
    ) => {
      dispatch(getAravoToContractMapForSupplier(supplier, fiscalYearNumber));
    },
    deleteAravoId: (supplier: ISupplier, aravoEngagementId: string) => {
      dispatch(requestAravoToContractMappingForSupplier());
      dispatch(deleteAravoIdForSupplier(supplier, aravoEngagementId));
    },
    handleBackButtonClick: () => {
      dispatch(setReturnToSelectedSupplier(true));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    contractsForSupplier: state.selectedSupplier.contracts,
    currentSupplier: state.selectedSupplier.basicInfo,
    selectedFiscalYear: state.selectedSupplier.selectedFiscalYear,
    aravoToContractMappings: state.selectedSupplier.aravoToContractMapping,
    isFetchingAravoToContractMap:
      state.selectedSupplier.isFetchingAravoEngagementToContractMapping,
    // existingAravoMappings: [];
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAravoEngagement);
