import { IRenderFunction } from '@uifabric/utilities';
import { SpinnerSize, Spinner } from 'office-ui-fabric-react';
import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IDetailsHeaderProps,
  Selection,
  SelectionMode,
} from 'office-ui-fabric-react/lib/DetailsList';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { Sticky, StickyPositionType } from 'office-ui-fabric-react/lib/Sticky';
import * as React from 'react';
import { IContract } from '../../../shared/models/index';
import { customDetailsListHeaderStyles } from '../../../shared/styles/DetailsListHeaderStyles';
import './ContractDetails.css';

// Interface for contract details props
export interface IContractDetailsProps {
  contractItems: IContract[];
  onContractSelection: any;
  selectionMode: SelectionMode;
  listHeight: string;
  listWidth: string;
  showCard: boolean;
}

// Interface for contract details state
export interface IContractDetailsState {
  showContractModal: boolean;
  selectedContractId: number;
}

// This class renders the cotract details section on the purchase order review page.
class ContractDetails extends React.Component<
  IContractDetailsProps,
  IContractDetailsState
> {
  private selection: Selection;
  private columns: IColumn[];
  private selectedContract: number;

  constructor(props: IContractDetailsProps) {
    super(props);

    // Initializing the columns
    this.columns = [
      {
        key: 'column1',
        name: 'Contract ID',
        fieldName: 'contractNumber', // TODO:
        minWidth: 100,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column2',
        name: 'Expiry Date',
        fieldName: 'expirationDate',
        minWidth: 90,
        maxWidth: 150,
        isResizable: true,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column3',
        name: 'Title',
        fieldName: 'contractDescription',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column4',
        name: 'IsSharedContract',
        fieldName: 'IsSharedContract',
        minWidth: 90,
        maxWidth: 150,
        isResizable: true,
        data: 'string',
        isPadded: true,
      },
    ];

    // Setting up the selection for contract details.
    this.selection = new Selection({
      onSelectionChanged: () => {
        this.getSelectionDetails();
      },
    });

    // TODO: This isn't working yet. Look into selections to see what the issue may be.
    this.selection.setAllSelected(true);

    this.state = {
      selectedContractId: 0,
      showContractModal: false,
    };
  }

  public render() {
    return (
      <React.Fragment>
        <div className={this.getClassName()}>
          <h2 className="contract-details-header">Contract Details</h2>
          <Fabric
            style={{
              width: this.props.listWidth,
              height: this.props.listHeight,
              overflow: 'auto',
              display: 'block',
              margin: '0 auto',
            }}
          >
            {this.props.contractItems.length > 0 ? (
              <DetailsList
                items={this.props.contractItems}
                compact={false}
                columns={this.columns}
                // selectionMode={SelectionMode.multiple}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                selection={this.selection}
                selectionMode={this.props.selectionMode}
                enterModalSelectionOnTouch={true}
                ariaLabelForGrid="Contract Details List"
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Selection for all items"
                checkButtonAriaLabel={"Select Contract"}
                // tslint:disable-next-line: jsx-no-lambda
                onRenderDetailsHeader={(
                  detailsHeaderProps: IDetailsHeaderProps,
                  defaultRender: IRenderFunction<IDetailsHeaderProps>
                ) => {
                  return (
                    <Sticky stickyPosition={StickyPositionType.Header}>
                      {defaultRender({
                        ...detailsHeaderProps,
                        styles: customDetailsListHeaderStyles,
                      })}
                    </Sticky>
                  );
                }}
              />
            ) : (
              <Spinner style={{ marginTop: '10%' }} size={SpinnerSize.large} />
            )}
          </Fabric>
        </div>
      </React.Fragment>
    );
  }

  private getClassName = () => {
    if (this.props.showCard) {
      return 'contract-container';
    } else {
      return 'contract-container-nocard';
    }
  };

  // Close Contract Modal
  private closeContractModal = () => {
    this.setState({ showContractModal: false });
  };

  // Setting state to true for Contract Modal
  private showContractModal = (contractNumber: number) => {
    // Setting the Modal state
    this.setState({
      showContractModal: true,
      selectedContractId: contractNumber,
    });
  };

  // This function is invoked when a selection on the contracts page is changed.
  private getSelectionDetails() {
    // Call parent components handler to notify regarding selection change.
    this.props.onContractSelection(this.selection.getSelection());
  }
}

export default ContractDetails;
