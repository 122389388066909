export interface IBudget {
  OriginalBudgetLC: number;
  OriginalBudgetCD: number;
  YTDActualsLC: number;
  YTDActualsCD: number;
  BudgetAfterJudgementLC: number;
  BudgetAfterJudgementCD: number;
  AdjustmentsLC: number;
  AdjustmentsCD: number;
  ProjectedSpendLC: number;
  ProjectedSpendCD: number;
  EstimatedAmountLC: number; // This value is calculated as a summation of the AmountLC values of every month.
  EstimatedAmountCD: number; // This value is calculated as a summation of the AmountCD values of every month.
  OriginalPOAmountLC: number;
  OriginalPOAmountCD: number;
  BudgetAndActualDetails: IBudgetAndTrend[];
}

export interface IBudgetAndTrend {
  CalenderMonth: string;
  CalendarYear: number;
  CumulativeMonth: number;
  TrendType: string;
  ExchangeRateAmt: number;
  AmountLC: number;
  AmountCD: number;
  BudgetLC: number;
  BudgetCD: number;
}

// Getting the amount in decimal format and converting it to coma seprated string
export const getDecimalAdjustedStringAmount = amount => {
  return (Math.round(amount * 100) / 100).toLocaleString('en');
};
