import * as actionTypes from './actionTypes';
import { v4 as uuidv4 } from 'uuid';
import {
  IUser,
  Routes,
  TelemetryEvent,
  TelemetryException,
} from '../../shared/models';
import telemetryContext from '../../shared/services/TelemetryServices';
import gtoApiInstance, {
    gtoMsalApiFetch,
  } from '../../shared/http/GtoApiNew.axios';
import gtoNewApiInstance from '../../shared/http/GtoApiNew.axios';
import appConfig from '../../assets/configuration/config';
import { UserPreferences } from '../../shared/models/UserPreferences';
import { delay } from 'q';


export const setUserRoles = (user: IUser) => {
  return {
    type: actionTypes.SET_USER_ROLES,
    userDetails: user,
  };
};

export const setUserModules = userRoles => {
  return {
    type: actionTypes.SET_USER_MODULES,
    modules: userRoles.UserRoles,
    IsAdmin: userRoles.IsAdmin,
  };
};

// This action can be used to fetch when the user falied.
export const fetchUserFailed = () => {
  return {
    type: actionTypes.FETCH_USER_FAILED,
  };
};

// this action can be used to toggle the IsSynthetic Flag
export const toggleIsSyntheticFlag = () => {
  return {
    type: actionTypes.SET_SYNTHETIC_FLAG,
  };
};

// This action can be used to set the global loading flag.
export const setIsLoadingGlobal = (isLoadingGlobal: boolean) => {
  return {
    type: actionTypes.SET_IS_LOADING_GLOBAL_FLAG,
    isLoadingGlobal,
  };
};




/*--------------------------------------THUNK ACTION CREATORS-----------------------------------*/

export const getUserModules = () => {
 return dispatch => {
    const headers: object = {
      'X-CorrelationId': uuidv4(),
      SubCorrelationKey: uuidv4(),
    };

    gtoMsalApiFetch(gtoNewApiInstance.get, Routes.FetchUserModules, { headers })
      .then((response: any) => {
        if (response.data === null) {
          dispatch(setUserModules([]));
        } else {
          const moduleList = {
            IsAdmin: response.data.isAdmin,
            UserRoles: response.data.userRoles.map(y => ({
              ModuleID: y.moduleID,
              ModuleName: y.moduleName,
              Add: y.add,
              Edit: y.edit,
              Delete: y.delete,
              View: y.view,
            })),
          };

          dispatch(setUserModules(moduleList));
        }
      })
      .catch(error => {
        alert(
          'Unable to load user authorization at this moment. Please try again later.'
        );

        //    dispatch(fetchUserFailed());  TODO: See if this is needed.
      });
  };
};
export const getUserRoles = () => {
  return dispatch => {
    const headers: object = {
      'method':'GET',
      'X-CorrelationId': uuidv4(),
      SubCorrelationKey: uuidv4(),

    };
    gtoMsalApiFetch(gtoNewApiInstance.get, Routes.FetchUserAuth, { headers })
      .then((response: any) => {
        dispatch(setUserRoles(response.data));
      })
      .catch(error => {
        alert(
          'Unable to load user authorization at this moment. Please try again later.'
        );

        dispatch(fetchUserFailed());
      });
  };
};

export const getUserPreferences = () => {
  return (dispatch) => {
    if (appConfig.UseMockData === 'false') {
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };
      const url: string = Routes.UserPreferences;

      telemetryContext.logEvent(TelemetryEvent.GetUserPreferencesBegin, { headers, url });

      return gtoMsalApiFetch(gtoNewApiInstance.get, url, { headers })
        .then((response: any) => {
          telemetryContext.logEvent(TelemetryEvent.GetUserPreferencesSuccess, { headers, url });

          let userPreferences: UserPreferences = response.data.userPreferences;

          // If userPreferences was blank, then initialize it to empty object.
          if (!userPreferences) {
            userPreferences = {};
          }
          // If preference properties were empty, then initialize them.
          if (!userPreferences.billableUnitsCols) {
            userPreferences.billableUnitsCols = [];
          }

          dispatch({
            type: actionTypes.GET_USER_PREFERENCES,
            userPreferences
          }); 
        })
        .catch((error) => {
          telemetryContext.logException(error.toString(), TelemetryException.GetUserPreferencesFailure, undefined, { headers, url});
        });
    } else {
      // Return mock data.
      return delay(2000).then(() => {
        const userPreferences: UserPreferences = {
          billableUnitsCols: ['ExecPL', 'FunctionDetail', 'DiscountAmount']
        };
        dispatch({
          type: actionTypes.GET_USER_PREFERENCES,
          userPreferences
        });
      });
    }
  }
};

export const saveUserPreferences = (userPreferences: UserPreferences) => {
  return (dispatch) => {
    if (appConfig.UseMockData === 'false') {
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };
      const url: string = Routes.UserPreferences;
      const body: any = { userPreferences };

      telemetryContext.logEvent(TelemetryEvent.SaveUserPreferencesBegin, { body, headers, url });

      return gtoMsalApiFetch(gtoNewApiInstance, url, { data: body, headers, method: 'post' })
        .then((response: any) => {
          telemetryContext.logEvent(TelemetryEvent.SaveUserPreferencesSuccess, { headers, url });
          // No need to dispatch anything after save succeeds.
        })
        .catch((error) => {
          telemetryContext.logException(error.toString(), TelemetryException.SaveUserPreferencesFailure, undefined, { headers, url});
        });
    }
  }
}
