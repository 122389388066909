export interface ISelectedContract {
  contractID: number;
  supplierNumber: string;
  supplierName: string;
  expirationDate: string;
  expDateValue: number;
  contractDescription: string;
  companyCode: string;
  typeOfContract: TypeOfSelectedContract;
  contractNumber: number;
}

// TODO : change the type of Contract based on the API changes
// enum for the different types of contracts that exist
export enum TypeOfSelectedContract {
  none,
  master,
  addendumOrAmendment,
  sow,
  standalone,
  shell,
}
