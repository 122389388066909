import { delay } from 'q';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import gtoNewApiInstance, {
  gtoMsalApiFetch
} from '../../shared/http/GtoApiNew.axios';
import { supplierMockData } from '../../shared/mock';
import {
  ISupplier,
  Routes,
  TagTypes,
  TelemetryEvent,
  TelemetryException,
} from '../../shared/models';
import telemetryContext from '../../shared/services/TelemetryServices';
import * as actionTypes from './actionTypes';
import { setSelectedSupplier } from './selectedSupplier.action';
import { css } from 'glamor';
import appConfig from '../../assets/configuration/config';

// This action creator maps the data provided from the thunk middleware and maps it to an ISupplier[] type.
export const setSuppliers = (data: ISupplier[]) => {
  return {
    type: actionTypes.SET_SUPPLIERS,
    suppliers: data,
  };
};

export const setSupplierForUser = (data: ISupplier[]) => {
  return {
    type: actionTypes.SET_SUPPLIERS_FOR_USER,
    suppliers: data,
  };
};

// This action can be used to set the supplier tag list.
export const setSupplierTagList = (suppliers: ISupplier[]) => {
  // Filtering out unique company codes and master supplier names using Set to create the tags list.
  let companyCodeSet = new Set<string>();
  let masterSuppliersSet = new Set<string>();

  suppliers.forEach((item) => {
    companyCodeSet.add(item.companyCode);
    masterSuppliersSet.add(item.masterSupplierName);
  });

  const uniqueCompanyCodes = Array.from(companyCodeSet);
  const uniqueSuppliers = Array.from(masterSuppliersSet);

  // Returning the final tag list.
  let tags = uniqueCompanyCodes
    .map((item) => ({
      key: item,
      name: item,
      type: TagTypes.CompanyCodes,
    }))
    .concat(
      uniqueSuppliers.map((item) => ({
        key: item,
        name: item,
        type: TagTypes.SupplierName,
      }))
    );
  return { type: actionTypes.SET_SUPPLIER_TAG_LIST, tags: tags };
};

/*--------------------------------------THUNK ACTION CREATORS-----------------------------------*/

export const getSuppliers = () => {
  return (dispatch) => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers for telemetry
      const headers: Object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };

      // Defining the url
      const url: string = Routes.FetchSupplierList;

      // Logging telemetry
      telemetryContext.logEvent(TelemetryEvent.FetchSupplierDataBegin, {
        url: url,
        headers,
      });

      // Calling the supplie api to get the list of data.
      return gtoMsalApiFetch(gtoNewApiInstance.get, url, {
        headers,
      })
        .then((response: any) => {
          // console.log('supplier', response.data);
          // Mapping to ISupplier type and sorting the list in ascending order.
          let mappedSuppliers: ISupplier[] = response.data
            .map((x) => ({
              currencyCode: x.currencyCode,
              paymentTerms: x.discountTerm,
              supplierLegalName: x.supplierLegalName,
              supplierNumber: x.supplierNumber,
              supplierId: x.supplierId,
              masterSupplierName: x.supplierName,
              companyCode: x.msCompanyCode.toString(),
              contactName: x.supplierContactName,
              contactEmail: x.supplierContactEmail,
            }))
            .sort((a, b) =>
              a.supplierLegalName > b.supplierLegalName ? 1 : -1
            );
          dispatch(setSuppliers(mappedSuppliers));
          dispatch(setSupplierTagList(mappedSuppliers));
          if (mappedSuppliers.length !== 0) {
            dispatch(setSelectedSupplier(mappedSuppliers[0]));
          }
        })
        .catch((error) => {
          // Log exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchSupplierDataFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );

          // Error handling.
          if (error.response && error.response.status === 404) {
            toast.error(
              'Supplier information is not available at this moment.',
              {
                className: css({
                  background: '#a80000 !important',
                }),
              }
            );
          }

          throw error;
        });
    } else {
      // Setting mock data.
      return delay(2000).then(() => {
        dispatch(setSuppliers(supplierMockData));
        dispatch(setSelectedSupplier(supplierMockData[0]));
        dispatch(setSupplierTagList(supplierMockData));
      });
    }
  };
};

export const getSupplierListForUser = () => {
  return (dispatch) => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers for telemetry
      const headers: Object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };

      // Defining the url
      const url: string = Routes.GetSuppliersForUser;

      // Logging telemetry
      telemetryContext.logEvent(TelemetryEvent.FetchSupplierForUserBegin, {
        url: url,
        headers,
      });

      // Calling the supplie api to get the list of data.
      return gtoMsalApiFetch(gtoNewApiInstance.get, url, {
        headers,
      })
        .then((response: any) => {
          telemetryContext.logEvent(
            TelemetryEvent.FetchSupplierForUserSuccess,
            {
              url: url,
              headers,
            }
          );

          // Mapping to ISupplier type and sorting the list in ascending order.
          let mappedSuppliers: ISupplier[] = response.data
            .map((x) => ({
              currencyCode: x.currencyCode,
              paymentTerms: x.discountTerm,
              supplierLegalName: x.supplierLegalName,
              supplierNumber: x.supplierNumber,
              supplierId: x.supplierId,
              masterSupplierName: x.supplierName,
              companyCode: x.msCompanyCode.toString(),
              contactName: x.supplierContactName,
              contactEmail: x.supplierContactEmail,
            }))
            .sort((a, b) =>
              a.supplierLegalName > b.supplierLegalName ? 1 : -1
            );
          dispatch(setSuppliers(mappedSuppliers));
          dispatch(setSupplierTagList(mappedSuppliers));
          if (mappedSuppliers.length !== 0) {
            dispatch(setSelectedSupplier(mappedSuppliers[0]));
          }
          // Logging telemetry
        })
        .catch((error) => {
          // Log exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchSupplierForUserFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );

          // Error handling.
          if (error.response && error.response.status === 404) {
            toast.error(
              'Supplier information is not available at this moment.',
              {
                className: css({
                  background: '#a80000 !important',
                }),
              }
            );
          }

          throw error;
        });
    } else {
      // Setting mock data.
      return delay(2000).then(() => {
        dispatch(setSuppliers(supplierMockData));
        dispatch(setSelectedSupplier(supplierMockData[0]));
        dispatch(setSupplierTagList(supplierMockData));
      });
    }
  };
};
