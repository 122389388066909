import * as React from 'react';
import { Image } from 'office-ui-fabric-react';
class InvoiceViewDoc extends React.Component {
    render() {
        return (
            <div tabIndex={0}>
                <h2 style={{display: 'block', fontSize: '1.3em', margin: '0.83em 0 0.83em 0', fontWeight: 'bold'}}>Invoicing View:</h2>
                <p>Go to the GTO Invoicing tool.On the top menu bar, select the Invoicing tab and the Invoicing option.</p>
                <p>On the landing page, the Invoicing view will be defaulted to the previous month for which you are invoicing.
                           For example, if the current month is December, the default view will show the invoicing for November.</p>
                <p>Select the Invoice Prefix and the Supplier.</p>
                <p>There are two views under the Invoicing page: 1) Accruals 2) Actuals</p>
                <p>The accruals page will show what was budgeted or the actual billable units entered by the SDM and the accruals net amount.</p>
                <p>Accruals will be posted on the 3 last business day.</p>
                <Image
                    src={require('../../assets/images/invoiceView1.JPG')} alt="InvoiceView Image" style={{ height: 400, width: 900 }}></Image>
                <p> Actuals do not consider budget data only the data submitted by SDMs.  Billable units not entered by SDMs will reflect as zero.</p>
                <p>The actuals view is the same as the Delivery Partner’s (DP) view and will reflect the DP’s invoicing.</p>
                <Image
                    src={require('../../assets/images/invoiceView2.JPG')} alt="InvoiceView Image" style={{ height: 400, width: 900 }}></Image>
                <h3 style={{display: 'block', fontSize: '1.17em', margin: '1em 0 1em 0', fontWeight: 'bold'}}>Prior Period Adjustments:</h3>
                <p>For example, in a scenario where an SDM submitted a prior period adjustment for the month of October from 25.4 billable units to 5.4 billable units, the adjustments will be reflected in the month of November.</p>
                <p>‘Accruals’ and ‘Actuals’ for October reflect 25.4 billable units</p>
                <Image
                    src={require('../../assets/images/PPA1.png')} alt="InvoiceView Image" style={{ height: 400, width: 900 }}></Image>
                <br />
                <Image
                    src={require('../../assets/images/PPA2.png')} alt="InvoiceView Image" style={{ height: 400, width: 900 }}></Image>
                <br /><p>For November actuals, the invoicing view will reflect a prior period adjustment line.  You will see a negative entry of -25.4 which was already paid in October and a positive entry of 5.24 which shows October’s adjusted and correct invoice amount which will be paid in November.  In summary, invoice for October will not change and adjustments will be reflected in the next invoice.</p>
                <br /><Image
                    src={require('../../assets/images/PPA3.png')} alt="InvoiceView Image" style={{ height: 400, width: 900 }}></Image>
            </div>
        )
    }
}
export default InvoiceViewDoc;