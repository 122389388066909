import * as React from 'react';
import { IBudget, IPurchaseOrder, ISupplier } from '../../shared/models';
import { ITag } from '../../shared/models/Tag.model';
import telemetryContext from '../../shared/services/TelemetryServices';
import PurchaseOrders from './purchase-orders/PurchaseOrders';
import { SupplierBudget } from './supplier-budget/SupplierBudget';
import SupplierList from './supplier-selection/supplier-list/SupplierList';
import SupplierPicker from './supplier-selection/supplier-picker/SupplierPicker';
import './SupplierSummary.css';

import { connect } from 'react-redux';

import {
  getBudgetForSupplier,
  getContractsForSupplier,
  getPurchaseOrdersForSupplier,
  getSuppliersSetDefaultAndGetBudgetAndPODataForDefault,
  setBudgetForSupplier,
  setContractsForSupplier,
  setPurchaseOrdersForSupplier,
  setSelectedFiscalYearForSupplier,
  setSelectedSupplier,
} from '../../store/actions/selectedSupplier.action';

// Interface for supplier summary state.
interface ISupplierSummaryState {
  selectedTags: ITag[];
  currentFiscalYear: number;
  // purchaseOrderData: IPurchaseOrder[];
  // showPOLoader: boolean;
}

// Interface for supplier summary props.
interface ISupplierSummaryProps {
  suppliers: ISupplier[];
  selectedSupplier: ISupplier;
  showBudgetLoader: boolean;
  supplierTags: ITag[];
  budgetData: IBudget;
  fiscalYearNumbers: number[];
  selectedFiscalYear: number;
  purchaseOrderData: IPurchaseOrder[];
  showPOLoader: boolean;
  isMyOrderFeatureEnabled: boolean;
  isMyOrder2FeatureEnabled: boolean;
  returnToSameSupplier: boolean;
  netPoAmount: number;
  loadSuppliers(): any;
  onSupplierChange(supplier: ISupplier, defaultFiscalYear: number): any;
  onFiscalYearChange(supplier: ISupplier, year: number);
}

export class SupplierSummary extends React.Component<
  ISupplierSummaryProps,
  ISupplierSummaryState
> {
  constructor(props) {
    super(props);

    // initalizing the state
    this.state = {
      currentFiscalYear: 0,
      selectedTags: [],
    };
    // console.log('SupplierSummaryProps', this.props);
  }

  /*
  In this function, we call all the required api calls to initilize the state of this component. 
  */
  public async componentDidMount() {
    // Logging page view.
    telemetryContext.logPageView();

    if (!this.props.returnToSameSupplier) {
      // Load suppliers.
      this.props.loadSuppliers();
    }
  }

  public render() {
    return (
      <div className="grid-container">
        <div className="item2">
          <h1 className="selectText">Select a Supplier</h1>

          <SupplierPicker
            tagsChanged={this.handleTagUpdate}
            tagsList={this.props.supplierTags}
            aria-label={'Picker for Supplier'}
          />
          <SupplierList
            selectedSupplier={this.handleSelectedSupplier}
            selectedTags={this.state.selectedTags}
            supplierList={this.props.suppliers}
            aria-label={'Supplier List'}
          />
        </div>
        <div className="item5">
          <SupplierBudget
            netPoAmount={this.props.netPoAmount}
            fiscalYearNumbers={this.props.fiscalYearNumbers}
            onFiscalYearChange={this.handleFiscalYearChange}
            selectedFiscalYear={this.props.selectedFiscalYear}
            showLoader={this.props.showBudgetLoader}
            budgetData={this.props.budgetData}
            currentSupplier={this.props.selectedSupplier}
            aria-label={'Selected Supplier Budget'}
            addAdjustments={this.props.purchaseOrderData.length > 0}
          />
        </div>
        <div className="item3">
          <PurchaseOrders
            purchaseOrderItems={this.props.purchaseOrderData}
            showLoader={this.props.showPOLoader}
            currentSupplier={this.props.selectedSupplier}
            currentFiscalYear={this.props.selectedFiscalYear}
            aria-label={'Purchase Orders Component'}
            isMyOrderFeatureEnabled={this.props.isMyOrderFeatureEnabled}
            isMyOrder2FeatureEnabled={this.props.isMyOrder2FeatureEnabled}
          />
        </div>
      </div>
    );
  }

  // Handler for updating the selected tags in component state.
  private handleTagUpdate = (currentTags: any) => {
    const tags = [...currentTags.selectedTags];
    this.setState({ selectedTags: tags });
  };

  private handleFiscalYearChange = async (newFiscalYear: number) => {
    this.setState({ currentFiscalYear: newFiscalYear });
    this.props.onFiscalYearChange(this.props.selectedSupplier, newFiscalYear);
  };
  // Handler for updating the selected Supplier in component state.
  private handleSelectedSupplier = async (selectedSupplier: ISupplier) => {
    this.props.onSupplierChange(
      selectedSupplier,
      this.props.fiscalYearNumbers[0]
    );
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadSuppliers: () => {
      dispatch(getSuppliersSetDefaultAndGetBudgetAndPODataForDefault());
    },
    onSupplierChange: (supplier: ISupplier, defaultFiscalYear: number) => {
      dispatch(setBudgetForSupplier(null));
      dispatch(setPurchaseOrdersForSupplier([]));
      dispatch(setContractsForSupplier([]));
      dispatch(setSelectedSupplier(supplier));
      dispatch(setSelectedFiscalYearForSupplier(defaultFiscalYear));
      dispatch(getBudgetForSupplier(supplier));
      dispatch(getPurchaseOrdersForSupplier(supplier));
      dispatch(getContractsForSupplier(supplier));
    },
    onFiscalYearChange: (supplier: ISupplier, year: number) => {
      dispatch(setBudgetForSupplier(null));
      dispatch(setPurchaseOrdersForSupplier([]));
      dispatch(setContractsForSupplier([]));
      dispatch(setSelectedFiscalYearForSupplier(year));
      dispatch(getBudgetForSupplier(supplier, year.toString()));
      dispatch(getPurchaseOrdersForSupplier(supplier, year.toString()));
      dispatch(getContractsForSupplier(supplier));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    suppliers: state.suppliers.suppliersList,
    selectedSupplier: state.selectedSupplier.basicInfo,
    currentFiscalYear: state.selectedSupplier.selectedFiscalYear,
    showBudgetLoader: state.selectedSupplier.isFetchingBudget,
    supplierTags: state.suppliers.supplierTags,
    budgetData: state.selectedSupplier.budget,
    fiscalYearNumbers: state.selectedSupplier.availableFiscalYears,
    selectedFiscalYear: state.selectedSupplier.selectedFiscalYear,
    purchaseOrderData: state.selectedSupplier.purchaseOrders,
    showPOLoader: state.selectedSupplier.isFetchingPurchaseOrders,
    isMyOrderFeatureEnabled: state.features.isMyOrderEnabled,
    netPoAmount: state.selectedSupplier.netPoAmount,
    returnToSameSupplier: state.selectedSupplier.returnToSelectedSupplier,
    isMyOrder2FeatureEnabled: state.features.isMyOrder2Enabled,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupplierSummary);
