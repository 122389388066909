import gtoApiInstance, {
  gtoMsalApiFetch,
} from '../../shared/http/GtoApiNew.axios';
import { Routes } from '../../shared/models';
import * as actionTypes from './actionTypes';
import { v4 as uuidv4 } from 'uuid';
import { IUserRequestedRole } from '../../shared/models/UserRequestedRole.model';

export const setUserAccessRequest = (data: any) => {
  return {
    type: actionTypes.SET_ACCESS_REQUEST,
    requestDetails: data,
  };
};

export const updateRequestedRoles = (roleId: number, isChecked: boolean) => {
  return {
    type: actionTypes.SET_AVAILABLE_ROLES,
    roleId: roleId,
    isChecked: isChecked,
  };
};

export const updateJustification = (justification: string) => {
  return {
    type: actionTypes.SET_BUSINESSJUSTIFICATION,
    justification: justification,
  };
};

export const setSubmitRequestStatus = (status: boolean) => {
  return {
    type: actionTypes.SET_SUBMITREQUEST_STATUS,
    submitStatus: status,
  };
};

export const setMailReminderEligibility = (isEligible: boolean) => {
  return {
    type: actionTypes.SET_MAILREMINDER_ELIGIBILITY,
    isEligible: isEligible,
  };
};

export const setMailReminderDialogVisibility = (showDialog: boolean) => {
  return {
    type: actionTypes.SET_MAILREMINDER_DIALOG_VISIBILITY,
    showDialog: showDialog,
  };
};

export const getPendingAccessRequest = (userName: string) => {
  return (dispatch) => {
    const headers: object = {
      'X-CorrelationId': uuidv4(),
      SubCorrelationKey: uuidv4(),
    };
    gtoMsalApiFetch(gtoApiInstance.get, Routes.GetUnAuthUserPendingRequest, {
      headers,
    })
      .then((response: any) => {
        if (response.data !== null) {
          const requestData = response.data;
          gtoMsalApiFetch(gtoApiInstance.get, Routes.GetAvailableRoles, {
            headers,
          }).then((response: any) => {
            if (response.data !== null) {
              let previousSelectedRoles: number[] =
                requestData.selectedRoles !== null
                  ? requestData.selectedRoles
                  : [];
              const UserRequestedRoles = response.data.map((x) => {
                let isRoleRequested: boolean = false;
                if (previousSelectedRoles.indexOf(x.roleId) >= 0) {
                  isRoleRequested = true;
                }
                let role: IUserRequestedRole = {
                  roleId: x.roleId,
                  roleName: x.roleName,
                  isRequested: isRoleRequested,
                };
                return role;
              });

              const data = {
                RequestId: requestData.requestId,
                BusinessJustification: requestData.businessJustification,
                UserAlias: requestData.userAlias,
                UserName: userName,
                MailReminderEligible: requestData.mailReminderEligible,
                MailReminderInHours: requestData.mailReminderInHours,
                RolesChanged: requestData.rolesChanged,
                UserRequestedRoles: UserRequestedRoles,
                PreviousSelectedRoles: previousSelectedRoles,
                ShowRequestForm: true,
              };
              dispatch(setUserAccessRequest(data));
            }
          });
        }
      })
      .catch((error) => {
        alert(
          'Unable to load user authorization at this moment. Please try again later.'
        );
      });
  };
};

export const submitRequest = () => {
  return (dispatch, getState) => {
    const headers: object = {
      'X-CorrelationId': uuidv4(),
      SubCorrelationKey: uuidv4(),
    };
    let url: string = `${Routes.SubmitAccessRequest}`;
    const request = getState().unAuthUser;
    let previousSelectedRoles: number[] = request.PreviousSelectedRoles;
    let requestedRoles: IUserRequestedRole[] = request.UserRequestedRoles;
    let selectedRoles: number[] = requestedRoles
      .filter((x) => x.isRequested === true)
      .map((role) => {
        return role.roleId;
      });
    request.SelectedRoles = selectedRoles;
    if (previousSelectedRoles.length > 0) {
      let roleDiff1: number[] = [];
      let roleDiff2: number[] = [];
      roleDiff1 = previousSelectedRoles.filter(
        (x) => selectedRoles.indexOf(x) < 0
      );
      roleDiff2 = selectedRoles.filter(
        (x) => previousSelectedRoles.indexOf(x) < 0
      );
      //If the user adding new role(or) removing the existing role from the request then setting "IsRolesChanged" to true                //
      if (roleDiff1.concat(roleDiff2).length > 0) {
        request.RolesChanged = true;
      }
    }
    gtoMsalApiFetch(gtoApiInstance, url, {
      data: request,
      headers,
      method: 'post',
    })
      .then((response: any) => {
        if (response.status === 200) {
          dispatch(setSubmitRequestStatus(true));
        }
      })
      .catch((error) => {
        alert(
          'Unable to load user authorization at this moment. Please try again later.'
        );
      });
  };
};

export const sendMailReminder = () => {
  return (dispatch, getState) => {
    const headers: object = {
      'X-CorrelationId': uuidv4(),
      SubCorrelationKey: uuidv4(),
    };
    let url: string = `${Routes.SendMailReminderForApproval}`;
    const request = getState().unAuthUser;
    gtoMsalApiFetch(gtoApiInstance, url, {
      data: request,
      headers,
      method: 'post',
    })
      .then((response: any) => {
        if (response.status === 200) {
          dispatch(setMailReminderEligibility(false));
          dispatch(setMailReminderDialogVisibility(true));
        }
      })
      .catch((error) => {
        alert(
          'Unable to load user authorization at this moment. Please try again later.'
        );
      });
  };
};
