import * as React from 'react';
import './automatedsupplierengagements.css';
import { TextField } from '@fluentui/react/lib/TextField';
import HelmetTab from "../../shared/Helmet/Helmet";
import { DetailsList,DetailsListLayoutMode,Selection,IDetailsListStyles,IColumn,SelectionMode, DetailsRow, IDetailsRowStyles, IDetailsListProps } from '@fluentui/react/lib/DetailsList';
import appConfig from '../../assets/configuration/config';
import { v4 as uuidv4 } from 'uuid';
import { TelemetryEvent,Routes,TelemetryException } from '../../shared/models';
import telemetryContext from '../../shared/services/TelemetryServices';
import gtoNewApiInstance, {  gtoMsalApiFetch,} from '../../shared/http/GtoApiNew.axios';
import { toast } from 'react-toastify';
import { css } from 'glamor';
import { delay } from 'q';
import { getUserRoles } from '../../store/actions/user.action';
import { connect } from 'react-redux';
import { Dropdown, IDropdownOption,Link,Spinner, SpinnerSize } from '@fluentui/react';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Panel, PanelType } from '@fluentui/react';
import { exportToXlsx } from "../../shared/common/common-util";
import readXlsxFile from 'read-excel-file'
import { AutomatedSEIDStyles } from '../automated-seid/automatedseid.style';
import {  MessageBar,  MessageBarType} from '@fluentui/react';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import { _automatedseidGridUploadColumns,automatedSeidTemplateheaders,automatedSeidExportheaders,newAutomatedSupplierengagementsHeaders,invalidAutomatedSeidExportheaders } from "./automatedseid-columns";

  export interface IAutomatedSeidPageProps {
    getUserRoles: any;  
    IsInvoiceAnalyst: boolean;
    IsSupplierSupportManager:boolean;
  }
  
  export interface IAutomatedSeidPageState {
    automatedSeidItemsList:any;
    tempAutomatedSeidItemsList:any;
    activestatelist:any;
    getFiscalYearsList:any;
    currentFiscalYearNumber:any;
    supplierMasterOptions:any;
    filtersedis:any;
    filterseidsingle:any;
    disabledit:boolean;
    showeditPanel:boolean;
    editFields:any;
    captureField:any;
    captureFieldValue:any;
    captureFieldType:any;
    captureFieldName:any;
    selectedSupplierEngagements:any;
    displaySeidListEdit:any;
    alertFlagOnDifferentSupplierSlection:boolean;
    captureSearchtext:any;
    supplierValueSelected:any;
    supplierKeySelected:any;
    capturePricingGroupId:any;
    pricingGroupLists:any;
    displaySeidWithSupplierNames:any;
    captureUniqueSupplierSelected:any;
    showaddPanel:boolean;
    fileName:string;
    uploadedAutomatedData:any;
    duplicateStatus:boolean;
    duplicateData:any;
    showuploadedGrid:boolean;
    onclickValidate:boolean;
    validatedData:any;
    validatedresponsefromAPI:any;
    isFormLoading:boolean;
    validRecordsFromAPI:any;
    exportListData:any;
    inValidRecordsfromAPI:any;
    tempexportData:any;
  }

  const textFieldRef = React.createRef<HTMLInputElement>();


  const gridStyles: Partial<IDetailsListStyles> = {
    root: {
      overflowX: 'auto',
      selectors: {
        '& [role=grid]': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          height: '65vh',
        },
      },
    },
    headerWrapper: {
      flex: '0 0 auto',
    },
    contentWrapper: {
      flex: '1 1 auto',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
  };

class Automatedseid extends React.Component<IAutomatedSeidPageProps,IAutomatedSeidPageState> {

    private _automatedseidGridColumns: IColumn[];
    private _selection: Selection;

    constructor(props) {
        super(props);
        this.props.getUserRoles();

        this._selection = new Selection({
          onSelectionChanged: () => {
            this._getSelectionDetails();
          },
        });
        
        this._automatedseidGridColumns =[
            {
            key: 'column1',
            name: 'Supplier Engagement ID',
            fieldName: 'supplierEngagementId',
            minWidth: 180,
            maxWidth: 250,
            isResizable: true,
          },
          {
            key: 'column17',
            name: 'Supplier Name',
            fieldName: 'supplierName',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column2',
            name: 'Fiscal Year Number',
            fieldName: 'fiscalYearNumber',
            minWidth: 150,
            maxWidth: 250,
            isResizable: true,
          },
          {
            key: 'column3',
            name: 'Engagement ID',
            fieldName: 'engagementId',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column4',
            name: 'Business Group',
            fieldName: 'businessGroup',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender:this.renderBusinessGroup
          },
          {
            key: 'column5',
            name: 'CCOOrg',
            fieldName: 'CCOOrg',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender:this.renderccoorg
          },
          {
            key: 'column6',
            name: 'PCY',
            fieldName: 'PCY',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender:this.renderpcy
          },
          {
            key: 'column7',
            name: 'PCY IsGroup',
            fieldName: 'PCYIsGroup',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender:this.renderpcyisgroup
          },
          {
            key: 'column8',
            name: 'PCY Id',
            fieldName: 'PCYId',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender:this.renderpcyid
          },
          {
            key: 'column9',
            name: 'SubPCY',
            fieldName: 'SubPCY',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender:this.rendersubpcy
          },
          {
            key: 'column10',
            name: 'SubPCY IsGroup',
            fieldName: 'SubPCYIsGroup',
            minWidth: 150,
            maxWidth: 200,
            isResizable: true,
            onRender:this.renderpsubcyisgroup
          },
          {
            key: 'column11',
            name: 'SubPCY Id',
            fieldName: 'SubPCYId',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender:this.rendersubpcyid
          },
          {
            key: 'column12',
            name: 'Language',
            fieldName: 'Language',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender:this.renderLanguage
          },
          {
            key: 'column13',
            name: 'IsCommerce',
            fieldName: 'IsCommerce',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender:this.renderiscommerce
          },
          {
            key: 'column14',
            name: 'Offering',
            fieldName: 'Offering',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender:this.renderoffering
          },
          {
            key: 'column15',
            name: 'Report Category',
            fieldName: 'ReportGrouping',
            minWidth: 150,
            maxWidth: 200,
            isResizable: true,
            onRender:this.renderreportCategory
          },
          {
            key: 'column16',
            name: 'Supplier Id',
            fieldName: 'supplierId',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          
          {
            key: 'column18',
            name: 'Supplier Number',
            fieldName: 'supplierNumber',
            minWidth: 130,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column19',
            name: 'Company Code',
            fieldName: 'companyCode',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column20',
            name: 'Supplier Site',
            fieldName: 'supplierSite',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column21',
            name: 'Supplier City',
            fieldName: 'supplierCity',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column22',
            name: 'Supplier Country/Region',
            fieldName: 'supplierCountry',
            minWidth: 150,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column23',
            name: 'Currency Code',
            fieldName: 'currencyCode',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column24',
            name: 'Internal Order',
            fieldName: 'internalOrder',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column25',
            name: 'Cost Center',
            fieldName: 'costCenter',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column26',
            name: 'Profit Center',
            fieldName: 'profitCenter',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column27',
            name: 'Account Code',
            fieldName: 'accountCode',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column45',
            name: 'Pricing Group Id',
            fieldName: 'pricingGroupId',
            minWidth: 150,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column28',
            name: 'Purchase Order',
            fieldName: 'purchaseOrder',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column29',
            name: 'SD1',
            fieldName: 'sD1',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column30',
            name: 'SD2',
            fieldName: 'sD2',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column31',
            name: 'CCO1',
            fieldName: 'ccO1',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column32',
            name: 'CCO2',
            fieldName: 'ccO2',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column33',
            name: 'SEID IsInvoice Active',
            fieldName: 'isInvoiceActive',
            minWidth: 150,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column34',
            name: 'Invoice Prefix',
            fieldName: 'invoicePrefix',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column35',
            name: 'Business Segment',
            fieldName: 'BusinessSegment',
            minWidth: 150,
            maxWidth: 200,
            isResizable: true,
            onRender:this.renderBusinessSegment
          },
          {
            key: 'column36',
            name: 'ExcePI',
            fieldName: 'ExcePI',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender:this.renderExecPI
          },
          {
            key: 'column37',
            name: 'PL Function',
            fieldName: 'function',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column38',
            name: 'PL FunctionDetail',
            fieldName: 'functionDetail',
            minWidth: 150,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column39',
            name: 'PL FunctionSummary',
            fieldName: 'functionSummary',
            minWidth: 150,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column40',
            name: 'PL Org',
            fieldName: 'org',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column41',
            name: 'PL OrgDetail',
            fieldName: 'orgDetail',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column42',
            name: 'Product',
            fieldName: 'Product',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender:this.renderProduct
          },
          {
            key: 'column43',
            name: 'Product Group',
            fieldName: 'ProductGroup',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender:this.renderProductGroup
          },
          {
            key: 'column44',
            name: 'Product SubType',
            fieldName: 'ProductSubType',
            minWidth: 150,
            maxWidth: 200,
            isResizable: true,
            onRender:this.renderProductSubType
          },
        ];
        this.state ={
            automatedSeidItemsList:[],
            tempAutomatedSeidItemsList:[],
            activestatelist:[],
            getFiscalYearsList:[],
            currentFiscalYearNumber:'',
            supplierMasterOptions:[],
            filtersedis:[],
            filterseidsingle:'',
            disabledit:true,
            showeditPanel:false,
            captureField:'',
            captureFieldValue:'',
            captureFieldType:'',
            captureFieldName:'',
            selectedSupplierEngagements:[],
            displaySeidListEdit:[],
            alertFlagOnDifferentSupplierSlection:false,
            captureSearchtext:'',
            supplierValueSelected:'All',
            supplierKeySelected:0,
            capturePricingGroupId:'',
            pricingGroupLists:[],
            displaySeidWithSupplierNames:[],
            captureUniqueSupplierSelected:'',
            showaddPanel:false,
            fileName:'or drag and drop files here',
            uploadedAutomatedData:[],
            duplicateStatus:false,
            duplicateData:[],
            showuploadedGrid:false,
            onclickValidate:false,
            validatedData:[],
            validatedresponsefromAPI:[],
            isFormLoading:false,
            validRecordsFromAPI:[],
            exportListData:[],
            tempexportData:[],
            inValidRecordsfromAPI:[],
            editFields:[
              {
                "key":"CCO1",
                "text":"CCO1",
                "type":"text"
              },
              {
                "key":"CCO2",
                "text":"CCO2",
                "type":"text"
              },
              {
                "key":"InternalOrder",
                "text":"Internal Order",
                "type":"number"
              },
              {
                "key":"PricingGroup",
                "text":"Pricing",
                "type":"number"
              },
              {
                "key":"SD1",
                "text":"SD1",
                "type":"text"
              },
              {
                "key":"SD2",
                "text":"SD2",
                "type":"text"
              },
              {
                "key":"PCY",
                "text":"PCY",
                "type":"PCY"
              },
              {
                "key":"SubPCY",
                "text":"SubPCY",
                "type":"SubPCY"
              },
              {
                "key":"Language",
                "text":"Language",
                "type":"Language"
              },
              {
                "key":"IsCommerce",
                "text":"IsCommerce",
                "type":"IsCommerce"
              },
              {
                "key":"CloudType",
                "text":"CloudType",
                "type":"CloudType"
              },
              {
                "key":"ServiceOffering",
                "text":"ServiceOffering",
                "type":"ServiceOffering"
              },
              {
                "key":"CCOOrg",
                "text":"CCOOrg",
                "type":"CCOOrg"
              },
              {
                "key":"BusinessSegment",
                "text":"BusinessSegment",
                "type":"BusinessSegment"
              },
              {
                "key":"ExecPl",
                "text":"ExecPl",
                "type":"ExecPl"
              },
              {
                "key":"ProductGroup",
                "text":"ProductGroup",
                "type":"ProductGroup"
              },
              {
                "key":"ProductSubType",
                "text":"ProductSubType",
                "type":"ProductSubType"
              },
              {
                "key":"LOB",
                "text":"LOB",
                "type":"LOB"
              },
              {
                "key":"MediaType",
                "text":"MediaType",
                "type":"MediaType"
              },
              {
                "key":"MediaSubType",
                "text":"MediaSubType",
                "type":"MediaSubType"
              },
              {
                "key":"SupportTier",
                "text":"SupportTier",
                "type":"SupportTier"
              },
              {
                "key":"SalesRegion",
                "text":"SalesRegion",
                "type":"SalesRegion"
              },
              {
                "key":"SalesLocation",
                "text":"SalesLocation",
                "type":"SalesLocation"
              },
              {
                "key":"ReportCategory",
                "text":"ReportCategory",
                "type":"ReportCategory"
              }
          ]
        }
    }

    private _getSelectionDetails(): string {
      const selectionCount = this._selection.getSelectedCount();
      switch (selectionCount) {
        case 0:
          this.setState({
            disabledit:true
          })
          return ;
          case 1:
            this.setState({
              selectedSupplierEngagements:this._selection.getSelection(),
              disabledit:false,
            })
            return ;
            default:
              this.setState({
                selectedSupplierEngagements:this._selection.getSelection(),
                disabledit: false,
              });
      }
    }

    componentDidMount(): void {
      this.loadFiscalYears();
      this.loadSupplierMasterList();
    }

    private renderBusinessGroup = (item: any): JSX.Element => {
        if(item.engagement.businessGroup){
            return (
               <span  aria-label={item.engagement.businessGroup}>{item.engagement.businessGroup}</span>
              );
        }else{
        }
      };
      private renderccoorg = (item: any): JSX.Element => {
        if(item.engagement.engagementAttributes.CCOOrg){
            return (
               <span  aria-label={item.engagement.engagementAttributes.CCOOrg.name}>{item.engagement.engagementAttributes.CCOOrg.name}</span>
              );
        }else{
        }
      };
      private renderpcy = (item: any): JSX.Element => {
        if(item.engagement.engagementAttributes.PCY){
            return (
               <span  aria-label={item.engagement.engagementAttributes.PCY.name}>{item.engagement.engagementAttributes.PCY.name}</span>
              );
        }else{
        }
      };
      private renderpcyid = (item: any): JSX.Element => {
        if(item.engagement.engagementAttributes.PCY){
            return (
               <span  aria-label={item.engagement.engagementAttributes.PCY.id}>{item.engagement.engagementAttributes.PCY.id}</span>
              );
        }else{
        }
      };

      private renderpcyisgroup = (item: any): JSX.Element => {
        if(item.engagement.engagementAttributes.PCY){

          if( item.engagement.engagementAttributes.PCY.isGroup){
            return (
               <span  aria-label={item.engagement.engagementAttributes.PCY.isGroup}>True</span>
              );
        }else{
            return (
                <span  aria-label={item.engagement.engagementAttributes.PCY.isGroup}>False</span>
               );
        }
        }else{

        } 
      };
      private rendersubpcy = (item: any): JSX.Element => {
        if(item.engagement.engagementAttributes.SubPCY){
            return (
               <span  aria-label={item.engagement.engagementAttributes.SubPCY.name}>{item.engagement.engagementAttributes.SubPCY.name}</span>
              );
        }else{
        }
      };

      private rendersubpcyid = (item: any): JSX.Element => {
        if(item.engagement.engagementAttributes.SubPCY){
            return (
               <span  aria-label={item.engagement.engagementAttributes.SubPCY.id}>{item.engagement.engagementAttributes.SubPCY.id}</span>
              );
        }else{

        }
      };
      private renderpsubcyisgroup = (item: any): JSX.Element => {

        if(item.engagement.engagementAttributes.SubPCY ){
          if(item.engagement.engagementAttributes.SubPCY.isGroup){
            return (
               <span  aria-label={item.engagement.engagementAttributes.SubPCY.isGroup}>true</span>
              );
        }else{
            return (
                <span  aria-label={item.engagement.engagementAttributes.SubPCY.isGroup}>False</span>
               );
        }
        }else{

        }
        
      };

      private  renderLanguage = (item: any): JSX.Element => {
        if(item.engagement.engagementAttributes.Language){
            return (               
               <span  aria-label={item.engagement.engagementAttributes.Language.name}>{item.engagement.engagementAttributes.Language.name}</span>
              );
        }else{

        }
      };
      private renderiscommerce = (item: any): JSX.Element => {
        
        if(item.engagement.engagementAttributes.IsCommerce){
            return (              
               <span  aria-label={item.engagement.engagementAttributes.IsCommerce.name}>{item.engagement.engagementAttributes.IsCommerce.name}</span>
              );
        }else{

        }
      };

      private renderoffering = (item: any): JSX.Element => {
        if(item.engagement.engagementAttributes.ServiceOffering){
            return (
               <span  aria-label={item.engagement.engagementAttributes.ServiceOffering.name}>{item.engagement.engagementAttributes.ServiceOffering.name}</span>
              );
        }else{

        }
      };
      private renderreportCategory = (item: any): JSX.Element => {
        if(item.engagement.engagementAttributes.CloudType){
            return (
               <span  aria-label={item.engagement.engagementAttributes.CloudType.name}>{item.engagement.engagementAttributes.CloudType.name}</span>
              );
        }else{

        }
      };

      private renderBusinessSegment  = (item: any): JSX.Element => {
        if(item.engagement.engagementAttributes.BusinessSegment){
            return (
               <span  aria-label={item.engagement.engagementAttributes.BusinessSegment.name}>{item.engagement.engagementAttributes.BusinessSegment.name}</span>
              );
        }else{

        }
      };
      private renderExecPI = (item: any): JSX.Element => {
        if(item.engagement.engagementAttributes.ExecPl){
            return (
               <span  aria-label={item.engagement.engagementAttributes.ExecPl.name}>{item.engagement.engagementAttributes.ExecPl.name}</span>
              );
        }else{

        }
      };
      private renderProduct = (item: any): JSX.Element => {
        if(item.engagement.engagementAttributes.Product){
            return (
               <span  aria-label={item.engagement.engagementAttributes.Product.name}>{item.engagement.engagementAttributes.Product.name}</span>
              );
        }else{

        }
      };
      private renderProductGroup = (item: any): JSX.Element => {
        if(item.engagement.engagementAttributes.ProductGroup){
            return (
               <span  aria-label={item.engagement.engagementAttributes.ProductGroup.name}>{item.engagement.engagementAttributes.ProductGroup.name}</span>
              );
        }else{

        }
      };
      private renderProductSubType = (item: any): JSX.Element => {
        if(item.engagement.engagementAttributes.ProductSubType){
            return (
               <span  aria-label={item.engagement.engagementAttributes.ProductSubType.name}>{item.engagement.engagementAttributes.ProductSubType.name}</span>
              );
        }else{

        }
      };
       readUploadFile = (e) => {
        var fileref=e.target.files[0].name;
        var ext: string = fileref.substring(fileref.lastIndexOf('.') + 1).toLowerCase();

        if (ext.toLowerCase() === "xlsx") {
        this.setState({
          fileName:e.target.files[0].name
        })
        readXlsxFile(textFieldRef.current.files[0])
          .then((rows: any) => {
            let finalArr=[];
            rows.filter(function(v,index){
                let _obj = {};
                if(rows[index+1] !== undefined){
                    rows[0].filter(function(key,i){

                        _obj[key] = rows[index+1][i];
                    })
                    finalArr.push(_obj);
                }
            })
           if(finalArr.length < 1){
                  this.setState({
                    showaddPanel:false,
                    fileName:'or drag and drop files here',

                  })
                  toast.error(
                    `${fileref} file is blank.Please check`,
                    {
                      className: css({
                        background: '#a80000 !important',
                      }),
                    }
                  );
                     }
              else if(finalArr.length > 0){
                  let arrayVal = [];
                  let duplicatedrows =[]
                  finalArr.filter(function(i,v){
                      if(JSON.stringify(arrayVal).indexOf(JSON.stringify(i)) === -1){
                        arrayVal.push(i);  
                      }else{
                        duplicatedrows.push(v+2);
                        toast.error(
                          `row number ${v+2} in ${fileref} is duplicate. please remove duplicates and upload`,
                          {
                            className: css({
                              background: '#a80000 !important',
                            }),
                          }
                        );
                      }
                  })
                  this.setState({
                    uploadedAutomatedData:arrayVal,
                      duplicateStatus:duplicatedrows.length >0 ?true:false
                  })
                }
                this.setState({
                  showuploadedGrid:true,
                  onclickValidate:false
                })
          })
      }else{
        toast.error('only xlsx file allowed.', {
          className: css({
            background: '#a80000 !important',
          }),
        });

      }
      }

    loadAutomatedSeidsList = () =>{
      this.setState({
        isFormLoading:true
      })
        if (appConfig.UseMockData === 'false') {
          // Creating headers
          const headers: any = {
            SubCorrelationKey: uuidv4(),
            'X-CorrelationId': uuidv4(),
          };
          // Defining the url
          const url: string = `${Routes.getAutomatedSeidList}${this.state.currentFiscalYearNumber}`;
          // Logging telemetry
         
           gtoMsalApiFetch(gtoNewApiInstance.get, url, { headers })
            .then((response: any) => {
              let exportData = response.data && response.data.sort(function (a, b) {
                return a.supplierEngagementId - b.supplierEngagementId
              }).map((x) => ({
                supplierEngagementId:x.supplierEngagementId,
                supplierName:x.supplierName,         
                fiscalYearNumber:x.fiscalYearNumber,
                engagementId:x.engagementId,
                businessGroup:x.engagement.businessGroup ? x.engagement.businessGroup :'', 
                cooOrg:x.engagement.engagementAttributes.CCOOrg?x.engagement.engagementAttributes.CCOOrg.name:'',           
                pcy:x.engagement.engagementAttributes.PCY ?x.engagement.engagementAttributes.PCY.name:'',           
                pcyIsGroup:x.engagement.engagementAttributes.PCY?x.engagement.engagementAttributes.PCY.isGroup:'',           
                pcyId:x.engagement.engagementAttributes.PCY?x.engagement.engagementAttributes.PCY.id:'',         
                subPcy:x.engagement.engagementAttributes.SubPCY?x.engagement.engagementAttributes.SubPCY.name:'',            
                subPcyIsGroup:x.engagement.engagementAttributes.SubPCY?x.engagement.engagementAttributes.SubPCY.isGroup:'',           
                subPcyId:x.engagement.engagementAttributes.SubPCY?x.engagement.engagementAttributes.SubPCY.id:'',          
                language:x.engagement.engagementAttributes.Language?x.engagement.engagementAttributes.Language.name:'',            
                isCommerce:x.engagement.engagementAttributes.IsCommerce?x.engagement.engagementAttributes.IsCommerce.name:'',            
                offering:x.engagement.engagementAttributes.ServiceOffering?x.engagement.engagementAttributes.ServiceOffering.name:'',           
                reportGrouping:x.engagement.engagementAttributes.CloudType?x.engagement.engagementAttributes.CloudType.name:'',           
                supplierId:x.supplierId,          
                supplierNumber:x.supplierNumber,
                companyCode:x.companyCode,
                supplierSite:x.supplierSite,
                supplierCity:x.supplierCity,
                supplierCountry:x.supplierCountry,
                currencyCode:x.currencyCode,
                internalOrder:x.internalOrder,
                costCenter:x.costCenter,
                profitCenter:x.profitCenter,
                accountCode:x.accountCode,
                pricingGroupId:x.pricingGroupId,
                purchaseOrder:x.purchaseOrder,
                sD1:x.sD1,
                sD2:x.sD2,
                ccO1:x.ccO1,
                ccO2:x.ccO2,
                isInvoiceActive:x.isInvoiceActive,
                invoicePrefix:x.invoicePrefix,
                BusinessSegment:x.engagement.engagementAttributes.BusinessSegment?x.engagement.engagementAttributes.BusinessSegment.name:'',            
                ExcePI:x.engagement.engagementAttributes.ExecPl?x.engagement.engagementAttributes.ExecPl.name:'',
                function:x.function,
                functionDetail:x.functionDetail,
                functionSummary:x.functionSummary,
                org:x.org,
                orgDetail:x.orgDetail,
                Product:x.engagement.engagementAttributes.Product?x.engagement.engagementAttributes.Product.name:'',
                ProductGroup:x.engagement.engagementAttributes.ProductGroup?x.engagement.engagementAttributes.ProductGroup.name:'',
                ProductSubType:x.engagement.engagementAttributes.ProductSubType?x.engagement.engagementAttributes.ProductSubType.name:'',
              }));          
              this.setState({
                automatedSeidItemsList:response.data && response.data.sort(function (a, b) {
                  return a.supplierEngagementId - b.supplierEngagementId;
                }),
                activestatelist:response.data && response.data.filter(x=> x.isActive === true),
                tempAutomatedSeidItemsList:response.data,
                exportListData:exportData,
                tempexportData:exportData,
                isFormLoading:false
              },this.alertNotFoundForFiscalYear)
              telemetryContext.logEvent(
                TelemetryEvent.FetchAutomatedSeidList,
                {
                  headers,
                  url,
                }
              );
            // }
            })
            .catch((error) => {
    
              telemetryContext.logException(
                error.toString(),
                TelemetryException.FetchAutomatedSeidListFailure,
                undefined,
                {
                  headers,
                  url,
                }
              );
    
              if (error.response && error.response.status === 404) {
                toast.error(
                  'Automated supplier engagement list unable to fetch ',
                  {
                    className: css({
                      background: '#a80000 !important',
                    }),
                  }
                );
              }
              this.setState({
                isFormLoading:false
              })
            });
        }
    }

    loadSupplierMasterList = () =>{
      if (appConfig.UseMockData === 'false') {
        // Creating headers
        const headers: any = {
          SubCorrelationKey: uuidv4(),
          'X-CorrelationId': uuidv4(),
        };
        // Defining the url
        const url: string = `${Routes.getSupplierMasterData}`;
        // Logging telemetry
        telemetryContext.logEvent(
          TelemetryEvent.FetchSupplierMasterList,
          {
            headers,
            url,
          }
        );
        var supplierList =[];
        supplierList =[{
          key:0,
          text:'All',
          isActive:true
        }]  
         gtoMsalApiFetch(gtoNewApiInstance.get, url, { headers })
          .then((response: any) => {
          if(response.data && response.status === 200){
  
            let masterData = response.data && response.data.sort(function (a, b) {
              return a.supplierName - b.supplierName;
            }).map((x) => ({
              key: x.supplierMasterId,
              text:x.supplierName,
              isActive:x.isActive
            }));
            // Logging success
            this.setState({
              supplierMasterOptions:supplierList.concat(masterData)
            })
            // Logging success
          
            telemetryContext.logEvent(
              TelemetryEvent.FetchSupplierMasterList,
              {
                headers,
                url,
              }
            );
          }
          })
          .catch((error) => {
  
            telemetryContext.logException(
              error.toString(),
              TelemetryException.FetchSupplierMasterListFailure,
              undefined,
              {
                headers,
                url,
              }
            );
            if (error.response && error.response.status === 404) {
              toast.error(
                'Supplier Master  information is not available ',
                {
                  className: css({
                    background: '#a80000 !important',
                  }),
                }
              );
            }
          });
      }
  }

    loadFiscalYears = () =>{
      this.setState({
        isFormLoading:true
      })
      if (appConfig.UseMockData === 'false') {
        // Creating headers
        const headers: any = {
          SubCorrelationKey: uuidv4(),
          'X-CorrelationId': uuidv4(),
        };
        // Defining the url
        const url: string = `${Routes.GetAvailableFiscalYears}
        `;
        // Logging telemetry
        telemetryContext.logEvent(
          TelemetryEvent.FetchAvailableFiscalYearsForAutomationSeidBegin,
          {
            headers,
            url,
          }
        );
         gtoMsalApiFetch(gtoNewApiInstance.get, url, { headers })
          .then((response: any) => {
          if(response.data && response.status === 200){
            // Logging success
            telemetryContext.logEvent(
              TelemetryEvent.FetchAvailableFiscalYearsForAutomationSeidSuccess,
              {
                headers,
                url,
              }
            );
            this.setState({
              getFiscalYearsList:response.data && response.data.fiscalYearNumbers,
              currentFiscalYearNumber:response.data.currentFiscalYearNumber,
              isFormLoading:false
            },this.loadAutomatedSeidsList)
          }
          })
          .catch((error) => {
            telemetryContext.logException(
              error.toString(),
              TelemetryException.FetchAvailableFiscalYearsForAutomationSeidFailure,
              undefined,
              {
                headers,
                url,
              }
            );
            this.setState({
              isFormLoading:false
            })
            if (error.response && error.response.status === 404) {
              toast.error(
                'Fiscal Year information is not available ',
                {
                  className: css({
                    background: '#a80000 !important',
                  }),
                }
              );
            }
          });
      }
}
loadPrcingGroupBySupplierId = () =>{
  if (appConfig.UseMockData === 'false') {
    // Creating headers
    const headers: any = {
      SubCorrelationKey: uuidv4(),
      'X-CorrelationId': uuidv4(),
    };
    // Defining the url
    const url: string = `${Routes.getPricingGroupDescriptionBySupplier}?supplierId=${this.state.captureUniqueSupplierSelected}`;
    // Logging telemetry
    telemetryContext.logEvent(
      TelemetryEvent.FetchPricingGroupListBySupplierBegin,
      {
        headers,
        url,
      }
    );
   
     gtoMsalApiFetch(gtoNewApiInstance.get, url, { headers })
      .then((response: any) => {
          var masterData = response.data.map((x => ({
            key: x.PricingGroupId,
            text:x.PricingGroupDescription,
          })))
        this.setState({
          pricingGroupLists:masterData,
        },this.alertNotFoundForPricingGroups)
        telemetryContext.logEvent(
          TelemetryEvent.FetchPricingGroupListBySupplierSuccess,
          {
            headers,
            url,
          }
        );
      // }
      })
      .catch((error) => {

        telemetryContext.logException(
          error.toString(),
          TelemetryException.FetchPricingGroupListBySupplierFailure,
          undefined,
          {
            headers,
            url,
          }
        );

        if (error.response && error.response.status === 404) {
          toast.error(
            'Automated supplier engagement list unable to fetch ',
            {
              className: css({
                background: '#a80000 !important',
              }),
            }
          );
        }
      });
  }
}

SaveEditSupplierEngagementsAttributes = () =>{
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };
      let url: string;
      var body = {};
        for(var i = 0 ;i< this.state.displaySeidListEdit.length;i++){
          if(this.state.captureField !== 'PricingGroup'){
            body[this.state.displaySeidListEdit[i]] = this.state.captureFieldValue;
          }else{
            body[this.state.displaySeidListEdit[i]] = JSON.stringify(this.state.captureFieldValue);
          }
        }    

      // Defining the url
        url =Routes.editSupplierEngagementsAttributes +`?fiscalyearnumber=${this.state.currentFiscalYearNumber}&attributeName=${this.state.captureField}`;
        telemetryContext.logEvent(TelemetryEvent.EditSupplierEngagementsAttributesBegin, {
          url,
          headers,
        });

      // Making the get call.
      return gtoMsalApiFetch(gtoNewApiInstance.get, url, {
        data: body,
        headers,
        method: 'PUT',
      })
        .then((response: any) => {
          this.setState({
            showeditPanel:false,
            captureFieldValue:'',
            supplierKeySelected:0,
            supplierValueSelected:'All',
           captureSearchtext:''
          },this.loadAutomatedSeidsList)
          this._selection.setAllSelected(false)

          telemetryContext.logEvent(TelemetryEvent.EditSupplierEngagementsAttributesSuccess, {
            url,
            headers,
          });
          // Mapping the response data.
          toast.success(response.data, {
            className: css({
                  background: '#107c10 !important',
                }),
              });

        })
        .catch((error) => {
          // Log Exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.EditSupplierEngagementsAttributesFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );
          // Error handling.
          if (error.response && error.response.status === 404) {
            toast.error('Unable Edit Attributes of Supplier Engagements', {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }
          if (error.response && error.response.status === 400) {
            this.setState({
              showeditPanel:false,})
            toast.error(error.response.data.length > 0 && error.response.data[0].errorMessage && error.response.data[0].errorMessage, {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }

          throw error;
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {});
    }
}

exportInvalidrecords = () =>{
  if(this.state.inValidRecordsfromAPI.length >0){
  exportToXlsx(this.state.inValidRecordsfromAPI,invalidAutomatedSeidExportheaders, "InValidAutomatedSupplierEnagements");
  }
}
validateUploadedRecordsAPI = () =>{
  this.setState({
    isFormLoading:true
  })
  // Checking if app is using mock data.
  if (appConfig.UseMockData === 'false') {
    // Creating headers for telemetry
    const headers: object = {
      'X-CorrelationId': uuidv4(),
      SubCorrelationKey: uuidv4(),
    };
    let url: string;
    // Defining the url
      url =Routes.validateautomatedengagements;
    // Making the get call.
    return gtoMsalApiFetch(gtoNewApiInstance.get, url, {
      data: this.state.uploadedAutomatedData,
      headers,
      method: 'POST',
    })
      .then((response: any) => {
        if(response.status === 200){
          if(response.data.length > 0) {
            response.data.forEach((x, i) => {
              if (x.IsValid === false) {
                toast.error(`Row ${i+1} ${x.ErrorMessage}`, {
                  className: css({
                    background: '#a80000 !important',
                  }),
                });
              }
            });
          this.setState({
            showuploadedGrid:false,
            inValidRecordsfromAPI:response.data.length > 0 && response.data.filter(x => x.IsValid === false ),
            validatedData:this.state.uploadedAutomatedData,
            validatedresponsefromAPI:response.data,
            isFormLoading:false,
            validRecordsFromAPI:response.data.length > 0 && response.data.filter(x => x.IsValid === true ),
            onclickValidate:true
           },this.exportInvalidrecords)
          }
        }
        telemetryContext.logEvent(TelemetryEvent.ValidateUploadedAutomatedSeidRecords, {
          url,
          headers,
        });
        // Mapping the response data.
        toast.success(response.data, {
          className: css({
                background: '#107c10 !important',
              }),
            });

      })
      .catch((error) => {
        this.setState({
          isFormLoading:false
        })
        // Log Exception
        telemetryContext.logException(
          error.toString(),
          TelemetryException.ValidateUploadedAutomatedSeidRecordsFailure,
          undefined,
          {
            url: url,
            headers,
          }
        );
          toast.error('Unable to validate uploaded Automated  Supplier Engagements', {
            className: css({
              background: '#a80000 !important',
            }),
          });
       

        throw error;
      });
  } else {
    //Setting mock data.
    return delay(2000).then(() => {});
  }
}
createAutomatedNewRecords =() =>{
  this.setState({
    isFormLoading:true
  })
  // Checking if app is using mock data.
  if (appConfig.UseMockData === 'false') {
    // Creating headers for telemetry
    const headers: object = {
      'X-CorrelationId': uuidv4(),
      SubCorrelationKey: uuidv4(),
    };
    let url: string;
    // Defining the url
      url =Routes.addautomatedsupplierengagements;

    // Making the get call.
    return gtoMsalApiFetch(gtoNewApiInstance.get, url, {
      data: this.state.validRecordsFromAPI,
      headers,
      method: 'POST',
    })
      .then((response: any) => {
        if(response.status === 200){
          if(response.data.length >0){
            exportToXlsx(response.data.filter((x) => x.ErrorMesage === '' || x.ErrorMesage === null), newAutomatedSupplierengagementsHeaders, "NewAutomatedSupplierengagementscreated");
            response.data.forEach((x) => {
              if (x.ErrorMesage === '' || x.ErrorMesage === null) {
                toast.success(`New record created - Engagement Id ${x.EngagementId} with Supplier Engagement Id ${x.SupplierEngagementId}`, {
                  className: css({
                    background: '#107c10 !important',
                  }),
                });
              } else {
                toast.error(`Engagement Id ${x.EngagementId} with Supplier Engagement Id ${x.SupplierEngagementId} is ${x.ErrorMesage}`, {
                  className: css({
                    background: '#a80000 !important',
                  }),
                });
              }
            });
            this.setState({
              isFormLoading:false,
              showaddPanel:false
             },this.loadFiscalYears
             )

          }else{
            toast.error('Please re-check Uploaded records to create Automated Supplier Engagements', {
              className: css({
                background: '#a80000 !important',
              }),
            });
            this.setState({
              isFormLoading:false,
              showaddPanel:false
             },this.loadFiscalYears
             )
          }
        }
        telemetryContext.logEvent(TelemetryEvent.AddAutomatedSeid, {
          url,
          headers,
        });
      })
      .catch((error) => {
        this.setState({
          isFormLoading:false,
          // showaddPanel:false,

        },this.loadFiscalYears)
        // Log Exception
        telemetryContext.logException(
          error.toString(),
          TelemetryException.AddAutomatedSeidFailure,
          undefined,
          {
            url: url,
            headers,
          }
        );

          toast.error('Unable to create Uploaded Automated Supplier Engagements', {
            className: css({
              background: '#a80000 !important',
            }),
          });

        throw error;
      });
  } else {
    //Setting mock data.
    return delay(2000).then(() => {});
  }
}
    private filterwithSupplierNumber = (e, newValue) => {
      this._selection.setAllSelected(false);
      var temp:any;
      var newArr:any;

      this.setState({
        captureSearchtext:newValue,
        filtersedis:[],
        filterseidsingle:''
      })
      if(newValue === '' && this.state.supplierValueSelected !=='All'){
        this.setState({
          automatedSeidItemsList:this.state.tempAutomatedSeidItemsList.length > 0 && this.state.tempAutomatedSeidItemsList.filter(x=> JSON.stringify(x['supplierName']).toLowerCase().includes(this.state.supplierValueSelected.toLowerCase()) )
        })

      }else if(newValue === '' && this.state.supplierValueSelected ==='All'){
        this.setState({
          automatedSeidItemsList:this.state.tempAutomatedSeidItemsList 
        })
      }else if(newValue.includes('\n') && newValue.includes(',')){
        temp = newValue.split('\n')
         newArr = temp.filter((a) => a);
        this.setState({
          filtersedis:newArr
        })
      }
      else if(newValue.includes(',')){
       temp = newValue.split(',')
        newArr = temp.filter((a) => a);
        this.setState({
          filtersedis:newArr
        })
      }else if(newValue.includes('\n')){
        temp = newValue.split('\n')
         newArr = temp.filter((a) => a);
        this.setState({
          filtersedis:newArr
        })
      }else{
        this.setState({
          filtersedis:[],
          filterseidsingle:newValue
        })
      }
      };
  

      onClickSearchSeid = () =>{
        if(this.state.captureSearchtext === '' && this.state.supplierValueSelected !== 'All'){
          this.setState({
            automatedSeidItemsList:this.state.tempAutomatedSeidItemsList.length > 0 && this.state.tempAutomatedSeidItemsList.filter(x=> JSON.stringify(x['supplierName']).toLowerCase().includes(this.state.supplierValueSelected.toLowerCase()) ),
            exportListData:this.state.tempexportData.length > 0 && this.state.tempexportData.filter(x=> JSON.stringify(x['supplierName']).toLowerCase().includes(this.state.supplierValueSelected.toLowerCase()) ),
          })
          return;
        }else if(this.state.captureSearchtext === '' && this.state.supplierValueSelected === 'All'){
            this.setState({
              automatedSeidItemsList:this.state.tempAutomatedSeidItemsList,
              exportListData:this.state.tempexportData
            })
            return;
        }
        var tempresults =[];
        var storetemp= [];
        var tempresultsexport =[];
        var storetempexport= [];


        if( this.state.supplierValueSelected === 'All' && this.state.filtersedis.length > 0){

          for(var i =0 ; i<this.state.filtersedis.length ;i++){
            tempresults =this.state.tempAutomatedSeidItemsList.length > 0 && this.state.tempAutomatedSeidItemsList.filter(x=> JSON.stringify(x['supplierEngagementId']).includes(this.state.filtersedis[i].trim(' ').replace(',','')));
            tempresultsexport = this.state.tempexportData.length > 0 && this.state.tempexportData.filter(x=> JSON.stringify(x['supplierEngagementId']).includes(this.state.filtersedis[i].trim(' ').replace(',','')));
            if(!tempresults || tempresults.length < 1){
            }else{
              tempresults = tempresults[0];
              tempresultsexport = tempresultsexport[0];
                storetemp.push(tempresults)
                storetempexport.push(tempresultsexport)

            }
                  this.setState({
                    automatedSeidItemsList:storetemp,
                    exportListData:storetempexport
                    })
                }
        }else if( this.state.supplierValueSelected && this.state.supplierValueSelected !== 'All' && this.state.filtersedis.length > 0){

          for(var i =0 ; i<this.state.filtersedis.length ;i++){
            tempresults =this.state.tempAutomatedSeidItemsList.length > 0 && this.state.tempAutomatedSeidItemsList.filter(x=> JSON.stringify(x['supplierName']).toLowerCase().includes(this.state.supplierValueSelected.toLowerCase()) ).filter(x=> JSON.stringify(x['supplierEngagementId']).includes(this.state.filtersedis[i].trim(' ').replace(',','')));
            tempresultsexport = this.state.tempexportData.length > 0 && this.state.tempexportData.filter(x=> JSON.stringify(x['supplierName']).toLowerCase().includes(this.state.supplierValueSelected.toLowerCase()) ).filter(x=> JSON.stringify(x['supplierEngagementId']).includes(this.state.filtersedis[i].trim(' ').replace(',','')));
            if(!tempresults || tempresults.length < 1){
            }else{
              tempresults = tempresults[0];
              tempresultsexport = tempresultsexport[0];
                storetemp.push(tempresults)
                storetempexport.push(tempresultsexport)
            }
                  this.setState({
                    automatedSeidItemsList:storetemp,
                    exportListData:storetempexport
                    })
                }
        }else if(this.state.supplierValueSelected !== 'All' && this.state.filterseidsingle !== ''){
            storetemp=[]
            tempresults=[]
            this.setState({
            automatedSeidItemsList:this.state.automatedSeidItemsList.length >0 && this.state.automatedSeidItemsList.filter(x=> JSON.stringify(x['supplierEngagementId']).includes(this.state.filterseidsingle)),
            exportListData:this.state.exportListData.length >0 && this.state.exportListData.filter(x=> JSON.stringify(x['supplierEngagementId']).includes(this.state.filterseidsingle))
              })
        }
        else {
          storetemp=[]
          tempresults=[]
            this.setState({
          automatedSeidItemsList:this.state.tempAutomatedSeidItemsList.length > 0 && this.state.tempAutomatedSeidItemsList.filter(x=> JSON.stringify(x['supplierEngagementId']).includes(this.state.filterseidsingle)),
          exportListData:this.state.tempexportData.length > 0 && this.state.tempexportData.filter(x=> JSON.stringify(x['supplierEngagementId']).includes(this.state.filterseidsingle)),
        })
        }
      }

      private buildFiscalYearDropdown = (): IDropdownOption[] => {
        let options: IDropdownOption[] = [];
        this.state.getFiscalYearsList.forEach((x) => {
          options.push({
            key: x,
            text: `Fiscal Year ${x.toString()}`,
          });
        });
    
        return options;
      };
        
      //Function for onChange of Fiscal year dropdown
  private onFiscalYearDropdownChange(o) {
    this._selection.setAllSelected(false)

    this.setState({
      currentFiscalYearNumber:o.key,
      supplierKeySelected:0,
      supplierValueSelected:'All',
      captureSearchtext:'',
    },this.loadAutomatedSeidsList) 
  }

  private onchangeMasterName = (o: IDropdownOption) => {
    this._selection.setAllSelected(false)

    this.setState({
      captureSearchtext:'',
      supplierValueSelected:o.text,
      supplierKeySelected:o.key
    })
    if(o.text === 'All'){
      this.setState({
        automatedSeidItemsList:this.state.tempAutomatedSeidItemsList,
      })
    }else{
      this.setState({
        automatedSeidItemsList:this.state.tempAutomatedSeidItemsList.filter(x=> JSON.stringify(x['supplierName']).toLowerCase().includes(o.text.toLowerCase()) ),
        exportListData:this.state.tempexportData.filter(x=> JSON.stringify(x['supplierName']).toLowerCase().includes(o.text.toLowerCase()) )
      },this.alertNotFound)
    }
  };

  alertNotFound = () =>{
    if(this.state.automatedSeidItemsList.length  === 0){

      toast.error(`No records found for selected supplier ${this.state.supplierValueSelected} `, {
        className: css({
          background: '#a80000 !important',
        }),
      });
    }
  }

  alertNotFoundForFiscalYear = () =>{
    if(this.state.automatedSeidItemsList.length  === 0){

      toast.error(`No records found for selected fiscal year ${this.state.currentFiscalYearNumber} `, {
        className: css({
          background: '#a80000 !important',
        }),
      });
    }
  }

  alertNotFoundForPricingGroups = () =>{
    if(this.state.pricingGroupLists.length  === 0){

      toast.error(`No pricing groups  found for selected supplier`, {
        className: css({
          background: '#a80000 !important',
        }),
      });
    }
  }


  private onchangeSelectAttributes(o){
    this.setState({
      captureFieldValue:''
    })
    this.setState({
      captureField:o.key,
      captureFieldType:o.type,
      captureFieldName:o.text
    },this.loadPrcingGroupBySupplierId)
  }
  private onchangePricing(o){

    this.setState({
      captureFieldValue:''
    })
    
    this.setState({
      capturePricingGroupId:o.key,
      captureFieldValue:o.key
    })
  }
onclickaddbutton =()=>{
  this.setState({
    showaddPanel:true,
  })
}
  onclickeditpermissions = () => {
      this.setState({
        showeditPanel:true,
        captureField:''
      },this.formatvalues)
  }

  formatvalues (){
    var temp =[];
    var tempIdsWithNames =[];
    var tempnames =[];
    var supplierID =[];
    for(var i= 0;i<this.state.selectedSupplierEngagements.length;i++){
      temp.push(this.state.selectedSupplierEngagements[i].supplierEngagementId)
      tempIdsWithNames.push(this.state.selectedSupplierEngagements[i].supplierEngagementId +'-' +this.state.selectedSupplierEngagements[i].supplierName)
      if(tempnames.indexOf(this.state.selectedSupplierEngagements[i].supplierName) > -1){
        
      }else{
        tempnames.push(this.state.selectedSupplierEngagements[i].supplierName)
        supplierID.push(this.state.selectedSupplierEngagements[i].supplierId)
      }
    }
      this.setState({
        displaySeidListEdit:temp.length > 0 && temp.sort(function (a, b) {
          return a.value - b.value;
        }),
        displaySeidWithSupplierNames :tempIdsWithNames,
        alertFlagOnDifferentSupplierSlection : tempnames.length > 1 ? true : false,
        captureUniqueSupplierSelected:supplierID && supplierID.length > 0 ? supplierID [0] :''
      })
      
}

  ondismissEdit = () => {
    this.setState({
      showeditPanel: false,
      showaddPanel:false,
      showuploadedGrid:false,
      onclickValidate:false,
      uploadedAutomatedData:[],
      validRecordsFromAPI:[],
      validatedresponsefromAPI:[],
      fileName:'or drag and drop files here',
      duplicateStatus:false
    });
  };
  private onchangeFieldValue = (e,newValue) =>{
    this.setState({
      captureFieldValue:newValue,

    })
  }
  private _getErrorMessageOnFieldValidations = (value: string): string => {
    let errorMessage: string = '';
    if(value.length > 0 ){
  }else{
    errorMessage=`Enter valid ${this.state.captureFieldName.toLowerCase()}`
  }
    return errorMessage;
  };

  renderSelectedSupplierEngagementsIdList =() =>{
    return this.state.displaySeidWithSupplierNames.map((x,i) =>
    
     <li aria-label= {x} tabIndex={0}>{x}</li> )
  }
  private _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      if (!props.item.IsValid) {
        // Every other row renders with a different background color
        customStyles.root = { 
          border:'2px red solid'
        };
      }

      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  };
  private _renderItemColumn(item: any, index: number, column: IColumn) {
    const fieldContent = item[column.fieldName] as string;
    var InvalidList :any=[];
    if(item.InvalidPropertyName !== null){
      InvalidList = item.InvalidPropertyName.split(',')
      if(InvalidList.indexOf(column.fieldName) > -1){
        return <span
        data-selection-disabled={true}
        className={mergeStyles({ borderColor: fieldContent, height: '100%', display: 'block'})}
        style={{backgroundColor:'#f3f39b',opacity:'0.5'}}
      >
        {fieldContent}
      </span>
      }else{
        return <span>{fieldContent}</span>
      }
      
    }else{
      return <span>{fieldContent}</span>;

    }
  }
    
    render() {
        return (
          <React.Fragment>
            {this.state.isFormLoading && (
              <Spinner
                size={SpinnerSize.small}
                styles={AutomatedSEIDStyles.spinnerLoadingIcon}
              >
                Loading...
              </Spinner>
            )}
            <div className="mainDiv">
              <HelmetTab title={'Automated Supplier Engagements'} />
              <h1
                className="pageTitle"
                aria-label={'Automated Supplier Engagements'}
              >
                {!this.state.showaddPanel ? 'Automated Supplier Engagements' :'Create Automated Supplier Engagements' }
              </h1>

              {!this.state.showaddPanel && (
                <div className="ms-Grid" dir="ltr">
                  <div className="ms-Grid-row filterRow">
                    <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2">
                      <Dropdown
                        aria-label="Fiscal Year Dropdown"
                        label="Fiscal Year"
                        placeholder={
                          this.state.currentFiscalYearNumber !== undefined
                            ? `Fiscal Year ${this.state.currentFiscalYearNumber}`
                            : 'Select an option'
                        }
                        id="fiscalYearDropdown"
                        title="FiscalYear"
                        onChange={(e, o) => this.onFiscalYearDropdownChange(o)}
                        options={this.buildFiscalYearDropdown()}
                      />
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2">
                      <Dropdown
                        aria-label="Record Type"
                        placeholder="Supplier Engagement"
                        label="Record Type"
                        id="RecordType"
                        title="RecordType"
                        options={[
                          {
                            key: 0,
                            text: 'Supplier Engagement',
                          },
                        ]}
                      />
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2">
                      <Dropdown
                        placeholder="Supplier Name"
                        label="Supplier Name"
                        ariaLabel="Supplier Name"
                        options={this.state.supplierMasterOptions}
                        required={true}
                        onChange={(e, o) => this.onchangeMasterName(o)}
                        selectedKey={this.state.supplierKeySelected}
                      />
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2">
                      <TextField
                        placeholder="Enter to search supplier engagement id's"
                        label="Supplier Engagement Id's"
                        multiline
                        rows={4}
                        onChange={this.filterwithSupplierNumber}
                        value={this.state.captureSearchtext}
                      />
                    </div>

                    <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2 searchButton">
                      <PrimaryButton
                        text="Search"
                        iconProps={{ iconName: 'search' }}
                        onClick={() => this.onClickSearchSeid()}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="ms-Grid" dir="ltr">
                <div
                  className="ms-Grid-row"
                  style={{
                    marginLeft: '10px',
                    margin: '10px',
                    marginTop: '15px',
                  }}
                >
                  <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg12">
                  {!this.state.showaddPanel && (this.props.IsInvoiceAnalyst ||
                        this.props.IsSupplierSupportManager) && (<PrimaryButton
                      text="Add"
                      iconProps={{ iconName: 'EditNote' }}
                      style={{ marginRight: '10px' }}
                      onClick={() => this.onclickaddbutton()}
                    />)}
                    {!this.state.showaddPanel &&
                      (this.props.IsInvoiceAnalyst ||
                        this.props.IsSupplierSupportManager) && (
                        <PrimaryButton
                          text="Edit"
                          iconProps={{ iconName: 'EditNote' }}
                          style={{ marginRight: '10px' }}
                          disabled={this.state.disabledit}
                          onClick={() => this.onclickeditpermissions()}
                        />
                      )}
                    {!this.state.showaddPanel && (
                      <PrimaryButton
                        text="Export"
                        iconProps={{ iconName: 'ExcelDocument' }}
                        style={{ marginLeft: '10px' }}
                        disabled={
                          this.state.exportListData.length > 0 ? false : true
                        }
                        onClick={() =>
                          exportToXlsx(
                            this.state.exportListData,
                            automatedSeidExportheaders,
                            'Automatedupplierengagements'
                          )
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
              <Panel
                isOpen={this.state.showeditPanel}
                onDismiss={this.ondismissEdit}
                type={PanelType.medium}
                closeButtonAriaLabel="Close"
                headerText={'Edit Automated Supplier Engagements'}
              >
                <div>
                  <p
                    aria-label="Selected Automated Supplier Engagements Ids List"
                    style={{ fontWeight: 500 }}
                  >
                    Selected Automated Supplier Engagements Id's List
                  </p>
                  {this.state.displaySeidWithSupplierNames.length < 7 && (
                    <ul className="grid-list-length selectedListDiv-length">
                      {this.renderSelectedSupplierEngagementsIdList()}
                    </ul>
                  )}
                  {this.state.displaySeidWithSupplierNames.length > 6 && (
                    <ul className="grid-list selectedListDiv">
                      {this.renderSelectedSupplierEngagementsIdList()}
                    </ul>
                  )}
                </div>

                <div className="ms-Grid" dir="ltr">
                  <div className="ms-Grid-row commonMargin">
                    <Dropdown
                      placeholder="Select attribute"
                      label="Select attribute"
                      ariaLabel="Select attribute"
                      options={this.state.editFields}
                      onChange={(e, o) => this.onchangeSelectAttributes(o)}
                    />
                  </div>
                  {this.state.captureField !== '' && (
                    <div>
                      {this.state.captureField === 'PricingGroup' ? (
                        <div className="ms-Grid-row commonMargin">
                          <Dropdown
                            placeholder="Select Pricing Group"
                            label="Pricing"
                            ariaLabel="Pricing"
                            options={this.state.pricingGroupLists}
                            onChange={(e, o) => this.onchangePricing(o)}
                          />
                          {this.state.alertFlagOnDifferentSupplierSlection && (
                            <p style={{ color: '#B2484D' }}>
                              {' '}
                              Alert ! Please select automated supplier
                              engagement id's of single supplier to edit pricing
                            </p>
                          )}
                        </div>
                      ) : (
                        <div className="ms-Grid-row commonMargin">
                          <TextField
                            label={this.state.captureFieldName}
                            type={this.state.captureFieldType}
                            onChange={this.onchangeFieldValue}
                            value={this.state.captureFieldValue}
                            placeholder={`Please enter valid ${this.state.captureField.toLowerCase()} value`}
                          />
                        </div>
                      )}
                      <div className="editButtonGroup commonMargin">
                        <DefaultButton
                          data-automation-id="cancelBtn"
                          text="Cancel"
                          onClick={() => {
                            this.ondismissEdit();
                          }}
                          style={{ margin: '10px' }}
                        />
                        {this.state.captureField === 'PricingGroup' ? (
                          <PrimaryButton
                            data-automation-id="submitBtn"
                            role="button"
                            text="Save"
                            disabled={
                              this.state.alertFlagOnDifferentSupplierSlection
                            }
                            onClick={() =>
                              this.SaveEditSupplierEngagementsAttributes()
                            }
                            style={{ float: 'right', margin: '10px 0px' }}
                          />
                        ) : (
                          <PrimaryButton
                            data-automation-id="submitBtn"
                            role="button"
                            text="Save"
                            disabled={
                              this.state.captureFieldValue !== '' ? false : true
                            }
                            onClick={() =>
                              this.SaveEditSupplierEngagementsAttributes()
                            }
                            style={{ float: 'right', margin: '10px 0px' }}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </Panel>
              {this.state.showaddPanel && (
                <div>
                  {' '}
                  <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        &nbsp;
                      </div>
                      <div
                        className="ms-Grid-col ms-sm4 ms-md4 ms-lg4"
                        style={AutomatedSEIDStyles.marginTop25Left10}
                      >
                        <div className="file-drop-area">
                          <span className="fake-btn">Choose files</span>
                          <span className="file-msg">
                            {this.state.fileName}
                          </span>
                          <input
                            aria-label="upload a excel file"
                            className="file-input"
                            type="file"
                            onChange={(event) => this.readUploadFile(event)}
                            ref={textFieldRef}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            disabled={this.state.onclickValidate}
                          />
                        </div>
                      </div>

                      <div
                        className="ms-Grid-col ms-sm1 ms-md1 ms-lg1"
                        style={AutomatedSEIDStyles.paddingTop51}
                      >
                        <Link
                          onClick={() =>
                            exportToXlsx(
                              [],
                              automatedSeidTemplateheaders,
                              'AutomatedSeidUploadTemplate'
                            )
                          }
                        >
                          Download Template
                        </Link>
                      </div>
                      <div className="editButtonGroupUpload ">
                        <DefaultButton
                          data-automation-id="cancelBtn"
                          text="Cancel"
                          onClick={() => {
                            this.ondismissEdit();
                          }}
                          style={{ margin: '10px' }}
                        />

                        {!this.state.onclickValidate && (
                          <PrimaryButton
                            data-automation-id="submitBtn"
                            role="button"
                            text="Validate"
                            disabled={
                              this.state.fileName ===
                              'or drag and drop files here' || this.state.duplicateStatus
                                ? true
                                : false
                            }
                            onClick={() => this.validateUploadedRecordsAPI()}
                            style={{ float: 'right', margin: '10px 10px' }}
                          />
                        )}
                        {this.state.validatedresponsefromAPI.length > 0 &&
                          this.state.validRecordsFromAPI.length > 0 && (
                            <PrimaryButton
                              data-automation-id="submitBtn"
                              role="button"
                              text="Submit"
                              disabled={
                                this.state.fileName !==
                                'or drag and drop files here'
                                  ? false
                                  : true
                              }
                              onClick={() => this.createAutomatedNewRecords()}
                              style={{
                                float: 'right',
                                margin: '10px 10px 0px 0px',
                              }}
                            />
                          )}
                      </div>
                    </div>
                  </div>
                  {this.state.uploadedAutomatedData.length > 0 &&
                    this.state.showuploadedGrid && (
                      <MessageBar>
                        Grid Populated with unique records from uploaded excel
                      </MessageBar>
                    )}
                  {this.state.validatedresponsefromAPI.length > 0 &&
                    this.state.validRecordsFromAPI.length > 0 &&
                    this.state.showaddPanel && (
                      <MessageBar
                        messageBarType={MessageBarType.success}
                        isMultiline={false}
                      >
                        {this.state.validRecordsFromAPI.length} out of{' '}
                        {this.state.validatedresponsefromAPI.length} rows are
                        valid, Do you want to procced submitting valid rows?
                      </MessageBar>
                    )}
                  {this.state.uploadedAutomatedData.length > 0 &&
                    this.state.showuploadedGrid && (
                      <div className="Uploadgridproperties">
                        <DetailsList
                          compact={true}
                          items={this.state.uploadedAutomatedData}
                          columns={_automatedseidGridUploadColumns}
                          setKey="set"
                          layoutMode={DetailsListLayoutMode.justified}
                          selectionPreservedOnEmptyClick={true}
                          styles={gridStyles}
                          ariaLabelForSelectionColumn="Toggle selection"
                          ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                          checkButtonAriaLabel="select row"
                          selectionMode={SelectionMode.none}
                        />
                      </div>
                    )}
                  {this.state.validatedresponsefromAPI.length > 0 && 
                    this.state.showaddPanel && (
                      <div className="Uploadgridproperties">
                        <DetailsList
                          compact={true}
                          items={this.state.validatedresponsefromAPI}
                          columns={_automatedseidGridUploadColumns}
                          setKey="set"
                          layoutMode={DetailsListLayoutMode.justified}
                          onRenderRow={this._onRenderRow}
                          selectionMode={SelectionMode.none}
                          selectionPreservedOnEmptyClick={true}
                          styles={gridStyles}
                          ariaLabelForSelectionColumn="Toggle selection"
                          ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                          checkButtonAriaLabel="select row"
                        />
                      </div>
                    )}
                </div>
              )}

              {!this.state.showaddPanel && (
                <div className="gridproperties">
                  <DetailsList
                    compact={true}
                    items={this.state.automatedSeidItemsList}
                    columns={this._automatedseidGridColumns}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.justified}
                    selection={this._selection}
                    selectionPreservedOnEmptyClick={true}
                    selectionMode={
                      this.props.IsInvoiceAnalyst ||
                      this.props.IsSupplierSupportManager
                        ? SelectionMode.multiple
                        : SelectionMode.none
                    }
                    styles={gridStyles}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="select row"
                  />
                </div>
              )}
            </div>
          </React.Fragment>
        );}
}
        const mapDispatchToProps = (dispatch, props) => {
          return {
            getUserRoles: () => {
              dispatch(getUserRoles());
            },
          };
        };
        const mapStateToProps = (state) => {
          return {
            IsInvoiceAnalyst: state.user.IsInvoiceAnalyst,
            IsSupplierSupportManager:state.user.IsSupplierSupportManager
          };
        };

export default connect(mapStateToProps, mapDispatchToProps)(Automatedseid)
