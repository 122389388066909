import { INrcType, INrcCategory, INrcDescription } from '../models/NRC';

export const NRCTypesMockData: INrcType[] = [
  {
    TypeId: 2,
    Type: 'Incentives',
    IsActive: true,
  },
  {
    TypeId: 3,
    Type: 'Labor Payments',
    IsActive: true,
  },
  {
    TypeId: 4,
    Type: 'Other',
    IsActive: true,
  },
  {
    TypeId: 5,
    Type: 'PAM',
    IsActive: true,
  },
  {
    TypeId: 6,
    Type: 'T&E',
    IsActive: true,
  },
  {
    TypeId: 7,
    Type: 'Training',
    IsActive: true,
  },
  {
    TypeId: 8,
    Type: 'TT&I Charges',
    IsActive: true,
  },
];

export const NRCCategoryMockData: INrcCategory[] = [
  {
    CategoryID: 92,
    Type: 'TT&I Charges',
    TypeId: 8,
    Category: 'Access Charge',
    IsActive: true,
    IsDiscounted: true,
    IsScheduleRestricted:true
  },
  {
    CategoryID: 32,
    Type: 'Other',
    TypeId: 4,
    Category: 'Address Validation Penalty',
    IsActive: true,
    IsDiscounted: true,
    IsScheduleRestricted:true
  },
  {
    CategoryID: 56,
    Type: 'Training',
    TypeId: 7,
    Category: 'Agent Desktop (AD)',
    IsActive: true,
    IsDiscounted: true,
    IsScheduleRestricted:true
  },
  {
    CategoryID: 10,
    Type: 'Labor Payments',
    TypeId: 3,
    Category: 'Agent Roundtable',
    IsActive: true,
    IsDiscounted: true,
    IsScheduleRestricted:true
  },
  {
    CategoryID: 93,
    Type: 'TT&I Charges',
    TypeId: 8,
    Category: 'Automate and Eliminate',
    IsActive: true,
    IsDiscounted: true,
    IsScheduleRestricted:true
  },
  {
    CategoryID: 1,
    Type: 'Incentives',
    TypeId: 2,
    Category: 'Awards - Quality',
    IsActive: true,
    IsDiscounted: true,
    IsScheduleRestricted:true
  },
];

export const NRCDescriptionMockData: INrcDescription[] = [
  {
    NRCId: 1,
    SupplierEngagementId: 1234,
    NRCTypeId: 2,
    NRCCategoryId: 92,
    Description: 'Test description',
    AnaplanNRCId: null,
    IsActive: true,
  },
  {
    NRCId: 1,
    SupplierEngagementId: 1234,
    NRCTypeId: 3,
    NRCCategoryId: 32,
    Description: 'Test description 1',
    AnaplanNRCId: null,
    IsActive: true,
  },
  {
    NRCId: 1,
    SupplierEngagementId: 1234,
    NRCTypeId: 4,
    NRCCategoryId: 56,
    Description: 'Test description 2',
    AnaplanNRCId: null,
    IsActive: true,
  },
  {
    NRCId: 1,
    SupplierEngagementId: 1234,
    NRCTypeId: 5,
    NRCCategoryId: 10,
    Description: 'Test description 3',
    AnaplanNRCId: null,
    IsActive: true,
  },
  {
    NRCId: 1,
    SupplierEngagementId: 1234,
    NRCTypeId: 6,
    NRCCategoryId: 93,
    Description: 'Test description 4',
    AnaplanNRCId: null,
    IsActive: true,
  },
];
