import * as React from 'react';

class SupplierDoc extends React.Component {
    render() {
        return (
            <div>
                <h4>Supplier</h4>
            </div>
        );
    }
}
export default SupplierDoc;