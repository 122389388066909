import React from 'react';
import { IconButton } from 'office-ui-fabric-react';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { Dropdown, IDropdownOption,IDropdown} from '@fluentui/react';
import AdditionalAttributes from '../additionalAttributes';
import { IInvoiceBillableUnitDetails } from '../../../shared/models/InvoiceBillableUnitDetails';
import DisplayFilter from '../DisplayFilters/DisplayFilter';
import { IDataCount } from '../../../shared/models/DataCount';
import { connect } from 'react-redux';
import { amountCalculationHeadCount } from '../../../store/actions/headCount.action';
import { amountCalculationInvoice } from '../../../store/actions/invoiceView.action';

export interface Props {
  item: IInvoiceBillableUnitDetails[];
  onFilterChange: any;
  allItems: IInvoiceBillableUnitDetails[];
  columnSorted: any;
  disable: boolean;
  isEditable: boolean;
  amountCalculationHeadCount: any;
  amountCalculationInvoice: any;
  isActionChanged: boolean;
  columns: IColumn[];
}

export interface IFilterData {
  column: string;
  columnValue: string;
  filter: string;
  key: string;
}

export interface IFilterState {
  columnList: any[];
  selectedColumn: string;
  selectedColumnValue: string;
  filterOptions: IDropdownOption[];
  selectedFilter: string;
  filterData: IFilterData[];
  showdivonzoomchange:boolean
  dropdownwidth:string
}

const dropDownRef = React.createRef<IDropdown>();

class FilterDropdown extends React.Component<Props, IFilterState> {
  constructor(props) {
    super(props);
    this.state = {
      columnList: [],
      selectedColumn: '',
      selectedColumnValue: '',
      filterOptions: [],
      selectedFilter: '',
      filterData: [],
      showdivonzoomchange:false,
      dropdownwidth:'41%'
    };
  }

  public componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    const columns: IDropdownOption[] = [];
    this.props.columns.forEach((x) => {
      if (x.key !== 'State' && x.key !== 'Accept' && x.key !== 'Level') {
        columns.push({
          key: x.key,
          text: x.name,
        });
      }
    });
    AdditionalAttributes.forEach((x) => {
      columns.push({
        key: x.name,
        text: x.displayName,
      });
    });
    columns.sort((a, b) => (a.text > b.text ? 1 : -1));
    this.setState({ columnList: columns });
    
  }
  resize = () =>{
    let widnowSize = window.innerWidth;
    if(widnowSize <1400 && widnowSize>=1024){
      this.setState({
        dropdownwidth:'50%'
      })
      }else{
         this.setState({
        dropdownwidth:'41%'
      })
      }
if(widnowSize <1024){
this.setState({
  showdivonzoomchange:true
})
}else{
  this.setState({
    showdivonzoomchange:false
  })
}
  }

  public componentDidUpdate(prevProps) {
    if (prevProps.isActionChanged !== this.props.isActionChanged) {
      this.setState({ filterData: [] });
    }
  }

  //Function to handle the onchange event for Column
  private handleColumnChange = (evt, option) => {
    this.setState(
      { selectedColumn: option.key, selectedColumnValue: option.text },
      () => {
        let columnOptions = new Set<string>();
        if (
          this.state.selectedColumn === 'Price' ||
          this.state.selectedColumn === 'PricingType'
        ) {
          this.props.item.forEach((item) => {
            item.PriceLine.forEach((price) => {
              columnOptions.add(price[this.state.selectedColumn]);
            });
          });
        } else {
          this.props.item.forEach((item) => {
            columnOptions.add(item[this.state.selectedColumn]);
          });
        }
        const uniqueColumnOptions = Array.from(columnOptions);
        uniqueColumnOptions.sort();
        let filterOptionList: IDropdownOption[] = [];
        uniqueColumnOptions.forEach((x, index) => {
          if (x !== null) {
            filterOptionList.push({
              key: index,
              text: x,
            });
          }
        });
        this.setState({ filterOptions: filterOptionList });
      }
    );
  };

  //Function to handle onchange event for filter dropdown
  private handleFilterChange = (evt, option) => {
    this.setState({ selectedFilter: option.text });
  };

  //Function to add filters on add button click
  private addFilters = () => {
    let filterData = [...this.state.filterData];
    filterData.push({
      column: this.state.selectedColumn,
      columnValue: this.state.selectedColumnValue,
      filter: this.state.selectedFilter,
      key: this.state.selectedColumn,
    });
    this.setState({ filterData: filterData }, () => {
      let filterItems = this.getFilteredRows(this.props.allItems);
      let itemCount: IDataCount = this.getItemCount(filterItems);
      this.props.onFilterChange(filterItems, itemCount, true);
      this.setState({
        selectedFilter: '',
        selectedColumnValue: '',
        selectedColumn: '',
      });
    });
  };

  //Function to filter the data on applying filter change
  private getFilteredRows = (items: any[]): any[] => {
    let filteredItems: any[] = [...items];
    this.props.columnSorted(false);
    this.state.filterData.forEach((filterChangeData) => {
      if (filterChangeData.column !== '' && filterChangeData.filter !== '') {
        if (
          filterChangeData.column === 'PricingType' ||
          filterChangeData.column === 'Price'
        ) {
          filteredItems = filteredItems.filter((item) =>
            item.PriceLine.some(
              (priceLine) =>
                priceLine[filterChangeData.column] === filterChangeData.filter
            )
          );
        } else {
          filteredItems = filteredItems.filter((item) => {
            return item[filterChangeData.column] === filterChangeData.filter;
          });
        }
      }
    });

    this.props.isEditable
      ? this.props.amountCalculationHeadCount(filteredItems, true)
      : this.props.amountCalculationInvoice(filteredItems);

    return filteredItems;
  };

  //Funtion to remove filters on cancel button click
  private removeFilteredData = (filterData: IFilterData) => {
    let index = this.state.filterData.findIndex(
      (x) => x.key === filterData.key
    );
    let removeData = [...this.state.filterData];
    removeData.splice(index, 1);
    this.setState({ filterData: removeData }, () => {
      let filterItems = this.getFilteredRows(this.props.allItems);
      let itemCount: IDataCount = this.getItemCount(filterItems);
      this.props.onFilterChange(filterItems, itemCount, false);
    });
    this.props.columnSorted(false);
    dropDownRef.current.focus();
  };

  //Funtion to find the count of the items after the data is filtered
  private getItemCount = (filterItems: IInvoiceBillableUnitDetails[]) => {
    let itemCount: IDataCount = {
      All: 0,
      Volume: 0,
      NRC: 0,
      PPA: 0,
    };
    itemCount.All = filterItems.length;
    itemCount.NRC = filterItems.filter(
      (x) => x.IsNRC && !x.IsPriorPeriodAdjustment
    ).length;
    itemCount.Volume = filterItems.filter((x) => x.IsNRC === false).length;
    itemCount.PPA = filterItems.filter(
      (x) => x.IsPriorPeriodAdjustment === true
    ).length;

    return itemCount;
  };

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '85%',
          justifyContent: 'space-between',
        }}
      >
      {this.state.showdivonzoomchange ? <div className="ms-Grid">
          <div className="ms-Grid-row parent">
              <div className="ms-Grid-col">
              <Dropdown
            placeholder={
              this.state.selectedColumnValue === ''
                ? 'Select Filter'
                : this.state.selectedColumnValue
            }
            onChange={this.handleColumnChange}
            options={this.state.columnList}
            selectedKey={this.state.selectedColumn}
            calloutProps={{ calloutMaxHeight: 220 }}
            style={{ width: '100%',whiteSpace: 'normal'}}
            disabled={this.props.disable}
            aria-label="Select Filter"
            componentRef={dropDownRef}
          />
              </div>
              <div className="ms-Grid-col">
              <Dropdown
            placeholder={
              this.state.selectedFilter === ''
                ? 'Select Value'
                : this.state.selectedFilter
            }
            style={{ width: '100%' }}
            onChange={this.handleFilterChange}
            options={this.state.filterOptions}
            calloutProps={{ calloutMaxHeight: 220 }}
            selectedKey={this.state.filterOptions.length === 1 && 1}
            disabled={
              this.state.selectedColumn === '' ||
              this.props.disable ||
              this.state.filterOptions.length === 0
            }
            aria-label="Select Value"
          />
              </div>
              <div className="ms-Grid-col">
              <IconButton
            iconProps={{ iconName: 'Add' }}
            disabled={
                  this.state.selectedColumn === '' ||
                  this.state.selectedFilter === '' ||
                  this.props.disable
            }
            onClick={this.addFilters}
            aria-label="Add Filter"
          />
              </div>
              </div>
              </div>
              :
         <div
          className="filter-dropdowns"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: this.state.dropdownwidth,
            marginTop: '0.5%',
          }}
        >
          <Dropdown
            placeholder={
              this.state.selectedColumnValue === ''
                ? 'Select Filter'
                : this.state.selectedColumnValue
            }
            onChange={this.handleColumnChange}
            options={this.state.columnList}
            selectedKey={this.state.selectedColumn}
            calloutProps={{ calloutMaxHeight: 220 }}
            style={{ width: '100%',whiteSpace: 'normal'}}
            disabled={this.props.disable}
            aria-label="Select Filter"
            componentRef={dropDownRef}
          />
          <Dropdown
            placeholder={
              this.state.selectedFilter === ''
                ? 'Select Value'
                : this.state.selectedFilter
            }
            style={{ width: '100%' }}
            onChange={this.handleFilterChange}
            options={this.state.filterOptions}
            calloutProps={{ calloutMaxHeight: 220 }}
            selectedKey={this.state.filterOptions.length === 1 && 1}
            disabled={
              this.state.selectedColumn === '' ||
              this.props.disable ||
              this.state.filterOptions.length === 0
            }
            aria-label="Select Value"
          />
          <IconButton
            iconProps={{ iconName: 'Add' }}
            disabled={
                  this.state.selectedColumn === '' ||
                  this.state.selectedFilter === '' ||
                  this.props.disable
            }
            onClick={this.addFilters}
            aria-label="Add Filter"
          />
        </div> }
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: '1%',
            width: '118%',
            flexWrap: 'wrap',
          }}
        >
          {this.state.filterData.map((x) => {
            return (
              <DisplayFilter
                displayData={x}
                key={x.key}
                onDelete={this.removeFilteredData}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    amountCalculationHeadCount: (
      billableUnits: IInvoiceBillableUnitDetails[],
      isFilter: boolean
    ) => {
      dispatch(amountCalculationHeadCount(billableUnits, isFilter));
    },
    amountCalculationInvoice: (
      billableUnits: IInvoiceBillableUnitDetails[]
    ) => {
      dispatch(amountCalculationInvoice(billableUnits));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    isActionChanged: state.invoiceView.isActionChanged,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterDropdown);
