import React, { Component } from 'react';
import {
  getPendingAccessRequest,
  updateRequestedRoles,
  updateJustification,
  submitRequest,
  sendMailReminder,
  setMailReminderDialogVisibility,
} from '../../store/actions/unAuthUser.action';
import { connect } from 'react-redux';
import { IUserRequestedRole } from '../../shared/models/UserRequestedRole.model';
import {
  Stack,
  Label,
  Checkbox,
  TextField,
  PrimaryButton,
  DefaultButton,
  Dialog,
  DialogType,
  DialogFooter,
} from 'office-ui-fabric-react';
import './Unauthorized.css';
import 'office-ui-fabric-react/dist/css/fabric.css';
import Footer from '../Footer/Footer';

export interface IUnAuthUserProps {
  getPendingAccessRequest(string): any;
  updateRequestedRoles(number, boolean): any;
  updateJustification(string): any;
  submitRequest(): any;
  sendMailReminder(): any;
  setMailReminderDialogVisibility(boolean): any;
  RequestId: number;
  BusinessJustification: string;
  UserAlias: string;
  UserName: string;
  MailReminderEligible: boolean;
  MailReminderInHours: number;
  RolesChanged: boolean;
  UserRequestedRoles: IUserRequestedRole[];
  ShowRequestForm: boolean;
  IsRequestSubmitted: boolean;
  hideReminderDialog: boolean;
  ShowMailReminderDialog: boolean;
}

class Unauthorized extends Component<IUnAuthUserProps, {}> {
  // --------------------- LIFECYCLE METHODS ---------------------------- //
  componentDidMount() {
    this.props.getPendingAccessRequest(this.props.UserName);
  }

  public render() {
    if (this.props.ShowRequestForm) {
      return (
        <main>
          <Stack className="unAuthUser_Main_Container" verticalFill gap={20}>
            {this.renderRequestStatusMessage()}
            {this.props.IsRequestSubmitted ? (
              ''
            ) : (
              <div className="ms-Grid">
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col unAuth_Form_Container">
                    <h4
                      style={{ textDecoration: 'underline', marginTop: '5px' }}
                    >
                      {this.props.RequestId > 0
                        ? 'Update Access Request'
                        : 'New Access Request'}
                    </h4>
                    {this.renderRequestForm()}
                    <React.Fragment>
                        <Footer></Footer>
                    </React.Fragment>
                  </div>
                </div>
              </div>
            )}
          </Stack>
        </main>
      );
    } else {
      return '';
    }
  }

  private renderRequestStatusMessage() {
    let message: string =
      this.props.RequestId > 0
        ? 'Your request for access is pending approval from admin.'
        : 'Authorization denied. You are not yet set up to use the Invoicing Tool. Please provide the below details and request access.';
    if (this.props.IsRequestSubmitted) {
      message =
        'Request has been submitted. You will receive an email notification after admin approval.';
    }
    return (
      <div className="unAuth_Message_Container">
        <p> {message} </p>
      </div>
    );
  }

  private renderRequestForm() {
    return (
      <div className="ms-Grid unauth_Request_Form" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm6 ms-md3 ms-lg3">
            <Label className="ms-Label">Role</Label>
          </div>
          <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg8 inputCol">
            <div className="roleConainer">
              {this.props.UserRequestedRoles.map(role => {
                return (
                  <div key={role.roleId} style={{ marginBottom: '1vh' }}>
                    <Checkbox
                      label={role.roleName}
                      checked={role.isRequested}
                      onChange={(ev, isChecked) =>
                        this._onRoleChange(role.roleId, ev, isChecked)
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm6 ms-md3 ms-lg3">
            <Label className="ms-label">Business Justification</Label>
          </div>
          <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg8 inputCol">
            <TextField
              value={this.props.BusinessJustification}
              onChange={(e, newString) =>
                this.props.updateJustification(newString)
              }
              maxLength={500}
              multiline
              rows={3}
              required={true}
              className="ms-TextField-field"
            />
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm6 ms-md3 ms-lg3"></div>
          <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg8 inputCol">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg4">
                <PrimaryButton
                  className="ms-Button ms-Button--primary"
                  text="Submit Request"
                  disabled={
                    this.props.UserRequestedRoles.filter(
                      x => x.isRequested === true
                    ).length > 0 && this.props.BusinessJustification
                      ? false
                      : true
                  }
                  onClick={() => this._onSubmitRequestClick()}
                ></PrimaryButton>
              </div>
              {this.renderApprovalReminder()}
            </div>
          </div>
        </div>
        {this.renderRemindersuccessDialog()}
      </div>
    );
  }

  renderApprovalReminder() {
    return this.props.RequestId > 0 ? (
      <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg8">
        <DefaultButton
          text="Send Reminder For Approval"
          onClick={() => this._onSendReminderClick()}
          disabled={this.props.MailReminderEligible ? false : true}
        />
      </div>
    ) : (
      ''
    );
  }
  private _onRoleChange(
    roleId: number,
    ev: React.FormEvent<HTMLElement>,
    isChecked: boolean
  ): void {
    this.props.updateRequestedRoles(roleId, isChecked);
  }

  private _onSubmitRequestClick() {
    this.props.submitRequest();
  }

  private _onSendReminderClick() {
    this.props.sendMailReminder();
  }

  renderRemindersuccessDialog() {
    return (
      <Dialog
        hidden={!this.props.ShowMailReminderDialog}
        onDismiss={this._closeDialog}
        dialogContentProps={{
          type: DialogType.normal,
          closeButtonAriaLabel: 'Close',
          subText:
            'Email reminder has been sent. You can send the next reminder after ' +
            this.props.MailReminderInHours +
            ' hours."',
        }}
        modalProps={{
          styles: { main: { maxWidth: 450 } },
        }}
      >
        <DialogFooter>
          <PrimaryButton onClick={this._closeDialog} text="Ok" />
        </DialogFooter>
      </Dialog>
    );
  }

  private _closeDialog = (): void => {
    this.props.setMailReminderDialogVisibility(false);
  };
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    getPendingAccessRequest: (userName: string) => {
      return dispatch(getPendingAccessRequest(userName));
    },
    updateRequestedRoles: (roleId: number, isChecked: boolean) => {
      return dispatch(updateRequestedRoles(roleId, isChecked));
    },
    updateJustification: (justification: string) => {
      return dispatch(updateJustification(justification));
    },
    submitRequest: () => {
      return dispatch(submitRequest());
    },
    sendMailReminder: () => {
      return dispatch(sendMailReminder());
    },
    setMailReminderDialogVisibility: (showDialog: boolean) => {
      return dispatch(setMailReminderDialogVisibility(showDialog));
    },
  };
};

const mapStateToProps = state => {
  return {
    RequestId: state.unAuthUser.RequestId,
    BusinessJustification: state.unAuthUser.BusinessJustification,
    UserAlias: state.unAuthUser.UserAlias,
    MailReminderEligible: state.unAuthUser.MailReminderEligible,
    MailReminderInHours: state.unAuthUser.MailReminderInHours,
    RolesChanged: state.unAuthUser.RolesChanged,
    UserRequestedRoles: state.unAuthUser.UserRequestedRoles,
    ShowRequestForm: state.unAuthUser.ShowRequestForm,
    IsRequestSubmitted: state.unAuthUser.IsRequestSubmitted,
    ShowMailReminderDialog: state.unAuthUser.ShowMailReminderDialog,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Unauthorized);
