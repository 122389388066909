import {
  Checkbox,
  DefaultPalette,
  DetailsList,
  Fabric,
  IButton,
  IColumn,
  IDetailsHeaderProps,
  IRenderFunction,
  mergeStyleSets,
  PrimaryButton,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Stack,
  Sticky,
  StickyPositionType,
  TextField,
  TooltipHost,
} from 'office-ui-fabric-react';
import React from 'react';
import { connect } from 'react-redux';
import {
  IJudgement,
  ISupplier,
  IPurchaseOrder,
  getDecimalAdjustedStringAmount,
} from '../../shared/models';
import { CardStyle } from '../../shared/styles/Card';
import { customDetailsListHeaderStyles } from '../../shared/styles/DetailsListHeaderStyles';
import {
  getJudgementsForSupplier,
  postJudgementsForSupplier,
  setReturnToSelectedSupplier,
} from '../../store/actions';
import { Redirect } from 'react-router';

export interface IAddJudgementsProps {
  currentSupplier: ISupplier;
  totalOtherJudgements: number;
  totalBudgetJudgement: number;
  isFetchingJudgements: boolean;
  selectedFiscalYear: number;
  handleBackButtonClick(): any;
  purchaseOrder: IPurchaseOrder[];
  existingJudgements: IJudgement[];
  loadJudgements(supplier: ISupplier, fiscalYear: number): any;
  handleJudgementSubmit(
    supplier: ISupplier,
    fiscalYear: number,
    judgementObj: IJudgement
  ): any;
}

export interface IAddJudgementsState {
  // This drives and stores whether the judgement added is a budget judgement or not.
  isBudgetJudgement: boolean;
  judgementErrorMessage: string;
  multiline: boolean;
  redirect: boolean;
  firstcardheight:string;
  secondcardheight:string;
  tableheight:string;
}

const backButtonRef = React.createRef<IButton>();

export class AddJudgements extends React.Component<
  IAddJudgementsProps,
  IAddJudgementsState
> {
  // Judgement input from user.
  private judgementUserInput: string = '';
  private commentsUserInput: string = '';

  // Columns for the detailList
  private columns: IColumn[];

  // Style for stack
  private stackStyles = mergeStyleSets({
    root: {
      selectors: {
        '> *': {
          alignItems: 'center',
          display: 'flex',

          justifyContent: 'center',
          color: DefaultPalette.black,
          fontWeight: '400',
        },
      },
    },
  });

  // Constructor Creation.
  public constructor(props) {
    super(props);

    this.columns = [
      {
        key: '1',
        name: 'Is Budget Judgement',
        fieldName: 'isBudgetJudgement',
        minWidth: 200,
        maxWidth: 270,
        isResizable: true,
        data: 'string',
        isPadded: true,
        onRender: this.renderIsBudgetJudgement,
      },
      {
        key: 'estimatedAmount',
        name: `Judgements(${this.props.currentSupplier.currencyCode})`,
        fieldName: 'amount',
        minWidth: 200,
        maxWidth: 270,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
      },
      {
        key: '3',
        name: 'Comments',
        fieldName: 'comments',
        minWidth: 90,
        maxWidth: 150,
        isResizable: true,
        data: 'string',
        isPadded: true,
      },
      {
        key: '4',
        name: 'Date',
        fieldName: 'date',
        minWidth: 90,
        maxWidth: 150,
        isResizable: true,
        data: 'string',
        isPadded: true,
      },
    ];
    // State Initialization
    this.state = {
      judgementErrorMessage: '',
      isBudgetJudgement: false,
      multiline: false,
      redirect: false,
      firstcardheight:'300px',
      secondcardheight:'44vh',
      tableheight:'20vh'
    };
  }
  public componentDidMount() {
    this.props.purchaseOrder.length > 0 || this.props.existingJudgements.length > 0
        ? document.title = 'GTO Portal - Add Adjustments'
        : document.title = 'GTO Portal - Add Judgements';
    this.resize();
        
    this.props.loadJudgements(
      this.props.currentSupplier,
      this.props.selectedFiscalYear
    );
    backButtonRef.current.focus();
  }
  resize = () =>{
    let windowSize = window.innerWidth;
    if(windowSize <= 640){
      this.setState({
        firstcardheight:'350px',
        secondcardheight:'100vh',
        tableheight:'40vh'
      })
    }
  }

  public render() {
    // console.log('Judgement Props', this.props);
    if (this.state.redirect === true) {
      // Calculating path
      const path: string = `/`;
      return <Redirect push={true} to={path} />;
    } else {
      return (
        <div
          style={{
            backgroundColor: 'rgb(232, 237, 241, 0.8)', // This is used for setting the background color.
            height: '95%',
            marginTop: 0,
            width: '98vw',
          }}
        >
          <div
            style={{
              height: '100%',
              width: '98vw', // 98 vw is the width of the page.
              marginTop: '10vh', // 5 vh Is margin top for this page.
              margin: '0 auto', // This is used to center content
            }}
          >
            <div style={{ display: 'flex', paddingTop: '10px' }}>
              {' '}
              <TooltipHost content="Back to Supplier Page">
                <PrimaryButton
                  data-automation-id="test"
                  role="link"
                  onClick={this.backButtonHandler}
                  ariaDescription="Link for going back to supplier home page."
                  iconProps={{ iconName: 'ChromeBack' }}
                  style={{
                    marginTop: 7,
                  }}
                  componentRef={backButtonRef}
                />
              </TooltipHost>
              <h1
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  paddingLeft: '10px',
                  fontSize: '1.17em',
                }} // Using this style to create more distance from top.
                aria-label={
                  this.props.purchaseOrder.length > 0 ||
                  this.props.existingJudgements.length > 0
                    ? 'Adjustments'
                    : 'Judgements'
                }
              >
                {this.props.purchaseOrder.length > 0 ||
                this.props.existingJudgements.length > 0
                  ? 'Adjustments'
                  : 'Judgements'}
              </h1>
            </div>
            <div style={{ margin: '10px', height: this.state.firstcardheight, ...CardStyle }}>
              <h2
                style={{ padding: '10px', fontSize: '18.72px' }}
                aria-label={
                  this.props.purchaseOrder.length > 0 ||
                  this.props.existingJudgements.length > 0
                    ? 'Add Adjustments'
                    : 'Add Judgements'
                }
              >
                {this.props.purchaseOrder.length > 0 ||
                this.props.existingJudgements.length > 0
                  ? 'Add Adjustments'
                  : 'Add Judgements'}
              </h2>
              <Stack verticalAlign="space-between" style={{marginLeft: '2vw'}}>


              <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row parent" > 
              <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg3">
              <Stack >
                      <div
                        aria-label="Supplier Name "
                        style={{
                          textAlign: 'center',
                          height: 'auto',
                          fontWeight: 700,
                          fontSize: '15px',
                          marginBottom: '2vh',
                        }}
                        id="supplier_name"
                      >
                        <span>Supplier Name</span>
                      </div>
                      <div
                        aria-label="PO Created Date"
                        style={{
                          textAlign: 'center',
                          height: 'auto',
                        }}
                      >
                        <span>
                          {this.props.currentSupplier.masterSupplierName}
                        </span>
                      </div>
                    </Stack>
                </div>
                <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg3">
                <Stack >
                      <div
                        aria-label="Supplier Number"
                        style={{
                          textAlign: 'center',
                          height: 'auto',
                          fontWeight: 700,
                          fontSize: '15px',
                          marginBottom: '2vh',
                        }}
                        id="supplier_number"
                      >
                        <span>Supplier Number</span>
                      </div>
                      <div
                        aria-label="Purchase Order Amount"
                        style={{
                          textAlign: 'center',
                          height: 'auto',
                        }}
                      >
                        <span>{this.props.currentSupplier.supplierNumber}</span>
                      </div>
                    </Stack>
        </div>
        <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg3">
        <Stack >
                      <div
                        aria-label="Company Code"
                        style={{
                          textAlign: 'center',
                          height: 'auto',
                          fontWeight: 700,
                          fontSize: '15px',
                          marginBottom: '2vh',
                        }}
                        id="company_code"
                      >
                        <span>Company Code</span>
                      </div>
                      <div
                        aria-label="Contract Number"
                        style={{
                          textAlign: 'center',
                          height: 'auto',
                        }}
                      >
                        <span>{this.props.currentSupplier.companyCode}</span>
                      </div>
                    </Stack>
          </div>
          <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg3">
          <Stack >
                      <div
                        aria-label="Fiscal Year"
                        style={{
                          textAlign: 'center',
                          height: 'auto',
                          fontWeight: 700,
                          fontSize: '15px',
                          marginBottom: '2vh',
                        }}
                        id="fiscal_year"
                      >
                        <span>Fiscal Year</span>
                      </div>
                      <div
                        aria-label="Contract Number"
                        style={{
                          textAlign: 'center',
                          height: 'auto',
                        }}
                      >
                        <span>{this.props.selectedFiscalYear}</span>
                      </div>
                    </Stack>
            </div>
                </div>
                
                </div>
                <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row parent"  style={{marginTop:'2vh'}}> 
              <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg3">
              <Stack >
                      <TooltipHost
                        id={'submitHover'}
                        content={
                          this.props.purchaseOrder.length > 0 ||
                          this.props.existingJudgements.length > 0
                            ? ''
                            : 'Total Budget Judgement is: ' +
                              this.props.totalBudgetJudgement
                        }
                      >
                        <div
                          aria-label="IsBudget Judgement"
                          style={{
                            textAlign: 'center',
                            height: 'auto',
                            fontWeight: 700,
                            fontSize: '15px',
                            marginBottom: '2vh',
                          }}
                        >
                          <span>Is Budget Judgement</span>
                        </div>
                      </TooltipHost>
                      <div
                        aria-label=""
                        style={{
                          textAlign: 'center',
                          height: 'auto',
                          marginLeft: '10vw'
                        }}
                      >
                        <span>
                          <Checkbox
                            checked={this.state.isBudgetJudgement}
                            onChange={this.handleCheckboxToggle}
                            disabled={this.props.existingJudgements.length > 0}
                            ariaLabel="Is Budget Judgement"
                          />
                        </span>
                      </div>
                    </Stack>
                </div>
                <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg3">
                <Stack >
                      <div
                        style={{
                          textAlign: 'center',
                          height: 'auto',
                          fontWeight: 700,
                          fontSize: '15px',
                          marginBottom: '2vh',
                        }}
                      >
                        <span>
                          {' '}
                          {this.props.purchaseOrder.length > 0 ||
                          this.props.existingJudgements.length > 0
                            ? ' Adjustment Amount'
                            : 'Judgement Amount'}
                          ({this.props.currentSupplier.currencyCode}){' '}
                        </span>
                      </div>
                      <div
                        aria-label="Enter Engagement Id"
                        style={{
                          textAlign: 'center',
                          height: 'auto',
                        }}
                      >
                        {/* <span> */}
                        <TextField
                          ariaLabel={
                            this.props.purchaseOrder.length > 0 ||
                            this.props.existingJudgements.length > 0
                              ? 'Enter Adjustment Amount '
                              : 'Enter Judgement Amount '
                          }
                          // aria-disabled="Judgements cannot be empty"
                          // errorMessage={this.state.aravoErrorMessage}
                          onChange={this.handleJudgementInputChange}
                          id={'judgementid'}
                          autoComplete="on"
                          // label="Judgement Amount"
                          placeholder={
                            this.props.purchaseOrder.length > 0 ||
                            this.props.existingJudgements.length > 0
                              ? 'Enter Adjustment Amount '
                              : 'Enter Judgement Amount '
                          }
                          required={true}
                          styles={{
                            root: { width: '18vw' },
                          }}
                        />
                        {/* </span> */}
                      </div>
                    </Stack>
          </div>
          <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg3">
          <Stack >
                      <div
                        style={{
                          textAlign: 'center',
                          height: 'auto',
                          fontWeight: 700,
                          fontSize: '15px',
                          marginBottom: '2vh',
                        }}
                      >
                        <span>Comments</span>
                      </div>
                      <div
                        aria-label="Enter Engagement Id"
                        style={{
                          textAlign: 'center',
                          height: 'auto',
                        }}
                      >
                        {/* <span> */}
                        <TextField
                          ariaLabel="Enter Comments"
                          // aria-disabled="Judgements cannot be empty"
                          // errorMessage={this.state.aravoErrorMessage}
                          onChange={this.handleCommentsChange}
                          id={'aravoid2'}
                          label=""
                          autoComplete="on"
                          placeholder="Enter Comments "
                          required={true}
                          styles={{
                            root: { width: '18vw' },
                          }}
                          multiline={this.state.multiline}
                        />
                        {/* </span> */}
                      </div>
                    </Stack>
                   
              </div>
              <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg3">
              <Stack >
                      <div
                        style={{
                          textAlign: 'center',
                          height: 'auto',
                          fontWeight: 700,
                          fontSize: '15px',
                          marginBottom: '2vh',
                        }}
                      >
                        <span>
                          {this.props.purchaseOrder.length > 0 ||
                          this.props.existingJudgements.length > 0
                            ? 'Total Adjustments'
                            : 'Total Judgements'}
                          ({this.props.currentSupplier.currencyCode})
                        </span>
                      </div>
                      <div
                        aria-label="Total Other Judgements"
                        style={{
                          textAlign: 'center',
                          height: 'auto',
                        }}
                      >
                        <span>
                          {getDecimalAdjustedStringAmount(
                            this.props.totalOtherJudgements
                          )}
                        </span>
                      </div>
                    </Stack>
              </div>
                </div>
                
                </div>
               
              </Stack>
              <div style={{ paddingLeft: '10px', paddingTop: '5vh' }}>
                <TooltipHost
                  id={'tooltip'}
                  content={this.validateJudgementInput()}
                >
                  <PrimaryButton
                    data-automation-id="test"
                    id={'submitHover'}
                    disabled={
                      this.validateJudgementInput() === null ? false : true
                    }
                    text="Save"
                    onClick={this.handleJudgementSubmit}
                    ariaLabel="Save Judgement/Adjustment"
                  />
                </TooltipHost>
              </div>
            </div>
            <div style={{ margin: '10px', height: this.state.secondcardheight, ...CardStyle }}>
              <h2
                style={{ paddingTop: '10px', paddingLeft: '10px', fontSize: '18.72px' }}
                aria-label={
                  this.props.purchaseOrder.length > 0 ||
                  this.props.existingJudgements.length > 0
                    ? 'Existing Adjustments'
                    : 'Existing Judgements'
                }
              >
                {' '}
                {this.props.purchaseOrder.length > 0 ||
                this.props.existingJudgements.length > 0
                  ? 'Existing Adjustments'
                  : 'Existing Judgements'}
              </h2>
              {this.getExistingJudgementsContent()}
            </div>
          </div>
        </div>
      );
    }
  }

  // For Handling the Back Button Events
  private backButtonHandler = () => {
    this.props.handleBackButtonClick();
    this.setState({ redirect: true });
  };
  private getExistingJudgementsContent() {
    if (!this.props.isFetchingJudgements) {
      return (
        <Fabric
          style={{
            width: '90vw',
            height: this.state.tableheight,
            overflow: 'auto',
            display: 'block',
            margin: '0 auto',
          }}
        >
          <DetailsList
            selectionMode={SelectionMode.multiple}
            className="searchResultsList"
            checkboxVisibility={2} // to hide the checkboxes
            // componentRef={this._root}
            items={this.getItems()} // {JudgementItems}
            columns={this.columns} // {this._columns}
            ariaLabelForGrid="Existing Judgements/Adjustments List"
            onRenderDetailsHeader={(
              detailsHeaderProps: IDetailsHeaderProps,
              defaultRender: IRenderFunction<IDetailsHeaderProps>
            ) => {
              // custom header styles
              return (
                <Sticky stickyPosition={StickyPositionType.Header}>
                  {defaultRender({
                    ...detailsHeaderProps,
                    styles: customDetailsListHeaderStyles,
                  })}
                </Sticky>
              );
            }}
            groupProps={{
              showEmptyGroups: true,
            }}
            // onRenderItemColumn={this._onRenderColumn}
          />
        </Fabric>
      );
    } else {
      return (
        <div aria-label={'Loading'} style={{ paddingTop: '5vh' }}>
          <Spinner
            style={{ height: '100%' }}
            label="Loading..."
            size={SpinnerSize.large}
          />
        </div>
      );
    }
  }
  private getItems() {
    let items: any[] = [];
    let i = 0;
    this.props.existingJudgements.forEach(x => {
      items.push({
        key: i++,
        comments: x.Comments,
        isBudgetJudgement: x.IsBudgetJudgement,
        amount: getDecimalAdjustedStringAmount(x.JudgementAmountLC),
        date: x.CreatedDate,
      });
    });
    return items;
  }

  private handleCheckboxToggle = () => {
    const newToggle = !this.state.isBudgetJudgement;
    this.setState({
      isBudgetJudgement: newToggle,
    });
  };

  private handleJudgementSubmit = () => {
    const judgement: IJudgement = {
      JudgementAmountLC: parseInt(this.judgementUserInput),
      IsBudgetJudgement: this.state.isBudgetJudgement,
      Comments: this.commentsUserInput,
      // CreatedDate:this.date,
      CreatedDate: '',
    };

    this.props.handleJudgementSubmit(
      this.props.currentSupplier,
      this.props.selectedFiscalYear,
      judgement
    );
  };
  // Textfield of Judgements change
  private handleJudgementInputChange = (e, newValue: string) => {
    // When the input changes update the user input state.
    this.judgementUserInput = newValue;
    const judgementErrorMessage = this.validateJudgementInput();

    this.setState({
      judgementErrorMessage:
        judgementErrorMessage === null ? '' : judgementErrorMessage,
    });
  };

  private handleCommentsChange = (e, newValue: string) => {
    const newMultiline = newValue.length > 30;
    if (newMultiline !== this.state.multiline) {
      this.setState({ multiline: newMultiline });
    }
    // When the input changes update the user input state.
    this.commentsUserInput = newValue;
    const judgementErrorMessage = this.validateJudgementInput();
    this.setState({
      judgementErrorMessage:
        judgementErrorMessage === null ? '' : judgementErrorMessage,
    });
  };

  // This function renders the prepaid checkbox.
  private renderIsBudgetJudgement = (item?: any): JSX.Element => {
    return (
      <Checkbox
        checked={item.isBudgetJudgement}
        disabled={true}
        ariaLabel="is Budget judgement"
      />
    );
  };
  // Validation for Judgement Input
  private validateJudgementInput = () => {
    const numeric = /^[0-9.+-]+$/;

    let judgementErrorMessage = null;
    if (this.judgementUserInput === null || this.judgementUserInput === '') {
      judgementErrorMessage = 'Judgement amount cannot be empty';
    } else if (!this.judgementUserInput.match(numeric)) {
      judgementErrorMessage = 'Judgement amount must be numeric';
    } else if (this.commentsUserInput === '') {
      judgementErrorMessage = 'Comment cannot be empty.';
    }

    return judgementErrorMessage;
  };
}

const mapDispatchToProps = dispatch => {
  return {
    loadJudgements: (supplier: ISupplier, fiscalYear: number) => {
      if (fiscalYear === null) {
        dispatch(getJudgementsForSupplier(supplier));
      } else {
        dispatch(getJudgementsForSupplier(supplier, fiscalYear.toString()));
      }
    },
    handleJudgementSubmit: (
      supplier: ISupplier,
      fiscalYear: number,
      judgementObj: IJudgement
    ) => {
      dispatch(postJudgementsForSupplier(supplier, fiscalYear, judgementObj));
    },
    handleBackButtonClick: () => {
      dispatch(setReturnToSelectedSupplier(true));
    },
  };
};

const mapStateToProps = state => {
  return {
    currentSupplier: state.selectedSupplier.basicInfo,
    selectedFiscalYear: state.selectedSupplier.selectedFiscalYear,
    existingJudgements: state.selectedSupplier.judgements,
    totalOtherJudgements: state.selectedSupplier.totalOtherJudgements,
    totalBudgetJudgement: state.selectedSupplier.totalBudgetJudgement,
    purchaseOrder: state.selectedSupplier.purchaseOrders,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddJudgements);

// export default AddJudgements;
