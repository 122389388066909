import { IHeadCountMonthList } from '../models';

export const MonthListMockData: IHeadCountMonthList[] = [
  {
    CalendarYear: 2019,
    CumMonth: 541,
    Month: 'Jul',
    Adjustments: 0,
    IsCurrentInvoiceMonth: false,
    PeriodDetail: '2020 Jul [P1]',
  },
  {
    CalendarYear: 2019,
    CumMonth: 542,
    Month: 'Aug',
    Adjustments: 4,
    IsCurrentInvoiceMonth: false,
    PeriodDetail: '2020 Aug [P2]',
  },
  {
    CalendarYear: 2019,
    CumMonth: 543,
    Month: 'Sep',
    Adjustments: 22,
    IsCurrentInvoiceMonth: false,
    PeriodDetail: '2020 Sep [P3]',
  },
  {
    CalendarYear: 2019,
    CumMonth: 544,
    Month: 'Oct',
    Adjustments: 1,
    IsCurrentInvoiceMonth: false,
    PeriodDetail: '2020 Oct [P4]',
  },
];
