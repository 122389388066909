import * as actionTypes from '../actions/actionTypes';

const initialState = {
  RequestId: 0,
  BusinessJustification: '',
  UserAlias: '',
  UserName: '',
  MailReminderEligible: false,
  MailReminderInHours: 0,
  SelectedRoles: [],
  RolesChanged: false,
  UserRequestedRoles: [],
  ShowRequestForm: false,
  PreviousSelectedRoles: [],
  IsRequestSubmitted: false,
  ShowMailReminderDialog: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ACCESS_REQUEST:
      return Object.assign({}, state, {
        RequestId: action.requestDetails.RequestId,
        BusinessJustification: action.requestDetails.BusinessJustification,
        UserAlias: action.requestDetails.UserAlias,
        UserName: action.requestDetails.UserName,
        MailReminderEligible: action.requestDetails.MailReminderEligible,
        MailReminderInHours: action.requestDetails.MailReminderInHours,
        RolesChanged: action.requestDetails.RolesChanged,
        UserRequestedRoles: action.requestDetails.UserRequestedRoles,
        ShowRequestForm: action.requestDetails.ShowRequestForm,
        PreviousSelectedRoles: action.requestDetails.PreviousSelectedRoles,
      });
    case actionTypes.SET_AVAILABLE_ROLES:
      return Object.assign({}, state, {
        UserRequestedRoles: state.UserRequestedRoles.map(role => {
          if (role.roleId === action.roleId) {
            return {
              ...role,
              isRequested: action.isChecked,
            };
          } else return role;
        }),
      });
    case actionTypes.SET_BUSINESSJUSTIFICATION:
      return Object.assign({}, state, {
        BusinessJustification: action.justification,
      });
    case actionTypes.SET_SUBMITREQUEST_STATUS:
      return Object.assign({}, state, {
        IsRequestSubmitted: action.submitStatus,
      });
    case actionTypes.SET_MAILREMINDER_ELIGIBILITY:
      return Object.assign({}, state, {
        MailReminderEligible: action.isEligible,
      });
    case actionTypes.SET_MAILREMINDER_DIALOG_VISIBILITY:
      return Object.assign({}, state, {
        ShowMailReminderDialog: action.showDialog,
      });

    default:
      return state;
  }
};

export default reducer;
