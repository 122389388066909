import * as actionTypes from '../actions/actionTypes';

// Initialiing the suppliers state to an empty array.
const initialState = {
  availableFiscalYears: [], // Avaialable fiscal years for the current supplier.
  selectedFiscalYear: null,
  returnToSelectedSupplier: false, // THis bool is used to return to the selected supplier.
  basicInfo: {
    supplierId: null,
    companyCode: '',
    masterSupplierName: '',
    supplierLegalName: '',
    supplierNumber: '',
    contactName: '',
    contactEmail: '',
    paymentTerms: '',
    currencyCode: '',
  }, // Supplier basic information of type ISupplier
  budget: null, // Budget data for the supplier of type IBudget.
  purchaseOrders: [], // PO Info of Type IPurchaseOrder
  contracts: [], // Contracts Information of Type IContract
  isFetchingPurchaseOrders: true, // This flag indicates when we are fetching the PO's
  isFetchingBudget: true, // used to render supplier budget loading.
  netPoAmount: 0, // This is a summation of all po amounts.
  isFetchingContracts: true, // This Flag indicates when we are fetching the Contracts
  aravoToContractMapping: [], // This array stores the map from engagement to contract mapping.
  isFetchingAravoEngagementToContractMapping: true,
  judgements: [],
  totalBudgetJudgement: 0,
  totalOtherJudgements: 0,
  isFetchingJudgements: true,
  supplierEngagements: [],
  selectedAravoId: '',
  selectedLineItemKey: '',
  singleLineItems: [],
  monthlyLineItems: [],
  lineItemMetadata: null,
  createdPO: 0,
  isPOCreated: false,
  poNotCreated: true,
  isSupplierFiltered: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_SUPPLIER: // Setting the selected supplier.
      return Object.assign({}, state, {
        basicInfo: action.supplier,
      });

    case actionTypes.SET_RETURN_TO_SELECTED_SUPPLIER:
      return Object.assign({}, state, {
        returnToSelectedSupplier: action.value,
      });

    case actionTypes.REQUEST_SUPPLIER_BUDGET:
      return Object.assign({}, state, { isFetchingBudget: true });

    case actionTypes.REQUEST_SUPPLIER_BUDGET_COMPLETE:
      return Object.assign({}, state, { isFetchingBudget: false });

    case actionTypes.SET_AVAILABLE_FISCAL_YEARS:
      return Object.assign({}, state, {
        availableFiscalYears: action.availableFiscalYears,
      });

    case actionTypes.SET_SELECTED_FISCAL_YEAR:
      return Object.assign({}, state, {
        selectedFiscalYear: action.year,
      });

    case actionTypes.SET_SUPPLIER_ENGAGEMENTS:
      return Object.assign({}, state, {
        supp: action.supplierEngagements,
      });

    case actionTypes.SET_SINGlE_LINE_ITEM:
      return Object.assign({}, state, {
        singleLineItems: action.lineItems,
      });

    case actionTypes.SET_PO_LINE_METADATA:
      return Object.assign({}, state, {
        lineItemMetadata: action.lineItemData,
      });

    case actionTypes.SET_MONTHLY_LINE_ITEMS:
      return Object.assign({}, state, {
        monthlyLineItems: action.lineItems,
      });

    case actionTypes.SET_SELECTED_ARAVO_ID:
      return Object.assign({}, state, {
        selectedAravoId: action.selectedAravoId,
      });

    case actionTypes.SET_SELECTED_LINE_ITEM_KEY:
      return Object.assign({}, state, {
        selectedLineItemKey: action.selectedLineItemKey,
      });

    case actionTypes.SET_SUPPLIER_BUDGET: // Setting the supplier budget.
      return Object.assign({}, state, {
        budget: action.budget,
      });

    case actionTypes.REQUEST_SUPPLIER_PURCHASE_ORDERS:
      return Object.assign({}, state, { isFetchingPurchaseOrders: true });

    case actionTypes.REQUEST_SUPPLIER_PURCHASE_ORDERS_COMPLETE:
      return Object.assign({}, state, { isFetchingPurchaseOrders: false });

    case actionTypes.SET_SUPPLIER_PURCHASE_ORDERS: // Setting the supplier PO's.
      return Object.assign({}, state, {
        purchaseOrders: action.purchaseOrders,
      });

    case actionTypes.REQUEST_SUPPLIER_JUDGEMENTS:
      return Object.assign({}, state, { isFetchingJudgements: true });

    case actionTypes.REQUEST_SUPPLIER_JUDGEMENTS_COMPLETE:
      return Object.assign({}, state, { isFetchingJudgements: false });

    case actionTypes.SET_SUPPLIER_JUDGEMENTS: // Setting the supplier PO's.
      return Object.assign({}, state, {
        judgements: action.judgements,
        totalBudgetJudgement: action.totalBudgetJudgement,
        totalOtherJudgements: action.totalOtherJudgements,
      });

    case actionTypes.SET_NETPOAMOUNT_FOR_SUPPLIER:
      return Object.assign({}, state, { netPoAmount: action.netPoAmount });

    case actionTypes.REQUEST_SUPPLIER_ARAVOTOCONTRACT_MAPPING:
      return Object.assign({}, state, {
        isFetchingAravoEngagementToContractMapping: true,
      });

    case actionTypes.REQUEST_SUPPLIER_ARAVOTOCONTRACT_MAPPING_COMPLETE:
      return Object.assign({}, state, {
        isFetchingAravoEngagementToContractMapping: false,
      });

    case actionTypes.SET_SUPPLIER_ARAVOTOCONTRACT_MAPPING:
      return Object.assign({}, state, {
        aravoToContractMapping: action.aravoToContractMapping,
      });

    case actionTypes.REQUEST_SUPPLIER_CONTRACTS:
      return Object.assign({}, state, { isFetchingContracts: true });

    case actionTypes.REQUEST_SUPPLIER_CONTRACTS_COMPLETE:
      return Object.assign({}, state, { isFetchingContracts: false });

    case actionTypes.SET_PURCHASE_ORDER_CREATED:
      return Object.assign({}, state, { createdPO: action.poNumber });

    case actionTypes.SET_SUPPLIER_CONTRACTS: // Setting the supplier Contracts.
      return Object.assign({}, state, {
        contracts: action.contracts,
      });

    case actionTypes.SET_IS_PO_CREATED: // Setting the supplier Contracts.
      return Object.assign({}, state, {
        isPOCreated: action.isPOCreated,
      });
    case actionTypes.SET_IS_PO_NOT_CREATED:
      return Object.assign({}, state, {
        poNotCreated: action.poNotCreated,
      });

    case actionTypes.SET_IS_SUPPLIER_FILTERED:
      return Object.assign({}, state, {
        isSupplierFiltered: action.isSupplierFiltered,
      });

    default:
      return state;
  }
};

export default reducer;
