import { MicrosoftSubmittedBillableUnits } from '../models/MicrosoftSubmittedBillableUnits';

export const MicrosoftSubmittedBillableUnitsMockData: MicrosoftSubmittedBillableUnits[] = [
  {
    SupplierEngagementId: 19429,
    CumMonth: 550,
    PostCumMonth: 550,
    IsNrc: false,
    MicrosoftUnits: 0,
    NRCId: 0,
  },
  {
    SupplierEngagementId: 19428,
    CumMonth: 550,
    PostCumMonth: 550,
    IsNrc: false,
    MicrosoftUnits: 0,
    NRCId: 0,
  },
  {
    SupplierEngagementId: 20033,
    CumMonth: 550,
    PostCumMonth: 550,
    IsNrc: false,
    MicrosoftUnits: 0,
    NRCId: 0,
  },
  {
    SupplierEngagementId: 20034,
    CumMonth: 550,
    PostCumMonth: 550,
    IsNrc: false,
    MicrosoftUnits: 0,
    NRCId: 0,
  },
];
