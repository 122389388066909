import { IContract, TypeOfContract } from '../models/index';

// Declaring a mock helper variable for generating mock contracts.
const existingContractsMockHelper = {
  randomDate(start: Date, end: Date): { value: number; dateFormatted: string } {
    const date: Date = new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    );
    return {
      dateFormatted: date.toLocaleDateString(),
      value: date.valueOf(),
    };
  },

  // Generating mock contracts with this function
  generateExisitngContracts() {
    const items: IContract[] = [];
    for (let i = 0; i < 5; i++) {
      // const randomContractID = _randomContractID()
      const randomContractID = 1234567 + i;
      const randomSupplierNumber = 1234 + i;
      const randomSupplierName = 1 + i;
      const randomCompanyCode = 123 + i;
      const randomDate = existingContractsMockHelper.randomDate(
        new Date(2012, 0, 1),
        new Date()
      );
      let typeOfContract;
      i < 15
        ? (typeOfContract = TypeOfContract.master)
        : (typeOfContract = TypeOfContract.shell);
      items.push({
        contractNumber: 110,
        aravoEngagementId: '567',
        contractID: randomContractID,
        supplierName: 'Supplier' + randomSupplierName,
        supplierNumber: randomSupplierNumber,
        expirationDate: randomDate.dateFormatted,
        expDateValue: randomDate.value,
        contractDescription: 'this is an example description',
        companyCode: randomCompanyCode,
        typeOfContract,
        IsSharedContract: false,
      });
    }
    return items;
  },
};
// exporting the contract mock object.
export const existingContractsMockData: IContract[] = existingContractsMockHelper.generateExisitngContracts();
