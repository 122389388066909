// Interface for tags. This is used by SupplierList and SupplierPicker components
export interface ITag {
  key: string; // This is a unique identifier
  name: string; // This is the tag value
  type: TagTypes; // This is the type of tag that was selected.
}

// Enum defining the different tag types.
// Todo: Validate if enum works correctly.
export enum TagTypes {
  SupplierName = 'SupplierName',
  CompanyCodes = 'CompanyCodes',
}
