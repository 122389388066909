import { IUser } from '../models/index';

export const userMockData: IUser =
{
    UserId: 2042,
    FriendlyName: null,
    Alias: "test",
    IsActive: true,
    IsNotification: true,
    IsInvoicingManager: true,
    IsFinanceOwner: true,
    IsBusinessAnalyst: true,
    IsInvoiceAnalyst: true,
    IsSupplierAccountManager: true,
    IsServiceDelivery: true,
    IsAdmin: false,
    IsOnlyReportUser: false,
    isSynthetic: false
}