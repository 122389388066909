import axios, { AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import { css } from 'glamor';
import appConfig from '../../assets/configuration/config';
import{AuthResponse} from 'msal'
import{getAuthProvider} from "../auth/msalConfig"

// client id of for gto invoice api.
const clientId = `${appConfig.GTOAPIClientId}`;

// Creating the gto axios instance. This can be used to make http calls.
export const gtoApiNewInstance = axios.create(
  {
  baseURL: `${appConfig.GTOvNextAPIBaseUrl}`,
}
);

// global interceptor for the request calls.
gtoApiNewInstance.interceptors.request.use(async(config: AxiosRequestConfig) => {
  let myStorage = window.localStorage;
    let synthetic = myStorage.getItem('synthetic');
  if (synthetic === null) {
    synthetic = 'false';
  }
  let boolVal = synthetic === 'true';
  config.headers['IS-SYNTHETIC'] = boolVal;

  /**Msal code to get access token to pass to api */
  var authResponse: AuthResponse = null;
        try {
            authResponse =  await getAuthProvider().acquireTokenSilent({
              'scopes':[`${clientId}/.default`]
            });
        }
        catch (error) {
            console.dir("Token Expired, redirecting to login", error);
            getAuthProvider().loginRedirect({});
        }
        config.headers['Authorization'] = 'Bearer '+authResponse.accessToken;

        return config;
});
// global interceptor for gto invoice api calls
gtoApiNewInstance.interceptors.response.use(null, (error) => {
  // Expected error list.
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  // handling unexpected errors globally.
  if (!expectedError) {
    toast.error('An unexpected error occurred', {
      className: css({
        background: '#a80000 !important',
      }),
    });
  }

  // return a promise.reject to ensure that the code execution returns to caller correctly.
  return Promise.reject(error);
});
// This method is used to fetch data from the gto invoice legacy api. It auto populates the gto invoice api client id.
export const gtoMsalApiFetch = async (fetch: any, url: string, options: any) =>

  await gtoApiNewInstance(url,options);

export default gtoApiNewInstance;
