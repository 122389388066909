import { ISupplierInvoieVariance } from '../models/SupplierInvoiceVariance';

export const SupplierInvoiceVariance: ISupplierInvoieVariance[] = [
  {
    SupplierId: 241,
    SupplierName: 'Tek Experts',
    SupplierNumber: 3003232,
    CompanyCode: '1010',
    InvoicePrefix: null,
    CumulativeMonth: 549,
    MonthName: 'Mar',
    Currency: '',
    AccrualTotalAmount: 9875607.88,
    AccrualGrossAmount: 9663614.54,
    AccrualNetAmount: 9663614.54,
    AccrualDiscount: 211993.35,
    AccrualTax: 0,
    ActualTotalAmount: 1.23,
    ActualGrossAmount: 9296508.34,
    ActualNetAmount: 9296508.34,
    ActualDiscount: 204730.78,
    ActualTax: 0,
    Grossvariance: -367106.2,
    GrossVariancePercent: -3.8,
    ExchangeRate: 0,
    AccrualTotalAmountCD: 0,
    AccrualGrossAmountCD: 0,
    AccrualNetAmountCD: 0,
    AccrualDiscountCD: 0,
    AccrualTaxCD: 0,
    ActualTotalAmountCD: 0,
    ActualGrossAmountCD: 0,
    ActualNetAmountCD: 0,
    ActualDiscountCD: 0,
    ActualTaxCD: 0,
    GrossvarianceCD: 0,
    GrossVariancePercentCD: 0,
  },
  {
    SupplierId: 250,
    SupplierName: 'Tek Experts',
    SupplierNumber: 3003232,
    CompanyCode: '1062',
    InvoicePrefix: null,
    CumulativeMonth: 549,
    MonthName: 'Mar',
    Currency: '',
    AccrualTotalAmount: 1989811.01,
    AccrualGrossAmount: 1950014.77,
    AccrualNetAmount: 1950014.77,
    AccrualDiscount: 39796.24,
    AccrualTax: 0,
    ActualTotalAmount: 1.45,
    ActualGrossAmount: 1988981.88,
    ActualNetAmount: 1988981.88,
    ActualDiscount: 40591.48,
    ActualTax: 0,
    Grossvariance: 38967.11,
    GrossVariancePercent: 2,
    ExchangeRate: 0,
    AccrualTotalAmountCD: 0,
    AccrualGrossAmountCD: 0,
    AccrualNetAmountCD: 0,
    AccrualDiscountCD: 0,
    AccrualTaxCD: 0,
    ActualTotalAmountCD: 0,
    ActualGrossAmountCD: 0,
    ActualNetAmountCD: 0,
    ActualDiscountCD: 0,
    ActualTaxCD: 0,
    GrossvarianceCD: 0,
    GrossVariancePercentCD: 0,
  },
];
