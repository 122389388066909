import { IInternalOrderExport, IInternalOrders } from "../models/IInternalOrders";

export const IInternalOrderMockData: IInternalOrders[] = [
  {
    CostCenter: 0,
    EngagementId: 14378,
    ExecPl: 'CS',
    FiscalYearNumber: 2022,
    HasErrors: null,
    InternalOrder: 9115062,
    IsBudgetActive: true,
    IsInvoiceActive: true,
    KeyValue: 'CS-All Learning Programs-Content and Social',
    LOB: 'Content and Social',
    NewCostCenter: 0,
    NewInternalOrder: 0,
    Product: 'All Learning Programs',
    Status: null,
    SupplierEngagementId: 21516,
    IsBudgetActiveString: "Active",
    IsInvoiceActiveString:"Active"
  },
  {
    CostCenter: 10024018,
    EngagementId: 2657,
    ExecPl: "CELA",
    FiscalYearNumber: 2022,
    HasErrors: null,
    InternalOrder: 0,
    IsBudgetActive: true,
    IsInvoiceActive: true,
    KeyValue: "CELA-All Products-Consumer",
    LOB: "Consumer",
    NewCostCenter: 0,
    NewInternalOrder: 0,
    Product: "All Products",
    Status: null,
    SupplierEngagementId: 5575,
    IsBudgetActiveString: "Active",
    IsInvoiceActiveString:"Active"
  },
];

export const IInternalOrderApproveMockData: IInternalOrderExport[] = [
  {
    ImportStatus: "Valid",
    OldCostCenter: 0,
    EngagementId: 12451,
    FiscalYearNumber: 2022,
    OldInternalOrder: 1706324,
    KeyValue: 'C&E-ASMS-Accounts & Billing',
    NewCostCenter: 0,
    NewInternalOrder: 0,
    SupplierEngagementId: 18943,
  },
];