import * as React from 'react';
import { Dropdown, IDropdownOption } from '@fluentui/react';
import { Stack } from '@fluentui/react/lib/Stack';
import { Toggle } from '@fluentui/react/lib/Toggle';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';

import appConfig from '../../assets/configuration/config';
import { ScrollablePane } from 'office-ui-fabric-react';
import {
  TelemetryEvent,
  Routes,
  TelemetryException,
} from '../../shared/models';
import gtoNewApiInstance, {
  gtoMsalApiFetch,
} from '../../shared/http/GtoApiNew.axios';
import telemetryContext from '../../shared/services/TelemetryServices';
import { Panel, PanelType } from 'office-ui-fabric-react';
import { TextField } from '@fluentui/react/lib/TextField';
import { v4 as uuidv4 } from 'uuid';
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  IColumn,
  SelectionMode,
} from '@fluentui/react/lib/DetailsList';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { IInvoiceManagementCategory } from '../../shared/models/InvoiceManagement';
import { InvoiceManagementMockData } from '../../shared/mock/InvoiceMangementMockData';

import { toast } from 'react-toastify';
import { css } from 'glamor';
import { delay } from 'q';
import { connect } from 'react-redux';
import { getUserRoles } from '../../store/actions/user.action';
import HelmetTab from "../../shared/Helmet/Helmet";

export interface IInvoiceMangementProps {
  getUserRoles: any;
  IsInvoiceAnalyst: boolean;
  IsInvoicingManager: boolean;
}

export interface IInvoiceMangementState {
  onclickadd: boolean;
  onclickedit: boolean;
  disabledit: boolean;
  invoiceManagementOptions: any;
  invoicePrefixListActive: any;
  showAddPanel: boolean;
  invoiceSupplierFilterOptions: IDropdownOption[];
  supplierMasterOptions: any;
  archiveToggle: boolean;
  originalItems: IInvoiceManagementCategory[];
  items: IInvoiceManagementCategory[];
  itemSelected: IInvoiceManagementCategory;
  filterSupplierName: string;
  filterInvoicePrefix: string;
  oneditInvoiceOwnerName: string;
  invoiceOwnerName: string;
  oneditInvoicePrefix: string;
  invoicePrefix: string;
  oneditSupplierName: string;
  supplierName: string;
  supplierId:any;
  captureInvoicePrefix:string;
  captureSupplierName:string;
}

class InvoiceMangement extends React.Component<
  IInvoiceMangementProps,
  IInvoiceMangementState
> {
  private _columns: IColumn[];
  private _selection: Selection;

  private indexSelected: number = -1;

  constructor(props) {
    super(props);
    this.props.getUserRoles();

    this._selection = new Selection({
      onSelectionChanged: () => {
        this._getSelectionDetails();
      },
    });
    this.state = {
      onclickadd: false,
      onclickedit: false,
      disabledit: false,
      invoiceManagementOptions: [],
      items: [],
      originalItems: [],
      filterSupplierName: null,
      filterInvoicePrefix: null,
      showAddPanel: false,
      supplierMasterOptions: [],
      invoicePrefixListActive: [],
      archiveToggle: false,
      itemSelected: null,
      oneditInvoiceOwnerName: null,
      invoiceOwnerName: null,
      oneditInvoicePrefix: null,
      invoicePrefix: null,
      oneditSupplierName: null,
      supplierName: null,
      supplierId:null,
      captureInvoicePrefix:'',
      captureSupplierName:'',

      invoiceSupplierFilterOptions: [
        {
          key: 'Alorica',
          text: 'Alorica',
        },
        {
          key: 'Arvato',
          text: 'Arvato',
        },
        {
          key: 'Concentrix',
          text: 'Concentrix',
        },
        {
          key: 'Language Line Services',
          text: 'Language Line Services',
        },
      ],
    };

    this._columns = [
      {
        key: 'column1',
        name: 'Invoice Prefix',
        fieldName: 'invoicePrefix',
        minWidth: 200,
        maxWidth: 300,
        isResizable: true,
      },
      {
        key: 'column2',
        name: 'Supplier Name',
        fieldName: 'supplierName',
        minWidth: 200,
        maxWidth: 300,
        isResizable: true,
      },
      {
        key: 'column3',
        name: 'Active',
        fieldName: 'isActive',
        minWidth: 200,
        maxWidth: 300,
        isResizable: true,
      },
      {
        key: 'column4',
        name: 'Action',
        fieldName: 'action',
        minWidth: 200,
        maxWidth: 300,
        isResizable: true,
        onRender: this.renderInvoiceManagementArchive,
      },
    ];
  }

  private _getSelectionDetails(): string {
    const selectionCount = this._selection.getSelectedCount();
    var selectIndex = this._selection;

    switch (selectionCount) {
      case 0:
        this.setState({
          disabledit: true,
          itemSelected: null,
        });
        return;
      case 1:
        this.indexSelected = this._selection['_anchoredIndex'];
        this.setState({
          disabledit: false,
          itemSelected: this.state.items[this.indexSelected],
        });
        return;
      default:
        this.setState({
          disabledit: true,
        });
    }
  }

  public componentDidMount() {
    this.getInvoicePrefixlistFn();
  }

  getInvoicePrefixlistFn = () => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };

      // Defining the url
      let url: string = Routes.getAllInvoicePrefix;

      // Logging telemetry for Invoice Prefix api call.
      telemetryContext.logEvent(TelemetryEvent.FetchInvoicePrefixList, {
        url,
        headers,
      });

      // Making the get call.
      return gtoMsalApiFetch(gtoNewApiInstance.get, url, {
        headers,
      })
        .then((response: any) => {
          this.setState({
            originalItems: response.data,
            items: response.data.filter(
              (x) => x.isActive === true
            ),
          });
        })
        .catch((error) => {
          // Log Exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchInvoicePrefixListFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );
          // Error handling.
          if (error.response && error.response.status === 404) {
            toast.error('Unable to fetch Invoice Prefix list.', {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }

          throw error;
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {
        this.setState({
          items: InvoiceManagementMockData,
        });
      });
    }
  };

  archiveUnarchiveInvoicePrefix = (flag, value) => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      var rolesformat = [];
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };

      // Defining the url
      let url: string =
        Routes.archiveInvoicePrefix +
        `?invoiceId=${this.state.itemSelected.invoiceId}&isArchive=${flag}`;

      telemetryContext.logEvent(TelemetryEvent.ArchiveInvoicePrefix, {
        url,
        headers,
      });

      // Making the get call.
      return gtoMsalApiFetch(gtoNewApiInstance.get, url, {
        headers,
        method: 'PUT',
      })
        .then((response: any) => {
          this._selection.setAllSelected(false);
          this.getInvoicePrefixlistFn();

          // Mapping the response data.
          toast.success(response.data, {
            className: css({
              background: '#107c10 !important',
            }),
          });
        })
        .catch((error) => {
          // Log Exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.ArchiveInvoicePrefixFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );
          // Error handling.
          if (error.response && error.response.status === 404) {
            toast.error('Unable to archive/un archive', {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }

          throw error;
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {
        this.setState({
          items: InvoiceManagementMockData,
        });
      });
    }
  };

  loadSupplierMasterList = () => {
    if (appConfig.UseMockData === 'false') {
      // Creating headers
      const headers: any = {
        SubCorrelationKey: uuidv4(),
        'X-CorrelationId': uuidv4(),
      };
      // Defining the url
      const url: string = `${Routes.getSupplierMasterData}`;
      // Logging telemetry
      telemetryContext.logEvent(TelemetryEvent.FetchSupplierMasterList, {
        headers,
        url,
      });
      gtoMsalApiFetch(gtoNewApiInstance.get, url, { headers })
        .then((response: any) => {
          if (response.data && response.status === 200) {
            let masterData = response.data
              .filter((x) => x.isActive)
              .map((x) => ({
                key: String(x.supplierMasterId),
                text: x.supplierName,
                isActive: x.isActive,
              }));
            // Logging success
            this.setState({
              supplierMasterOptions: masterData,
            });
            // Logging success

            telemetryContext.logEvent(TelemetryEvent.FetchSupplierMasterList, {
              headers,
              url,
            });
          }
        })
        .catch((error) => {
          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchSupplierMasterListFailure,
            undefined,
            {
              headers,
              url,
            }
          );

          if (error.response && error.response.status === 404) {
            toast.error('Supplier Master  information is not available ', {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }
        });
    }
  };

  ondismissAdd = () => {
    this.setState({
      showAddPanel: false,
    });
  };
  onclickAddbutton = () => {
    this.setState({
      showAddPanel: true,
    });
  };

  private renderInvoiceManagementArchive = (item: any): JSX.Element => {
    if (item.isActive === true) {
      return (
        <TooltipHost
          content={'Archive'}
          id={'Archive'}
          calloutProps={{ gapSpace: 0 }}
        >
          <IconButton
            style={{ margin: '5px' }}
            data-automation-id="archive"
            name="archive"
            aria-label="archive"
            iconProps={{ iconName: 'Archive' }}
            onClick={() => this.archiveUnarchiveInvoicePrefix(item.isActive, 0)}
            disabled={
              this.props.IsInvoiceAnalyst || this.props.IsInvoicingManager
                ? false
                : true
            }
          />
        </TooltipHost>
      );
    } else if (item.isActive === false) {
      return (
        <TooltipHost
          content={'Unarchive'}
          id={'Unarchive'}
          calloutProps={{ gapSpace: 0 }}
        >
          <IconButton
            style={{ margin: '5px' }}
            data-automation-id="Unarchive"
            name="Unarchive"
            aria-label="Unarchive"
            iconProps={{ iconName: 'Archive' }}
            onClick={() => this.archiveUnarchiveInvoicePrefix(item.isActive, 1)}
            disabled={
              this.props.IsInvoiceAnalyst || this.props.IsInvoicingManager
                ? false
                : true
            }
          />
        </TooltipHost>
      );
    }
  };

  addInvoicePrefix = (value) => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers
      const headers: any = {
        SubCorrelationKey: uuidv4(),
        'X-CorrelationId': uuidv4(),
      };
      let url: string = `${Routes.addInvoicePrefix}`;
      // Logging telemetry
      telemetryContext.logEvent(TelemetryEvent.AddInvoicePrefix, {
        headers,
        url,
      });
      var body = {};
      if (value === 'add') {
        if (this.state.supplierName === '' || this.state.invoicePrefix === '') {
          return;
        }
        body = {
          supplierName: this.state.supplierName,
          invoicePrefix: this.state.invoicePrefix,
          supplierMasterId: this.state.supplierId,
          isActive: true,
        };
      } else if (value === 'edit') {
        if (
          this.state.oneditSupplierName === '' ||
          this.state.oneditInvoicePrefix === ''
        ) {
          return;
        }

        body = {
          invoiceId: this.state.itemSelected.invoiceId,
          supplierMasterId: this.state.oneditSupplierName,
          invoicePrefix: this.state.oneditInvoicePrefix,
          isActive: true,
        };
      }

      return gtoMsalApiFetch(gtoNewApiInstance, url, {
        data: body,
        headers,
        method: 'post',
      })
        .then((response: any) => {
          this.setState({
            showAddPanel: false,
            archiveToggle:false
          });
          this._selection.setAllSelected(false);
          this.getInvoicePrefixlistFn();

          // Logging success
          telemetryContext.logEvent(TelemetryEvent.AddInvoicePrefix, {
            headers,
            url,
          });

          toast.success(response.data, {
            className: css({
              background: '#107c10 !important',
            }),
          });
          this.getInvoicePrefixlistFn();
        })
        .catch((error) => {
          this.setState({
            showAddPanel: false,
          });
          //Logging Exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.AddInvoicePrefixFailure,
            undefined,
            {
              headers,
              url,
            }
          );
        });
    } else {
      return delay(2000).then(() => {
        toast.success('NRC Line Item Created successfully', {
          className: css({
            background: '#107c10 !important',
          }),
        });
      });
    }
  };

  onclickeditpermissions = (value) => {
    this.loadSupplierMasterList();
    if (value === 'add') {
      this.setState({
        onclickadd: true,
        onclickedit: false,
      });
    } else if (value === 'edit') {
      this.setState({
        onclickadd: false,
        onclickedit: true,

        oneditInvoicePrefix: this.state.itemSelected.invoicePrefix,
        oneditSupplierName: String(this.state.itemSelected.supplierMasterId),
      });
    }
    this.setState({
      showAddPanel: true,
    });
  };

  onChangeArchiveToggle = (e) => {
    this._selection.setAllSelected(false);
    this.setState({
      captureInvoicePrefix:'',
      captureSupplierName:'',
      filterInvoicePrefix:'',
      filterSupplierName:''
    })
    if (this.state.originalItems.length > 0) {
      if (!this.state.archiveToggle) {
        this.setState({
          archiveToggle: !this.state.archiveToggle,
          items: this.state.originalItems.filter((x) => x.isActive === false),
        });
      } else {
        this.setState({
          archiveToggle: !this.state.archiveToggle,
          items: this.state.originalItems.filter((x) => x.isActive),
        });
      }
    }
  };

  private onchangeInvoicePrefix = (e, newValue) => {
    this.setState({
      invoicePrefix: newValue,
      oneditInvoicePrefix: newValue,
    });
  };
 
  private onchangeSupplierName = (o: IDropdownOption) => {

    this.setState({
      supplierName: o.text,
      oneditSupplierName: String(o.key),
      supplierId :o.key
    });
  };

  private filterwithSupplierName = (e, newValue) => {
    this.setState({
      captureSupplierName:newValue
    })
    this._selection.setAllSelected(false);
    let items = [...this.state.originalItems];

    if (newValue.length > 0 ) {
      items = this.state.originalItems.filter((x) =>
        x['supplierName'].toLowerCase().includes(newValue.toLowerCase())
      ).filter((x) =>
      x['isActive'] === this.state.archiveToggle ? false : true);
    }else if (newValue.length < 1){
        items = this.state.originalItems.filter((x) =>
        x['isActive'] === this.state.archiveToggle ? false : true);
    }
    if (
      this.state.filterInvoicePrefix &&
      this.state.filterInvoicePrefix.length > 0
    ) {
      items = items.filter((x) =>
        x['invoicePrefix']
          .toLowerCase()
          .includes(this.state.filterInvoicePrefix.toLowerCase()) 
      ).filter((x) =>
      x['isActive'] === this.state.archiveToggle ? false : true);
    }
    this.setState({
      items: items,
      filterSupplierName: newValue.length > 0 ? newValue : null,
    });
  };

  private filterwithInvoicePrefix = (e, newValue) => {
    this.setState({
      captureInvoicePrefix:newValue
    })
    this._selection.setAllSelected(false);
    let items = [...this.state.originalItems];

    if (newValue.length > 0) {
      items = this.state.originalItems.filter((x) =>
        x['invoicePrefix'].toLowerCase().includes(newValue.toLowerCase())
      ).filter((x) =>
      x['isActive'] === this.state.archiveToggle ? false : true);
    }  else if (newValue.length < 1){
      items = this.state.originalItems.filter((x) =>
      x['isActive'] === this.state.archiveToggle ? false : true);
  }

  if (
    this.state.filterSupplierName &&
    this.state.filterSupplierName.length > 0
  ) {
    items = items.filter((x) =>
      x['supplierName']
        .toLowerCase()
        .includes(this.state.filterSupplierName.toLowerCase())
    ).filter((x) =>
    x['isActive'] === this.state.archiveToggle ? false : true);
  }
    this.setState({
      items: items,
      filterInvoicePrefix: newValue.length > 0 ? newValue : null,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div style={{ height: '100%' }}>
        <HelmetTab title={'Invoice Management'} />
          <h1
            style={{ padding: '15px', fontSize: '18.72px' }}
            aria-label={'Invoice Management'}
          >
            Invoice Management
          </h1>

          <Stack>
            <div className="ms-Grid" dir="ltr" style={{ paddingLeft: '20px' }}>
              <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2">
                  <TextField
                    placeholder="Invoice Prefix"
                    label="Invoice Prefix"
                    ariaLabel="Invoice Prefix"
                    onChange={this.filterwithInvoicePrefix}
                    value={this.state.captureInvoicePrefix}
                  />
                </div>
                <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2">
                  <TextField
                    placeholder="Supplier Name"
                    label="Supplier Name"
                    ariaLabel="Supplier Name"
                    onChange={this.filterwithSupplierName}
                    value={this.state.captureSupplierName}
                  />
                </div>
                <div
                  className="ms-Grid-col ms-sm6 ms-md4 ms-lg2"
                  style={{ marginTop: '3vh' }}
                >
                  <Toggle
                    label={<div>Archived </div>}
                    inlineLabel
                    onAriaLabel="On"
                    offAriaLabel="Off"
                    defaultChecked={this.state.archiveToggle}
                    onChange={(e) => this.onChangeArchiveToggle(e)}
                    role="switch"
                  />
                </div>
              </div>
            </div>
            <div className="ms-Grid " dir="ltr">
              <div className="ms-Grid-row " style={{ margin: '20px' }}>
                <div style={{ float: 'left' }}>
                  <PrimaryButton
                    text="Add"
                    iconProps={{ iconName: 'Add' }}
                    style={{ marginRight: '10px' }}
                    onClick={() => this.onclickeditpermissions('add')}
                    disabled={
                      this.props.IsInvoiceAnalyst ||
                      this.props.IsInvoicingManager
                        ? false
                        : true
                    }
                  />

                  <PrimaryButton
                    text="Edit"
                    iconProps={{ iconName: 'EditNote' }}
                    style={{ marginRight: '2px' }}
                    onClick={() => this.onclickeditpermissions('edit')}
                    disabled={
                      (this.props.IsInvoiceAnalyst ||
                        this.props.IsInvoicingManager) &&
                      this._selection.getSelectedCount() > 0
                        ? false
                        : true
                    }
                  />
                </div>
              </div>
            </div>
          <div>
            <div
              style={{
                height: '68vh',
                position: 'relative',
                marginLeft: '1vw',
              }}
            >
              <ScrollablePane>
                <DetailsList
                  compact={true}
                  items={this.state.items}
                  columns={this._columns}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  selection={this._selection}
                  selectionPreservedOnEmptyClick={true}
                  selectionMode={SelectionMode.single}
                  ariaLabelForSelectionColumn="Toggle selection"
                  checkButtonAriaLabel="select row"
                />
              </ScrollablePane>
            </div>
          </div>

          <Panel
            isOpen={this.state.showAddPanel}
            onDismiss={this.ondismissAdd}
            type={PanelType.medium}
            closeButtonAriaLabel="Close"
            headerText={
              this.state.onclickadd
                ? 'New Invoice Prefix'
                : 'Edit Invoice Prefix'
            }
          >
            {this.state.onclickadd && (
              <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                  <div className="ms-Grid-row" style={{ margin: '10px' }}>
                    <TextField
                      label="Invoice Prefix"
                      placeholder="Invoice Prefix"
                      ariaLabel="Invoice Prefix"
                      required
                      onChange={this.onchangeInvoicePrefix}
                    />
                  </div>

                  <div className="ms-Grid-row" style={{ margin: '10px' }}>
                    <Dropdown
                      label="Supplier"
                      placeholder="Supplier"
                      ariaLabel="Supplier"
                      required
                      options={this.state.supplierMasterOptions}
                      onChange={(e, o) => this.onchangeSupplierName(o)}
                    />
                  </div>

                  <div className="addNrcButtonGroup" style={{ margin: '10px' }}>
                    <PrimaryButton
                      data-automation-id="submitBtn"
                      role="button"
                      text="Save"
                      ariaLabel="Save Button"
                      disabled={
                        (this.state.invoicePrefix !== null && this.state.supplierName!== null) ?false :true
                      }
                      onClick={() => this.addInvoicePrefix('add')}
                      style={{ float: 'right', margin: '10px 0px' }}
                    />
                    <DefaultButton
                      data-automation-id="cancelBtn"
                      text="Cancel"
                      ariaLabel="Cancel Button"
                      onClick={() => {
                        this.ondismissAdd();
                      }}
                      style={{ margin: '10px' }}
                    />
                  </div>
                </div>
              </div>
            )}

            {this.state.onclickedit && (
              <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                  <div className="ms-Grid-row" style={{ margin: '10px' }}>
                    <TextField
                      label="Invoice Prefix"
                      placeholder="Invoice Prefix"
                      ariaLabel="Invoice Prefix"
                      required
                      value={this.state.oneditInvoicePrefix}
                      onChange={this.onchangeInvoicePrefix}
                    />
                  </div>

                  <div className="ms-Grid-row" style={{ margin: '10px' }}>
                    <Dropdown
                      label="Supplier"
                      placeholder="Supplier"
                      ariaLabel="Supplier"
                      disabled={this.state.onclickedit}
                      options={this.state.supplierMasterOptions}
                      onChange={(e, o) => this.onchangeSupplierName(o)}
                      selectedKey={this.state.oneditSupplierName}
                    />
                  </div>

                  <div className="addNrcButtonGroup" style={{ margin: '10px' }}>
                    <PrimaryButton
                      data-automation-id="submitBtn"
                      role="button"
                      text="Save"
                      ariaLabel="save Button"
                      disabled={
                        this.state.oneditInvoicePrefix === '' ||
                        this.state.oneditSupplierName === ''
                          ? true
                          : false
                      }
                      onClick={() => this.addInvoicePrefix('edit')}
                      style={{ float: 'right', margin: '10px 0px' }}
                    />
                    <DefaultButton
                      data-automation-id="cancelBtn"
                      text="Cancel"
                      ariaLabel="cancel Button"
                      onClick={() => {
                        this.ondismissAdd();
                      }}
                      style={{ margin: '10px' }}
                    />
                  </div>
                </div>
              </div>
            )}
          </Panel>
          </Stack>
        </div>
        
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    getUserRoles: () => {
      dispatch(getUserRoles());
    },
  };
};
const mapStateToProps = (state) => {
  return {
    IsInvoiceAnalyst: state.user.IsInvoiceAnalyst,
    IsInvoicingManager: state.user.IsInvoicingManager,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceMangement);
