import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import * as React from 'react';
import { toast } from 'react-toastify';
import gtoApiInstance, {
  gtoMsalApiFetch,
} from '../../../../shared/http/GtoApiNew.axios';
import {
  Routes,
  TelemetryEvent,
  TelemetryException,
} from '../../../../shared/models';
import { IContract } from '../../../../shared/models/Contract.model';
import telemetryContext from '../../../../shared/services/TelemetryServices';
import './SearchContractBox.css';
import { SearchSuggestions } from './SearchSuggestions';
import { css } from 'glamor';
import { v4 as uuidv4 } from 'uuid';
import appConfig from '../../../../assets/configuration/config';

interface ISearchContractBoxState {
  filteredContracts: IContract[];
  showSearchSuggestions: boolean;
}

interface ISearchContractBoxProps {
  handleContractChange: any;
  clearPressed: any;
}
export class SearchContractBox extends React.Component<
  ISearchContractBoxProps,
  ISearchContractBoxState
> {
  // Init a timeout variable to be used below
  public timeout: any = null;

  constructor(props) {
    super(props);
    this.state = {
      showSearchSuggestions: false,
      filteredContracts: [],
    };
  }

  public render(): JSX.Element {
    return (
      <div className="ms-SearchBoxExample">
        <SearchBox
          placeholder="Search by Contract ID or Deal Owner"
          onSearch={(newValue) => console.log('value is ' + newValue)}
          onChange={(_, newValue) => this.textChanged(newValue)}
          onClear={() => this.clearPressed()}
        />
        <div className="searchSuggestions">{this.getSearchSuggestions()}</div>
      </div>
    );
  }

  private randomDate(
    start: Date,
    end: Date
  ): { value: number; dateFormatted: string } {
    const date: Date = new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    );
    return {
      value: date.valueOf(),
      dateFormatted: date.toLocaleDateString(),
    };
  }

  // To clear the SearchBox values
  private clearPressed() {
    this.setState({ showSearchSuggestions: false });
    this.props.clearPressed();
  }

  // API Call for Search the contracts entered in the search box
  private textChanged(newValue) {
    // Clear the timeout if it has already been set.
    // This will prevent the previous task from executing
    // if it has been less than <MILLISECONDS>
    clearTimeout(this.timeout);

    // Make a new timeout set to go off in 500ms
    let _this = this;
    this.timeout = setTimeout(function () {
      // If the string length is greater than or equal to 3
      if (newValue.length >= 3) {
        if (appConfig.UseMockData === 'true') {
          _this.generateMockData();
        } else {
          // make api call here to get new search suggestions
          // Defining the url
          const url: string = `${Routes.FetchContractSearchSuggestions}?searchValue=${newValue}`;

          // Logging telemetry
          _this.logFetchContractSearchSuggestionsBegin(url, newValue);

          let contractsList: IContract[] = [];
          // fetch search suggestions from api based on entered query
          try {
            _this.callSearchAPI(url, newValue).then(function (result) {
              // map to "this.filteredContracts", making each returned object an
              // IContract object
              for (let i = 0; i < result.data.length; i++) {
                // initialize the contract object
                let contract: IContract = {
                  contractNumber: 0,
                  aravoEngagementId: '',
                  contractID: 0,
                  supplierName: '',
                  supplierNumber: 0,
                  contractDescription: '',
                  companyCode: 0,
                  typeOfContract: 0,
                  expirationDate: '',
                  expDateValue: 0,
                  IsSharedContract: false,
                };
                // actually set the values
                contract.contractID = result.data[i].contractId;
                contract.supplierName = result.data[i].supplierName;
                contract.contractDescription =
                  result.data[i].contractDescription;
                contract.companyCode = result.data[i].companyCode;
                contract.typeOfContract = result.data[i].typeOfContract;
                contract.supplierNumber = result.data[i].supplierNumber;

                // setting the expiration date
                let expDate = result.data[i].expirationDate;

                // '0001-01-01T00:00:00' is the min value returned by API
                // - meaning that the value was null in the DB
                if (expDate !== '0001-01-01T00:00:00') {
                  let dateObj = new Date(result.data[i].expirationDate);
                  contract.expirationDate = dateObj.toLocaleDateString();
                  contract.expDateValue = dateObj.valueOf();
                }
                contractsList.push(contract);
              }

              _this.setState({
                filteredContracts: contractsList,
                showSearchSuggestions: true,
              });
            });
            // Logging success
            _this.logFetchContractSearchSuggestionsSuccess(url);
          } catch (ex) {
            // log the exception
            _this.logFetchContractSearchSuggestionsFailure(url, ex, newValue);

            if (ex.response && ex.response.status === 404) {
              toast.error('Error when searching for a contract', {
                className: css({
                  background: '#a80000 !important',
                }),
              });
            }
          }
        }
      } else {
        _this.setState({ showSearchSuggestions: false });
      }
    }, 500);
  }

  // call the Search API
  private async callSearchAPI(url, searchValue) {
    const headers: object = {
      'X-CorrelationId': uuidv4(),
      SubCorrelationKey: uuidv4(),
    };
    return await gtoMsalApiFetch(gtoApiInstance, url, {
      searchValue,
      headers,
      method: 'post',
    });
  }

  // Return Search Suggesstions based on the API call which will happen when user search based on the criteria and Search Suggestions component will be called
  private getSearchSuggestions() {
    if (this.state.showSearchSuggestions) {
      return (
        <SearchSuggestions
          handleSuggestionClick={(contract) => {
            this.setState({ showSearchSuggestions: false });

            this.props.handleContractChange(contract);
          }}
          suggestionItems={
            this.state.filteredContracts.length > 5
              ? this.showFiveSuggestions()
              : this.state.filteredContracts
          }
        />
      );
    }
  }

  // Limiting the Search results based on the search or right now showing all the possible suggestions
  private showFiveSuggestions() {
    let temp = [];
    for (let i = 0; i < this.state.filteredContracts.length; i++) {
      temp.push(this.state.filteredContracts[i]);
    }
    return temp;
  }

  private generateMockData() {
    let _this = this;
    _this.setState({
      filteredContracts: [
        {
          contractNumber: 0,
          aravoEngagementId: '',
          contractID: 123,
          supplierName: 'Supplier1',
          supplierNumber: 1,
          expirationDate: _this.randomDate(new Date(2012, 0, 1), new Date())
            .dateFormatted,
          expDateValue: _this.randomDate(new Date(2012, 0, 1), new Date())
            .value,
          contractDescription: 'this is an example description',
          companyCode: 12345,
          typeOfContract: 0,
          IsSharedContract: false,
        },
        {
          aravoEngagementId: '',
          contractNumber: 0,
          contractID: 234,
          supplierName: 'Supplier2',
          supplierNumber: 2,
          expirationDate: _this.randomDate(new Date(2012, 0, 1), new Date())
            .dateFormatted,
          expDateValue: _this.randomDate(new Date(2012, 0, 1), new Date())
            .value,
          contractDescription: 'this is an example description',
          companyCode: 12345,
          typeOfContract: 0,
          IsSharedContract: false,
        },
        {
          aravoEngagementId: '',
          contractID: 345,
          contractNumber: 0,
          supplierName: 'Supplier3',
          supplierNumber: 3,
          expirationDate: _this.randomDate(new Date(2012, 0, 1), new Date())
            .dateFormatted,
          expDateValue: _this.randomDate(new Date(2012, 0, 1), new Date())
            .value,
          contractDescription: 'this is an example description',
          companyCode: 12345,
          typeOfContract: 0,
          IsSharedContract: false,
        },
      ],
      showSearchSuggestions: true,
    });
  }

  // ----------- LOGGING METHODS ------------------ //
  private logFetchContractSearchSuggestionsBegin(url, newValue) {
    telemetryContext.logEvent(
      TelemetryEvent.FetchContractSearchSuggestionsBegin,
      {
        url: url,
        searchValue: newValue,
      }
    );
  }
  private logFetchContractSearchSuggestionsSuccess(url) {
    telemetryContext.logEvent(
      TelemetryEvent.FetchContractSearchSuggestionsSuccess,
      {
        url: url,
      }
    );
  }

  private logFetchContractSearchSuggestionsFailure(url, ex, newValue) {
    telemetryContext.logException(
      ex.toString(),
      TelemetryException.FetchContractSearchSuggestionsFailure,
      undefined,
      {
        url: url,
        searchValue: newValue,
      }
    );
  }
  // ----------- END OF LOGGING METHODS ----------- //
}
