import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsights } from '@microsoftit/telemetry-extensions-npm';
import appConfig from '../../assets/configuration/config';

// Service for telemetry on our UI
class TelemetryService {
  private appInsightsExtension: AppInsights;
  private appInsights: ApplicationInsights;

  constructor() {
    this.appInsightsExtension = new AppInsights();
    this.appInsights = this.appInsightsExtension.InitializeTelemetry(
      appConfig.Environment,
      appConfig.InstrumentationKey,
      'Finance',
      'Procurement',
      'Call Center Management',
      'GTO Invoicing Tool',
      '31a5cf87-cadb-4ea2-94c3-723eda2a6752',
      ''
    );
  }

  public logPageView(properties?: any) {
    this.appInsightsExtension.TrackPageview(properties);
  }

  public logEvent(name: string, properties?: any, measurements?: any) {
    this.appInsightsExtension.TrackEvent(
      name,
      properties,
      measurements
    );
  }

  public logException(
    errorMessage: string,
    exceptionType: string,
    severityLevel?: any,
    properties: any = {},
    measurements?: any
  ) {
    let error: Error = new Error(errorMessage);
    properties['exceptionType'] = exceptionType;
    this.appInsightsExtension.TrackException(
      error,
      severityLevel,
      properties,
      measurements
    );
  }
}
const telemetryContext = new TelemetryService();

export default telemetryContext;
