import { IRenderFunction } from '@uifabric/utilities';
import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IDetailsHeaderProps,
  Selection,
  SelectionMode,
} from '@fluentui/react';
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib/Pivot';
import { Sticky } from 'office-ui-fabric-react/lib/Sticky';
import * as React from 'react';
import { toast } from 'react-toastify';
import {
  IContract,
  TypeOfContract,
} from '../../../shared/models/Contract.model';
import { contractColumns } from '../../../shared/styles/ContractListColumns';
import { customDetailsListHeaderStyles } from '../../../shared/styles/DetailsListHeaderStyles';
import './ExistingContractsList.css';
import { css } from 'glamor';
import { Announced } from '@fluentui/react/lib/Announced';
import { isNullOrUndefined } from '../../../shared/common/common-util';

interface IExistingContractsListProps {
  listItems: IContract[];
  showContractShellInfo: boolean;
}

interface IExistingContractsListState {
  columns: IColumn[];
  items: IContract[];
  contractDisplayType: string;
  existingHeight:string;
  listitemHeight:string;
  announcedMessage:string;


}

export class ExistingContractsList extends React.Component<
  IExistingContractsListProps,
  IExistingContractsListState
> {
  private _selection: Selection;

  constructor(props) {
    super(props);
    const contractColumns: IColumn[] = [
      {
        key: 'column1',
        name: 'Contract ID',
        fieldName: 'contractID',
        minWidth: 50,
        maxWidth: 110,
        isResizable: true,
        isCollapsible: false,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted ascending',
        sortDescendingAriaLabel: 'Sorted descending',
        onColumnClick:this._onColumnClick,
        data: 'number',
        isPadded: true,
      },
      {
        key: 'column2',
        name: 'Supplier Name',
        fieldName: 'supplierName',
        minWidth: 50,
        maxWidth: 110,
        isResizable: true,
        isCollapsible: false,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column3',
        name: 'Company Code',
        fieldName: 'companyCode',
        minWidth: 90,
        maxWidth: 110,
        isResizable: true,
        isCollapsible: false,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted ascending',
        sortDescendingAriaLabel: 'Sorted descending',
        data: 'number',
      },
      {
        key: 'column4',
        name: 'Description',
        fieldName: 'contractDescription',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        isCollapsible: false,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column5',
        name: 'Expiration Date',
        fieldName: 'expirationDate',
        minWidth: 90,
        maxWidth: 110,
        isResizable: true,
        data: 'string',
        isPadded: true,
      },
    ];

    // make a deep copy of the contractColumns object
    let customColumns = JSON.parse(JSON.stringify(contractColumns));

    // customize the "on column click behavior" for column headers for sorting
    for (let i = 0; i < contractColumns.length; i++) {
      customColumns[i].onColumnClick = this._onColumnClick;
    }

    // default sort by the contract ID
    customColumns[0].isSorted = true;

    this.state = {
      items: this.props.listItems,
      columns: customColumns,
      contractDisplayType: 'Mapped',
      existingHeight:'43vh',
      listitemHeight:'27vh',
      announcedMessage:undefined

    };
  }
  public async componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize()
  }
  resize = () =>{
    let widnowSize = window.innerWidth;
     if(widnowSize <1000){

      this.setState({
        existingHeight:'85vh',
        listitemHeight:'80vh'
      })
     }else{
      this.setState({
        existingHeight:'43vh',
      listitemHeight:'27vh'
      })
     }
}

  // ---------------------- LIFECYCLE METHODS ------------------------ //
  public render() {
    const { columns,announcedMessage } = this.state;

    return (
      <div className="existingContractsList" style={{height:this.state.existingHeight}}>
        <div className="myHeader">
          <h1
            style={{ color: 'black', display: 'block', fontSize: '1.5em', margin: '0.83em 0 0.83em 0', fontWeight: 'bold', }}
            aria-label={'Existing Contracts'}
          >
            Existing Contracts
          </h1>
          {this.getContractTypeToggle()}
        </div>
        {announcedMessage ? <Announced message={announcedMessage} /> : undefined}

        <div className="listItems" data-is-scrollable="true" style={{height:this.state.listitemHeight}}>
            <DetailsList
              items={this.createContractTableItems()}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForGrid="Existing Contracts List"
              columns={
                this.state.contractDisplayType === 'Mapped'
                  ? columns
                  : columns.slice(1, 4) // excluding "expiration date" column and Contract ID columns for Shell Type
              }
              selectionMode={SelectionMode.none}
              setKey="set"
              layoutMode={DetailsListLayoutMode.justified}
              // isHeaderVisible={true}
              selection={this._selection}
              selectionPreservedOnEmptyClick={true}
              onItemInvoked={this._onItemInvoked}
              enterModalSelectionOnTouch={true}
              onRenderDetailsHeader={(
                detailsHeaderProps: IDetailsHeaderProps,
                defaultRender: IRenderFunction<IDetailsHeaderProps>
              ) => {
                return (
                  <Sticky >
                    {defaultRender({
                      ...detailsHeaderProps,
                      styles: customDetailsListHeaderStyles,
                    })}
                  </Sticky>
                );
              }}
            />
        </div>
      </div>
    );
  }

  public componentDidUpdate(previousProps: any) {
    // logic to see if the list items have been updated
    if (previousProps.listItems !== this.props.listItems) {
      this.setState({ items: this.props.listItems });
    }
  }
  // -------------------- END OF LIFECYCLE METHODS --------------------- //

  private _onItemInvoked(item: any): void {
    if(item && !isNullOrUndefined(item.name)) {
      toast.info(`Item invoked: ${item.name}`, {
        className: css({
          background: '#0275d8 !important',
        }),
      });
    }
  }

  // For getting the toggle in Existing Contracts on supplier contracts
  private getContractTypeToggle = () => {
    if (this.props.showContractShellInfo) {
      return (
        <div className="myHeader-actions">
          <Pivot
            style={{ width: '50%' }}
            onLinkClick={this.handleContractTypeToggle}
          >
            <PivotItem headerText="Mapped" />
            <PivotItem headerText="Shells" />
          </Pivot>
        </div>
      );
    }
  };

  // providing column sorting ability on all columns
  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns, items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(
      currCol => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        this.setState({
          announcedMessage: `${currColumn.name} is sorted ${
            currColumn.isSortedDescending ? 'descending' : 'ascending'
          }`,
        });
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    // if expiration date column, sort on the value of the date instead of
    // the string representing that date
    let newItems;

    if (currColumn.name === 'Expiration Date') {
      newItems = _copyAndSort(
        items,
        'expDateValue',
        currColumn.isSortedDescending
      );
    } else {
      newItems = _copyAndSort(
        items,
        currColumn.fieldName!,
        currColumn.isSortedDescending
      );
    }

    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  // For handling toggle between mapped and shells contracts
  private handleContractTypeToggle = (item: PivotItem) => {
    // Setting the display type in private variable.
    this.setState({ contractDisplayType: item.props.headerText });
  };

  // Create table items based on current Contract display type.
  private createContractTableItems(): IContract[] {
    let finalContractList: IContract[];

    if (this.state.contractDisplayType === 'Mapped') {
      finalContractList = this.state.items.filter(x => {
        // will be a condition to see if it's a mapped contract or not
        if (x.typeOfContract !== TypeOfContract.shell) {
          return x;
        }

        return null;
      });
    }
    // If the type is shells render shell contracts
    else if (this.state.contractDisplayType === 'Shells') {
      finalContractList = this.state.items.filter(x => {
        if (x.typeOfContract === TypeOfContract.shell) {
          return x;
        }
        return null;
      });
    }

    return finalContractList;
  }
}

// compares the items in a column and sorts them either by ascending or descending order
// returns the ordered list to show
function _copyAndSort<T>(
  items: T[],
  columnKey: string,
  isSortedDescending?: boolean
): T[] {
  const key = columnKey as keyof T;
  return items
    .slice(0)
    .sort((a: T, b: T) =>
      (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
    );
}
