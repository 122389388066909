import {IColumn} from '@fluentui/react/lib/DetailsList';

export const columnsForBillableUnitsViewInitialGrid: IColumn[] = [
  {
    key: 'State',
    name: 'State',
    // headerClassName: 'StateStyles',
    ariaLabel: 'state',
    fieldName: 'State',
    minWidth: 20,
    maxWidth: 21,
    isPadded: true,
    isResizable: false
  },
  {
    key: 'Accept',
    name: 'accept',
    fieldName: '',
    ariaLabel: 'accept',
    headerClassName: 'StateStyles',
    minWidth: 25,
    maxWidth: 30,
    isPadded: true,
    isResizable: true
  },
  {
    key: 'SupplierEngagementId',
    name: 'SEID',
    fieldName: 'SupplierEngagementId',
    minWidth: 20,
    maxWidth: 35,
    isPadded: true,
    isResizable: true,
    isSorted:true,
    sortAscendingAriaLabel: 'Ascending and select to sort',
    sortDescendingAriaLabel: 'Descending and select to sort',
    data:'number'
  },
  {
    key: 'BillableUnits',
    name: 'Unit',
    fieldName: 'BillableUnits',
    ariaLabel:'Unit',
    minWidth: 80,
    maxWidth: 85,
    isRowHeader: true,
    isResizable: true,
    isPadded: true,
    isSorted: true,
    isSortedDescending: false,
    sortAscendingAriaLabel: 'Ascending and select to sort',
    sortDescendingAriaLabel: 'Descending and select to sort',
    data:'number'
  },
  {
    key: 'PostInvoicePeriod',
    name: 'Invoice Period',
    fieldName: 'PostInvoicePeriod',
    minWidth: 80,
    maxWidth: 85,
    isResizable: true,
    isPadded: true,
    isSorted: true,
    isSortedDescending: false,
    sortAscendingAriaLabel: 'Ascending and select to sort',
    sortDescendingAriaLabel: 'Descending and select to sort',
    data:'string'
  }
];

export const columnsForInvoiceViewInitialGrid: IColumn[] = [
  {
    key: 'Details',
    name: 'Details',
    ariaLabel: 'Details',
    // headerClassName: 'StateStyles',
    fieldName: 'Details',
    minWidth: 10,
    maxWidth: 50,
    isPadded: true,
    isResizable: false
  },
  {
    key: 'SupplierName',
    name: 'Supplier',
    fieldName: 'SupplierName',
    ariaLabel: 'Supplier Name',
    minWidth: 120,
    maxWidth: 150,
    isPadded: true,
    isResizable: true
  },
  {
    key: 'InvoicePrefix',
    name: 'Invoice Prefix',
    fieldName: 'InvoicePrefix',
    minWidth: 80,
    maxWidth: 90,
    isPadded: true,
    isResizable: true
  },
  {
    key: 'Currency',
    name: 'Currency',
    fieldName: 'Currency',
    minWidth: 80,
    maxWidth: 85,
    isResizable: true,
    isPadded: true
  },
  {
    key: 'ActualGrossAmount',
    name: 'Actual Gross Amount',
    fieldName: 'ActualGrossAmount',
    minWidth: 100,
    maxWidth: 150,
    isResizable: true,
    isPadded: true
  },
  {
    key: 'AccrualGrossAmount',
    name: 'Accrual Gross Amount',
    fieldName: 'AccrualGrossAmount',
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
    isPadded: true
  },
  {
    key: 'Grossvariance',
    name: 'Gross Variance ',
    fieldName: 'Grossvariance',
    minWidth: 60,
    maxWidth: 70,
    isResizable: true,
    isPadded: true
  },
  {
    key: 'GrossVariancePercent',
    name: 'Gross Variance %',
    fieldName: 'GrossVariancePercent',
    minWidth: 100,
    maxWidth: 150,
    isResizable: true,
    isPadded: true
  },
  {
    key: 'ActualGrossAmountCD',
    name: 'Actual Gross Amount CD',
    fieldName: 'ActualGrossAmountCD',
    minWidth: 100,
    maxWidth: 150,
    isResizable: true,
    isPadded: true
  },
  {
    key: 'AccrualGrossAmountCD',
    name: 'Accrual Gross Amount CD',
    fieldName: 'AccrualGrossAmountCD',
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
    isPadded: true
  },
  {
    key: 'GrossvarianceCD',
    name: 'Gross Variance CD ',
    fieldName: 'GrossvarianceCD',
    minWidth: 80,
    maxWidth: 90,
    isResizable: true,
    isPadded: true
  },
  {
    key: 'GrossVariancePercentCD',
    name: 'Gross Variance CD %',
    fieldName: 'GrossVariancePercentCD',
    minWidth: 100,
    maxWidth: 150,
    isResizable: true,
    isPadded: true
  },
  {
    key: 'ActualNetAmount',
    name: 'Actual Net Amount',
    fieldName: 'ActualNetAmount',
    minWidth: 100,
    maxWidth: 150,
    isResizable: true,
    isPadded: true
  },
  {
    key: 'AccrualNetAmount',
    name: 'Accrual Net Amount',
    fieldName: 'AccrualNetAmount',
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
    isPadded: true
  },
];

export const columnsForInvoiceViewSupplierDetailGrid: IColumn[] = [
  {
    key: 'State',
    name: 'state',
    headerClassName: 'StateStyles',
    ariaLabel: 'state',
    fieldName: 'State',
    minWidth: 20,
    maxWidth: 21,
    isPadded: true,
    isResizable: false,
  },
  {
    key: 'Accept',
    name: 'state',
    fieldName: '',
    ariaLabel: 'accept',
    headerClassName: 'StateStyles',
    minWidth: 25,
    maxWidth: 30,
    isPadded: true,
    isResizable: true,
  },
  {
    key: 'SupplierEngagementId',
    name: 'SEID',
    fieldName: 'SupplierEngagementId',
    minWidth: 20,
    maxWidth: 35,
    isPadded: true,
    isResizable: true,
  },
  {
    key: 'BillableUnits',
    name: 'Unit',
    fieldName: 'BillableUnits',
    minWidth: 80,
    maxWidth: 85,
    isResizable: true,
    isPadded: true,
  },
  {
    key: 'PostInvoicePeriod',
    name: 'Invoice Period',
    fieldName: 'PostInvoicePeriod',
    minWidth: 80,
    maxWidth: 85,
    isResizable: true,
    isPadded: true,
  },
  {
    key: 'PricingModel',
    name: 'PM',
    fieldName: 'PricingModel',
    minWidth: 25,
    maxWidth: 30,
    isResizable: true,
    data: 'string',
    isPadded: true
  },
  {
    key: 'PricingType',
    name: 'Pricing Type',
    fieldName: 'PricingType',
    minWidth: 30,
    maxWidth: 55,
    isResizable: true,
    data: 'string',
    isPadded: true
  },
  {
    key: 'Level',
    name: 'Level',
    fieldName: '',
    minWidth: 20,
    maxWidth: 40,
    isResizable: true,
    data: 'string',
    isPadded: true
  },
  {
    key: 'CurrencyCode',
    name: 'Currency',
    fieldName: 'CurrencyCode',
    minWidth: 60,
    maxWidth: 70,
    isResizable: true,
    data: 'string',
    isPadded: true,
  },
  {
    key: 'Price',
    name: 'Price',
    fieldName: '',
    minWidth: 20,
    maxWidth: 40,
    isResizable: true,
    data: 'string',
    isPadded: true
  },
  {
    key: 'TotalAmount',
    name: 'Amount',
    fieldName: 'TotalAmount',
    minWidth: 30,
    maxWidth: 50,
    isResizable: true,
    isPadded: true,
  },
  {
    key: 'FunctionSummary',
    name: 'Function Summary',
    fieldName: 'FunctionSummary',
    minWidth: 75,
    maxWidth: 100,
    isResizable: true,
    isPadded: true,
  },
  {
    key: 'ProductGroup',
    name: 'Product Group',
    fieldName: 'ProductGroup',
    minWidth: 75,
    maxWidth: 100,
    isResizable: true,
    isPadded: true,
  },
  {
    key: 'Product',
    name: 'Product',
    fieldName: 'Product',
    minWidth: 75,
    maxWidth: 100,
    isResizable: true,
    isPadded: true,
  },
  {
    key: 'Language',
    name: 'Language',
    fieldName: 'Language',
    minWidth: 75,
    maxWidth: 100,
    isResizable: true,
    isPadded: true,
  },
  {
    key: 'SupportTier',
    name: 'Support Tier ',
    fieldName: 'SupportTier',
    minWidth: 75,
    maxWidth: 100,
    isResizable: true,
    isPadded: true,
  },
  {
    key: 'MediaSubType',
    name: 'Media Subtype',
    fieldName: 'MediaSubType',
    minWidth: 75,
    maxWidth: 100,
    isResizable: true,
    isPadded: true,
  },
];
