import { toast } from 'react-toastify';
import gtoApiInstance, {
  gtoMsalApiFetch,
} from '../../shared/http/GtoApiNew.axios';
import { existingContractsMockData } from '../../shared/mock';
import {
  IContract,
  Routes,
  TelemetryEvent,
  TelemetryException,
} from '../../shared/models';
import telemetryContext from '../../shared/services/TelemetryServices';
import * as actionTypes from './actionTypes';
import { css } from 'glamor';
import { v4 as uuidv4 } from 'uuid';
import appConfig from '../../assets/configuration/config';

export const setExistingContracts = (data: IContract[]) => {
  return {
    type: actionTypes.SET_EXISTING_CONTRACTS,
    existingContracts: data,
  };
};

// ----------- LOGGING METHODS ----------- //
const logFetchExistingContractsBegin = (url) => {
  telemetryContext.logEvent(TelemetryEvent.FetchExistingContractsBegin, {
    url: url,
  });
};

const logFetchExistingContractsSuccess = (url) => {
  telemetryContext.logEvent(TelemetryEvent.FetchExistingContractsSuccess, {
    url: url,
  });
};

const logFetchExistingContractsFailure = (ex, url) => {
  telemetryContext.logException(
    ex.toString(),
    TelemetryException.FetchExistingContractsFailure,
    undefined,
    {
      url: url,
    }
  );
};
// ----------- END OF LOGGING METHODS ----------- //

const callFetchExistingContractsAPI = async (url) => {
  try {
    const headers: object = {
      'X-CorrelationId': uuidv4(),
      SubCorrelationKey: uuidv4(),
    };
    let temp = await gtoMsalApiFetch(gtoApiInstance.get, url, { headers });
    return temp;
  } catch (ex) {
    return ex.response.data;
  }
};
/*--------------------------------------THUNK ACTION CREATORS-----------------------------------*/

export const getExistingContracts = () => {
  return (dispatch) => {
    let existingContractsList: IContract[] = [];

    if (appConfig.UseMockData === 'false') {
      // Defining the url
      const url: string = Routes.FetchExistingContracts;

      // Logging telemetry
      logFetchExistingContractsBegin(url);

      // fetch existing contract data from api
      try {
        // making this variable to use inside of function below because not using Arrow function
        callFetchExistingContractsAPI(url).then(function (response) {
          // Logging success
          telemetryContext.logEvent(
            TelemetryEvent.FetchExistingContractsBegin,
            {
              url: url,
            }
          );
          logFetchExistingContractsSuccess(url);

          for (let i = 0; i < response.data.length; i++) {
            // initialize the contract object
            let contract: IContract = {
              contractNumber: 0,
              aravoEngagementId: '',
              contractID: 0,
              supplierName: '',
              supplierNumber: 0,
              contractDescription: '',
              companyCode: 0,
              typeOfContract: 0,
              expirationDate: '',
              expDateValue: 0,
              IsSharedContract: false,
            };

            // setting the values according to API response
            contract.contractID = response.data[i].contractId;
            contract.supplierName = response.data[i].supplierName;
            contract.contractDescription = response.data[i].contractDescription;
            contract.companyCode = response.data[i].companyCode;
            contract.typeOfContract = response.data[i].typeOfContract;
            contract.supplierNumber = response.data[i].supplierNumber;

            // setting the expiration date
            let expDate = response.data[i].expirationDate;

            // '0001-01-01T00:00:00' is the min value returned by API
            // - meaning that the value was null in the DB
            if (expDate !== '0001-01-01T00:00:00') {
              let dateObj = new Date(response.data[i].expirationDate);

              contract.expirationDate = dateObj.toLocaleDateString();
              contract.expDateValue = dateObj.valueOf();
            }

            existingContractsList.push(contract);
          }

          if (existingContractsList.length > 0) {
            dispatch(setExistingContracts(existingContractsList));
          }
        });
      } catch (ex) {
        // log the exception
        logFetchExistingContractsFailure(ex, url);

        // if (ex.response && ex.response.status === 404) {
        toast.error(
          'Existing contract information is not available at this moment.',
          {
            className: css({
              background: '#a80000 !important',
            }),
          }
        );
        // Setting the contract data as empty array as response had an error.
        existingContractsList = [];
      }
    } else if (appConfig.UseMockData === 'true') {
      // existingContractsList = existingContractsMockData;
      dispatch(setExistingContracts(existingContractsMockData));
    }
  };
};
