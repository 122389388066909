import React, { Component } from 'react';
import {PrimaryButton,
         Label, 
         Spinner,
         SpinnerSize
         } from "office-ui-fabric-react";
import {IColumn} from '@fluentui/react/lib/DetailsList';
 import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { BOBReportTable } from "./BOBReportTable";
import { connect } from 'react-redux';
import { updateInvoiceReportSelectedSuppliers, 
        updateInvoiceReportSelectedFiscalYears, 
        updateInvoiceReportSelectedSources,
        updateInvoiceReportSelectedQuarters,
        getInvoiceReportSummary, 
        getInvoiceReportDetails} from '../../store/actions/bobReport.action';
import { CSVLink } from 'react-csv';
import { toast } from 'react-toastify';
import { css } from 'glamor';

//Interface for BOB Invoice Report Props
export interface IInvoiceReportProps {
  IsFetchingInvoiceReport: boolean,
  IsFetchingInvoiceReportDetails: boolean,
  InvoiceReportIntialLoading: boolean,
  InvoiceFiscalYearFilterOptions: IDropdownOption[];
  InvoiceSupplierFilterOptions: IDropdownOption[],
  InvoiceQuarterFilterOptions: IDropdownOption[],
  InvoiceSourceFilterOptions: IDropdownOption[],
  InvoiceReportSummary: [],
  InvoiceReportDetails: [],
  SpendInDollar: string,
  DiscountAmountInUSD: string,  
  onSupplierFilterChange(option : IDropdownOption): any;
  onQuarterFilterChange(option : IDropdownOption): any;
  onSourceFilterChange(option : IDropdownOption): any;
  onFiscalYearFilterChange(option : IDropdownOption): any;
  getInvoiceReportSummary(): any;
  getInvoiceReportDetails(): any;
}

const csvLink = React.createRef<CSVLink>();
  
class BOBReportInvoice extends Component<IInvoiceReportProps, {}> {
  public constructor(props) {
      super(props);   
  }

  public render() {
      return(
        <React.Fragment>
          <div className="report-filters">
              <div className="ms-Grid" dir="ltr" style={{marginLeft: '1vw'}}>
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg10"> 
                      <div className="ms-Grid-row">            
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3 reportFilter">
                            <Dropdown
                            placeholder = "All"
                            label = "SupplierName"
                            multiSelect
                            options= {this.props.InvoiceSupplierFilterOptions}
                            onChange = {(e, o) => this.props.onSupplierFilterChange(o)}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3 reportFilter">
                        <Dropdown
                            placeholder = "All"
                            label = "Quarter"
                            multiSelect
                            options={this.props.InvoiceQuarterFilterOptions}
                            onChange = {(e, o) => this.props.onQuarterFilterChange(o)}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3 reportFilter">
                        <Dropdown                      
                            placeholder = "All"
                            label = "Source"
                            multiSelect
                            options={this.props.InvoiceSourceFilterOptions}
                            onChange = {(e, o) => this.props.onSourceFilterChange(o)}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3 reportFilter">
                      <Dropdown
                          placeholder = "All"
                          label = "FiscalYearNumber"
                          multiSelect
                          options={this.props.InvoiceFiscalYearFilterOptions}
                          onChange = {(e, o) => this.props.onFiscalYearFilterChange(o)}
                          />
                      </div>
                      </div>            
                    </div>
                    <div className ="ms-Grid-col ms-sm6 ms-md4 ms-lg2 reportFilter">
                      <PrimaryButton
                      className="ms-Button ms-Button--primary"
                      text="Search"    
                      iconProps={{ iconName: 'search' }}       
                      style = {{marginTop: '25px'}}       
                      onClick={ async () => {
                          await this.props.getInvoiceReportSummary() 
                        }}
                        ></PrimaryButton>
                    </div>
                  </div>
              </div>
          </div>          
          <div className="report-content1">
            <div style={{
            height: '37vh',
            position: 'relative',
            marginLeft: '1vw'}}
            className ="tableViewInvoice"
            >
              {this.renderReportSummary()}
            </div>
          </div>
          <div className="report-content3">            
            {this.renderReportDetails()}
          </div>
        </React.Fragment>
      )
  }

  private renderReportSummary(){   
    if(this.props.IsFetchingInvoiceReport){
      return(
        <div aria-label={'Loading'} style={{paddingTop: '10vh'}}>
          <Spinner
            style={{ height: '100%' }}
            label="Loading..."
            size={SpinnerSize.large}
          />
        </div>
      )
    }
    else{
      if(this.props.InvoiceReportIntialLoading){
        return(
          <Label style={{paddingLeft : '8px'}} className="invoicelabel">
                Please select the filter conditions and click the search button to see results
            </Label>
        )
      }
      else{
        if(this.props.InvoiceReportSummary.length <= 0){
          return (
            <Label style={{paddingLeft : '8px'}}>
                No Records found for the selected filter conditions
            </Label>
          )
        }
        else{
          return (
              <BOBReportTable items={this.props.InvoiceReportSummary}
              columns={this.createInvoiceReportColumns()}
              spendInDollar= {this.props.SpendInDollar}
              discountAmountInUSD= {this.props.DiscountAmountInUSD}
              grossAmount= ''
              lastRefreshedAt = {new Date().toString()}
              showFooter = {true}
              />
            )  
        }    
      }
    }      
  }


  private renderReportDetails(){
    if(this.props.InvoiceReportSummary.length > 0 && !this.props.IsFetchingInvoiceReport){
      return (
          <div style={{
          height: '5vh',
          position: 'relative',
          marginRight: '1vw',
          marginTop: '7vh',
          textAlign: 'right'}}
          >   
            <PrimaryButton 
             className="ms-Button ms-Button--primary exportDetailsBtn"
             text= "Export Supplier Engagement Level Discount Details to Excel"
             ariaLabel = "Export Supplier Engagement Level Discount Details to Excel"
             onClick={ async () =>
              {
                await this.props.getInvoiceReportDetails();
                if(this.props.InvoiceReportDetails.length > 0)
                {
                 csvLink.current.link.click()
                }
                else{
                  toast.info('Supplier Engagement Level Discount details not found for the selected filter conditions', {
                    className: css({
                      background: '#0275d8 !important',
                    }),
                  });
                }
              }
             }
            >
              {this.props.IsFetchingInvoiceReportDetails ?
              <Spinner
                style={{ height: '100%', marginLeft: '5px' }}
                size={SpinnerSize.large}
              /> : ""}             
            </PrimaryButton>
            <CSVLink
              data={this.props.InvoiceReportDetails}
              filename={'SupplierDiscountDetails_Invoice.csv'}
              className="hidden"
              ref={csvLink}
              target="_blank" 
              aria-hidden="true"
              tabindex="-1"
            />               
          </div>                          
      )
    }
    else{
      return "";
    }
  }
  
  private createInvoiceReportColumns(): IColumn[] {
    let columns: IColumn[] = [
      {
        key: 'column1',
        name: 'SupplierName',
        fieldName: 'SupplierName',
        minWidth: 20,
        maxWidth: 50,
        isResizable: true,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column2',
        name: 'CurrencyCode',
        fieldName: 'CurrencyCode',
        minWidth: 20,
        maxWidth: 35,
        isResizable: true,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column3',
        name: 'TOTALSpendinLC',
        fieldName: 'TOTALSpendinLC',
        minWidth: 30,
        maxWidth: 50,
        isResizable: true,
        data: 'string',
        isPadded: true,
      },    
      {
          key: 'column4',
          name: 'PassThrough',
          fieldName: 'PassThrough',
          minWidth: 20,
          maxWidth: 35,
          isResizable: true,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column5',
          name: 'GrossAmount',
          fieldName: 'GrossAmount',
          minWidth: 20,
          maxWidth: 35,
          isResizable: true,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column6',
          name: 'ExchangeRate',
          fieldName: 'ExchangeRate',
          minWidth: 20,
          maxWidth: 35,
          isResizable: true,
          data: 'string',
          isPadded: true,
        },      
        {
          key: 'column7',
          name: 'ExchangeRateMonth',
          fieldName: 'ExchangeRateMonth',
          minWidth: 30,
          maxWidth: 50,
          isResizable: true,
          data: 'string',
          isPadded: true,
        },      
        {
          key: 'column8',
          name: 'SpendInDollar',
          fieldName: 'SpendInDollar',
          minWidth: 40,
          maxWidth: 70,
          isResizable: true,
          data: 'string',
          isPadded: true,
          isMultiline: true
        },
        {
          key: 'column9',
          name: 'BoBDiscountPercent',
          fieldName: 'BoBDiscountPercent',
          minWidth: 15,
          maxWidth: 20,
          isResizable: true,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column10',
          name: 'DiscountAmountLC',
          fieldName: 'DiscountAmountLC',
          minWidth: 40,
          maxWidth: 60,
          isResizable: true,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column11',
          name: 'DiscountAmountInUSD',
          fieldName: 'DiscountAmountInUSD',
          minWidth: 40,
          maxWidth: 70,
          isResizable: true,
          data: 'string',
          isPadded: true,
          isMultiline: true
        },
        {
          key: 'column12',
          name: 'IsAnualDiscount',
          fieldName: 'IsAnualDiscount',
          minWidth: 15,
          maxWidth: 20,
          isResizable: true,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column13',
          name: 'Quarter',
          fieldName: 'Quarter',
          minWidth: 10,
          maxWidth: 15,
          isResizable: true,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column14',
          name: 'SOURCE',
          fieldName: 'SOURCE',
          minWidth: 10,
          maxWidth: 15,
          isResizable: true,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column15',
          name: 'LastBobProcessedTime',
          fieldName: 'LastBobProcessedTime',
          minWidth: 15,
          maxWidth: 100,
          isResizable: true,
          data: 'string',
          isPadded: true,
        }
    ]; 
    return columns;
  }
}

const mapDispatchToProps = (dispatch, props) => { 
  return {
    onSupplierFilterChange: (option: IDropdownOption) => {
     return dispatch(updateInvoiceReportSelectedSuppliers(option));
    },
    onQuarterFilterChange: (option: IDropdownOption) => {
      return dispatch(updateInvoiceReportSelectedQuarters(option));
    },
    onSourceFilterChange: (option: IDropdownOption) => {
      return dispatch(updateInvoiceReportSelectedSources(option));
    },
    onFiscalYearFilterChange: (option: IDropdownOption) => {
      return dispatch(updateInvoiceReportSelectedFiscalYears(option));
    },
    getInvoiceReportSummary: () =>{
      return dispatch(getInvoiceReportSummary());      
    },
    getInvoiceReportDetails: () =>{
      return  dispatch(getInvoiceReportDetails());
    }
  }
};

const mapStateToProps = state => {
  return {    
    IsFetchingInvoiceReport: state.bobReport.isFetchingInvoiceReport,
    IsFetchingInvoiceReportDetails: state.bobReport.isFetchingInvoiceReportDetails,
    InvoiceReportIntialLoading: state.bobReport.invoiceReportIntialLoading,
    InvoiceFiscalYearFilterOptions : state.bobReport.invoiceFiscalYearFilterOptions,
    InvoiceSupplierFilterOptions: state.bobReport.invoiceSupplierFilterOptions,
    InvoiceQuarterFilterOptions: state.bobReport.invoiceQuarterFilterOptions,
    InvoiceSourceFilterOptions: state.bobReport.invoiceSourceFilterOptions,
    InvoiceReportSummary: state.bobReport.invoiceReportSummary,
    InvoiceReportDetails: state.bobReport.invoiceReportDetails,
    SpendInDollar: state.bobReport.invoiceTotalSpendInDollar,
    DiscountAmountInUSD: state.bobReport.invoiceTotalDiscountAmountInUSD,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BOBReportInvoice);