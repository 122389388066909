import { IColumn } from '@fluentui/react';

export const contractColumns: IColumn[] = [
  {
    key: 'column1',
    name: 'Contract ID',
    fieldName: 'contractID',
    minWidth: 50,
    maxWidth: 110,
    isResizable: true,
    isCollapsible: false,
    isSorted: true,
    isSortedDescending: false,
    sortAscendingAriaLabel: 'Sorted ascending',
    sortDescendingAriaLabel: 'Sorted descending',
    data: 'number',
    isPadded: true,
  },
  {
    key: 'column2',
    name: 'Supplier Name',
    fieldName: 'supplierName',
    minWidth: 50,
    maxWidth: 110,
    isResizable: true,
    isCollapsible: false,
    data: 'string',
    isPadded: true,
  },
  {
    key: 'column3',
    name: 'Company Code',
    fieldName: 'companyCode',
    minWidth: 90,
    maxWidth: 110,
    isResizable: true,
    isCollapsible: false,
    isSorted: true,
    isSortedDescending: false,
    sortAscendingAriaLabel: 'Sorted ascending',
    sortDescendingAriaLabel: 'Sorted descending',
    data: 'number',
  },
  {
    key: 'column4',
    name: 'Description',
    fieldName: 'contractDescription',
    minWidth: 150,
    maxWidth: 200,
    isResizable: true,
    isCollapsible: false,
    data: 'string',
    isPadded: true,
  },
  {
    key: 'column5',
    name: 'Expiration Date',
    fieldName: 'expirationDate',
    minWidth: 90,
    maxWidth: 110,
    isResizable: true,
    data: 'string',
    isPadded: true,
  },
];
