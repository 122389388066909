import { Spinner, SpinnerSize } from 'office-ui-fabric-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddAravoEngagement from './components/add-aravo-engagements/AddAravoEngagement';
import AppErrorBoundry from './components/error-boundry/AppErrorBoundry';
import Layout from './components/layout/Layout';
import PurchaseOrderReview from './components/purchase-order-review/PurchaseOrderReview';
import SupplierContract from './components/supplier-contract/SupplierContract';
import SupplierSummary from './components/supplier-summary/SupplierSummary';
import Unauthorized from './components/unauthorized/Unauthorized';
import { TelemetryEvent } from './shared/models';
import telemetryContext from './shared/services/TelemetryServices';
import { getFeatureStatus } from './store/actions/features.action';
import AddJudgements from './components/add-judgements/AddJudgements';
import {
  getUserModules,
  toggleIsSyntheticFlag,
  getUserRoles,
} from './store/actions/user.action';
import HeadCount from './components/headcount/HeadCount';
import InvoiceView from './components/invoice-view/InvoiceView';
import Help from './components/help/Help';
import Home from './components/help/HomePageDoc';
import BOBReport from './components/bob-report/BOBReport';
import UserManagement from './components/user-management/UserManagement';
import Suppliers from './components/suppliers/Suppliers';
import Suppliersites from './components/suppliersites/Suppliersites';
import Nrclineitems from './components/nrclineitems/Nrclineitems';
import Engagementidattribute from './components/engagementidattribute/Engagementidattribute';
import Reports from './components/reports/Reports';
import InvoiceManagement from './components/invoice-management/invoice-management';
import Pricing from './components/pricing/pricing';
import Automatedseid from './components/automated-seid/Automatedseid';
import Rollover from './components/rollover/rollover';
import InternalOrder from './components/internalOrder/internal-order';


import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import appConfig from './assets/configuration/config';

interface IAppProps {
  userName: string;
  logoutHandler: any;
  createdPO: number;
  isFetchingUser: boolean;
  isFeatureEnabled(feature: string): any;
  userModules: any[];
  isSynthetic: boolean;
  isAdmin: boolean; // Add This
  toggleIsSynthetic(): any;
  // onLoadUser(): any;
  getModulesForUser(): any;
  IsBusinessAnalyst: boolean;
  IsSupplierSupportManager: boolean;
  IsInvoiceAnalyst: boolean;
  IsInvoicingManager: boolean;
  IsFinanceOwner: boolean;
  IsServiceDelivery: boolean;
  getUserRoles(): any;
  //getCurrentUrlNavigation: any;
}

class App extends Component<IAppProps, {}> {

  constructor(props: any) {
    super(props);
    this.state = {
      isNavCollapsed: false,
      searchData: {
        showSearchDetails: false,
        searchText: '',
        searchResult: []
      }
    };

    const clickPluginInstance = new ClickAnalyticsPlugin();
    // Click Analytics configuration
    const clickPluginConfig = {
      autoCapture: true,
      dataTags: {
        customDataPrefix: 'data-custom-',
        useDefaultContentNameOrId: true,
        parentDataTag: 'parent-group',
      }
    };
    // Application Insights Configuration
    const configObj = {
      instrumentationKey: `${appConfig.InstrumentationKey}`,
      extensions: [clickPluginInstance],
      extensionConfig: {
        [clickPluginInstance.identifier]: clickPluginConfig
      },
      autoTrackPageVisitTime: true,
      enableAutoRouteTracking: true,
      disableFetchTracking: false,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
    };

    const appInsights = new ApplicationInsights({ config: configObj });
    appInsights.loadAppInsights();
    var handleDocumentClick = (event: any) => {
      var element = event.target as HTMLElement;
      var rootElement = document.getElementById('root');
      if (element !== rootElement && !rootElement.contains(element)) {
        var closestUserInput = element.closest('BUTTON , INPUT, A , *[role="button"] , *[role="menuitem"] , DROPDOWN');
        if (closestUserInput && closestUserInput.id && closestUserInput.getAttribute("data-custom-parentid") 
        && closestUserInput.getAttribute("data-custom-parentid") !== "") {
          appInsights.trackEvent({
            name: closestUserInput.id,
            properties: { // accepts any type
              parentId: closestUserInput.getAttribute("data-custom-parentid"),
              pageName: document.title,
              uri: document.URL,
              baseTypeSource:  "ClickEvent",
              actionType: "CL"
            }
          });
        }
      }
    };

    document.addEventListener('click', handleDocumentClick, true)
  }

  public componentDidMount() {
    // logging app load event with user alias. This event can be use to correlate user with session.
    telemetryContext.logEvent(TelemetryEvent.AppLoad, {
      userName: this.props.userName,
    });
    this.props.getModulesForUser();
    this.props.getUserRoles();
  }

  public render() {
    if (this.props.isFetchingUser) {
      return (
        <div style={{ marginTop: '45vh' }}>
          <Spinner label="Loading..." size={SpinnerSize.large} />
        </div>
      );
    } else if (this.props.userModules.length > 0) {
      this.props.isFeatureEnabled('myorder2');
      this.props.isFeatureEnabled('myorder');
      return (
        <AppErrorBoundry>
          <Layout
            isSynthetic={this.props.isSynthetic}
            isAdmin={this.props.isAdmin}
            toggleIsSynthetic={this.props.toggleIsSynthetic}
            userName={this.props.userName}
            logoutHandler={this.props.logoutHandler}
          >
            <ToastContainer autoClose={3500} />
            <Switch>
              <Route path="/unauthorized" component={Unauthorized} />
              <Route
                path="/supplier/:suppliernumber/purchaseorder/create"
                component={PurchaseOrderReview}
              />
              <Route path="/billableunits" component={HeadCount} />
              <Route
                path="/invoiceview"
                component={
                  this.props.IsBusinessAnalyst ||
                    this.props.IsSupplierSupportManager ||
                    this.props.IsInvoiceAnalyst ||
                    this.props.IsInvoicingManager ||
                    this.props.IsFinanceOwner ||
                    this.props.IsServiceDelivery
                    ? InvoiceView
                    : Unauthorized
                }
              />
              <Route
                path="/supplier/:suppliernumber/purchaseorder/:purchaseordernumber/create"
                component={PurchaseOrderReview}
              />
              <Route
                path="/supplier/:suppliernumber/judgements/add"
                component={AddJudgements}
              />
              <Route
                path="/supplier/:suppliernumber/aravoengagement/add"
                component={AddAravoEngagement}
              />
              <Route path="/contract" component={SupplierContract} />
              <Route path="/help" component={Help} />
              <Route path="/help/home" component={Home} />
              <Route path="/bobdiscount" component={BOBReport} />
              <Route path="/userManagement" component={UserManagement} />
              <Route path="/supplier" component={Suppliers} />
              <Route path="/suppliersites" component={Suppliersites} />
              <Route path="/nrcLineItems" component={Nrclineitems} />
              <Route path="/orgAttribute" component={Engagementidattribute} />
              <Route path="/GTOReports" component={Reports} />
              <Route path="/invoiceManagement" component={InvoiceManagement} />
              <Route path="/pricing" component={Pricing} />
              <Route path="/automatedSupplierEngagements" component={Automatedseid} />
              <Route path="/rollover" component={Rollover} />
              <Route path="/internalorder" component={InternalOrder} />
              <Route path="/" component={SupplierSummary} />
            </Switch>
          </Layout>
        </AppErrorBoundry>
      );
    } else {
      return <Unauthorized UserName={this.props.userName} />;
    }
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    isFeatureEnabled: (feature: string) => {
      return dispatch(getFeatureStatus(feature));
    },
    getUserRoles: () => {
      return dispatch(getUserRoles());
    },
    getModulesForUser: () => {
      return dispatch(getUserModules());
    },
    toggleIsSynthetic: () => {
      dispatch(toggleIsSyntheticFlag());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    userModules: state.user.Modules,
    isFetchingUser: state.user.IsFetchingUser,
    createdPO: state.selectedSupplier.createdPO,
    isSynthetic: state.user.isSynthetic,
    isAdmin: state.user.IsAdmin,
    IsBusinessAnalyst: state.user.IsBusinessAnalyst,
    IsSupplierSupportManager: state.user.IsSupplierSupportManager,
    IsInvoiceAnalyst: state.user.IsInvoiceAnalyst,
    IsInvoicingManager: state.user.IsInvoicingManager,
    IsFinanceOwner: state.user.IsFinanceOwner,
    IsServiceDelivery: state.user.IsServiceDelivery,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
