import { IBudget } from '../models/index';

export const budgetMockData: IBudget = {
  EstimatedAmountCD: 10,
  EstimatedAmountLC: 10,
  OriginalBudgetLC: 10,
  OriginalBudgetCD: 10,
  YTDActualsLC: 10,
  YTDActualsCD: 10,
  BudgetAfterJudgementLC: 10,
  BudgetAfterJudgementCD: 10,
  AdjustmentsLC: 10,
  AdjustmentsCD: 10,
  ProjectedSpendLC: 10,
  ProjectedSpendCD: 10,
  OriginalPOAmountLC: 10,
  OriginalPOAmountCD:10,
  BudgetAndActualDetails: [
    {
      CalenderMonth: 'Jan',
      CalendarYear: 10,
      CumulativeMonth: 10,
      TrendType: 'Forecast',
      ExchangeRateAmt: 10,
      AmountLC: 10,
      AmountCD: 10,
      BudgetLC: 10,
      BudgetCD: 10,
    },
    {
      CalenderMonth: 'Feb',
      CalendarYear: 10,
      CumulativeMonth: 10,
      TrendType: 'Forecast',
      ExchangeRateAmt: 10,
      AmountLC: 10,
      AmountCD: 10,
      BudgetLC: 10,
      BudgetCD: 10,
    },
    {
      CalenderMonth: 'Mar',
      CalendarYear: 10,
      CumulativeMonth: 10,
      TrendType: 'Forecast',
      ExchangeRateAmt: 10,
      AmountLC: 10,
      AmountCD: 10,
      BudgetLC: 10,
      BudgetCD: 10,
    },
    {
      CalenderMonth: 'Apr',
      CalendarYear: 10,
      CumulativeMonth: 10,
      TrendType: 'Actuals',
      ExchangeRateAmt: 10,
      AmountLC: 10,
      AmountCD: 10,
      BudgetLC: 10,
      BudgetCD: 10,
    },
    {
      CalenderMonth: 'May',
      CalendarYear: 10,
      CumulativeMonth: 10,
      TrendType: 'Forecast',
      ExchangeRateAmt: 10,
      AmountLC: 10,
      AmountCD: 10,
      BudgetLC: 10,
      BudgetCD: 10,
    },
    {
      CalenderMonth: 'Jun',
      CalendarYear: 10,
      CumulativeMonth: 10,
      TrendType: 'Forecast',
      ExchangeRateAmt: 10,
      AmountLC: 10,
      AmountCD: 10,
      BudgetLC: 10,
      BudgetCD: 10,
    },
  ],
};
