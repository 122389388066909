import { IAravoToContractMap } from './../models/AravoToContractMap.model';

export const aravoToContractMappingMockData: IAravoToContractMap[] = [
  {
    SupplierId: 12,
    ContractIds: ['1234', '1234', '5432'],
    FiscalYearNumber: 2019,
    AravoEngagementId: 'hhgnm776o8',
    SharedContractIds: ['1223', '4567'],
  },
  {
    SupplierId: 123,
    ContractIds: ['432', '122', '22'],
    FiscalYearNumber: 2019,
    AravoEngagementId: '123hg6',
    SharedContractIds: ['1223', '4567'],
  },
];
